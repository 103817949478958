<template>
	<div class="l-content-section">
		<div class="jumbo-carousel">
			<div
				v-if="slides.length > 1"
				class="jumbo-carousel__dots"
				:class="{'has-live-video': hasLiveVideo}"
			>
				<div class="l-container">
					<div class="l-row">
						<div class="l-col l-col--12 l-col--11@sm l-offset--1@sm">
							<span
								v-for="(slide, i) in slides"
								:key="i"
								class="jumbo-carousel__dot-w"
								:class="{'is-active': slideIndex === i}"
								@click="changeSlide(i)"
							>
								<span class="jumbo-carousel__dot" />
							</span>
						</div>
					</div>
				</div>
			</div>
			<Carousel
				ref="carousel"
				:cells="mappedSlides"
				:wrap-around="true"
				:auto-play="true"
				:disable-carousel-card-overflow="true"
				class="jumbo-carousel_slider"
				@change="handleChange"
			>
				<template #cell="{cell}">
					<component
						:is="cell.link && !cell.showLiveVideo ? 'a' : 'div'"
						class="jumbo-carousel__slide"
						:class="{'has-live-video': cell.showLiveVideo}"
						:style="{
							'--background-image-url': `url('${cell.backgroundImageUrl}')`,
							'--background-image-mobile-url': `url('${cell.backgroundImageMobileUrl || cell.backgroundImageUrl}')`
						}"
						v-bind="getLinkProps(cell, true)"
					>
						<div class="l-container">
							<div class="l-row">
								<div class="l-col l-col--12 l-col--11@sm l-offset--1@sm">
									<div class="jumbo-carousel__slide-content-w">
										<div class="jumbo-carousel__slide-content-main">
											<img
												v-if="cell.imageUrl"
												class="jumbo-carousel__logo"
												:src="cell.imageUrl"
											>
											<div class="jumbo-carousel__slide-pretitle-w">
												<BadgeLive
													v-if="cell.slideType === ArenaSlideType.EVENT && getEventStatus(cell) === EVENT_STATUS.LIVE"
													modifier="small"
													:hide-text="true"
													class="jumbo-carousel__slide-live-badge"
												/>
												<h4
													v-if="cell.slideType === ArenaSlideType.EVENT"
													class="jumbo-carousel__slide-pretitle"
												>
													{{ getTimeDescription(cell) }}
												</h4>
											</div>
											<SectionTitle
												:title="cell.title"
												:vertical-spacing="false"
												type="main"
												class="jumbo-carousel__slide-title"
											/>
											<h5
												v-if="cell.subtitle"
												class="jumbo-carousel__slide-subtitle"
											>
												{{ cell.subtitle }}
											</h5>
											<Button
												v-if="cell.link"
												class="jumbo-carousel__link"
												:text="getMoreText(cell)"
												theme="dark"
												v-bind="getLinkProps(cell)"
											/>
											<span
												v-if="cell.link"
												class="jumbo-carousel__mobile-link"
											>{{ getMoreText(cell) }}
												<Icon
													icon-name="right-arrow"
													size="sm"
												/>
											</span>
										</div>
										<a
											v-if="cell.showLiveVideo"
											class="jumbo-carousel__slide-content-video"
											v-bind="getLinkProps(cell, true)"
										>
											<LivePreviewPlayer
												:product-placement-enabled="cell.event.productPlacementEnabled"
												:autostop-muted-after-seconds="120"
												:autostop-after-seconds="900"
												:poster="cell.backgroundImageUrl"
												:event-id="cell.eventId"
											/>
										</a>
									</div>
								</div>
							</div>
						</div>
					</component>
				</template>
			</Carousel>
		</div>
		<slot name="after" />
	</div>
</template>

<script>
import Carousel from '@js/components/Carousel';
import SectionTitle from '@js/components/SectionTitle';
import LivePreviewPlayer from '@js/components/LivePreviewPlayer';
import Button from '@shared/components/Button';
import Icon from '@shared/components/Icon';
import BadgeLive from '@shared/components/BadgeLive';
import { ArenaSlideType } from '@shared/enums/ArenaSlideType';
import { getEventStatus, EVENT_STATUS } from '@shared/utils/eventUtils';
import { getDateTime, getRelativeTime } from '@shared/utils/timeUtils';

export default {
	name:       'JumbotronCarousel',
	components: { Carousel, SectionTitle, Button, Icon, BadgeLive, LivePreviewPlayer },
	props:      {
		slides: {
			type:     Array,
			required: true,
		},
	},
	data() {
		return {
			slideIndex: 0,
			ArenaSlideType,
			EVENT_STATUS,
			unixNow:    Math.round(new Date().getTime() / 1000),
		};
	},
	computed: {
		hasLiveVideo() {
			return this.slides.some((slide) => slide.slideType === ArenaSlideType.EVENT && this.getEventStatus(slide) === EVENT_STATUS.LIVE && slide.showLiveVideo);
		},
		mappedSlides() {
			return this.slides.map((slide) => {
				const hasLiveVideo = slide.slideType === ArenaSlideType.EVENT && this.getEventStatus(slide) === EVENT_STATUS.LIVE && slide.showLiveVideo;
				return {
					...slide,
					showLiveVideo:   hasLiveVideo,
					pauseForSeconds: hasLiveVideo ? 16 : 0,
				};
			});
		},
	},
	methods: {
		getMoreText(slide) {
			if (slide.slideType === ArenaSlideType.EVENT) {
				return $I18n.trans('arena.watch_now');
			}

			return $I18n.trans('commons.show_more');
		},
		getEventStatus(slide) {
			return getEventStatus(slide.event.type, slide.event.startTimestamp, slide.event.endTimestamp, this.unixNow);
		},
		getTimeDescription(slide) {
			const status = this.getEventStatus(slide);
			let eventPreTitle = '';
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			const gameStartDate = new Date(slide.event.startTimestamp * 1000);
			const gameIsToday = gameStartDate.toLocaleDateString() === new Date().toLocaleDateString();
			const gameIsTomorrow = gameStartDate.toLocaleDateString() === tomorrow.toLocaleDateString();

			switch (status) {
			case EVENT_STATUS.UPCOMING:
				if (gameIsTomorrow) {
					eventPreTitle += `${this.$I18n.trans('commons.tomorrow')} · ${getDateTime(slide.event.startTimestamp, { withDate: false })}`;
				} else if (gameIsToday) {
					eventPreTitle += `${this.$I18n.trans('commons.today')} · ${getDateTime(slide.event.startTimestamp, { withDate: false })}`;
				} else {
					eventPreTitle += `${getDateTime(slide.event.startTimestamp, { withWeekdayPrefix: true, dateTimeSeparator: ' · ' })}`;
				}
				break;
			case EVENT_STATUS.LIVE:
				eventPreTitle += getRelativeTime(slide.event.endTimestamp, { useRemaining: true, unixNow: this.unixNow });
				break;
			default:
				eventPreTitle += getDateTime(slide.event.startTimestamp, { withWeekdayPrefix: true, withTime: false });
			}

			return eventPreTitle;
		},
		handleChange(index) {
			this.slideIndex = index;
		},
		changeSlide(index) {
			this.$refs.carousel.select(index);
		},
		getLinkProps(slide, hrefAttribute = false) {
			if (!slide.link) {
				return null;
			}

			const url = new URL(slide.link, window.location.origin);
			const isInternal = url.host === window.location.host;

			return {
				[hrefAttribute ? 'href' : 'link']: slide.link,
				target:                            isInternal ? '_self' : '_blank',
				rel:                               isInternal ? 'opener' : 'noopener',
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.jumbo-carousel {
	position: relative;
}

.jumbo-carousel__slide {
	display: flex;
	align-items: center;
	position: relative;
	height: 377px;
	width: 100%;
	background-size: cover;
	background-position: center;
	padding-bottom: $sp5;
	background-image: var(--background-image-mobile-url);

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0.2) 0%, var(--color-black) 100%);
		opacity: 0.8;
		z-index: -1;

		@include size(sm-up) {
			background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, var(--color-black) 100%);
		}

		@include size(md-up) {
			height: 85%;
		}
	}

	&.has-live-video {
		background-image: none;
		padding-top: $sp5;
		background-color: var(--color-grey-800);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-200);
		}

		.l-container {
			@include size(xxl-up) {
				max-width: 80vw;
			}
		}
	}

	@include size(sm-up) {
		background-image: var(--background-image-url);
	}

	@include size(md-up) {
		align-items: flex-end;
		padding-bottom: $sp9;
		height: 520px;
	}

	@supports (aspect-ratio: 4/3) {
		height: auto;
		aspect-ratio: 4/3;

		@include size(sm-up) {
			aspect-ratio: 16/9;
		}

		@include size(md-up) {
			height: auto;
			aspect-ratio: 16/7;
		}

		@include size(lg-up) {
			aspect-ratio: 36/13;
		}
	}
}

.jumbo-carousel__slide-content-w {
	.jumbo-carousel__slide.has-live-video & {
		display: flex;
		justify-content: space-between;
		gap: $sp5;
	}

	@include size(sm-down) {
		padding-top: 7%;
	}
}

.jumbo-carousel__slide-content-main {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
	flex-grow: 1;
}

.jumbo-carousel__slide-content-video {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	flex-shrink: 0;

	@include size(xs-only) {
		::v-deep {
			.v2-player__video {
				height: 100%;
				object-fit: cover;
				aspect-ratio: 4 / 3;
			}
		}
	}

	@include size(sm-down) {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		z-index: -1;
		justify-content: flex-start;

		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0.2) 0%, var(--color-black) 100%);
		}
	}

	@include size(md-up) {
		flex-basis: 40%;
	}

	@include size(lg-up) {
		flex-basis: 50%;
	}
}

.jumbo-carousel__video-iframe {
	width: 100%;
	aspect-ratio: 16 / 9;
}

.jumbo-carousel__logo {
	max-width: 80px;
	max-height: 60px;
	margin-bottom: $sp3;

	@include size(sm-up) {
		max-width: 130px;
		max-height: 80px;
	}

	@include size(md-up) {
		max-width: 140px;
		max-height: 110px;
		margin-bottom: $sp4;
	}
}

.jumbo-carousel__slide-pretitle-w {
	display: flex;
	align-items: center;
	margin-bottom: $sp2;
	gap: $sp3;
	margin-left: 2px; // optical alignment

	&:empty {
		display: none;
	}
}

// .jumbo-carousel__slide-live-badge {}

.jumbo-carousel__slide-pretitle {
	@include font(primary, book, normal, fs-100);
}

.jumbo-carousel__slide-title {
	margin-bottom: $sp3;

	/* stylelint-disable */
	::v-deep {
		.section-title__text {
			@include size(xs-only) {
				font-size: 25px !important;
				font-weight: 600 !important;
			}
		}
	}
	/* stylelint-enable */
}

/* stylelint-disable */
body.-t-light .jumbo-carousel__slide-content-w {
	::v-deep {
		.jumbo-carousel__slide-content-main,
		.section-title__text {
			color: var(--color-white);
		}
	}
}
/* stylelint-enable */

.jumbo-carousel__slide-subtitle {
	@include font(primary, light, normal, fs-120);
	margin-bottom: $sp3;
	padding-right: 30%;
	margin-left: 1px; // optical alignment

	@include size(sm-up) {
		@include font(primary, light, normal, fs-150);
	}

	@include size(md-up) {
		margin-bottom: $sp5;
	}
}

.jumbo-carousel__dots {
	position: absolute;
	width: 100%;
	bottom: $sp4;
	z-index: 1;

	&.has-live-video {
		@include size(xxl-up) {
			display: none;
		}
	}

	@include size(md-up) {
		bottom: $sp7-5;
	}
}

.jumbo-carousel__dot-w {
	display: inline-block;
	padding: $sp2 0;
	cursor: pointer;

	&:not(:last-child) {
		margin-right: $sp3;
	}

	@include size(md-up) {
		&:not(:last-child) {
			margin-right: $sp2;
		}
	}
}

.jumbo-carousel__dot {
	width: $sp3;
	height: $sp3;
	background-color: var(--color-grey-500);
	border-radius: 50%;
	display: inline-block;

	.jumbo-carousel__dot-w.is-active & {
		background-color: var(--color-grey-200);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-800);
		}
	}

	@include size(md-up) {
		width: $sp5;
		height: $sp2;
		background-color: var(--color-grey-500);
		border-radius: $border-radius--sm;
	}
}

.jumbo-carousel__link {
	.jumbo-carousel__slide-title + & {
		margin-top: $sp4;
	}

	@include size(sm-down) {
		display: none;
	}
}

.jumbo-carousel__mobile-link {
	display: flex;
	align-items: center;
	gap: $sp2;
	position: absolute;
	right: $sp5;
	bottom: $sp4;

	@include size(sm-up) {
		bottom: $sp5;
	}

	@include size(md-up) {
		display: none;
	}
}
</style>
