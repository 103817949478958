<template>
	<div class="fan-account-pw">
		<!-- PW_STATUS.NOT_SET -->
		<template v-if="mode === PW_STATUS.NOT_SET">
			<div
				class="fan-account-pw__copy"
			>
				{{ $I18n.trans('arena.login.login.no_password') }}
			</div>
			<Button
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.request_reset_button'))"
				theme="dark"
				:busy="isLoading"
				@click="requestPwReset()"
			/>
		</template>

		<!-- PW_STATUS.RESET_MAIL_SENT -->
		<InfoBox
			v-else-if="mode === PW_STATUS.RESET_MAIL_SENT"
			:title="$I18n.trans('arena.login.check_email.title')"
			:copy="$I18n.trans('arena.login.check_email.reset_subtitle', { email: profile.email } )"
			:info="$I18n.trans('arena.login.check_email.help')"
		/>

		<!-- PW_STATUS.SET / PW_STATUS.RESET -->
		<form v-else>
			<template v-if="mode === PW_STATUS.SET">
				<InputText
					v-if="mode === PW_STATUS.SET"
					ref="passwordCurrent"
					name="current-password"
					type="password"
					pattern=".{8,}"
					min-length="8"
					:custom-error-msg="$I18n.trans('arena.login.login.password_hint')"
					:label="$I18n.trans('passwords.attributes.current')"
					autocomplete="current-password"
					:dark-theme="!$arenaConfig.isLightTheme"
					required
					:value="passwordCurrent"
					@input="handlePwCurrent"
				/>
				<div class="fan-account-pw__forgot-pw-w">
					<a
						class="fan-account-pw__forgot-pw"
						@click="requestPwReset()"
					>
						{{ $I18n.trans('arena.login.login.forgot_password') }}
					</a>
				</div>
			</template>

			<InputText
				ref="passwordNew"
				name="password"
				type="password"
				pattern=".{8,}"
				min-length="8"
				:custom-error-msg="$I18n.trans('arena.login.login.password_hint')"
				:label="$I18n.trans('passwords.attributes.new')"
				autocomplete="new-password"
				:dark-theme="!$arenaConfig.isLightTheme"
				required
				:value="passwordNew"
				@input="handlePwNew"
			/>

			<InputText
				ref="passwordConfirm"
				name="passwordConfirm"
				type="password"
				pattern=".{8,}"
				min-length="8"
				:custom-error-msg="$I18n.trans('arena.login.login.password_confirm_error')"
				:label="$I18n.trans('passwords.attributes.new_confirm')"
				autocomplete="new-password"
				:dark-theme="!$arenaConfig.isLightTheme"
				required
				:value="passwordConfirm"
				:show-error="showPasswordConfirmError"
				@input="handlePwConfirm"
			/>

			<div
				v-if="!error"
				class="fan-account-pw__hint"
			>
				<V2Icon
					icon-name="c-info"
					size="md"
				/>
				{{ $I18n.trans('arena.login.login.password_hint') }}
			</div>
			<div
				v-if="error === 'InvalidCredentials'"
				class="fan-account-pw__hint -error"
			>
				<V2Icon
					icon-name="c-info"
					size="md"
				/>
				{{ $I18n.trans('arena.login.login.invalid_password') }}
			</div>

			<div class="fan-account-pw__submit-w">
				<Button
					:text="$I18n.trans('commons.type_update', {type: $I18n.trans('commons.password')})"
					:disabled="!formIsValid"
					:busy="isLoading"
					theme="dark"
					@click="handleSubmit()"
				/>
			</div>
		</form>
	</div>
</template>

<script>
import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';
import InputText from '@shared/components/Form/InputText';
import InfoBox from '@js/components/InfoBox';
import { authApi } from '@js/api/client';
import laroute from '@laroute';
import { mapState } from 'vuex';

export const PW_STATUS = {
	NOT_SET:         'notSet',
	RESET_MAIL_SENT: 'resetSent',
	RESET:           'reset',
	SET:             'set',
};

export default {
	name:       'FanAccountPassword',
	components: { Button, InputText, V2Icon, InfoBox },
	props:      {
		pwIsSet: {
			type:     Boolean,
			required: true,
		},
	},
	data() {
		return {
			passwordCurrent: '',
			passwordNew:     '',
			passwordConfirm: '',
			error:           null,
			token:           null,
			formIsValid:     false,
			isLoading:       false,
			mode:            null,
			PW_STATUS,
		};
	},
	computed: {
		...mapState({
			profile: state => state.user.profile,
		}),
		showPasswordConfirmError() {
			return !!this.passwordConfirm && !!this.passwordNew && this.passwordConfirm !== this.passwordNew && this.passwordConfirm.length >= 8;
		},
	},
	created() {
		this.mode = this.pwIsSet ? PW_STATUS.SET : PW_STATUS.NOT_SET;
		this.checkPwResetToken();
	},
	methods: {
		async requestPwReset() {
			try {
				this.isLoading = true;
				await authApi.post(laroute.route('arena.api.arena_login.request_reset'), {
					email:  this.profile.email,
					target: 'fan_account',
				});
				this.mode = PW_STATUS.RESET_MAIL_SENT;
			} catch (error) {
				this.$toast.error(this.$I18n.trans('errors.500.title'));
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
		async resetPassword() {
			try {
				this.isLoading = true;
				const response = await authApi.post(laroute.route('arena.api.arena_login.reset', {
					email:    encodeURIComponent(this.profile.email),
					token:    this.token,
					password: this.passwordNew,
				}));
				const responseContent = await response.json();
				if (responseContent.status === 'ok') {
					this.$toast.success(this.$I18n.trans('notifications.password_changed'));
					this.mode = PW_STATUS.SET;
					this.$emit('password-set');
					this.resetInput();
					// remove token from url
					window.history.replaceState({}, document.title, window.location.pathname);
				} else if (responseContent && responseContent.reason) {
					this.error = responseContent.reason;
				}
			} catch (error) {
				this.$toast.error(this.$I18n.trans('errors.500.title'));
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
		async resetPasswordLoggedIn() {
			try {
				this.isLoading = true;
				const response = await authApi.post(laroute.route('arena.api.arena_login.reset_logged_in', {
					currentPassword: this.passwordCurrent,
					newPassword:     this.passwordNew,
				}));
				const responseContent = await response.json();
				if (responseContent.status === 'ok') {
					this.$toast.success(this.$I18n.trans('notifications.password_changed'));
					this.resetInput();
				} else if (responseContent && responseContent.reason) {
					this.error = responseContent.reason;
				}
			} catch (error) {
				this.$toast.error(this.$I18n.trans('errors.500.title'));
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
		checkPwResetToken() {
			const urlParams = new URLSearchParams(window.location.search);
			const token = urlParams.get('token');

			if (token) {
				this.mode = PW_STATUS.RESET;
				this.token = token;
			}
		},
		checkFormValidity() {
			if (this.passwordNew !== this.passwordConfirm) {
				this.formIsValid = false;
			}
			if (this.mode === PW_STATUS.SET) {
				this.formIsValid = this.$refs.passwordCurrent?.isValid && this.$refs.passwordNew?.isValid && this.$refs.passwordConfirm?.isValid;
			} else {
				this.formIsValid = this.$refs.passwordNew?.isValid && this.$refs.passwordConfirm?.isValid;
			}
		},
		resetInput() {
			this.passwordCurrent = '';
			this.passwordNew = '';
			this.passwordConfirm = '';
			this.checkFormValidity();
			this.$nextTick(() => {
				this.$refs.passwordCurrent?.resetFocus();
				this.$refs.passwordNew?.resetFocus();
				this.$refs.passwordConfirm?.resetFocus();
			});
		},
		handleSubmit() {
			if (this.mode === PW_STATUS.RESET) {
				this.resetPassword();
			} else {
				this.resetPasswordLoggedIn();
			}
		},
		handlePwCurrent(data) {
			this.passwordCurrent = data;
			this.checkFormValidity();
		},
		handlePwNew(data) {
			this.passwordNew = data;
			this.checkFormValidity();
		},
		handlePwConfirm(data) {
			this.passwordConfirm = data;
			this.checkFormValidity();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.fan-account-pw {
	position: relative;
}

.fan-account-pw__forgot-pw-w {
	text-align: right;
}

.fan-account-pw__forgot-pw {
	text-align: right;
	color: var(--color-hype-yellow);
}

.fan-account-pw__hint {
	margin-bottom: $sp6;
	display: flex;
	align-items: center;
	gap: $sp3;

	&.-error {
		color: var(--color-red);
	}
}

.fan-account-pw__copy {
	margin-bottom: $sp6;

	&.-small {
		@include font-size(fs-80);
		color: var(--color-grey-500);
	}
}

.fan-account-pw__submit-w {
	display: flex;
	justify-content: flex-end;
}
</style>
