<template>
	<div class="event-calendar">
		<div class="event-calendar__title-w">
			<h2 class="event-calendar__title">
				{{ $I18n.trans('commons.calendar'), }}
			</h2>
			<h3 class="event-calendar__month">
				{{ currentMonth }}
			</h3>
		</div>
		<Carousel
			:cells="dates"
			class="event-calendar__carousel"
			initial-index=".is-centered"
			:group-cells="carouselSettings.groupCells"
			@change="updateMonth"
		>
			<template #cell="{cell}">
				<div
					class="event-calendar__date-w"
					:class="{'is-centered': cell.index === carouselSettings.startIndex}"
				>
					<div
						class="event-calendar__month -floating"
						:class="{'is-visible': cell.date === '01' && ((currentIndex + 1) < cell.index) }"
					>
						{{ cell.month }}
					</div>
					<div
						class="event-calendar__date"
						:class="{
							'is-today': cell.isToday,
							'has-event': cell.event_count > 0,
							'is-selected': cell.timestamp === value
						}"
						@click="cell.event_count > 0 && $emit('change', cell.timestamp)"
					>
						{{ cell.date }}
						<span
							v-if="!cell.isToday"
							class="event-calendar__weekday"
						>{{ cell.day }}</span>
					</div>
				</div>
			</template>
		</Carousel>
		<div
			v-if="value !== null"
			class="event-calendar__reset"
			@click="$emit('change', null)"
		>
			{{ $I18n.trans('commons.reset_date') }}
		</div>
	</div>
</template>

<script>
import Carousel from '@js/components/Carousel';

export default {
	name:       'EventCalendar',
	components: { Carousel },
	props:      {
		eventDates: {
			type:     Array,
			required: true,
		},
		value: {
			type:    [Number, null],
			default: null,
		},
	},
	data() {
		return {
			currentMonth: null,
			currentIndex: null,
		};
	},
	computed: {
		dates() {
			return this.eventDates.map((entry, index) => {
				const momentDate = moment(entry[0]);
				const eventCount = entry[1];
				if (momentDate.isSame(moment(), 'day')) {
					return {
						isToday:     true,
						date:        this.$I18n.trans('commons.today_abbreviation'),
						timestamp:   momentDate.unix(),
						index,
						month:       momentDate.format('MMMM'),
						event_count: eventCount,
					};
				} else {
					return {
						moment:      momentDate,
						timestamp:   momentDate.unix(),
						index,
						day:         momentDate.format('dd'),
						month:       momentDate.format('MMMM'),
						date:        momentDate.format('DD'),
						event_count: eventCount,
					};
				}
			});
		},
		carouselSettings() {
			switch (true) {
			case window.innerWidth < 500:
				return {
					startIndex: 30,
					groupCells: 2,
				};

			case window.innerWidth < 700:
				return {
					startIndex: 28,
					groupCells: 2,
				};

			case window.innerWidth < 900:
				return {
					startIndex: 28,
					groupCells: 2,
				};

			case window.innerWidth < 1100:
				return {
					startIndex: 28,
					groupCells: 2,
				};

			case window.innerWidth < 1300:
				return {
					startIndex: 28,
					groupCells: 3,
				};

			case window.innerWidth < 1500:
				return {
					startIndex: 25,
					groupCells: 3,
				};

			default:
				return {
					startIndex: 25,
					groupCells: 3,
				};
			}
		},
	},
	mounted() {
		this.currentMonth = this.dates[this.carouselSettings.startIndex].month;
		this.currentIndex = this.dates[this.carouselSettings.startIndex].index;
	},
	methods: {
		updateMonth(cellGroup) {
			this.currentIndex = this.dates[cellGroup * this.carouselSettings.groupCells].index;
			const newMonth = this.dates[(cellGroup * this.carouselSettings.groupCells) + 1]?.month;
			if (newMonth) {
				this.currentMonth = newMonth;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.event-calendar {
	position: relative;
	margin-bottom: $sp6;
}

.event-calendar__title-w {
	position: relative;
	margin-bottom: $sp5;
	display: inline-block;
	padding-right: $sp3;
	z-index: 2;
}

.event-calendar__title {
	@include font-size(fs-150);
	display: inline-block;
	margin-right: $sp5;
}

.event-calendar__carousel {
	margin-top: -44px;

	.flickity-button {
		top: calc(50% + 20px) !important; // stylelint-disable-line declaration-no-important
	}
}

.event-calendar__carousel::v-deep .flickity-viewport {
	margin: -8px 0 -8px -4px;
}

.event-calendar__carousel::v-deep .flickity-button {
	top: calc(50% + 18px);
}

.event-calendar__month {
	display: inline-block;
	color: var(--color-grey-400);

	&.-floating {
		position: absolute;
		top: -40px;
		left: $sp2;
		opacity: 0;
		transition: opacity $trans-time-fast;

		&.is-visible {
			opacity: 1;
		}
	}
}

.event-calendar__date-w {
	margin-top: 40px;
	margin-bottom: $sp2;
}

.event-calendar__date {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 70px;
	height: 68px;
	font-size: 26px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	font-weight: 500; // stylelint-disable-line property-disallowed-list
	background-color: var(--color-grey-900);
	border: 1px solid var(--color-grey-800);
	border-radius: $border-radius--sm;
	margin-right: $sp2;
	margin-left: $sp2;
	transform: scale(1);
	transition: background-color $trans-time-fast,  transform $trans-time-fast;
	color: var(--color-grey-700);

	&.is-today {
		font-size: 18px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	}

	&.has-event {
		color: var(--color-soft-white);

		&:hover {
			background-color: var(--color-grey-850);
			transform: scale(1.075);
		}
	}

	&.is-selected {
		border-color: var(--color-hype-yellow);
	}

	@include size(xs-only) {
		margin-right: calc($grid-gutter-width--xs / 2);
		margin-left: calc($grid-gutter-width--xs / 2);
	}

	@include size(sm-up) {
		width: 100px;
		height: 96px;
		font-size: 40px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

		&.is-today {
			font-size: 24px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		}
	}
}

.event-calendar__weekday {
	font-size: 16px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	font-weight: 400; // stylelint-disable-line property-disallowed-list
	color: var(--color-grey-700);

	.event-calendar__date.has-event & {
		color: var(--color-grey-300);
	}
}

.event-calendar__reset {
	@include font-size(fs-120);
	color: var(--color-hype-yellow);
	cursor: pointer;
	position: absolute;
	right: 0;
	bottom: -40px;
}
</style>
