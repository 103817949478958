<template>
	<Layout>
		<NavBack
			:link="backUrl"
			:title="$I18n.trans('commons.back')"
		/>
		<div class="js-keynav-section">
			<JumbotronPlayer
				:player-image-url="playerImageUrl"
				:club-logo-url="clubLogoUrl"
				:title="title"
				:team-name="teamName"
				:team-link="teamLink"
				:club-name="clubName"
				:club-link="clubLink"
			/>
		</div>

		<div class="l-container">
			<div
				v-for="lane in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
				/>
				<AdLane
					v-if="lane.lane_type === ArenaLaneType.AD && lane.visible"
					:ad="lane.ad"
				/>
			</div>
			<EmptyState
				v-if="noEvents"
				:title="$I18n.trans_choice('commons.event', 2)"
				:text="$I18n.trans('arena.placeholder.replays')"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import EventLane from '@js/components/EventLane';
import AdLane from '@js/components/AdLane';
import NavBack from '@js/components/NavBack';
import JumbotronPlayer from '@js/components/JumbotronPlayer';
import EmptyState from '@js/components/EmptyState';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { laneMixer } from '@js/utils/laneMixer';

export default {
	name:       'ViewPlayer',
	components: { Layout, EventLane, AdLane, JumbotronPlayer, EmptyState, NavBack },
	props:      {
		playerImageUrl: {
			type:     String,
			required: true,
		},
		clubLogoUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:     String,
			required: true,
		},
		teamName: {
			type:    String,
			default: null,
		},
		teamLink: {
			type:    String,
			default: null,
		},
		clubName: {
			type:    String,
			default: null,
		},
		clubLink: {
			type:    String,
			default: null,
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
		backUrl: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			ArenaLaneType,
		};
	},
	computed: {
		mixedLanes() {
			// On Player Context lanes don't exist so we add them here manually
			const lanes = [
				{
					lane_type:   ArenaLaneType.RECOMMENDATIONS,
					visible:     true,
					highlighted: true,
				},
				{
					lane_type:   ArenaLaneType.LIVE_AND_UPCOMING,
					visible:     true,
					highlighted: false,
				},
				{
					lane_type:   ArenaLaneType.REPLAYS,
					visible:     true,
					highlighted: false,
				},
				{
					lane_type:   ArenaLaneType.VIDEOS,
					visible:     true,
					highlighted: false,
				},
			];
			return laneMixer(
				this.albums,
				lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
			);
		},
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
	},
};
</script>

<style>

</style>
