<template>
	<div>
		<SectionTitle
			class="vc-header__title"
			:title="title"
			type="main"
		>
			<template #outer>
				<div
					v-if="mobileFilters.length > 0"
					class="vc-header__mobile-filter-toggle"
					:class="{'is-filtered': mobileFilterIsFiltered }"
					@click="openMobileFilter"
				>
					<Icon
						icon-name="filter"
						size="lg"
					/>
				</div>
			</template>
		</SectionTitle>

		<MobileFilter
			v-if="mobileFilters.length > 0"
			ref="mobileFilter"
			:filters="mobileFilters"
			@changeSelect="handleMobileSelectChange"
			@changeToggle="handleMobileToggleChange"
			@reset="handleMobileReset"
		/>

		<div class="vc-header__desktop-filter-w">
			<Select
				v-for="select in selects"
				:key="select.id"
				class="vc-header__select"
				:options="select.options"
				:value="select.value"
				:clearable="false"
				:dark-theme="!$arenaConfig.isLightTheme"
				@change="(value) => handleSelectChange(select, value)"
			/>

			<ToggleButtonGroup
				v-for="toggle in toggles"
				:key="toggle.id"
				:can-emit-change="false"
				:name="`${toggle.id}_desktop`"
				:unique-id="`${toggle.id}_desktop`"
				:buttons="toggle.options"
				:selected-id="toggle.value"
				:full-width="true"
				class="vc-header__toggle"
				:theme="$arenaConfig.isLightTheme ? null : 'dark'"
				@change="(value) => handleToggleChange(toggle, value)"
			/>
			<InputText
				v-if="search"
				:dark-theme="!$arenaConfig.isLightTheme"
				type="search"
				:placeholder="$Helpers.ucfirst($I18n.trans('commons.search')) + '...'"
				class="vc-header__search"
				:value="search.value"
				@input="(value) => handleSearchChange(search, value)"
			/>
		</div>
		<div
			v-if="calendar"
			class="vc-header__calendar-w"
		>
			<EventCalendar
				:event-dates="calendar.dates"
				:value="calendar.value"
				@change="(value) => handleCalendarChange(calendar, value)"
			/>
		</div>
	</div>
</template>

<script>
import EventCalendar from '@js/components/EventCalendar';
import Select from '@shared/components/Select';
import SectionTitle from '@js/components/SectionTitle';
import MobileFilter from '@js/components/MobileFilter';
import Icon from '@shared/components/Icon';
import InputText from '@shared/components/Form/InputText';
import ToggleButtonGroup from '@shared/components/ToggleButtonGroup';

export default {
	name:       'VideoCenterHeader',
	components: { SectionTitle, MobileFilter, Select, InputText, ToggleButtonGroup, EventCalendar, Icon },
	props:      {
		title: {
			type:     String,
			required: true,
		},
		filters: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	computed: {
		selects() {
			return this.filters.filter(filter => filter.type === 'select');
		},
		toggles() {
			return this.filters.filter(filter => filter.type === 'toggle');
		},
		search() {
			return this.filters.find(filter => filter.type === 'search');
		},
		calendar() {
			return this.filters.find(filter => filter.type === 'calendar');
		},
		mobileFilters() {
			return [
				...this.selects,
				...this.toggles,
			];
		},
		mobileFilterIsFiltered() {
			return this.mobileFilters.some(filter => filter.value !== filter.defaultValue);
		},
	},
	methods: {
		handleMobileSelectChange(select, value) {
			this.handleSelectChange(select, value);
		},
		handleMobileToggleChange(toggle, value) {
			this.handleToggleChange(toggle, value);
		},
		handleSelectChange(select, value) {
			this.$emit(select.emitEvent, value);
		},
		handleToggleChange(toggle, value) {
			this.$emit(toggle.emitEvent, value.id);
		},
		handleCalendarChange(calendar, value) {
			this.$emit(calendar.emitEvent, value);
		},
		handleSearchChange(search, value) {
			this.$emit(search.emitEvent, value);
		},
		handleMobileReset() {
			this.filters.forEach(filter => {
				this.$emit(filter.emitEvent, filter.defaultValue);
			});
		},
		openMobileFilter() {
			this.$refs.mobileFilter.open();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

// .vc-header__title {}

.vc-header__mobile-filter-toggle {
	cursor: pointer;

	&.is-filtered {
		color: var(--color-hype-yellow);
	}

	@include size(md-up) {
		display: none;
	}
}

.vc-header__desktop-filter-w {
	display: flex;
	flex-wrap: wrap;
	gap: $grid-gutter-width;
}

.vc-header__select {
	margin-bottom: 0;
	flex-basis: 25%;

	@include size(sm-down) {
		display: none;
	}
}

.vc-header__toggle {
	flex-grow: 1;
	min-width: 33%;

	@include size(sm-down) {
		display: none;
	}
}

.vc-header__search {
	flex-grow: 1;
	margin-bottom: $sp5;
}

.vc-header__calendar-w {
	margin-bottom: $sp8;
}
</style>
