import smoothscroll from 'smoothscroll-polyfill';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';

// blueTV runs on Chrome 58 which does not fully support scrollTo or scrollIntoView
smoothscroll.polyfill();

const jsSelector = 'js-keynav-section';
const cssActiveClass = 'is-keynav-section-active';
const events = {
	ARROWLEFT:    'previous',
	ARROWRIGHT:   'next',
	ENTER:        'clickSelected',
	UNIDENTIFIED: 'clickSelected', // on IP1400, the key code for OK is not ENTER but UNIDENTIFIED
};

export default {
	currentIndex: -1,
	vueInstance:  null,
	getSectionedComponents() {
		// get all vue components that have the css class for key navigation in their parent and then order components by their DOM occurrence
		const allCarouselComponents = [];
		const getCarouselComponent = (component) => {
			if (component.$options?.name === 'Carousel' && component.$el?.closest(`.${jsSelector}`) !== null) {
				allCarouselComponents.push(component);
			}

			if (component.$children) {
				component.$children.forEach(child => {
					getCarouselComponent(child);
				});
			}
		};

		getCarouselComponent(this.vueInstance);

		return allCarouselComponents.sort((a, b) =>
			a.$el.compareDocumentPosition(b.$el) & Node.DOCUMENT_POSITION_FOLLOWING ? -1 : 1
		);
	},
	handleBackNavigation() {
		const params = new URLSearchParams(window.location.search);
		const entryUrl = params?.get('entryUrl'); // todo: implement domain whitelist

		// When navigating forward to the same page we've been previoiusly, the scroll position
		// is at the top hence we have to remove the index
		localStorage.removeItem(`BEFORE_NAV_INDEX_${window.location.pathname}`, this.currentIndex);
		if (entryUrl) {
			window.location.href = entryUrl;
		} else if (window.isHome) {
			window.close();
		} else {
			window.history.back();
		}
	},
	init(instance) {
		this.vueInstance = instance;

		const sectionedComponents = this.getSectionedComponents();
		const userNavigatedBack = window.performance?.navigation?.type === 2;
		const previousIndex = userNavigatedBack && localStorage.getItem(`BEFORE_NAV_INDEX_${window.location.pathname}`);
		if (previousIndex) {
			this.currentIndex = parseInt(previousIndex);
			const component = sectionedComponents[this.currentIndex];

			if (component) {
				component.$el.parentElement.classList.add(cssActiveClass);
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		window.addEventListener('message', event => {
			if (event.data.command === 'navigate:Back') {
				this.handleBackNavigation();
			}
		});
		document.addEventListener('keydown', event => {
			const key = event.key?.toUpperCase();
			if (!key) {
				return;
			}
			const loginLogoutButton = document.querySelector('.js-smart-tv-login-logout-button');
			if (key === 'ARROWDOWN' && loginLogoutButton) {
				loginLogoutButton.blur();
			}

			if (key === 'GOBACK') {
				// Let the player handle GOBACK navigation on event page
				if (window.location.pathname.includes('event')) {
					return;
				}
				this.handleBackNavigation();
			}

			if (key === 'ARROWUP') {
				if (this.currentIndex === -1) { // already above first component
					if (loginLogoutButton) {
						loginLogoutButton.focus();
					} else if (window.scrollY > 0) {
						window.scrollTo({ top: 0, behavior: 'smooth' });
						event.preventDefault();
					}
					return;
				} else if (this.currentIndex === 0) { // at first component, but trying to navigate further to the top
					window.scrollTo({ top: 0, behavior: 'smooth' });
					sectionedComponents.forEach(c => {
						c.$el.parentElement.classList.remove(cssActiveClass);
					});
					this.currentIndex--;
					event.preventDefault();
					return;
				} else if (this.currentIndex > 0) {
					this.currentIndex--;
				}
			}
			if (key === 'ARROWDOWN' && this.currentIndex < sectionedComponents.length - 1) {
				this.currentIndex++;
			}

			const component = sectionedComponents[this.currentIndex];

			if (component) {
				component.$el.parentElement.classList.add(cssActiveClass);

				// disable other keynav components
				sectionedComponents.forEach(c => {
					if (c._uid !== component._uid) {
						c.$el.parentElement.classList.remove(cssActiveClass);
					}
				});

				if (key === 'ARROWUP' || key === 'ARROWDOWN') {
					scrollIntoView(component.$el.parentElement, { block: 'center', behavior: 'smooth' });
					event.preventDefault();
				}

				const method = events[key];
				if (method && component[method]) {
					localStorage.setItem(`BEFORE_NAV_INDEX_${window.location.pathname}`, this.currentIndex);
					component[method]();
					event.preventDefault();
				}
			} else if (key === 'ENTER' && loginLogoutButton && (document.activeElement === loginLogoutButton)) {
				loginLogoutButton.click();
			}
		});
	},
};
