<template>
	<Layout>
		<NavBack
			:link="backUrl"
			:title="$I18n.trans('commons.back')"
		/>
		<PaymentModal />
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import PaymentModal from '@js/components/Payments/PaymentModal';
import NavBack from '@js/components/NavBack';
import { mapState, mapGetters } from 'vuex';

export default {
	name:       'ViewEvent',
	components: { Layout, PaymentModal, NavBack },
	props:      {
	},
	data() {
		return {
			backUrl: window.location.protocol + '//' + window.arenaDomain,
		};
	},
	computed: {
		...mapState({
			profileIsInitialized: state => state.user.isInitialized,
		}),
		...mapGetters({
			hasAccessToEvent: 'user/hasAccessToEvent',
			purchasedEvents:  'user/purchasedEvents',
			subscriptions:    'user/subscriptions',
			isLoggedIn:       'user/isLoggedIn',
			context:          'user/context',
		}),
	},
	watch: {
		profileIsInitialized() {
			const defaultAction = this.isLoggedIn ? 'checkout' : 'login';
			const action = window.location.hash.replace('#', '').split('/qr/')[0] || defaultAction;

			this.eventBus.$emit('modal:PaymentModal', {
				action,
			});
		},
	},
};
</script>

<style>

</style>
