<template>
	<Modal
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		modal-name="PasswordResetModal"
		:closeable="false"
	>
		<template #title>
			{{ $I18n.trans('arena.login.reset.title') }}
		</template>
		<template #content>
			<div class="arena-reset-modal">
				<template
					v-if="!success"
				>
					<div class="arena-reset-modal__head">
						<h2 class="arena-reset-modal__title">
							{{ $I18n.trans('arena.login.reset.headline') }}
						</h2>
						<span class="arena-reset-modal__subtitle">{{ $I18n.trans('arena.login.reset.subheadline') }}</span>
					</div>
					<div
						v-if="error"
						class="arena-reset-modal__hint -error -no-icon"
					>
						<template v-if="errorReason && errorReason === 'InvalidToken'">
							{{ $I18n.trans('arena.login.reset.invalid_token') }}
						</template>
						<template v-else>
							{{ $I18n.trans('arena.login.reset.error') }}
						</template>
					</div>
					<InputText
						ref="email"
						name="email"
						type="text"
						pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
						required
						:label="$I18n.trans('arena.login.login.email')"
						:placeholder="$I18n.trans('arena.login.login.email')"
						:dark-theme="!$arenaConfig.isLightTheme"
						:value="email"
						@input="(data) => email = data"
					/>
					<InputText
						ref="password"
						name="password"
						type="password"
						pattern=".{8,}"
						:custom-error-msg="$I18n.trans('arena.login.login.password_hint')"
						required
						:label="$I18n.trans('arena.login.login.password')"
						:placeholder="$I18n.trans('arena.login.login.password')"
						:dark-theme="!$arenaConfig.isLightTheme"
						:value="password"
						@input="(data) => password = data"
					/>
					<div
						class="arena-reset-modal__hint"
					>
						<V2Icon
							icon-name="c-info"
							size="md"
							class="arena-reset-modal__hint-icon"
						/>
						{{ $I18n.trans('arena.login.login.password_hint') }}
					</div>
					<InputText
						ref="passwordConfirm"
						name="passwordConfirm"
						type="password"
						:custom-error-msg="$I18n.trans('arena.login.login.password_confirm_error')"
						required
						:label="$I18n.trans('arena.login.login.password_confirm')"
						:placeholder="$I18n.trans('arena.login.login.password_confirm')"
						:dark-theme="!$arenaConfig.isLightTheme"
						:value="passwordConfirm"
						:show-error="submitted && passwordConfirm && passwordConfirm !== password"
						@input="(data) => passwordConfirm = data"
					/>
					<Button
						class="arena-reset-modal__continue"
						:text="$Helpers.ucfirst($I18n.trans('arena.login.reset.reset'))"
						:disabled="!($refs.password && $refs.password.isValid && password === passwordConfirm) || inProgress"
						:theme="$arenaConfig.isLightTheme ? null : 'dark'"
						@click="resetPassword"
					/>
					<a
						:href="laroute.route('arena.login')"
					>
						<Button
							class="arena-reset-modal__back"
							:text="$Helpers.ucfirst($I18n.trans('arena.login.login.back'))"
							:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							modifier="borderless"
						/>
					</a>
				</template>
				<template v-else>
					<div class="arena-reset-modal__message">
						<V2Icon
							icon-name="fireworks"
							size="xxxl"
							class="arena-reset-modal__info-icon"
						/>
						<h2 class="arena-reset-modal__title">
							{{ $I18n.trans('arena.login.reset.success.title') }}
						</h2>
						<!-- eslint-disable vue/no-v-html -->
						<div
							class="arena-reset-modal__info"
							v-html="$I18n.trans('arena.login.reset.success.subtitle' )"
						/>
						<a
							:href="laroute.route('arena.login')"
						>
							<Button
								class="arena-reset-modal__continue"
								:text="$Helpers.ucfirst($I18n.trans('arena.login.reset.success.button'))"
								:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							/>
						</a>
					</div>
				</template>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@shared/components/Modal';
import InputText from '@shared/components/Form/InputText';
import laroute from '@laroute';
import Button from '@shared/components/Button';

import { authApi } from '../../api/client';

export default {
	name:       'PasswordResetModal',
	components: { Modal, InputText, Button },
	props:      {
		token: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			submitted:       false,
			inProgress:      false,
			password:        null,
			passwordConfirm: null,
			email:           null,
			error:           null,
			errorReason:     null,
			success:         false,
			laroute,
		};
	},
	methods: {
		onSubmit() {
			this.submitted = true;
		},
		async resetPassword() {
			this.invalidPassword = false;
			this.inProgress = true;
			this.error = false;
			this.errorReason = null;

			try {
				const body = {
					email:    this.email,
					password: this.password,
					token:    this.token,
				};

				const response = await authApi.post(
					laroute.route('arena.api.arena_login.reset'),
					body,
				);
				const responseContent = await response.json();

				if (responseContent.status === 'ok') {
					this.success = true;
				} else {
					this.error = true;
					this.errorReason = responseContent.reason;
				}
			} catch (error) {
				this.error = true;
				console.error(error);
			} finally {
				this.inProgress = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.arena-reset-modal {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.arena-reset-modal__continue {
	width: 100%;
}

.arena-reset-modal__head {
	text-align: center;
	margin-bottom: 50px;
}

.arena-reset-modal__title {
	@include font(primary, light, normal, fs-150);
	margin-top: 45px;
	margin-bottom: 8px;
}

.arena-reset-modal__subtitle {
	color: var(--color-grey-300);
}

.arena-reset-modal__hint {
	position: relative;
	color: var(--color-grey-300);
	padding-left: 30px;
	margin-bottom: 20px;

	&.-error {
		color: var(--color-red);
	}

	&.-no-icon {
		padding-left: 0;
	}
}

.arena-reset-modal__hint-icon {
	position: absolute;
	top: 0;
	left: 0;
}

.arena-reset-modal__back {
	margin-left: auto;
	margin-right: auto;
	margin-top: 30px;
	display: flex;
}

.arena-reset-modal__message {
	@include font(primary, book, normal, fs-120);
	text-align: center;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
}

.arena-reset-modal__info {
	@include font(primary, book, normal, fs-120);
	margin-bottom: 48px;
}

.arena-reset-modal__title {
	@include font(primary, light, normal, fs-150);
	margin-bottom: 16px;
}

.arena-reset-modal__info-icon {
	margin-top: 40px;
	margin-bottom: 40px;
}

.arena-reset-modal__continue {
	margin-top: 35px;
}
</style>
