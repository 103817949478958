<template>
	<Modal
		modal-name="ContactForm"
		type="dialog-md"
		:theme="darkTheme ? 'dark' : null"
		class="contact-form"
		@closing-modal="handleModalClose"
	>
		<template #title>
			<!-- eslint-disable vue/no-v-html -->
			<span
				v-if="values.topic"
				v-html="topics.find((topic) => topic.name === values.topic).text"
			/>
			<template v-else>
				{{ isSwissLeague ? $I18n.trans('swiss_league_infos.contact_form.title') : $I18n.trans('commons.contact') }}
			</template>
		</template>
		<template #content>
			<div v-if="submitted">
				{{ $I18n.trans('notifications.contact_form_confirmation_msg') }}
			</div>
			<template v-else>
				<div
					v-if="isSwissLeague && step === 1"
					class="contact-form__topic-select"
				>
					<div
						v-for="topic in topics"
						:key="topic.name"
						class="contact-form__topic"
						@click="selectTopic(topic.name)"
					>
						<Icon
							class="contact-form__topic-icon"
							:icon-name="topic.icon"
							size="xxxl"
						/>
						<!-- eslint-disable vue/no-v-html -->
						<div
							class="contact-form__topic-text"
							v-html="topic.text"
						/>
					</div>
				</div>

				<form
					v-else-if="(isSwissLeague && step === 2) || !isSwissLeague"
					ref="form"
				>
					<Select
						v-if="isSwissLeague && values.topic === 'ppv'"
						:label="$I18n.trans_choice('commons.team', 1)"
						:options="teams.map((team) => ({ label: team, value: team }))"
						:value="values.team"
						:searchable="false"
						:clearable="false"
						:placeholder="$I18n.trans('commons.type_select', {type: $I18n.trans_choice('commons.team', 1)})"
						required
						dark-theme
						@change="values.team = $event"
					/>
					<div class="l-row">
						<div class="l-col l-col--6">
							<InputText
								name="firstName"
								type="text"
								required
								:label="$I18n.trans('validation.attributes.first_name')"
								:dark-theme="darkTheme"
								:value="values.firstName"
								@input="(data) => values.firstName = data"
							/>
						</div>
						<div class="l-col l-col--6">
							<InputText
								name="lastName"
								type="text"
								required
								:label="$I18n.trans('validation.attributes.last_name')"
								:dark-theme="darkTheme"
								:value="values.lastName"
								@input="(data) => values.lastName = data"
							/>
						</div>
					</div>
					<InputText
						name="email"
						type="email"
						required
						:label="$I18n.trans('validation.attributes.email')"
						:dark-theme="darkTheme"
						:value="values.email"
						@input="(data) => values.email = data"
					/>
					<InputText
						name="phone"
						type="tel"
						:label="$I18n.trans('validation.attributes.phone')"
						:dark-theme="darkTheme"
						:value="values.phone"
						@input="(data) => values.phone = data"
					/>
					<InputText
						name="message"
						type="textarea"
						required
						:label="$I18n.trans('validation.attributes.message')"
						:dark-theme="darkTheme"
						:value="values.message"
						@input="(data) => values.message = data"
					/>
				</form>
			</template>
		</template>
		<template #footer>
			<div
				v-if="submitted"
				class="l-button-group"
			>
				<Button
					:text="$Helpers.ucfirst($I18n.trans('commons.close'))"
					:theme="darkTheme ? 'dark' : null"
					@click="closeModal"
				/>
			</div>
			<div
				v-else
				class="l-button-group"
			>
				<Button
					v-if="isSwissLeague && step === 2"
					:text="$Helpers.ucfirst($I18n.trans('commons.back'))"
					modifier="secondary"
					:disabled="isBusy"
					:theme="buttonTheme"
					@click="step = 1"
				/>
				<Button
					v-else
					:text="$Helpers.ucfirst($I18n.trans('commons.cancel'))"
					modifier="secondary"
					:disabled="isBusy"
					:theme="buttonTheme"
					@click="closeModal"
				/>
				<Button
					:text="$Helpers.ucfirst($I18n.trans('commons.send'))"
					:theme="buttonTheme"
					:disabled="!formIsValid"
					:busy="isBusy"
					@click="submitForm"
				/>
			</div>
		</template>
	</Modal>
</template>

<script>
import Button from '@shared/components/Button';
import Icon from '@shared/components/Icon';
import Modal from '@shared/components/Modal';
import InputText from '@shared/components/Form/InputText';
import Select from '@shared/components/Select';
import laroute from '@laroute';

export default {
	name:       'ContactForm',
	components: { Modal, Button, Icon, Select, InputText },
	props:      {
		darkTheme: {
			type:    Boolean,
			default: true,
		},
		contactSource: {
			type:    String,
			default: 'Arena',
		},
		contactEmail: {
			type:     String, // can be multiple with comma separation
			required: true,
		},
		isSwissLeague: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			values: {
				message:      '',
				firstName:    '',
				lastName:     '',
				email:        '',
				phone:        '',
				topic:        null,
				team:         null,
				url:          window.location.href,
				userAgent:    navigator.userAgent,
				windowWidth:  window.innerWidth.toString(),
				contactEmail: this.contactEmail,
			},
			submitted: false,
			isBusy:    false,
			// Swiss League specific
			step:      1,
			topics:    [
				{
					name: 'ppv',
					icon: 'account-circle',
					text: this.$I18n.trans('swiss_league_infos.contact_form.topics.ppv'),
				},
				{
					name: 'tv',
					icon: 'tv',
					text: this.$I18n.trans('swiss_league_infos.contact_form.topics.tv'),
				},
				{
					name: 'technical',
					icon: 'settings-gear',
					text: this.$I18n.trans('swiss_league_infos.contact_form.topics.technical'),
				},
				{
					name: 'other',
					icon: 'comment',
					text: this.$I18n.trans('swiss_league_infos.contact_form.topics.other'),
				},
			],
			teams: [
				'EHC Basel',
				'Bellinzona Rockets',
				'HC La Chaux-de-Fonds',
				'GCK Lions',
				'HCV Martigny',
				'EHC Olten',
				'HC Sierre',
				'HC Thurgau',
				'EHC Visp',
				'EHC Winterthur',
			],
		};
	},
	computed: {
		formIsValid() {
			const baseValidation =
				this.values.feedback !== '' &&
				this.values.message !== '' &&
				this.values.firstName !== '' &&
				this.values.lastName !== '' &&
				this.values.email !== '';

			if (this.isSwissLeague && this.values.topic === 'ppv') {
				return baseValidation && this.values.team !== null;
			}

			return baseValidation;
		},
		buttonTheme() {
			if (this.$arenaConfig.isLightTheme) {
				return 'dark';
			}
			return this.darkTheme ? 'dark' : null;
		},
	},

	methods: {
		closeModal() {
			this.eventBus.$emit('modalClose:ContactForm');
		},
		selectTopic(topic) {
			this.values.topic = topic;
			this.step = 2;
		},
		async submitForm() {
			if (this.formIsValid) {
				try {
					this.isBusy = true;
					const response = await fetch(laroute.route('api.contact'), {
						method:  'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify({
							...this.values,
							isSwissLeague: this.isSwissLeague,
							contactSource: this.contactSource,
							domainKey:     this.$arenaConfig?.domainKey ?? null,
						}),
					});

					if (!response.ok) {
						throw new Error(response.statusText);
					}
					this.submitted = true;
				} catch (error) {
					console.error(error);
					const errorMessage = error.message ? error.message.toString() : this.$I18n.trans('errors.500.title');
					this.$toast.error(errorMessage);
				} finally {
					this.isBusy = false;
				}
			}
		},
		resetForm() {
			this.values = {
				...this.values,
				message:   '',
				firstName: '',
				lastName:  '',
				email:     '',
				phone:     '',
				topic:     null,
				team:      null,
			};
			this.submitted = false;
			this.step = 1;
		},
		handleModalClose() {
			this.resetForm();
		},
	},
};
</script>

<style lang="scss">
@import '@shared/sass/shared-variables';

// .contact-form {}

.contact-form__topic-select {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: $sp4;

	@include size(sm-up) {
		grid-template-columns: repeat(2, 1fr);
	}
}

.contact-form__topic {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $sp4;
	background-color: var(--color-grey-800);
	padding: $sp6 $sp5;

	&:hover {
		background-color: var(--color-grey-700);
		cursor: pointer;
	}

	@include size(sm-up) {
		flex-direction: column;
		text-align: center;
	}
}
</style>
