import axios from 'axios';

const INITIAL_MAX_DELAY_MS = 10_000; // 10 seconds
const HEARTBEAT_INTERVAL_MS = 30_000; // 30 seconds
const NEW_SESSION_DELAY_MS = 60_000; // 60 seconds
const ERROR_RESET_DELAY_MS = 300_000; // 5 minutes
const FORCE_NEW_SESSION_DELAY_MS = 1_800_000; // 30 minutes
const SEND_HEARTBEAT_TIMEOUT_MS = 30_000; // 30 seconds,

export const createHeartbeat = () => {
	const state = {
		eventId:                    null,
		startTimestamp:             null,
		lastSentHeartbeatTimestamp: null,
		stoppedBecauseOfErrors:     false,
		handleParallelViews:        null,
		playerBus:                  null,
		viewerId:                   null,
		lastStreamPosition:         null,
		payload:                    {},
		heartbeatCounter:           {},
	};

	let ticker = null;
	let timeout = null;

	return {
		setEventId(eventId) {
			state.eventId = eventId;
			return this;
		},
		setViewerId(viewerId) {
			state.viewerId = viewerId;
			return this;
		},
		setPlayerBus(playerBus) {
			state.playerBus = playerBus;
			return this;
		},
		setPayload(payload, merge = false) {
			state.payload = merge ? { ...state.payload, ...payload } : payload;
			return this;
		},
		setParallelViewHandler(handleParallelViews) {
			state.handleParallelViews = handleParallelViews;
			return this;
		},
		validateOrPanic() {
			if (!state.eventId) throw new Error('[HEARTBEAT] Fatal error: eventId is not set');
			if (!state.viewerId) throw new Error('[HEARTBEAT] Fatal error: viewerId is not set');
		},
		clearTimers() {
			if (ticker) clearInterval(ticker);
			if (timeout) clearTimeout(timeout);
		},
		resetAndStart() {
			this.reset();
			this.start();
		},
		reset() {
			this.clearTimers();
			state.startTimestamp = null;
			state.lastSentHeartbeatTimestamp = null;
		},
		start() {
			this.validateOrPanic();
			this.clearTimers();

			// this.log('starting heartbeat');
			const lastHeartbeatSent = state.lastSentHeartbeatTimestamp ? Date.now() / 1000 - state.lastSentHeartbeatTimestamp : null;

			// Create a new session timestamp when no start timestamp is available, or last heartbeat was more then 60 seconds ago
			// Do no start a new session if the heartbeat was stopped because of errors (i.e. server temporarily not available),
			// but always force start a new session after a certain threshold.
			if (!state.startTimestamp ||
				(lastHeartbeatSent && lastHeartbeatSent > NEW_SESSION_DELAY_MS && !this.stoppedBecauseOfErrors) ||
				(lastHeartbeatSent && lastHeartbeatSent > FORCE_NEW_SESSION_DELAY_MS && this.stoppedBecauseOfErrors)
			) {
				state.startTimestamp = Math.floor(Date.now() / 1000);
				this.log('new session initialized');
			}

			state.stoppedBecauseOfErrors = false;
			const delay = this.getRandomNumberBetween(0, INITIAL_MAX_DELAY_MS);
			this.log(`delaying heartbeat interval by ${delay} milliseconds`);
			timeout = setTimeout(() => {
				this.log('initializing heartbeat interval');
				// Start heartbeat ticker, send initial heartbeat after an initial delay
				ticker = setInterval(() => this.sendHeartbeat(), HEARTBEAT_INTERVAL_MS);
				this.sendHeartbeat();
			}, delay);
		},
		pause() {
			this.log('pausing heartbeat');
			this.clearTimers();
		},
		sendHeartbeat() {
			this.validateOrPanic();

			let telemetry = {};
			let isPlaying = false;

			if (!state.heartbeatCounter[state.eventId]) {
				state.heartbeatCounter[state.eventId] = 1;
			}

			try {
				// Detect when the stream has stopped playing due to unexpected errors
				isPlaying = state.playerBus && !(state.playerBus.position === state.lastStreamPosition);
				const videoHeight = state.playerBus?.player?.getCurrentVideoHeight();
				const audioTrack = state.playerBus?.player?.getCurrentAudioTrack();
				telemetry = {
					qualityLevel:       videoHeight || 0,
					fatalMediaError:    state.playerBus?.player?.errorCount?.fatalMediaError || 0,
					networkError:       state.playerBus?.player?.errorCount?.networkError || 0,
					bufferStalledError: state.playerBus?.player?.errorCount?.bufferStalledError || 0,
					requestFailedError: state.playerBus?.player?.errorCount?.requestFailedError || 0,
					firstFrameLoaded:   state.playerBus?.player?.hasLoadedFirstFrame || false,
					heartbeatCount:     state.heartbeatCounter[state.eventId],
					audioTrack:         audioTrack || '-',
				};
			} catch (e) {
				console.error(e);
			}

			this.sendPlayHeartbeat(state.eventId, state.viewerId, state.startTimestamp, isPlaying, telemetry)
				.then(() => {
					const player = state.playerBus?.player;
					if (player) {
						player.resetErrorCount();
					}
					state.heartbeatCounter[state.eventId]++;
				})
				.catch((error) => {
					if (error.status === 403) {
						if (error.data && error.data && error.data.reason === 'parallelStream' && state.handleParallelViews) {
							state.handleParallelViews(error.data);
						} else {
							this.log('invalid response from heartbeat endpoint: ' + JSON.stringify(error.data), 'error');
						}
					} else {
						console.error(error);
					}

					state.stoppedBecauseOfErrors = true;
					this.clearTimers();
					timeout = setTimeout(() => this.start(), ERROR_RESET_DELAY_MS);
				});

			state.lastStreamPosition = state.playerBus ? state.playerBus.position : null;
			state.lastSentHeartbeatTimestamp = Date.now() / 1000;
		},
		getRandomNumberBetween(min, max) {
			return Math.random() * (max - min) + min;
		},
		async sendPlayHeartbeat(eventId, viewerId, sessionStartTs, isPlaying, telemetry) {
			const url = `/api/v1/event/${eventId}/play/${viewerId}/${sessionStartTs}`;
			const body = {
				...{
					viewerId:       viewerId,
					sessionStartTs: sessionStartTs.toString(),
					source:         window.referer || window.location.href,
					ignore:         !!window.Cypress,
					isPlaying:      isPlaying,
					telemetry,
				},
				...state.payload,
			};
			const res = await axios.post(
				url,
				body,
				{
					timeout: SEND_HEARTBEAT_TIMEOUT_MS,
				},
			);
			return res.data;
		},
		log(msg, level = 'info') {
			msg = `[HEARTBEAT][${new Date().toLocaleString()}] ${msg} (eventId=${state.eventId}, viewerId=${state.viewerId}, startTimestamp=${state.startTimestamp})`;
			if (typeof console[level] === 'function') {
				console[level](msg);
			} else {
				console.log('Unknown log level:', level);
				console.log(msg);
			}
		},
	};
};
