<template>
	<div
		class="match-scores"
		:class="{'has-scores': homeScore && guestScore, '-large': large}"
	>
		<div
			v-if="homeName"
			class="match-scores__team -home"
		>
			<span
				class="match-scores__logo"
				:style="{ backgroundImage: `url(${homeImg})` }"
			/>
			<span
				class="match-scores__team-name"
			>{{ homeName }}</span>
			<span
				v-if="homeScore"
				class="match-scores__result"
			>{{ noSpoilerMode ? '-' : homeScore }}</span>
		</div>
		<div
			v-if="guestName"
			class="match-scores__team -guest"
		>
			<span
				class="match-scores__logo"
				:style="{ backgroundImage: `url(${guestImg})` }"
			/>
			<span
				class="match-scores__team-name"
			>{{ guestName }}</span>
			<span
				v-if="guestScore"
				class="match-scores__result -with-extra"
				:data-extra="noSpoilerMode ? '' : scoreExtraAbbr"
			>{{ noSpoilerMode ? '-' : guestScore }}</span>
		</div>
	</div>
</template>

<script>
import { MatchScoresPropsConfig } from './MatchScoresPropsConfig';
import { mapState } from 'vuex';

export default {
	name:  'MatchScores',
	props: {
		...MatchScoresPropsConfig,
		large: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		homeImg() {
			return this.scaleImage(this.homeImgUrl);
		},
		guestImg() {
			return this.scaleImage(this.guestImgUrl);
		},
		...mapState({
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
	},
	methods: {
		scaleImage(url) {
			return url && this.$Helpers && !url.includes('logo_placeholder')
				? this.$Helpers.getScaledImageUrl(url, 80)
				: url;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.match-scores {
	@include font(primary, book, normal, fs-80);
	color: var(--color-white);

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
		color: var(--color-black);
	}

	.is-embed & {
		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			color: var(--color-white);
		}
	}

	&.-large {
		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			color: var(--color-white);
		}
	}
}

.match-scores__team {
	display: flex;
	align-items: center;

	&:not(:last-child) {
		margin-bottom: $sp3;
	}
}

.match-scores__logo {
	$logo-size: 24px;
	display: inline-block;
	width: $logo-size;
	height: $logo-size;
	background-size: $logo-size;
	background-repeat: no-repeat;
	background-position: left center;
	margin-right: $sp3;
}

.match-scores__team-name {
	.match-scores.has-scores & {
		width: 56px;
	}
}

.match-scores__result {
	@include font(primary, bold, normal, fs-80);
	text-align: right;

	&.-with-extra::after {
		@include font(primary, book, normal, fs-60);
		line-height: inherit;
		content: attr(data-extra);
		position: absolute;
		color: var(--color-grey-300);
		margin-left: $sp2;
	}
}
</style>
