<template>
	<header
		class="c-header js-header"
		:class="$arenaConfig.isFanAt ? '-fan-at' : ''"
	>
		<div class="c-header__left">
			<div
				v-if="!$arenaConfig.isFanAt"
				class="c-header__logo-w"
			>
				<a
					v-if="$arenaConfig.isAsport ? ($arenaConfig.headerImageLink || homeUrl) : (($arenaConfig.headerImageLink || homeUrl) && $arenaConfig.headerImageUrl)"
					:href="$arenaConfig.headerImageLink || homeUrl"
				>
					<img
						ref="logo"
						class="c-header__logo js-header-logo"
						alt="Logo"
						:src="$arenaConfig.headerImageUrl || '/images/asport_logo_light.svg'"
					>
				</a>
				<img
					v-else-if="$arenaConfig.isAsport ? true : $arenaConfig.headerImageUrl"
					ref="logo"
					class="c-header__logo js-header-logo"
					alt="Logo"
					:src="$arenaConfig.headerImageUrl || '/images/asport_logo_light.svg'"
				>
			</div>
			<div
				v-if="subNavItems.length && !$arenaConfig.isSmartTv"
				class="c-header__nav-toggle js-nav-toggle"
				@click="toggleSubNav"
			>
				<V2Icon
					:icon-name="subNavIsOpen ? 'e-remove' : 'menu'"
					size="lg"
				/>
			</div>
			<div
				v-if="((topNavItems && topNavItems.length) || (menuItems && menuItems.length)) && !$arenaConfig.isSmartTv"
				class="c-header__nav-main"
			>
				<a
					v-for="item in topNavItems"
					:key="item.id"
					:href="item.link"
					:target="mixinIsExternalLink(item.link) ? '_blank' : '_self'"
					:rel="mixinIsExternalLink(item.link) ? 'noopener' : ''"
					class="c-header__nav-main-item -custom"
					:class="{'is-active': item.isActive}"
				>
					<span>{{ item.title }}</span>
					<V2Icon
						v-if="mixinIsExternalLink(item.link)"
						class="u-show-as-inline-flex@sm"
						icon-name="launch"
						size="xxs"
					/>
				</a>
				<span
					v-if="menuItems && menuItems.filter(i => !i.hidden).length && topNavItems && topNavItems.length"
					class="c-header__nav-item-separator"
				/>
				<a
					v-for="item in menuItemsWithoutHidden"
					:key="item.link"
					:href="item.link"
					:target="item.external ? '_blank' : '_self'"
					:rel="item.external ? 'noopener' : ''"
					class="c-header__nav-main-item -default"
					:class="{'is-active': item.isActive}"
				>
					<span>{{ item.text }}</span>
					<V2Icon
						v-if="item.external"
						class="u-show-as-inline-flex@sm"
						icon-name="launch"
						size="xxs"
					/>
				</a>
			</div>
		</div>

		<div class="c-header__right">
			<template v-if="$arenaConfig.loadArenaProfile">
				<InputToggle
					v-if="profile && $arenaConfig.enableNoSpoilerMode && !$arenaConfig.isSmartTv"
					class="c-header__no-spoiler"
					:text="$I18n.trans('arena.fan_account.settings.no_spoiler_mode.label_short')"
					:value="noSpoilerMode"
					:busy="isProcessing"
					size="sm"
					:dark-theme="!$arenaConfig.isLightTheme"
					@input="toggleNoSpoilerMode"
				/>
				<SwissLeagueSubscribtionBadge v-if="$arenaConfig.isSwissLeague" />
				<div class="c-header__nav-profile">
					<NavProfile
						:is-login-page="isLoginPage"
					/>
					<form
						id="logout-form"
						action="/logout"
						method="POST"
						class="u-display-none"
					>
						<input
							type="hidden"
							name="_token"
							:value="$arenaConfig.csrfToken"
						>
					</form>
					<form
						id="switch-context-form"
						:action="laroute.route('auth.login_switch')"
						method="POST"
						class="u-display-none"
						autocomplete="off"
					>
						<input
							type="hidden"
							name="_token"
							:value="$arenaConfig.csrfToken"
						>
						<input
							id="context-switch-id"
							type="hidden"
							name="id"
							value=""
							autocomplete="off"
						>
						<input
							type="hidden"
							name="stayOnPage"
							value="true"
						>
					</form>
				</div>
			</template>
			<div
				v-if="!$Helpers.isBlueTv()"
				class="c-header__nav-mobile-toggle"
				@click="toggleMobileNav"
			>
				<V2Icon
					:icon-name="mobileNavIsOpen ? 'e-remove' : 'menu'"
					size="lg"
				/>
			</div>
		</div>

		<div
			class="c-header__nav-sub-bg-overlay"
			:class="{'is-open': subNavIsOpen}"
			@click="toggleSubNav"
		/>
		<div
			class="c-header__nav-sub"
			:class="{'is-open': subNavIsOpen}"
		>
			<div
				ref="subNavContainer"
				class="c-header__nav-sub-inner-w"
				:style="{
					height: 30 + subNavContainerHeight + 'px',
					left: 10 + subNavContainerPaddingLeft + 'px',
					maxWidth: `calc(100vw - ${10 + 30 + subNavContainerPaddingLeft}px)`,
				}"
			>
				<NavSub
					v-if="subNavItems.length"
					:nav-items="subNavItems"
				/>
			</div>
		</div>
		<NavMobile
			ref="navMobile"
			:is-open="mobileNavIsOpen"
			:context-nav-items="menuItems"
			:top-nav-items="topNavItems"
			:sub-nav-items="subNavItems"
			@close-nav-mobile="toggleMobileNav"
		/>
	</header>
</template>

<script>
import NavProfile from '@js/components/NavProfile.vue';
import NavMobile from '@js/components/NavMobile.vue';
import NavSub from '@js/components/NavSub.vue';
import SwissLeagueSubscribtionBadge from '@js/components/SwissLeague/SubscribtionBadge';
import InputToggle from '@shared/components/Form/InputToggle';
import V2Icon from '@shared/components/Icon';
import { Context } from '@shared/enums/Context';
import { ArenaNavigationPlacement } from '@shared/enums/ArenaNavigationPlacement';
import { ArenaNavigationDefaultType } from '@shared/enums/ArenaNavigationDefaultType';
import { mapState, mapActions } from 'vuex';
import { actions } from '@legacy/js/store/user';
import laroute from '@laroute';

export default {
	name:       'Header',
	components: { NavProfile, InputToggle, SwissLeagueSubscribtionBadge, NavSub, NavMobile, V2Icon },
	data() {
		return {
			isLoginPage:                window.location.pathname.includes('login'),
			isProcessing:               false,
			laroute,
			subNavIsOpen:               false,
			mobileNavIsOpen:            false,
			subNavContainerHeight:      0,
			subNavContainerPaddingLeft: 0,
		};
	},
	computed: {
		menuItems() {
			const configMenuItems = this.$arenaConfig.menuItems;
			const configAdditionalMenuItems = this.$arenaConfig.additionalMenuItems;
			const menuItems = [];
			if (configMenuItems) {
				menuItems.push(...(configMenuItems).map(item => {
					const route = item.route ? item.route : laroute.route(`arena.${item.routeName}`);
					return {
						link:     item.external ? item.route : route,
						isActive: window.location.pathname === route,
						text:     item.external ? item.text : item.text.includes(',') ? this.$I18n.trans_choice(item.text.split(',')[0], item.text.split(',')[1]) : this.$I18n.trans(item.text),
						icon:     item.icon,
						external: item.external,
					};
				}));
			} else if (this.$arenaConfig.type === Context.LEAGUE && this.$arenaConfig.id) {
				const routes = {
					home:        this.$arenaConfig.isCustomDomain ? '/' : laroute.route('arena.league', { slug: this.$arenaConfig.slug, league: this.$arenaConfig.id }),
					matchCenter: this.$arenaConfig.isCustomDomain ? laroute.route('arena.match.center.custom') : laroute.route('arena.match.center', { slug: this.$arenaConfig.slug, league: this.$arenaConfig.id }),
					VideoCenter: this.$arenaConfig.isCustomDomain ? laroute.route('arena.video.center.custom') : laroute.route('arena.video.center', { slug: this.$arenaConfig.slug, league: this.$arenaConfig.id }),
				};
				menuItems.push(
					{
						link:     routes.home,
						text:     this.$I18n.trans('commons.home'),
						isActive: window.location.pathname === routes.home,
						icon:     'stadium',
						type:     'home',
						hidden:   this.topNavItems.length > 0,
					},
				);
				if (!this.$arenaConfig.disableLeagueMatchCenter) {
					menuItems.push(
						{
							link:     routes.matchCenter,
							text:     this.$I18n.trans('arena.match_center.title'),
							isActive: window.location.pathname === routes.matchCenter,
							icon:     'calendar-event',
						},
					);
				}
				if (!this.$arenaConfig.disableVideoCenter) {
					menuItems.push(
						{
							link:     routes.VideoCenter,
							text:     this.$I18n.trans('arena.video_center.title'),
							isActive: window.location.pathname === routes.VideoCenter,
							icon:     'video-playlist',
						},
					);
				}
			} else if ([Context.FEDERATION, Context.CLIENT].includes(this.$arenaConfig.type) && this.$arenaConfig.id) {
				const routes = {
					home:        this.$arenaConfig.isCustomDomain ? '/' : laroute.route('arena.federation', { slug: this.$arenaConfig.slug, federation: this.$arenaConfig.id }),
					VideoCenter: this.$arenaConfig.isCustomDomain ? laroute.route('arena.video.center.custom') : laroute.route('arena.video.center.federation', { federation: this.$arenaConfig.id }),
				};
				menuItems.push(
					{
						link:     routes.home,
						text:     this.$I18n.trans('commons.home'),
						isActive: window.location.pathname === routes.home,
						icon:     'stadium',
						type:     'home',
						hidden:   this.topNavItems.length > 0,
					},
				);
				if (!this.$arenaConfig.disableVideoCenter) {
					menuItems.push(
						{
							link:     routes.VideoCenter,
							text:     this.$I18n.trans('arena.video_center.title'),
							isActive: window.location.pathname === routes.VideoCenter,
							icon:     'video-playlist',
						},
					);
				}
			}
			if (configAdditionalMenuItems) {
				menuItems.push(...(configAdditionalMenuItems).map(item => {
					const route = item.route ? item.route : laroute.route(`arena.${item.routeName}`);
					return {
						link:     item.external ? item.route : route,
						isActive: window.location.pathname === route,
						text:     item.external ? item.text : item.text.includes(',') ? this.$I18n.trans_choice(item.text.split(',')[0], item.text.split(',')[1]) : this.$I18n.trans(item.text),
						icon:     item.icon,
						external: item.external,
					};
				}));
			}

			return menuItems;
		},
		menuItemsWithoutHidden() {
			return this.menuItems.filter(item => !item.hidden);
		},
		homeUrl() {
			return (this.menuItems || []).find(i => i.type === 'home')?.link;
		},
		...mapState({
			profile:     state => state.user.profile,
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
		topNavItems() {
			return (this.$arenaConfig.navItems || [])
				.filter(item => item.placement === ArenaNavigationPlacement.TOP)
				.map(item => {
					const link = item.link ? new URL(item.link) : null;
					return {
						...item,
						isActive: window.location.pathname === link.pathname && window.location.hostname === link.hostname,
					};
				});
		},
		subNavItems() {
			const filterEmptyItems = (items) => {
				return (items || []).filter(item => {
					if (item.children) {
						if (typeof item.children === 'object') {
							item.children = Object.values(item.children);
						}
						item.children = filterEmptyItems(item.children || []);
					}
					const isTopNavItem = item.placement === ArenaNavigationPlacement.TOP;
					const hasLink = item.link;
					const hasChildren = item.children && item.children.length > 0;
					return !isTopNavItem && (hasLink || hasChildren);
				});
			};

			// We don't need the folder structure for ArenaNavigationDefaultType.LEAGUES_TEAMS and ArenaNavigationDefaultType.TEAMS.
			const flattenItems = (items) => {
				const flattened = [];

				const recursiveFlatten = (item) => {
					if ([ArenaNavigationDefaultType.LEAGUES_TEAMS, ArenaNavigationDefaultType.TEAMS].includes(item.defaultType) && Array.isArray(item.children)) {
						item.children.forEach(child => recursiveFlatten(child));
					} else {
						if (Array.isArray(item.children) && item.children.length > 0) {
							item.children = flattenItems(item.children);
						}
						flattened.push(item);
					}
				};

				if (Array.isArray(items)) {
					items.forEach(item => recursiveFlatten(item));
				}

				return flattened;
			};

			const filteredItems = filterEmptyItems(this.$arenaConfig.navItems || []);
			const flattenedItems = flattenItems(filteredItems).map(item => {
				const linkPath = item.link ? new URL(item.link).pathname : null;
				return {
					...item,
					isActive: linkPath ? window.location.pathname === linkPath : false,
				};
			});

			return flattenedItems;
		},
	},
	mounted() {
		this.initObserver();
		this.calculateSubNavContainerHeight();
		window.addEventListener('resize', () => {
			requestAnimationFrame(() => {
				this.calculateSubNavContainerHeight();
			});
		});

		// Recalculate the Toggle offset once the logo was loaded
		const logo = this.$refs.logo;
		if (logo) {
			logo.addEventListener('load', () => {
				this.calculateSubNavContainerHeight();
			});
		}

		window.addEventListener('keydown', (e) => {
			if (e.key === 'Escape' && this.subNavIsOpen) {
				this.toggleSubNav();
			}
		});
	},
	methods: {
		...mapActions({
			updateProfile: `user/${actions.UPDATE_PROFILE}`,
		}),
		async toggleNoSpoilerMode(value) {
			try {
				this.isProcessing = true;
				await this.updateProfile({
					preferences: { noSpoilerMode: value },
				});

				// Bit more complex to change no spoilermode on the fly in the player,
				// hence the reload here
				if (window.location.pathname.includes('/event/')) {
					setTimeout(() => {
						window.location.reload();
					}, 500);
				}
			} catch (e) {
				console.error(e);
				this.$toast.error(this.$I18n.trans('errors.500.title'));
			} finally {
				this.isProcessing = false;
			}
		},
		toggleSubNav() {
			this.subNavIsOpen = !this.subNavIsOpen;
		},
		toggleMobileNav() {
			this.mobileNavIsOpen = !this.mobileNavIsOpen;
		},
		initObserver() {
			if (!this.$refs.subNavContainer) return;

			const config = { attributes: false, childList: true, subtree: true };

			const observer = new MutationObserver((mutations) => {
				for (const mutation of mutations) {
					if (mutation.type === 'childList') {
						this.calculateSubNavContainerHeight();
					}
				}
			});

			observer.observe(this.$refs.subNavContainer, config);
		},
		calculateSubNavContainerHeight() {
			let maxHeight = 0;
			const container = this.$refs.subNavContainer;
			if (container) {
				const subNavContainer = container.querySelectorAll('.js-nav-level');
				for (let i = 0; i < subNavContainer.length; i++) {
					const subNavConainerHeight = subNavContainer[i].scrollHeight;
					if (subNavConainerHeight > maxHeight) {
						maxHeight = subNavConainerHeight;
					}
				}
				this.subNavContainerHeight = maxHeight;

				const toggle = document.querySelector('.js-nav-toggle');
				const toggleLeftOffset = toggle ? toggle.offsetLeft : 0;
				this.subNavContainerPaddingLeft = toggleLeftOffset;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

/* stylelint-disable */
.c-header {
	@include z-index(arena-header);

	--header-items-gap: #{$sp4};

	position: relative;
	display: flex;
	background-color: var(--color-black);
	justify-content: space-between;
	align-items: center;
	border: none; // needed due to SDX conflicts
	transform: translateZ(1px); // fix for Safari z-index issue

	body.is-player-mode.is-smarttv & {
		display: none;
	}

	@include size(md-up) {
		--header-items-gap: #{$sp3};
		border-bottom: 1px solid var(--color-grey-900);
	}

	@include size(lg-up) {
		--header-items-gap: #{$sp4};
	}

	@include size(xl-only) {
		--header-items-gap: #{$sp5};
	}

	@include mobile-media-query {
		body.is-player-mode & {
			display: none;
		}
	}

	&.-fan-at {
		position: absolute;
		right: 0;
		top: 72px;

		@media only screen and (min-width: 992px) {
			top: 82px;
		}

		@include size(md-up) {
			position: relative;
			left: auto;
			top: auto;
			padding-left: $sp4;
		}
	}

	body.-t-light & {
		background-color: var(--color-white);
	}
}

.c-header__left {
	display: flex;
	gap: var(--header-items-gap);
	align-items: center;
	flex-grow: 1;
	background-color: var(--color-black);

	body.-t-light & {
		background-color: var(--color-white);
	}
}

.c-header__right {
	display: flex;
	align-items: center;
	gap: var(--header-items-gap);
	background-color: var(--color-black);
	margin-right: $grid-gutter-width--xs;

	body.-t-light & {
		background-color: var(--color-white);
	}

	@include size(sm-up) {
		margin-right: $grid-gutter-width;
	}
}

.c-header__no-spoiler {
	display: none;

	@include size(md-up) {
		display: block;
	}
}

::v-deep .v2-input-toggle {
	margin-bottom: 0;
}

.c-header__logo-w {
	display: flex;
	align-items: center;
	height: $header-height;
	margin-left: $grid-gutter-width--xs;

	a {
		text-decoration: none;
	}

	body.-t-swiss-league & {
		align-items: flex-start;
	}

	body.-t-atv & {
		width: 40px;
	}

	body.-t-oeoc & {
		width: 26px;

		@include size(sm-up) {
			width: 36px;
		}
	}

	body.-t-baseball-u23-em & {
		width: 30px;

		@include size(sm-up) {
			width: 40px;
		}
	}

	@include size(xs-only) {
		height: $header-height--xs;
	}

	@include size(sm-up) {
		margin-left: $grid-gutter-width;
	}
}

.c-header__logo {
	width: 100%;
	max-width: 92px;
	max-height: $header-height--xs - $sp3;
	object-fit: contain;

	body.-t-swiss-league.is-player-mode & {
		position: relative;
		max-width: 34px !important;
		max-height: none;
		top: $sp2;
	}

	body.-t-swiss-league:not(.is-player-mode) & {
		position: relative;
		top: $sp2;
		max-width: 43px !important;
		max-height: none;
	}

	body.-t-handball & {
		@include size(xs-only) {
			margin-left: $sp2;
		}
	}

	// Important:
	// For SVG Logos, use explicit width, otherwise it won't be visible in firefox

	body.-st-stv-fsg & {
		width: 35px !important;
		max-height: none;
	}

	@include size(sm-up) {
		max-width: 100px;
		max-height: $header-height - $sp3;

		body.-t-swiss-league:not(.is-player-mode) & {
			max-width: 59px !important;
			max-height: none;
		}

		body.-t-swiss-league.is-player-mode & {
			max-width: 45px !important;
			max-height: none;
		}

		body.-st-stv-fsg & {
			width: 43px !important;
			max-height: none;
		}
	}

	@include size(md-up) {
		body.-t-swiss-league.is-player-mode & {
			max-width: 59px !important;
		}
	}

	&[src$="logo-abf.svg"] {
		height: $header-height--xs - $sp4;

		@include size(sm-up) {
			height: $header-height - $sp5;
		}
	}

	&[src$="skicheck-logo.png"] {
		max-height: $header-height--xs - $sp4;

		@include size(sm-up) {
			max-height: $header-height - $sp5;
		}
	}

	&[src$="native-waves-logo.png"] {
		max-width: 130px;

		@include size(sm-up) {
			max-width: 150px;
		}
	}

	&[src$="sfl-logo.png"] {
		max-width: 120px;

		@include size(sm-up) {
			max-width: 160px;
		}
	}

	&[src$="spz.svg"] {
		width: 130px;
		padding-top: 6px;

		@include size(sm-up) {
			width: 140px;
			padding-top: 14px;
		}

		@include size(md-up) {
			margin-right: 13px;
		}

		@include size(lg-up) {
			margin-right: 0;
		}
	}

	&[src$="logo-ehv.gif"] {
		max-width: 130px;
		max-height: 56px;

		@include size(sm-up) {
			max-height: 66px;
		}
	}

	&[src$="logo-oepc.png"] {
		max-width: 160px;
		max-height: none;

		@include size(sm-up) {
			max-width: 190px;
		}
	}

	&[src$="logo-ballhockey-wc.png"] {
		position: relative;
		left: -5%;

		body:not(.is-player-mode) & {
			max-height: 56px;
			top: 10px;

			@include size(sm-up) {
				max-height: 66px;
				top: 10px;
			}
		}

		@include size(md-up) {
			max-height: 66px;
			top: 10px;
		}
	}

	&[src$="sport-austria-finals.png"] {
		max-height: 46px !important;

		@include size(sm-up) {
			max-width: 64px !important;
			max-height: 64px !important;
		}
	}
}

.c-header__nav-toggle {
	display: block;
	padding: $sp3;
	cursor: pointer;
	margin-top: $sp3;

	@include is-touch-or-size-md-down {
		display: none;
	}
}

.c-header__nav-mobile-toggle {
	display: none;
	cursor: pointer;

	@include is-touch-or-size-md-down {
		display: block;
	}
}

.c-header__nav-main {
	display: flex;
	position: relative;
	gap: var(--header-items-gap);
	margin-top: $sp2;

	&:empty {
		display: none;
	}

	@include is-touch-or-size-md-down {
		display: none;
	}
}

.c-header__nav-main-item {
	font-size: 14px;
	display: inline-flex;
	gap: $sp3;
	align-items: center;
	position: relative;
	padding: $sp3-5 $sp3;
	color: var(--color-grey-300);
	transition: color $trans-time-fast;
	user-select: none;
	white-space: nowrap;

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 1px;
		background-color: var(--color-hype-yellow);
		opacity: 0;
		transition: opacity $trans-time-fast;
	}

	&:hover,
	&:focus,
	&.is-active {
		color: var(--color-grey-050);

		&::after {
			opacity: 1;
		}
	}

	@include size(lg-up) {
		font-size: 16px;
	}
}

.c-header__nav-item-separator {
    width: 1px;
    height: 24px;
    background-color: var(--color-grey-300);
    display: inline-block;
    position: relative;
    align-self: center;
}

.c-header__nav-sub {
	z-index: -1;
	position: absolute;
	width: 100vw;
    min-height: 100px;
	top: 100%;
	left: 0;
	right: 0;
	background-color: var(--color-black);
	padding-top: $sp5;
	padding-bottom: $sp7;
	visibility: hidden;
	pointer-events: none;
	transform: translateY(-100%);
	transition: transform $trans-time-fast, visibility $trans-time-fast;

	&.is-open {
		visibility: visible;
		pointer-events: auto;
		transform: translateY(0);
	}

	@include is-touch-or-size-md-down {
		display: none;
	}

	body.-t-light & {
		background-color: var(--color-white);
	}
}

.c-header__nav-sub-bg-overlay {
	position: fixed;
	z-index: -1;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: transparent;
	visibility: hidden;
	pointer-events: none;

	&.is-open {
		visibility: visible;
		pointer-events: auto;
	}

	@include is-touch-or-size-md-down {
		display: none;
	}
}

.c-header__nav-sub-inner-w {
	position: relative;

	@include size(xl-up) {
		max-width: 1170px !important;
	}
}

.c-header__actions {
	margin-left: $sp4;
	margin-right: $sp4;
}

// .c-header__nav-profile {}

.c-header__login {
	display: flex;
	color: var(--color-white); // needed due to SDX conflicts

	&:hover {
		text-decoration: none;
	}
}

/* stylelint-enable */
</style>
