<template>
	<Layout>
		<input
			type="hidden"
			name="trigger-reset-modal"
		>
		<PasswordResetModal
			:token="token"
		/>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import PasswordResetModal from '@js/components/Login/PasswordResetModal';

export default {
	name:       'ViewResetPassword',
	components: { Layout, PasswordResetModal },
	props:      {
		token: {
			type:     String,
			required: true,
		},
	},
	mounted() {
		window.eventBus.$emit('modal:PasswordResetModal');
	},
};
</script>

<style>

</style>
