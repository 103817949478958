<template>
	<Modal
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		modal-name="QrLoginModal"
		:closeable="false"
		@opened="onOpen"
	>
		<template #content>
			<div class="l-container">
				<div class="l-row l-align-center">
					<div class="l-col l-col--12">
						<div
							v-if="!error && !expired"
							class="qr-login__head"
						>
							<h2 class="qr-login__title">
								{{ $I18n.trans('arena.login.qr.headline') }}
							</h2>
							<div class="qr-login__subtitle">
								{{ $I18n.trans('arena.login.qr.subheadline') }}
								<template v-if="!isHome">
									{{ $I18n.trans('arena.login.qr.purchase_info') }}
								</template>
							</div>
							<LoadingSpinner v-if="!loginUrl" />
							<QrcodeVue
								v-if="loginUrl"
								class="qr-login__qr"
								:value="loginUrl"
								render-as="svg"
								size="400"
								level="H"
								background="transparent"
								foreground="#fff"
							/>
							<div
								v-if="loginUrl"
								class="qr-login__manual"
							>
								{{ loginUrl }}
							</div>
						</div>
						<div
							v-if="error"
							class="qr-login__head"
						>
							<h2 class="qr-login__title">
								{{ $I18n.trans('arena.login.qr.error.title') }}
							</h2>
							<div class="qr-login__subtitle">
								{{ $I18n.trans('arena.login.qr.error.message') }}
							</div>
							<icon
								icon-name="sad"
								size="xxxxl"
								class="qr-login__icon"
							/>
						</div>
						<div
							v-if="expired"
							class="qr-login__head"
						>
							<h2 class="qr-login__title">
								{{ $I18n.trans('arena.login.qr.expired.title') }}
							</h2>
							<div class="qr-login__subtitle">
								{{ $I18n.trans('arena.login.qr.expired.message') }}
							</div>
							<icon
								icon-name="sad"
								size="xxxxl"
								class="qr-login__icon"
							/>
						</div>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
import laroute from '@laroute';

import Modal from '@shared/components/Modal';
import Icon from '@shared/components/Icon';
import LoadingSpinner from '@shared/components/V2LoadingSpinner';

import QrcodeVue from 'qrcode.vue';

import { authApi } from '../../api/client';

export default {
	name:       'QrLoginModal',
	components: { Modal, QrcodeVue, Icon, LoadingSpinner },
	data() {
		return {
			laroute,
			tokenVerification: null,
			error:             false,
			token:             null,
			expired:           false,
			isHome:            window.location.pathname === '/',
		};
	},
	computed: {
		loginUrl: function() {
			return this.token ? window.location.protocol + '//' + window.location.hostname + laroute.route('arena.login.qr_confirm', { qrToken: this.token }) : null;
		},
	},
	methods: {
		async monitorTokenVerification() {
			const verificationInterval = 8000;

			this.tokenVerification = setInterval(async() => {
				const url = laroute.route('arena.api.arena_login.check_qr_token', { token: this.token });
				const response = await authApi.get(url);
				const responseContent = await response.json(url);

				if (responseContent && ['verified', 'payment_success'].includes(responseContent.status)) {
					window.location.href = responseContent.redirectUrl || '/';
				}

				if (responseContent && responseContent.status === 'invalid_or_expired') {
					this.expired = true;
					console.log('QR token expired.');
					clearInterval(this.tokenVerification);
				}
			}, verificationInterval);
		},
		closeModal() {
			this.eventBus.$emit('modalClose:QrLoginModal');
			this.clearInterval();
		},
		clearInterval() {
			if (this.tokenVerification) {
				clearInterval(this.tokenVerification);
			}
		},
		onOpen(data) {
			this.fetchToken(data);
		},
		async fetchToken(data) {
			try {
				const body = {};
				this.error = false;

				if (data.finalRedirectUrl) {
					body.finalRedirectUrl = data.finalRedirectUrl;
				}

				if (data.redirectAfterLoginUrl) {
					body.redirectAfterLoginUrl = data.redirectAfterLoginUrl;
				}

				if (data.eventId) {
					body.eventId = data.eventId;
				}

				if ($Helpers.isBlueTv()) {
					body.channel = 'bluetv';
				}

				const response = await fetch(laroute.route('arena.api.arena_login.create_qr_token'), {
					method:  'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(body),
				});
				const responseContent = await response.json();

				if (responseContent && responseContent.token) {
					this.error = false;
					this.token = responseContent.token;
					this.monitorTokenVerification();
				} else {
					this.error = true;
				}
			} catch (error) {
				this.error = true;
				console.error(error);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.qr-login__title {
	@include font(primary, light, normal, fs-180);
	margin-top: 30px;
	margin-bottom: 20px;
}

.qr-login__subtitle {
	@include font(primary, book, normal, fs-150);
	margin-bottom: 40px;

	@include size(md-up) {
		margin-bottom: 80px;
	}
}

.qr-login__head {
	text-align: center;
}

.qr-login__qr {
	width: 100%;
	text-align: center;
	margin-bottom: 30px;

	&::v-deep svg {               // stylelint-disable-line selector-max-type
		width: 100% !important;   // stylelint-disable-line declaration-no-important
		height: 100% !important;  // stylelint-disable-line declaration-no-important
		max-width: 400px;
		max-height: 40vh;
	}

	@include size(md-up) {
		margin-bottom: 60px;
	}
}

.qr-login__manual {
	@include font(primary, book, normal, fs-150);
	color: var(--color-grey-500);
	text-align: center;
}

.qr-login__body {
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	max-width: 350px;
	margin-left: auto;
	margin-right: auto;
}

.qr-login__error {
	@include font(primary, medium, normal, fs-150);
	margin-bottom: 20px;
	margin-top: 20px;
	text-align: center;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.qr-login__icon {
	margin-top: 40px;
	margin-bottom: 40px;
}
</style>
