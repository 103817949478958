import Vue from 'vue';

Vue.mixin({
	methods: {
		mixinIsExternalLink(link) {
			if (!link) {
				return false;
			}
			try {
				const url = new URL(link);
				return url.hostname !== window.location.hostname;
			} catch (e) {
				return false;
			}
		},
	},
});
