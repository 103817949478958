<template>
	<Layout>
		<div class="l-container">
			<MatchCenter
				:teams="teams"
				:league-id="leagueId"
				:seasons="seasons"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import MatchCenter from '@js/components/MatchCenter/MatchCenter';

export default {
	name:       'ViewMatchCenter',
	components: { Layout, MatchCenter },
	props:      {
		teams: {
			type:     Array,
			required: true,
		},
		leagueId: {
			type:     Number,
			required: true,
		},
		seasons: {
			type:    Array,
			default: () => [],
		},
	},
};
</script>

<style>

</style>
