<template>
	<div class="nav-profile">
		<div
			v-if="profile && !$Helpers.isSmartTV()"
			class="nav-profile__wrapper"
		>
			<div
				class="nav-profile__toggler"
				@click="isOpen = !isOpen"
			>
				<Icon
					class="u-show@sm"
					icon-name="account"
					size="lg"
				/>
				<Icon
					class="u-hide@sm"
					icon-name="account"
					size="sm"
				/>
			</div>
			<transition name="fade-down">
				<div
					v-show="isOpen"
					class="nav-profile__dropdown"
				>
					<component
						:is="editProfileLink ? 'a' : 'span'"
						class="nav-profile__dropdown-item -main"
						:class="{'has-no-link': !editProfileLink}"
						:href="editProfileLink"
					>
						<div
							v-if="canUpdateContext && profile.imgUrl"
							class="nav-profile__dropdown-img"
							:style="{ backgroundImage: 'url(' + profile.imgUrl + ')'}"
						/>
						<div class="nav-profile__dropdown-body-text">
							<div class="nav-profile__dropdown-title">
								{{ canUpdateContext ? profile.name : $I18n.trans('arena.fan_account.my_account') }}
							</div>
							<div class="nav-profile__dropdown-subtitle">
								{{ profile.email }}
							</div>
						</div>
					</component>
					<a
						v-for="item in navItems"
						:key="item.link"
						:href="item.link"
						class="nav-profile__dropdown-item"
						:class="[item.modifier]"
						@click="item.clickHandler ? item.clickHandler() : null"
					>
						{{ item.label }}
					</a>
				</div>
			</transition>
		</div>
		<V2Button
			v-else-if="!isLoginPage && userInitialized && profile && loginEnabled && $Helpers.isSmartTV()"
			:text="$Helpers.ucfirst($I18n.trans('commons.logout'))"
			theme="dark"
			size="small"
			class="nav-profile__login-button js-smart-tv-login-logout-button"
			@click="signOut"
		/>
		<template
			v-else-if="!isLoginPage && userInitialized && loginEnabled"
		>
			<V2Button
				:text="$Helpers.ucfirst($I18n.trans('arena.login.button'))"
				theme="dark"
				size="small"
				class="nav-profile__login-button js-smart-tv-login-logout-button"
				@click="handleLogin"
			/>
		</template>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
import V2Button from '@shared/components/Button';
import laroute from '@laroute';
import { mapGetters, mapActions } from 'vuex';

export default {
	name:       'NavProfile',
	components: { Icon, V2Button },
	props:      {
		isLoginPage: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: false,
			route:  laroute.route,
		};
	},
	computed: {
		...mapGetters({
			profile:         'user/profile',
			context:         'user/context',
			isLoggedIn:      'user/isLoggedIn',
			userInitialized: 'user/isInitialized',
			hasAssumedUser:  'user/hasAssumedUser',
			originUserId:    'user/originUserId',
		}),
		editProfileLink() {
			if (this.canUpdateContext) {
				return laroute.route('manager.user.edit');
			}
			if (this.profile?.isFanAccount) {
				return laroute.route('arena.fan_account') + '/profile';
			}
			return null;
		},
		canUpdateContext() {
			return this.profile?.canUpdateContext;
		},
		loginEnabled() {
			return this.$arenaConfig.enableLogin;
		},
		navItems() {
			const navItems = [];

			if (this.profile?.isAdmin) {
				navItems.push({
					label: $I18n.trans('admin.title'),
					link:  laroute.route('manager.admin.users'),
				});
			}

			if (this.canUpdateContext) {
				navItems.push({
					label: $I18n.trans('commons.type_manage', { type: $I18n.trans('context.type.' + this.context?.name) }),
					link:  laroute.route('manager.context.edit', { userContext: this.context.id }),
				});
			} else {
				navItems.push(
					{
						label: this.$I18n.trans('arena.fan_account.purchases.nav_label'),
						link:  laroute.route('arena.fan_account') + '/purchases',
					},
					{
						label: this.$I18n.trans('commons.settings'),
						link:  laroute.route('arena.fan_account') + '/settings',
					},
				);
			}

			if (this.hasAssumedUser) {
				navItems.push({
					label:        $I18n.trans('admin.back_to_manager'),
					clickHandler: this.switchLogin,
				});
			}

			navItems.push({
				label:        $Helpers.ucfirst($I18n.trans('commons.logout')),
				clickHandler: this.signOut,
				modifier:     '-sign-out',
			});

			return navItems;
		},
	},
	watch: {
		isOpen(value) {
			document[(value ? 'add' : 'remove') + 'EventListener']('click', this.onClickOutside);
		},
	},
	methods: {
		...mapActions({
			logout: 'user/logout',
		}),
		onClickOutside(e) {
			if (!this.$el.contains(e.target)) {
				this.isOpen = false;
			}
		},
		switchLogin(e) {
			e.preventDefault();
			document.getElementById('context-switch-id').value = this.profile.originUserId;
			document.getElementById('switch-context-form').submit();
		},
		handleLogin() {
			if (this.$Helpers.isSmartTV()) {
				this.eventBus.$emit('modal:QrLoginModal', {});
			} else {
				this.showLoginForm();
			}
		},
		async signOut() {
			this.logout();
		},
		showLoginForm() {
			window.eventBus.$emit('modal:LoginModal');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

$toggler-size: 42px;
$toggler-size--xs: 32px;

.nav-profile__wrapper {
	position: relative;
}

.nav-profile__toggler {
	display: flex;
	align-items: center;
	justify-content: center;
	width: $toggler-size--xs;
	height: $toggler-size--xs;
	border-radius: 50%;
	background-color: var(--color-grey-850);
	border: 1px solid var(--color-grey-600);
	color: var(--color-white);

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
		color: var(--color-grey-600);
		border-color: var(--color-grey-600);
	}

	&:hover {
		cursor: pointer;
	}

	@include size(sm-up) {
		width: $toggler-size;
		height: $toggler-size;
	}
}

.nav-profile__dropdown {
	@include box-shadow(1);
	@include z-index(arena-nav-profile);

	position: absolute;
	top: 100%;
	right: 0;
	background-color: var(--color-grey-850);
	margin-top: 10px;
	max-height: 60vh;
	list-style: none;

	&::after {
		position: absolute;
		right: calc($toggler-size--xs / 2);
		top: -10px;
		width: 20px;
		height: 20px;
		transform: rotate(45deg);
		transform-origin: bottom;
		background-color: var(--color-grey-850);
		display: block;
		content: '';
		z-index: -1;
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);

		&::after {
			background-color: var(--color-white);
		}
	}

	@include size(sm-up) {
		&::after {
			right: calc($toggler-size / 2);
		}
	}
}

.nav-profile__dropdown-item {
	display: flex;
	align-items: center;
	padding: 12px 16px;
	margin-left: 0; // needed due to SDX conflicts
	color: var(--color-white);

	&.-sign-out {
		color: var(--color-red);
	}

	&.-main {
		border-bottom: 1px solid var(--color-grey-800);
	}

	&:not(.has-no-link):focus,
	&:not(.has-no-link):hover {
		background-color: var(--color-grey-800);
		text-decoration: none;
		cursor: pointer;
	}

	// stylelint-disable
	body.-t-light & {
		color: var(--color-black);

		&.-sign-out {
			color: var(--color-red);
		}

		&:not(.has-no-link):focus,
		&:not(.has-no-link):hover {
			background-color: var(--color-grey-950);
		}
	}
	// stylelint-enable
}

.nav-profile__dropdown-body-text {
	max-width: 280px;
	white-space: nowrap;
}

.nav-profile__dropdown-img {
	width: 48px;
	height: 48px;
	float: left;
	margin-right: 8px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	border-radius: 50%;
	border: 1px solid #CFD5D9;
	background-color: var(--color-grey-850);
}

.nav-profile__dropdown-title {
	box-sizing: border-box;
	text-overflow: ellipsis;
	overflow: hidden;
}

.nav-profile__dropdown-subtitle {
	@include font-size(fs-80);
	color: var(--color-grey-300);
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: $sp2;
}

.nav-profile__login-button {
	min-width: unset !important; // stylelint-disable-line declaration-no-important
	padding: 0 20px !important; // stylelint-disable-line declaration-no-important
}
</style>
