<template>
	<div
		class="trigger"
		@click="isOpen = !isOpen"
	>
		{{ currentLanguage }}
		<Icon
			icon-name="down-arrow"
			class="trigger__icon"
			:class="{'is-open' : isOpen}"
			size="xs"
		/>
		<div
			class="lang-switcher"
			:class="{'is-open' : isOpen}"
		>
			<a
				v-for="lang in languages"
				:key="lang.name"
				class="lang-switcher__item"
				:href="lang.link"
				rel="nofollow"
				@click="handleClick($event, lang.code)"
			>
				{{ lang.name }}
			</a>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
import { mapGetters, mapActions } from 'vuex';

export default {
	name:       'LangSwitcher',
	components: { Icon },
	props:      {
		languages: {
			type:     Array,
			required: true,
		},
		currentLanguage: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			isOpen: false,
		};
	},
	computed: {
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		isOpen(value) {
			document[(value ? 'add' : 'remove') + 'EventListener']('click', this.onClickOutside);
		},
	},
	methods: {
		...mapActions({
			setLocale: 'user/setLocale',
		}),
		onClickOutside(evt) {
			if (!this.$el.contains(evt.target)) {
				this.isOpen = false;
			}
		},
		handleClick(event, lang) {
			if (this.isLoggedIn) {
				this.setLocale(lang);
				event.stopPropagation();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.trigger {
	position: relative;
	cursor: pointer;
}

.trigger__icon {
	&.is-open {
		transform: rotate(180deg);
	}
}

.lang-switcher {
	display: none;
	position: absolute;
	bottom: 100%;
	right: 0;
	background-color: var(--color-white);

	&.is-open {
		display: block;
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-grey-950);
	}
}

.lang-switcher__item {
	display: inline-block;
	white-space: nowrap;
	padding: $sp4 $sp5;
	width: 100%;
	color: var(--color-black);

	&:not(:last-child) {
		border-bottom: 1px solid var(--color-grey-300);
	}

	&:hover,
	&:focus {
		background-color: var(--color-grey-100);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-900);
		}
	}
}
</style>
