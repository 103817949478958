<template>
	<Layout>
		<Matchday
			:bg-image-url="bgImageUrl"
			:main-image-url="mainImageUrl"
			:title="title"
			:slides="slides"
			:header-sponsors="headerSponsors"
			:enable-liveheader="enableLiveheader"
		/>
		<div class="l-container">
			<div
				v-for="lane in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<SectionChildNav
					v-if="teams.length && lane.lane_type === ArenaLaneType.NAVIGATION && lane.visible"
					type="team"
					:items="teams"
				/>
				<SocialLane
					v-if="lane.lane_type === ArenaLaneType.STORIES && lane.visible && socialPosts.length > 0"
					:social-posts="socialPosts"
				/>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:album-icon="lane.imgUrl"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
				/>
				<AdLane
					v-if="lane.lane_type === ArenaLaneType.AD && lane.visible"
					:ad="lane.ad"
				/>
			</div>
			<EmptyState
				v-if="noEvents"
				:title="$I18n.trans_choice('commons.event', 2)"
				:text="$I18n.trans('arena.placeholder.replays')"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Matchday from '@js/components/Matchday';
import SectionChildNav from '@js/components/SectionChildNav';
import EventLane from '@js/components/EventLane';
import SocialLane from '@js/components/SocialLane';
import AdLane from '@js/components/AdLane';
import EmptyState from '@js/components/EmptyState';
import { laneMixer } from '@js/utils/laneMixer';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { AdType } from '@shared/enums/AdType';
import laroute from '@laroute';

export default {
	name:       'ViewLeague',
	components: { Layout, Matchday, SectionChildNav, EventLane, EmptyState, SocialLane, AdLane },
	props:      {
		bgImageUrl: {
			type:    String,
			default: null,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		slides: {
			type:    Array,
			default: () => [],
		},
		enableLiveheader: {
			type:    Boolean,
			default: false,
		},
		teams: {
			type:    Array,
			default: () => [],
		},
		albums: {
			type:    Array,
			default: () => [],
		},
		lanes: {
			type:    Array,
			default: () => [],
		},
		socialPosts: {
			type:    Array,
			default: () => [],
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
	},
	data() {
		return {
			ArenaLaneType,
		};
	},
	computed: {
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
		mixedLanes() {
			return laneMixer(
				this.albums,
				this.lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
				{
					moreLinks: this.$arenaConfig.disableLeagueMatchCenter
						? null
						: {
							upcoming: this.$arenaConfig.isCustomDomain ? laroute.route('arena.match.center.custom') : laroute.route('arena.match.center', { slug: this.$arenaConfig.slug, league: this.$arenaConfig.id }),
							replays:  this.$arenaConfig.isCustomDomain ? laroute.route('arena.match.center.custom') : laroute.route('arena.match.center', { slug: this.$arenaConfig.slug, league: this.$arenaConfig.id }),
						},
				}
			);
		},
		headerSponsors() {
			const headerSponsors = this.ads.find(ad => ad.type === AdType.SPONSORS_HEADER);
			return headerSponsors ? headerSponsors.logos : null;
		},
	},
};
</script>

<style>

</style>
