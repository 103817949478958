<template>
	<Layout>
		<input
			type="hidden"
			name="trigger-login-modal"
		>
		<input
			v-if="redirectUrl"
			type="hidden"
			name="login-redirect-url"
			:value="redirectUrl"
		>
		<input
			v-if="email"
			type="hidden"
			name="login-email"
			:value="email"
		>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';

export default {
	name:       'ViewLogin',
	components: { Layout },
	props:      {
		redirectUrl: {
			type:    String,
			default: null,
		},
		email: {
			type:    String,
			default: null,
		},
	},
};
</script>

<style>

</style>
