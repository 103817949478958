export const AlbumProps = (album) => {
	return {
		albumId:                      album.id,
		title:                        album.name,
		subtitle:                     album.subtitle,
		type:                         album.type,
		eventOrder:                   album.eventOrderType,
		includeLiveAndUpcomingEvents: album.include_live_and_upcoming,
	};
};
