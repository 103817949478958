<template>
	<div
		v-if="!hidden && !errorLoading"
		class="ad-card"
		:class="[
			'l-carousel-wrapper-event',
			{'-large': large},
			`-ad-format-${ad.adFormatType}`,
		]"
	>
		<div
			v-if="isStaticAd"
			class="ad-card__inner"
			:style="{ backgroundImage: 'url(' + ad.imageUrl + ')' }"
			:data-text="$I18n.trans('monetization.advertisement')"
			@click="onCardClick"
		/>
		<div
			v-else
			v-show="dynamicImageUrl"
			ref="adCard"
			class="ad-card__inner"
			:style="{ backgroundImage: 'url(' + dynamicImageUrl + ')' }"
			:data-text="$I18n.trans('monetization.advertisement')"
			@click="onCardClick"
		/>
	</div>
</template>

<script>
import AdsMixin from '@shared/js/mixins/AdsMixin';

export default {
	name:   'AdCard',
	mixins: [AdsMixin],
	props:  {
		ad: {
			type:     Object,
			required: true,
		},
		large: {
			type:     Boolean,
			required: false,
			default:  false,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
		labels: {
			type:    Array,
			default: () => [],
		},
	},
	data: function() {
		return {
			dynamicImageUrl: null,
			dynamicAdLink:   null,
			hidden:          false,
			errorLoading:    false,
		};
	},
	mounted() {
		if (this.isAdButlerAd) {
			this.fetchAdbutlerAd(this.adButlerCallback, { labels: this.labels });
		}
	},
	methods: {
		onCardClick() {
			if (this.clickable && this.ad.link) {
				window.open(this.ad.link, '_blank');
			} else if (this.clickable && this.dynamicAdLink) {
				window.open(this.dynamicAdLink, '_blank');
			}
		},
		adButlerCallback(adButlerResponse) {
			// Error handling
			if (!adButlerResponse || adButlerResponse.placements?.placement_1 === undefined) {
				this.errorLoading = true;
				this.$emit('error-loading');
				return;
			}
			if (adButlerResponse.status === 'SUCCESS' && adButlerResponse.placements?.placement_1) {
				const placement = adButlerResponse.placements?.placement_1;

				if (placement.image_url) {
					this.dynamicImageUrl = placement.image_url;
					this.dynamicAdLink = placement.redirect_url;
				}

				if (parseInt(placement.refresh_time) > 0) {
					setTimeout(() => {
						this.fetchAdbutlerAd(this.adButlerCallback);
					}, parseInt(placement.refresh_time) * 1000);
				}
			} else {
				// Adbutler might return an empty ad placement. In this case, the card needs to be hidden and carousels reloaded
				this.hidden = true;
				window.eventBus.$emit('update-carousels');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.ad-card {
	height: 100%;

	&.-ad-format-regular {
		height: 250px + (2 * $carousel-card-overflow);

		&.-large {
			@include size(md-up) {
				align-self: center;
			}
		}
	}
}

.ad-card__inner {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 2px;
	transition: transform $trans-time-fast;
	will-change: transform;
	background-size: cover;
	height: 0;
	padding-bottom: 56.25%;
	cursor: pointer;

	.ad-card.-ad-format-regular & {
		height: 100%;
		padding-bottom: 0;
	}

	&:hover {
		transform: scale(1.05);
	}

	&::after {
		display: block;
		content: attr(data-text);
		position: absolute;
		right: 2px;
		top: calc(100% - -8px);
		font-size: 14px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		color: var(--color-grey-300);
	}

	.ad-card.-large & {
		@include size(md-up) {
			&:hover {
				transform: scale(1.025);
			}
		}
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include box-shadow(1);
	}
}

</style>
