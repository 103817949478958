<template>
	<footer
		class="footer js-footer"
		:class="{
			'is-hidden-on-event': !$arenaConfig.showFooterOnEvent,
		}"
	>
		<div class="footer__logo-w">
			<div
				v-if="$arenaConfig.showPoweredBy"
				class="footer__logo-text"
			>
				powered by
			</div>
			<img
				v-if="$arenaConfig.footerImageUrl"
				class="footer__logo"
				alt="Logo"
				:src="$arenaConfig.footerImageUrl"
			>
			<a
				v-else
				href="https://asport.tv/"
				target="_blank"
			>
				<img
					class="footer__logo"
					alt="Asport"
					:src="$arenaConfig.isLightTheme ? '/images/asport_logo_dark.svg' : '/images/asport_logo_light.svg'"
				>
			</a>
		</div>

		<div class="footer__nav-w">
			<ul class="footer__nav">
				<li
					v-for="(item, i) in menu"
					:key="i"
					class="footer__nav-item"
				>
					<component
						:is="item.link ? 'a' : 'span'"
						class="footer__link"
						:href="item.link ? item.link : null"
						:class="{'is-active': item.isActive }"
						@click="handleClick(item)"
					>
						{{ item.text }}
					</component>
				</li>
				<li class="footer__nav-item">
					<LangSwitcher
						:languages="$arenaConfig.locales"
						:current-language="$arenaConfig.currentLanguage"
						class="footer__link"
					/>
				</li>
			</ul>

			<div
				v-if="$arenaConfig.socialMedia.length"
				class="footer__social"
			>
				<a
					v-for="link in $arenaConfig.socialMedia"
					v-show="getSocialLinkIcon(link)"
					:key="link"
					:href="link"
					rel="nooponer"
					target="blank"
					class="footer__social-link"
				>
					<V2Icon
						v-if="getSocialLinkIcon(link)"
						:icon-name="getSocialLinkIcon(link)"
						size="lg"
					/>
				</a>
			</div>
		</div>
	</footer>
</template>

<script>
import LangSwitcher from '@js/components/LangSwitcher';
import V2Icon from '@shared/components/Icon';
import laroute from '@laroute';

export default {
	name:       'Footer',
	components: { V2Icon, LangSwitcher },
	props:      {
		showCookiesMenu: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			laroute,
		};
	},
	computed: {
		menu() {
			const getStvFsgImprintLink = () => {
				if (window.locale === 'fr') {
					return 'https://www.stv-fsg.ch/fr/impressum.html';
				}
				return 'https://www.stv-fsg.ch/de/impressum.html';
			};
			const getStvFsgTermsLink = () => {
				if (window.locale === 'fr') {
					return 'https://www.stv-fsg.ch/fr/declaration-sur-la-protection-des-donnees.html';
				}
				return 'https://www.stv-fsg.ch/de/datenschutz.html ';
			};

			const menu = [
				{
					text:     $I18n.trans('commons.contact'),
					emit:     'modal:ContactForm',
					isActive: false,
				},
				{
					text: $I18n.trans('commons.imprint'),
					link: this.$arenaConfig.isStvFsg
						? getStvFsgImprintLink()
						: laroute.route('arena.imprint'),
					isActive: window.location.pathname === laroute.route('arena.imprint'),
				},
				{
					text: $I18n.trans('commons.terms'),
					link: this.$arenaConfig.isStvFsg
						? getStvFsgTermsLink()
						: laroute.route('arena.terms'),
					isActive: window.location.pathname === laroute.route('arena.terms'),
				},
				{
					text:     $I18n.trans('arena.device_support.title'),
					link:     laroute.route('arena.device_support'),
					isActive: window.location.pathname === laroute.route('arena.device_support'),
				},
			];

			if (this.showCookiesMenu) {
				menu.push(
					{
						text:     $I18n.trans('arena.sportpass.cookies'),
						link:     laroute.route('arena.cookies'),
						isActive: window.location.pathname === laroute.route('arena.cookies'),
					}
				);
			}

			if (this.$arenaConfig.about) {
				menu.splice(1, 0, {
					text:     $I18n.trans('commons.about', { name: 'Sportpass' }),
					link:     laroute.route('arena.about'),
					isActive: window.location.pathname === laroute.route('arena.about'),
				});
			}

			return menu;
		},
	},
	methods: {
		getSocialLinkIcon(link) {
			switch (true) {
			case link.includes('instagram'):
				return 'logo-instagram-filled';
			case link.includes('facebook'):
				return 'logo-facebook-circle';
			case link.includes('twitter'):
				return 'logo-x';
			case link.includes('tiktok'):
				return 'logo-tiktok';
			case link.includes('youtube'):
				return 'logo-youtube-filled';
			case link.includes('linkedin'):
				return 'logo-linkedin-filled';
			default:
				return null;
			}
		},
		handleClick(item) {
			if (item.emit) {
				this.eventBus.$emit(item.emit);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.footer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	background-color: var(--color-grey-900);
	padding-top: $sp5;
	padding-bottom: $sp5;
	padding-right: $grid-gutter-width;
	padding-left: $grid-gutter-width;
	width: 100%;
	// the follwing styles are required due to SDX conflicts
	z-index: 1;
	overflow: initial;

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-grey-950);
		border-top: 1px solid var(--color-grey-900);
	}

	&.is-hidden-on-event {
		body.is-player-mode & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			display: none;
		}
	}

	@include size(md-up) {
		justify-content: space-between;
		flex-direction: row;
	}
}

.footer__logo-w {
	@include size(sm-down) {
		border-bottom: 1px solid var(--color-grey-800);
		width: 100%;
		text-align: center;
		margin-bottom: $sp4;
		padding-bottom: $sp3;
	}

	@include size(md-up) {
		padding-top: $sp3;
	}
}

.footer__logo-text {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-400);
	margin-bottom: $sp3;
}

.footer__logo {
	height: 25px;
	width: 100%;
	margin-bottom: $sp4;
	object-fit: contain;

	&[src$='sport-austria-finals.png'] {
		height: 46px;
	}

	@include size(md-up) {
		width: auto;
		justify-content: space-between;
		margin-bottom: 0;

		body.-t-oeoc & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			transform: scale(1.8);
		}
	}
}

.footer__nav-w {
	flex-grow: 1;
	align-self: center;
}

.footer__nav {
	display: flex;
	flex-wrap: wrap;
	flex-grow: 1;
	list-style: none;
	justify-content: flex-end;
	gap: $sp3;

	@include size(md-up) {
		gap: $sp5;
	}
}

.footer__nav-item {
	display: block;
	width: 100%;
	text-align: center;

	@include size(md-up) {
		width: auto;
		text-align: left;
	}
}

.footer__link {
	display: inline-block;
	// the following !important's are required due to SDX conflicts
	color: var(--color-grey-400) !important; // stylelint-disable-line declaration-no-important
	padding-top: $sp3;
	padding-bottom: $sp3;
	padding-left: $sp4;
	padding-right: $sp4;
	cursor: pointer;

	.footer__nav-item:last-child & {
		padding-right: 0;
	}

	&.is-active,
	&:hover {
		text-decoration: underline;
	}
}

.footer__social {
	display: flex;
	justify-content: center;
	gap: $sp5;
	margin-top: $sp5;

	@include size(md-up) {
		justify-content: flex-end;
		margin-top: $sp3;
	}
}

.footer__social-link {
	padding: $sp3;
	color: var(--color-grey-100) !important; // stylelint-disable-line declaration-no-important

	&:hover {
		color: var(--color-hype-yellow) !important; // stylelint-disable-line declaration-no-important
	}
}

</style>
