<template>
	<div
		class="v2-input-toggle"
		:class="[
			`-size-${size}`,
			disabled ? 'is-disabled' : '',
			busy ? 'is-busy' : '',
			darkTheme ? '-t-dark' : '',
		]"
	>
		<V2InputLabel
			v-if="label"
			:text="label"
			:info="info"
			:hide-optional="true"
		/>

		<div class="v2-input-toggle__row-w">
			<div
				v-if="text && textAlignment === 'before'"
				class="v2-input-toggle__text -before"
			>
				<Tooltip
					v-if="info && !label"
					:text="info"
					position="top-center"
				/>
				{{ text }}
			</div>
			<div
				class="v2-input-toggle__input"
				:class="{'is-active': toggleState}"
				@click="toggle"
			/>
			<div
				v-if="text && textAlignment === 'after'"
				class="v2-input-toggle__text -after"
			>
				{{ text }}
				<Tooltip
					v-if="info && !label"
					:text="info"
					position="top-center"
				/>
			</div>
		</div>
		<input
			v-show="false"
			:value="toggleState"
			type="checkbox"
			:name="name"
			:disabled="disabled"
		>
		<div
			v-if="textAfter"
			class="v2-input-toggle__text-after"
			:class="[`-${textAfterAlignment}`]"
		>
			{{ textAfter }}
		</div>
	</div>
</template>

<script>
import V2InputLabel from '@shared/components/Form/InputLabel';
import Tooltip from '@shared/components/Tooltip';

const sizes = ['sm', 'md'];
const textAlignment = ['before', 'after'];

export default {
	name:       'InputToggle',
	components: { V2InputLabel, Tooltip },
	props:      {
		label: {
			type:    String,
			default: null,
		},
		name: {
			type:    String,
			default: '',
		},
		text: {
			type:    String,
			default: null,
		},
		textAlignment: {
			type:      String,
			default:   'after',
			validator: (alignment) => textAlignment.includes(alignment),
		},
		info: {
			type:    String,
			default: null,
		},
		value: {
			type:    Boolean,
			default: false,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		busy: {
			type:    Boolean,
			default: false,
		},
		size: {
			type:      String,
			default:   'md',
			validator: (size) => sizes.includes(size),
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		textAfter: {
			type:    String,
			default: null,
		},
		textAfterAlignment: {
			type:    String,
			default: 'right',
		},
	},
	data() {
		return {
			toggleState: this.value,
		};
	},
	watch: {
		value() {
			this.toggleState = this.value;
		},
	},
	methods: {
		toggle() {
			if (this.disabled || this.busy) {
				return;
			};
			this.toggleState = !this.toggleState;
			this.$emit('input', this.toggleState);
		},
	},

};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@shared/sass/shared-variables';

$toggle-width--md: 58px;
$toggle-height--md: 28px;

$toggle-width--sm: 46px;
$toggle-height--sm: 22px;

.v2-input-toggle {
	margin-bottom: $form-margin-bottom;
	user-select: none;
}

.v2-input-toggle__row-w {
	display: flex;
	align-items: center;
}

.v2-input-toggle__input {
	position: relative;
	height: $toggle-height--md;
	width: $toggle-width--md;
	background-color: var(--color-grey-100);
	border-radius: math.div($toggle-height--md, 2);
	transition: background-color $trans-time-fast;
	will-change: background-color;

	&::after {
		display: block;
		content: '';
		width: $toggle-height--md;
		height: $toggle-height--md;
		border-radius: 50%;
		background-color: var(--color-white);
		border: 2px solid var(--color-grey-300);
		transition: transform $trans-time-fast;
		will-change: transform;
	}

	&.is-active {
		background-color: var(--color-green-petrol);

		.v2-input-toggle.-t-dark & {
			background-color: var(--color-hype-yellow);
		}

		&::after {
			transform: translateX(#{$toggle-width--md - $toggle-height--md});
		}
	}

	.v2-input-toggle.-size-sm & {
		height: $toggle-height--sm;
		width: $toggle-width--sm;
		border-radius: math.div($toggle-height--sm, 2);

		&::after {
			width: $toggle-height--sm;
			height: $toggle-height--sm;
		}

		&.is-active {
			&::after { // stylelint-disable-line max-nesting-depth
				transform: translateX(#{$toggle-width--sm - $toggle-height--sm});
			}
		}
	}

	.v2-input-toggle.is-busy &,
	.v2-input-toggle.is-disabled & {
		pointer-events: none;
		opacity: 0.5;
	}

	&:hover {
		cursor: pointer;
	}
}

.v2-input-toggle__text {
	&.-after {
		margin-left: $sp3;
	}

	&.-before {
		margin-right: $sp3;
	}

	.v2-input-toggle.-size-sm & {
		@include font-size(fs-80);
	}
}

.v2-input-toggle__text-after {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-500);
	text-align: right;
	padding-top: $sp3;

	&.-left {
		text-align: left;
	}
}

</style>
