<template>
	<Layout>
		<div class="l-container">
			<div class="view-generic">
				<div class="l-row l-align-center">
					<div class="l-col l-col--12 l-col--8@lg">
						<!-- eslint-disable vue/no-v-html -->
						<div
							class="view-generic__content s-html"
							v-html="content"
						/>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';

export default {
	name:       'ViewGeneric',
	components: { Layout },
	props:      {
		content: {
			type:     String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.view-generic {
	margin-top: $sp7;
	margin-bottom: $sp7;
}

</style>
