<template>
	<div
		v-if="events.length || isLoading"
		class="l-row"
	>
		<div
			v-for="event in events"
			:key="event.id"
			class="l-col l-col--12 l-col--6@sm l-col--4@md l-col--3@lg"
		>
			<EventCard
				v-bind="EventCardProps(event, {
					flexibleSize: true,
					showEventTags: false,
					openInNewTab: isAlbumEmbed,
					hidePaymentRestriction:	hasAccessToEvent(event)
				})"
			/>
		</div>
	</div>
	<EmptyState
		v-else
		:text="emptyMessage"
	/>
</template>

<script>
import EventCard from '@shared/components/EventCard/EventCard.vue';
import EmptyState from '@js/components/EmptyState';
import { EventCardProps } from '@shared/components/EventCard/EventCardProps';
import { mapGetters } from 'vuex';

export default {
	name:       'EventsGrid',
	components: { EventCard, EmptyState },
	props:      {
		events: {
			type:    Array,
			default: () => [],
		},
		isLoading: {
			type:    Boolean,
			default: false,
		},
		emptyMessage: {
			type:     String,
			required: true,
		},
		isAlbumEmbed: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			EventCardProps,
		};
	},
	computed: {
		...mapGetters({
			hasAccessToEvent: 'user/hasAccessToEvent',
		}),
	},
};
</script>

<style lang="scss" scoped>
.events-grid {
	display: flex;
	flex-wrap: wrap;
}
</style>
