<template>
	<div class="fan-account-email">
		<div v-if="!pwIsSet">
			{{ $I18n.trans('arena.login.login.email_update_pw_required') }}
		</div>
		<form v-else-if="!updateEmailRequestSent">
			<InputText
				ref="email"
				name="email"
				type="email"
				pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
				required
				:label="$I18n.trans('auth.labels.new_email')"
				autocomplete="username"
				:dark-theme="!$arenaConfig.isLightTheme"
				:value="newEmail"
				@input="handleEmailInput"
			/>
			<InputText
				ref="password"
				name="password"
				type="password"
				pattern=".{8,}"
				min-length="8"
				:custom-error-msg="$I18n.trans('arena.login.login.password_hint')"
				:label="$I18n.trans('commons.password')"
				autocomplete="password"
				:dark-theme="!$arenaConfig.isLightTheme"
				required
				:value="password"
				@input="handlePasswordInput"
			/>

			<div
				v-if="error"
				class="fan-account-email__hint -error"
			>
				<V2Icon
					icon-name="c-info"
					size="md"
				/>
				{{ error === 'InvalidCredentials' ? $I18n.trans('arena.login.login.invalid_password') : error }}
			</div>

			<div class="fan-account-email__submit-w">
				<Button
					:text="$I18n.trans('commons.type_update', {type: $I18n.trans('commons.email')})"
					:disabled="!formIsValid"
					:busy="isLoading"
					theme="dark"
					@click="submitNewEmail()"
				/>
			</div>
		</form>
		<InfoBox
			v-else
			:title="$I18n.trans('arena.login.check_email.title')"
			:copy="$I18n.trans('arena.login.check_email.email_update_subtitle', { email: newEmail } )"
			:info="$I18n.trans('arena.login.check_email.help')"
		/>
	</div>
</template>

<script>
import { authApi } from '@js/api/client';
import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';
import InfoBox from '@js/components/InfoBox';
import InputText from '@shared/components/Form/InputText';
import laroute from '@laroute';

export default {
	name:       'FanAccountEmail',
	components: {
		InputText,
		InfoBox,
		Button,
		V2Icon,
	},
	props: {
		pwIsSet: {
			type:     Boolean,
			required: true,
		},
	},
	data() {
		return {
			newEmail:               '',
			password:               '',
			isLoading:              false,
			error:                  null,
			formIsValid:            false,
			updateEmailRequestSent: false,
		};
	},
	methods: {
		async submitNewEmail() {
			try {
				this.isLoading = true;
				const response = await authApi.post(laroute.route('arena.api.arena_login.update_email'), {
					newEmail: this.newEmail,
					password: this.password,
				});
				const responseContent = await response.json();
				if (responseContent.status === 'ok') {
					this.updateEmailRequestSent = true;
				} else if (responseContent && responseContent.reason) {
					this.error = responseContent.reason;
				}
			} catch (error) {
				this.$toast.error(this.$I18n.trans('errors.500.title'));
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
		handleEmailInput(data) {
			this.newEmail = data;
			this.checkFormValidity();
		},
		handlePasswordInput(data) {
			this.password = data;
			this.checkFormValidity();
		},
		checkFormValidity() {
			this.formIsValid = this.$refs.password?.isValid && this.$refs.email?.isValid;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.fan-account-email__submit-w {
	display: flex;
	justify-content: flex-end;
}

.fan-account-email__hint {
	margin-bottom: $sp6;
	display: flex;
	align-items: center;
	gap: $sp3;

	&.-error {
		color: var(--color-red);
	}
}

</style>
