<template>
	<div class="purchases">
		<LoadingSpinner
			v-if="!profile"
			class="purchases__loading-spinner"
		/>
		<template v-else>
			<!-- SUBSCRIPTIONS -->
			<div class="purchases__section">
				<SectionTitle
					:title="$I18n.trans('arena.fan_account.purchases.subscriptions.title')"
					type="md"
				/>
				<div
					v-if="subscriptions.length === 0"
					class="purchases__box"
				>
					{{ $I18n.trans('arena.fan_account.purchases.subscriptions.empty', { mail: profile.email }) }}
				</div>

				<div
					v-for="(subscription, i) in subscriptions"
					:key="i"
				>
					<SectionTitle
						v-if="i === firstArchivedSubscriptionIndex"
						:title="$I18n.trans('commons.archive')"
						type="sm"
					/>
					<div
						class="purchases__box"
						:class="{'is-archived': !subscription.isActive || subscription.refunded}"
					>
						<img
							class="purchases__box-image"
							:src="$arenaConfig.headerImageUrl"
						>
						<div class="purchases__box-text-w">
							<h4 class="purchases__box-title">
								{{ $Helpers.getTranslatedContent(subscription.name) }}
							</h4>
							<div
								v-if="subscription.refunded"
								class="purchases__box-content -attention"
							>
								{{ $I18n.trans('arena.fan_account.purchases.refunded') }}<br>
							</div>
							<div
								v-else
								class="purchases__box-content"
							>
								{{ subscription.activatedOn }}<br>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- EVENTS -->
			<div class="purchases__section">
				<SectionTitle
					:title="$I18n.trans('arena.fan_account.purchases.events.title')"
					type="md"
				/>
				<div
					v-if="events.length === 0"
					class="purchases__box"
				>
					{{ $I18n.trans('arena.fan_account.purchases.events.empty') }}
				</div>
				<table
					v-else
					class="purchases__table"
				>
					<tr>
						<td class="purchases__table-el -header">
							{{ $I18n.trans_choice('commons.event', 1) }}
						</td>
						<td class="purchases__table-el -header">
							{{ $I18n.trans('arena.fan_account.purchases.events.table_labels.amount') }}
						</td>
						<td class="purchases__table-el -header">
							{{ $I18n.trans('arena.fan_account.purchases.events.table_labels.pruchase_date') }}
						</td>
						<td class="purchases__table-el -header u-show-as-table-cell@md">
							{{ $I18n.trans('arena.fan_account.purchases.events.table_labels.order_code') }}
						</td>
						<td class="purchases__table-el -header" />
					</tr>
					<tr
						v-for="(event, i) in events"
						:key="i"
						class="purchases__table-row"
						:class="{'was-refunded': event.refunded}"
					>
						<td class="purchases__table-el -body">
							<a
								class="purchases__table-row-link"
								:href="event.link"
							/>
							{{ event.eventName }}<br>
							{{ event.eventDate }}
						</td>
						<td class="purchases__table-el -body">
							<span
								v-if="event.paymentType === 'voucher'"
								class="purchases__voucher"
							>
								{{ $I18n.trans('arena.fan_account.purchases.with_voucher') }}
							</span>
							<span v-else>
								{{ event.price }}
							</span>
						</td>
						<td class="purchases__table-el -body">
							{{ event.orderDate }}
						</td>
						<td class="purchases__table-el -body u-show-as-table-cell@md">
							{{ event.orderCode }}
						</td>
						<td class="purchases__table-el -body -attention">
							<template v-if="event.refunded">
								<span class="u-show@md">{{ $I18n.trans('arena.fan_account.purchases.refunded') }}</span>
								<span class="u-hide@md">•</span>
							</template>
						</td>
					</tr>
				</table>
			</div>
		</template>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { mapState } from 'vuex';
import { getDateTime } from '@shared/utils/timeUtils';
import { formatCurrency } from '@shared/utils/formatNumber';
import { OrderStatus } from '@shared/enums/OrderStatus';
import { OfferValidity } from '@shared/enums/OfferValidity';
import laroute from '@laroute';

export default {
	name:       'Purchases',
	components: { SectionTitle, LoadingSpinner },
	computed:   {
		...mapState({
			profile: state => state.user.profile,
		}),
		subscriptions() {
			return (this.profile?.allResources?.subscriptions || [])
				.map(subscription => {
					return {
						...subscription,
						isActive:    subscription.validity === OfferValidity.PERMANENT || new Date(subscription.expiresAt) > new Date(),
						refunded:    subscription.status === OrderStatus.REFUNDED,
						activatedOn: $I18n.trans('arena.fan_account.purchases.subscriptions.activated_on', { date: getDateTime(new Date(subscription.createdAt).getTime() / 1000, { withTime: false }) }),
					};
				}).sort((a, b) => a.isActive && !b.isActive ? -1 : 1);
		},
		events() {
			return (this.profile?.allResources?.events || [])
				.map(event => {
					return {
						eventName:   event.name,
						eventDate:   getDateTime(new Date(event.start).getTime() / 1000, { withTime: false }),
						orderDate:   getDateTime(new Date(event.createdAt).getTime() / 1000, { withTime: false }),
						price:       formatCurrency(event.currencyCode, event.amount),
						refunded:    event.status === OrderStatus.REFUNDED,
						orderCode:   event.code,
						paymentType: event.paymentType,
						link: 	      laroute.route('arena.event', {
							event: event.eventId,
							slug:  this.$Helpers.slugify(event.nameTranslations?.[this.$arenaConfig.defaultLanguage] || event.name),
						}),
					};
				}).sort((a, b) => a.eventDate > b.eventDate ? -1 : 1);
		},
		firstArchivedSubscriptionIndex() {
			return this.subscriptions.findIndex(subscription => !subscription.isActive);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.purchases {
	position: relative;
}

.purchases__section {
	margin-bottom: $sp6;
}

.purchases__loading-spinner {
	position: absolute;
	right: 50%;
	top: 10vh;
	transform: translateX(-50%);
}

.purchases__box {
	display: flex;
	gap: $sp4;
	padding: $sp4;
	align-items: flex-start;
	border: 1px solid var(--color-grey-800);
	border-radius: $border-radius;
	background-color: var(--color-grey-900);
	margin-bottom: $sp5;
}

.purchases__box-image {
	max-width: 56px;
	max-height: 56px;
}

.purchases__box-text-w {
	.purchases__box.is-archived & {
		color: var(--color-grey-500);
	}
}

.purchases__box-title {
	@include font-size(fs-130);
	margin-bottom: $sp3;
}

.purchases__box-content {
	&.-attention {
		color: var(--color-dimmed-orange);
	}

	.purchases__box:not(.is-archived) & {
		color: var(--color-dimmed-green);
	}
}

.purchases__table {
	width: 100%;
	border-collapse: collapse;
}

.purchases__table-row {
	position: relative;

	&:nth-child(odd) {
		background-color: var(--color-grey-950);
	}

	&:hover {
		background-color: var(--color-grey-900);
	}
}

.purchases__table-el {
	padding: $sp3;
	border-bottom: 1px solid var(--color-grey-900);

	&.-header {
		@include font(primary, medium, normal, fs-80);
		color: var(--color-grey-300);
	}

	&.-body {
		@include font(primary, book, normal, fs-80);
		vertical-align: middle;
	}

	&.-attention {
		color: var(--color-dimmed-orange);
	}

	@include size(sm-up) {
		padding: $sp4;
	}
}

.purchases__table-row-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.purchases__voucher {
	padding: 5px 10px;
	display: inline-block;
	background-color: var(--color-hype-yellow--inactive);
	color: var(--color-grey-950);
}

</style>
