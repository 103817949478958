<template>
	<a
		:href="arenaUrl"
		:target="isExternalLink ? '_blank' : false"
		class="l-carousel-wrapper-sports card-team"
	>
		<div
			class="card-team__inner"
			:class="{ '-has-icon': showExternalLinkIcon }"
		>
			<div class="card-team__content">
				<h4
					class="card-team__title"
					v-text="title"
				/>
				<h5
					class="card-team__pretitle"
					v-text="$I18n.trans('team.category.' + item.category)"
				/>
			</div>
			<div
				v-if="showExternalLinkIcon"
				class="card-team__icon"
			>
				<V2Icon
					class="card-team__icon"
					icon-name="launch"
					size="sm"
				/>
			</div>
		</div>
	</a>
</template>

<script>
import laroute from '@laroute';
import TeamCardMixin from './TeamCardMixin';
/**
 * INFO: This card is only used on the Club page where we don't want to show
 * the logo (would be the same for the majority).
 */

export default {
	name:   'TeamCardSmall',
	mixins: [TeamCardMixin],
	props:  {
		item: {
			type:     Object,
			required: true,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
	},
	data() {
		return {
			laroute,
		};
	},
	computed: {
		title() {
			return this.item.league?.name || this.item?.name || '';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-team {
	display: inline-block;
}

.card-team__inner {
	background-color: var(--color-grey-900);
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	padding: $sp4;
	border: 1px solid var(--color-grey-800);
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;
	cursor: pointer;
	display: flex;

	.is-keynav-section-active .is-selected & {
		transform: scale(1.09);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include box-shadow(1);
		background-color: var(--color-white);
		border: none;
	}

	&:focus,
	&:hover {
		transform: scale(1.09);
	}
}

.card-team__pretitle {
	@include font-size(fs-80);

	color: var(--color-grey-300);
}

.card-team__title {
	@include font(primary, medium, normal, fs-120);

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include font(primary, bold, normal, fs-120);
	}
}
</style>
