<template>
	<Layout>
		<div class="l-container">
			<div class="l-row l-align-center">
				<div class="l-col l-col--12 l-col--8@lg">
					<DeviceSupport :is-manager="false" />
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import DeviceSupport from '@shared/components/DeviceSupport';

export default {
	name:       'ViewDeviceSupport',
	components: { Layout, DeviceSupport },
};
</script>

<style lang="scss" scoped>
</style>
