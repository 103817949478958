<template>
	<div class="nav-sub js-nav-level">
		<component
			:is="item.link ? 'a' : 'div'"
			v-for="item in navItems"
			:key="item.id"
			:href="item.link"
			:target="mixinIsExternalLink(item.link) ? '_blank' : '_self'"
			class="nav-sub__item"
			:class="{
				'is-active': item.id === openItemId && item.children && item.children.length > 0,
			}"
			@mouseover.stop="openChildren(item.id)"
		>
			<div class="nav-sub__item-inner-w">
				<span class="nav-sub__item-text">{{ item.title }}</span>
				<V2Icon
					v-if="mixinIsExternalLink(item.link)"
					icon-name="launch"
					size="xxs"
				/>
				<V2Icon
					v-if="item.children && item.children.length > 0"
					icon-name="right-arrow"
					size="sm"
				/>
			</div>
			<NavSub
				v-if="item.children && item.children.length > 0 && item.id === openItemId"
				class="-child"
				:nav-items="item.children"
			/>
		</component>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

export default {
	name:       'NavSub',
	components: { V2Icon },
	props:      {
		navItems: {
			type:    Array,
			default: () => [],
		},
	},
	data() {
		return {
			openItemId: null,
		};
	},
	methods: {
		openChildren(itemId) {
			this.openItemId = itemId;
		},
	},
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@sass/variables';

$column-gap: $sp6;
$column-width: calc((100% / 3) - #{math.div(($column-gap * 2), 3)});

.nav-sub {
	display: flex;
	flex-direction: column;
	width: $column-width;
	position: relative;
	gap: 0 $sp3;

	.nav-sub.-child {
		position: absolute;
		top: 0;
		left: calc(100% + $column-gap);
		width: 100%;
		height: 100%;
	}
}

.nav-sub__item {
	display: flex;
	width: 100%;
	color: var(--color-grey-600);
	cursor: pointer;
	user-select: none;

	.nav-sub__item {
		width: 100%;
	}

	&:hover,
	&.is-active {
		color: var(--color-white);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			color: var(--color-black);
		}
	}
}

:not(a).nav-sub__item { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
	cursor: auto !important; // stylelint-disable-line declaration-no-important
}

.nav-sub__item-inner-w {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: $sp4 0;
	white-space: nowrap;

	.nav-sub__item:not(:last-child) & {
		border-bottom: 1px solid var(--color-grey-900);
	}
}

.nav-sub__item-text {
	@include ellipsis;
}
</style>
