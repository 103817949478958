<template>
	<div
		v-if="!errorLoading"
		class="x-lane l-content-section -extra-spacing -overlays"
		:class="{ 'has-mobile-image': imageMobileUrl }"
	>
		<div
			v-if="isIframe"
		>
			<!-- eslint-disable vue/no-v-html -->
			<div
				class="x-lane__inner-w"
				:class="{ 'has-loaded': hasLoaded }"
				:data-text="$I18n.trans('monetization.advertisement')"
				v-html="iframeCode"
			/>
		</div>
		<component
			:is="link ? 'a' : 'div'"
			v-else
			:href="link"
			:target="link ? '_blank' : null"
			rel="noopener"
			class="x-lane__inner-w"
			:class="{ 'has-loaded': hasLoaded }"
			:data-text="$I18n.trans('monetization.advertisement')"
		>
			<img
				v-if="imageUrl"
				ref="imageDesktop"
				:src="imageUrl"
				:alt="name || ''"
				class="x-lane__image -desktop"
				:style="[maxWidth ? { maxWidth } : null]"
			>
			<img
				v-if="imageMobileUrl"
				ref="imageMobile"
				:src="imageMobileUrl"
				:alt="name || ''"
				class="x-lane__image -mobile"
				:style="[maxWidth ? { maxWidth } : null]"
			>
		</component>
	</div>
</template>

<script>
import AdsMixin from '@shared/js/mixins/AdsMixin';

export default {
	name:   'AdLane',
	mixins: [AdsMixin],
	props:  {
		ad: {
			type:     Object,
			required: true,
		},
	},
	data() {
		return {
			imageUrl:       null,
			imageMobileUrl: null,
			name:           null,
			link:           null,
			hasLoaded:      false,
			isIframe:       false,
			iframeCode:     null,
			errorLoading:   false,
			maxWidth:       null,
		};
	},
	mounted() {
		if (this.isStaticAd) {
			this.imageUrl = this.ad.imageUrl;
			this.imageMobileUrl = this.ad.imageMobileUrl;
			this.name = this.ad.name;
			this.link = this.ad.link;

			this.$nextTick(() => {
				this.$refs.imageDesktop.addEventListener('load', () => {
					this.hasLoaded = true;
				});

				if (this.ad.imageMobileUrl) {
					this.$refs.imageMobile.addEventListener('load', () => {
						this.hasLoaded = true;
					});
				}
			});
		} else if (this.isAdButlerAd) {
			this.fetchAdbutlerAd(this.adButlerCallback);
		}
	},
	methods: {
		adButlerCallback(adButlerResponse) {
			// Error handling
			if (!adButlerResponse || adButlerResponse.placements?.placement_1 === undefined) {
				this.errorLoading = true;
				return;
			}
			if (adButlerResponse.status === 'SUCCESS' && adButlerResponse.placements?.placement_1) {
				const placement = adButlerResponse.placements?.placement_1;

				if (placement.body) {
					this.iframeCode = placement.body;
					this.isIframe = true;
					this.hasLoaded = true;
				} else if (placement.image_url) {
					this.imageUrl = placement.image_url;
					this.imageMobileUrl = placement.image_url;
					this.link = placement.redirect_url;
					this.maxWidth = placement.width ? `${placement.width}px` : null;

					this.$nextTick(() => {
						if (this.$refs.imageDesktop) {
							this.$refs.imageDesktop.addEventListener('load', () => {
								this.hasLoaded = true;
							});
						}

						if (this.$refs.imageMobile) {
							this.$refs.imageMobile.addEventListener('load', () => {
								this.hasLoaded = true;
							});
						}
					});
				}

				if (parseInt(placement.refresh_time) > 0) {
					setTimeout(() => {
						this.fetchAdbutlerAd(this.adButlerCallback);
					}, parseInt(placement.refresh_time) * 1000);
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.x-lane {
	display: flex;
	justify-content: center;
	align-items: center;
}

.x-lane__inner-w {
	@include font-size(fs-80);

	position: relative;

	&.has-loaded::after {
		display: block;
		content: attr(data-text);
		position: absolute;
		right: 2px;
		bottom: calc(100% - -8px);
		font-size: 14px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		color: var(--color-grey-300);
	}
}

.x-lane__image {
	&.-desktop {
		width: 100%;
		max-width: 970px;
		max-height: 250px;
		display: block;

	}

	&.-mobile {
		display: block;
		width: auto;
		max-width: 320px;

		@include size(sm-up) {
			display: none;
		}
	}

	.x-lane.has-mobile-image & {
		&.-desktop {
			@include size(xs-only) {
				display: none;
			}
		}
	}
}
</style>
