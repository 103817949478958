<template>
	<div
		v-click-outside="hideTouchOverlay"
		class="event-card"
		:class="[{
			'-large' : large,
			'-flexible' : flexibleSize,
			'l-carousel-wrapper-event': !flexibleSize && !isEmbed,
			'-with-image': withImage,
			'-with-infos': showInfos,
			'is-embed': isEmbed,
			'-couchmode': couchModeAvailable,
			'is-landscape': isLandscape,
			'is-touch': isTouchDevice,
			'is-external': isExternalEvent,
		}, `is-${status}`]"
		@click="handleCardClick"
	>
		<div class="event-card__link-w">
			<a
				v-if="!isEmbed"
				class="event-card__link"
				:href="link"
				:target="openInNewTab ? '_blank' : '_self'"
			/>
			<Icon
				v-if="icsDownloadEnabled"
				class="event-card__notification"
				icon-name="alarm"
				size="lg"
				@click="handleIcsRequest"
			/>
			<div class="event-card__aspect-ratio-w">
				<div
					ref="scaleOuter"
					class="event-card__inner"
				>
					<img
						v-if="withImage"
						class="event-card__bg-image"
						:src="currentBgImgUrl"
					>
					<div
						ref="scaleInner"
						class="event-card__scale-w"
						:class="{'-match': isMatch}"
					>
						<div
							v-if="status === EVENT_STATUS.UPCOMING && !withImage"
							class="event-card__bg-pattern"
						>
							<div class="event-card__bg-pattern-el -left" />
							<div class="event-card__bg-pattern-el -right" />
						</div>
						<div
							v-if="calendarIconOverImage"
							class="event-card__upcoming-calendar"
						>
							<svg class="event-card__upcoming-calendar-icon">
								<use :href="`${calendarIconOverImage}`" />
							</svg>
						</div>
						<div class="event-card__header">
							<div class="event-card__tags-w">
								<BadgeLive
									v-if="status === EVENT_STATUS.LIVE"
									modifier="small"
								/>
								<div
									v-if="!isEmbed && countDownTag"
									class="event-card__tag"
								>
									<span>{{ countDownTag }}</span>
								</div>
								<div
									v-for="tag in tags"
									v-show="tag.type === 'highlight' || showEventTags"
									:key="tag.label"
									class="event-card__tag"
									:class="{'-highlight': tag.type === 'highlight'}"
								>
									<span>{{ tag.label }}</span>
								</div>
								<!-- eslint-disable vue/no-v-html -->
								<div
									v-if="audioLanguagesTag"
									class="event-card__tag"
									v-html="audioLanguagesTag"
								/>
								<div
									v-if="isPaymentRestricted"
									class="event-card__tag -icon"
									:class="{'is-unlocked': isUnlocked}"
								>
									<Icon
										:icon-name="isUnlocked ? 'unlocked' : 'lock'"
										size="xxs"
									/>
								</div>
							</div>
						</div>
						<div
							v-if="showPlayIcon"
							class="event-card__play"
						>
							<Icon
								icon-name="button-play-filled"
								size="lg"
							/>
						</div>
						<div
							v-if="couchModeAvailable && (isTouchDevice ? isLandscape : true)"
							class="event-card__couchmode-overlay"
							:class="{'-touch-active': touchOverlayActive}"
						>
							<a
								class="event-card__couchmode-overlay-text -left"
								:href="link"
								:target="openInNewTab ? '_blank' : '_self'"
							>
								<div>{{ isMatch ? $I18n.trans('arena.whole_game') : $I18n.trans('arena.whole_event') }}</div>
								<div class="event-card__couchmode-overlay-info">{{ duration }}</div>
							</a>
							<a
								class="event-card__couchmode-overlay-text -right"
								:href="link + '?autostartCouchMode'"
								:target="openInNewTab ? '_blank' : '_self'"
							>
								<div>{{ $I18n.trans('view_mode.couch_mode') }}</div>
								<div class="event-card__couchmode-overlay-info">
									{{ couchModeClipsCount }} {{ $I18n.trans_choice('commons.clip', couchModeClipsCount) }}
								</div>
							</a>
						</div>
						<div
							v-if="isMatch && !placeholderText && status === EVENT_STATUS.UPCOMING"
							class="event-card__teams"
						>
							<span
								v-if="homeImgUrl"
								class="event-card__team"
								:style="{ backgroundImage: `url(${$Helpers.getScaledImageUrl(homeImgUrl, 180)})` }"
							/>
							<span
								v-if="guestImgUrl"
								class="event-card__team"
								:style="{ backgroundImage: `url(${$Helpers.getScaledImageUrl(guestImgUrl, 180)})` }"
							/>
						</div>
						<div
							v-if="isTeamTraining && teamLogo && status === EVENT_STATUS.UPCOMING"
							class="event-card__teams"
						>
							<span
								class="event-card__team"
								:style="{ backgroundImage: `url(${$Helpers.getScaledImageUrl(homeImgUrl, 180)})` }"
							/>
						</div>
						<div
							v-if="showSportIcon"
							class="event-card__sport-icon"
						>
							<svg>
								<use :href="`${sportIcon}`" />
							</svg>
						</div>
						<div
							v-if="showCountdown && status === EVENT_STATUS.UPCOMING"
							class="event-card__countdown"
						>
							<Countdown
								v-if="liveInSeconds"
								size="sm"
								:seconds="liveInSeconds"
								:show-days="true"
								:show-seconds="false"
							/>
						</div>
						<div
							v-if="placeholderText && isEmbed"
							class="event-card__placeholder-text"
						>
							{{ placeholderText[$arenaConfig.currentLanguageKey] }}
						</div>
						<div
							v-if="(isLeagueMatch || isLeagueHighLight) && leagueName && !isEmbed"
							class="event-card__league"
						>
							<img
								v-if="leagueLogo"
								:src="$Helpers.getScaledImageUrl(leagueLogo, 32)"
								:alt="leagueName"
								class="event-card__league-image"
							>
							<span
								v-if="leagueName"
								class="event-card__league-title"
							>{{ leagueName }}</span>
						</div>
						<div
							v-if="showVideoDuration"
							class="event-card__video-duration"
						>
							{{ duration }}
						</div>
						<div
							v-if="showInfos"
							class="event-card__infos-w -for-large-card"
						>
							<EventInfos
								class="event-card__infos"
								:large="true"
								:show-scores="isMatch && hasTeams && status !== EVENT_STATUS.UPCOMING"
								:home-name="homeName"
								:home-score="homeScore"
								:home-img-url="homeImgUrl"
								:guest-name="guestName"
								:guest-score="guestScore"
								:guest-img-url="guestImgUrl"
								:pre-title="infoPreTitle"
								:title="infoTitle"
								:large-title="(!isMatch || !hasTeams) || status === EVENT_STATUS.UPCOMING"
								:sub-title="infoSubTitle"
								:is-embed="isEmbed"
							/>
							<div
								v-if="showEmbedPaymentButton"
								class="event-card__payment-button-w"
							>
								<Button
									size="small"
									theme="dark"
									:text="$I18n.trans('payments.preview.buy_now')"
									:link="rawEvent.url"
									target="_blank"
								/>
							</div>
						</div>
						<img
							v-if="isExternalEvent"
							:src="externalEventPlatformIcon"
							class="event-card__external-platform-icon"
						>
					</div>
				</div>
			</div>
			<div
				v-if="showInfos"
				class="event-card__infos-w -for-small-card"
			>
				<EventInfos
					class="event-card__infos"
					:large="false"
					:show-scores="isMatch && hasTeams && status !== EVENT_STATUS.UPCOMING"
					:home-name="homeName"
					:home-score="homeScore"
					:home-img-url="homeImgUrl"
					:guest-name="guestName"
					:guest-score="guestScore"
					:guest-img-url="guestImgUrl"
					:score-extra-abbr="scoreExtraAbbr"
					:pre-title="infoPreTitle"
					:title="infoTitle"
					:large-title="(!isMatch || !hasTeams) || status === EVENT_STATUS.UPCOMING"
					:sub-title="isLeagueHighLight ? null : infoSubTitle"
					:is-embed="isEmbed"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
import Button from '@shared/components/Button';
import BadgeLive from '@shared/components/BadgeLive';
import EventInfos from './EventInfos';
import Countdown from '@shared/components/Countdown';
import { getDateTime, getRelativeTime, getDuration, getTimeUntil } from '@shared/utils/timeUtils';
import { getEventStatus, EVENT_STATUS } from '@shared/utils/eventUtils';
import { EVENT_TYPE } from './EventCardProps';
import { MatchScoresPropsConfig } from './MatchScoresPropsConfig';
import { EventType } from '@shared/enums/EventType';

const matchMediaIsSupported = window.matchMedia && window.matchMedia('all').addEventListener;
const landscapeQuery = matchMediaIsSupported && matchMedia('(orientation: landscape)');
const touchQuery = matchMediaIsSupported && matchMedia('(pointer: coarse)');

export default {
	name:       'EventCard',
	components: { Icon, BadgeLive, EventInfos, Countdown, Button },
	props:      {
		/**
		 * Generic props
		 */
		eventType: {
			type:      String,
			required:  true,
			validator: (type) => Object.values(EVENT_TYPE).includes(type),
		},
		type: {
			type:     String,
			required: true,
		},
		unixStart: {
			type:     Number,
			required: true,
		},
		unixEnd: {
			type:     Number,
			required: true,
		},
		unixKickoff: {
			type:     Number,
			required: true,
		},
		bgImgUrl: {
			type:    String,
			default: null,
		},
		sportIcon: {
			type:    String,
			default: null,
		},
		link: {
			type:     String,
			required: true,
		},
		leagueLogo: {
			type:    String,
			default: null,
		},
		leagueName: {
			type:    String,
			default: null,
		},
		tags: {
			type:    Array,
			default: () => [],
		},
		couchModeAvailable: {
			type:    Boolean,
			default: false,
		},
		couchModeClipsCount: {
			type:    Number,
			default: null,
		},
		teamLogo: {
			type:    String,
			default: null,
		},
		location: {
			type:    String,
			default: null,
		},
		audioLanguages: {
			type:    Array,
			default: () => [],
		},
		eventName: {
			type:    String,
			default: null,
		},
		categoryParent: {
			type:    String,
			default: null,
		},
		categoryChild: {
			type:    String,
			default: null,
		},
		rawEvent: {
			type:     Object,
			required: true,
		},
		placeholderText: {
			type:    Object,
			default: null,
		},
		isPaymentRestricted: {
			type:    Boolean,
			default: false,
		},
		isUnlocked: {
			type:    Boolean,
			default: false,
		},
		openInNewTab: {
			type:    Boolean,
			default: false,
		},
		isExternalEvent: {
			type:    Boolean,
			default: false,
		},
		externalEventPlatformIcon: {
			type:    String,
			default: null,
		},
		...MatchScoresPropsConfig,
		/**
		 * Placement specific props
		 */
		large: {
			type:    Boolean,
			default: false,
		},
		flexibleSize: {
			type:    Boolean,
			default: false,
		},
		isEmbed: {
			type:    Boolean,
			default: false,
		},
		showEventTags: {
			type:    Boolean,
			default: true,
		},
		showInfos: {
			type:    Boolean,
			default: true,
		},
		showEmbedPaymentButton: {
			type:    Boolean,
			default: false,
		},
		showCountdown: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			liveInSeconds:      null,
			touchOverlayActive: false,
			isTouchDevice:      matchMediaIsSupported ? touchQuery.matches : false,
			isLandscape:        matchMediaIsSupported ? landscapeQuery.matches : false,
			unixNow:            Math.round(new Date().getTime() / 1000),
			EVENT_STATUS,
			EVENT_TYPE,
			icsDownloadModal:   'icsDownloadModal',
			currentBgImgUrl:    null,
		};
	},
	computed: {
		withImage() {
			if (this.isMatch && this.status === EVENT_STATUS.UPCOMING && !this.isEmbed) {
				return false;
			}
			return this.bgImgUrl !== null;
		},
		duration() {
			return getDuration(this.unixEnd - this.unixStart);
		},
		status() {
			return getEventStatus(this.type, this.unixStart, this.unixEnd, this.unixNow);
		},
		gameStartDay() {
			const tomorrow = new Date();
			tomorrow.setDate(tomorrow.getDate() + 1);
			const gameStartDate = new Date(this.unixStart * 1000);
			if (gameStartDate.toLocaleDateString() === new Date().toLocaleDateString()) {
				return 'today';
			}
			if (gameStartDate.toLocaleDateString() === tomorrow.toLocaleDateString()) {
				return 'tomorrow';
			}
			return 'other';
		},
		infoPreTitle() {
			let infoPreTitle = '';

			switch (this.status) {
			case EVENT_STATUS.UPCOMING:
				if (this.gameStartDay === 'tomorrow') {
					infoPreTitle += `${this.$I18n.trans('commons.tomorrow')} · ${getDateTime(this.unixKickoff, { withDate: false })}`;
				} else if (this.gameStartDay === 'today') {
					infoPreTitle += `${this.$I18n.trans('commons.today')} · ${getDateTime(this.unixKickoff, { withDate: false })}`;
				} else {
					infoPreTitle += `${getDateTime(this.unixKickoff, { withWeekdayPrefix: true, dateTimeSeparator: ' · ' })}`;
				}
				break;
			case EVENT_STATUS.LIVE:
				infoPreTitle += getRelativeTime(this.unixEnd, { useRemaining: true, unixNow: this.unixNow });
				break;
			// Past
			default:
				infoPreTitle += getDateTime(this.unixKickoff, { withWeekdayPrefix: true, withTime: false });
			}

			if (!this.isLeagueMatch && this.eventType !== EVENT_TYPE.MATCH_OTHER && this.location) {
				infoPreTitle = `${infoPreTitle} · ${this.location}`;
			}

			return infoPreTitle;
		},
		infoTitle() {
			if (this.isLeagueMatch && this.status !== EVENT_STATUS.UPCOMING && this.large) {
				return this.leagueName;
			}
			if ((this.eventType === EVENT_TYPE.MATCH_OTHER && this.hasTeams && this.status !== EVENT_STATUS.UPCOMING)) { // must have teams otherwise teams/name is not displayed
				return this.categoryChild;
			}
			if ((this.isMatch && this.hasTeams && this.status !== EVENT_STATUS.UPCOMING)) {
				return null;
			}
			return this.eventName;
		},
		infoSubTitle() {
			if ((this.eventType === EVENT_TYPE.MATCH_OTHER && this.status !== EVENT_STATUS.UPCOMING)) {
				return this.location;
			}
			if (this.isLeagueHighLight) {
				return this.leagueName;
			}
			if (this.tags && this.tags.some(tag => tag.type === 'highlight')) {
				return this.categoryChild;
			}
			if (this.eventType === EVENT_TYPE.SINGLE) {
				return this.categoryChild;
			}
			if (this.eventType === EVENT_TYPE.SINGLE_TRAINING) {
				return `${this.categoryParent}${this.categoryChild ? ' · ' + this.categoryChild : ''}`;
			}
			if (this.isTeamTraining) {
				return this.categoryParent;
			}
			if (this.isLeagueMatch && this.location) {
				return this.location;
			}
			return this.categoryChild;
		},
		isMatch() {
			return this.eventType === EVENT_TYPE.MATCH_LEAGUE || this.eventType === EVENT_TYPE.MATCH_OTHER;
		},
		hasTeams() {
			return this.homeName && this.guestName;
		},
		isLeagueMatch() {
			return this.eventType === EVENT_TYPE.MATCH_LEAGUE;
		},
		isLeagueHighLight() {
			return this.eventType === EVENT_TYPE.MATCH_LEAGUE_HIGHLIGHT;
		},
		isTeamTraining() {
			return this.eventType === EVENT_TYPE.TEAM_TRAINING;
		},
		showSportIcon() {
			if (this.isMatch || this.isTeamTraining || this.isEmbed || this.withImage) {
				return false;
			}
			return this.status === EVENT_STATUS.UPCOMING;
		},
		// todo: tmp for oetv - disable after tennis events took place
		disableImageCutOff() {
			return this.rawEvent.sport?.name === 'tennis';
		},
		calendarIconOverImage() {
			if (this.disableImageCutOff || this.isMatch || this.isTeamTraining || this.isEmbed || (this.status !== EVENT_STATUS.UPCOMING)) {
				return null;
			}
			return '/images/sport-icons/calendar.svg#icon';
		},
		countDownTag() {
			if (this.type !== EventType.EVENT || this.status !== EVENT_STATUS.UPCOMING) { // or is live
				return null;
			}
			// if event is today, show live at time
			if (this.gameStartDay === 'today') {
				return this.$I18n.trans('arena.match_center.live_short', { time: getDateTime(this.unixStart, { withDate: false }) });
			}
			return getTimeUntil(this.unixStart, { unixNow: this.unixNow });
		},
		audioLanguagesTag() {
			if (!this.audioLanguages?.length) {
				return null;
			}
			return `<span>${this.audioLanguages.map(lang => lang.split('_')?.[0].toUpperCase()).join(' &bull; ')}</span>`;
		},
		icsDownloadEnabled() {
			return this.status === EVENT_STATUS.UPCOMING && !this.isEmbed && !navigator.userAgent.includes('iPhone');
		},
		canPlay() {
			// on embeds live/replay with paymentRestriction and no streamPreview
			if (this.isEmbed && this.isPaymentRestricted && !this.rawEvent.previewDuration) {
				return false;
			}

			return this.status !== EVENT_STATUS.UPCOMING;
		},
		showPlayIcon() {
			return this.canPlay && !this.couchModeAvailable;
		},
		showVideoDuration() {
			// PaymentButton would be on top of the duration. Button is more important, so we hide duration in this case.
			return this.status === EVENT_STATUS.REPLAY && !this.showEmbedPaymentButton;
		},
	},
	watch: {
		bgImgUrl: {
			immediate: true,
			handler(newUrl) {
				const newScaledUrl = this.getThumbnailSrc(newUrl);
				if (newScaledUrl && newScaledUrl !== this.currentBgImgUrl) {
					const img = new Image();
					img.onload = () => {
						this.currentBgImgUrl = newScaledUrl;
					};
					img.src = newScaledUrl;
				}
			},
		},
	},
	beforeMount() {
		this.currentBgImgUrl = this.getThumbnailSrc(this.bgImgUrl);
	},
	mounted() {
		this.liveInSeconds = this.getLiveInSeconds();
		setInterval(() => {
			this.unixNow = Math.round(new Date().getTime() / 1000);
			this.liveInSeconds = this.getLiveInSeconds();
		}, 1000);

		if (this.isEmbed) {
			this.$scaleOuter = this.$refs.scaleOuter;
			this.$scaleInner = this.$refs.scaleInner;
			requestAnimationFrame(() => {
				this.scaleCard();
			});

			window.addEventListener('resize', () => {
				requestAnimationFrame(this.scaleCard);
			});
		}
		if (matchMediaIsSupported) {
			landscapeQuery.addEventListener('change', (e) => {
				this.isLandscape = e.matches;
			});
			touchQuery.addEventListener('change', (e) => {
				this.isTouchDevice = e.matches;
			});
		}
	},
	methods: {
		getThumbnailSrc(url) {
			return this.$Helpers.getScaledImageUrl(url, (this.large || this.isEmbed) ? 2000 : 320, { heightIsWidth: true });
		},
		getLiveInSeconds() {
			const unixNow = new Date() / 1000;

			return this.unixStart - unixNow;
		},
		scaleCard() {
			const scaleOuterHeight = this.$scaleOuter.offsetHeight;
			const scaleOuterWidth = this.$scaleOuter.scrollWidth;
			const scaleInnerHeight = this.$scaleInner.offsetHeight;
			const scaleInnerWidth = this.$scaleInner.offsetWidth;

			const scale = Math.min(
				scaleOuterWidth / scaleInnerWidth,
				scaleOuterHeight / scaleInnerHeight
			);

			this.$scaleInner.style.transform = `scale(${scale})`;
		},
		handleCardClick(e) {
			// We only show the live and ended events preview on external embeds for iPhones.
			if (this.isEmbed && this.canPlay) {
				this.$emit('init-embed-player');
			} else {
				// Show overlay to choose between full Video and Couchmode on Touch interfaces in landscape, else handle link normally
				if (this.couchModeAvailable && this.isTouchDevice && this.isLandscape && !this.touchOverlayActive) {
					e.preventDefault();
					this.touchOverlayActive = true;
				}
			}
		},
		hideTouchOverlay() {
			if (this.couchModeAvailable && this.isTouchDevice && this.isLandscape) {
				this.touchOverlayActive = false;
			}
		},
		handleIcsRequest() {
			window.eventBus.$emit(`modal:${this.icsDownloadModal}`, {
				eventId:   this.rawEvent.id,
				eventName: this.rawEvent.name,
				unixStart: this.unixStart,
				unixEnd:   this.unixEnd,
				link:      `${window.location.origin}/event/${this.rawEvent.id}/${this.$Helpers.slugify(this.rawEvent.name)}`,
			});
		},
	},
};
</script>

<style lang="scss" scoped>
/* stylelint-disable property-disallowed-list, sh-waqar/declaration-use-variable */
@import '@shared/sass/shared-variables';

$info-overlay-height: 86px;

.event-card {
	display: block;

	&.-flexible {
		margin-bottom: 30px;
	}

	body.-t-light .flickity-slider & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		flex-grow: 1;
	}
}

.event-card__link-w {
	position: relative;
}

.event-card__link {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	.event-card.-couchmode.is-touch:not(.is-landscape) &,
	.event-card:not(.-couchmode) & {
		z-index: 3; // stylelint-disable-line declaration-property-value-allowed-list
	}

	.event-card.-couchmode.-large:not(.is-touch) & {
		@include size(md-up) {
			height: $info-overlay-height;
			z-index: 3; // stylelint-disable-line declaration-property-value-allowed-list
		}
	}
}

.event-card__aspect-ratio-w {
	position: relative;
	padding-top: 56.25%;
	width: 100%;
	z-index: 2;
}

.event-card__inner {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: var(--color-grey-900);
	border-radius: $border-radius--sm $border-radius--sm 0 0;
	transition: transform $trans-time-fast;
	will-change: transform;
	overflow: hidden;

	.event-card.-large:not(.is-touch) & {
		@include size(md-up) {
			border-radius: $border-radius--sm;
		}
	}

	.event-card:not(.is-embed):not(.is-touch):hover & {
		transform: scale(1.05);
	}

	.event-card.-large:not(.is-touch):hover & {
		@include size(md-up) {
			transform: scale(1.025);
		}
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-grey-900);
	}

	.is-keynav-section-active .is-selected & {
		transform: scale(1.025);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}
}

.event-card__bg-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.event-card__scale-w {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	padding: $sp4 $sp4 $sp3-5;

	.event-card.-with-image.is-embed & {
		&::before {
			@include z-index('image-shadow', 'event-card');
			content: '';
			display: block;
			position: absolute;
			left: -1px;
			bottom: -1px;
			width: calc(100% + 2px);
			height: calc(100% + 2px);
			background-color: rgba(var(--color-rgb-black), 0.6);
		}
	}

	.event-card.is-embed & {
		// scaling proportional to .event-card__scale-w
		$base-size: 420px;
		$base-size-sm: 500px;
		$base-size-md: 720px;
		$base-size-lg: 720px;
		$base-size-xl: 900px;

		position: relative;
		transform-origin: center center;
		flex-shrink: 0;
		width: $base-size;
		height: calc($base-size / 16) * 9;

		@include size(sm-up) {
			width: $base-size-sm;
			height: calc($base-size-sm / 16) * 9;
		}
		@include size(md-up) {
			width: $base-size-md;
			height: calc($base-size-md / 16) * 9;
		}
		@include size(lg-up) {
			width: $base-size-lg;
			height: calc($base-size-lg / 16) * 9;
		}
		@include size(xl-up) {
			width: $base-size-xl;
			height: calc($base-size-xl / 16) * 9;
		}
	}
}

////////////////////////////////////////////////
// Background pattern
////////////////////////////////////////////////

.event-card__bg-pattern {
	@include z-index('background', 'event-card');

	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.event-card__bg-pattern-el {
	transform: skew(18deg);
	position: absolute;
	top: 0;
	width: 70%;
	height: 100%;

	&.-left {
		right: 50%;
		background-color: var(--color-grey-900);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-900);
		}
	}

	&.-right {
		left: 50%;
		background-color: var(--color-grey-800);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-850);
		}
	}

	.event-card.is-embed & {
		&.-left {
			body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type, max-nesting-depth
				background-color: var(--color-grey-200);
			}
		}

		&.-right {
			body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type, max-nesting-depth
				background-color: var(--color-grey-300);
			}
		}
	}
}

.event-card__upcoming-calendar {
	position: absolute;
	top: 0;
	right: -30%;
	width: 50%;
	height: 100%;
	background-color: var(--color-grey-800);
	transform: skew(18deg);
	overflow: hidden;

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-grey-600);
	}
}

.event-card__upcoming-calendar-icon {
	position: absolute;
	bottom: -1%;
	right: -21%;
	transform: skew(-18deg) scale(0.51);

	.event-card.-large & {
		@include size(md-up) {
			bottom: 29%;
			left: -27%;
			transform: skew(-18deg) scale(0.85);
		}
	}

	// on mobile the event cards adapt
	@include size(xs-only) {
		right: 25%;
		transform: skew(-18deg) scale(0.51);
		width: 40vw;
		height: 30vw;
	}
}

////////////////////////////////////////////////
// Header
////////////////////////////////////////////////

.event-card__header {
	@include z-index('header', 'event-card');

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.event-card__notification {
	position: absolute;
	top: $sp4;
	right: $sp4;
	z-index: 3; // stylelint-disable-line declaration-property-value-allowed-list
	opacity: 0;
	transition: opacity $trans-time-fast, top $trans-time-fast, right $trans-time-fast;
	cursor: pointer;

	.event-card:hover & {
		opacity: 1;
		top: $sp3;
		right: $sp3;
	}

	@include is-touch {
		opacity: 1;

		.event-card:hover & {
			opacity: 1;
			top: $sp4;
			right: $sp4;
		}

		.event-card.is-external & {
			display: none;
		}
	}
}

.event-card__external-platform-icon {
	@include z-index('external-platform', 'event-card');
	position: absolute;
	top: 0;
	right: $sp4;
	width: 50px;
	opacity: 1;
	transition: opacity $trans-time-fast;

	.event-card.is-upcoming:hover & {
		opacity: 0;
	}
}

.event-card__tags-w {
	display: flex;
	gap: $sp3;
	pointer-events: none;
	flex-wrap: wrap;
}

.event-card__tag {
	@include font(primary, book, normal, fs-60);

	display: inline-block;
	align-items: center;
	position: relative;
	background-color: var(--color-black);
	color: var(--color-white);
	align-items: center;
	line-height: 20px;
	height: 20px;
	padding-left: $sp3;
	padding-right: $sp3;
	border-radius: $border-radius--xs;

	&.-icon {
		color: var(--color-white);
		padding-left: $sp2;
		padding-right: $sp2;
	}

	.event-card:not(.-large) & {
		max-width: 150px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: rgba(var(--color-rgb-black), 0.6);
	}
}

////////////////////////////////////////////////
// Hover overlays
////////////////////////////////////////////////

.event-card__play {
	@include z-index('play-icon', 'event-card');

	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(var(--color-rgb-black), 0.8);
	margin-left: auto;
	margin-right: auto;
	border-radius: 50%;
	width: 56px;
	height: 56px;
	opacity: 0;
	transition: opacity $trans-time-fast, transform $trans-time-fast;
	transform-origin: center;
	cursor: pointer;

	.event-card.-large & {
		@include size(md-up) {
			top: 41%;
		}
	}

	.event-card:not(.is-touch):hover & {
		opacity: 1;
	}

	.event-card.is-embed & {
		opacity: 1;

		&:hover {
			transform: translateY(-50%) scale(1.2);
		}
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		color: var(--color-white);
	}
}

.event-card__couchmode-overlay {
	@include z-index('couchmode', 'event-card');

	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	pointer-events: none;
	transition: opacity $trans-time-fast;

	&.-touch-active,
	.event-card__link:hover + .event-card__aspect-ratio-w &,
	.event-card__inner:hover & {
		opacity: 1;
		pointer-events: initial;
	}

	&.-touch-active {
		@include z-index('couchmode-touch-large', 'event-card');
	}
}

/* stylelint-disable max-nesting-depth, selector-max-type, selector-no-qualifying-type */
.event-card__couchmode-overlay-text {
	@include font(primary, medium, normal, fs-100);

	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 0;
	width: 50%;
	height: 100%;

	&::before {
		content: '';
		transform: skew(18deg);
		position: absolute;
		top: 0;
		width: 140%;
		height: 100%;
		background-color: var(--color-grey-800);
		opacity: 0.85;
		z-index: -1;

		body.-t-light & {
			background-color: var(--color-grey-600);
		}
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		background-size: 70% auto;
		background-repeat: no-repeat;
	}

	body.-t-light & {
		color: var(--color-white);
	}

	&.-left {
		right: 50%;

		body.-t-asport & {
			&::after {
				width: 30%;
				bottom: 6%;
				left: 6%;
				background-image: url('/images/event-card/pattern-left.svg');
				background-position: 0 100%;
			}
		}

		&::before {
			background-color: var(--color-grey-900);
			right: 0;

			body.-t-light & {
				background-color: var(--color-grey-200);
			}
		}
	}

	&.-right {
		left: 50%;

		body.-t-asport & {
			&::after {
				width: 50%;
				top: 6%;
				right: 6%;
				background-image: url('/images/event-card/pattern-right.svg');
				background-position: 100% 0;
			}
		}

		&::before {
			left: 0;

			body.-t-light & {
				background-color: var(--color-grey-300);
			}
		}
	}

	.event-card__couchmode-overlay.-touch-active & {
		&.-right::before {
			background-color: var(--color-hype-yellow);
			opacity: 1;

		}

		body.-t-asport & {
			&.-right {
				color: var(--color-shadow-grey);
			}
		}
	}

	.event-card.-large & {
		@include size(md-up) {
			@include font(primary, medium, normal, fs-120);
			height: calc(100% - #{$info-overlay-height});
			padding-top: $sp4;
		}
	}

	.event-card.is-touch.-large & {
		@include size(md-up) {
			height: 100%;
		}
	}

	.event-card:not(.is-touch) & {
		&:hover {
			body.-t-asport & {
				color: var(--color-shadow-grey);
			}

			&::before {
				background-color: var(--color-hype-yellow);
				opacity: 1;
			}
		}
	}
}

.event-card__couchmode-overlay-info {
	@include font(primary, book, normal, fs-60);
	margin-top: $sp2;

	.event-card.-large & {
		@include size(md-up) {
			@include font(primary, book, normal, fs-80);
		}
	}
}
/* stylelint-enable max-nesting-depth, selector-max-type, selector-no-qualifying-type */

////////////////////////////////////////////////
// Teams
////////////////////////////////////////////////

.event-card__teams {
	@include z-index('teams', 'event-card');
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	display: flex;
	justify-content: space-around;

	.event-card.-large & {
		@include size(md-up) {
			top: 40%;
		}
	}

	.event-card.-with-infos.is-embed & {
		top: 39%;

		@include size(sm-up) {
			top: 41%;
		}
		@include size(md-up) {
			top: 44%;
		}
	}
}

.event-card__team {
	background-size: contain;
	background-repeat: no-repeat;

	.event-card:not(.is-embed) & {
		width: 56px;
		height: 56px;
	}

	.event-card.-large:not(.is-embed) & {
		@include size(md-up) {
			width: 96px;
			height: 96px;
		}
	}

	.event-card.is-embed & {
		$image-size: 80px;
		$image-size-sm: 95px;
		$image-size-md: 137px;
		$image-size-lg: 137px;
		$image-size-xl: 171px;

		width: $image-size;
		height: $image-size;

		@include size(sm-up) {
			width: $image-size-sm;
			height: $image-size-sm;
		}
		@include size(md-up) {
			width: $image-size-md;
			height: $image-size-md;
		}
		@include size(lg-up) {
			width: $image-size-lg;
			height: $image-size-lg;
		}
		@include size(xl-up) {
			width: $image-size-xl;
			height: $image-size-xl;
		}
	}
}

////////////////////////////////////////////////
// Sport Icon
////////////////////////////////////////////////

.event-card__sport-icon {
	@include z-index('sport-icon', 'event-card');
	position: absolute;
	left: -20px;
	bottom: -8px;
	opacity: 0.3;
	width: 136px;
	height: 136px;

	svg { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		width: 100%;
		height: 100%;
	}

	.event-card.-large & {
		@include size(md-up) {
			left: -11%;
			top: 15%;
			width: 197px;
			height: 197px;
		}
	}
}

////////////////////////////////////////////////
// Placeholder text
////////////////////////////////////////////////

.event-card__placeholder-text {
	@include z-index('placeholder-text', 'event-card');
	position: absolute;
	bottom: 110px;
	padding-right: $sp4;
	margin-bottom: 0;

	.event-card:not(.-with-infos) & {
		bottom: 30px;
	}
}

////////////////////////////////////////////////
// Countdown
////////////////////////////////////////////////

.event-card__countdown {
	@include z-index('countdown', 'event-card');
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	bottom: 110px;
	margin-bottom: 0;

	.event-card:not(.-with-infos) & {
		bottom: 10px;

		@include size(md-up) {
			bottom: 30px;
		}
	}
}

////////////////////////////////////////////////
// League overlay
////////////////////////////////////////////////

.event-card__league {
	@include z-index('league', 'event-card');
	@include ellipsis();
	margin-top: $sp4;

	.event-card.-with-image:not(.is-upcoming) & {
		&::after {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 50%;
			background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, var(--color-black) 100%);
			opacity: 0.8;
			z-index: -1;
		}
	}

	.event-card.-large & {
		@include size(md-up) {
			display: none;
		}
	}
}

.event-card__league-image {
	height: 16px;
	margin-right: $sp2;
}

.event-card__league-title {
	@include font-size(fs-60);
	color: var(--color-grey-200);

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		color: var(--color-white);
	}

	body.-t-light .event-card:not(.-with-image) & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		color: var(--color-black);
	}
}

////////////////////////////////////////////////
// Video duration
////////////////////////////////////////////////

.event-card__video-duration {
	@include z-index('video-duration', 'event-card');
	@include font(primary, book, normal, fs-60);
	position: absolute;
	bottom: $sp3-5;
	right: $sp4;
	display: flex;
	padding: $sp1 $sp2;
	background-color: rgba(var(--color-rgb-black), 0.6);
	flex-grow: 0;
	align-self: flex-end;

	.event-card.-large & {
		@include size(md-up) {
			@include z-index('video-duration-on-large', 'event-card');
		}
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		color: var(--color-white);
	}
}

////////////////////////////////////////////////
// Event Infos
////////////////////////////////////////////////

.event-card__infos-w {
	@include z-index('infos', 'event-card');

	display: none;

	.event-card.-large & {
		&.-for-large-card {
			@include size(md-up) {
				display: block;
				position: absolute;
				bottom: -1px;
				left: -1px; // need to widen the background a bit because of a flickering at the border on hover
				padding-left: 1px;
				padding-bottom: 1px;
				width: calc(100% + 2px);
				height: $info-overlay-height + 1px;
				pointer-events: none;

				&::after { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
					content: '';
					display: block;
					position: absolute;
					left: 0;
					bottom: 0;
					width: 100%;
					height: 150px;
					background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, var(--color-black) 100%);
					opacity: 0.8;
					z-index: -1;
				}
			}
		}

		&.-for-small-card {
			@include size(sm-down) {
				display: block;
			}
		}
	}

	.event-card:not(.-large) & {
		&.-for-small-card {
			display: block;
		}
	}

	.event-card.is-embed & {
		&.-for-small-card {
			display: none;
		}

		&.-for-large-card {
			display: flex;
			justify-content: space-between;
		}
	}
}

.event-card__payment-button-w {
	margin-bottom: $sp2;
	padding-bottom: $sp3;
	display: flex;
	align-items: flex-end;
	flex-shrink: 0;
}

.event-card__infos {
	margin-top: $sp2-5;
	margin-bottom: $sp2;
	margin-left: $sp3;
	padding-top: $sp3;
	padding-bottom: $sp3;

	.event-card.-large & {
		@include size(md-up) {
			margin-left: $sp4;
			margin-right: $sp4;
		}
	}

	.event-card.is-embed & {
		margin-left: 0;
		margin-right: 0;
		overflow: hidden;
	}
}
</style>
