export const MatchScoresPropsConfig = {
	homeName: {
		type:    String,
		default: null,
	},
	homeImgUrl: {
		type:    String,
		default: null,
	},
	homeScore: {
		type:    [String, Number],
		default: null,
	},
	guestName: {
		type:    String,
		default: null,
	},
	guestImgUrl: {
		type:    String,
		default: null,
	},
	guestScore: {
		type:    [String, Number],
		default: null,
	},
	hasOvertime: {
		type:    Boolean,
		default: false,
	},
	hasShootout: {
		type:    Boolean,
		default: false,
	},
	scoreExtraAbbr: {
		type:    String,
		default: null,
	},
	large: {
		type:    Boolean,
		default: false,
	},
};
