import laroute from '@laroute';

export default {
	computed: {
		arenaUrl() {
			return this.item?.config?.arenaTeamLink || this.item.arenaUrl || laroute.route('arena.team', { team: this.item.id, slug: $Helpers.slugify(this.item.clubTeamName || this.item.name) });
		},
		isExternalLink() {
			return this.arenaUrl && (new URL(this.arenaUrl)).hostname !== location.hostname;
		},
		showExternalLinkIcon() {
			// disabling external link icon because doesn't look good when all links are external (in case of sfl)
			if (this.item?.config?.arenaTeamLink !== undefined) {
				return false;
			}
			return this.isExternalLink;
		},
	},
};
