<template>
	<V2Modal
		modal-name="icsDownloadModal"
		type="dialog"
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		@opened="onOpen"
	>
		<template #title>
			{{ $I18n.trans('arena.modal_ics_download.title') }}
		</template>
		<template #content>
			<!-- eslint-disable vue/no-v-html -->
			<div v-html="$I18n.trans('arena.modal_ics_download.text', { name: modalData.eventName })" />
		</template>
		<template #footer>
			<div class="l-button-group">
				<V2Button
					:text="$Helpers.ucfirst($I18n.trans('commons.cancel'))"
					modifier="secondary"
					theme="dark"
					@click="closeIcsDownloadModal"
				/>
				<V2Button
					:text="$Helpers.ucfirst($I18n.trans('commons.download'))"
					theme="dark"
					icon="cloud-download"
					@click="downloadIcsFile"
				/>
			</div>
		</template>
	</V2Modal>
</template>

<script>
import V2Modal from '@shared/components/Modal';
import V2Button from '@shared/components/Button';
import { getIcsFile } from '@shared/utils/getIcsFile';

export default {
	name:       'IcsDownloadModal',
	components: { V2Modal, V2Button },
	data() {
		return {
			icsDownloadModal: 'icsDownloadModal',
			modalData:        {
				eventId:   '',
				eventName: '',
				unixStart: '',
				unixEnd:   '',
				link:      '',
			},
		};
	},
	methods: {
		onOpen(data) {
			this.modalData = data;
		},
		closeIcsDownloadModal() {
			window.eventBus.$emit(`modalClose:${this.icsDownloadModal}`);
		},
		downloadIcsFile() {
			const date = {
				start: this.modalData.unixStart * 1000,
				end:   this.modalData.unixEnd * 1000,
			};
			const file = getIcsFile(date, this.modalData.eventName, this.modalData.link, '', '');
			const tempLink = document.createElement('a');
			tempLink.href = file;
			tempLink.download = 'event.ics';
			document.body.appendChild(tempLink);
			tempLink.click();
			document.body.removeChild(tempLink);
			this.closeIcsDownloadModal();
		},
	},
};
</script>
