export const SportName = {
	HANDBALL:              'handball',
	BASKETBALL:            'basketball',
	VOLLEYBALL:            'volleyball',
	FUTSAL:                'futsal',
	INDOORSPORTS:          'indoorsports',
	SOCCER:                'soccer',
	FIELDHOCKEY:           'fieldhockey',
	FLOORBALL:             'floorball',
	HORNUSSEN:             'hornussen',
	TENNIS:                'tennis',
	FIGURE_SKATING:        'figure_skating',
	ICE_HOCKEY:            'ice_hockey',
	FISTBALL:              'fistball',
	FLAG_FOOTBALL:         'flag_football',
	SKI_ALPINE:            'ski_alpine',
	SKI_BIATHLON:          'ski_biathlon',
	SKI_CROSS:             'ski_cross',
	SKI_CROSS_COUNTRY:     'ski_cross_country',
	SKI_FREESTYLE:         'ski_freestyle',
	SKI_JUMPING:           'ski_jumping',
	SKI_MOGULS:            'ski_moguls',
	SKI_NORDIC_COMBINED:   'ski_nordic_combined',
	SKI_TELEMARK:          'ski_telemark',
	SKI_MOUNTAINEERING:    'ski_mountaineering',
	SNOWBOARD:             'snowboard',
	ROAD_CYCLING:          'road_cycling',
	TRACK_CYCLING:         'track_cycling',
	MOUNTAIN_BIKE:         'mountain_bike',
	CYCLO_CROSS:           'cyclo_cross',
	NATURAL_TRACK_LUGE:    'natural_track_luge',
	ARTIFICIAL_TRACK_LUGE: 'artificial_track_luge',
	WHEELCHAIR_RUGBY:      'wheelchair_rugby',
	WHEELCHAIR_BASKETBALL: 'wheelchair_basketball',
	WHEELCHAIR_BOCCIA:     'wheelchair_boccia',
	WHEELCHAIR_HOCKEY:     'wheelchair_hockey',
	MISCELLANEOUS:         'miscellaneous',
	ASSET:                 'asset',
	BASEBALL:              'baseball',
	CHEERSPORT:            'cheersport',
	DARTS:                 'darts',
	FENCING:               'fencing',
	FOOTVOLLEY:            'footvolley',
	KARATE:                'karate',
	KICKBOXING:            'kickboxing',
	ATHLETICS:             'athletics',
	MODERN_PENTATHLON:     'modern_pentathlon',
	ORIENTEERING:          'orienteering',
	JU_JITSU:              'ju_jitsu',
	SPORT_CLIMBING:        'sport_climbing',
	BILLARD:               'billard',
	ARCHERY:               'archery',
	POWERLIFTING:          'powerlifting',
	MUAYTHAI:              'muaythai',
	RACKETLON:             'racketlon',
	RUGBY:                 'rugby',
	ROPE_SKIPPING:         'rope_skipping',
	SITTING_BALL:          'sitting_ball',
	SPORTS_BOWLING:        'sports_bowling',
	ARTISTIC_SWIMMING:     'artistic_swimming',
	DANCING:               'dancing',
	TRIATHLON:             'triathlon',
	ULTIMATE_FRISBEE:      'ultimate_frisbee',
	WATER_POLO:            'water_polo',
	DIVING:                'diving',
	BEACH_TENNIS:          'beach_tennis',
	ICE_STOCK_SPORT:       'ice_stock_sport',
	INLINE_SKATING:        'inline_skating',
	WATER_SKIING:          'water_skiing',
	WAKEBOARDING:          'wakeboarding',
	MINIGOLF:              'minigolf',
	SQUASH:                'squash',
	CANOE_SPRINT:          'canoe_sprint',
	CANOE_SLALOM:          'canoe_slalom',
	CANOE_WILD_WATER:      'canoe_wild_water',
	GYMNASTICS_ARTISTIC:   'gymnastics_artistic',
	GYMNASTICS_ACROBATIC:  'gymnastics_acrobatic',
	GYMNASTICS_TRAMPOLINE: 'gymnastics_trampoline',
	SPEED_SKATING:         'speed_skating',
	CURLING:               'curling',
	GRASS_SKIING:          'grass_skiing',
	TAEKWONDO:             'taekwondo',
	TABLE_FOOTBALL:        'table_football',
	CHESS:                 'chess',
	INLINE_HOCKEY:         'inline_hockey',
	SWIMMING:              'swimming',
	BADMINTON:             'badminton',
	JUDO:                  'judo',
	PADEL:                 'padel',
	SHOOTING_SPORTS:       'shooting_sports',
	TABLE_TENNIS:          'table_tennis',
	TEQBALL:               'teqball',
	BOXING:                'boxing',
	SKATEBOARDING:         'skateboarding',
	PADBOL:                'padbol',
	GYMNASTICS:            'gymnastics',
	GYMNASTICS_WHEEL:      'gymnastics_wheel',
	GYMNASTICS_APPARATUS:  'gymnastics_apparatus',
	GYMNASTICS_RHYTHMIC:   'gymnastics_rhythmic',
	GYMNASTICS_NATIONAL:   'gymnastics_national',
	AEROBIC:               'aerobic',
	PARKOUR:               'parkour',
	INDIACA:               'indiaca',
	NETBALL:               'netball',
	BOBSLEIGH:             'bobsleigh',
	SKELETON:              'skeleton',
	NETZBALL:              'netzball',
	TURNEN:                'turnen',
	PARA_ATHLETICS:        'para_athletics',
	SMALL_SIDED_FOOTBALL:  'small_sided_football',
	WRESTLING:             'wrestling',
	WEIGHTLIFTING:         'weightlifting',
	DISCGOLF:              'discgolf',
	SAILING:               'sailing',
	STREET_HOCKEY:         'street_hockey',
	GOLF:                  'golf',
	ROWING:                'rowing',
	AMERICAN_FOOTBALL:     'american_football',
	BEACH_VOLLEYBALL:      'beach_volleyball',
	EQUESTRIAN:            'equestrian',
	BLIND_FOOTBALL:        'blind_football',
	GOALBALL:              'goalball',
	PARA_ARCHERY:          'para_archery',
	PARA_BADMINTON:        'para_badminton',
	PARA_CANOE:            'para_canoe',
	PARA_CYCLING_TRACK:    'para_cycling_track',
	PARA_CYCLING_ROAD:     'para_cycling_road',
	PARA_EQUESTRIAN:       'para_equestrian',
	PARA_JUDO:             'para_judo',
	PARA_POWERLIFTING:     'para_powerlifting',
	PARA_ROWING:           'para_rowing',
	PARA_SWIMMING:         'para_swimming',
	PARA_TABLE_TENNIS:     'para_table_tennis',
	PARA_TAEKWONDO:        'para_taekwondo',
	PARA_TRIATHLON:        'para_triathlon',
	SHOOTING_PARA_SPORT:   'shooting_para_sport',
	WHEELCHAIR_FENCING:    'wheelchair_fencing',
	WHEELCHAIR_TENNIS:     'wheelchair_tennis',
	WHEELCHAIR_BADMINTON:  'wheelchair_badminton',
	SITTING_VOLLEYBALL:    'sitting_volleyball',
	WHEELCHAIR_DANCE:      'wheelchair_dance',
	PARA_BIATHLON:         'para_biathlon',
	PARA_CROSS_COUNTRY:    'para_cross_country',
	WHEELCHAIR_CURLING:    'wheelchair_curling',
	PARA_ICE_HOCKEY:       'para_ice_hockey',
	PARA_SNOWBOARDING:     'para_snowboarding',
	PARA_ALPINE_SKIING:    'para_alpine_skiing',
	NINJA:                 'ninja',
	MEDIEVAL_COMBAT:       'medieval_combat',
	RALLYING:              'rallying',
	KART_RACING:           'kart_racing',
	BOCCIA:                'boccia',
	HANG_GLIDING:          'hang_gliding',
};
