<template>
	<div>
		<InputText
			ref="email"
			name="email"
			type="email"
			pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
			required
			:label="$I18n.trans('arena.login.login.email')"
			:placeholder="$I18n.trans('arena.login.login.email')"
			autocomplete="username"
			:dark-theme="!$arenaConfig.isLightTheme"
			:value="email"
			@input="(data) => $parent.email = data"
		/>
		<InputText
			v-if="error !== 'NoPassword'"
			ref="password"
			name="password"
			class="arena-login__password"
			type="password"
			pattern=".{8,}"
			:custom-error-msg="$I18n.trans('arena.login.login.password_hint')"
			required
			:label="$I18n.trans('arena.login.login.password')"
			:placeholder="$I18n.trans('arena.login.login.password')"
			:autocomplete="hasAccount ? 'current-password' : 'new-password'"
			:dark-theme="!$arenaConfig.isLightTheme"
			:value="password"
			@input="(data) => password = data"
		/>
		<div
			v-if="!error"
			class="arena-login__hint"
		>
			<V2Icon
				icon-name="c-info"
				size="md"
				class="arena-login__hint-icon"
			/>
			{{ $I18n.trans('arena.login.login.password_hint') }}
		</div>
		<div
			v-if="error === 'InvalidCredentials'"
			class="arena-login__hint -error"
		>
			<V2Icon
				icon-name="c-info"
				size="md"
				class="arena-login__hint-icon"
			/>
			{{ $I18n.trans('arena.login.login.invalid_password') }}
		</div>
		<template v-if="hasAccount && !isSpecialCase">
			<Button
				class="arena-login__continue"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.continue'))"
				:disabled="!($refs.password && $refs.password.isValid)"
				:busy="inProgress"
				theme="dark"
				@click="submitForm()"
			/>
			<a
				class="arena-login__forgot"
				@click="$parent.mode = LOGIN_MODES.RESET_PASSWORD"
			>
				{{ $I18n.trans('arena.login.login.forgot_password') }}
			</a>
		</template>
		<template v-if="!hasAccount">
			<InputText
				ref="passwordConfirm"
				name="passwordConfirm"
				type="password"
				:custom-error-msg="$I18n.trans('arena.login.login.password_confirm_error')"
				required
				:label="$I18n.trans('arena.login.login.password_confirm')"
				:placeholder="$I18n.trans('arena.login.login.password_confirm')"
				autocomplete="new-password"
				:dark-theme="!$arenaConfig.isLightTheme"
				:value="passwordConfirm"
				:show-error="passwordConfirm && passwordConfirm !== password && passwordConfirm.length >= 8"
				@input="(data) => passwordConfirm = data"
			/>
			<Button
				class="arena-login__continue"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.create_account'))"
				:disabled="!($refs.password && $refs.password.isValid && passwordConfirm === password)"
				:busy="inProgress"
				theme="dark"
				@click="submitForm()"
			/>
		</template>
		<template v-if="error === 'EmailNotVerified'">
			<div
				class="arena-login__hint -no-icon -info"
			>
				{{ $I18n.trans('arena.login.login.email_not_verified') }}
			</div>
			<Button
				class="arena-login__continue -info"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.request_activation_button'))"
				theme="dark"
				@click="resendActivation()"
			/>
		</template>
		<template v-else-if="error === 'NoPassword'">
			<div
				class="arena-login__hint -no-icon -info"
			>
				{{ $I18n.trans('arena.login.login.no_password') }}
			</div>
			<Button
				class="arena-login__continue"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.request_reset_button'))"
				theme="dark"
				@click="requestReset()"
			/>
		</template>
		<div
			v-if="error === 'UnknownError'"
			class="arena-login__hint -error"
		>
			<V2Icon
				icon-name="c-info"
				size="md"
				class="arena-login__hint-icon"
			/>
			{{ $I18n.trans('arena.login.login.error') }}
		</div>
		<Button
			class="arena-login__back"
			:text="$Helpers.ucfirst($I18n.trans('arena.login.login.back'))"
			:theme="$arenaConfig.isLightTheme ? null : 'dark'"
			modifier="borderless"
			@click="$parent.resetForm"
		/>
	</div>
</template>

<script>
import { authApi } from '@js/api/client';
import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';
import InputText from '@shared/components/Form/InputText';
import { LOGIN_MODES } from '../LoginComponent';
import laroute from '@laroute';
import storage from '@shared/js/storage';
import LoggingService from '@shared/js/LoggingService';

export default {
	name:       'PasswordLoginForm',
	components: { Button, V2Icon, InputText },
	props:      {
		loginRedirectUrl: {
			type:    String,
			default: null,
		},
		email: {
			type:    String,
			default: '',
		},
		hasAccount: {
			type:     Boolean,
			required: true,
		},
		hasNoPassword: {
			type:     Boolean,
			required: true,
		},
	},
	data() {
		return {
			error:           null,
			inProgress:      false,
			password:        null,
			passwordConfirm: null,
			LOGIN_MODES,
		};
	},
	computed: {
		isSpecialCase: function() {
			return ['NoPassword', 'EmailNotVerified'].includes(this.error);
		},
	},
	watch: {
		hasNoPassword: {
			immediate: true,
			handler(hasNoPassword) {
				if (hasNoPassword) {
					this.error = 'NoPassword';
				}
			},
		},
		password: function(newVal) {
			if (newVal.length === 0) {
				this.error = null;
			}
		},
	},
	mounted() {
	},
	methods: {
		async submitForm() {
			this.invalidPassword = false;
			this.inProgress = true;
			this.error = null;

			try {
				const body = {
					email:    this.email,
					password: this.password,
				};

				if (this.redirectUrl) {
					body.redirectUrl = this.redirectUrl;
				}

				if (storage.affilId) {
					body.affilId = storage.affilId;
				}

				const response = await authApi.post(
					this.hasAccount ? laroute.route('arena.api.arena_login.submit') : laroute.route('arena.api.arena_login.create_account'),
					body,
				);
				const responseContent = await response.json();

				if (responseContent.status === 'ok') {
					// Login with password
					if (responseContent.authenticated === true) {
						this.$emit('login-success');
					} else {
						this.$emit('submitted', {
							mode: LOGIN_MODES.CHECK_EMAIL,
						});
						// Wait for confirmation of account creation
						this.$parent.monitorTokenVerification(responseContent.verificationToken, responseContent.validUntil);
						this.inProgress = false;
					}
				} else if (responseContent && responseContent.reason) {
					this.error = responseContent.reason;
					this.inProgress = false;
				} else {
					// Unknown error
					this.error = 'UnknownError';
					this.inProgress = false;
					console.error(responseContent);
				}
			} catch (error) {
				this.error = 'UnknownError';
				LoggingService.logFatal(error, {
					scope: 'arena-login',
				});
				this.inProgress = false;
			}
		},
		async resendActivation() {
			this.inProgress = true;

			try {
				await authApi.post(laroute.route('arena.api.arena_login.resend_activation'), {
					email: this.email,
				});
				this.$emit('submitted', {
					mode: LOGIN_MODES.ACTIVATION_EMAIL_SENT,
				});
			} catch (error) {
				this.error = 'UnknownError';
				console.error(error);
			} finally {
				this.inProgress = false;
			}
		},
		async requestReset() {
			this.inProgress = true;

			try {
				await authApi.post(laroute.route('arena.api.arena_login.request_reset'), {
					email: this.email,
				});
				this.$emit('submitted', {
					mode: LOGIN_MODES.PASSWORD_RESET_SENT,
				});
			} catch (error) {
				this.error = 'UnknownError';
				console.error(error);
			} finally {
				this.inProgress = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.arena-login__forgot {
	text-align: right;
	display: block;
	color: var(--color-hype-yellow);
	margin-top: 15px;
}
</style>
