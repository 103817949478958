const LEGACY_STORAGE_KEY = 'analysis';

class Storage {
	key = 'asport-web';

	data = {
		tagButtonsFilterValue: null,
		videoQuality:          0,
		viewerId:              null,
		playerRestore:         {
			eventId:  null,
			position: null,
		},
		affilId:     null,
		previewTime: {},
	};

	constructor() {
		// Migrate legacy data
		if (!localStorage.getItem(this.key) && localStorage.getItem(LEGACY_STORAGE_KEY)) {
			localStorage.setItem(this.key, localStorage.getItem(LEGACY_STORAGE_KEY));

			// enable for final migration
			// localStorage.setItem(LEGACY_STORAGE_KEY, null);
		}
		this.load();
	}

	load() {
		try {
			const data = JSON.parse(localStorage.getItem(this.key));
			if (data) this.data = data;
		} catch (error) {}
	}

	store() {
		localStorage.setItem(this.key, JSON.stringify(this.data));
	}

	get(key) {
		return this.data[key];
	}

	get playerRestore() {
		if (!this.data) return null;
		return this.data.playerRestore;
	}

	set playerRestore(val) {
		if (!val) return;
		if (!this.data.playerRestore) this.data.playerRestore = {};

		// only store position if it's greater than 1 to avoid race condition between stored position and player position
		if (val?.position !== undefined && val?.position !== null && val?.position > 1) {
			this.data.playerRestore.position = val.position;
		}

		// Update event id if it changes
		if (val?.eventId !== undefined && val?.eventId !== null && val?.eventId !== this.data.playerRestore.eventId) {
			this.data.playerRestore = {
				eventId:  val.eventId,
				position: 0,
			};
		}
		this.store();
	}

	get tagButtonsFilterValue() {
		if (!this.data) return null;
		return this.data.tagButtonsFilterValue;
	}

	set tagButtonsFilterValue(val) {
		this.data.tagButtonsFilterValue = val;
		this.store();
	}

	get videoQuality() {
		if (!this.data) return null;
		return this.data.videoQuality;
	}

	set videoQuality(val) {
		this.data.videoQuality = val;
		this.store();
	}

	get viewerId() {
		if (!this.data) return null;

		if (!this.data.viewerId) {
			this.data.viewerId = Date.now() + Math.random().toString(36).substr(2, 9);
			this.store();
		}

		return this.data.viewerId;
	}

	get affilId() {
		return this.data.affilId;
	}

	set affilId(val) {
		// AffilId can only be set once
		if (this.data.affilId) {
			return;
		}

		const regex = /^[a-zA-Z0-9]{1,10}-[a-zA-Z0-9]{1,16}$/;
		if (!regex.test(val)) {
			throw new Error('Invalid format for affillId');
		}

		this.data.affilId = val;
		this.store();
	}

	registerPreviewTime(eventId, initial, val) {
		const existing = this.get('previewTime') || {};
		if (!(eventId in existing) || existing[eventId].lastUpdated < Date.now() - 1000 * 60 * 60 * 24) {
			existing[eventId] = {
				remaining: initial,
			};
		}

		existing[eventId].remaining = Math.max(0, existing[eventId].remaining - val);
		existing[eventId].lastUpdated = Date.now();
		this.data.previewTime = existing;
		this.store();
	}

	getPreviewTime(eventId) {
		return this.get('previewTime') && this.get('previewTime')[eventId] ? this.get('previewTime')[eventId].remaining : null;
	}
}

const storage = new Storage();
export default storage;
