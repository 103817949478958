<template>
	<div
		class="pagination"
		:class="{'-t-dark': darkTheme, 'has-pager': showPager}"
	>
		<div class="pagination__left">
			<div
				v-if="showPager"
				class="pagination__pager"
			>
				<component
					:is="page !== '...' ? 'a' : 'span'"
					v-for="(page, i) in pagination"
					:key="i"
					class="pagination__page"
					:class="{ 'is-active': page === currentPage, 'is-page': page !== '...' }"
					@click="page !== '...' ? navigate(page) : () => {}"
				>
					{{ page }}
				</component>
			</div>
		</div>
		<div class="pagination__right">
			<div class="pagination__info">
				{{ readablePagination }}
			</div>
			<div
				v-if="!showPager"
				class="pagination__controls"
				:class="{'is-disabled': disabled}"
			>
				<div
					class="pagination__control"
					:class="{'is-disabled': currentPage === 1}"
					@click="currentPage !== 1 && navigate(1)"
				>
					<Icon
						icon-name="list-first"
						size="lg"
					/>
				</div>
				<div
					class="pagination__control"
					:class="{'is-disabled': currentPage === 1}"
					@click="currentPage !== 1 && navigate(Math.max(currentPage - 1, 1))"
				>
					<Icon
						icon-name="left-arrow"
						size="lg"
					/>
				</div>
				<div
					class="pagination__control"
					:class="{'is-disabled': currentPage === lastPage }"
					@click="currentPage !== lastPage && navigate(Math.min(currentPage + 1, lastPage))"
				>
					<Icon
						icon-name="right-arrow"
						size="lg"
					/>
				</div>
				<div
					class="pagination__control"
					:class="{'is-disabled': currentPage === lastPage }"
					@click="currentPage !== lastPage && navigate(lastPage)"
				>
					<Icon
						icon-name="list-last"
						size="lg"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';

export default {
	name:       'Pagination',
	components: { Icon },
	props:      {
		disabled: {
			type:    Boolean,
			default: false,
		},
		total: {
			type:     Number,
			required: true,
		},
		perPage: {
			type:     Number,
			required: true,
		},
		currentPage: {
			type:     Number,
			required: true,
		},
		lastPage: {
			type:     Number,
			required: true,
		},
		darkTheme: {
			type:    Boolean,
			default: true,
		},
		showPager: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		readablePagination() {
			const countFrom = (this.currentPage * this.perPage) - this.perPage + 1;
			const countTo = (this.currentPage * this.perPage);
			const total = this.total;
			return `${countFrom}-${Math.min(countTo, total)} ${this.$I18n.trans('commons.of')} ${total}`;
		},
		pages() {
			return Array.from({ length: this.lastPage }, (v, k) => k + 1);
		},
		pagination() {
			const pagination = [];
			const pageRange = 3;

			this.pages.forEach((page, index) => {
				if (
					page === 1 ||
					page === this.pages.length ||
					(page >= this.currentPage - pageRange && page <= this.currentPage + pageRange)
				) {
					pagination.push(page);
				} else if (
					page - 1 === pagination[pagination.length - 1] ||
					(index === 0 && page === this.currentPage - pageRange - 1)
				) {
					pagination.push('...');
				}
			});

			return pagination;
		},
	},
	methods: {
		navigate(page) {
			this.$emit('change', page);
		},
		getPages() {
			const pages = [];
			let page = 1;
			while (page <= this.lastPage) {
				pages.push(page);
				page++;
			}
			return pages;
		},
		clampPages(pages) {
			// Given an array of pages
			// take the first page, the last page and the current page + 3 pages before and after
			return [
				1,
				...this.getPagesAroundCurrentPage(pages),
				this.lastPage,
			];
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-black);

	&.-t-dark {
		color: var(--color-white);
	}
}

.pagination__pager {
	display: flex;
	gap: $sp1;

	@include size(sm-up) {
		gap: $sp2;
	}
}

.pagination__right {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	user-select: none;

	.pagination.has-pager & {
		@include size(xs-only) {
			display: none;
		}
	}
}

.pagination__pages {
	list-style: none;
}

.pagination__page {
	@include font-size(fs-80);
	width: $sp6;
	height: $sp6;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--color-black);
	transition: none;

	.pagination.-t-dark & {
		color: var(--color-white);
	}

	&:not(.is-page) {
		cursor: default;
		width: $sp3;
	}

	&.is-active {
		color: var(--color-white);
		background-color: var(--color-green-petrol);

		.pagination.-t-dark & {
			background-color: var(--color-hype-yellow);
			color: var(--color-black);
		}
	}

	&.is-page:hover {
		background-color: var(--color-green-petrol);
		color: var(--color-white);

		.pagination.-t-dark & {
			background-color: var(--color-hype-yellow);
			color: var(--color-black);
		}
	}
}

.pagination__info {
	&:not(:only-child) {
		margin-right: $sp5;
	}
}

.pagination__controls {
	display: flex;
	margin-right: -#{$sp3};

	&.is-disabled {
		pointer-events: none;
	}

	@include size(md-up) {
		margin-right: -#{$sp4};
	}
}

.pagination__control {
	color: var(--color-grey-600);
	padding: $sp3;
	cursor: pointer;

	.pagination.-t-dark & {
		color: var(--color-grey-300);
	}

	&.is-disabled {
		cursor: default;
		color: var(--color-grey-300);

		.pagination.-t-dark & {
			color: var(--color-grey-600);
		}
	}

	&:not(.is-disabled):hover {
		color: var(--color-green-petrol);

		.pagination.-t-dark & {
			color: var(--color-hype-yellow);
		}
	}

	.pagination__controls.is-disabled & {
		color: var(--color-grey-300);

		.pagination.-t-dark & {
			color: var(--color-grey-600);
		}
	}

	@include size(md-up) {
		padding: $sp4;
	}
}
</style>
