export const Context = {
	ASPORT:     'asport',
	CLIENT:     'client',
	FEDERATION: 'federation',
	VENUE:      'venue',
	LEAGUE:     'league',
	CLUB:       'club',
	TEAM:       'team',
	PLAYER:     'player',
	FAN:        'fan',
	EVENT:      'event',
};
