<template>
	<div
		v-if="!isUnmounted"
		class="autoplay-muted-indicator"
		:class="buttonClass"
		@click.stop.prevent="handleClick"
	>
		<Icon
			v-if="state === 'unmuted'"
			icon-name="sound-on"
			size="sm"
		/>
		<Icon
			v-else
			icon-name="sound-off"
			size="sm"
		/>
		<span v-if="state !== 'unmuted'">{{ text }}</span>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';

const STATE = {
	DEFAULT:   'default',
	MINIMIZED: 'minimized',
	DISMISSED: 'dismissed',
	UNMOUNTED: 'unmounted',
	UNMUTED:   'unmuted',
};

const MINIMIZE_TIMEOUT_MS = 5000;
const DISMISS_TIMEOUT_MS = 5000;
const UNMOUNT_TIMEOUT_MS = 300;

export default {
	name:       'AutoplayMutedIndicator',
	components: { Icon },
	props:      {
		showSoundOffWhenUnmuted: {
			type:    Boolean,
			default: false,
		},
		disableDismiss: {
			type:    Boolean,
			default: false,
		},
		localIsMobile: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			state: STATE.DEFAULT,
		};
	},
	computed: {
		buttonClass() {
			return {
				minimized: this.state === STATE.MINIMIZED,
				dismissed: this.state === STATE.DISMISSED,
				unmuted:   this.state === STATE.UNMUTED,
			};
		},
		text() {
			return this.$I18n.trans('view_mode.autoplayed_muted_indicator');
		},
		isUnmounted() {
			return this.state === STATE.UNMOUNTED;
		},
	},
	watch: {
		state() {
			if (this.state === STATE.DISMISSED) {
				setTimeout(() => {
					this.state = STATE.UNMOUNTED;
				}, UNMOUNT_TIMEOUT_MS);
			}
		},
	},
	mounted() {
		setTimeout(() => {
			if (this.state !== STATE.DEFAULT) return;
			this.state = STATE.MINIMIZED;
			setTimeout(() => {
				if (this.state !== STATE.MINIMIZED || this.localIsMobile || this.disableDismiss) {
					return;
				}
				this.state = STATE.DISMISSED;
			}, DISMISS_TIMEOUT_MS);
		}, MINIMIZE_TIMEOUT_MS);
	},
	methods: {
		handleClick() {
			// swith to show mute icon when unmuted
			if (this.showSoundOffWhenUnmuted) {
				this.state = this.state === STATE.UNMUTED ? STATE.MINIMIZED : STATE.UNMUTED;
			// unmount
			} else {
				this.state = STATE.UNMOUNTED;
			}

			this.$emit('click');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.autoplay-muted-indicator {
	display: flex;
	align-items: center;
	gap: 8px;
	border-radius: 20px; // stylelint-disable-line sh-waqar/declaration-use-variable
	padding: 12px;
	background-color: var(--color-soft-white); // stylelint-disable-line declaration-property-value-allowed-list
	color: var(--color-shadow-grey);
	font-weight: 500; // stylelint-disable-line property-disallowed-list
	font-size: 14px; // stylelint-disable-line property-disallowed-list, sh-waqar/declaration-use-variable
	max-width: 100%;
	opacity: 1;
	transition: opacity 0.5s cubic-bezier(0, 0, 0, 1), max-width 0.5s cubic-bezier(0, 0, 0, 1);
	overflow: hidden;
	white-space: nowrap;
	line-height: 1;
	cursor: pointer;

	&:hover {
		background-color: var(--color-grey-100); // stylelint-disable-line declaration-property-value-allowed-list

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-800);
		}
	}

	span { // stylelint-disable-line selector-max-type
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
		white-space: nowrap;
	}

	&.minimized {
		max-width: 40px;

		span { // stylelint-disable-line selector-max-type
			opacity: 0;
		}
	}

	&.dismissed {
		max-width: 40px;
		opacity: 0;
	}

	&.unmuted {
		max-width: 40px;
	}
}
</style>
