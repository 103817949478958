import { getArenaConfig } from '@shared/utils/getArenaConfig';
import laroute from '@laroute';

const baseModule = 'user';

const LOGIN_NOTIFICATION_COOKIE = 'asport.loginNotification';
const LOCALSTORAGE_REDIRECT_URL = 'loginRedirectUrl';

const getArenaUrl = (route, parameters) => {
	const url = laroute.route(`arena.${route}`, parameters);
	return url;
};

const fetchProfile = async() => {
	return fetch(laroute.route('api.user.profile'), { credentials: 'include' });
};

const logout = async() => {
	try {
		return fetch(getArenaUrl('api.arena_login.logout'), {
			method:      'POST',
			credentials: 'include',
		});
	} catch (error) {
		return null;
	}
};

const hasSubscriptionForEvent = (accessibleResources, event) => {
	return accessibleResources?.eventIds?.subscription?.includes(parseInt(event.id));
};

const hasPpv = (accessibleResources, event) => {
	return accessibleResources?.eventIds?.bought?.includes(parseInt(event.id));
};

const getInitialState = () => ({
	profile:              null,
	isInitialized:        false,
	subscriptions:        null,
	purchasedEvents:      null,
	hasAccessToEvent:     null,
	fanAccountGeoblocked: false,
	preferences:          {
		// we set a default here to avoid a flash of the noSpoilerMode while the profile is loading
		// also if the user is logged out, we want to show the results
		noSpoilerMode: !getArenaConfig().loadArenaProfile || !getArenaConfig().enableNoSpoilerMode
			? false
			: localStorage.getItem('noSpoilerMode') === 'true',
	},
});

export const mutations = {
	FLUSH_PROFILE:             'flushProfile',
	SET_PROFILE:               'setProfile',
	SET_INITIALIZED:           'setInitialized',
	SET_LOGIN_REDIRECT_URL:    'setLoginRedirectUrl',
	SET_FANACCOUNT_GEOBLOCKED: 'setFanaccountGeoblocked',
};

export const actions = {
	LOGOUT:                    'logout',
	LOAD_PROFILE:              'loadProfile',
	SET_INITIALIZED:           'setInitialized',
	REMOVE_LOGIN_NOTIFICATION: 'removeLoginNotification',
	UPDATE_PROFILE:            'updateProfile',
	SET_LOCALE:                'setLocale',
	CHECK_ARENA_GEOBLOCKING:   'checkArenaGeoblocking',
};

export default {
	namespaced: true,
	state:      getInitialState,
	mutations:  {
		[mutations.FLUSH_PROFILE](state) {
			state.profile = null;
		},
		[mutations.SET_PROFILE](state, { profile }) {
			state.profile = profile;

			if (
				profile.preferences &&
				Object.prototype.hasOwnProperty.call(profile.preferences, 'noSpoilerMode') &&
				getArenaConfig().enableNoSpoilerMode // only set the noSpoilerMode if it is enabled in the config
			) {
				localStorage.setItem('noSpoilerMode', profile.preferences.noSpoilerMode);
				state.preferences.noSpoilerMode = profile.preferences.noSpoilerMode;
			}
		},
		[mutations.SET_INITIALIZED](state) {
			state.isInitialized = true;
		},
		[mutations.SET_LOGIN_REDIRECT_URL](state, redirectUrl) {
			localStorage.setItem(LOCALSTORAGE_REDIRECT_URL, redirectUrl);
		},
		[mutations.SET_FANACCOUNT_GEOBLOCKED](state) {
			state.fanAccountGeoblocked = true;
		},
	},
	actions: {
		async [actions.LOAD_PROFILE]({ commit, dispatch }) {
			// Both Arena and the Player are requesting the profile. Whichever comes first,
			// actually fetches it, other awaits the same promise
			try {
				if (!window.userProfilePromise) {
					window.userProfilePromise = fetchProfile();
				}
				const response = await window.userProfilePromise;
				const profile = await response.clone().json(); // Clone for every read
				if (profile && profile.id) {
					commit(mutations.SET_PROFILE, { profile });
				} else {
					commit(mutations.FLUSH_PROFILE); // Logged out
				}
			} catch (error) {
				console.error(error);
				window.userProfilePromise = null;
				commit(mutations.FLUSH_PROFILE);
			} finally {
				commit(mutations.SET_INITIALIZED);
			}
		},
		[actions.SET_INITIALIZED]({ commit }) {
			commit(mutations.SET_INITIALIZED);
		},
		[actions.LOGOUT]({ commit, dispatch }) {
			dispatch(actions.REMOVE_LOGIN_NOTIFICATION);
			logout()
				.then(() => {
					window.location.reload();
				});
		},
		[actions.REMOVE_LOGIN_NOTIFICATION]() {
			$cookies.remove(LOGIN_NOTIFICATION_COOKIE, null, window.sessionDomain);
		},
		async [actions.UPDATE_PROFILE]({ commit }, profile) {
			const bodyData = profile;
			if (profile.preferences) {
				bodyData.preferences = JSON.stringify(profile.preferences);
			}
			const response = await fetch(laroute.route('api.user.profile.update'), {
				method:      'PATCH',
				credentials: 'include',
				headers:     {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(bodyData),
			});

			if (!response.ok) {
				const errorText = await response.text();
				throw new Error(`HTTP ${response.status}: ${errorText}`);
			}

			const json = await response.json();
			commit(mutations.SET_PROFILE, { profile: json });
		},
		async [actions.SET_LOCALE]({ commit }, locale) {
			const response = await fetch(laroute.route('api.user.profile.update'), {
				method:      'PATCH',
				credentials: 'include',
				headers:     {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ locale }),
			});
			const json = await response.json();
			window.location.reload();
		},
		async [actions.CHECK_ARENA_GEOBLOCKING]({ commit }) {
			const response = await fetch(laroute.route('api.arena_geoblocking'), {
				method:  'GET',
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const json = await response.json();

			if (json.fanaccount && json.fanaccount === 'blocked') {
				commit(mutations.SET_FANACCOUNT_GEOBLOCKED);
			}
		},
	},
	getters: {
		profile:              (state) => state.profile,
		preferences:          (state) => state.preferences,
		userId:               (state) => state.profile ? state.profile.userId : null,
		context:              (state) => state.profile && state.profile.context ? state.profile.context : null,
		isLoggedIn:           (state) => state.profile !== null,
		isInitialized:        (state) => state.isInitialized,
		fanAccountGeoblocked: (state) => state.fanAccountGeoblocked,
		hasAssumedUser:       (state) => state.profile && state.profile.originUserId,
		originUserId:         (state) => state.profile && state.profile.originUserId ? state.profile.originUserId : null,
		loginRedirectUrl:     () => localStorage.getItem(LOCALSTORAGE_REDIRECT_URL),
		purchasedEvents:      (state) => state.profile && state.profile.accessibleResources && state.profile.accessibleResources.eventIds ? state.profile.accessibleResources.eventIds.bought : [],
		subscriptions:        (state) => state.profile && state.profile.accessibleResources?.subscriptionIds ? state.profile.accessibleResources.subscriptionIds : [],
		hasAccessToEvent:     (state) => (event) => {
			// Pay per view or subscription
			return hasSubscriptionForEvent(state.profile?.accessibleResources, event) || hasPpv(state.profile?.accessibleResources, event);
		},
	},
};
