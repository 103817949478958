<template>
	<div
		class="v2-player"
		:class="{
			'is-playing': isPlaying,
			'has-pointer-events': pointerEvents
		}"
	>
		<div
			class="v2-player__player-w"
			@click="handleInit"
		>
			<div
				v-if="!isInitialized"
				class="v2-player__stop -cover"
			/>
			<div class="v2-player__player">
				<video
					ref="player"
					class="v2-player__video"
					:poster="poster"
					playsinline
				/>
				<slot name="over" />
			</div>
			<div
				v-if="error.type && showErrors"
				class="v2-player__error"
				:class="{'is-fatal': error.fatal}"
			>
				<div class="v2-player__error-type">
					Type: {{ error.type }}
				</div>
				<div
					v-if="error.details"
					class="v2-player__error-details"
				>
					Details: {{ error.details }}
				</div>
			</div>
			<LoadingSpinner
				v-if="loadingSpinnerEnabled && showLoadingSpinner"
				class="v2-player__loading"
			/>
			<div
				v-if="isInitialized && showControls"
				class="v2-player__controls"
			>
				<div
					class="v2-player__stop"
					@click="togglePlayer"
				/>
				<V2Icon
					v-if="volume >= 50"
					icon-name="sound-on"
					size="xl"
					@click="mute"
				/>
				<V2Icon
					v-if="volume > 0 && volume < 50"
					icon-name="sound-quiet"
					size="xl"
					@click="mute"
				/>
				<V2Icon
					v-if="volume === 0"
					icon-name="sound-off"
					size="xl"
					@click="setVolume(Math.max(preMuteVolume, 20))"
				/>

				<input
					class="v2-player__volume"
					:value="volume"
					type="range"
					min="0"
					max="100"
					step="1"
					@change="volumeChangeHandler"
				>
				<div
					v-if="audioTracks.length"
					class="v2-player__audio"
					@click="() => audioTrackMenuVisible = !audioTrackMenuVisible"
				>
					<V2Icon
						icon-name="voice-recognition"
						size="xl"
					/>
					<div
						v-if="audioTrackMenuVisible"
						class="v2-player__audio-options"
					>
						<button
							v-for="track in audioTracks"
							:key="track.id"
							class="v2-player__audio-option"
							@click.stop="setAudioTrack(track.id)"
						>
							<span>
								{{ track.label }}
							</span>
							<V2Icon
								v-if="track.selected"
								class="selected"
								icon-name="check"
								size="sm"
							/>
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import V2Icon from '@shared/components/Icon';
import Hls from 'hls.js';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { getAudiotrackLabel } from '@shared/utils/audiotrack';

const HLS_EVENT = Hls.Events;

export default {
	name:       'Player',
	components: { V2Icon, LoadingSpinner },
	props:      {
		hlsUrl: {
			type:     String,
			required: true,
		},
		poster: {
			type:    String,
			default: '',
		},
		autoInit: {
			type:    Boolean,
			default: true,
		},
		showControls: {
			type:    Boolean,
			default: true,
		},
		autoPlay: {
			type:    Boolean,
			default: false,
		},
		pointerEvents: {
			type:    Boolean,
			default: false,
		},
		showErrors: {
			type:    Boolean,
			default: true,
		},
		loadingSpinnerEnabled: {
			type:    Boolean,
			default: true,
		},
		hlsConfig: {
			type:    Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			isInitialized:            this.autoInit,
			isPlaying:                false,
			player:                   null,
			volume:                   100,
			preMuteVolume:            100,
			audioTracks:              [],
			audioTrackMenuVisible:    false,
			showLoadingSpinner:       false,
			currentQualityLevelIndex: -1,
			enableAutoQuality:        true,
			qualityLevels:            [],
			error:                    {
				type:    null,
				details: null,
				fatal:   false,
			},
		};
	},
	mounted() {
		if (this.autoInit) {
			this.init();
		}
	},
	methods: {
		handleInit() {
			if (this.isInitialized) {
				return;
			}
			this.init();
			this.isInitialized = true;
			this.start();
		},
		init() {
			const video = this.$refs.player;
			if (Hls.isSupported()) {
				const hls = new Hls(this.hlsConfig);
				hls.loadSource(this.hlsUrl);
				hls.attachMedia(video);
				this.hls = hls;
			} else if (video.canPlayType('application/vnd.apple.mpegurl')) {
				video.src = this.hlsUrl;
			}

			if (this.autoPlay) {
				this.$refs.player.autoplay = true;
				this.$refs.player.muted = true;
				this.$emit('autoplay-muted');
			}

			this.volume = this.$refs.player.volume * 100;
			this.preMuteVolume = this.$refs.player.volume * 100;
			this.registerHlsErrorListeners();
			this.registerNativeListeners();
			this.registerHlsAudioTrackListeners();
			this.registerHlsQualityLevelListeners();

			this.$refs.player.addEventListener('play', () => {
				this.isPlaying = true;
				this.disableLoadingSpinner();
				this.$emit('play');
			});
			this.$refs.player.addEventListener('timeupdate', () => {
				this.isPlaying = true;
				if (this.$refs.player) {
					this.$emit('timeupdate', this.$refs.player.currentTime);
				}
				if (this.showLoadingSpinner || this.showLoadingSpinnerTimeout) {
					this.timeUpdateCount += 1;

					if (this.timeUpdateTimeout) {
						if (this.timeUpdateCount > 2) {
							this.disableLoadingSpinner();
							clearTimeout(this.timeUpdateTimeout);
							this.timeUpdateTimeout = null;
						}
						return;
					}
					this.timeUpdateTimeout = setTimeout(() => {
						this.timeUpdateCount = 0;
					}, 1000);
				}
			});
			this.$refs.player.addEventListener('pause', () => {
				this.isPlaying = false;
			});
			this.$refs.player.addEventListener('loadedmetadata', () => {
				const duration = this.getDuration();
				this.$emit('loadedmetadata', duration);
			});
		},
		togglePlayer() {
			if (this.isPlaying) {
				this.stop();
			} else {
				this.start();
			}
		},
		stop() {
			this.$refs.player.pause();
		},
		play() {
			this.$refs.player?.play().catch(() => {});
		},
		pause() {
			if (this.$refs.player) {
				this.$refs.player.pause();
			}
		},
		start() {
			this.$refs.player.play();

			if (this.$refs.player?.duration) {
				this.$refs.player.currentTime = this.$refs.player.duration - 6;
			}
		},
		seek(time) {
			if (this.$refs.player) {
				this.$refs.player.currentTime = time;
			}
		},
		end() {
			this.$refs.player.pause();
			this.hls.stopLoad();
		},
		mute() {
			this.preMuteVolume = this.volume;
			this.$refs.player.volume = 0;
			this.volume = 0;
			this.$refs.player.muted = true;
		},
		unmute() {
			this.$refs.player.muted = false;
			this.setVolume(this.preMuteVolume);
		},
		toggleMute() {
			if (this.$refs.player.muted) {
				this.unmute();
			} else {
				this.mute();
			}
		},
		setVolume(volume = this.preMuteVolume) {
			if (volume > 0) {
				this.preMuteVolume = volume;
			}
			this.volume = volume;
			this.$refs.player.volume = volume / 100;
		},
		setAudioTrack(id) {
			if (this.hls) {
				if (this.hls.audioTrack !== id) {
					this.hls.audioTrackController.setAudioTrack(id);
				}
			}
			this.audioTrackMenuVisible = false;
		},
		setQuality(value = 0) {
			if (value === 0) {
				this.enableAutoQuality = true;
			} else {
				this.enableAutoQuality = false;
			};
			if (value >= this.qualityLevels.length) {
				return;
			}
			this.hls.loadLevel = value - 1;
			this.hls.nextLevel = value - 1;
		},
		volumeChangeHandler(e) {
			this.setVolume(parseInt(e.target.value));
		},
		registerHlsErrorListeners() {
			if (this.hls) {
				this.hls.on(HLS_EVENT.ERROR, (_event, data) => {
					if (data.type === Hls.ErrorTypes.MEDIA_ERROR && [Hls.ErrorDetails.BUFFER_STALLED_ERROR, Hls.ErrorDetails.BUFFER_NUDGE_ON_STALL].includes(data.details)) {
						this.enableLoadingSpinner();
					} else {
						this.error.type = data.type;
						this.error.details = data.details;
						this.error.fatal = data.fatal;

						this.$emit('error', {
							type:    data.type,
							details: data.details,
							fatal:   data.fatal,
							event:   _event.type,
						});
					}
				});
			}
		},
		registerNativeListeners() {
			this.$refs.player.addEventListener('error', (event) => {
				this.error.type = 'MEDIA_ERROR';
				this.error.details = event.target.error.code;
				this.error.fatal = true;

				this.$emit('error', {
					type:    'MEDIA_ERROR',
					details: event.target.error.code,
					fatal:   true,
					event:   event.type,
				});
			});
			// show loading spinner on stalled
			this.$refs.player.addEventListener('stalled', () => {
				this.enableLoadingSpinner();
			});
			// show loading spinner on waiting
			this.$refs.player.addEventListener('waiting', () => {
				this.enableLoadingSpinner();
			});
		},
		registerHlsAudioTrackListeners() {
			if (this.hls) {
				this.hls.on(HLS_EVENT.AUDIO_TRACKS_UPDATED, (_event, data) => {
					this.audioTracks = data?.audioTracks?.map((track, i) => {
						return {
							id:       track.id,
							label:    getAudiotrackLabel(track, i),
							selected: track.default || false,
						};
					}) || [];
				});
				this.hls.on(HLS_EVENT.AUDIO_TRACK_SWITCHED, (_event, data) => {
					this.audioTracks = [
						...this.audioTracks.map(track => {
							return { ...track, selected: data?.id === track.id };
						}),
					];
				});
			}
		},
		registerHlsQualityLevelListeners() {
			if (this.hls) {
				this.hls.on(HLS_EVENT.LEVEL_LOADED, (_event) => {
					const levels = this.hls.levels.map((level) => {
						let label = level.bitrate;

						if (level.name) label = level.name;
						else if (level.height) label = `${level.height}p`;

						return {
							id:      level.id,
							label,
							bitrate: level.bitrate,
							width:   level.width,
							height:  level.height,
							url:     level.uri,
						};
					});
					this.qualityLevels = [{ id: -1, label: 'Auto' }, ...levels];
					this.$emit('quality-levels-updated', this.qualityLevels);
				});

				this.hls.on(HLS_EVENT.LEVEL_SWITCHED, (_event) => {
					if (this.enableAutoQuality) {
						this.currentQualityLevelIndex = 0;
					} else {
						this.currentQualityLevelIndex = this.hls.currentLevel + 1;
					}
				});
			}
		},
		enableLoadingSpinner(timeout = 1000) {
			if (this.showLoadingSpinnerTimeout || this.showLoadingSpinner) {
				return;
			}
			clearTimeout(this.showLoadingSpinnerTimeout);
			this.showLoadingSpinnerTimeout = setTimeout(() => {
				this.showLoadingSpinner = true;
				this.showLoadingSpinnerTimeout = null;
			}, timeout);
		},
		disableLoadingSpinner() {
			clearTimeout(this.showLoadingSpinnerTimeout);
			this.showLoadingSpinnerTimeout = null;
			this.showLoadingSpinner = false;
		},
		getDuration() {
			if (!this.$refs.player) {
				return 0;
			}
			return this.$refs.player.duration;
		},
		getCurrentTime() {
			if (!this.$refs.player) {
				return 0;
			}
			return this.$refs.player.currentTime;
		},
	},
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@shared/sass/shared-variables';

$colorvar-track: var(--color-soft-white) !default;
$colorvar-thumb: var(--color-soft-white) !default;

$thumb-radius: 50% !default;
$thumb-height: 16px !default;
$thumb-width: 16px !default;
$thumb-border-width: 2px !default;
$colorvar-thumb-border: var(--color-soft-white) !default;

$track-width: 220px !default;
$track-height: 2px !default;
$track-border-width: 0 !default;
$colorvar-track-border: var(--color-soft-white) !default;

$track-radius: 2px !default;
$contrast: 40% !default;

$colorvar-ie-bottom-track: var(--color-grey-300) !default;

@mixin track {
	cursor: default;
	height: $track-height;
	width: $track-width;
}

@mixin thumb {
	background-color: $colorvar-thumb;
	border: $thumb-border-width solid $colorvar-thumb-border;
	border-radius: $thumb-radius;
	box-sizing: border-box;
	cursor: default;
	height: $thumb-height;
	width: $thumb-width;
}

.v2-player__player-w {
	position: relative;
	line-height: 0;
}

.v2-player__video {
	width: 100%;
}

.v2-player__player::v-deep * { // stylelint-disable-line

	.v2-player:not(.has-pointer-events) & {
		pointer-events: none;
	}
}

.v2-player__loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%);
}

.v2-player__controls {
	display: flex;
	align-items: center;
	padding-left: $sp6;
	padding-right: $sp6;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 64px;
	background-color: rgba(0, 0, 0, 0.6);
	color: var(--color-soft-white);
}

.v2-player__stop {
	width: 0;
	height: 0;
	border-top: 10px solid transparent;
	border-left: 20px solid var(--color-soft-white);
	border-bottom: 10px solid transparent;
	margin-right: $sp6;
	cursor: pointer;

	.v2-player.is-playing & {
		width: 20px;
		height: 20px;
		border: none;
		background-color: var(--color-soft-white);
	}

	&.-cover {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%);
		border-top: 10px solid transparent;
		border-left: 20px solid var(--color-soft-white);
		border-bottom: 10px solid transparent;
	}
}

.v2-player__volume {
	margin-left: $sp6;
	cursor: pointer;
}

.v2-player__audio {
	margin-left: $sp6;
	cursor: pointer;
	position: relative;
}

.v2-player__audio-options {
	position: absolute;
	bottom: 150%;
	background-color: rgba(0, 0, 0, 0.6);
	text-decoration: underline;
	color: var(--color-soft-white);
}

.v2-player__audio-option {
	display: flex;
	align-items: center;
	padding: $sp4;
	width: 100%;

	&:hover {
		background: var(--color-grey-700); // stylelint-disable-line
	}

	.selected {
		margin-left: $sp3;
	}
}

[type='range'] {
	appearance: none;
	background-color: transparent;
	width: $track-width;

	&::-moz-focus-outer {
		border: 0;
	}

	&:focus {
		outline: 0;

		&::-webkit-slider-runnable-track {
			background-color: $colorvar-track; // stylelint-disable-line
		}

		&::-ms-fill-lower {
			background-color: $colorvar-track;
		}

		&::-ms-fill-upper {
			background-color: $colorvar-track; // stylelint-disable-line
		}
	}

	&::-webkit-slider-runnable-track {
		@include track;
		background-color: $colorvar-track;
		border: $track-border-width solid $colorvar-track-border;
		border-radius: $track-radius;
	}

	&::-webkit-slider-thumb {
		@include thumb;
		appearance: none;
		margin-top: (math.div(math.div((-$track-border-width * 2 + $track-height), 2) - $thumb-height, 2));
	}

	&::-moz-range-track {
		@include track;
		background-color: $colorvar-track;
		border: $track-border-width solid $colorvar-track-border;
		border-radius: $track-radius;
		height: math.div($track-height, 2);
	}

	&::-moz-range-thumb {
		@include thumb;
	}

	&::-ms-track {
		@include track;
		background-color: transparent;
		border-color: transparent;
		border-width: (math.div($thumb-height, 2)) 0;
		color: transparent;
	}

	&::-ms-fill-lower {
		background-color: $colorvar-ie-bottom-track;
		border: $track-border-width solid $colorvar-track-border;
		border-radius: ($track-radius * 2); // stylelint-disable-line
	}

	&::-ms-fill-upper {
		background-color: $colorvar-track;
		border: $track-border-width solid $colorvar-track-border;
		border-radius: ($track-radius * 2); // stylelint-disable-line
	}

	&::-ms-thumb {
		@include thumb;
		margin-top: math.div($track-height, 4);
	}

	&:disabled {
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb,
		&::-webkit-slider-runnable-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			cursor: not-allowed;
		}
	}
}

.v2-player__error {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-shadow-grey);
	color: var(--color-white);
	padding: $sp6 $sp5;

	&.is-fatal {
		background-color: var(--color-red);
	}
}

.v2-player__error-type {
	@include font(primary, bold, normal, fs-150);
	margin-bottom: $sp3;
}

// .v2-player__error-details  {}

</style>
