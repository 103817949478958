<template>
	<component
		:is="withLink ? 'a' : 'div'"
		class="l-carousel-wrapper-player card-player"
		:href="withLink ? link : false"
	>
		<div
			class="card-player__inner"
		>
			<img
				v-if="portraitImage"
				class="card-player__img"
				:src="portraitImage"
			>
			<div
				v-else
				class="card-player__img -placeholder"
			>
				<Icon
					icon-name="account"
					size="xl"
				/>
			</div>
			<h4
				class="card-player__title"
				v-text="item.name"
			/>
			<h5
				v-if="subtitle"
				class="card-player__subtitle"
				v-text="subtitle"
			/>
		</div>
	</component>
</template>

<script>
import Icon from '@shared/components/Icon';
import laroute from '@laroute';

export default {
	name:       'PlayerCard',
	components: { Icon },
	props:      {
		item: {
			type:     Object,
			required: true,
		},
		withLink: {
			type:     Boolean,
			required: false,
			default:  false,
		},
	},
	computed: {
		subtitle() {
			if (!this.item.position) {
				return null;
			}
			// Todo: translations missing for positions for certain sports like futsal
			const translatedPosition = this.$I18n.trans('sports.' + this.item.position.sport.name + '.positions.' + this.item.position.name);
			return translatedPosition.includes(this.item.position.name) ? null : translatedPosition;
		},
		link() {
			return laroute.route('arena.player', {
				player: this.item.id,
				slug:   this.$Helpers.slugify(this.item.name),
			});
		},
		portraitImage() {
			if (!this.item.imageUrl || this.item.imageUrl.includes('placeholder')) {
				return null;
			}
			return this.$Helpers.getScaledImageUrl(this.item.imageUrl, 100);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-player {
	@include font-size(fs-60);

	display: block;

	&.a {
		cursor: pointer;
	}
}

.card-player__inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: var(--color-grey-900);
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	padding: $sp4 $sp3;
	border: 1px solid var(--color-grey-800);
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;

	.is-keynav-section-active .is-selected & {
		transform: scale(1.09);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include box-shadow(1);
		background-color: var(--color-white);
		border: none;
	}

	&:focus,
	&:hover {
		transform: scale(1.09);
	}
}

.card-player__img {
	width: 72px;
	height: 72px;
	border: 1px solid var(--color-grey-800);
	background-color: var(--color-body-background);
	border-radius: 50%;
	margin-bottom: $sp4;
	object-fit: cover;

	&.-placeholder {
		display: flex;
		justify-content: center;
		align-items: center;
		stroke-width: 0.5px;
		color: var(--color-grey-500);
	}
}

.card-player__title {
	margin-bottom: $sp1;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include font(primary, bold, normal, fs-60);
		color: var(--color-black);
	}
}

.card-player__subtitle {
	color: var(--color-grey-500);
}

</style>
