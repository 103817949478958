<template>
	<Modal
		ref="paywallModal"
		modal-name="PaywallModal"
		type="dialog"
		class="paywall__modal"
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		:closeable="false"
	>
		<template #title>
			{{ isGeoblocked ? $I18n.trans('event.geoblocked.title') : $I18n.trans('payments.paywall.title') }}
		</template>
		<template #content>
			<template v-if="isGeoblocked">
				<V2Icon
					class="geo-blocking__icon"
					icon-name="world-pin"
				/>
				<div
					class="geo-blocking__message"
					v-html="geoblockingMessage"
				/>
			</template>
			<template v-else>
				<div
					v-if="paymentOffers && paymentOffers.length > 0"
					class="paywall__desc"
				>
					{{ $I18n.trans(`payments.paywall.${isLoggedIn ? 'content_logged_in' : 'content_logged_out'}`) }}
				</div>
				<template v-if="paymentOffers && paymentOffers.length > 0">
					<div
						v-for="offer in paymentOffers"
						:key="offer.id"
						class="paywall__offer"
					>
						{{ $Helpers.getTranslatedContent(offer.name) }}: {{ formatCurrency(offer.currencyCode, offer.amount) }}
						<div class="payment__offer-desc">
							{{ $I18n.trans('payments.paywall.use_voucher') }}
						</div>
					</div>
				</template>
				<div
					v-else
					class="paywall__offer"
				>
					{{ $I18n.trans('payments.paywall.no_offers_event') }}
				</div>
				<div
					v-if="!isLoggedIn && paymentOffers && paymentOffers.length > 0"
					class="paywall__login-hint"
				>
					<V2Icon
						icon-name="c-info"
						size="md"
						class="paywall__login-hint-icon"
					/>
					{{ $I18n.trans('payments.paywall.login_hint', { button: $I18n.trans('commons.continue')}) }}
				</div>
			</template>
		</template>
		<template #footer>
			<div class="l-button-group">
				<Button
					v-if="computedBackUrl && !$Helpers.isSmartTV()"
					:text="$Helpers.ucfirst($I18n.trans('commons.back'))"
					theme="dark"
					modifier="secondary"
					@click="back()"
				/>
				<Button
					v-if="canContinue"
					class="paywall__continue"
					:disabled="cannotPurchase"
					:text="$I18n.trans(`${isLoggedIn ? 'commons.continue' : 'arena.login.error_page.button'}`)"
					:busy="busy"
					theme="dark"
					autofocus
					@click="next()"
				/>
			</div>
		</template>
	</Modal>
</template>

<script>
import laroute from '@laroute';
import { formatCurrency } from '@shared/utils/formatNumber';
import { mapState, mapGetters } from 'vuex';
import Modal from '@shared/components/Modal';
import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';

export default {
	name:       'PaywallModal',
	components: { Modal, Button, V2Icon },
	props:      {
		backUrl: {
			type:     String,
			required: false,
			default:  null,
		},
		event: {
			type:     Object,
			required: true,
		},
	},
	data() {
		return {
			busy:          false,
			paymentOffers: null,
			action:        window.location.hash.replace('#', '').split('/qr/')[0],
			qrCode:        window.location.hash.split('/qr/').length > 1 ? window.location.hash.split('/qr/')[1] : null,
			isGeoblocked:  false,
		};
	},
	computed: {
		...mapState({
			profileIsInitialized: state => state.user.isInitialized,
		}),
		...mapGetters({
			hasAccessToEvent: 'user/hasAccessToEvent',
			purchasedEvents:  'user/purchasedEvents',
			subscriptions:    'user/subscriptions',
			isLoggedIn:       'user/isLoggedIn',
			context:          'user/context',
		}),
		cannotPurchase() {
			return !this.paymentOffers || this.paymentOffers.length === 0;
		},
		computedBackUrl() {
			return this.backUrl || $Helpers.getBackUrl();
		},
		geoblockingMessage() {
			return window.$Helpers.getTranslatedContent(window.arenaConfig.geoblocking?.message) || $I18n.trans('event.geoblocked.message');
		},
		canContinue() {
			return !this.isGeoblocked;
		},
		hasPreview() {
			return (this.paymentOffers || []).some(obj => obj.previewSeconds > 0);
		},
		isUpcoming() {
			return this.event && this.event.liveInSeconds > 0;
		},
	},
	watch: {
		profileIsInitialized() {
			if (!this.hasAccessToEvent(this.event) || this.action === 'confirm') {
				this.initializeModal();
			}
		},
	},
	mounted() {
		window.addEventListener('keydown', this.handleKeyDown);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeyDown);
	},
	methods: {
		async initializeModal() {
			await this.getPaymentOffers();
			const isInPaymentFlow = ['login', 'checkout', 'confirm'].includes(this.action);
			const showPaywall = this.action === 'buy' || !this.hasPreview || this.isUpcoming;

			// Proceed directly to payment, or open the paywall modal depending on the url hash
			if (isInPaymentFlow) {
				const data = {
					action: this.isLoggedIn ? this.action : 'login',
				};

				if (this.qrCode) {
					data.qrCode = this.qrCode;
				}
				this.eventBus.$emit('modal:PaymentModal', data);
			} else if (showPaywall) {
				// If there is no preview (including in pre-live state), show the paywall
				this.eventBus.$emit('modal:PaywallModal');
			}
		},
		next() {
			if ($Helpers.isSmartTV()) {
				// Show QR login, redirect to checkout page for this event after login on smartphone / desktop
				this.eventBus.$emit('modal:QrLoginModal', {
					redirectAfterLoginUrl: window.location.href.split('#')[0] + '#checkout',
					eventId:               this.event.id,
				});
			} else {
				// this.eventBus.$emit('modalClose:PaywallModal');
				this.eventBus.$emit('modal:PaymentModal', { action: this.isLoggedIn ? 'checkout' : 'login' });
			}
		},
		back() {
			window.location.href = this.computedBackUrl;
		},
		async getPaymentOffers() {
			const response = await fetch(laroute.route('api.payment.offers', { eventId: this.event.id }));
			const data = await response.json();
			this.paymentOffers = Array.isArray(data) ? data : [];
			if (data.error && data.reason === 'geoblocked') {
				this.isGeoblocked = true;
			}
		},
		formatCurrency,
		handleKeyDown(event) {
			const modalIsOpen = this.$refs.paywallModal && this.$refs.paywallModal.modalActive;
			if (!event.key || !modalIsOpen) {
				return;
			}
			const key = event.key.toUpperCase();
			// handle ENTER on SmartTV (also fine for non SmartTV)
			if (key === 'ENTER') {
				this.next();
				window.removeEventListener('keydown', this.handleKeyDown);
			}
		},
	},

};
</script>

<style lang="scss" scoped>
@import '@sass/variables';
$context-col-width: 85px;

.paywall__offer {
	padding: 24px;
	background-color: var(--color-grey-950);
	margin-bottom: $sp3;
}

.paywall__desc {
	margin-bottom: $sp6;
}

.payment__offer-desc {
	margin-top: 6px;
	color: var(--color-grey-300);
}

.paywall__login-hint {
	margin-top: $sp5;
	position: relative;
	color: var(--color-grey-300);
	padding-left: 30px;
}

.paywall__login-hint-icon {
	position: absolute;
	top: 0;
	left: 0;
}

.geo-blocking__icon.v2-icon.-size-md {
	margin-bottom: $sp6;
	width: 64px;
	height: 64px;
}

.geo-blocking__message::v-deep a { // stylelint-disable-line
	text-decoration: underline;
}
</style>
