<template>
	<Layout>
		<div class="l-container">
			<div class="terms">
				<div class="l-row l-align-center">
					<div class="l-col l-col--12 l-col--8@lg">
						<InPageNavigation
							:nav-items="navItems"
							:use-anchor-tag="false"
							:dark-theme="!$arenaConfig.isLightTheme"
							horizontal
							@nav-change="handleInPageNavChange"
						/>

						<div class="terms__content">
							<!-- eslint-disable vue/no-v-html -->
							<section
								v-show="isAGBView"
								class="s-html"
								v-html="agb"
							/>

							<section
								v-show="isLegalView"
								class="s-html"
								v-html="legal"
							/>

							<section
								v-show="isJugendschutzView"
								class="s-html"
								v-html="jugendschutz"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import InPageNavigation from '@shared/components/InPageNavigation';

export default {
	name:       'ViewTermsSportpass',
	components: { Layout, InPageNavigation },
	props:      {
		agb: {
			type:     String,
			required: true,
		},
		legal: {
			type:     String,
			required: true,
		},
		jugendschutz: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			navItems: [
				{
					label:  'AGB',
					url:    'agb',
					active: true,
				},
				{
					label:  'Datenschutzerklärung & Rechtliche Hinweise',
					url:    'legal',
					active: false,
				},
				{
					label:  'Jugendschutzerklärung',
					url:    'jungendschutzerklaerung',
					active: false,
				},
			],
		};
	},
	computed: {
		isAGBView() {
			return this.navItems.find(i => i.url === 'agb').active;
		},
		isLegalView() {
			return this.navItems.find(i => i.url === 'legal').active;
		},
		isJugendschutzView() {
			return this.navItems.find(i => i.url === 'jungendschutzerklaerung').active;
		},
	},
	methods: {
		handleInPageNavChange(url) {
			this.navItems.forEach(i => {
				i.active = false;
				if (url === i.url) {
					i.active = true;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.terms {
	margin-top: $sp7;
	margin-bottom: $sp7;
}

.terms__content {
	margin-top: $sp7;
}

</style>
