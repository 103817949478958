<template>
	<div
		class="carousel"
		:class="{'-no-overflow': disableCarouselCardOverflow}"
	>
		<div
			ref="flickity"
			class="flickity-enabled"
			:class="{ 'has-dots': pageDots }"
		>
			<template v-for="(cell, index) in cells">
				<slot
					name="cell"
					:cell="cell"
					:redirect="redirect"
					:index="index"
					:is-dragging="isDragging"
				/>
			</template>
		</div>
	</div>
</template>

<script>
import Flickity from 'flickity';
import laroute from '@laroute';

export default {
	name:  'Carousel',
	props: {
		cells: {
			type:     Array,
			required: true,
		},
		initialIndex: {
			type:    [String, Number],
			default: 0,
		},
		groupCells: {
			type:    [String, Number],
			default: '103%',
		},
		pageDots: {
			type:    Boolean,
			default: false,
		},
		autoPlay: {
			type:    Boolean,
			default: false,
		},
		autoPlaySpeed: {
			type:    Number,
			default: 4000,
		},
		rightAlignLastCell: {
			type:    Boolean,
			default: true,
		},
		wrapAround: {
			type:    Boolean,
			default: false,
		},
		disableCarouselCardOverflow: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			flickityOptions: {
				initialIndex:       this.initialIndex,
				resize:             true,
				prevNextButtons:    true,
				pageDots:           this.pageDots,
				autoPlay:           this.autoPlay ? this.autoPlaySpeed : false,
				wrapAround:         this.wrapAround,
				cellAlign:          'left',
				contain:            this.rightAlignLastCell,
				percentPosition:    false,
				freeScroll:         false,
				groupCells:         $Helpers.isSmartTV() ? false : this.groupCells,
				dragThreshold:      10,
				draggable:          true,
				selectedAttraction: 0.15,
				friction:           0.8,
				arrowShape:         'M76.8057 1.25526C78.4793 2.92893 78.4793 5.6425 76.8057 7.31617L34.1218 50L76.8057 92.6838C78.4793 94.3575 78.4793 97.0711 76.8057 98.7447C75.132 100.418 72.4184 100.418 70.7447 98.7447L22 50L70.7447 1.25526C72.4184 -0.418419 75.132 -0.418419 76.8057 1.25526Z',
				on:                 {
					change: (index) => {
						this.$emit('change', index);
					},
					ready: () => {
						this.$nextTick(() => {
							if (this.cells[0]?.pauseForSeconds) {
								this.$flickity.pausePlayer();
								setTimeout(() => {
									this.$flickity.unpausePlayer();
								}, this.cells[0].pauseForSeconds * 1000);
							}
						});
					},
					settle: (index) => {
						if (this.cells[index]?.pauseForSeconds) {
							this.$flickity.pausePlayer();
							setTimeout(() => {
								this.$flickity.unpausePlayer();
							}, this.cells[index].pauseForSeconds * 1000);
						}
					},
				},
			},
			isDragging: false,
		};
	},
	mounted() {
		this.init();
		window.eventBus.$on('update-carousels', this.rerender);
	},
	beforeDestroy() {
		this.$flickity.destroy();
		this.$flickity = null;
	},
	methods: {
		init() {
			if (!this.$refs.flickity) {
				return;
			}
			this.$flickity = new Flickity(this.$refs.flickity, this.flickityOptions);
			this.$emit('init', this.$flickity);

			this.$flickity.on('dragStart', () => {
				this.isDragging = true;
			});
			this.$flickity.on('dragEnd', () => {
				// leave this until I find a better way to listen for slot events
				setTimeout(() => {
					this.isDragging = false;
				}, 100);
			});
			this.$flickity.on('change', (e) => {
				this.$emit('change', e);
			});
		},
		flickity() {
			return this.$flickity;
		},
		rerender() {
			if (this.$flickity) {
				this.$flickity.destroy();
				this.$nextTick(() => {
					this.init();
					this.$emit('change', 0);
				});
			}
		},
		redirect(routeName, params) {
			if (!this.isDragging) {
				window.location.href = laroute.route(routeName, params);
			}
		},
		previous() {
			if (this.$flickity) {
				this.$flickity.previous();
			}
		},
		next() {
			if (this.$flickity) {
				this.$flickity.next();
			}
		},
		select(index) {
			if (this.$flickity) {
				this.$flickity.select(index);
			}
		},
		clickSelected() {
			if (this.$flickity) {
				const element = this.$flickity.selectedElement;
				if (element) {
					if (element.href) {
						element.click();
					} else {
						const links = element.querySelectorAll('a');
						if (links && links.length > 0) {
							links[0].click();
						}
					}
				}
			}
		},
	},
};
</script>

<style lang="scss">
@import '@sass/variables';
@import 'flickity/dist/flickity.css';

.carousel {
	position: relative;
}

.flickity-viewport {
	display: flex;
	align-items: center;
	overflow-x: clip;
	overflow-y: visible;

	.carousel:not(.-no-overflow) & {
		margin: -#{$carousel-card-overflow} 0 -#{$carousel-card-overflow} -#{$carousel-card-overflow};
	}
}

.flickity-slider {
	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		display: flex;
	}
}

.flickity-prev-next-button {
	position: absolute;
	top: 50%;
	border: none;
	height: 48px;
	width: 48px;
	background-color: var(--color-grey-850);
	color: var(--color-white);
	border-radius: 50%;
	right: 0;
	visibility: hidden;
	cursor: pointer;
	transition: background-color $trans-time-fast, color $trans-time-fast !important; // stylelint-disable-line declaration-no-important
	transform: translateY(-50%);

	&.next {
		right: $sp3;
	}

	&.previous {
		left: $sp3;
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-grey-900);
		color: var(--color-grey-600);
	}

	&:disabled {
		visibility: hidden;
	}

	&:hover {
		background-color: var(--color-hype-yellow);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-hype-yellow);
			color: var(--color-white);
		}
		/* stylelint-disable */
		body.-t-oeoc &,
		body.-t-asport & {
			color: var(--color-black);
		}
		/* stylelint-enable */
	}

	.flickity-enabled:hover & {
		&:enabled {
			visibility: visible;
		}
	}

	@media screen and (pointer: coarse) {
		display: none;
	}
}

.flickity-button-icon.flickity-button-icon.flickity-button-icon {
	left: 50%;
	top: 50%;
	width: 40%;
	height: 40%;
	transform: translate(-50%, -50%);
}

.flickity-page-dots {
	display: none;
	position: absolute;
	right: 0;
	text-align: right;
	margin-top: 30px;
	white-space: nowrap;

	.dot {
		width: $sp5;
		height: $sp2;
		background-color: var(--color-grey-800);
		border-radius: $border-radius--sm;
		margin: 0;
		opacity: 1;

		&.is-selected {
			background-color: var(--color-grey-200);
		}

		&:not(:last-child) {
			margin-right: $sp1;
		}

		&:not(:first-child) {
			margin-left: $sp1;
		}

		&:only-child {
			display: none;
		}
	}

	.flickity-enabled.has-dots & {
		display: block;
	}
}
</style>
