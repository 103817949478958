<template>
	<div class="info-box">
		<div class="info-box__content-w">
			<V2Icon
				:icon-name="icon"
				size="xxxl"
				class="info-box__icon"
			/>
			<h2 class="info-box__title">
				{{ title }}
			</h2>

			<!-- eslint-disable vue/no-v-html -->
			<div
				class="info-box__copy"
				v-html="copy"
			/>
			<!--eslint-enable-->
			<div
				v-if="info"
				class="info-box__copy -small"
			>
				{{ info }}
			</div>
		</div>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

export default {
	name:       'InfoBox',
	components: { V2Icon },
	props:      {
		icon: {
			type:    String,
			default: 'newsletter',
		},
		title: {
			type:     String,
			required: true,
		},
		copy: {
			type:     String,
			required: true,
		},
		info: {
			type:    String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.info-box {
	display: flex;
	justify-content: center;
	border: 1px solid var(--color-grey-700);
	padding: $sp6;
	text-align: center;
}

.info-box__content-w {
	max-width: 600px;
}

.info-box__icon {
	margin-bottom: $sp4;
}

.info-box__title {
	@include font-size(fs-150);
	margin-bottom: $sp4;
}

.info-box__copy {
	@include font-size(fs-100);
	margin-bottom: $sp6;

	&.-small {
		@include font-size(fs-80);
		color: var(--color-grey-500);
	}
}
</style>
