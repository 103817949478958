import Vue from 'vue';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import 'moment/dist/locale/de';
import 'moment/dist/locale/fr';
import 'moment/dist/locale/it';
import 'moment/dist/locale/es';
import 'moment/dist/locale/ca';
import { setupToast } from '@shared/plugins/Toast';
import { getArenaConfig } from '@shared/utils/getArenaConfig';
import I18n from '@legacy/js/vendor/I18n';
import Helpers from '@legacy/js/helpers';

momentDurationFormatSetup(moment);
moment.locale(window.locale);

window.moment = moment;

window.eventBus = new Vue();
Vue.prototype.eventBus = window.eventBus;

Vue.prototype.$I18n = new I18n();
Vue.prototype.$Helpers = new Helpers();
window.$Helpers = new Helpers();
const arenaConfig = getArenaConfig();
Vue.prototype.$arenaConfig = arenaConfig;
window.$arenaConfig = arenaConfig;

setupToast();
