class ApiClient {
	host = '';
	includeCredentials = false;
	prependArenaHost = true;

	constructor(options = {}) {
		if (options.host) {
			this.host = options.host;
		}

		if (options.includeCredentials) {
			this.includeCredentials = options.includeCredentials;
		}

		if (options.prependArenaHost) {
			this.prependArenaHost = options.prependArenaHost;
		}
	}

	get(url, config = null) {
		return fetch(this.getApiUrl(url), this.getParameters(config));
	};

	post(url, data, config = null) {
		return fetch(this.getApiUrl(url),
			{
				method: 'POST',
				body:   JSON.stringify(data),
				...this.getParameters(config, { 'Content-Type': 'application/json' }),
			});
	};

	put(url, data, config = null) {
		return this.client.put(this.getApiUrl(url), data, this.getParameters(config));
	};

	delete(url, config = null) {
		return this.client.delete(this.getApiUrl(url), this.getParameters(config));
	};

	patch(url, data, config = null) {
		return this.client.patch(this.getApiUrl(url), data, this.getParameters(config));
	};

	getApiUrl(path) {
		return (this.prependArenaHost ? (window.location.protocol + '//' + this.host) : '') + path;
	}

	getParameters(config, headers = {}) {
		return {
			mode:        this.includeCredentials ? 'cors' : 'no-cors',
			credentials: this.includeCredentials ? 'include' : 'omit',
			headers:     {
				...headers,
				...((config && config.headers) || {}),
			},
		};
	}
};

export const arenaApi = new ApiClient({
	host:               window.arenaParams?.forceWww ? ('www.' + window.arenaDomain) : window.arenaDomain,
	includeCredentials: false,
	prependArenaHost:   true,
});
export const authApi = new ApiClient({
	host:               window.arenaParams?.forceWww ? ('www.' + window.arenaDomain) : window.arenaDomain,
	includeCredentials: true,
	prependArenaHost:   true,
});
