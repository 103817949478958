import { WS_TIMEOUT, WS_FALLBACK_INTERVAL } from '@shared/js/websocket/constants';

import Echo from 'laravel-echo';
import SocketIoClient from 'socket.io-client';

const echoOptions = {
	broadcaster:        'socket.io',
	host:               window.echoHost,
	client:             SocketIoClient,
	rejectUnauthorized: false,
	upgrade:            false,
	transports:         ['websocket'],
};

// Channel + Event Subscription
class Subscription {
	channel = null;

	event = null;

	callback = null;

	echoChannel = null;

	constructor({ channel, event, callback }) {
		this.channel = channel;
		this.event = event;
		if (!callback) throw new Error('Cannot create subscription without a callback.');
		this.callback = (...args) => {
			callback(...args); // eslint-disable-line
		};
	}
}

// WS Client
class WebsocketClient {
	echo = null;
	maxReconnectionDelay = WS_TIMEOUT; // 30 seconds
	subscriptions = [];
	identifier = null;
	enableLogging = true;

	constructor(echoInstance) {
		this.echo = echoInstance;
		this.identifier = (Math.random() + 1).toString(36).substring(7);
		this.init();
	}

	log(...args) {
		this.enableLogging && console.log(`[SYNC-${this.identifier}]`, ...args);
	};

	init() {
		this.socket.io.reconnectionDelayMax(WS_TIMEOUT);
		this.socket.io.reconnectionDelay(WS_FALLBACK_INTERVAL);

		this.echo.connector.socket.on('reconnecting', (attemptNumber) => {
			this.log('Socket reconnecting:', { attemptNumber, delay: this.socket.io.reconnectionDelay() });

			// Delay by 5s per attempt, max 30 seconds
			this.socket.io.reconnectionDelay(Math.min(this.maxReconnectionDelay, attemptNumber * WS_FALLBACK_INTERVAL));
		});

		this.echo.connector.socket.on('connect_error', (err) => {
			this.log('Connection error.', { err });
		});

		this.echo.connector.socket.on('connect_failed', () => {
			this.log('Connection failed.');
		});

		this.echo.connector.socket.on('disconnect', () => {
			this.log('Socket disconnected.');
		});
	}

	get socket() {
		return this.echo.connector.socket;
	}

	get isConnected() {
		return this.socket.connected;
	}

	get isDisconnected() {
		return this.socket.disconnected;
	}

	subscribe({ channel, event, callback }) {
		this.log(`Subscribe on ${channel}:`, { channel, event, callback });
		const subscription = new Subscription({ channel, event, callback });
		subscription.echoChannel = this.echo.channel(channel).listen(event, callback);
		this.subscriptions.push(subscription);
		return subscription;
	}

	unsubscribe(subscription) {
		this.log(`Unsubscribe on ${subscription.channel}:`, { subscription });
		this.echo.leaveChannel(subscription.channel);
		this.subscriptions = this.subscriptions.filter(sub => sub !== subscription);
	}
}

// create WebsocketClient singleton
(() => {
	if (!window.echoHost) {
		console.warn('Unable to retrieve window.echoHost');
		return;
	}
	if (window.isMocked || window.wsClient) {
		return;
	}
	const echoInstance = !window.isTesting ? new Echo(echoOptions) : window.Echo;
	window.wsClient = new WebsocketClient(echoInstance);
})();

// DEBUG
window.ws = window.wsClient;

export default window.wsClient;
