<template>
	<Layout>
		<NavBack
			:link="backUrl"
			:title="$I18n.trans('commons.back')"
		/>
		<div class="js-keynav-section">
			<JumbotronCarousel
				v-if="slides.length > 0"
				:slides="slides"
			>
				<template #after>
					<HeaderSponsors
						v-if="headerSponsors"
						:sponsors="headerSponsors"
					/>
				</template>
			</JumbotronCarousel>
			<Jumbotron
				v-else-if="isSingleSport"
				:bg-image-url="bgImageUrl"
				:main-image-url="mainImageUrl"
				:title="title"
				:subtitle="subtitle"
				:special-announcement="false"
			>
				<template #after>
					<HeaderSponsors
						v-if="headerSponsors"
						:sponsors="headerSponsors"
					/>
				</template>
			</Jumbotron>
			<JumbotronTeam
				v-else
				:logo-url="mainImageUrl"
				:team-img-url="bgImageUrl"
				:title="teamName"
				:league-link="leagueLink"
				:league-name="leagueName"
				:category="category"
				:club-link="clubLink"
			>
				<template #after>
					<HeaderSponsors
						v-if="headerSponsors"
						:sponsors="headerSponsors"
					/>
				</template>
			</JumbotronTeam>
		</div>
		<div class="l-container">
			<div
				v-for="lane in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<SectionChildNav
					v-if="players.length && lane.lane_type === ArenaLaneType.NAVIGATION && lane.visible"
					type="athletes"
					:items="players"
					:is-single-sport="isSingleSport"
					:with-link="isSingleSport"
				/>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
				/>
				<AdLane
					v-if="lane.lane_type === ArenaLaneType.AD && lane.visible"
					:ad="lane.ad"
				/>
			</div>
			<EmptyState
				v-if="noEvents"
				:title="$I18n.trans_choice('commons.event', 2)"
				:text="$I18n.trans('arena.placeholder.replays')"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import SectionChildNav from '@js/components/SectionChildNav';
import EventLane from '@js/components/EventLane';
import AdLane from '@js/components/AdLane';
import EmptyState from '@js/components/EmptyState';
import Jumbotron from '@js/components/Jumbotron';
import JumbotronTeam from '@js/components/JumbotronTeam';
import JumbotronCarousel from '@js/components/JumbotronCarousel';
import NavBack from '@js/components/NavBack';
import HeaderSponsors from '@js/components/HeaderSponsors';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { AdType } from '@shared/enums/AdType';
import { laneMixer } from '@js/utils/laneMixer';

export default {
	name:       'ViewTeam',
	components: { Layout, SectionChildNav, EventLane, AdLane, Jumbotron, JumbotronTeam, JumbotronCarousel, HeaderSponsors, EmptyState, NavBack },
	props:      {
		bgImageUrl: {
			type:    String,
			default: null,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		subtitle: {
			type:    String,
			default: null,
		},
		slides: {
			type:    Array,
			default: () => [],
		},
		teamName: {
			type:    String,
			default: null,
		},
		players: {
			type:    Array,
			default: () => [],
		},
		albums: {
			type:    Array,
			default: () => [],
		},
		lanes: {
			type:    Array,
			default: () => [],
		},
		isSingleSport: {
			type:    Boolean,
			default: false,
		},
		leagueLink: {
			type:    String,
			default: null,
		},
		leagueName: {
			type:    String,
			default: null,
		},
		category: {
			type:    String,
			default: null,
		},
		clubLink: {
			type:    String,
			default: null,
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
		backUrl: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			ArenaLaneType,
		};
	},
	computed: {
		mixedLanes() {
			// On Team Context the default lanes aren't stored, so we add them here manually
			const defaultLaneTypes = [
				ArenaLaneType.NAVIGATION,
				ArenaLaneType.RECOMMENDATIONS,
				ArenaLaneType.LIVE_AND_UPCOMING,
				ArenaLaneType.REPLAYS,
				ArenaLaneType.VIDEOS,
			];
			const defaultLanes = [
				{
					lane_type:   ArenaLaneType.NAVIGATION,
					visible:     true,
					highlighted: false,
					order:       0,
				},
				{
					lane_type:   ArenaLaneType.RECOMMENDATIONS,
					visible:     true,
					highlighted: true,
					order:       1,
				},
				{
					lane_type:   ArenaLaneType.LIVE_AND_UPCOMING,
					visible:     true,
					highlighted: false,
					order:       2,
				},
				{
					lane_type:   ArenaLaneType.REPLAYS,
					visible:     true,
					highlighted: false,
					order:       3,
				},
				{
					lane_type:   ArenaLaneType.VIDEOS,
					visible:     true,
					highlighted: false,
					order:       4,
				},
			];

			// If a team has lanes stored, overwrite the default. Append albums at the end again
			let lanes = this.lanes.filter(lane => defaultLaneTypes.includes(lane.lane_type));
			defaultLanes.forEach(defaultLane => {
				if (!lanes.some(lane => lane.lane_type === defaultLane.lane_type)) {
					lanes.push(defaultLane);
				}
			});
			lanes = [
				...lanes.sort((a, b) => a.order - b.order),
				...this.lanes.filter(lane => !defaultLaneTypes.includes(lane.lane_type)),
			];

			return laneMixer(
				this.albums,
				lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
			);
		},
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
		headerSponsors() {
			return this.ads.find(ad => ad.type === AdType.SPONSORS_HEADER)?.logos;
		},
	},
};
</script>

<style>

</style>
