<template>
	<div class="imprint">
		<section
			v-if="$arenaConfig.imprint === 'sportpass'"
			class="s-html l-content-section -reduced-spacing u-mt-7"
		>
			<h1 class="imprint__title">
				Impressum
			</h1>
			<hr aria-hidden="true">

			<article>
				<h3>Medieninhaber</h3>
				<p>SportPass Medien GmbH</p>
			</article>

			<article>
				<h3>Sitz, Geschäftsanschrift</h3>
				<p>1010 Wien, Schenkenstraße 2</p>
			</article>

			<article>
				<h3>Telefon</h3>
				<p><a href="tel:+436649634263">+43 664 9634263</a></p>
			</article>

			<article>
				<h3>E-Mail</h3>
				<p><a href="mailto:office@sportpassaustria.at">office@sportpassaustria.at</a></p>
			</article>

			<article>
				<h3>Website</h3>
				<p>www.sportpassaustria.at</p>
			</article>

			<article>
				<h3>Firmenbuchnummer</h3>
				<p>552045g</p>
			</article>

			<article>
				<h3>Firmenbuchgericht</h3>
				<p>Handelsgericht Wien</p>
			</article>

			<article>
				<h3>UID-Nummer</h3>
				<p>ATU76738336</p>
			</article>

			<article>
				<h3>Geschäftsführer</h3>
				<p>
					Alexander Trauttmansdorff-Weinsberg<br>
					Rainer Rößlhuber
				</p>
			</article>

			<article>
				<h3>Unternehmensgegenstand</h3>
				<p>Betrieb eines Medienunternehmens, insbesondere im Bereich der Sportberichterstattung über eine digitale Multimediaplattform unter der Bezeichnung „SportPass Austria“, und Betriebsführung sowie Beteiligungserwerb und -verwaltung hinsichtlich von Unternehmen und Betrieben, die in diesen Bereichen tätig sind.</p>
			</article>

			<article>
				<h3>Kammer/Berufsverband</h3>
				<p>Wirtschaftskammer Wien</p>
			</article>

			<article>
				<h3>Anwendbare gewerbe- oder berufsrechtlichen Vorschriften</h3>
				<p>
					Gewerbeordnung: <a
						href="https://www.ris.bka.gv.at/"
						target="_blank"
						rel="noopener"
					>https://www.ris.bka.gv.at/</a>
				</p>
			</article>

			<article>
				<h3>Aufsichtsbehörden</h3>
				<p>Magistrat der Stadt Wien - Magistratisches Bezirksamt für den 1. Bezirk; Kommunikationsbehörde Austria</p>
			</article>

			<article>
				<p>
					Verbraucher haben die Möglichkeit, Beschwerden an die Online Streitbeilegungsplattform der EU zu richten: <a
						href="http://ec.europa.eu/odr"
						target="_blank"
						rel="noopener"
					>http://ec.europa.eu/odr</a>
				</p>
				<p>Sie können allfällige Beschwerde auch an die oben angegebene E-Mail-Adresse richten.</p>
			</article>
		</section>

		<section
			v-else-if="$arenaConfig.imprint === 'oeoc'"
			class="s-html l-content-section -reduced-spacing u-mt-7"
		>
			<h1 class="imprint__title">
				Impressum
			</h1>
			<hr aria-hidden="true">

			<article>
				<h3>Medieninhaber</h3>
				<p>Österreichisches Olympisches Comité</p>
			</article>

			<article>
				<h3>Rennweg 46-50 / Stiege 1 / Top 7</h3>
				<p>1030 Wien</p>
			</article>

			<article>
				<h3>Telefon</h3>
				<p><a href="tel:+4317995511">+43 (0) 1 799 55 11</a></p>
			</article>

			<article>
				<h3>Fax</h3>
				<p>+43 (0) 1 799 55 11-20</p>
			</article>

			<article>
				<h3>E-Mail</h3>
				<p><a href="mailto:office@olympia.at">office@olympia.at</a></p>
			</article>

			<article>
				<h3>ZVR</h3>
				<p>530776223</p>
			</article>

			<article>
				<h3>Technische Durchführung</h3>
				<p>SportPass Medien GmbH</p>
			</article>

			<article>
				<h3>Firmenbuchnummer</h3>
				<p>552045g</p>
			</article>

			<article>
				<h3>Firmenbuchgericht</h3>
				<p>Handelsgericht Wien</p>
			</article>

			<article>
				<h3>UID-Nummer</h3>
				<p>ATU76738336</p>
			</article>

			<article>
				<h3>Geschäftsführer</h3>
				<p>Mag. Rainer Rößlhuber</p>
			</article>
		</section>
		<div
			v-else
			class="s-html"
		>
			<h1 class="imprint__title">
				{{ $I18n.trans('legal.imprint.title') }}
			</h1>
			<hr aria-hidden="true">
			<!-- eslint-disable vue/no-v-html -->
			<div
				v-for="(section, i) in $I18n.trans(imprintSectionsTranslationKey)"
				:key="i"
			>
				<h2>{{ section.title }}</h2>
				<p v-html="section.content" />
			</div>
		</div>
		<br>
		<hr aria-hidden="true">
	</div>
</template>

<script>

export default {
	name:     'Imprint',
	computed: {
		imprintSectionsTranslationKey() {
			return this.$arenaConfig.imprint
				? `legal.imprint.${this.$arenaConfig.imprint}.sections`
				: 'legal.imprint.asport.sections';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.imprint {
	margin-top: $sp7;
	margin-bottom: $sp7;
}

.imprint__title {
	@include font(primary, light, normal, fs-180);
	margin-top: 0;
	margin-bottom: $sp5;
	letter-spacing: initial; // stylelint-disable-line property-disallowed-list

	@include size(lg-up) {
		margin-bottom: $sp6;
	}
}
</style>
