<template>
	<div class="sl-infos">
		<div
			v-if="isLoggedIn"
			class="sl-infos__subscribtion-info"
		>
			<template v-if="hasSubscription">
				{{ $I18n.trans('swiss_league_infos.subscription_info.active') }}
			</template>
			<template v-else>
				{{ $I18n.trans('swiss_league_infos.subscription_info.inactive_1', { email: profile.email }) }}
				<div class="sl-infos__subscribtion-info-sub">
					{{ $I18n.trans('swiss_league_infos.subscription_info.inactive_2') }}
				</div>
			</template>
		</div>
		<header
			class="sl-infos__header"
			:style="{backgroundImage: 'url(/images/swiss-league/header.jpg)'}"
		>
			<div
				v-if="secondsUntilStart > 0"
				class="sl-infos__header-text"
			>
				<SectionTitle
					:title="$I18n.trans('swiss_league_infos.countdown_title')"
					type="main"
				/>
				<Countdown
					class="u-hide@md"
					:seconds="secondsUntilStart"
					:show-days="true"
					size="sm"
				/>
				<Countdown
					class="u-show-as-flex@md"
					:seconds="secondsUntilStart"
					:show-days="true"
				/>
			</div>
			<div
				v-else
				class="sl-infos__header-text-post-live"
			>
				<div class="l-container">
					<SectionTitle
						v-if="secondsUntilStart <= 0 && isLoggedIn"
						:title="$I18n.trans('swiss_league_infos.section1.title1')"
						type="main"
					/>
				</div>
			</div>
		</header>
		<div
			v-if="!isLoggedIn"
			class="sl-infos__section -color-1"
		>
			<div class="l-container">
				<div class="l-row">
					<div class="l-col l-col--12 l-col--8@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('swiss_league_infos.section1.title1')"
								type="main"
							/>
							<SectionTitle
								v-if="secondsUntilStart > 0"
								:title="$I18n.trans('swiss_league_infos.section1.title2')"
								type="md"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans(`swiss_league_infos.section1.${ secondsUntilStart > 0 ? 'copy1' : 'copy1_post_start'}`)"
							/>
							<div class="sl-infos__login-button-w">
								<Button
									:text="$I18n.trans('swiss_league_infos.section1.login')"
									theme="dark"
									@click="showLoginForm()"
								/>
							</div>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('swiss_league_infos.section1.copy2', {onclick: 'window.eventBus.$emit(\'modal:ContactForm\');'})"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sl-infos__section -color-2">
			<div class="l-container">
				<div class="l-row l-flex-row-reverse@md">
					<div class="l-col l-col--12 l-col--5@md l-offset--1@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('swiss_league_infos.section2.title1')"
								type="md"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('swiss_league_infos.section2.copy1')"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md">
						<div class="sl-infos__section-inner-w -img">
							<img src="/images/swiss-league/info_1.png">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sl-infos__section -color-3">
			<div class="l-container">
				<div class="l-row">
					<div class="l-col l-col--12 l-col--5@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('swiss_league_infos.section3.title1')"
								type="md"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('swiss_league_infos.section3.copy1')"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md l-offset--1@md">
						<div class="sl-infos__section-inner-w -img">
							<div class="sl-infos__club-logos">
								<div
									v-for="(club, i) in $I18n.trans('swiss_league_infos.club_links')"
									:key="i"
									class="sl-infos__club-logo"
								>
									<a
										:href="club.link"
										target="_blank"
										rel="noopener"
									>
										<img
											:src="club.logo"
											:alt="club.link"
										>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sl-infos__section -color-1">
			<div class="l-container">
				<div class="l-row l-flex-row-reverse@md">
					<div class="l-col l-col--12 l-col--5@md l-offset--1@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('swiss_league_infos.section4.title1')"
								type="md"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('swiss_league_infos.section4.copy1')"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md">
						<div class="sl-infos__section-inner-w -img">
							<img src="/images/swiss-league/info_2.png">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sl-infos__section -color-2">
			<div class="l-container">
				<div class="l-row">
					<div class="l-col l-col--12 l-col--5@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('swiss_league_infos.section5.title1')"
								type="lg"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('swiss_league_infos.section5.copy1')"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md l-offset--1@md">
						<div class="sl-infos__section-inner-w -img">
							<img src="/images/swiss-league/info_3.png">
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- FAQ -->
		<div class="sl-infos__section -color-3">
			<div class="l-container">
				<div class="sl-infos__section-inner-w sl-infos__faq">
					<SectionTitle
						title="FAQ"
						type="lg"
						:vertical-spacing="false"
					/>
					<Accordion
						:items="faq"
						modifier="no-outlines"
					>
						<template #header="{item}">
							<SectionTitle
								class="sl-infos__faq-title"
								:title="item.title"
								type="md"
							/>
						</template>
						<template #body="{item}">
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm sl-infos__faq-content"
								v-html="item.content"
							/>
						</template>
					</Accordion>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import Accordion from '@shared/components/Accordion';
import Countdown from '@shared/components/Countdown';
import Button from '@shared/components/Button';
import { mapGetters } from 'vuex';

export default {
	name:       'Infos',
	components: { SectionTitle, Accordion, Countdown, Button },
	data() {
		return {
			faq: [

				{
					title:   $I18n.trans('swiss_league_infos.faq.1.title'),
					content: $I18n.trans('swiss_league_infos.faq.1.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.2.title'),
					content: $I18n.trans('swiss_league_infos.faq.2.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.3.title'),
					content: $I18n.trans('swiss_league_infos.faq.3.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.4.title'),
					content: $I18n.trans('swiss_league_infos.faq.4.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.5.title'),
					content: $I18n.trans('swiss_league_infos.faq.5.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.6.title'),
					content: $I18n.trans('swiss_league_infos.faq.6.content'),
				},
				{
					title:   $I18n.trans('swiss_league_infos.faq.7.title'),
					content: $I18n.trans('swiss_league_infos.faq.7.content'),
				},
			],
			secondsUntilStart: (1663349400000 - new Date().getTime()) / 1000,
		};
	},
	computed: {
		...mapGetters({
			profile:    'user/profile',
			isLoggedIn: 'user/isLoggedIn',
		}),
		hasSubscription() {
			const currentYear = new Date().getFullYear();
			const nextYear = currentYear + 1;

			return this.profile?.allResources?.subscriptions?.find(s => s.context === 'league' && s.status === 'confirmed' && s.name.de.endsWith(`${currentYear}/${nextYear}`));
		},
	},
	methods: {
		showLoginForm() {
			window.eventBus.$emit('modal:LoginModal');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.sl-infos {
	line-height: 140%;
	position: relative;
}

.sl-infos__header {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 377px;
	width: 100%;
	background-size: cover;
	background-position: center;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, var(--color-black) 100%);
		opacity: 0.8;

		@include size(md-up) {
			height: 85%;
		}
	}

	@include size(md-up) {
		height: 520px;
	}

	@supports (aspect-ratio: 4/3) {
		height: auto;
		aspect-ratio: 4/3;

		@include size(sm-up) {
			aspect-ratio: 16/9;
		}

		@include size(md-up) {
			height: auto;
			aspect-ratio: 16/7;
		}

		@include size(lg-up) {
			aspect-ratio: 36/13;
		}
	}
}

.sl-infos__header-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-left: $sp4;
	padding-right: $sp4;
	text-align: center;
}

.sl-infos__header-text-post-live {
	position: absolute;
	bottom: $sp6;

	@include size(sm-up) {
		bottom: $sp7;
	}

	@include size(md-up) {
		bottom: $sp9;
	}
}

.sl-infos__subscribtion-info {
	@include font(primary, medium, normal, fs-100);
	background-color: var(--color-grey-900);
	padding: $sp5 $sp5;

	@include size(md-up) {
		background-color: rgba(var(--color-rgb-grey-900), 0.8);
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		text-align: center;
		z-index: 1;
	}
}

.sl-infos__subscribtion-info-sub {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-200);
	margin-top: $sp2;
}

.sl-infos__section {
	&.-color-1 {
		background-color: var(--color-primary);
	}

	&.-color-2 {
		background-color: var(--color-grey-900);
	}

	&.-color-3 {
		background-color: var(--color-grey-800);
	}
}

.sl-infos__section-inner-w {
	padding-bottom: $sp6;

	&:not(.-img) {
		padding-top: $sp6;

		@include size(md-up) {
			padding-top: $sp8-5;
		}
	}

	@include size(md-up) {
		padding-top: $sp8-5;
		padding-bottom: $sp8-5;
	}
}

.sl-infos__login-button-w {
	margin-top: $sp5;
	margin-bottom: $sp5;
}

.sl-infos__club-logos {
	display: grid;
	grid-template-columns: minmax(40px, 80px) minmax(40px, 80px) minmax(40px, 80px) minmax(40px, 80px) minmax(40px, 80px);
	gap: $sp3;

	@include size(sm-up) {
		gap: $sp5;
	}
}

.sl-infos__club-logo {
	width: 100%;
}

// .sl-infos__faq {}

.sl-infos__faq-title {
	padding-top: $sp4;
	padding-right: $sp3;
	padding-bottom: $sp3;
	padding-left: $sp3;
}

.sl-infos__faq-content {
	padding-right: $sp3;
	padding-bottom: $sp5;
	padding-left: $sp3;
}
</style>
