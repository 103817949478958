<template>
	<VideoList
		:title="$I18n.trans('arena.video_center.title')"
		:enable-filter-sport="enableFilterSportComputed"
		:sports="sports"
		:enable-filter-date="enableFilterDateComputed"
		:content-period="contentPeriod"
		:context-filter="contextFilter"
		:show-results-count="true"
	/>
</template>

<script>
import VideoList from './VideoList';

export default {
	name:       'VideoCenter',
	components: { VideoList },
	props:      {
		contextFilter: {
			type:     Object,
			required: true,
		},
		sports: {
			type:    Array,
			default: () => [],
		},
		contentPeriod: {
			type:    Array,
			default: () => [],
		},
		enableFilterSport: {
			type:    Boolean,
			default: true,
		},
		enableFilterDate: {
			type:    Boolean,
			default: true,
		},
	},
	computed: {
		enableFilterSportComputed() {
			return this.enableFilterSport && (this.sports?.length > 1);
		},
		enableFilterDateComputed() {
			return this.enableFilterDate && (this.contentPeriod?.length > 1);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

</style>
