<template>
	<Modal
		ref="geoblockingModal"
		modal-name="GeoblockingModal"
		type="dialog"
		class="geoblocking__modal"
		theme="dark"
		:closeable="false"
	>
		<template #title>
			{{ $I18n.trans('event.geoblocked.title') }}
		</template>
		<template #content>
			<V2Icon
				class="geo-blocking__icon"
				icon-name="world-pin"
			/>
			<div
				class="geo-blocking__message"
				v-html="geoblockingMessage"
			/>
		</template>
		<template #footer>
			<div class="l-button-group">
				<Button
					v-if="computedBackUrl && !$Helpers.isSmartTV()"
					:text="$Helpers.ucfirst($I18n.trans('commons.back'))"
					theme="dark"
					modifier="secondary"
					@click="back()"
				/>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@shared/components/Modal';
import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';

export default {
	name:       'GeoblockingModal',
	components: { Modal, Button, V2Icon },
	props:      {},
	data() {
		return {
		};
	},
	computed: {
		computedBackUrl() {
			return this.backUrl || $Helpers.getBackUrl();
		},
		geoblockingMessage() {
			return window.$Helpers.getTranslatedContent(window.arenaConfig.geoblocking?.message) || $I18n.trans('event.geoblocked.message');
		},
	},
	methods: {
		back() {
			window.location.href = this.computedBackUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.geo-blocking__icon.v2-icon.-size-md {
	margin-bottom: $sp6;
	width: 64px;
	height: 64px;
}

.geo-blocking__message::v-deep a { // stylelint-disable-line
	text-decoration: underline;
}
</style>
