import moment from 'moment';

/**
 * @param {number} unixTimestamp - seseconds since the Epoch
 * @property {object}  _options
 * @property {boolean}  _options.withTime
 * @property {boolean}  _options.withSeconds
 * @returns {string} - DE: DD.MM.YY, HH:mm:ss / EN: YYYY-MM-DD, HH:mm:ss
 */
export const getDateTime = (unixTimestamp, _options = {}) => {
	const options = {
		withDate:          true,
		withTime:          true,
		withSeconds:       false,
		withWeekdayPrefix: false,
		weekdayFormatLong: false,
		monthFormatLong:   false,
		dateTimeSeparator: ', ',
		..._options,
	};
	if (typeof unixTimestamp !== 'number') {
		return null;
	}

	const weekdayFormat = options.weekdayFormatLong ? 'dddd, ' : 'dd. ';

	// We use the Swiss-French date format for FR
	if (
		moment.locale() === 'de' ||
		moment.locale() === 'fr' ||
		moment.locale() === 'it' ||
		moment.locale() === 'es' ||
		moment.locale() === 'ca'
	) {
		const appendix = options.withTime && !options.withSeconds && moment.locale() === 'de' ? ' Uhr' : '';
		const dayMonthYearFormat = options.monthFormatLong ? 'D. MMMM YYYY' : 'DD.MM.YYYY';
		const dateFormat = (options.withWeekdayPrefix ? weekdayFormat : '') +
			(options.withDate ? dayMonthYearFormat : '') +
			(options.withDate && options.withTime ? options.dateTimeSeparator : '') +
			(options.withTime ? 'HH:mm' : '') +
			(options.withTime && options.withSeconds ? ':ss' : '');

		const dateString = moment.unix(unixTimestamp).format(dateFormat) + appendix;
		return options.withWeekdayPrefix
			? $Helpers.ucfirst(dateString)
			: dateString;
	} else {
		const dayMonthYearFormat = options.monthFormatLong ? 'D MMMM YYYY' : 'YYYY-MM-DD';
		const dateFormat = (options.withWeekdayPrefix ? weekdayFormat : '') +
			(options.withDate ? dayMonthYearFormat : '') +
			(options.withDate && options.withTime ? options.dateTimeSeparator : '') +
			(options.withTime ? 'HH:mm' : '') +
			(options.withTime && options.withSeconds ? ':ss' : '');

		const dateString = moment.unix(unixTimestamp).format(dateFormat);
		return options.withWeekdayPrefix
			? $Helpers.ucfirst(dateString)
			: dateString;
	}
};

/**
 * @param {number} unixTimestamp - seseconds since the Epoch
 * @property {object}  _options
 * @property {number}  _options.untilHour - Returns relative time up to this hour
 * @property {boolean}  _options.useSince - Returns relative time in format "since 2 hours" instead of "2 hours ago" | will always be returned in minutes or hours
 * @property {boolean}  _options.useRemaining - Returns relative time in format "120 minutes left" instead of "In 2 hours" | will always be returned in minutes only
 * @property {number}  _options.unixNow - Provice custom now. Helpful to force updates in computed properties when updating now
 * @returns {string} - Vor 6 Tagen | Seit 2 Stunden | noch 2 Minuten | 22.04.2020 (if date is outside untilHour)
 */
export const getRelativeTime = (unixTimestamp, _options = {}) => {
	const options = {
		untilHour:             false,
		useSince:              false,
		useRemaining:          false,
		useRemainingHoursDays: false,
		useInHoursMinutes:     false,
		unixNow:               null,
		..._options,
	};

	if (typeof unixTimestamp !== 'number') {
		return null;
	}

	const now = options.unixNow ? moment.unix(options.unixNow) : moment(Date.now());
	const lookupTime = moment.unix(unixTimestamp);
	const diffInHours = now.diff(lookupTime, 'hours');
	const diffInMinutes = now.diff(lookupTime, 'minutes');
	const diffInMinutesExact = now.diff(lookupTime, 'minutes', true);

	// Only return relative value within untilHour
	if (options.untilHour && Math.abs(diffInHours) > options.untilHour) {
		return getDateTime(unixTimestamp, { withTime: false });
	}

	// Since 55 minutes / Since 2 hours
	if (options.useSince && diffInMinutes >= 0) {
		if (diffInMinutes < 60) {
			return `${$Helpers.ucfirst($I18n.trans('commons.since'))} ${diffInMinutes} ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.minute', diffInMinutes))}`;
		} else {
			return `${$Helpers.ucfirst($I18n.trans('commons.since'))} ${diffInHours} ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.hour', diffInHours))}`;
		}
	}

	// In 2 hours 20 minutes / In 20 minutes
	if (options.useInHoursMinutes) {
		const hours = Math.floor(Math.abs(diffInMinutes) / 60);
		const minutes = Math.abs(diffInMinutes) - (hours * 60);
		const minutesString = $Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.minute', minutes));
		const hoursString = $Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.hour', hours));

		if (diffInMinutes <= -60) {
			return `${$Helpers.ucfirst($I18n.trans('commons.in'))} ${hours} ${hoursString} ${minutes} ${minutesString}`;
		} else if (diffInMinutes <= 0) {
			return `${$Helpers.ucfirst($I18n.trans('commons.in'))} ${minutes} ${minutesString}`;
		} else {
			return `${$Helpers.ucfirst($I18n.trans('commons.in'))} 0 ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.minute', 2))}`;
		}
	}

	// 120 minutes left
	if (options.useRemaining) {
		const remainingTime = diffInMinutesExact >= 0 ? 0 : Math.ceil(Math.abs(diffInMinutesExact));
		if (moment.locale() === 'de' || moment.locale() === 'es' || moment.locale() === 'ca') {
			return `${$Helpers.ucfirst($I18n.trans('commons.left'))} ${remainingTime} ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.minute', remainingTime))}`;
		}
		return `${remainingTime} ${$Helpers.lcfirst($I18n.trans_choice('commons.minute', remainingTime))} ${$I18n.trans('commons.left')}`;
	}

	if (options.useRemainingHoursDays) {
		const remainingTime = diffInMinutesExact >= 0 ? 0 : Math.ceil(Math.abs(diffInMinutesExact));
		const remainingDays = Math.ceil(remainingTime / 1440);
		const remainingHours = Math.ceil(remainingTime / 60);
		if (moment.locale() === 'de' || moment.locale() === 'es' || moment.locale() === 'ca') {
			if (remainingHours >= 24) {
				return `${$Helpers.ucfirst($I18n.trans('commons.left'))} ${remainingDays} ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.day', remainingDays))}`;
			}
			return `${$Helpers.ucfirst($I18n.trans('commons.left'))} ${remainingHours} ${$Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.hour', remainingHours))}`;
		}
		if (remainingHours >= 24) {
			return `${remainingDays} ${$Helpers.lcfirst($I18n.trans_choice('commons.day', remainingDays))} ${$I18n.trans('commons.left')}`;
		}
		return `${remainingHours} ${$Helpers.lcfirst($I18n.trans_choice('commons.hour', remainingHours))} ${$I18n.trans('commons.left')}`;
	}

	return $Helpers.ucfirst(lookupTime.fromNow());
};

/**
 * @param {number} unixTimestamp - seseconds since the Epoch
 * @property {object}  _options
 * @property {number}  _options.unixNow - Provice custom now. Helpful to force updates in computed properties when updating now
 * @returns {string} - In 1 min / In 1 h 2 min / In 23 h 59 min / In 1 day / In 218 days
 */
export const getTimeUntil = (unixTimestamp, _options = {}) => {
	const options = {
		prependPrefix: true,
		unixNow:       null,
		..._options,
	};

	if (typeof unixTimestamp !== 'number') {
		return null;
	}

	const prefix = (() => {
		switch (window.location) {
		case 'fr':
			return 'En';
		default:
			return 'In'; // same for EN, DE and IT
		}
	})();
	const now = options.unixNow ? moment.unix(options.unixNow) : moment(Date.now());
	const lookupTime = moment.unix(unixTimestamp);
	const diffInHours = Math.abs(now.diff(lookupTime, 'hours'));
	const diffInMinutes = Math.abs(now.diff(lookupTime, 'minutes'));
	const hours = Math.floor(Math.abs(diffInMinutes) / 60);
	const minutes = Math.abs(diffInMinutes) - (hours * 60);

	if (lookupTime <= now) {
		return null;
	}

	if (diffInHours >= 24) {
		return $Helpers.ucfirst(lookupTime.fromNow(!options.prependPrefix));
	} else if (diffInMinutes >= 60) {
		return `${options.prependPrefix ? `${prefix} ` : ''}${hours} h ${minutes} min`;
	} else {
		return `${options.prependPrefix ? `${prefix} ` : ''}${diffInMinutes} min`;
	}
};

/**
 * @param {*} seconds
 * @returns {string} - H:mm:ss -> 12:00:30 | mm:ss -> 00:30 | mm:ss -> 00:30 minutes
 */
export const getDuration = (seconds, _options = {}) => {
	const options = {
		noNegativeValue:  false,
		includeZeroHours: false,
		appendUnit:       false,
		withSeconds:      true,
		..._options,
	};

	if (typeof seconds !== 'number') {
		return null;
	}

	const hoursFormat = options.includeZeroHours
		? 'HH:'
		: (Math.abs(seconds)) >= 3600 ? 'H:' : '';

	const appendix = options.appendUnit
		? ` ${(Math.abs(seconds)) >= 3600
			? $Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.hour', 2))
			: $Helpers[moment.locale() === 'de' ? 'ucfirst' : 'lcfirst']($I18n.trans_choice('commons.minute', 2))
		}`
		: '';

	return moment.duration({ seconds: options.noNegativeValue ? Math.abs(seconds) : seconds }).format(`${hoursFormat}mm${options.withSeconds ? ':ss' : ''}`, {
		trim: false,
	}) + appendix;
};

/**
 * Converts start and end from seconds of the week (0 = monday, 00:00:01) to human readable string
 * i.e Dienstag 10:00 - 14:00
 *
 * @param {*} start
 * @param {*} end
 * @returns
 */
export const weekMinutesToInterval = (start, end) => {
	const startTime = moment().startOf('week').add(start, 'minutes');
	const endTime = moment().startOf('week').add(end, 'minutes');
	const startFormat = 'dddd HH:mm';
	const endFormat = startTime.isSame(endTime, 'day') ? 'HH:mm' : 'dddd HH:mm';

	return `${startTime.format(startFormat)} - ${endTime.format(endFormat)}`;
};

/** @param {Number} timestamp (seconds) */
export const formatTimestampAsMinutesSeconds = (timestamp) => {
	const roundedTimestamp = Math.round(timestamp);
	const minutes = Math.abs(Math.floor(roundedTimestamp / 60));
	const seconds = Math.abs(roundedTimestamp % 60);

	return `${timestamp < 0 ? '-' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};
