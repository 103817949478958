<template>
	<a
		:href="arenaUrl"
		:target="isExternalLink ? '_blank' : false"
		class="l-carousel-wrapper-team card-team"
	>
		<div class="card-team__inner">
			<div class="card-team__logo-w">
				<img
					class="card-team__logo"
					:src="imageUrl"
				>
			</div>
			<div class="card-team__title-wrapper">
				<h4
					class="card-team__title"
					v-text="item.club.name"
				/>
				<V2Icon
					v-if="showExternalLinkIcon"
					class="card-team__icon"
					icon-name="launch"
					size="sm"
				/>
			</div>
		</div>
	</a>
</template>

<script>
import laroute from '@laroute';
import TeamCardMixin from './TeamCardMixin';

/**
 * INFO: This renders the teamCard only with the Club name which
 * works on the league page but wouldn't work on the club page because all
 * teams would be named the same. So this component should only be used on the
 * league page.
 */

export default {
	name:   'TeamCard',
	mixins: [TeamCardMixin],
	props:  {
		item: {
			type:     Object,
			required: true,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
		imageSize: {
			type:     Number,
			required: false,
			default:  null,
		},
	},
	data() {
		return {
			laroute,
		};
	},
	computed: {
		imageUrl: function() {
			return this.$Helpers && this.imageSize && this.item.imageUrl && !this.item.imageUrl.includes('logo_placeholder')
				? this.$Helpers.getScaledImageUrl(this.item.imageUrl, this.imageSize)
				: this.item.imageUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-team {
	display: inline-block;
}

.card-team__inner {
	text-align: center;
	background-color: var(--color-grey-900);
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	padding: 0 $sp3 $sp4;
	border: 1px solid var(--color-grey-800);
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;
	cursor: pointer;

	.is-keynav-section-active .is-selected & {
		transform: scale(1.09);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include box-shadow(1);
		background-color: var(--color-white);
		border: none;
	}

	&:focus,
	&:hover {
		transform: scale(1.09);
	}
}

.card-team__title {
	@include font-size(fs-80);

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		@include font(primary, bold, normal, fs-80);
		color: var(--color-black);
	}
}

.card-team__logo-w {
	height: 92px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-team__logo {
	width: 56px;
}

.card-team__title-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.card-team__icon {
	margin-left: 6px;
}
</style>
