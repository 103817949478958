let icsFile = null;

const getIcsDate = (date) => {
	return new Date(date).toISOString().replaceAll('-', '').replaceAll(':', '').split('.')[0] + 'Z';
};

export const getIcsFile = (date, summary = '', link = '', _description = '', location = '') => {
	const description = link || _description;
	const IcsString =
	'BEGIN:VCALENDAR\n' +
	'VERSION:2.0\n' +
	'BEGIN:VEVENT\n' +
	'CLASS:PUBLIC\n' +
	'DESCRIPTION:' + description + '\n' +
	'LOCATION:' + location + '\n' +
	'SUMMARY:' + summary + '\n' +
	'TRANSP:TRANSPARENT\n' +
	'DTSTART:' + getIcsDate(date.start) + '\n' +
	'DTEND:' + getIcsDate(date.end) + '\n' +
	'END:VEVENT\n' +
	'END:VCALENDAR\n' +
	'PRODID:Asport\n';

	const data = new File([IcsString], { type: 'text/plain' });

	// If we are replacing a previously generated file we need to
	// manually revoke the object URL to avoid memory leaks.
	if (icsFile !== null) {
		window.URL.revokeObjectURL(icsFile);
	}

	icsFile = window.URL.createObjectURL(data);

	return icsFile;
};
