<template>
	<Layout>
		<div class="l-container">
			<Sports :sports="sports" />
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Sports from '@js/components/sportpass/Sports';

export default {
	name:       'ViewSports',
	components: { Layout, Sports },
	props:      {
		sports: {
			type:    Array,
			default: () => [],
		},
	},
};
</script>

<style>

</style>
