<template>
	<div class="l-content-section">
		<SectionTitle
			v-if="title"
			:title="title"
		/>
		<div class="empty-state">
			<Icon
				icon-name="cctv"
				size="xxl"
			/>
			<span class="empty-state__text">{{ text }}</span>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import Icon from '@shared/components/Icon';

export default {
	name:       'EmptyState',
	components: { SectionTitle, Icon },
	props:      {
		title: {
			type:    String,
			default: null,
		},
		text: {
			type:     String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.empty-state {
	@include font(primary, bold, normal, fs-120);
	display: flex;
	align-items: center;
	height: 96px;
	background-color: var(--color-grey-900);
	border: 1px solid var(--color-grey-800);
	border-radius: $border-radius;
	margin-bottom: $sp5;
	padding-left: $sp5;
	padding-right: $sp5;

	.v2-icon {
		transform: scaleX(-1); // Christmas gift for philipp {´◕ ◡ ◕｀}
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		background-color: var(--color-white);
		color: var(--color-grey-500);
	}
}

.empty-state__text {
	margin-left: $sp4;
	font-weight: 500; // stylelint-disable-line property-disallowed-list
}

</style>
