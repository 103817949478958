<template>
	<div class="profile">
		<SectionTitle
			:title="$I18n.trans('commons.type_details', {type: $I18n.trans_choice('commons.account', 1)})"
			:theme="$arenaConfig.isLightTheme ? 'light' : 'dark'"
			type="md"
		/>
		<div class="profile__input-w">
			<ProfileForm
				ref="profileForm"
				@isProcessing="isProcessing = $event"
				@canSubmit="canSubmit = $event"
			/>
		</div>
		<div class="profile__submit-w">
			<Button
				:text="$Helpers.ucfirst($I18n.trans('commons.save'))"
				:busy="isProcessing"
				:disabled="!canSubmit"
				theme="dark"
				@click="handleSubmit"
			/>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import ProfileForm from '@js/components/ProfileForm';
import Button from '@shared/components/Button';

export default {
	name:       'Profile',
	components: { ProfileForm, Button, SectionTitle },
	data() {
		return {
			isProcessing: false,
			canSubmit:    false,
		};
	},
	methods: {
		handleSubmit() {
			this.$refs.profileForm.submit();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.profile__input-w {
	margin-bottom: $sp6;
}

.profile__submit-w {
	display: flex;
	justify-content: flex-end;
}
</style>
