export default class Helpers {
	/**
	 * Transform first letter of string to uppercase
	 *
	 * @param   {string} string
	 * @returns {string}
	 */
	ucfirst(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	/**
	 * Transform first letter of string to lowercase
	 *
	 * @param   {string} string
	 * @returns {string}
	 */
	 lcfirst(string) {
		return string.charAt(0).toLowerCase() + string.slice(1);
	}

	/**
	 * Get the resized image url, if filesystem driver is set to remote url
	 * Allowed Heights: 50, 80, 90, 100, 180, 360, 1080
	 *
	 * @param {string} originalUrl
	 * @param {int} height
	 *
	 * @returns {string}
	 */
	getScaledImageUrl(originalUrl, height, config = {}) {
		if (originalUrl?.includes('logo_placeholder')) {
			return originalUrl;
		}
		if (originalUrl && !originalUrl.match(/^s*data:image/) && originalUrl.length > 0 && window.enableImageResizing) {
			const fileName = originalUrl.substring(originalUrl.lastIndexOf('/') + 1);
			const absoluteUrl = originalUrl.substring(0, originalUrl.lastIndexOf('/') + 1);
			const sizePath = config.heightIsWidth ? height + 'xAUTO' + '/' : 'AUTOx' + height + '/';

			return absoluteUrl + sizePath + fileName;
		}
		if (!originalUrl || originalUrl.length === 0) {
			originalUrl = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNgYAAAAAMAASsJTYQAAAAASUVORK5CYII='; // 1x1px transparent png
		}
		return originalUrl;
	}

	/**
	 * Observes a SDX Modal and executes the callback function when the modal opens or closes
	 *
	 * @param {DOMNode} target
	 * @param {function} callback
	 */
	onModalChangeVisibility(target, callback) {
		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				if (mutation.attributeName === 'class') {
					const isOpen = target.classList.contains('modal--open');
					callback(isOpen);
				}
			});
		});
		observer.observe(target, { attributes: true });
	}

	/**
	 * Create a slug from string
	 *
	 * @param {string} string
	 *
	 * @returns {string}
	 */
	 slugify(string) {
		if (!string) {
			return 'unknown';
		}

		const slug = string
			.toString() // Cast to string
			.toLowerCase() // Convert the string to lowercase letters
			.normalize('NFD') // The normalize() method returns the Unicode Normalization Form of a given string.
			.trim() // Remove whitespace from both sides of a string
			.replace(/\s+/g, '-') // Replace spaces with -
			.replace(/[^\w\-]+/g, '') // Remove all non-word chars
			.replace(/\-\-+/g, '-'); // Replace multiple - with single -

		return slug || 'unknown';
	}

	/**
	 *
	 * @param {DOMNode} target
	 *
	 * @returns {boolean}
	 */
	isElementInViewport(target) {
		const rect = target.getBoundingClientRect();
		return (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	}

	/*
	* @returns {boolean}
	*/
	isSmartTV() {
		return window.isSmartTv || window.location.hash === '#tv';
	}

	/*
	* @returns {boolean}
	*/
	isBlueTv() {
		const containsTvBox = navigator.userAgent.search(/TVBox/i) > -1;
		const containsIP1xxx = navigator.userAgent.search(/IP1\d\d\d/i) > -1;
		const containsIP2xxx = navigator.userAgent.search(/IP2\d\d\d/i) > -1;
		return containsTvBox || containsIP1xxx || containsIP2xxx;
	}

	/**
	 * @returns {string}
	 */
	getUrlWithoutParameters() {
		return location.protocol + '//' + location.host + location.pathname;
	}

	/**
	 *
	 * @param {string} route
	 * @returns string
	 */
	getManagerUrl(route) {
		return window.location.protocol + '//' + window.managerDomain + route;
	}

	/**
	 * Get translation from Object
	 *
	 * @param string translations
	 * @param string lang
	 *
	 * @returns string
	 *
	 */
	getTranslatedContent(translations) {
		const lang = this.getLocale();
		const fallbackLang = window.arenaConfig?.languages?.[0] || 'de';

		if (!translations || typeof translations !== 'object' || Object.keys(translations).length === 0) {
			return '';
		}
		// return current language if available
		if (translations[lang]) {
			return translations[lang];
		}
		// return fallback language if available
		if (translations[fallbackLang]) {
			return translations[fallbackLang];
		}
		// return first available language
		for (const key in translations) {
			console.log(translations[key]);
			if (translations[key]) {
				return translations[key];
			}
		}

		return '';
	};

	/**
	 *
	 * @param {string} route
	 * @returns string
	 */
	getLocale() {
		return window.locale;
	}

	getBackUrl() {
		// If the referrer is on the arena domain, use it as back url. In other cases, use the arena homepage.
		const referrer = document.referrer && document.referrer !== document.location.href && (new URL(document.referrer).hostname.replace('www.', '') === window.arenaDomain) ? document.referrer : null;

		return referrer || window.arenaHomepage || null;
	}
}
