import storage from '@shared/js/storage';

class Affil {
	init() {
		const params = new URLSearchParams(window.location.search);
		if (params.has('afsrc')) {
			try {
				storage.affilId = params.get('afsrc');
			} catch (e) {
				console.error('Unable to set affilId: ' + e);
			}
		}
	}
}

export const initAffil = function() {
	const affil = new Affil();
	affil.init();
};
