<template>
	<div v-if="mappedInfos.length">
		<div
			v-for="(info, i) in mappedInfos"
			:key="i"
			class="arena-info"
		>
			<div class="l-container">
				<div class="arena-info__inner">
					<V2Icon
						icon-name="c-info"
						size="lg"
					/>
					<span class="arena-info__text">
						{{ info }}
					</span>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

export default {
	name:       'ArenaInfo',
	components: { V2Icon },
	props:      {
		infos: {
			type:    Array,
			default: () => [],
		},
		currentLanguageKey: {
			type:     String,
			required: true,
		},
	},
	computed: {
		mappedInfos() {
			return this.infos.map(info => {
				return info.info[this.currentLanguageKey];
			}).filter(info => !!info);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.arena-info {
	text-align: center;
	padding: $sp4 $sp2;
	background-color: var(--color-red);
	display: flex;
	align-items: center;
	text-align: left;
	color: var(--color-white);

	body.is-player-mode & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		display: none;
	}
}

.arena-info__inner {
	display: flex;
	align-items: center;
	text-align: left;
}

.arena-info__text {
	position: relative;
	padding-left: $sp3;
	margin-left: $sp3;
}

</style>
