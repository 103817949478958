<template>
	<Layout>
		<div class="l-container">
			<div class="arena-message">
				<Icon
					icon-name="sad"
					size="xxxl"
					class="arena-message__icon"
				/>
				<SectionTitle :title="title" />
				<p
					v-if="error"
					class="message"
				>
					{{ $I18n.trans('arena.login.errors.' + error) }}
				</p>
				<p
					v-else
					class="message"
				>
					{{ message }}
				</p>
				<Button
					:text="buttonText"
					:link="buttonLink"
					theme="dark"
					class="arena-message__button"
				/>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Icon from '@shared/components/Icon';
import Button from '@shared/components/Button';
import SectionTitle from '@js/components/SectionTitle';

export default {
	name:       'ViewLoginError',
	components: { Layout, Icon, Button, SectionTitle },
	props:      {
		message: {
			type:     String,
			required: true,
		},
		error: {
			type:     String,
			required: false,
			default:  null,
		},
		buttonText: {
			type:     String,
			required: true,
		},
		buttonLink: {
			type:     String,
			required: true,
		},
		title: {
			type:     String,
			required: true,
		},
	},
};
</script>

<style>
	.message {
		margin-bottom: 15px;
	}
</style>
