<template>
	<div>
		<JumbotronCarousel
			v-if="slides.length > 0 && (events.length === 0 || enableLiveheader !== true)"
			:slides="slides"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</JumbotronCarousel>
		<Jumbotron
			v-else-if="events.length === 0 || enableLiveheader !== true"
			:bg-image-url="bgImageUrl"
			:main-image-url="mainImageUrl"
			:title="title"
			:subtitle="subtitle"
			:special-announcement="false"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</Jumbotron>
		<div
			v-else
			class="l-content-section"
		>
			<div
				class="matchday"
				:class="{'is-single-event': events.length === 1}"
			>
				<div
					v-for="(event, index) in events"
					:key="'bg' + event.id"
					class="matchday__bg"
					:class="{'is-active': index === focusEventIndex}"
					@click="() => linkToPlayer(event)"
				>
					<img
						:src="event.imageUrl || bgImageUrl"
						class="matchday__bg-img"
						alt="Event image"
					>
				</div>
				<div class="matchday__focus-w">
					<div
						v-for="(event, index) in events"
						:key="event.id"
						class="matchday__focus"
						:class="{'is-active': index === focusEventIndex}"
						@click="() => linkToPlayer(event)"
					>
						<div class="matchday__play">
							<Icon
								icon-name="button-play-filled"
								size="lg"
							/>
						</div>
						<BadgeLive
							class="matchday__live-badge"
							:always-show-text="true"
						/>
						<div
							class="matchday__scores -large"
							:style="{'grid-template-columns': `auto 1fr repeat(${getNumberOfPeriods(event) + 1}, auto)`}"
						>
							<div class="matchday__scores-logo matchday__scores-item">
								<img
									:src="$Helpers.getScaledImageUrl(getMatchScores(event).homeImgUrl, 100)"
									:alt="getMatchScores(event).homeName"
								>
							</div>
							<div class="matchday__scores-name matchday__scores-item">
								{{ getMatchScores(event).homeName }}
							</div>
							<div class="matchday__scores-score matchday__scores-item">
								{{ noSpoilerMode ? '-' : (getMatchScores(event).homeScore || '-') }}
							</div>
							<!-- The loser of the set is low colored, except the last set (the ongoing one) -->
							<div
								v-for="(period, i) in getMatchScores(event).scores"
								:key="`setshome-${period.id}`"
								class="matchday__scores-set matchday__scores-item"
								:class="{
									'-loser': ((period.teamHomeScoreInPeriod < period.teamGuestScoreInPeriod) && (i < getNumberOfPeriods(event)-1)),
								}"
							>
								{{ noSpoilerMode ? '-' : (period.teamHomeScoreInPeriod || '-') }}
							</div>
							<div class="matchday__scores-logo matchday__scores-item">
								<img
									:src="$Helpers.getScaledImageUrl(getMatchScores(event).guestImgUrl, 100)"
									:alt="getMatchScores(event).guestName"
								>
							</div>
							<div class="matchday__scores-name matchday__scores-item">
								{{ getMatchScores(event).guestName }}
							</div>
							<div class="matchday__scores-score matchday__scores-item">
								{{ noSpoilerMode ? '-' : (getMatchScores(event).guestScore || '-') }}
							</div>

							<div
								v-for="(period, i) in getMatchScores(event).scores"
								:key="`setsguest-${period.id}`"
								class="matchday__scores-set matchday__scores-item"
								:class="{ '-loser': ((period.teamHomeScoreInPeriod > period.teamGuestScoreInPeriod) && (i < getNumberOfPeriods(event)-1)) }"
							>
								{{ noSpoilerMode ? '-' : (period.teamGuestScoreInPeriod || '-') }}
							</div>
						</div>
						<div
							class="matchday__event-infos"
							:class="{ '-with-sets': getNumberOfPeriods(event) > 0}"
						>
							<div class="matchday__event-infos-text -main">
								{{ getRemainingTime(event) }}
							</div>
							<div class="matchday__event-infos-text">
								{{ getLeagueName(event) }}
							</div>
							<div class="matchday__event-infos-text -hide-sm">
								{{ getLocation(event) }}
							</div>
						</div>
					</div>
				</div>
				<div
					v-if="events.length > 1"
					class="matchday__arrow-nav -prev"
					@click="() => changeFocus(focusEventIndex - 1)"
				>
					<Icon
						icon-name="left-arrow"
						size="lg"
						class="matchday__arrow-nav-icon"
					/>
					<div class="matchday__arrow-nav-toucharea" />
				</div>
				<div
					v-if="events.length > 1"
					class="matchday__arrow-nav -next"
					@click="() => changeFocus(focusEventIndex + 1)"
				>
					<Icon
						icon-name="right-arrow"
						size="lg"
						class="matchday__arrow-nav-icon"
					/>
					<div class="matchday__arrow-nav-toucharea" />
				</div>
				<div
					v-if="events.length > 1"
					class="matchday__nav"
				>
					<div
						v-for="(event, index) in events"
						:key="event.id"
						class="matchday__nav-item"
						:class="{'is-active': index === focusEventIndex}"
						@click="() => changeFocus(index)"
					>
						<div class="matchday__nav-item-inner-w">
							<div class="matchday__nav-progress">
								<span
									v-if="index === focusEventIndex"
									class="matchday__nav-progress-meter"
									:style="{width: `${100 - timeInPct}%`}"
								/>
							</div>
							<div class="matchday__scores">
								<div class="matchday__scores-logo matchday__scores-item">
									<img
										:src="$Helpers.getScaledImageUrl(getMatchScores(event).homeImgUrl, 50)"
										:alt="getMatchScores(event).homeName"
									>
								</div>
								<div class="matchday__scores-name matchday__scores-item">
									{{ getMatchScores(event).homeName }}
								</div>
								<div class="matchday__scores-score matchday__scores-item">
									{{ noSpoilerMode ? '-' : (getMatchScores(event).homeScore || '-') }}
								</div>
								<div class="matchday__scores-logo matchday__scores-item">
									<img
										:src="$Helpers.getScaledImageUrl(getMatchScores(event).guestImgUrl, 50)"
										:alt="getMatchScores(event).guestName"
									>
								</div>
								<div class="matchday__scores-name matchday__scores-item">
									{{ getMatchScores(event).guestName }}
								</div>
								<div class="matchday__scores-score matchday__scores-item">
									{{ noSpoilerMode ? '-' : (getMatchScores(event).guestScore || '-') }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<HeaderSponsors
				v-if="headerSponsors"
				:sponsors="headerSponsors"
			/>
		</div>
	</div>
</template>

<script>
/**
 * Design is tested for a maximum of 5 concurrent events
 */

import laroute from '@laroute';
import Jumbotron from './Jumbotron';
import BadgeLive from '@shared/components/BadgeLive';
import Icon from '@shared/components/Icon';
import JumbotronCarousel from '@js/components/JumbotronCarousel';
import HeaderSponsors from '@js/components/HeaderSponsors';
import { getMatchScores, getLeagueName, getLocation } from '@shared/utils/eventUtils';
import { getRelativeTime } from '@shared/utils/timeUtils';
import { mapState } from 'vuex';

const slideDuration = 5000;

export default {
	name:       'Matchday',
	components: { Jumbotron, JumbotronCarousel, HeaderSponsors, BadgeLive, Icon },
	props:      {
		bgImageUrl: {
			type:     String,
			required: true,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:     String,
			required: true,
		},
		subtitle: {
			type:    String,
			default: null,
		},
		slides: {
			type:    Array,
			default: () => [],
		},
		enableLiveheader: {
			type:    Boolean,
			default: false,
		},
		headerSponsors: {
			type:    Array,
			default: null,
		},
	},
	data() {
		return {
			focusEventIndex: 0,
			timer:           slideDuration,
			unixNow:         Math.round(new Date().getTime() / 1000),
			getMatchScores,
		};
	},
	computed: {
		...mapState({
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
		events() {
			// We only want to display cup and league matches
			return this.$store.state.events.live.items
				.filter(e => e.participants.length === 2)
				.filter(e => e.categoryParent === 'match' && (e.categoryChild === 'league' || e.categoryChild === 'cup'));
		},
		timeInPct() {
			return (this.timer / slideDuration) * 100;
		},
	},
	watch: {
		events(newEvents) {
			if (newEvents.length <= 1) {
				clearInterval(this.triggerNavigation, 100);
			}
			this.changeFocus(0);
		},
	},
	mounted() {
		this.startNavIndicatorTimer();
		setInterval(this.setUnixNow, 1000);
	},
	methods: {
		setUnixNow() {
			this.unixNow = Math.round(new Date().getTime() / 1000);
		},
		changeFocus(index = undefined) {
			this.resetTimer();

			if (index !== undefined) {
				if (index > this.events.length - 1) {
					this.focusEventIndex = 0;
				} else if (index < 0) {
					this.focusEventIndex = this.events.length - 1;
				} else {
					this.focusEventIndex = index;
				}
			} else {
				this.focusEventIndex === this.events.length - 1
					? this.focusEventIndex = 0
					: this.focusEventIndex++;
			}
		},
		startNavIndicatorTimer() {
			setInterval(this.triggerNavigation, 100);
		},
		resetTimer() {
			this.timer = slideDuration;
		},
		triggerNavigation() {
			if (this.timer <= 0) {
				this.changeFocus();
			}
			this.timer = this.timer - 100;
		},
		linkToPlayer(event) {
			window.location.href = laroute.route('arena.event', {
				event: event.id,
				slug:  this.$Helpers.slugify(event.nameTranslations?.[this.$arenaConfig.defaultLanguage] || event.name),
			});
		},
		getRemainingTime(event) {
			return getRelativeTime(event.endTimestamp, { useRemaining: true, unixNow: this.unixNow });
		},
		getLeagueName(event) {
			return getLeagueName(event);
		},
		getLocation(event) {
			return getLocation(event);
		},
		getNumberOfPeriods(event) {
			return getMatchScores(event).scores?.length || 0;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

$transition-duration: 0.25s;

.matchday {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid var(--color-grey-800);
	height: 377px;

	&:not(.is-single-event) {
		@include size(md-up) {
			padding-bottom: 5vw;
		}
	}

	@include size(md-up) {
		height: 420px;
	}

	@supports (aspect-ratio: 4/3) {
		height: auto;
		aspect-ratio: 16/7;

		@include size(lg-up) {
			aspect-ratio: 36/13;
		}
	}
}

.matchday__bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: cover;
	background-position: center;
	opacity: 0;
	transition: opacity ease-in-out $transition-duration;
	will-change: opacity;
	overflow: hidden;
	pointer-events: none;

	&.is-active {
		opacity: 1;
		pointer-events: initial;
		cursor: pointer;
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
	}
}

.matchday__bg-img {
	object-fit: cover;
	width: calc(100% + 20px);
	height: calc(100% + 20px);
	filter: blur(10px);
	margin: -10px;
	max-width: none;
}

.matchday__live-badge {
	position: absolute;
	top: -40px !important; // stylelint-disable-line declaration-no-important

	@include size(sm-down) {
		top: -25px !important; // stylelint-disable-line declaration-no-important
	}

	@include size(xs-only) {
		top: -20px !important; // stylelint-disable-line declaration-no-important
		left: -4px;
		transform: scale(0.8);
	}
}

.matchday__focus-w {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: $sp6;
	margin-bottom: $sp6;
	width: 100%;
}

.matchday__focus {
	position: absolute;
	display: flex;
	align-items: center;
	opacity: 0;
	transform: scale(0.9);
	transition: opacity ease-in-out $transition-duration, transform ease-in-out $transition-duration;
	will-change: transform, opacity;
	pointer-events: none;

	&.is-active {
		transition-delay: $transition-duration;
		opacity: 1;
		transform: scale(1);
		pointer-events: initial;
		cursor: pointer;
	}

	@include size(sm-down) {
		flex-direction: column-reverse;
		align-items: flex-start;
		margin: 0;
	}
}

.matchday__play {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(var(--color-rgb-black), 0.8);
	border-radius: 50%;
	width: $sp8;
	height: $sp8;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 0;
	z-index: 1;
	transition: opacity $trans-time-fast;

	.matchday__focus:hover & {
		@media screen and (pointer: fine) {
			opacity: 1;
		}
	}
}

.matchday__event-infos {
	position: relative;
	padding-bottom: $sp3;

	&.-with-sets {
		padding-bottom: $sp4;
	}

	@include size(md-up) {
		margin-left: $sp8;
		padding-left: $sp8;
		padding-top: $sp5;
		padding-bottom: $sp5;

		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 1px;
			height: 100%;
			background-color: var(--color-grey-500);
		}

		&.-with-sets {
			padding-bottom: $sp5;
		}
	}

}

.matchday__event-infos-text {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-300);
	margin-bottom: $sp1;

	&.-main {
		color: var(--color-grey-050);
	}

	&.-hide-sm {
		@include size(sm-down) {
			display: none;
		}
	}

	@include size(sm-down) {
		@include font(primary, book, normal, fs-60);
		display: inline-block;

		&.-main {
			&::after {
				content: '•';
			}
		}
	}

}

////////////////////////////////
// NAVIGATION
////////////////////////////////

.matchday__arrow-nav {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: $sp5;
	height: $sp5;
	border-radius: 50%;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-grey-850);
	transition: background-color $trans-time-fast, color $trans-time-fast;

	&.-prev {
		left: $sp3;
	}

	&.-next {
		right: $sp3;
	}

	&:hover {
		background-color: var(--color-hype-yellow);
		color: var(--color-shadow-grey);
	}

	@include size(sm-up) {
		width: $sp7;
		height: $sp7;
	}

	@include size(md-up) {
		&.-prev {
			left: $sp5;
		}

		&.-next {
			right: $sp5;
		}
	}

}

.matchday__nav {
	position: absolute;
	bottom: 5vw;
	display: none;

	@include size(md-up) {
		display: flex;
		max-width: 100vw;
		gap: $sp5;
	}

	@include size(lg-up) {
		gap: $sp7;
	}
}

.matchday__nav-item {
	width: 160px;

	@include size(lg-up) {
		width: 197px;
	}
}

// extra element needed to fix overflow scroll
.matchday__nav-item-inner-w {
	position: relative;
	display: flex;
	padding: $sp3-5;
	cursor: pointer;
	background-color: var(--color-grey-900);
	border-radius: $border-radius--sm;
}

.matchday__nav-progress {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 2px;
	border-radius: 1px;
}

.matchday__nav-progress-meter {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	background-color: var(--color-grey-050);
	transition: width 60ms linear 0ms;
}

.matchday__scores {
	@include font(primary, book, normal, fs-80);
	display: grid;

	gap: $sp4;
	grid-template-columns: auto 1fr auto;

	&.-large {
		@include font(primary, medium, normal, fs-80);
		gap: $sp3;
	}

	&:not(.-large) {
		width: 100%;
	}

	@include size(sm-up) {
		&.-large {
			@include font(primary, medium, normal, fs-150);
			gap: $sp3-5;
		}
	}

	@include size(md-up) {
		&.-large {
			@include font(primary, medium, normal, fs-170);
			gap: $sp4;
		}
	}

}

.matchday__scores-item {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2ch;
}

.matchday__scores-logo {
	width: $sp5;

	.matchday__scores.-large & {
		width: $sp5;
	}

	@include size(sm-up) {
		.matchday__scores.-large & {
			width: $sp7;
		}
	}

	@include size(md-up) {
		.matchday__scores.-large & {
			width: $sp7-5;
		}
	}
}

.matchday__scores-name {
	justify-content: flex-start;
	margin-right: $sp3;
	min-width: 3em;

	@include size(md-up) {
		margin-right: $sp4;
	}
}

.matchday__scores-score {
	@include font(primary, bold, normal, fs-80);

	.matchday__scores.-large & {
		@include font(primary, bold, normal, fs-100);
	}

	@include size(sm-up) {
		.matchday__scores.-large & {
			@include font(primary, medium, normal, fs-150);
		}
	}

	@include size(md-up) {
		.matchday__scores.-large & {
			@include font(primary, medium, normal, fs-170);
		}
	}
}

.matchday__scores-set {
	@include font(primary, book, normal, fs-80);

	&.-loser {
		color: var(--color-grey-300);
	}

	.matchday__scores-score + & {
		margin-left: $sp3-5;

		@include size(md-up) {
			margin-left: $sp4;
		}
	}

	@include size(sm-up) {
		.matchday__scores.-large & {
			@include font-size(fs-120);
		}
	}

	@include size(md-up) {
		.matchday__scores.-large & {
			@include font-size(fs-150);
		}
	}
}

@keyframes blinking {
	0% { opacity: 1; }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.matchday__arrow-nav-icon {
	@include size(xs-only) {
		width: $sp4;
		height: $sp4;
	}
}

.matchday__arrow-nav-toucharea {
	@include size(xs-only) {
		position: absolute;
		height: 40px;
		width: 40px;
	}
}

</style>
