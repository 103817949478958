<template>
	<div
		class="section-title"
		:class="[`-${type}`, {'-spacing': verticalSpacing}]"
	>
		<a
			v-if="moreLinkActive && moreLink"
			class="section-title__link-mobile"
			:href="moreLink"
		/>
		<component
			:is="computedLevel"
			class="section-title__text"
		>
			{{ title }} <slot name="inner" />
			<Icon
				v-if="moreLinkActive && moreLink"
				class="section-title__link-mobile-icon"
				icon-name="right-arrow"
				size="sm"
			/>
		</component>
		<slot name="outer" />
		<a
			v-if="moreLinkActive && moreLink"
			class="section-title__link"
			:href="moreLink"
		>
			<span class="section-title__link-text">{{ moreText ? moreText : $I18n.trans('commons.show_more') }}</span>
		</a>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
const types = ['main', 'main-lg', 'lg', 'md', 'sm'];

export default {
	name:       'SectionTitle',
	components: { Icon },
	props:      {
		type: {
			type:      String,
			default:   'lg',
			validator: (type) => types.includes(type),
		},
		title: {
			type:     String,
			required: true,
		},
		verticalSpacing: {
			type:    Boolean,
			default: true,
		},
		moreLink: {
			type:    String,
			default: null,
		},
		moreText: {
			type:    String,
			default: null,
		},
		moreLinkActive: {
			type:    Boolean,
			default: true,
		},
	},
	computed: {
		computedLevel() {
			switch (this.type) {
			case 'main':
				return 'h1';
			case 'main-lg':
				return 'h1';
			case 'lg':
				return 'h2';
			default:
				return 'h3';
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.section-title {
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: baseline;

	&.-spacing {
		margin-bottom: $sp3;

		&.-main {
			margin-bottom: $sp5;
		}

		&.-main-lg {
			margin-bottom: $sp5;
		}

		&.-lg {
			margin-bottom: $sp4;

			body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type, max-nesting-depth
				margin-bottom: $sp3;
			}

			@include size(md-up) {
				margin-bottom: $sp5;

				body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type, max-nesting-depth
					margin-bottom: $sp4;
				}
			}
		}

		&.-md {
			margin-bottom: $sp4;
		}

		&.-sm {
			margin-bottom: $sp4;
		}

		&.-lg + &.-sm {
			margin-top: -#{$sp3};

			@include size(md-up) {
				margin-top: -#{$sp4};
			}
		}
	}
}

.section-title__text {
	color: var(--color-white);

	.section-title.-main & {
		@include font(primary, medium, normal, fs-180);
		letter-spacing: -0.015em; // stylelint-disable-line property-disallowed-list

		@include size(md-up) {
			letter-spacing: -0.03em; // stylelint-disable-line property-disallowed-list
		}
	}

	.section-title.-main-lg & {
		@include font(primary, medium, normal, fs-200);
		letter-spacing: -0.015em; // stylelint-disable-line property-disallowed-list

		@include size(md-up) {
			letter-spacing: -0.03em; // stylelint-disable-line property-disallowed-list
		}
	}

	.section-title.-lg & {
		@include font(primary, book, normal, fs-170);
		letter-spacing: -0.01em; // stylelint-disable-line property-disallowed-list

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			@include font(primary, bold, normal, fs-150);
		}

		@include size(md-up) {
			@include font(primary, light);
			letter-spacing: -0.02em; // stylelint-disable-line property-disallowed-list
		}
	}

	.section-title.-md & {
		@include font(primary, light, normal, fs-150);
		color: var(--color-white);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			color: var(--color-black);
		}
	}

	.section-title.-sm & {
		@include font(primary, light, normal, fs-100);
		color: var(--color-grey-400);
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-black);
	}
}

.section-title__link {
	color: var(--color-hype-yellow);
	white-space: nowrap;

	&:hover,
	&:focus {
		color: var(--color-hype-yellow--active);
	}

	/* stylelint-disable selector-max-type, selector-no-qualifying-type */
	body.-t-sportpass & {
		color: var(--color-soft-white);

		&:hover,
		&:focus {
			color: var(--color-grey-200);
		}
	}

	@include size(md-down) {
		display: none;
	}
}

.section-title__link-text {
	@include size(md-down) {
		display: none;
	}
}

.section-title__link-mobile-icon {
	transform: translateY(10%);

	@include size(lg-up) {
		display: none;
	}
}

.section-title__link-mobile {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

	@include size(lg-up) {
		display: none;
	}
}

</style>
