<template>
	<div
		class="settings-menu"
		:class="{ 'is-mobile': isMobileVariant, [`-size-${size}`]: true }"
		@click.self.stop="handleWrapperClick"
	>
		<!-- main menu -->
		<div
			v-if="isMainMenu"
			class="settings-menu__menu -main"
		>
			<button
				v-for="item in currentMenu.items"
				:key="item.key"
				class="settings-menu__button"
				@click.stop="item.handleClick"
			>
				<div class="settings-menu__menu-left">
					<div
						v-if="item.icon"
						class="settings-menu__icon"
					>
						<Icon
							:icon-name="item.icon"
							color-class="sc-white"
							:size="size === 'sm' && !isMobileVariant ? 'md' : 'lg'"
						/>
					</div>
					<span>{{ item.label }}</span>
				</div>
				<div class="settings-menu__menu-right">
					<span
						v-if="item.valueLabel"
						class="settings-menu__value"
					>{{ item.valueLabel }}</span>
					<div
						v-if="!isMobileVariant"
						class="settings-menu__chevron"
					>
						<Icon
							icon-name="right-arrow"
							:size="size === 'sm' && !isMobileVariant ? 'sm' : 'lg'"
						/>
					</div>
				</div>
			</button>
		</div>
		<!-- submenu -->
		<div
			v-else
			class="settings-menu__menu -submenu"
		>
			<div
				v-if="isAudioTracksMenu"
				class="settings-menu__title"
			>
				{{ currentMenu.backButtonLabel }}
			</div>
			<button
				v-else
				class="settings-menu__button -back"
				@click.stop="resetToMainMenu"
			>
				<div class="settings-menu__chevron">
					<Icon
						icon-name="left-arrow"
						color-class="sc-white"
						:size="size === 'sm' && !isMobileVariant ? 'sm' : 'lg'"
					/>
				</div>
				<span>{{ currentMenu.backButtonLabel }}</span>
			</button>
			<button
				v-for="item in currentMenu.items"
				:key="item.key"
				:disabled="item.disabled"
				class="settings-menu__button"
				@click.stop="item.handleClick"
			>
				<Icon
					v-if="item.active"
					class="settings-menu__icon -active"
					icon-name="check"
					size="sm"
				/>
				<span
					v-else
					class="settings-menu__spacer"
				/>
				<span>{{ item.label }}</span>
			</button>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon.vue';

const MENU_TYPE = {
	MAIN:        'main',
	QUALITY:     'quality',
	AUDIO_TRACK: 'audio-track',
	SPEED:       'speed',
};

const VARIANT = {
	DEFAULT: 'default',
	MOBILE:  'mobile',
};

export default {
	name:       'PlayerControlsSettingsMenu',
	components: { Icon },
	props:      {
		variant: {
			type:      String,
			default:   VARIANT.DEFAULT,
			validator: (value) => Object.values(VARIANT).includes(value),
		},
		qualityOptions: {
			type:    Array,
			default: () => [], // { id, label }[]
		},
		currentQualityId:  [String, Number],
		audioTrackOptions: {
			type:    Array,
			default: () => [], // { id, label }[]
		},
		currentAudioTrackId: [String, Number],
		speedOptions:        {
			type:    Array,
			default: () => [], // { id, label, value, disabled}[]
		},
		currentSpeedId:         [String, Number],
		isPlaybackSpeedEnabled: {
			type:    Boolean,
			default: true,
		},
		isQualitySettingsEnabled: {
			type:    Boolean,
			default: true,
		},
		size: {
			type:      String,
			default:   'lg',
			validator: (value) => ['lg', 'md', 'sm'].includes(value),
		},
	},
	data() {
		return {
			currentMenuType: MENU_TYPE.MAIN,
		};
	},
	computed: {
		isMobileVariant() {
			return this.variant === VARIANT.MOBILE;
		},
		isMainMenu() {
			return this.currentMenuType === MENU_TYPE.MAIN;
		},
		isAudioTracksMenu() {
			return this.audioTrackOptions.length > 0;
		},
		mainMenuItems() {
			const currentAudioTrackLabel = this.audioTrackOptions.find((option) => option.id === this.currentAudioTrackId)?.label;
			const currentQualityLabel = this.qualityOptions.find((option) => option.id === this.currentQualityId)?.label;
			const currentSpeedLabel = this.speedOptions.find((option) => option.id === this.currentSpeedId)?.label;

			const canControlAudioTrack = this.audioTrackOptions.length > 1;
			const canControlQuality = this.isQualitySettingsEnabled && this.qualityOptions.length > 1;
			const canControlSpeed = this.isPlaybackSpeedEnabled && this.speedOptions.length > 1;
			const items = [];

			if (canControlQuality) {
				items.push({
					key:         'main-quality',
					label:       this.$I18n.trans('view_mode.quality'),
					icon:        'settings-gear',
					valueLabel:  currentQualityLabel,
					handleClick: () => this.setMenuType(MENU_TYPE.QUALITY),
				});
			}

			if (canControlAudioTrack) {
				items.push({
					key:         'main-audio-track',
					label:       this.$I18n.trans('view_mode.language'),
					icon:        'headset',
					valueLabel:  currentAudioTrackLabel,
					handleClick: () => this.setMenuType(MENU_TYPE.AUDIO_TRACK),
				});
			}

			if (canControlSpeed) {
				items.push({
					key:         'main-speed',
					label:       this.$I18n.trans('view_mode.speed'),
					icon:        'video-speed',
					valueLabel:  currentSpeedLabel,
					handleClick: () => this.setMenuType(MENU_TYPE.SPEED),
				});
			}

			return { items };
		},
		currentMenu() {
			if (this.isMainMenu) {
				return this.mainMenuItems;
			}

			if (this.currentMenuType === MENU_TYPE.QUALITY) {
				return {
					backButtonLabel: this.$I18n.trans('view_mode.quality'),
					items:           this.qualityOptions.map((option) => ({
						key:         `quality-${option.id}`,
						label:       option.label,
						active:      option.id === this.currentQualityId,
						handleClick: () => this.$emit('set-quality', option.id),
					})),
				};
			}

			if (this.currentMenuType === MENU_TYPE.AUDIO_TRACK) {
				return {
					backButtonLabel: this.$I18n.trans('view_mode.language'),
					items:           this.audioTrackOptions.map((option) => ({
						key:         `audio-track-${option.id}`,
						label:       option.label,
						active:      Number.parseInt(option.id) === Number.parseInt(this.currentAudioTrackId),
						handleClick: () => {
							this.$emit('set-audio-track', option.id);
							this.$emit('close');
						},
					})),
				};
			}

			if (this.currentMenuType === MENU_TYPE.SPEED) {
				return {
					backButtonLabel: this.$I18n.trans('view_mode.speed'),
					items:           this.speedOptions.map((option) => ({
						key:         `speed-${option.id}`,
						label:       option.label,
						active:      option.id === this.currentSpeedId,
						disabled:    option.disabled,
						handleClick: () => this.$emit('set-speed', option.id),
					})),
				};
			}

			return [];
		},
	},
	watch: {
		currentQualityId() {
			this.resetToMainMenu();
		},
		currentSpeedId() {
			this.resetToMainMenu();
		},
	},
	created() {
		if (this.isAudioTracksMenu) {
			this.currentMenuType = MENU_TYPE.AUDIO_TRACK;
		}
	},
	methods: {
		setMenuType(type) {
			this.currentMenuType = type;
		},
		resetToMainMenu() {
			if (this.isAudioTracksMenu) return;
			this.setMenuType(MENU_TYPE.MAIN);
		},
		handleWrapperClick() {
			if (this.isMobileVariant) {
				this.$emit('close');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

/* stylelint-disable */

.settings-menu {
	display: inline-flex;
	cursor: default;
	--menu-h-padding: 16px;
	--menu-v-padding: 12px;
	--menu-margin-right: 16px;
	--font-size: 16px;

	&:not(.is-mobile).-size-sm {
		--menu-h-padding: 12px;
		--menu-v-padding: 8px;
		--menu-margin-right: 8px;
		--font-size: 12px;
	}

	&.is-mobile {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.6);
	}
}

.settings-menu__menu {
	display: flex;
	flex-direction: column;
	flex: 1;
	border-radius: 4px;
	overflow: hidden;
	background: var(--color-grey-850);
	color: var(--color-soft-white);

	body.-t-light & {
		background: var(--color-white);
		color: var(--color-black);
	}

	.settings-menu.is-mobile & {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		border-radius: 16px 16px 0 0;
	}
}

.settings-menu__menu-left {
	display: flex;
	align-items: center;
}

.settings-menu__menu-right {
	display: flex;
	align-items: center;
	padding-left: 10px;
}

.settings-menu__value {
	color: var(--color-grey-300);
}

.settings-menu__chevron {
	color: var(--color-soft-white);
	padding-left: 8px;

	.settings-menu__button.-back & {
		padding-left: 0;
		padding-right: 8px;
	}

	body.-t-light & {
		color: var(--color-black);
	}
}

.settings-menu__title {
	padding: var(--menu-v-padding) var(--menu-h-padding);
	text-align: left;
	font-size: var(--font-size);

	.settings-menu.is-mobile .settings-menu__menu & {
		padding: 14px 16px;

		&:first-child {
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}
}

.settings-menu__icon {
	padding-right: var(--menu-h-padding);

	&.-active {
		margin-right: var(--menu-margin-right);
		padding-right: 0;
		color: var(--color-hype-yellow);
	}
}

.settings-menu__spacer {
	padding: 0 var(--menu-h-padding);
}

.settings-menu__button {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: var(--font-size);
	padding: var(--menu-v-padding) var(--menu-h-padding);
	white-space: nowrap;

	.settings-menu__menu.-main & {
		justify-content: space-between;
	}

	&.-back {
		padding: var(--menu-v-padding) var(--menu-h-padding);

		.settings-menu.is-mobile & {
			font-size: 18px;
			font-weight: 500;
		}
	}

	.settings-menu.is-mobile .settings-menu__menu.-main & {
		padding: 14px 16px;

		&:first-child {
			padding-top: 26px;
		}

		&:last-child {
			padding-bottom: 26px;
		}
	}

	.settings-menu.is-mobile .settings-menu__menu.-submenu & {
		padding: 14px 16px;

		&:first-child {
			padding-top: 24px;
			padding-bottom: 24px;
		}

		&:last-child {
			padding-bottom: 26px;
		}
	}

	&:hover {
		background: var(--color-grey-750);

		body.-t-light & {
			background: var(--color-grey-850);
		}
	}

	&:disabled {
		color: var(--color-grey-500);
	}
}
</style>
