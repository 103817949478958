<template>
	<Modal
		modal-name="FeedbackSurvey"
		type="dialog-md"
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		@closing-modal="storeDismissed"
	>
		<template #title>
			{{ $I18n.trans('feedback.survey_title') }}
		</template>
		<template #content>
			<div
				v-if="submitted"
			>
				{{ $I18n.trans('feedback.survey_success_msg') }}
			</div>

			<form
				v-else
				ref="form"
			>
				<div class="feedback-survey__intro">
					{{ $I18n.trans('feedback.survey_intro') }}
				</div>
				<div class="feedback-survey__input-w">
					<InputLabel
						:text="$I18n.trans('feedback.label_nps')"
						:dark-theme="!$arenaConfig.isLightTheme"
						required
					/>
					<ToggleButtonGroup
						name="nps"
						unique-id="nps"
						:buttons="options.nps"
						:selected-id="null"
						:full-width="true"
						:theme="$arenaConfig.isLightTheme ? null : 'dark'"
						:required="true"
						:ellipsis="false"
						@change="(data) => values.nps = data.id"
					/>
				</div>
				<InputText
					class="feedback-survey__input-w"
					name="feedback"
					type="textarea"
					:label="$I18n.trans('feedback.label_feedback')"
					:dark-theme="!$arenaConfig.isLightTheme"
					:value="values.feedback"
					@input="(data) => values.feedback = data"
				/>
				<InputText
					name="email"
					type="email"
					:label="$I18n.trans('validation.attributes.email')"
					:dark-theme="!$arenaConfig.isLightTheme"
					:value="values.email"
					@input="(data) => values.email = data"
				/>
			</form>
		</template>
		<template #footer>
			<div
				v-if="submitted"
				class="l-button-group"
			>
				<Button
					:text="$Helpers.ucfirst($I18n.trans('commons.close'))"
					:theme="$arenaConfig.isLightTheme ? null : 'dark'"
					@click="closeModal"
				/>
			</div>
			<div
				v-else
				class="l-button-group"
			>
				<Button
					:text="$Helpers.ucfirst($I18n.trans('commons.cancel'))"
					modifier="secondary"
					:disabled="isBusy"
					:theme="$arenaConfig.isLightTheme ? null : 'dark'"
					@click="closeModal"
				/>
				<Button
					:text="$Helpers.ucfirst($I18n.trans('commons.send'))"
					:theme="$arenaConfig.isLightTheme ? null : 'dark'"
					:disabled="!formIsValid"
					:busy="isBusy"
					@click="submitForm"
				/>
			</div>
		</template>
	</Modal>
</template>

<script>
import Button from '@shared/components/Button';
import Modal from '@shared/components/Modal';
import InputText from '@shared/components/Form/InputText';
import InputLabel from '@shared/components/Form/InputLabel';
import ToggleButtonGroup from '@shared/components/ToggleButtonGroup';
import laroute from '@laroute';

const LOCAL_STORAGE_DISMISSED = 'surveyDismissed';
const LOCAL_STORAGE_PARTICIPATED = 'surveyParticipated';
const LOCAL_STORAGE_FIRST_VISIT = 'surveyFirstVisit';

export default {
	name:       'FeedbackSurvey',
	components: { Modal, Button, InputText, ToggleButtonGroup, InputLabel },
	data() {
		return {
			options: {
				nps: Array.from(Array(11).keys()).map(key => ({ name: key, id: key })),
			},
			values: {
				nps:         null,
				feedback:    '',
				email:       '',
				url:         window.location.href,
				userAgent:   navigator.userAgent,
				windowWidth: window.innerWidth.toString(),
			},
			submitted: false,
			isBusy:    false,
		};
	},
	computed: {
		formIsValid() {
			return this.values.nps !== null;
		},
	},
	mounted() {
		this.init();
	},
	methods: {
		init() {
			const canPartcipate = this.canPartcipate();

			if (!canPartcipate) {
				return;
			}

			setTimeout(() => {
				this.eventBus.$emit('modal:FeedbackSurvey');
			}, window.isStorybook ? 0 : 3000);
		},
		canPartcipate() {
			if (window.isStorybook) {
				return true;
			}

			if (window.isEventPage) {
				return false;
			}

			const firstVisit = JSON.parse(localStorage.getItem(LOCAL_STORAGE_FIRST_VISIT));
			const dismissed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISSED));
			const participated = JSON.parse(localStorage.getItem(LOCAL_STORAGE_PARTICIPATED));
			const isSeasonStart = (new Date().getMonth() === 7 && new Date().getDate() >= 25) || (new Date().getMonth() === 8 && new Date().getDate() <= 30);

			const now = new Date().getTime();
			let showSurvey = true;

			if (!firstVisit) {
				localStorage.setItem(LOCAL_STORAGE_FIRST_VISIT, JSON.stringify(new Date().getTime()));
				return false;
			}
			// We don't want to ask for feedback on first visit... so we check if the first visit was at least
			// one week ago
			if (firstVisit && now < (firstVisit + (1000 * 60 * 60 * 24 * 7))) {
				return false;
			}
			if (dismissed) {
				showSurvey = now > (dismissed + (1000 * 60 * 60 * 24 * 30));
			}
			if (participated) {
				showSurvey = now > (participated + (1000 * 60 * 60 * 24 * 420));
			}
			if (isSeasonStart) { // On Season start there is usually not a lot a valuable feedback
				showSurvey = false;
			}

			return showSurvey ? Math.random() > 0.90 : false;
		},
		closeModal() {
			this.eventBus.$emit('modalClose:FeedbackSurvey');
		},
		storeDismissed() {
			if (!this.submitted) {
				localStorage.setItem(LOCAL_STORAGE_DISMISSED, JSON.stringify(new Date().getTime()));
			}
		},
		storeParticipation() {
			localStorage.setItem(LOCAL_STORAGE_PARTICIPATED, JSON.stringify(new Date().getTime()));
		},
		async submitForm() {
			if (this.formIsValid) {
				try {
					this.isBusy = true;
					await fetch(laroute.route('api.survey'), {
						method:  'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(this.values),
					});
					this.submitted = true;
					this.storeParticipation();
				} catch (error) {
					console.error(error);
				} finally {
					this.isBusy = false;
				}
			}
		},
	},
};
</script>

<style lang="scss">
@import '@sass/variables';

.feedback-survey__intro {
	padding-bottom: $sp5;
	margin-bottom: $sp5;
	border-bottom: 1px solid var(--color-grey-700);
}

.feedback-survey__input-w {
	&:not(:last-child) {
		margin-bottom: $sp6 !important; // stylelint-disable-line declaration-no-important
	}
}
</style>
