<template>
	<div class="input-birthdate">
		<InputLabel
			:text="$I18n.trans('commons.birthday')"
			:dark-theme="darkTheme"
		/>
		<div class="input-birthdate__input-w">
			<InputText
				ref="dayInput"
				:value="day"
				class="input-birthdate__input-el -width-2"
				placeholder="DD"
				type="number"
				maxlength="2"
				min="01"
				max="31"
				:dark-theme="darkTheme"
				:disabled="disabled"
				autocomplete="bday-day"
				@input="(value) => handleInput(value, 'day', 2)"
			/>
			<InputText
				ref="monthInput"
				:value="month"
				class="input-birthdate__input-el -width-2"
				placeholder="MM"
				type="number"
				min="01"
				max="12"
				maxlength="2"
				:dark-theme="darkTheme"
				:disabled="disabled"
				autocomplete="bday-month"
				@input="(value) => handleInput(value, 'month', 2)"
			/>
			<InputText
				ref="yearInput"
				:value="year"
				class="input-birthdate__input-el -width-4"
				placeholder="YYYY"
				type="number"
				min="1900"
				max="2100"
				maxlength="4"
				:dark-theme="darkTheme"
				:disabled="disabled"
				autocomplete="bday-year"
				@input="(value) => handleInput(value, 'year', 4)"
			/>
		</div>
	</div>
</template>

<script>
import InputText from '@shared/components/Form/InputText';
import InputLabel from '@shared/components/Form/InputLabel';

export default {
	name:       'InputBirthdate',
	components: { InputText, InputLabel },
	props:      {
		value: {
			type:    String,
			default: null,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			day:     '',
			month:   '',
			year:    '',
			mounted: false,
		};
	},
	computed: {
		isValid() {
			const allEmpty = this.day === '' && this.month === '' && this.year === '';
			const allFilled = this.day !== '' && this.month !== '' && this.year !== '';
			if (allEmpty) return true;

			return allFilled && [this.$refs.dayInput, this.$refs.monthInput, this.$refs.yearInput].every(input => input.isValid);
		},
	},
	watch: {
		value(value) {
			this.setValues(value);
		},
	},
	created() {
		this.setValues(this.value);
	},
	mounted() {
		this.$nextTick(() => {
			this.mounted = true;
		});
	},
	methods: {
		setValues(value) {
			if (value) {
				const date = new Date(value);
				this.day = date.getUTCDate().toString().padStart(2, '0');
				this.month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
				this.year = date.getUTCFullYear().toString();
			} else {
				this.day = '';
				this.month = '';
				this.year = '';
			}
		},
		handleInput(value, field, maxLength) {
			if (!this.mounted) return;
			switch (field) {
			case 'day':
				this.day = value;
				break;
			case 'month':
				this.month = value;
				break;
			case 'year':
				this.year = value;
				break;
			}

			if (value.length >= maxLength) {
				switch (field) {
				case 'day':
					this.$refs.monthInput.focus();
					break;
				case 'month':
					this.$refs.yearInput.focus();
					break;
				case 'year':
					break;
				}
			}
			this.emitDate();
		},
		emitDate() {
			const day = parseInt(this.day);
			const month = parseInt(this.month);
			const year = parseInt(this.year);

			if (this.isValid && day && month && year) {
				const date = new Date(Date.UTC(year, month - 1, day)).toISOString().split('T')[0];
				this.$emit('input', date);
			} else if (this.isValid) {
				this.$emit('input', null);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.input-birthdate__input-w {
	display: flex;
	gap: $sp4;
}

.input-birthdate__input-el {
	&.-width-2 {
		width: 100px;
	}

	&.-width-4 {
		width: 200px;
	}
}

</style>
