import actions from './actions';
import mutations from './mutations';
import getters from './getters';
export default {
	state: {
		recommended:     { items: [] },
		upcoming:        { items: [] },
		liveAndUpcoming: { items: [] },
		live:            { items: [] },
		ended:           { items: [] },
		videos:          { items: [] },
	},
	actions,
	mutations,
	getters,
};
