<template>
	<div class="league-table">
		<LoadingSpinner
			v-if="isLoading"
			class="league-table__loading-spinner"
		/>

		<template v-if="!isLoading && tableEntries">
			<div class="league-table__w">
				<table class="league-table__table">
					<tr class="league-table__header">
						<th class="league-table__col -main">
							<span class="league-table__rank">R</span> {{ $I18n.trans_choice('commons.team', 1) }}
						</th>
						<th
							v-for="col in tableColumns"
							:key="col.abbr"
							class="league-table__col"
						>
							<div>
								<Tooltip
									:text="col.name"
									position="top-center"
								>
									<template #toggle>
										{{ col.abbr }}
									</template>
								</Tooltip>
							</div>
						</th>
					</tr>
					<tr
						v-for="(entry, i) in tableEntries"
						:key="i"
						class="league-table__body"
						:class="{'-line': i === relegatedIndex}"
					>
						<td class="league-table__col -main">
							<a
								:href="entry.link"
								class="league-table__team-link"
							/>
							<span class="league-table__rank">{{ i + 1 }}</span>
							<img
								class="league-table__logo"
								:src="entry.image"
								:alt="entry.name.long"
							>
							<span class="u-hide@md">{{ entry.name.short }}</span>
							<span class="u-show-as-inline-flex@md">{{ entry.name.long }}</span>
						</td>
						<td
							v-for="(d, j) in entry.data"
							:key="j"
							class="league-table__col"
						>
							<div
								v-if="d.key === 'lastFive' && d.value"
								class="league-table__series"
							>
								<span
									v-for="(result, k) in d.value.split('')"
									:key="k"
									class="league-table__series-entry"
									:class="[`-${result}`]"
								/>
							</div>
							<template v-else>
								{{ d.value }}
							</template>
						</td>
					</tr>
				</table>
			</div>
			<div class="league-table__footnote">
				<div
					v-for="col in tableColumns"
					:key="col.abbr"
					class="league-table__footnote-row"
				>
					<div class="league-table__footnote-key">
						{{ col.abbr }}
					</div>
					<div class="league-table__footnote-value">
						{{ col.name }}
					</div>
				</div>
			</div>
		</template>
		<EmptyState
			v-if="!tableEntries && !isLoading"
			class="u-mt-7"
			:text="$I18n.trans('commons.no_types_existing', { type: $I18n.trans('arena.match_center.table')})"
		/>
	</div>
</template>

<script>
import Tooltip from '@shared/components/Tooltip';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import EmptyState from '@js/components/EmptyState';
import laroute from '@laroute';

export default {
	name:       'LeagueTable',
	components: { Tooltip, LoadingSpinner, EmptyState },
	props:      {
		table: {
			type:    Object,
			default: null,
		},
		isLoading: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		tableProperties() {
			return this.table?.entries.meta.formats.find(format => format.format === 'table').properties;
		},
		tableColumns() {
			return this.tableProperties?.map(p => {
				return {
					abbr: this.$I18n.trans(`sports.league.${p}`).abbr,
					name: this.$I18n.trans(`sports.league.${p}`).name,
				};
			});
		},
		relegatedIndex() {
			return this.table?.entries.data.length - (this.table.relegated || 0) - 1;
		},
		tableEntries() {
			return this.table?.entries.data.map(entry => {
				return {
					name: {
						long:  entry.team.club.name,
						short: entry.team.club.code,
					},
					image: this.getScaledImage(entry.team.imageUrl),
					data:  this.tableProperties.map(col => ({
						key:   col,
						value: entry.data[col],
					})),
					link: laroute.route('arena.team', {
						team: entry.team.id,
						slug: $Helpers.slugify(entry.team.clubTeamName),
					}),
				};
			});
		},
	},
	methods: {
		getScaledImage(imageUrl) {
			return this.$Helpers ? this.$Helpers.getScaledImageUrl(imageUrl, 50) : imageUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.league-table {
	width: 100%;
	position: relative;
	margin-bottom: $sp7;
}

.league-table__loading-spinner {
	position: absolute;
	top: 20vh;
	left: 50%;
	transform: translate(-50%);
}

.league-table__w {
	width: 100%;
	margin-bottom: $sp6;

	@include size(xs-only) {
		overflow-x: auto;
	}
}

.league-table__table {
	@include font-size(fs-80);
	width: 100%;
}

.league-table__header {
	color: var(--color-grey-300);
}

.league-table__body {
	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-black);
	}
}

.league-table__col {
	white-space: nowrap;
	padding: $sp4 $sp3 $sp4 $sp3;
	border-bottom: 1px solid var(--color-grey-900);
	position: relative;

	&.-main {
		width: 99%;
		text-align: left;
	}

	.league-table__body.-line & {
		border-bottom: 1px solid var(--color-grey-300);
	}

	.league-table__body:hover & {
		background-color: var(--color-grey-950);
	}

	@include size(md-up) {
		padding: $sp4 $sp4 $sp4 $sp4;
	}

	@include size(xs-only) {
		.league-table__header &,
		.league-table__body & {
			&:first-child {
				min-width: 120px;
				position: sticky;
				left: 0;
				z-index: 2;
				background-color: var(--color-body-background);
			}
		}

		.league-table__body & {
			&:first-child {
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					box-shadow: 5px 0 14px rgba(248, 248, 247, 0.18); // stylelint-disable-line declaration-property-value-allowed-list
					clip-path: inset(0 -15px 0 0);
					width: 15px;
					height: 100%;
				}
			}
		}
	}
}

.league-table__team-link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.league-table__rank {
	display: inline-block;
	width: 24px;
}

.league-table__logo {
	width: $sp5;
	margin-right: $sp2;
}

.league-table__series {
	display: flex;
	gap: $sp2;
}

.league-table__series-entry {
	display: inline-block;
	width: $sp3;
	height: $sp3;
	border-radius: 50%;

	&.-l { // stylelint-disable-line selector-class-pattern
		background-color: #FF8484;// stylelint-disable-line  declaration-property-value-allowed-list, sh-waqar/declaration-use-variable
	}

	&.-d { // stylelint-disable-line selector-class-pattern
		background-color: #FECA57; // stylelint-disable-line declaration-property-value-allowed-list, sh-waqar/declaration-use-variable
	}

	&.-w { // stylelint-disable-line selector-class-pattern
		background-color: #1DD1A1; // stylelint-disable-line declaration-property-value-allowed-list, sh-waqar/declaration-use-variable
	}

	@include size(md-up) {
		width: $sp3-5;
		height: $sp3-5;
	}
}

.league-table__footnote {
	@include font-size(fs-80);
	color: var(--color-grey-300);

}

.league-table__footnote-row {
	display: flex;
}

.league-table__footnote-key {
	width: 80px;
}

//.league-table__footnote-value {}
</style>
