if (!window.Object.fromEntries) {
	window.Object.fromEntries = function(entries) {
		if (!entries || !entries[Symbol.iterator]) {
			throw new Error('Object.fromEntries() requires a single iterable argument');
		}
		const obj = {};
		for (const [key, value] of entries) {
			obj[key] = value;
		}
		return obj;
	};
}

window.Promise.prototype.finally = window.Promise.prototype.finally || {
	finally(fn) {
		const onFinally = callback => Promise.resolve(fn()).then(callback);
		return this.then(
			result => onFinally(() => result),
			reason => onFinally(() => Promise.reject(reason))
		);
	},
}.finally;
