export const ArenaLaneType = {
	NAVIGATION:                     'navigation',
	NAVIGATION_SPORTS:              'navigation_sports',
	NAVIGATION_CLUBS_ON_FEDERATION: 'navigation_clubs_on_federation',
	RECOMMENDATIONS:                'recommendations',
	LIVE_AND_UPCOMING:              'live_and_upcoming',
	REPLAYS:                        'replays',
	VIDEOS:                         'videos',
	ALBUM:                          'album',
	SPORT:                          'sport',
	SUBSITE:                        'subsite',
	STORIES:                        'stories',
	OETV_RESULTS:                   'oetv_results',
	AD:                             'ad',
};
