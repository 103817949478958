import Vue from 'vue';
import Toast, { TYPE, createToastInterface } from 'vue-toastification';

export const setupToast = () => {
	const toastOptions = {
		icon:            false,
		hideProgressBar: true,
		toastDefaults:   {
			[TYPE.ERROR]: {
				timeout: false,
			},
			[TYPE.SUCCESS]: {
				timeout: 4000,
			},
		},
	};

	Vue.use(Toast, toastOptions);

	window.toast = createToastInterface(toastOptions);
};
