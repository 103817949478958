const baseModule = 'ALBUMS';

export const INIT_ALBUMS = `${baseModule}/INIT_ALBUMS`;
export const SET_STORE = `${baseModule}/SET_STORE`;

export default {
	state: {
		items: [],
	},
	actions: {
		[INIT_ALBUMS]({ commit }, { data }) {
			commit(SET_STORE, { data });
		},
	},
	mutations: {
		[SET_STORE](state, { data }) {
			const { items, currentPage, lastPage } = data;
			state.items = items;
			state.currentPage = currentPage;
			state.lastPage = lastPage;
			state.totalEventCount = 0;
			items?.forEach(album => state.totalEventCount += album.events?.length ?? 0);
		},
	},
};
