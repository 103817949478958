<template>
	<Layout>
		<JumbotronCarousel
			v-if="slides.length > 0"
			:slides="slides"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</JumbotronCarousel>
		<Jumbotron
			v-else
			:bg-image-url="bgImageUrl"
			:main-image-url="mainImageUrl"
			:title="title"
			:special-announcement="false"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</Jumbotron>
		<div class="l-container">
			<div
				v-for="(lane, index) in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<div
					v-if="index === 1 && $arenaConfig.domainKey === 'streethockey-verband' && !hasSubscription"
					class="l-content-section ballhockey-info"
				>
					<div class="l-row">
						<div class="l-col l-col--12 l-col--5@md">
							<div class="ballhockey-info__section-inner-w">
								<SectionTitle
									:title="$I18n.trans('ballhockey_wc.banner.title')"
									type="md"
								/>
								<!-- eslint-disable vue/no-v-html -->
								<div
									class="s-html -sm"
									v-html="$I18n.trans('ballhockey_wc.banner.copy')"
								/>
								<br><br>

								<Button
									:text="$I18n.trans('ballhockey_wc.banner.cta')"
									theme="dark"
									:link="purchaseLink"
								/>
							</div>
						</div>
						<div class="l-col l-col--12 l-col--6@md l-offset--1@md">
							<div class="ballhockey-info__section-inner-w -img">
								<img src="/images/ballhockey-wc/tournament-pass-banner-image.jpg">
							</div>
						</div>
					</div>
				</div>

				<SectionChildNav
					v-if="leagues.length && lane.lane_type === ArenaLaneType.NAVIGATION && lane.visible"
					type="league"
					:items="leagues"
				/>
				<SectionChildNav
					v-if="clubs.length && lane.lane_type === ArenaLaneType.NAVIGATION_CLUBS_ON_FEDERATION && lane.visible"
					type="club"
					:items="clubs"
				/>
				<SectionChildNav
					v-if="sports && sports.length && lane.lane_type === ArenaLaneType.NAVIGATION_SPORTS && lane.visible"
					type="sportsCardAlbum"
					:lane-config="lane.config ? JSON.parse(lane.config) : null"
					:items="sports"
				/>
				<SocialLane
					v-if="lane.lane_type === ArenaLaneType.STORIES && lane.visible && socialPosts.length > 0"
					:social-posts="socialPosts"
				/>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:album-icon="lane.imgUrl"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
				/>
				<AdLane
					v-if="lane.lane_type === ArenaLaneType.AD && lane.visible"
					:ad="lane.ad"
				/>
				<div
					v-if="lane.lane_type === ArenaLaneType.OETV_RESULTS && lane.visible"
					class="l-content-section"
				>
					<SectionTitle title="Resultate IMMOunited Bundesliga" />
					<SectionTitle
						type="sm"
						title="presented by win2day"
					/>
					<div class="results">
						<a
							ref="noopener"
							class="results__link"
							target="_blank"
							href="https://www.oetv.at/liga/adults/220834"
						>
							<div>1. Bundesliga (Damen) - <span style="white-space: nowrap;">Gruppe A</span></div>
							<Icon
								icon-name="launch"
								class="results__link-icon"
							/>
						</a>
						<a
							ref="noopener"
							class="results__link"
							target="_blank"
							href="https://www.oetv.at/liga/adults/220842"
						>
							<div>1. Bundesliga (Damen) - <span style="white-space: nowrap;">Gruppe B</span></div>
							<Icon
								icon-name="launch"
								class="results__link-icon"
							/>
						</a>
						<a
							ref="noopener"
							class="results__link"
							target="_blank"
							href="https://www.oetv.at/liga/adults/220838"
						>
							<div>1. Bundesliga (Herren) - <span style="white-space: nowrap;">Gruppe A</span></div>
							<Icon
								icon-name="launch"
								class="results__link-icon"
							/>
						</a>
						<a
							ref="noopener"
							class="results__link"
							target="_blank"
							href="https://www.oetv.at/liga/adults/220836"
						>
							<div>1. Bundesliga (Herren) - <span style="white-space: nowrap;">Gruppe B</span></div>
							<Icon
								icon-name="launch"
								class="results__link-icon"
							/>
						</a>
					</div>
				</div>
			</div>
			<EmptyState
				v-if="noEvents"
				:title="$I18n.trans_choice('commons.event', 2)"
				:text="$I18n.trans('arena.placeholder.replays')"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Jumbotron from '@js/components/Jumbotron';
import JumbotronCarousel from '@js/components/JumbotronCarousel';
import SectionChildNav from '@js/components/SectionChildNav';
import EventLane from '@js/components/EventLane';
import AdLane from '@js/components/AdLane';
import SocialLane from '@js/components/SocialLane';
import EmptyState from '@js/components/EmptyState';
import SectionTitle from '@js/components/SectionTitle';
import Icon from '@shared/components/Icon';
import Button from '@shared/components/Button';
import HeaderSponsors from '@js/components/HeaderSponsors';
import { laneMixer } from '@js/utils/laneMixer';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { AdType } from '@shared/enums/AdType';
import { mapState } from 'vuex';
import laroute from '@laroute';

export default {
	name:       'ViewFederation',
	components: { Layout, Jumbotron, JumbotronCarousel, SectionChildNav, EventLane, AdLane, SocialLane, EmptyState, HeaderSponsors, SectionTitle, Icon, Button },
	props:      {
		bgImageUrl: {
			type:    String,
			default: null,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		slides: {
			type:    Array,
			default: () => [],
		},
		leagues: {
			type:    Array,
			default: () => [],
		},
		clubs: {
			type:    Array,
			default: () => [],
		},
		sports: {
			type:    Array,
			default: () => [],
		},
		albums: {
			type:    Array,
			default: () => [],
		},
		lanes: {
			type:    Array,
			default: () => [],
		},
		socialPosts: {
			type:    Array,
			default: () => [],
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
	},
	data() {
		return {
			ArenaLaneType,
			purchaseLink: laroute.route('arena.subscriptions'),
		};
	},
	computed: {
		...mapState({
			profile: state => state.user.profile,
		}),
		hasSubscription() {
			return (this.profile?.allResources?.subscriptions || []).some(subscription => subscription.isActive);
		},
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
		mixedLanes() {
			return laneMixer(
				this.albums,
				this.lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
				{
					moreLinks: {
						upcoming: this.$arenaConfig.isCustomDomain
							? `${laroute.route('arena.video.center.custom')}?eventState=not_ended`
							: `${laroute.route('arena.video.center.federation', { federation: this.$arenaConfig.id })}?eventState=not_ended`,
						replays: this.$arenaConfig.isCustomDomain
							? `${laroute.route('arena.video.center.custom')}?eventState=ended`
							: `${laroute.route('arena.video.center.federation', { federation: this.$arenaConfig.id })}?eventState=ended`,
					},
				}
			);
		},
		headerSponsors() {
			return this.ads.find(ad => ad.type === AdType.SPONSORS_HEADER)?.logos;
		},
	},
	methods: {
		showTennisResults(lane) {
			return this.$arenaConfig.domainKey === 'oetv' && lane.lane_type === ArenaLaneType.REPLAYS;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.results {
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	gap: $sp4;

	@include size(sm-up) {
		grid-template-columns: repeat(2, 1fr);
	}

	@include size(lg-up) {
		grid-template-columns: repeat(4, 1fr);
	}
}

.results__link {
	background-color: var(--color-grey-900);
	padding: $sp4 $sp4 $sp3 $sp4;
	display: flex;
	justify-content: space-between;
	gap: $sp3;
	border-radius: $border-radius;
	cursor: pointer;
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;

	&:hover {
		transform: scale(1.05);
	}

	@include size(sm-up) {
		padding: $sp6 $sp4 $sp5 $sp4;
	}
}

.results__link-icon {
	color: var(--color-grey-500);
	transform: translateY(-4px);
}

// Ballhockey World Cup
.ballhockey-info {
	position: relative;
	margin-bottom: $sp6;

	&::after {
		content: '';
		display: block;
		background-color: var(--color-grey-900);
		height: 100%;
		width: 100vw;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: -1;
		pointer-events: none;
	}

	&::before {
		content: '';
		display: block;
		background-image: url('/images/ballhockey-wc/tournament-pass-graphic-en.png');
		background-position: top right;
		background-repeat: no-repeat;
		background-size: 370px auto;
		position: absolute;
		width: 100vw;
		height: 100%;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 1;
		pointer-events: none;

		html[lang='de'] & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-image: url('/images/ballhockey-wc/tournament-pass-graphic-de.png');
		}
	}

	@include size(sm-down) {
		&::before {
			display: none;
		}
	}
}

.ballhockey-info__section-inner-w {
	padding-bottom: $sp6;

	&:not(.-img) {
		padding-top: $sp6;

		@include size(md-up) {
			padding-top: $sp8-5;
		}
	}

	@include size(md-up) {
		padding-top: $sp8-5;
		padding-bottom: $sp8-5;
	}
}

</style>
