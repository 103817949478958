<template>
	<div
		class="multi-format-tool__outter-w"
	>
		<div class="multi-format__title">
			<span>Clip your moment</span>
		</div>
		<div
			class="multi-format-tool"
			:class="[`is-${currentUiStatus}`]"
		>
			<Modal
				:modal-name="modalNameDownloadVideo"
				type="dialog"
				:theme="$arenaConfig.isLightTheme ? null : 'dark'"
			>
				<template #title>
					{{ $I18n.trans('commons.type_create', { type: $I18n.trans_choice('commons.video', 1)}) }}
				</template>
				<template #content>
					<div v-if="currentUiStatus === UI_STATUS.EMAIL_NOTIFICATION_REQUESTED">
						<div
							class="multi-format-tool__notification-message"
						>
							<Icon
								icon-name="fireworks"
								size="xl"
							/>
							{{ $I18n.trans('event.multiformat_editor.messages.notification_success') }}
						</div>
					</div>
					<div
						v-else-if="currentUiStatus === UI_STATUS.GENERATING_VIDEO_CROP || currentUiStatus === UI_STATUS.VIDEO_CROP_OVERTIME"
						class="multi-format-tool__in-progress-info"
					>
						<LoadingSpinner
							color="light"
							:theme="$arenaConfig.isLightTheme ? 'dark' : 'light'"
							class="multi-format-tool__loading-spinner"
						/>
						{{ $I18n.trans('analysis.video_export.progress.create_video') }}
						<div
							v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_OVERTIME"
							class="multi-format-tool__overtime-message"
						>
							{{ $I18n.trans('event.multiformat_editor.messages.processing_overtime') }}
							<InputText
								ref="emailInput"
								:dark-theme="!$arenaConfig.isLightTheme"
								label="E-Mail"
								type="email"
								class="multi-format-tool__overtime-email"
								:value="email"
								@input="(value) => email = value"
							/>
						</div>
						<div
							v-if="emailNotificationError"
							class="multi-format-tool__notification-message"
						>
							<Icon
								icon-name="sad"
								size="xl"
							/>
							{{ $I18n.trans('event.multiformat_editor.messages.notification_error') }}
						</div>
					</div>
					<template v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_AVAILABLE">
						{{ $I18n.trans('event.multiformat_editor.messages.video_created') }}
						<InputCopy
							class="multi-format-tool__copy-link"
							:label="$I18n.trans('event.multiformat_editor.labels.copy_link', {days: `14 ${$I18n.trans_choice('commons.day', 14)}`})"
							:value="cropVideoDownloadUrl"
							:no-wrap="true"
							:dark-theme="!$arenaConfig.isLightTheme"
						/>
					</template>
				</template>
				<template #footer>
					<div class="multi-format-tool__button-group">
						<template v-if="currentUiStatus === UI_STATUS.EMAIL_NOTIFICATION_REQUESTED">
							<Button
								:text="$Helpers.ucfirst($I18n.trans('commons.close'))"
								modifier="secondary"
								theme="dark"
								@click="closeDownloadModal"
							/>
						</template>
						<template v-else>
							<Button
								:text="currentUiStatus === UI_STATUS.VIDEO_CROP_AVAILABLE ? $Helpers.ucfirst($I18n.trans('commons.close')) : $Helpers.ucfirst($I18n.trans('commons.cancel'))"
								modifier="secondary"
								theme="dark"
								@click="closeDownloadModal"
							/>
							<Button
								v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_OVERTIME && !emailNotificationSaved"
								:text="$Helpers.ucfirst($I18n.trans('commons.save'))"
								:disabled="!($refs.emailInput && $refs.emailInput.isValid)"
								theme="dark"
								@click="storeEmailNotification"
							/>
							<Button
								v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_AVAILABLE"
								:text="$Helpers.ucfirst($I18n.trans('commons.download'))"
								theme="dark"
								@click="downloadCropVideo"
							/>
						</template>
					</div>
				</template>
			</Modal>

			<div
				ref="playerWrapper"
				class="multi-format-tool__player-w"
			>
				<MultiFormatPlayer
					ref="cropperVideo"
					:hls-url="hlsUrl"
					:ui-status="currentUiStatus"
					:show-controls="showControls"
					:tag-time="tagTime"
					:internal-is-mobile-portrait="internalIsMobilePortrait"
					@coordinates-update="coordinatesUpdate"
					@cropstart="handleCropStart"
					@video-ended="handleVideoEnded"
					@duration-update="setMaxDuration"
				>
					<template #video-overlay>
						<!-- STATUS: INITIAL -->
						<div
							v-if="[UI_STATUS.INITIAL ].includes(currentUiStatus) && !internalIsMobilePortrait"
							class="multi-format-tool__header"
							:style="{
								width: `${cropPosition.width - 6}px`,
								left: `${cropPosition.x}px`,
							}"
						>
							<ol class="multi-format-tool__header-info">
								<li>{{ $I18n.trans('event.multiformat_editor.messages.info_1') }}</li>
								<li>{{ $I18n.trans('event.multiformat_editor.messages.info_2') }}</li>
								<li>{{ $I18n.trans('event.multiformat_editor.messages.info_3') }}</li>
							</ol>
						</div>
						<!-- STATUS: READY or RECORDING -->
						<div
							v-if="[UI_STATUS.READY, UI_STATUS.RECORDING].includes(currentUiStatus) && !internalIsMobilePortrait"
							class="multi-format-tool__header"
							:style="{
								width: `${cropPosition.width - 6}px`,
								left: `${cropPosition.x}px`,
							}"
						>
							<Button
								class="multi-format-tool__header-button"
								theme="dark"
								modifier="borderless"
								icon="e-remove"
								icon-size="xl"
								@click="cancelReadyStatus"
							/>
						</div>
						<div
							v-if="currentUiStatus !== UI_STATUS.PREVIEW && !internalIsMobilePortrait"
							class="multi-format-tool__controls-w"
							:class="[`is-${currentUiStatus}`]"
							:style="controlsWrapperPositionStyle"
						>
							<div class="multi-format-tool__controls">
								<!-- STATUS: INITIAL -->
								<Button
									v-if="currentUiStatus === UI_STATUS.INITIAL"
									:text="$I18n.trans('event.multiformat_editor.labels.ready')"
									theme="dark"
									@click="setReadyStatus"
								/>
								<!-- STATUS: READY -->
								<div
									v-if="currentUiStatus === UI_STATUS.READY"
									class="multi-format-tool__ready-info"
								>
									<svg
										width="61"
										height="55"
										viewBox="0 0 61 55"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M25.5 41.5H59.5"
											stroke="currentColor"
											stroke-miterlimit="10"
										/>
										<path
											d="M47.5 53.5L59.5 41.5L47.5 29.5"
											stroke="currentColor"
											stroke-miterlimit="10"
											stroke-linecap="square"
										/>
										<path
											d="M35.5 13.5H1.5"
											stroke="currentColor"
											stroke-miterlimit="10"
										/>
										<path
											d="M13.5 25.5L1.5 13.5L13.5 1.5"
											stroke="currentColor"
											stroke-miterlimit="10"
											stroke-linecap="square"
										/>
									</svg>

									{{ $I18n.trans('event.multiformat_editor.labels.drag_to_start') }}
								</div>
								<!-- STATUS: RECORDING -->
								<template v-if="currentUiStatus === UI_STATUS.RECORDING">
									<Button
										:text="$I18n.trans('event.multiformat_editor.labels.stop')"
										theme="dark"
										modifier="tertiary"
										size="small"
										@click="stopClipRecording"
									/>
									<div
										class="multi-format-tool__timer"
										v-text="remainingClipTimeSecs"
									/>
								</template>
								<!-- STATUS: FINISHED_RECORDING -->
								<!-- STATUS: GENERATING_VIDEO_CROP -->
								<!-- STATUS: VIDEO_CROP_AVAILABLE -->
								<template v-if="[UI_STATUS.FINISHED_RECORDING, UI_STATUS.GENERATING_VIDEO_CROP, UI_STATUS.VIDEO_CROP_AVAILABLE].includes(currentUiStatus)">
									<div
										v-if="!isCommunityCropping"
										class="multi-format-tool__logo-settings"
									>
										<Select
											name="logo"
											class="multi-format-tool__logo-settings-select"
											:searchable="false"
											:clearable="false"
											:value="logo"
											:options="logoOptions"
											:dark-theme="!$arenaConfig.isLightTheme"
											@change="(val) => logo = val"
										/>
										<Select
											v-if="logo"
											name="logoPosition"
											class="multi-format-tool__logo-settings-select"
											:searchable="false"
											:value="logoPosition"
											:clearable="false"
											:options="logoPositionOptions"
											:dark-theme="!$arenaConfig.isLightTheme"
											@change="(val) => logoPosition = val"
										/>
									</div>
									<div class="multi-format-tool__button-group u-mb-3">
										<Button
											:text="$I18n.trans('event.multiformat_editor.labels.restart')"
											theme="dark"
											@click="resetClipping"
										/>
										<Button
											:text="$I18n.trans('commons.preview')"
											theme="dark"
											@click="previewClip"
										/>
									</div>
									<div class="multi-format-tool__button-group">
										<Button
											:text="$I18n.trans('commons.type_create', {'type': $I18n.trans_choice('commons.video', 1)})"
											theme="dark"
											@click="createVideoCrop"
										/>
									</div>
								</template>
								<!-- STATUS: VIDEO_CROP_FAILURE -->
								<Button
									v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_FAILURE"
									:text="$I18n.trans('event.multiformat_editor.labels.restart')"
									theme="dark"
									@click="resetClipping"
								/>
							</div>
						</div>
					</template>
				</MultiFormatPlayer>
			</div>

			<template v-if="currentUiStatus === UI_STATUS.PREVIEW">
				<div
					ref="previewContainer"
					class="multi-format-tool__preview-container"
					:style="maskStyle"
				/>
				<div
					v-if="!isCommunityCropping"
					class="multi-format-tool__preview-logo-container"
					:style="{width: `${cropPosition.width + 2}px`}"
				>
					<div
						class="multi-format-tool__preview-logo"
						:class="[`-position-${logoPosition}`]"
					>
						<img
							:src="logo"
							class="multi-format-tool__preview-logo-image"
						>
					</div>
				</div>
				<div
					v-if="!internalIsMobilePortrait"
					class="multi-format-tool__header -preview"
					:style="{width: `${cropPosition.width + 2}px`}"
				>
					<Button
						theme="dark"
						class="multi-format-tool__header-button"
						modifier="borderless"
						icon="e-remove"
						icon-size="xl"
						@click="stopPreview"
					/>
				</div>
				<div
					v-if="!internalIsMobilePortrait"
					class="multi-format-tool__preview-infos"
					:style="{width: `${cropPosition.width + 2}px`}"
				>
					<span class="multi-format-tool__preview-info-label">{{ $I18n.trans('commons.preview') }}</span>
					<span class="multi-format-tool__preview-info-value">{{ previewCountdown }}</span>
				</div>
			</template>
			<div
				v-if="maxDurationReachedInfoVisible && currentUiStatus === UI_STATUS.FINISHED_RECORDING && !internalIsMobilePortrait"
				class="multi-format-tool__max-duration-reached-info"
				:style="controlsWrapperPositionStyle"
			>
				{{ $I18n.trans('event.multiformat_editor.labels.30s_limit_reached') }}
			</div>
		</div>
		<div
			v-if="internalIsMobilePortrait"
			class="multi-format-tool__mobile-potrait-w"
		>
			<div
				v-if="currentUiStatus === UI_STATUS.RECORDING"
				class="multi-format-tool__timer"
			>
				{{ remainingClipTimeSecs }} {{ $I18n.trans_choice('commons.second', remainingClipTimeSecs) }}
			</div>
			<div
				v-if="currentUiStatus === UI_STATUS.PREVIEW"
				class="multi-format-tool__preview-infos-mobile-portrait"
			>
				<span>{{ $I18n.trans('commons.preview') }}</span>
				<span>{{ previewCountdown }}</span>
			</div>
			<div
				v-if="maxDurationReachedInfoVisible && currentUiStatus === UI_STATUS.FINISHED_RECORDING"
				class="multi-format-tool__timer"
			>
				{{ $I18n.trans('event.multiformat_editor.labels.30s_limit_reached') }}
			</div>
			<!-- STATUS: READY -->
			<div
				v-if="currentUiStatus === UI_STATUS.READY"
				class="multi-format-tool__timer"
			>
				{{ $I18n.trans('event.multiformat_editor.labels.drag_to_start') }}
			</div>

			<!-- STATUS: INITIAL -->
			<div
				v-if="currentUiStatus === UI_STATUS.INITIAL"
				class="l-button-group"
			>
				<Button
					:text="$I18n.trans('event.multiformat_editor.labels.ready')"
					theme="dark"
					modifier="primary"
					@click="setReadyStatus"
				/>
			</div>

			<div
				v-if="[UI_STATUS.READY, UI_STATUS.RECORDING].includes(currentUiStatus)"
				class="l-button-group"
			>
				<Button
					theme="dark"
					modifier="secondary"
					:text="$Helpers.ucfirst($I18n.trans('commons.back'))"
					@click="cancelReadyStatus"
				/>
				<Button
					theme="dark"
					:text="$I18n.trans('event.multiformat_editor.labels.stop')"
					:disabled="currentUiStatus === UI_STATUS.READY"
					@click="stopClipRecording"
				/>
			</div>
			<template v-if="[UI_STATUS.FINISHED_RECORDING, UI_STATUS.GENERATING_VIDEO_CROP, UI_STATUS.VIDEO_CROP_AVAILABLE].includes(currentUiStatus)">
				<div class="l-button-group u-mb-4">
					<Button
						theme="dark"
						modifier="secondary"
						:text="$I18n.trans('event.multiformat_editor.labels.restart')"
						@click="resetClipping"
					/>
					<Button
						theme="dark"
						modifier="secondary"
						:text="$I18n.trans('commons.preview')"
						@click="previewClip"
					/>
				</div>
				<div
					v-if="!isCommunityCropping"
					class="multi-format-tool__logo-settings"
				>
					<Select
						name="logo"
						class="multi-format-tool__logo-settings-select"
						:searchable="false"
						:clearable="false"
						:value="logo"
						:options="logoOptions"
						:dark-theme="!$arenaConfig.isLightTheme"
						@change="(val) => logo = val"
					/>
					<Select
						v-if="logo"
						name="logoPosition"
						class="multi-format-tool__logo-settings-select"
						:searchable="false"
						:clearable="false"
						:value="logoPosition"
						:options="logoPositionOptions"
						:dark-theme="!$arenaConfig.isLightTheme"
						@change="(val) => logoPosition = val"
					/>
				</div>
				<div class="l-button-group">
					<Button
						:text="$I18n.trans('commons.type_create', {'type': $I18n.trans_choice('commons.video', 1)})"
						theme="dark"
						@click="createVideoCrop"
					/>
				</div>
			</template>
			<div
				v-if="currentUiStatus === UI_STATUS.PREVIEW"
				class="l-button-group"
			>
				<Button
					theme="dark"
					modifier="secondary"
					:text="$Helpers.ucfirst($I18n.trans('commons.cancel'))"
					@click="stopPreview"
				/>
			</div>
			<!-- STATUS: VIDEO_CROP_FAILURE -->
			<div
				v-if="currentUiStatus === UI_STATUS.VIDEO_CROP_FAILURE"
				class="l-button-group"
			>
				<Button
					theme="dark"
					:text="$I18n.trans('event.multiformat_editor.labels.restart')"
					@click="resetClipping"
				/>
			</div>
			<ol
				v-if="[UI_STATUS.INITIAL ].includes(currentUiStatus)"
				class="multi-format-tool__mobile-potrait-info"
			>
				<li>{{ $I18n.trans('event.multiformat_editor.messages.info_1') }}</li>
				<li>{{ $I18n.trans('event.multiformat_editor.messages.info_2') }}</li>
				<li>{{ $I18n.trans('event.multiformat_editor.messages.info_3') }}</li>
			</ol>
		</div>
	</div>
</template>

<script>

import MultiFormatPlayer from '@shared/components/MultiFormatTool/MultiFormatPlayer';
import Button from '@shared/components/Button';
import Icon from '@shared/components/Icon';
import Modal from '@shared/components/Modal';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import InputText from '@shared/components/Form/InputText';
import InputCopy from '@shared/components/Form/InputCopy';
import Select from '@shared/components/Select';
import { getShortLink } from '@shared/utils/getShortLink';

import laroute from '@laroute';
import * as Sentry from '@sentry/vue';

const CLIP_INTERVAL_MS = 1000;
const API_POLLING_INTERVAL_MS = 2000;
const CROP_VIDEO_PROCESSING_OVERTIME = 3000;
const CLIP_MAX_DURATION_MS = 30000;

export const UI_STATUS = {
	INITIAL:                      'initial',
	READY:                        'ready',
	RECORDING:                    'recording',
	FINISHED_RECORDING:           'finished-recording',
	PREVIEW:                      'preview',
	GENERATING_VIDEO_CROP:        'generating-video-crop',
	VIDEO_CROP_AVAILABLE:         'video-crop-available',
	VIDEO_CROP_FAILURE:           'video-crop-failure',
	VIDEO_CROP_OVERTIME:          'video-crop-overtime',
	EMAIL_NOTIFICATION_REQUESTED: 'email-notification-requested',
};

export const VIDEO_CROP_STATUS = {
	JOB_CREATED: 'created',
	PROCESSING:  'processing',
	AVAILABLE:   'available',
	FAILED:      'failed',
};

const getMobileQuery = () => {
	return window.matchMedia('(max-width: 1024px)').matches && window.matchMedia('(pointer: coarse)').matches;
};
const getPortraitQuery = () => {
	return window.matchMedia('(orientation: portrait)').matches;
};
const getLandscapeQuery = () => {
	return window.matchMedia('(orientation: landscape)').matches;
};

export default {
	name:       'MultiFormatTool',
	components: { MultiFormatPlayer, Button, Modal, LoadingSpinner, InputText, Icon, Select, InputCopy },
	props:      {
		eventId: {
			type:     Number,
			required: true,
		},
		hlsUrl: {
			type:     String,
			required: true,
		},
		tagTime: {
			type:    Object,
			default: null,
		},
		logos: {
			type:    Array,
			default: () => [],
		},
		isCommunityCropping: {
			type:    Boolean,
			default: false,
		},
		uiStatus: {
			type:    String,
			default: null,
		},
	},
	data() {
		return {
			cropper:          null,
			clipUrl:          null,
			logo:             null,
			logoPosition:     'bottom_right',
			email:            null,
			elapsedClipTime:  0,
			previewCountdown: 0,
			clipData:         {
				keyPoints: [],
				startTime: 0,
				duration:  0,
			},
			maxClipDuration:          CLIP_MAX_DURATION_MS,
			clipInterval:             null,
			checkVideoStatusInterval: null,
			cropVideoId:              null,
			cropVideoDataUrl:         null,
			cropVideoDownloadUrl:     null,
			cropVideoStatus:          null,
			cropVideoStart:           null,
			cropPosition:             {
				x:     0,
				width: 0,
			},
			logoPositionOptions: [
				{ label: 'Bottom Right', value: 'bottom_right' },
				{ label: 'Bottom Left', value: 'bottom_left' },
				{ label: 'Top Right', value: 'top_right' },
				{ label: 'Top Left', value: 'top_left' },
			],
			maxDurationReachedInfoVisible: false,
			emailNotificationSaved:        false,
			emailNotificationError:        false,
			modalNameDownloadVideo:        'download-video',
			internalUiStatus:              UI_STATUS.INITIAL,
			UI_STATUS,
			internalIsMobile:              getMobileQuery(),
			internalIsPortrait:            getPortraitQuery(),
			internalIsLandscape:           getLandscapeQuery(),
		};
	},
	computed: {
		currentUiStatus() {
			// Use the prop value if provided, otherwise use internal state
			return this.uiStatus || this.internalUiStatus;
		},
		showControls() {
			return ![UI_STATUS.READY, UI_STATUS.PREVIEW, UI_STATUS.RECORDING, UI_STATUS.FINISHED_RECORDING].includes(this.currentUiStatus);
		},
		internalIsMobilePortrait() {
			return this.internalIsMobile && this.internalIsPortrait;
		},
		remainingClipTimeSecs() {
			return (this.maxClipDuration / 1000) - (Math.abs(Math.round(this.maxClipDuration - this.elapsedClipTime) / 1000)) - 1; // -1 to show 0 at the beginning
		},
		controlsWrapperPositionStyle() {
			return {
				width:     `${this.cropPosition.width - 6}px`, // offsetting border
				transform: `translateX(${this.cropPosition.x}px)`,
			};
		},
		maskStyle() {
			return {
				maskImage: `linear-gradient(to right,
			black 0%,
			black calc(50% - ${this.cropPosition.width / 2}px),
			transparent calc(50% - ${this.cropPosition.width / 2}px),
			transparent calc(50% + ${this.cropPosition.width / 2}px),
			black calc(50% + ${this.cropPosition.width / 2}px),
			black 100%)`,
			};
		},
		logoOptions() {
			return [...[{ label: $I18n.trans('event.multiformat_editor.labels.no_logo'), value: null }], ...this.logos.map(l => ({ label: l.name.replace(/(.{18})..+/, '$1…'), value: l.imageUrl }))];
		},
	},
	watch: {
		cropVideoStatus(value) {
			if (value === VIDEO_CROP_STATUS.JOB_CREATED) {
				this.internalUiStatus = UI_STATUS.GENERATING_VIDEO_CROP;
				this.eventBus.$emit(`modal:${this.modalNameDownloadVideo}`);
			}
			if (value === VIDEO_CROP_STATUS.PROCESSING) {
				this.internalUiStatus = UI_STATUS.GENERATING_VIDEO_CROP;
			}
			if (value === VIDEO_CROP_STATUS.AVAILABLE) {
				this.internalUiStatus = UI_STATUS.VIDEO_CROP_AVAILABLE;
			}
			if (value === VIDEO_CROP_STATUS.FAILED) {
				this.internalUiStatus = UI_STATUS.VIDEO_CROP_FAILURE;
				this.eventBus.$emit(`modalClose:${this.modalNameDownloadVideo}`);
				this.$toast.error(this.$I18n.trans('commons.something_went_wrong'));
			}
			if (value === VIDEO_CROP_STATUS.PROCESSING) {
				this.internalUiStatus = UI_STATUS.GENERATING_VIDEO_CROP;
			}
		},
	},
	mounted() {
		this.handleKeybindings();
		this.handleOrientationChange();
	},
	beforeDestroy() {
		// Remove event listeners to prevent memory leaks
		document.removeEventListener('keydown', this.handleKeydown);
		window.matchMedia('(orientation: portrait)').removeEventListener('change', this.handleOrientationChangeEvent);
	},
	methods: {
		handleKeybindings() {
			document.addEventListener('keydown', this.handleKeydown);
		},
		handleKeydown(event) {
			if (event.key === ' ') {
				if (this.currentUiStatus === UI_STATUS.READY) {
					this.startClipRecording();
				} else if (this.currentUiStatus === UI_STATUS.RECORDING) {
					this.stopClipRecording();
				}
			}

			if (event.key === 'ArrowLeft') {
				if (this.currentUiStatus === UI_STATUS.INITIAL) {
					this.$refs.cropperVideo.seek(-1);
				}
			}

			if (event.key === 'ArrowRight') {
				if (this.currentUiStatus === UI_STATUS.INITIAL) {
					this.$refs.cropperVideo.seek(1);
				}
			}

			if (event.key === 'Escape') {
				if ([UI_STATUS.RECORDING, UI_STATUS.READY].includes(this.currentUiStatus)) {
					this.cancelReadyStatus();
				}
			}
		},
		handleOrientationChange() {
			window.matchMedia('(orientation: portrait)').addEventListener('change', this.handleOrientationChangeEvent);
		},
		handleOrientationChangeEvent(_event) {
			this.internalIsPortrait = getPortraitQuery();
			this.internalIsLandscape = getLandscapeQuery();
		},
		coordinatesUpdate(data) {
			window.requestAnimationFrame(() => {
				this.cropPosition.width = data.width + 2;
				this.cropPosition.x = data.left + 2;
			});
		},
		handleCropStart() {
			if (this.currentUiStatus === UI_STATUS.READY) {
				this.startClipRecording();
			}
		},
		setPlayerClippingMode(value) {
			if (value) {
				this.$refs.cropperVideo.togglePlay(true);
			} else {
				this.$refs.cropperVideo.togglePlay(false);
			}
		},
		resetClipping() {
			this.$refs.cropperVideo.setVideoTime(this.clipData.startTime ?? 0);
			this.clipData = {
				keyPoints: [],
				startTime: 0,
				duration:  0,
			};
			this.elapsedClipTime = 0;
			this.internalUiStatus = UI_STATUS.INITIAL;
			this.emailNotificationSaved = false;
			this.emailNotificationError = false;
			this.email = null;
			this.cropVideoId = null;
			this.cropVideoDataUrl = null;
			this.cropVideoDownloadUrl = null;
			this.cropVideoStatus = null;
			this.setPlayerClippingMode(false);
		},
		setReadyStatus() {
			this.internalUiStatus = UI_STATUS.READY;
		},
		cancelReadyStatus() {
			this.internalUiStatus = UI_STATUS.INITIAL;
			this.setPlayerClippingMode(false);
			this.resetClipping();

			if (this.clipInterval) {
				clearInterval(this.clipInterval);
				this.elapsedClipTime = 0;
			}
		},
		handleVideoEnded() {
			if (this.currentUiStatus === UI_STATUS.RECORDING) {
				this.stopClipRecording();
			}
		},
		startClipRecording() {
			this.internalUiStatus = UI_STATUS.RECORDING;

			// Set player to play
			this.setPlayerClippingMode(true);

			this.clipData.startTime = this.$refs?.cropperVideo.position;

			// Save initial position
			this.saveClipData();
			this.elapsedClipTime += CLIP_INTERVAL_MS;

			this.clipInterval = setInterval(() => {
				if (this.elapsedClipTime >= this.maxClipDuration) {
					this.showMaxDurationReachedInfo();
					this.stopClipRecording();
					return;
				}
				this.saveClipData();
				this.elapsedClipTime += CLIP_INTERVAL_MS;
			}, CLIP_INTERVAL_MS);
		},
		stopClipRecording() {
			this.internalUiStatus = UI_STATUS.FINISHED_RECORDING;
			this.clipData.duration = Math.abs((this.elapsedClipTime / 1000));
			this.setPlayerClippingMode(false);
			if (this.clipInterval) {
				clearInterval(this.clipInterval);
				this.elapsedClipTime = 0;
			}
		},
		previewClip() {
			this.internalUiStatus = UI_STATUS.PREVIEW;

			this.$refs.cropperVideo.setVideoTime(this.tagTime ? this.clipData.startTime - this.tagTime.start : this.clipData.startTime);
			this.$refs.cropperVideo.togglePlay(true);
			this.$refs.playerWrapper.style.transition = '';
			// set initial position to first key, then set position to second key to start transition which will end after 1s
			this.$refs.playerWrapper.style.transform = `translateX(calc(50% - ${this.cropPosition.width / 2}px - ${this.clipData.keyPoints[0].xLeft * 100}%))`;
			window.requestAnimationFrame(() => {
				this.$refs.playerWrapper.style.transition = 'transform 1s linear';
				this.$refs.playerWrapper.style.transform = `translateX(calc(50% - ${this.cropPosition.width / 2}px - ${this.clipData.keyPoints[1].xLeft * 100}%))`;
			});

			this.previewCountdown = this.clipData.duration;

			let counter = 2;

			this.previewInterval = setInterval(() => {
				this.previewCountdown -= 1;
				if (counter >= this.clipData.keyPoints.length) {
					// wait for the last transition to finish
					setTimeout(() => {
						this.stopPreview();
					}, 1000);
					return;
				}
				this.$refs.playerWrapper.style.transform = `translateX(calc(50% - ${this.cropPosition.width / 2}px - ${this.clipData.keyPoints[counter].xLeft * 100}%))`;
				counter += 1;
			}, CLIP_INTERVAL_MS);
		},
		stopPreview() {
			this.internalUiStatus = UI_STATUS.FINISHED_RECORDING;
			this.$refs.cropperVideo.togglePlay(false);
			this.$refs.playerWrapper.style.transition = '';
			this.$refs.playerWrapper.style.transform = 'translateX(0)';
			clearInterval(this.previewInterval);
		},
		saveClipData() {
			const cropperRef = this.$refs?.cropperVideo;
			const cropBoxData = cropperRef?.cropper?.getCropBoxData();
			this.clipData.keyPoints.push({
				t:      (this.elapsedClipTime / CLIP_INTERVAL_MS) ?? 0,
				xLeft:  (cropBoxData?.left ?? 0) / (cropperRef?.containerWidth ?? 0), // Percentage offset
				xWidth: (cropBoxData?.width ?? 0) / (cropperRef?.containerWidth ?? 0), // Percentage width
			});
		},
		downloadCropVideo() {
			window.open(this.cropVideoDownloadUrl, '_blank');
		},
		closeDownloadModal() {
			if (this.checkVideoStatusInterval) {
				clearInterval(this.checkVideoStatusInterval);
			}
			this.resetClipping();
			this.eventBus.$emit(`modalClose:${this.modalNameDownloadVideo}`);
		},
		showMaxDurationReachedInfo() {
			this.maxDurationReachedInfoVisible = true;
			setTimeout(() => {
				this.maxDurationReachedInfoVisible = false;
			}, 5000);
		},
		async getVideoCropStatus() {
			this.cropVideoStart = this.cropVideoStart || Date.now();

			const response = await fetch(this.cropVideoDataUrl);
			const data = await response.json();

			// For testing purposes
			if (new URLSearchParams(window.location.search).has('slow')) {
				this.cropVideoStatus = VIDEO_CROP_STATUS.PROCESSING;
			} else {
				this.cropVideoStatus = data.status;
			}

			// Keep checking crop until failed or processed
			if ([VIDEO_CROP_STATUS.AVAILABLE, VIDEO_CROP_STATUS.FAILED].includes(this.cropVideoStatus)) {
				clearInterval(this.checkVideoStatusInterval);
			}

			// Video takes longer to process thn expected
			const timeElapsed = Date.now() - this.cropVideoStart;
			if (timeElapsed > CROP_VIDEO_PROCESSING_OVERTIME) {
				this.internalUiStatus = UI_STATUS.VIDEO_CROP_OVERTIME;
			}
		},
		async createVideoCrop() {
			try {
				if (this.logo) {
					this.clipData.logoPosition = this.logoPosition;
					this.clipData.logoImageUrl = this.logo;
				}
				this.internalUiStatus = UI_STATUS.GENERATING_VIDEO_CROP;

				const headers = {
					'Content-Type': 'application/json',
				};

				if (!this.isCommunityCropping) {
					headers['Asport-User-Context-ID'] = document.head.querySelector('meta[name="asport-user-context-id"]').content;
				}

				const route = laroute.route(this.isCommunityCropping ? 'api.event.community_video_crop.create' : 'api.event.video_crop.create', { event: this.eventId });
				const response = await fetch(route, {
					method: 'POST',
					body:   JSON.stringify({ event: this.eventId, ...this.clipData }),
					headers,
				});

				const data = await response.json();

				if (!response.ok) {
					Sentry.captureMessage('MultiFormatTool: Cropping Backend Error', {
						level: 'fatal',
						tags:  {
							eventId: this.eventId,
							group:   'multi-format-tool',
						},
						extra: {
							errorDetails: data,
							timestamp:    new Date().toISOString(),
						},
					});

					// Throw an error if the response status is not ok (not in the range 200-299)
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				this.cropVideoId = data.id;
				this.cropVideoDataUrl = data.dataUrl;
				this.cropVideoStatus = data.status;
				this.cropVideoDownloadUrl = await getShortLink(data.downloadUrl, this.eventId, this.cropVideoId);

				if (this.cropVideoDataUrl) {
					this.checkVideoStatusInterval = setInterval(() => this.getVideoCropStatus(), API_POLLING_INTERVAL_MS);
				}
			} catch (error) {
				this.internalUiStatus = UI_STATUS.VIDEO_CROP_FAILURE;
				this.$toast.error(this.$I18n.trans('commons.something_went_wrong'));

				Sentry.captureMessage('MultiFormatTool: Cropping Frontend Error', {
					level: 'fatal',
					tags:  {
						eventId: this.eventId,
						group:   'multi-format-tool',
					},
					extra: {
						errorDetails: error,
						timestamp:    new Date().toISOString(),
					},
				});

				console.error(error);
			}
		},
		async storeEmailNotification() {
			this.emailNotificationSaved = false;
			this.emailNotificationError = false;
			try {
				const response = await fetch(laroute.route('api.event.video_crop.notify', { event: this.eventId, cropVideoId: this.cropVideoId }), {
					method:  'POST',
					body:    JSON.stringify({ email: this.email }),
					headers: {
						'Content-Type': 'application/json',
					},
				});

				const data = await response.json();
				if (data && data.status === 'ok') {
					this.emailNotificationSaved = true;
					this.internalUiStatus = UI_STATUS.EMAIL_NOTIFICATION_REQUESTED;

					if (this.checkVideoStatusInterval) {
						clearInterval(this.checkVideoStatusInterval);
					}
				} else {
					throw new Error('Unable to store email notification: ' + data);
				}
			} catch (e) {
				this.emailNotificationError = true;
			}
		},
		setMaxDuration(durationS) {
			const durationMs = durationS * 1000;
			this.maxClipDuration = Math.floor(Math.min(durationMs, CLIP_MAX_DURATION_MS) / 1000) * 1000;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

body { // stylelint-disable-line selector-max-type
	background-color: var(--color-black);
}

.multi-format-tool {
	position: relative;
	overflow-x: hidden;

	&.is-preview {
		overflow: hidden;
	}
}

.multi-format-tool__player-w {
	will-change: transform;
}

.multi-format-tool__header-info {
	@include font-size(fs-100);

	display: flex;
	flex-direction: column;
	gap: $sp2;
	width: 100%;
	padding-top: $sp3;
	padding-left: $sp5;
	list-style: auto;

	@include size(md-up) {
		@include font-size(fs-130);

		padding-left: $sp6;
	}
}

.multi-format-tool__header-button {
	@include mobile-landscape {
		transform: scale(0.8);
		transform-origin: top center;
	}
}

.multi-format-tool__controls-w {
	position: absolute;
	bottom: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;
	will-change: transform;
	padding-bottom: 5%;

	&::after {
		content: '';
		display: none;
		position: absolute;
		// offsetting border
		left: 3px;
		right: -3px;
		bottom: 3px;
		height: calc(100% - 6px);
		background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, rgba(var(--color-rgb-black), 0.8) 70%);
		pointer-events: none;
		z-index: -1;
	}

	&.is-ready,
	&.is-recording {
		&::after {
			display: block;
		}
	}

	@include mobile-portrait {
		pointer-events: none;
	}
}

.multi-format-tool__button-group {
	display: flex;
	gap: $sp4;

	> * {
		flex-grow: 1;
		flex-basis: 50%;
	}

	@include mobile-landscape {
		flex-wrap: wrap;
		gap: $sp3;
		padding: 0 $sp2;
	}
}

.multi-format-tool__controls {
	pointer-events: initial;
	gap: $sp4;
	display: flex;
	flex-direction: column;

	@include mobile-landscape {
		transform: scale(0.8);
		transform-origin: top center;
		gap: $sp3;
	}

	@include mobile-portrait {
		pointer-events: none;
	}
}

.multi-format-tool__ready-info {
	@include font-size(fs-120);

	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $sp5;
	pointer-events: none;
	text-align: center;
	white-space: nowrap;

	@include mobile-portrait {
		transform: scale(0.8);
	}
}

.multi-format-tool__max-duration-reached-info {
	@include font-size(fs-120);
	position: absolute;
	top: 3px;
	left: 3px;
	height: 25%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0.4) 60%, rgba(var(--color-rgb-black), 0) 100%);
}

.multi-format-tool__timer {
	@include font-size(fs-150);
	text-align: center;
	margin-top: $sp5;

	@include mobile-portrait {
		@include font-size(fs-100);
		display: flex;
		align-items: flex-end;
		position: absolute;
		bottom: 100%;
		margin-top: 0;
		height: 52px;
	}
}

.multi-format-tool__preview-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-black);
	mask-size: 100% 100%;
	mask-position: center;
	mask-repeat: no-repeat;
}

.multi-format-tool__header {
	position: absolute;
	top: 3px;
	height: 50%;
	padding: $sp5 $sp4;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0.7) 15%, rgba(var(--color-rgb-black), 0) 100%);
	pointer-events: none;
	z-index: 1;

	&.-preview {
		top: 0;
		left: 50%;
		transform: translateX(-50%);
	}

	::v-deep button { // stylelint-disable-line
		pointer-events: initial;
	}
}

.multi-format-tool__preview-infos {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	height: 30%;
	padding: $sp5;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, rgba(var(--color-rgb-black), 0.4) 85%);
}

.multi-format-tool__preview-infos-mobile-portrait {
	height: 52px;
	display: flex;
	justify-content: space-between;
	display: flex;
	align-items: center;
	position: relative;
	top: -12px;
}

// .multi-format-tool__preview-info-label {}

.multi-format-tool__preview-info-value {
	@include font-size(fs-150);
}

.multi-format-tool__in-progress-info {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: $sp5;
}

.multi-format-tool__preview-logo-container {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	right: 0;
	top: 0;
}

.multi-format-tool__preview-logo {
	height: auto;
	position: absolute;
	width: 100px;

	&.-position-top_left {
		top: 30px;
		left: 30px;
	}

	&.-position-top_right {
		top: 30px;
		right: 30px;
	}

	&.-position-bottom_left {
		bottom: 30px;
		left: 30px;
	}

	&.-position-bottom_right {
		bottom: 30px;
		right: 30px;
	}
}

.multi-format-tool__overtime-message {
	text-align: left;
}

.multi-format-tool__overtime-email {
	margin-top: $sp4;
	margin-bottom: 0;
}

.multi-format-tool__notification-message {
	display: flex;
	align-items: flex-start;
	flex-direction: row;
	text-align: left;

	.v2-icon {
		margin-right: $sp4;
	}
}

.multi-format-tool__copy-link {
	margin-top: $sp6;

	&::v-deep .v2-input-text {
		margin-bottom: 0;
	}
}

.multi-format-tool__mobile-potrait-w {
	position: relative;
	padding: $sp5;
}

.multi-format-tool__mobile-potrait-info {
	@include font-size(fs-150);
	margin-top: $sp5;
	display: flex;
	flex-direction: column;
	gap: $sp3;
	padding-left: $sp5;
	list-style: auto;
}

.multi-format__title {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
	padding-right: 24px;
	cursor: pointer;
	font-size: 24px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

	span { // stylelint-disable-line selector-max-type
		position: relative;

		&::after { // stylelint-disable-line selector-max-type
			content: 'beta';
			position: absolute;
			right: -5%;
			top: 84%;
			font-size: 64%; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
			color: var(--color-grey-200);
		}
	}

	@include mobile-landscape {
		display: none;
	}

	@include mobile-portrait {
		position: fixed;
		bottom: $sp6;
		right: $sp4;
		justify-content: center;
	}

	@include size(lg-up) {
		@include font(primary, book, normal, fs-150);

		margin-bottom: $sp6;

		span { // stylelint-disable-line selector-max-type
			&::after { // stylelint-disable-line selector-max-type
				top: 68%;
			}
		}
	}
}

.multi-format-tool__logo-settings-select {
	background-color: $form-color-selection-active--dark;

	@include mobile-portrait {
		background-color: transparent;
	}
}
</style>
