<template>
	<div>
		<div
			v-if="error"
			class="arena-login__hint -error -no-icon"
		>
			{{ $I18n.trans('arena.login.login.error') }}
		</div>
		<InputText
			ref="email"
			name="email"
			type="email"
			pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
			required
			:label="$I18n.trans('arena.login.login.email')"
			:placeholder="$I18n.trans('arena.login.login.email')"
			autocomplete="username"
			:dark-theme="!$arenaConfig.isLightTheme"
			:value="email"
			@input="(data) => $parent.email = data"
		/>
		<!-- eslint-disable vue/no-v-html -->
		<div
			v-if="didYouMean"
			class="arena-login__did-you-mean"
			@click="() => $parent.email = didYouMean"
			v-html="$I18n.trans('arena.login.login.did_you_mean', { word: didYouMean })"
		/>
		<div class="l-button-group">
			<Button
				class="arena-login__continue"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.send_link'))"
				:disabled="!($refs.email && $refs.email.isValid) || inProgress"
				:busy="inProgress"
				:theme="$arenaConfig.isLightTheme ? null : 'dark'"
				@click="submitForm"
			/>
			<Button
				class="arena-login__continue"
				modifier="secondary"
				:text="$Helpers.ucfirst($I18n.trans('arena.login.login.continue_password'))"
				:disabled="!($refs.email && $refs.email.isValid)"
				:busy="inProgress"
				:theme="$arenaConfig.isLightTheme ? null : 'dark'"
				@click="$parent.continueWithPassword()"
			/>
		</div>
		<div class="arena-login__more">
			<div class="arena-login__more--text">
				{{ $I18n.trans('arena.login.login.continue_with') }}
			</div>
		</div>
		<div class="arena-login__social-container">
			<!-- <a
				class="arena-login__social"
				@click="redirectSocial('facebook')"
			>
				<Button
					class="arena-login__social-btn -facebook"
					icon="logo-facebook"
					:text="$I18n.trans('arena.login.providers.facebook')"
					modifier="custom"
				/>
			</a> -->
			<a
				class="arena-login__social"
				@click="redirectSocial('google')"
			>
				<Button
					class="arena-login__social-btn -google"
					icon="logo-google"
					:text="$I18n.trans('arena.login.providers.google')"
					modifier="custom"
				/>
			</a>
		</div>
	</div>
</template>

<script>
import levenshtein from 'js-levenshtein';
import { authApi } from '@js/api/client';
import { mapMutations } from 'vuex';
import { LOGIN_MODES } from '../LoginComponent';
import Button from '@shared/components/Button';
import InputText from '@shared/components/Form/InputText';
import laroute from '@laroute';
import storage from '@shared/js/storage';

const TOKEN_MONITORING_DURATION_MINUTES = 10;

export default {
	name:       'EmailAndSocialLogin',
	components: { Button, InputText },
	props:      {
		redirectUrl: {
			type:    String,
			default: null,
		},
		email: {
			type:    String,
			default: '',
		},
	},
	data() {
		return {
			error:           null,
			inProgress:      false,
			password:        null,
			passwordConfirm: null,
		};
	},
	computed: {
		didYouMean() {
			const emailProviders = ['bluewin.ch', 'gmail.com'];
			if (this.email) {
				const username = this.email.split('@')[0];
				const domain = this.email.split('@')[1];
				const tld = domain && domain.split('.')[1];
				const matches = emailProviders.filter(p => {
					if (domain && tld && (tld.startsWith('co') ? tld.length > 2 : tld.length > 1)) {
						const difference = levenshtein(p, domain);
						return difference === 1;
					}
					return false;
				});

				if (matches.length > 0) {
					return `${username}@${matches[0]}`;
				}
			}
			return false;
		},
	},
	methods: {
		...mapMutations({
			setLoginRedirectUrl: 'user/setLoginRedirectUrl',
		}),
		async submitForm() {
			this.inProgress = true;
			this.error = null;

			try {
				const body = {
					email: this.email,
				};

				if (this.redirectUrl) {
					body.redirectUrl = this.redirectUrl;
				}

				if (storage.affilId) {
					body.affilId = storage.affilId;
				}

				const response = await authApi.post(
					laroute.route('arena.api.arena_login.submit'),
					body,
				);
				const responseContent = await response.json();

				if (responseContent.status === 'ok') {
					this.$emit('submitted', { mode: LOGIN_MODES.CHECK_EMAIL });
					this.$parent.monitorTokenVerification(responseContent.verificationToken, moment().add(TOKEN_MONITORING_DURATION_MINUTES, 'minutes'));
					this.error = false;
				} else {
					// Unknown error
					this.error = true;
				}
			} catch (error) {
				this.error = true;
				console.error(error);
			} finally {
				this.inProgress = false;
			}
		},
		redirectSocial(provider) {
			if (this.redirectUrl) {
				this.setLoginRedirectUrl(this.redirectUrl);
			}
			const params = storage.affilId ? '?affilId=' + storage.affilId : '';
			const redirectUrl = laroute.route('arena.login.social', { provider: provider }) + params;

			window.location.href = redirectUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.arena-login__did-you-mean {
	@include font(primary, light, italic, fs-120);
	margin-bottom: $sp4;

	::v-deep {
		span { // stylelint-disable-line selector-max-type
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.arena-login__more {
	margin: 50px 0;
	display: flex;
	justify-content: center;
	position: relative;

	&::after {
		display: block;
		content: '';
		border-bottom: 1px solid var(--color-grey-500);
		width: 100%;
		position: absolute;
		top: 50%;
	}
}

.arena-login__more--text {
	color: var(--color-grey-500);
	background-color: var(--color-grey-850);
	padding: 20px 40px;
	z-index: 1;

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}
}

.arena-login__social {
	margin: 8px 0;
	display: flex;
	justify-content: center;
}

.arena-login__social-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.arena-login__social-btn {
	width: 300px;
	border: none;

	&.-google {
		background-color: #fff; // stylelint-disable-line
		color: $color-shadow-grey;

		&:hover {
			background-color: #EBEBEB;	// stylelint-disable-line
		}

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-950);
			color: var(--color-grey-050);

			&:hover {
				background-color: var(--color-grey-900);
			}
		}
	}

	&.-facebook {
		background-color: #1877F2; // stylelint-disable-line
		color: #fff; // stylelint-disable-line

		&:hover {
			background-color: #216FDB;	// stylelint-disable-line
		}
	}

	&.-apple {
		background-color: #1C1C1E; // stylelint-disable-line
		color: #fff; // stylelint-disable-line

		&:hover {
			background-color: #3A3A3C;	// stylelint-disable-line
		}
	}
}
</style>
