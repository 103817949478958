<template>
	<div
		v-if="liveCountdown"
		class="countdown"
		:class="[`-${size}`]"
	>
		<div
			v-if="showDays && liveCountdown.days > 0 && !lessThan1Minute"
			class="countdown__el"
		>
			<span class="countdown__number">
				{{ liveCountdown.days }}
			</span>
			<span class="countdown__text">
				{{ size === 'sm' ? 'd' : $I18n.trans_choice('commons.day', liveCountdown.days) }}
			</span>
		</div>
		<div class="countdown__el">
			<span class="countdown__number">
				{{ liveCountdown.hours }}
			</span>
			<span class="countdown__text">
				{{ size === 'sm' ? 'h' : $I18n.trans_choice('commons.hour', liveCountdown.hours) }}
			</span>
		</div>
		<div class="countdown__el">
			<span class="countdown__number">
				{{ liveCountdown.minutes }}
			</span>
			<span class="countdown__text">
				{{ size === 'sm' ? 'min' : $I18n.trans_choice('commons.minute', liveCountdown.minutes) }}
			</span>
		</div>
		<div
			v-if="showSeconds || lessThan1Minute"
			class="countdown__el"
		>
			<span class="countdown__number">
				{{ liveCountdown.seconds }}
			</span>
			<span class="countdown__text">
				{{ size === 'sm' ? 's' : $I18n.trans_choice('commons.second', liveCountdown.seconds) }}
			</span>
		</div>
	</div>
</template>

<script>
/**
 * Attention: Don't use showDays = false if countdown is expected to be more than 1 day
 */

export default {
	name:  'Countdown',
	props: {
		seconds: {
			type:     Number,
			required: true,
		},
		showSeconds: {
			type:    Boolean,
			default: true,
		},
		showDays: {
			type:    Boolean,
			default: false,
		},
		size: {
			type:      String,
			default:   'md',
			validator: (size) => ['md', 'sm'].includes(size),
		},
	},
	data() {
		return {
			liveCountdown:   null,
			duration:        moment.duration(this.seconds * 1000, 'milliseconds'),
			interval:        1000,
			lessThan1Minute: false,
		};
	},
	watch: {
		seconds() {
			this.duration = moment.duration(this.seconds * 1000, 'milliseconds');
			this.updateCountdown();
		},
	},
	beforeMount() {
		this.updateCountdown();
	},
	mounted() {
		setInterval(this.updateCountdown, this.interval);
	},
	methods: {
		updateCountdown() {
			this.duration = moment.duration(this.duration - this.interval, 'milliseconds');
			this.lessThan1Minute = this.duration.asSeconds() < 60;

			if (this.duration._milliseconds < 0) {
				this.liveCountdown = {
					hours:   0,
					minutes: 0,
					seconds: 0,
				};
				return;
			}
			this.liveCountdown = {
				hours:   this.duration.hours(),
				minutes: this.duration.minutes(),
			};

			if (this.showSeconds || this.lessThan1Minute) {
				this.liveCountdown.seconds = this.duration.seconds();
			}

			if (this.showDays) {
				this.liveCountdown.days = Math.floor(this.duration.asDays());
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.countdown {
	display: flex;
	margin-bottom: 8px;
}

.countdown__el {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px;
	margin: 0 8px;
	width: 96px;
	height: 96px;
	background-color: var(--color-black);
	color: var(--color-soft-white);
	text-align: center;
	border-radius: 2px;
	font-size: 48px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	line-height: 120%;
	font-weight: 500; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}

	.countdown.-sm & {
		width: 48px;
		height: 48px;
		font-size: 16px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		font-weight: 600; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		margin: 0 4px;

		&:first-child {
			margin-left: 0;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.countdown__text {
	color: var(--color-grey-300);
	font-size: 14px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	line-height: 135%;
	font-weight: 400; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

	.countdown.-sm & {
		font-size: 12px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	}

	body.-t-light & {
		color: var(--color-grey-700);
	}
}
</style>
