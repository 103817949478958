import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import socketModule from './socket';
import eventsModule from './events';
import albumsModule from './albums';
import userModule from './user';
import notificationsModule from './notifications';

Vue.use(Vuex);
Vue.use(VueCookies);

const store = new Vuex.Store({
	modules: {
		socket:        socketModule,
		notifications: notificationsModule,
		albums:        albumsModule,
		events:        {
			...eventsModule,
			modules: {},
		},
		user: userModule,
	},
});

export default store;
