<template>
	<div
		class="nav-mobile-child"
		:class="{'child-is-open': openItemId !== null}"
	>
		<component
			:is="titleLink ? 'a' : 'div'"
			v-if="title"
			:href="titleLink"
			class="nav-mobile-child__title"
		>
			{{ title }}
			<V2Icon
				v-if="titleLink"
				icon-name="right-arrow"
				size="xs"
			/>
		</component>
		<component
			:is="item.link ? 'a' : 'div'"
			v-for="item in subNavItems"
			:key="item.link"
			:href="item.link"
			:target="mixinIsExternalLink(item.link) ? '_blank' : '_self'"
			:rel="mixinIsExternalLink(item.link) ? 'noopener' : ''"
			class="nav-mobile-child__item"
			:class="{'is-active': item.isActive}"
			@click="(e) => openChild(e, item)"
		>
			<span>{{ item.title }}</span>
			<V2Icon
				v-if="mixinIsExternalLink(item.link)"
				icon-name="launch"
				size="xxs"
			/>
			<V2Icon
				v-if="item.children && item.children.length > 0"
				icon-name="right-arrow"
				size="md"
			/>
			<NavMobileChild
				v-if="item.children && item.children.length > 0"
				ref="navMobileChild"
				class="-child"
				:class="{'is-open': item.id === openItemId}"
				:title="item.title"
				:title-link="item.link"
				:sub-nav-items="item.children"
				@open-child="(id) => $emit('open-child', id)"
			/>
		</component>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

export default {
	name:       'NavMobileChild',
	components: { V2Icon },
	props:      {
		subNavItems: {
			type:    Array,
			default: () => [],
		},
		title: {
			type:    String,
			default: null,
		},
		titleLink: {
			type:    String,
			default: null,
		},
	},
	data() {
		return {
			openItemId: null,
		};
	},
	mounted() {
		window.eventBus.$on('mobileNav:close-child', (data) => {
			if (data.id === this.openItemId) {
				this.openItemId = null;
			}
		});
	},
	methods: {
		openChild(e, item) {
			if (item.children && item.children.length > 0 && (this.openItemId !== item.id)) {
				this.openItemId = item.id;
				this.$emit('open-child', item.id);
				e.preventDefault();
				e.stopPropagation();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.nav-mobile-child {
	overflow-x: hidden;
	inset: 0;

	&.-child {
		position: fixed;
		left: 0;
		top: $sp7-5;
		width: 100%;
		height: calc(100vh - #{$sp7-5});
		transform: translateX(120%);
		transition: transform $trans-time-fast;
		background-color: var(--color-black);
		overflow-y: scroll;
		overscroll-behavior: contain;
		-webkit-overflow-scrolling: touch;
		padding-bottom: 10vh;

		.nav-mobile-child.-child {
			top: 0;
		}

		&.is-open {
			transform: translateX(0%);
		}

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-white);
		}
	}

	&.child-is-open {
		overflow-y: hidden;
	}
}

.nav-mobile-child__title {
	@include font(primary, bold, normal, fs-150);
	display: flex;
	align-items: center;
	gap: $sp3;
	margin-left: $sp4;
	margin-right: $sp4;
	margin-bottom: $sp4;
	padding: $sp4 0;
}

.nav-mobile-child__item {
	display: flex;
	justify-content: space-between;
	margin: 0 $sp4;
	padding: $sp4 0;
	font-size: 16px; // stylelint-disable-line property-disallowed-list, sh-waqar/declaration-use-variable

	&:not(:last-child) {
		border-bottom: 1px solid var(--color-grey-900);
	}
}
</style>
