<template>
	<div
		class="spinner-wrapper"
		:class="{ center }"
	>
		<img
			class="spinner-image"
			:src="`/images/loading-spinner-${theme}.gif`"
			:aria-label="$Helpers.ucfirst($I18n.trans('commons.loading')) || hint"
		>
	</div>
</template>

<script>
export default {
	name:  'LoadingSpinner',
	props: {
		theme: {
			type:      String,
			default:   'dark',
			validator: (value) => ['light', 'dark'].includes(value),
		},
		hint: {
			type:     String,
			required: false,
			default:  null,
		},
		center: {
			type: Boolean,
		},
	},
};
</script>

<style lang="scss" scoped>
.spinner-wrapper {
	width: 100%;
	height: 100%;

	&.center {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.spinner-image {
		width: 64px;
		height: 64px;
	}
}
</style>
