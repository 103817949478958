<template>
	<div
		ref="wrapper"
		class="timeline"
		:class="[
			{
				'-active-on-hover': showScrubberOnHover,
				'is-seekable': isSeekable,
			},
			`-${timelinePlacement}`,
		]"
		@click.stop="() => {}"
	>
		<!-- Higher timeline touch area for mobile and embedded mode -->
		<div
			class="timeline__timeline-box"
			@touchstart.prevent="
				handleEmptyChannelClick($event);
				handleTouchStart();
			"
			@mousedown.prevent="handleMouseDown"
		/>
		<div
			ref="main"
			:class="{ dragging: isCursorDragging }"
			class="timeline__empty-channel"
			@mousedown.prevent="handleMouseDown"
		>
			<div
				class="timeline__progress"
				:style="emptyProgressStyle"
			/>
			<!-- needle -->
			<div
				ref="needle"
				class="timeline__needle"
				:style="minimalNeedleStyle"
				@touchstart.stop.prevent="handleTouchStart"
			>
				<div class="timeline__grab-box" />
				<div
					ref="cursor"
					class="timeline__needle-cursor"
					:class="{ dragging: isCursorDragging }"
				/>

				<!-- timebox -->
				<div
					v-if="!disableTimebox"
					v-show="showTimebox"
					ref="timebox"
					class="timeline__timebox"
					:style="minimalTimeBoxStyle"
				>
					{{ timeboxLabel }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { throttle } from '@shared/utils/throttle';

export default {
	name:  'MinimalTimeline',
	props: {
		duration: {
			type:    Number,
			default: 0,
		},
		elapsed: {
			type:    Number,
			default: 0,
		},
		isPlaying: {
			type:    Boolean,
			default: false,
		},
		hideScrubber: {
			type:    Boolean,
			default: false,
		},
		showScrubberOnHover: {
			type:    Boolean,
			default: false,
		},
		hideScrubberPermanent: {
			type:    Boolean,
			default: false,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		disableTimebox: {
			type:    Boolean,
			default: false,
		},
		timelinePlacement: {
			type:      String,
			default:   'top',
			validator: (value) => ['top', 'bottom'].includes(value),
		},
		isSeekable: {
			type:    Boolean,
			default: true,
		},
	},
	data() {
		return {
			isMounted:            false,
			isCursorDragging:     false,
			cursorDraggingTimer:  null,
			wasPlayingBeforeSeek: false,
			resizeObserver:       null,
		};
	},
	computed: {
		showTimebox() {
			return this.isCursorDragging;
		},
		timeboxLabel() {
			const time = Number.isFinite(this.elapsed) ? this.elapsed : 0;

			return this.getTimeLabelFromSeconds(time);
		},
		emptyProgressStyle() {
			const [duration, elapsed] = [this.duration, this.elapsed];

			const width = this.computeHighlightPercentage({
				elapsed,
				duration,
			});

			return {
				width: `${width}%`,
			};
		},
		minimalNeedleStyle() {
			if (!this.isMounted) return {};
			if (!this.duration || !this.$refs.main || !this.$refs.cursor) {
				return { left: 0 };
			}

			const ref = this.$refs.main;
			const width = ref.offsetWidth;
			let left = (this.elapsed / this.duration) * width;

			const IGNORED_MARGIN = this.$refs.cursor.offsetWidth / 2; // After this margin we have a fixed position for the timebox
			if (left < IGNORED_MARGIN) {
				left = IGNORED_MARGIN;
			} else if (left > width - IGNORED_MARGIN) {
				left = width - IGNORED_MARGIN;
			}

			const stlyes = {
				transform: `translateX(${left}px)`,
			};

			if (!this.showScrubberOnHover) {
				stlyes.visibility = (!this.hideScrubber || this.isCursorDragging) && !this.hideScrubberPermanent ? 'visible' : 'hidden';
			}

			return stlyes;
		},
		minimalTimeBoxStyle() {
			if (!this.isCursorDragging) return {};
			if (!this.duration) return {};

			const ref = this.$refs.main;
			const width = ref.offsetWidth;

			let left = (this.elapsed / this.duration) * width;

			// Timebox moves after the needle so we have to handle the margins that get out from the interface
			const TIMEBOX_SIZE = this.$refs.timebox.offsetWidth; // handling for any timebox size
			const IGNORED_MARGIN = this.$refs.cursor.offsetWidth / 2; // After this margin we have a fixed position for the timebox
			const MARGIN_THRESHOLD = TIMEBOX_SIZE / 2 + IGNORED_MARGIN; // From here the timebox doesn't have to move with the needle

			// These formulas will make the timebox move smoothly on the margin of the timeline depending of needle position
			if (left <= MARGIN_THRESHOLD) {
				// handling left margin
				if (left <= IGNORED_MARGIN) {
					left = MARGIN_THRESHOLD - IGNORED_MARGIN;
				} else {
					left = MARGIN_THRESHOLD - left;
				}
			} else if (left >= width - MARGIN_THRESHOLD) {
				// handling right margin
				if (left >= width - IGNORED_MARGIN) {
					left = -1 * MARGIN_THRESHOLD + IGNORED_MARGIN;
				} else {
					left = -1 * MARGIN_THRESHOLD + width - left;
				}
			} else {
				// handling middle
				return {};
			}

			return {
				left: `${left}px`,
			};
		},
	},
	mounted() {
		this.isMounted = true;

		if (this.$refs.wrapper && window.ResizeObserver) {
			this.resizeObserver = new ResizeObserver(() => {
				this.forceRerender();
			});
			this.resizeObserver.observe(this.$refs.wrapper);
		}
	},
	beforeDestroy() {
		if (this.resizeObserver) {
			this.resizeObserver.unobserve(this.$refs.wrapper);
			this.resizeObserver = null;
		}
	},
	methods: {
		forceRerender() {
			this.$nextTick(() => {
				this.$raf('force-rerender', () => {
					this.$forceUpdate();
					this._computedWatchers.minimalNeedleStyle.run();
				});
			});
		},
		handleEmptyChannelClick(e) {
			if (!this.isSeekable) return;
			if (!this.$refs.main) return;
			let x = e.touches ? e.touches[0].clientX : e.clientX;
			const rect = this.$refs.main.getBoundingClientRect();
			const width = this.$refs.main.offsetWidth;
			x -= rect.x;
			if (x < 0) x = 0;
			const val = (x * 100) / width;
			this.handlePercentageSeek(val);
		},
		handlePercentageSeek(value) {
			const time = this.percentageToTime(value);

			throttle(() => {
				this.$emit('seek', time);
			}, 10)();
		},
		handleTouchStart() {
			if (!this.isSeekable) return;
			this.handleCursorDragStart();
			this.wasPlayingBeforeSeek = this.isPlaying; // new: new prop for isPlaying
			if (this.isPlaying) {
				this.$emit('pause');
			}

			document.addEventListener('touchmove', this.handleTouchMove);
			document.addEventListener('touchend', this.handleTouchEnd);
		},
		handleMouseDown(e) {
			if (!this.isSeekable) return;
			this.handleCursorDragStart();
			this.wasPlayingBeforeSeek = this.isPlaying; // new: new prop for isPlaying
			if (this.isPlaying) {
				this.$emit('pause');
			}
			document.addEventListener('mousemove', this.handleMouseMove);
			document.addEventListener('mouseup', this.handleMouseUp);
			document.addEventListener('touchmove', this.handleMouseMove);
			document.addEventListener('touchend', this.handleMouseUp);
			this.handleClick(e);
		},
		handleClick(event) {
			const ref = this.$refs.main;
			if (['tag', 'pill'].some((className) => event.target.closest(`.${className}`))) {
				return;
			}
			const sizes = event.touches ? [event.touches[0].clientX, event.touches[0].clientY] : [event.clientX, event.clientY];
			const [x] = sizes;
			const rect = ref.getBoundingClientRect();
			const width = ref.offsetWidth;

			this.handlePercentageSeek(this.getXRatio(x, rect.x, width, true));
		},
		handleMouseUp() {
			this.handleCursorDragEnd();
			if (this.wasPlayingBeforeSeek) {
				this.$emit('play');
			}
			document.removeEventListener('mousemove', this.handleMouseMove);
			document.removeEventListener('mouseup', this.handleMouseUp);
			document.removeEventListener('touchmove', this.handleMouseMove);
			document.removeEventListener('touchend', this.handleMouseUp);
		},
		handleMouseMove(e) {
			this.handleClick(e);
		},
		handleCursorDragStart() {
			if (this.cursorDraggingTimer) return;
			this.cursorDraggingTimer = setTimeout(() => {
				this.isCursorDragging = true;
				this.$emit('cursor-drag-start');
			}, 150);
		},
		handleTouchMove(e) {
			if (!this.$refs.main) return;
			let x = e.touches ? e.touches[0].clientX : e.clientX;
			const rect = this.$refs.main.getBoundingClientRect();
			const width = this.$refs.main.offsetWidth;

			// offset X
			x -= rect.x;
			if (x < 0) x = 0;

			const val = (x * 100) / width;

			this.handlePercentageSeek(val);
		},
		handleTouchEnd() {
			this.handleCursorDragEnd();
			if (!this.$refs.main) return;
			if (this.wasPlayingBeforeSeek) {
				this.$emit('play');
			}
			document.removeEventListener('touchmove', this.handleTouchMove);
			document.removeEventListener('touchend', this.handleTouchEnd);
		},
		handleCursorDragEnd() {
			if (this.isCursorDragging) {
				this.$emit('cursor-drag-end');
			}
			if (this.cursorDraggingTimer) {
				clearTimeout(this.cursorDraggingTimer);
				this.cursorDraggingTimer = null;
			}
			this.isCursorDragging = false;
		},
		getXRatio(clientX, containerX, containerWidth, asPercentage = true) {
			let x = clientX - containerX;
			// offset X
			if (x < 0) {
				x = 0;
			}
			const val = x / containerWidth;
			return asPercentage ? val * 100 : val;
		},

		getTimeLabelFromSeconds(value) {
			const minutes = Math.floor(Math.round(value) / 60);
			const seconds = Math.round(value) - minutes * 60;
			return [minutes, seconds].map(val => this.padZero(val, 2)).join(':');
		},
		percentageToTime(percentage) {
			const clampedValue = Math.min(100, Math.max(0, percentage));
			return (clampedValue / 100) * this.duration;
		},
		padZero(val, len) {
			let res = `${val}`;
			while (res.length < len) {
				res = `0${res}`;
			}
			return res;
		},
		computeHighlightPercentage({ elapsed = 0, duration = 0 }) {
			return (elapsed * 100) / duration;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

/* Main block */
// .timeline {}

/* Timeline box element */
.timeline__timeline-box {
	position: absolute;
	width: 100%;
	height: 24px;
	transform: translate(0, -50%);
	touch-action: none;

	.timeline.is-seekable & {
		cursor: pointer;
	}
}

/* Empty channel element */
.timeline__empty-channel {
	position: relative;
	height: 2px;
	background-color: rgba(var(--color-rgb-grey-300), 0.4);

	// &.dragging {}
}

/* Progress element */
.timeline__progress {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	background-color: var(--color-hype-yellow);
}

/* Needle element */
.timeline__needle {
	position: absolute;
	top: 0;
	height: 100%;
	pointer-events: none;
	visibility: hidden;

	/* State: when parent timeline has active-on-hover and is being hovered */
	.timeline.-active-on-hover:hover .timeline__empty-channel & {
		visibility: visible !important; // stylelint-disable-line declaration-no-important
	}
}

/* Needle cursor element */
.timeline__needle-cursor {
	position: absolute;
	top: 50%;
	width: 16px;
	height: 16px;
	transform: translate(-50%, -50%);
	border-radius: 100%;
	background-color: var(--color-hype-yellow);
	box-shadow: 0 9px 12px rgba(var(--color-rgb-black), 0.14), 0 3px 16px rgba(var(--color-rgb-black), 0.12), 0 5px 6px rgba(var(--color-rgb-black), 0.2); // stylelint-disable-line declaration-property-value-allowed-list
	transition: all 0.2s linear; // stylelint-disable-line declaration-property-value-disallowed-list
	pointer-events: auto;
	touch-action: none;

	&.dragging {
		width: 18px;
		height: 18px;
	}

	.timeline:hover & {
		width: 18px;
		height: 18px;
	}

	.timeline.is-seekable & {
		cursor: pointer;
	}
}

/* Grab box element */
.timeline__grab-box {
	position: absolute;
	top: 50%;
	width: 25px;
	height: 25px;
	transform: translate(-50%, -50%);

	.timeline.is-seekable & {
		cursor: pointer;
	}
}

/* Timebox element */
.timeline__timebox {
	position: absolute;
	top: auto;
	bottom: 35px;
	padding: 4px 8px;
	transform: translate(-50%, 50%);
	border-radius: 2px;
	background-color: var(--color-white);
	color: var(--color-shadow-grey);
	font-size: 14px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	opacity: 0;
	transition: opacity 0.25s linear;
	pointer-events: none;
	opacity: 1;
}
</style>
