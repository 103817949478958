<template>
	<div style="display: none;" />
</template>

<script>
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent/src/cookieconsent.js';

export default {
	name: 'CookieConsent',
	beforeMount() {
		document.body.classList.add('c_darkmode');
		document.body.classList.add('-t-cookie');
	},
	mounted() {
		const cookieConsent = window.initCookieConsent();
		cookieConsent.run({
			revision:          this.$arenaConfig.cookieConsentRevisionNr || 1,
			current_lang:      'en', // fallback
			autoclear_cookies: true, // default: false
			page_scripts:      true, // default: false
			languages:         {
				en: this.getCookieConsentConfig(),
			},
		});

		window.eventBus.$on('openCookieConsent', () => {
			cookieConsent.showSettings();
		});
	},
	methods: {
		getCookieConsentConfig() {
			const config = this.$I18n.trans('cookies.default_config');
			const hasGoogleAnalytics = !!this.$arenaConfig.googleAnalyticsId;
			if (hasGoogleAnalytics) {
				config.settings_modal.blocks.push(this.$I18n.trans('cookies.gooogle_analytics'));
			}

			return config;
		},
	},
};
</script>

<style lang="scss">
/* stylelint-disable */
.-t-cookie {
    --cc-bg: var(--color-grey-850);
    --cc-text: var(--color-white);
    --cc-btn-primary-bg: var(--color-hype-yellow);
    --cc-btn-primary-text: var(--color-black);
    --cc-btn-primary-hover-bg: var(--color-hype-yellow--active);
    --cc-btn-secondary-bg: var(--color-grey-950);
    --cc-btn-secondary-text: var(--cc-text);
    --cc-btn-secondary-hover-bg: var(--color-grey-900);
    --cc-toggle-bg-off: var(--color-grey-500);
    --cc-toggle-bg-on: var(--cc-btn-primary-bg);
    --cc-toggle-bg-readonly: var(--color-grey-700);
    --cc-toggle-knob-bg: var(--cc-cookie-category-block-bg);
    --cc-toggle-knob-icon-color: var(--cc-bg);
    --cc-cookie-category-block-bg: var(--color-grey-900);
    --cc-cookie-category-block-bg-hover: var(--color-grey-950);
    --cc-section-border: var(--color-grey-900);
    --cc-block-text: var(--color-grey-050);
    --cc-cookie-table-border: #2b3035;
    --cc-overlay-bg: rgba(4, 6, 8, .85);
    --cc-webkit-scrollbar-bg: var(--color-grey-200);
    --cc-webkit-scrollbar-bg-hover: var(--color-grey-050);

	&.-t-light {
		--cc-bg: var(--color-white);
		--cc-text: var(--color-black);
		--cc-btn-primary-text: var(--color-white);
	}
}

.-t-sportpass.-t-cookie {
	--cc-btn-primary-text: var(--color-text-on-hype-yellow);
}

.-t-cookie {
	.cc_div {
		font-family: inherit;
	}

	.cc_div a {
		color: var(--cc-btn-primary-bg);
	}

	#cc_div #s-hdr {
		border-bottom: none;
	}

	.cc_div #c-txt {
		color: var(--color-grey-300) !important;
	}
}

</style>
