<template>
	<div
		ref="likeButton"
		class="like-button"
		:class="[
			localIsLiked ? '-liked' : '',
			animate ? '-animate' : '',
			modifier ? '-' + modifier : '',
			'-size-' + size,
			{'-has-likes': localLikeCount > 0},
		]"
		@click.stop="handleClick"
	>
		<div class="like-button__icon">
			<svg
				v-for="index in 3"
				:key="index"
				viewBox="0 0 23 21"
				fill="none"
			>
				<path
					d="M20.743 2.25701C18.4 -0.0859941 14.601 -0.0859941 12.258 2.25701C11.969 2.54601 11.718 2.85701 11.501 3.18401C11.284 2.85701 11.032 2.54501 10.744 2.25701C8.40101 -0.0859941 4.602 -0.0859941 2.259 2.25701C-0.0839952 4.60001 -0.0839952 8.39901 2.259 10.742L11.5 19.985L20.743 10.742C23.086 8.39901 23.086 4.60001 20.743 2.25701Z"
					stroke="currentColor"
					stroke-miterlimit="10"
					stroke-linecap="square"
				/>
			</svg>
		</div>
		<span
			class="like-button__count"
			:class="{ '-liked': localIsLiked, '-has-likes': localLikeCount > 0 }"
		>
			{{ localLikeCount }}
		</span>
	</div>
</template>

<script>
export default {
	name:  'LikeButton',
	props: {
		likes: {
			type:     Number,
			required: true,
		},
		isLiked: {
			type:    Boolean,
			default: false,
		},
		modifier: {
			type:       String,
			default:    '',
			validators: [value => value === 'horizontal'],
		},
		size: {
			type:       String,
			default:    'lg',
			validators: [value => ['sm', 'md', 'lg'].includes(value)],
		},
		increaseCountOnLike: {
			type:    Boolean,
			default: true,
		},
	},
	data() {
		return {
			animate:        false,
			localLikeCount: this.likes,
			localIsLiked:   this.isLiked,
		};
	},
	watch: {
		likes(newVal) {
			this.localLikeCount = newVal;
		},
		isLiked(newVal) {
			this.localIsLiked = newVal;
		},
	},
	methods: {
		handleClick() {
			if (!this.isLiked && !this.localIsLiked) {
				this.animate = true;
				this.$emit('like');
				if (this.increaseCountOnLike) {
					this.localLikeCount += 1;
					this.localIsLiked = true;
				}

				this.$refs.likeButton.addEventListener('animationend', () => {
					this.animate = false;
				}, { once: true });
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.like-button {
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	cursor: pointer;
	color: var(--color-grey-300);

	&.-horizontal {
		flex-direction: row;
	}

	&.-liked {
		cursor: default;
		color: var(--color-hype-yellow);
		fill: var(--color-hype-yellow);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type
			color: transparent;
		}
	}
}

.like-button__count {
	opacity: 0;
	font-size: 10px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	margin-top: 1px;

	&.-liked {
		color: var(--color-hype-yellow);
	}

	&.-has-likes {
		opacity: 1;
	}

	.like-button.-horizontal & {
		display: none;
		margin-left: 4px;
		margin-top: 0;
	}

	.like-button.-horizontal.-has-likes & {
		display: block;
	}
}

.like-button__icon {
	position: relative;
	display: flex;
	align-items: center;
}

.like-button__icon svg { // stylelint-disable-line selector-max-type
	transition: fill 200ms ease, transform 200ms ease;
	color: var(--color-soft-white);
	position: absolute;
	left: 0;
	top: 0;
	opacity: 0;
	width: 16px;
	height: 16px;
	fill: transparent;
	transform: scale(1);

	.like-button.-size-md & { // stylelint-disable-line selector-max-type
		width: 20px;
		height: 20px;
	}

	.like-button.-size-lg & { // stylelint-disable-line selector-max-type
		width: 24px;
		height: 24px;
	}

	&:first-child { // stylelint-disable-line selector-max-type
		opacity: 1;
		position: relative;
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type
		color: var(--color-grey-700);
	}

	.like-button:hover:not(.-liked) & { // stylelint-disable-line selector-max-type
		fill: var(--color-grey-300);
		transform: scale(0.95);
	}

	.like-button.-liked & { // stylelint-disable-line selector-max-type
		color: var(--color-hype-yellow);
		fill: var(--color-hype-yellow);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-compound-selectors, selector-max-type
			color: transparent;
		}
	}

	&:nth-child(2) { // stylelint-disable-line selector-max-type
		.like-button.-animate & { // stylelint-disable-line selector-max-type
			animation: 1s ease 0s flyAwayLeft;
		}
	}

	&:nth-child(3) { // stylelint-disable-line selector-max-type
		.like-button.-animate & { // stylelint-disable-line selector-max-type
			animation: 1.1s ease 0s flyAwayRight;
		}
	}
}

@keyframes flyAwayLeft {
	0% {
		opacity: 1;
		fill: var(--color-hype-yellow);
		transform: translate(0, 0) rotate(-2deg) scale(1);
		scale: 1;
	}

	70% {
		transform: translate(-14px, -30px) rotate(-30deg) scale(0.8);
		scale: 0.5;
	}

	100% {
		opacity: 0;
		fill: var(--color-hype-yellow);
		transform: translate(-17px, -37px) rotate(-32deg) scale(0.7);
		scale: 0.3;
	}
}

@keyframes flyAwayRight {
	0% {
		opacity: 1;
		fill: var(--color-hype-yellow);
		transform: translate(0, 0) rotate(2deg) scale(1);
	}

	30% {
		transform: translate(5px, -13px)  rotate(35deg) scale(0.9);
	}

	100% {
		opacity: 0;
		fill: var(--color-hype-yellow);
		transform: translate(8px, -17px)  rotate(75deg) scale(0.8);
	}
}
</style>
