<template>
	<div
		class="event-infos"
		:class="{'-large': large, 'is-embed': isEmbed}"
	>
		<MatchScores
			v-if="showScores"
			class="event-infos__match-scores"
			:home-name="homeName"
			:home-score="homeScore"
			:home-img-url="homeImgUrl"
			:guest-name="guestName"
			:guest-score="guestScore"
			:guest-img-url="guestImgUrl"
			:score-extra-abbr="scoreExtraAbbr"
			:large="large"
		/>
		<div class="event-infos__text">
			<div
				v-if="preTitle"
				class="event-infos__line"
			>
				{{ preTitle }}
			</div>
			<!-- eslint-disable vue/no-v-html -->
			<div
				v-if="title"
				class="event-infos__line"
				:class="{'-large-title': largeTitle}"
				v-html="title"
			/>
			<div
				v-if="subTitle"
				class="event-infos__line"
			>
				{{ subTitle }}
			</div>
		</div>
	</div>
</template>

<script>
import MatchScores from './MatchScores';
import { MatchScoresPropsConfig } from './MatchScoresPropsConfig';

export default {
	name:       'EventInfos',
	components: { MatchScores },
	props:      {
		preTitle: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		subTitle: {
			type:    String,
			default: null,
		},
		large: {
			type:    Boolean,
			default: false,
		},
		largeTitle: {
			type:    Boolean,
			default: false,
		},
		showScores: {
			type:    Boolean,
			default: false,
		},
		isEmbed: {
			type:    Boolean,
			default: false,
		},
		...MatchScoresPropsConfig,
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.event-infos {
	display: flex;
}

.event-infos__match-scores {
	margin-right: $sp5;
	padding-right: $sp5;
	position: relative;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		width: 1px;
		height: 100%;
		background-color: var(--color-grey-700);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			background-color: var(--color-grey-700);
		}
	}

	.event-infos.-large & {
		@include size(md-up) {
			margin-right: 40px;
			padding-right: 40px;
		}
	}

	.event-infos.is-embed & {
		&::after {
			background-color: var(--color-grey-300);

			body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
				background-color: var(--color-grey-600);
			}
		}
	}
}

.event-infos__text {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: var(--color-grey-300);
	overflow: auto;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
		color: var(--color-grey-300);
	}

	.event-infos.-large & {
		color: var(--color-grey-100);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			color: var(--color-white);
		}
	}

	.event-infos.is-embed & {
		color: var(--color-grey-100);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			color: var(--color-white);
		}
	}
}

.event-infos__line {
	@include font(primary, book, normal, fs-60);

	&:not(:last-child) {
		margin-bottom: $sp1;
	}

	&.-large-title {
		@include font(primary, medium, normal, fs-100);
		color: var(--color-white);
		margin-bottom: $sp2;

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
			@include font(primary, bold, normal, fs-100);
			color: var(--color-black);
		}

		.event-infos.-large & {
			body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
				color: var(--color-white);
			}
		}

		.event-infos.is-embed & {
			body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
				color: var(--color-white);
			}
		}
	}

	.event-infos.-large &,
	&:not(.-large-title) {
		@include ellipsis;
		width: 100%;
	}

	.event-infos:not(.-large) & {
		&.-large-title {
			@include line-clamp(2);
		}
	}
}
</style>
