import { SET_STORE, SET_STORE_INTERVAL, SET_STORE_ITEMS, UPDATE_STORE_ITEM, CHANGE_ITEM_STORE } from './mutation-types';

export default {
	[SET_STORE](state, { storeId, data }) {
		state[storeId] = {
			...state[storeId],
			...data,
		};
	},
	[SET_STORE_INTERVAL](state, { storeId, intervalId }) {
		state[storeId].intervalId = intervalId;
	},
	[SET_STORE_ITEMS](state, { storeId, items }) {
		state[storeId].items = items;
	},
	[UPDATE_STORE_ITEM](state, { storeIds, item }) {
		storeIds.forEach(storeId => {
			const event = state[storeId].items.find(event => event.id === item.id);
			if (event) {
				const { scores, finalScore, ...eventData } = item;
				Object.assign(event, {
					...eventData,
					scores:     item.scores ?? event.scores,
					finalScore: item.finalScore ?? event.finalScore,
				});
			}
		});
	},
	[CHANGE_ITEM_STORE](state, { oldStoreId, newStoreIds, item }) {
		state[oldStoreId].items = state[oldStoreId].items.filter(event => event.id !== item.id);
		newStoreIds.forEach(newStoreId => {
			if (!state[newStoreId].items.find(event => event.id === item.id)) {
				state[newStoreId].items.unshift(item);
			}
		});
		eventBus.$emit('update-carousels');
	},
};
