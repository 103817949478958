<template>
	<div class="video-center">
		<Header
			:title="$I18n.trans('arena.video_center.title')"
			:filters="filters"
			@changeTeam="handleTeamChange"
			@changeSearch="handleSearch"
		/>
		<div
			v-if="filtersData.search.value"
			class="video-center__search-text"
		>
			{{ $I18n.trans('arena.match_center.results_for') }} "{{ filtersData.search.value }}"
		</div>
		<div
			v-if="noSearchResults"
			class="video-center__no-results"
		>
			{{ $I18n.trans('arena.match_center.no_results') }}
		</div>
		<div
			v-if="recommendedSectionEnabled"
			class="l-content-section video-center__recommendations"
		>
			<SectionTitleMatchCenter
				:title="$I18n.trans_choice('commons.recommendation', 2)"
				:is-loading="false"
				:show-results-counter="false"
			/>
			<Carousel
				v-if="events.recommended.items.length"
				:right-align-last-cell="false"
				:cells="events.recommended.items"
			>
				<template #cell="{cell}">
					<EventCard v-bind="EventCardProps(cell, {large: true, showEventTags: false})" />
				</template>
			</Carousel>
		</div>

		<EventsSection
			v-show="videoSectionVisible"
			:title="$I18n.trans_choice('commons.video', 2)"
			:pagination="events.videos.pagination"
			:handle-pagination-change="(e) => handlePaginationChange('videos', e)"
			:events="events.videos.items"
			:is-loading="events.videos.isLoading"
			:show-results-counter="filtersData.search.value !== '' && typeof events.videos.pagination.total === 'number'"
		>
			<Select
				v-if="events.videos.items.length > 0"
				class="video-center__select-order"
				:options="sortOrderVideos.options"
				:value="sortOrderVideos.selected"
				:searchable="false"
				:clearable="false"
				:dark-theme="!$arenaConfig.isLightTheme"
				open-direction="left"
				@change="handleSortOrderVideosChange"
			/>
		</EventsSection>
	</div>
</template>

<script>
import EventsSection from './EventsSection';
import Header from '@js/components/VideoCenter/Header';
import SectionTitleMatchCenter from './SectionTitleMatchCenter';
import Select from '@shared/components/Select';
import Carousel from '@js/components/Carousel';
import EventCard from '@shared/components/EventCard/EventCard';
import { EventCardProps } from '@shared/components/EventCard/EventCardProps';
import { EventFilter } from '@shared/enums/EventFilter';
import { EventOrder } from '@shared/enums/EventOrder';
import { EventType } from '@shared/enums/EventType';
import { debounce } from '@shared/utils/debounce';
import laroute from '@laroute';

const lists = {
	recommended: 'recommended',
	videos:      'videos',
};

export default {
	name:       'VideoCenter',
	components: { Header, Select, Carousel, EventsSection, SectionTitleMatchCenter, EventCard },
	props:      {
		teams: {
			type:     Array,
			required: true,
		},
		recommended: {
			type:    Array,
			default: () => [],
		},
		leagueId: {
			type:     Number,
			required: true,
		},
	},
	data() {
		return {
			filtersData: {
				team: {
					value:   'all',
					options: [
						{
							label: this.$I18n.trans('commons.all_types', { type: this.$I18n.trans_choice('commons.team', 2) }),
							value: 'all',
						},
						...this.teams.map(team => ({ label: team.club.name, value: team.id })),
					],
				},
				search: {
					value: '',
				},
			},
			sortOrderVideos: {
				options: [
					{
						label: this.$I18n.trans('commons.date'),
						value: 'date',
					},
					{
						label: this.$I18n.trans('commons.views'),
						value: 'views',
					},
				],
				selected: 'date',
			},
			events: {
				[lists.recommended]: {
					items: this.recommended,
				},
				[lists.videos]: {
					fetchController: new AbortController(),
					isLoading:       false,
					pagination:      this.getInitialPagination(12),
					items:           [],
				},
			},
			EventCardProps,
			debounceSearch: null,
		};
	},
	computed: {
		filters() {
			return [
				{
					type:         'select',
					id:           'team',
					label:        this.$I18n.trans_choice('commons.team', 1),
					defaultValue: 'all',
					emitEvent:    'changeTeam',
					...this.filtersData.team,
				},
				{
					type:         'search',
					id:           'search',
					defaultValue: '',
					emitEvent:    'changeSearch',
					...this.filtersData.search,
				},
			];
		},
		recommendedSectionEnabled() {
			return this.events.recommended.items.length > 0 &&
			this.filtersData.search.value === '' &&
			this.filtersData.team.value === 'all';
		},
		videoSectionVisible() {
			return !this.noSearchResults || this.events.videos.isLoading;
		},
		noSearchResults() {
			const noResultsOnVideos = this.events.videos.items.length === 0 && !this.events.videos.isLoading;
			return this.filtersData.search.value && noResultsOnVideos;
		},
	},
	created() {
		this.debounceSearch = debounce(this.getAllData, 300, false);
	},
	mounted() {
		this.getAllData();
	},
	methods: {
		handlePaginationChange(list, page) {
			this.events[list].pagination.currentPage = page;

			if (list === lists.videos) {
				this.getVideos();
			}
		},
		handleTeamChange(teamId) {
			this.filtersData.team.value = teamId;
			this.getAllData();
		},
		handleSearch(value) {
			this.filtersData.search.value = value;
			this.events.videos.pagination = this.getInitialPagination(12);
			this.debounceSearch();
		},
		handleSortOrderVideosChange(value) {
			this.sortOrderVideos.selected = value;
			this.events.videos.pagination.currentPage = 1;
			this.getVideos();
		},
		resetFilters(loadData = true) {
			this.filtersData.team.value = 'all';
			this.filtersData.search.value = '';
			this.events.videos.pagination.perPage = 12;
			if (loadData) {
				this.getAllData();
			}
		},
		getAllData() {
			this.getVideos();
		},
		async fetchData(searchParams, signal) {
			const headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest' });
			const response = await fetch(`${laroute.route('api.events.cached')}?${searchParams}`, { signal, headers });
			return await response.json();
		},
		async loadEventList(list) {
			try {
				if (this.events[list].isLoading) {
					this.events[list].fetchController.abort();
					this.events[list].fetchController = new AbortController();
				}
				this.events[list].isLoading = true;

				const searchParams = this.getSearchParams(list);
				const { signal } = this.events[list].fetchController;
				const data = await this.fetchData(searchParams, signal);
				this.events[list].items = data.data;
				this.events[list].pagination = data.meta;
				this.events[list].isLoading = false;
			} catch (error) {
				if (error.name === 'AbortError') {
					// nothing to do here
				} else {
					console.error(error);
				}
			}
		},
		getVideos() {
			this.loadEventList(lists.videos);
		},
		getSearchParams(list) {
			const searchParams = new URLSearchParams();

			// filter(s) parameter
			const filters = {
				[EventFilter.LEAGUE]: this.leagueId,
			};

			if (list === lists.videos) {
				filters[EventFilter.ARENA_AVAILABLE] = null;
				filters[EventFilter.CONTENT_TYPE] = [EventType.UPLOAD, EventType.CLIP];
			}
			if (this.filtersData.team.value !== 'all') {
				filters[EventFilter.TEAM] = this.filtersData.team.value;
			}
			if (this.filtersData.search.value) {
				filters[EventFilter.SEARCH] = this.filtersData.search.value;
			}
			searchParams.append('filters', JSON.stringify(filters));

			if (this.sortOrderVideos.selected === 'views') {
				searchParams.append('sortOrder', JSON.stringify({
					[EventOrder.VIEWS]: 'desc',
				}));
			} else {
				searchParams.append('sortOrder', JSON.stringify({
					[EventOrder.START]: 'desc',
				}));
			}

			// pagination parameters
			searchParams.append('page', JSON.stringify(this.events[list].pagination.currentPage));
			searchParams.append('perPage', JSON.stringify(this.events[list].pagination.perPage));

			return searchParams;
		},
		getInitialPagination(perPage) {
			return {
				perPage:     perPage,
				currentPage: 1,
			};
		},

	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.video-center {
	padding-top: $sp6;
}

.video-center__select-order {
	margin-bottom: $sp5;
	width: 135px;

	@include size(md-up) {
		width: 205px;
	}
}

.video-center__search-text {
	@include font(primary, light, normal, fs-180);
	margin-bottom: $sp7;
}

.video-center__no-results {
	@include font(primary, light, normal, fs-150);
	color: var(--color-grey-300);
}

.video-center__recommendations {
	position: relative;
}
</style>
