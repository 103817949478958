<template>
	<a
		:href="link"
		class="l-carousel-wrapper-sports card-sports"
		:class="{'has-image': isAlbum}"
	>
		<div class="card-sports__inner">
			<template v-if="isAlbum">
				<img
					v-if="isPng"
					class="card-sports__img -png"
					:src="imageUrl"
				>
				<svg
					v-else
					class="card-sports__img"
				>
					<use :href="imageUrl" />
				</svg>
			</template>
			<h4
				class="card-sports__title"
				v-text="item.name"
			/>
		</div>
	</a>
</template>

<script>
import laroute from '@laroute';

export default {
	name:  'SportsCardSmall',
	props: {
		item: {
			type:     Object,
			required: true,
		},
		isAlbum: {
			type:    Boolean,
			default: false,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
	},
	data() {
		return {
			laroute,
			link: this.isAlbum
				? this.item.link ?? (laroute.route('arena.album', { album: this.item.id, slug: $Helpers.slugify(this.item.name) }))
				: laroute.route('arena.team', { team: this.item.id, slug: $Helpers.slugify(this.item.name) }),
		};
	},
	computed: {
		imageUrl() {
			return this.item.imgUrl ? this.item.imgUrl : 'images/sport-icons/fallback.svg#icon';
		},
		isPng() {
			return this.imageUrl.includes('.png');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-sports {
	display: inline-block;
}

.card-sports__inner {
	background-color: var(--color-grey-900);
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	padding: $sp4;
	border: 1px solid var(--color-grey-800);
	text-align: center;
	cursor: pointer;
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;

	// remove next 3 lines when image is back
	display: flex;
	justify-content: center;
	align-items: center;

	.is-keynav-section-active .is-selected & {
		transform: scale(1.05);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}

	.card-sports.has-image & {
		display: flex;
		flex-direction: column;
		gap: $sp3;
	}

	body.-t-light & { // stylelint-disable-line
		background-color: var(--color-grey-950);
		border-color: var(--color-grey-950);
	}

	&:focus,
	&:hover {
		transform: scale(1.05);
	}
}

.card-sports__img {
	width: 72px;
	height: 72px;
	object-fit: contain;

	&.-png {
		margin: -15px 0;
	}
}

.card-sports__title {
	@include font-size(fs-150);

	.card-sports.has-image & {
		@include font-size(fs-80);
	}
}
</style>
