<template>
	<div class="settings">
		<LoadingSpinner
			v-if="!profile"
			class="settings__loading-spinner"
		/>
		<template v-else>
			<template v-if="$arenaConfig.enableNoSpoilerMode">
				<SectionTitle
					:title="$I18n.trans('arena.fan_account.settings.no_spoiler_mode.label')"
					type="md"
				/>
				<InputToggle
					:text="$I18n.trans('arena.fan_account.settings.no_spoiler_mode.description')"
					:value="noSpoilerMode"
					:busy="isProcessing"
					:dark-theme="!$arenaConfig.isLightTheme"
					@input="toggleNoSpoilerMode"
				/>
			</template>
		</template>
	</div>
</template>

<script>
import LoadingSpinner from '@shared/components/LoadingSpinner';
import InputToggle from '@shared/components/Form/InputToggle';
import SectionTitle from '@js/components/SectionTitle';
import { mapState, mapActions } from 'vuex';
import { actions } from '@legacy/js/store/user';

export default {
	name:       'Settings',
	components: { LoadingSpinner, SectionTitle, InputToggle },
	data() {
		return {
			isProcessing: false,
		};
	},
	computed: {
		...mapState({
			profile:     state => state.user.profile,
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
	},
	methods: {
		...mapActions({
			updateProfile: `user/${actions.UPDATE_PROFILE}`,
		}),
		async toggleNoSpoilerMode(value) {
			try {
				this.isProcessing = true;
				await this.updateProfile({
					preferences: { noSpoilerMode: value },
				});
				this.$toast.success($I18n.trans('notifications.profile_updated'));
			} catch (e) {
				console.error(e);
				this.$toast.error(this.$I18n.trans('errors.500.title'));
			} finally {
				this.isProcessing = false;
			}
		},
	},
};
</script>

<style>

</style>
