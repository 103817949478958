<template>
	<Layout>
		<Infos />
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Infos from '@js/components/SFL/Infos';

export default {
	name:       'ViewSflInfos',
	components: { Layout, Infos },
};
</script>

<style>

</style>
