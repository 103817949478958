const groupDigits = (numberString) => {
	const reversed = numberString.split('').reverse();
	let counter = 1;
	const formatted = [];
	reversed.forEach((digit, i) => {
		formatted.push(digit);
		if (counter === 3 && reversed.length > (i + 1)) {
			formatted.push('\'');
			counter = 1;
		} else {
			counter += 1;
		}
	});
	return formatted.reverse().join('');
};

/**
 * Converts number to better readable string
 * Grouping keeps two decimals, short none
 * @param {*} number
 * @param {*} type
 * @returns {String}
 */
export const formatNumber = (number, type = 'grouping') => {
	if (!['grouping', 'short'].includes(type)) {
		console.error(`Invalid type ${type} provided, valid are "grouping" or "short".`);
		return number;
	}

	if (type === 'grouping') {
		let convertedNumber = number;

		if ((number % 1) !== 0) {
			convertedNumber = (Math.round(number * 100) / 100).toFixed(2);
		}

		if (convertedNumber < 10000) {
			return `${convertedNumber}`;
		}

		if (`${convertedNumber}`.includes('.')) {
			const splitted = `${convertedNumber}`.split('.');
			const fulls = splitted[0];
			const decimals = splitted[1];
			return `${groupDigits(fulls)}.${decimals}`;
		}

		return groupDigits(`${convertedNumber}`);
	}

	if (type === 'short') {
		let convertedNumber = number;

		if ((number % 1) !== 0) {
			convertedNumber = Math.round(number);
		}

		if (convertedNumber < 1000) {
			return `${convertedNumber}`;
		}

		if (convertedNumber < 1000000) {
			return (Math.floor(convertedNumber / 100) / 10).toFixed(1).replace('.0', '') + 'K';
		}

		return (Math.floor(convertedNumber / 100000) / 10).toFixed(1).replace('.0', '') + 'M';
	}
};

export const formatPercentage = (number) => {
	if (isNaN(number)) {
		return '';
	}
	return Math.round(number * 100) + '%';
};

export const formatCurrency = (currency, amount) => {
	return currency + ' ' + (amount / 100).toFixed(2);
};
