<template>
	<Layout>
		<div class="l-container">
			<Album v-bind="AlbumProps(album)" />
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Album from '@js/components/Album';
import { AlbumProps } from '@js/utils/AlbumProps';

export default {
	name:       'ViewAlbum',
	components: { Layout, Album },
	props:      {
		album: {
			type:     Object,
			required: true,
		},
	},
	data() {
		return {
			AlbumProps,
		};
	},
};
</script>

<style>

</style>
