<template>
	<a
		:href="link"
		class="l-carousel-wrapper-league card-league"
	>
		<div
			class="card-league__inner"
			:class="{'with-background' : backgroundStyle}"
			:style="backgroundStyle"
		>
			<div class="card-league__logo-w">
				<div class="card-league__logo">
					<Icon
						v-if="sportIconName"
						:icon-name="sportIconName"
						size="xxxl"
					/>
					<img
						v-else-if="imageUrl"
						:alt="item.name"
						:src="imageUrl"
					>
				</div>
				<h4
					class="card-league__title"
					v-text="item.name"
				/>
			</div>
		</div>
	</a>
</template>

<script>
import Icon from '@shared/components/Icon';
import laroute from '@laroute';

const sportIcons = [
	'wheelchair-rugby',
	'wheelchair-basketball',
];

export default {
	name:       'LeagueCard',
	components: { Icon },
	props:      {
		item: {
			type:     Object,
			required: true,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
	},
	computed: {
		backgroundImageUrl: function() {
			return this.$Helpers && !(this.item?.backgroundImageUrl ?? '').includes('background_auth')
				? this.$Helpers.getScaledImageUrl(this.item.backgroundImageUrl, 480)
				: null;
		},
		backgroundStyle: function() {
			if (this.backgroundImageUrl) {
				return {
					backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.75) 100%),url(${this.backgroundImageUrl})`,
					backgroundSize:  'cover',
				};
			}
			return null;
		},
		imageUrl: function() {
			return this.$Helpers && !(this.item?.imageUrl ?? '').includes('background_auth')
				? this.$Helpers.getScaledImageUrl(this.item.imageUrl, 180)
				: null;
		},
		link: function() {
			return laroute.route('arena.league', { league: this.item.id, slug: $Helpers.slugify(this.item.name) });
		},
		sportIconName: function() {
			return this.$arenaConfig.showLeaguesAsSport ? sportIcons.find(icon => icon === (this.item?.sport?.name?.replace('_', '-') ?? null)) : null;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-league__inner {
	position: relative;
	background-color: var(--color-grey-900);
	border-radius: $border-radius;
	border: 1px solid var(--color-grey-800);
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform $trans-time-fast;
	will-change: transform;
	cursor: pointer;
	background-size: cover;

	&.with-background {
		@include gradient-animation(
			linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.75) 100%),
			linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 100%),
			$trans-time-fast
		);
	}

	.is-keynav-section-active .is-selected & {
		transform: scale(1.05);
		background-color: var(--color-grey-850);
		border: 3px solid var(--color-hype-yellow);
		border-radius: 3px;
	}

	&:focus,
	&:hover {
		transform: scale(1.05);
	}
}

.card-league__logo-w {
	position: absolute;
	bottom: 38px;
	text-align: center;
}

.card-league__logo {
	opacity: 0;
	transition: opacity $trans-time-fast;
	margin-left: auto;
	margin-right: auto;
	width: 64px;

	.card-league__inner:hover & {
		opacity: 1;
	}
}

.card-league__title {
	@include font(primary, medium, normal, fs-150);

	margin-top: $sp4;
}
</style>
