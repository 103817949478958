import laroute from '@laroute';
import { SeasonStageRound } from '@shared/enums/SeasonStageRound';
import { SeasonStageType } from '@shared/enums/SeasonStageType';
import { getMatchScores } from '@shared/utils/eventUtils';
import { SportName } from '@shared/enums/SportName';

const getSeasonStageRoundInfo = (event) => {
	if (event.seasonStage?.type === SeasonStageType.REGULAR_SEASON || !event.seasonStage) {
		return null;
	}
	return {
		advanced: ![SeasonStageRound.PLAYOUTS, SeasonStageRound.RELEGATION].includes(event.seasonStage.round),
		label:    $Helpers.getTranslatedContent(event.seasonStage.name),
	};
};

/**
 *
 * @param {*} event
 * @param {boolean} large
 * @returns {object} All necessary props for the EventCard component
 */
export const MatchListItemProps = (event, _options = {}) => {
	const options = {
		hideSeasonStageRoundInfo: false,
		..._options,
	};

	return {
		unixStart:   event.startTimestamp,
		unixKickoff: event.startTimestamp + (event.kickoffOffsetMinutes * 60),
		unixEnd:     event.endTimestamp,
		eventType:   event.type,
		link:        laroute.route('arena.event', {
			event: event.id,
			slug:  $Helpers.slugify(event.nameTranslations?.[window.$arenaConfig.defaultLanguage] || event.name),
		}),
		couchModeAvailable:   event.couchmodeAvailable,
		seasonStageRoundInfo: options.hideSeasonStageRoundInfo ? null : getSeasonStageRoundInfo(event),
		numberOfPeriods:      getMatchScores(event).scores?.length || 0,
		showSets:             event.sport.name === SportName.VOLLEYBALL,
		...getMatchScores(event),
	};
};
