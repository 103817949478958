<template>
	<div
		class="v2-input-radio"
		:class="{
			'-inline': inline,
			'is-disabled': disabled,
			'-t-dark': darkTheme,
			'-stacked': stacked
		}"
	>
		<V2InputLabel
			v-if="label"
			:text="label"
			:info="info"
			:required="required"
			:dark-theme="darkTheme"
		/>
		<div
			class="v2-input-radio__group-w"
		>
			<div
				v-for="(option, i) in options"
				:key="i"
				class="v2-input-radio__input-w"
			>
				<input
					:id="name + '-' + i"
					v-model="currentSelected"
					:value="option.value"
					class="v2-input-radio__input"
					type="radio"
					:name="name"
					:disabled="disabled"
					:required="required"
					@change="change"
				>
				<label
					:for="name + '-' + i"
					class="v2-input-radio__label"
					:class="{
						'-t-dark': darkTheme,
					}"
				>{{ option.label }}</label>
			</div>
		</div>
	</div>
</template>

<script>
import V2InputLabel from '@shared/components/Form/InputLabel';

export default {
	name:       'InputRadio',
	components: { V2InputLabel },
	props:      {
		label: {
			type:    String,
			default: null,
		},
		name: {
			type:     String,
			required: true,
		},
		info: {
			type:    String,
			default: null,
		},
		options: {
			type:     Array,
			required: true,
			validator(options) {
				return options.every(option => Object.prototype.hasOwnProperty.call(option, 'label') && Object.prototype.hasOwnProperty.call(option, 'value'));
			},
		},
		selected: {
			type:    [Object, String, Number, Boolean],
			default: null,
		},
		required: {
			type:    Boolean,
			default: false,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		inline: {
			type:    Boolean,
			default: false,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		stacked: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			currentSelected: this.selected && typeof this.selected === 'object' ? this.selected.value : (this.selected || null),
		};
	},
	methods: {
		change() {
			if (this.disabled) {
				return;
			};
			this.$emit('change', this.currentSelected);
		},
	},

};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

$toggle-width: 58px;
$toggle-height: 28px;

.v2-input-radio {
	margin-bottom: $form-margin-bottom;

	&.-inline {
		display: flex;
		align-items: center;
	}
}

.v2-input-radio__group-w {
	display: flex;

	.v2-input-radio.-stacked & {
		display: block;
	}

	.v2-input-radio.-inline & {
		margin-bottom: $form-label-margin;
		margin-left: $sp4;
	}
}

.v2-input-radio__input-w {
	&:not(:last-child) {
		margin-right: $sp5;

		.v2-input-radio.-stacked & {
			margin-bottom: $sp4;
		}
	}
}

.v2-input-radio__input {
	position: absolute;
	opacity: 0;
}

.v2-input-radio__label {
	$circle-size: 20px;
	$select-circle-size: 10px;

	display: flex;
	position: relative;
	padding-left: $sp6;
	user-select: none;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: $circle-size;
		height: $circle-size;
		border-radius: 50%;
		border: 2px solid var(--color-shadow-grey);
		background-color: var(--color-white);
		transform: translateY(-50%);
	}

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: calc(($circle-size - $select-circle-size) / 2);
		top: 50%;
		width: $select-circle-size;
		height: $select-circle-size;
		border-radius: 50%;
		background-color: var(--color-shadow-grey);
		transform: scale(0) translateY(-50%);
		transform-origin: top center;
		transition: transform $trans-time-fast;
	}

	&.-t-dark::before {
		border: 1px solid var(--color-white);
		background-color: transparent;
	}

	&.-t-dark::after {
		background-color: var(--color-hype-yellow);
	}

	.v2-input-radio__input:checked + & {
		&::after {
			transform: scale(1) translateY(-50%);
		}
	}

	.v2-input-radio__input:checked + & {
		&.-t-dark::before {
			border: 1px solid var(--color-hype-yellow);
		}
	}

	.v2-input-radio.is-disabled & {
		pointer-events: none;
		color: $form-color-input-text-disabled;

		&::before {
			border-color: $form-color-input-text-disabled;
		}

		&::after {
			background-color: $form-color-input-text-disabled;
		}
	}

	.v2-input-radio.-t-dark & {
		color: var(--color-white);
	}

	&:hover {
		cursor: pointer;

		&::after {
			transform: scale(0.5) translateY(-50%);
		}
	}
}
</style>
