<template>
	<div class="sl-infos">
		<header
			class="sl-infos__header"
			:style="{backgroundImage: 'url(/images/sfl/infos/header.jpg)'}"
		>
			<div
				class="sl-infos__header-text"
			>
				<div class="l-container">
					<SectionTitle
						:title="$I18n.trans('sfl_infos.header_title')"
						type="main"
					/>
				</div>
			</div>
		</header>

		<div class="sl-infos__section -color-1">
			<div class="l-container">
				<div class="l-row">
					<div class="l-col l-col--12 l-col--5@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('sfl_infos.section1.title1')"
								type="lg"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm -simple-table"
								v-html="$I18n.trans('sfl_infos.section1.copy1')"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md l-offset--1@md">
						<div class="sl-infos__section-inner-w -img">
							<img src="/images/sfl/infos/mockup.png">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="sl-infos__section -color-3">
			<div class="l-container">
				<div class="l-row l-flex-row-reverse@md">
					<div class="l-col l-col--12 l-col--6@md">
						<div class="sl-infos__section-inner-w">
							<SectionTitle
								:title="$I18n.trans('sfl_infos.section2.title1')"
								type="lg"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<div
								class="s-html -sm"
								v-html="$I18n.trans('sfl_infos.section2.copy1')"
							/>
							<Button
								:text="$I18n.trans('sfl_infos.section2.button.text')"
								theme="dark"
								:link="$I18n.trans('sfl_infos.section2.button.url')"
								:is-external-link="true"
							/>
						</div>
					</div>
					<div class="l-col l-col--12 l-col--6@md">
						<div class="sl-infos__section-inner-w -img">
							<img src="/images/sfl/infos/sledger.png">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import Button from '@shared/components/Button';

export default {
	name:       'Infos',
	components: { SectionTitle, Button },
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.sl-infos {
	line-height: 140%;
	position: relative;
}

.sl-infos__header {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 377px;
	width: 100%;
	background-size: cover;
	background-position: center;

	&::before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(180deg, rgba(var(--color-rgb-black), 0) 0%, var(--color-black) 100%);
		opacity: 0.8;

		@include size(md-up) {
			height: 85%;
		}
	}

	@include size(md-up) {
		height: 520px;
	}

	@supports (aspect-ratio: 4/3) {
		height: auto;
		aspect-ratio: 4/3;

		@include size(sm-up) {
			aspect-ratio: 16/9;
		}

		@include size(md-up) {
			height: auto;
			aspect-ratio: 16/7;
		}

		@include size(lg-up) {
			aspect-ratio: 36/13;
		}
	}
}

.sl-infos__header-text {
	position: absolute;
	bottom: $sp6;

	@include size(sm-up) {
		bottom: $sp7;
	}

	@include size(md-up) {
		bottom: $sp9;
	}
}

.sl-infos__section {
	&.-color-1 {
		background-color: var(--color-primary);
	}

	&.-color-2 {
		background-color: var(--color-grey-900);
	}

	&.-color-3 {
		background-color: var(--color-grey-800);
	}
}

.sl-infos__section-inner-w {
	padding-bottom: $sp6;

	&:not(.-img) {
		padding-top: $sp6;

		@include size(md-up) {
			padding-top: $sp8-5;
		}
	}

	@include size(md-up) {
		padding-top: $sp8-5;
		padding-bottom: $sp8-5;
	}
}
</style>
