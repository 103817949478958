import laroute from '@laroute';

export const getShortLink = async(downloadUrl, eventId, cropVideoId) => {
	try {
		const url = new URL(downloadUrl);
		// Extract the app, hash and expires query parameters
		const app = url.searchParams.get('app');
		const hash = url.searchParams.get('hash');
		const expires = url.searchParams.get('expires');

		const shortlinkResponse = await fetch(laroute.route('api.event.video_crop.shortlink', { event: eventId, cropVideoId: cropVideoId }), {
			method:  'POST',
			body:    JSON.stringify({ app, hash, expires }),
			headers: {
				'Content-Type': 'application/json',
			},
		});

		const shortlink = await shortlinkResponse.json();
		return shortlink.url;
	} catch (e) {
		console.error('Unable to get shortlink: ' + e);
	}

	// If generating a shortlink fails, fall back to the default url
	return downloadUrl;
};
