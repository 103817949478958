<template>
	<div
		ref="modal"
		class="v2-modal"
		:class="[{
				'is-visible': modalActive,
				'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
				'-dialog-md': type === 'dialog-md',
				'-dialog-lg': type === 'dialog-lg',
				'-frameless': type === 'frameless',
			},
			theme ? `-t-${theme}` : '',
		]"
	>
		<div
			ref="modalContentWrapper"
			class="v2-modal__content-w"
			:class="{
				'is-visible': modalActive,
				'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
				'-dialog-md': type === 'dialog-md',
				'-dialog-lg': type === 'dialog-lg',
				'-frameless': type === 'frameless'
			}"
		>
			<template v-if="renderOnlyWhenActive ? modalActive : true">
				<span
					v-if="closeable"
					class="v2-modal__close"
					:class="{
						'is-visible': modalActive,
						'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
						'-dialog-md': type === 'dialog-md',
						'-dialog-lg': type === 'dialog-lg',
						'-frameless': type === 'frameless'
					}"
					@click.prevent="closeModal()"
				>
					<V2Icon
						icon-name="e-remove"
						size="lg"
					/>
				</span>
				<div
					v-if="$slots.title"
					class="v2-modal__header"
					:class="{
						'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
					}"
				>
					<h2
						class="v2-modal__title"
						:class="{
							'is-visible': modalActive,
							'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
							'-dialog-md': type === 'dialog-md',
							'-dialog-lg': type === 'dialog-lg',
							'-frameless': type === 'frameless'
						}"
					>
						<slot
							name="title"
							:passed-data="passedData"
						/>
					</h2>
				</div>
				<div
					class="v2-modal__content"
					:class="{
						'is-visible': modalActive,
						'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
						'-dialog-md': type === 'dialog-md',
						'-dialog-lg': type === 'dialog-lg',
						'-frameless': type === 'frameless'
					}"
				>
					<slot
						name="content"
						:passed-data="passedData"
					/>
				</div>
				<div
					v-if="$slots.footer"
					ref="modalFooter"
					class="v2-modal__footer"
					:class="{
						'is-visible': modalActive,
						'-dialog': type === 'dialog' || type === 'dialog-md' || type === 'dialog-lg',
						'-dialog-md': type === 'dialog-md',
						'-dialog-lg': type === 'dialog-lg',
						'-frameless': type === 'frameless'
					}"
				>
					<slot
						name="footer"
						:passed-data="passedData"
					/>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

const themes = [
	'dark',
];

export default {
	name:       'V2Modal',
	components: { V2Icon },
	props:      {
		modalName: {
			type:     String,
			required: true,
		},
		confirmModal: {
			type:    String,
			default: null,
		},
		type: {
			type:      String,
			default:   null,
			validator: type => ['dialog', 'frameless', 'dialog-md', 'dialog-lg'].includes(type),
		},
		secondaryModal: {
			type:     Boolean,
			required: false,
			default:  false,
		},
		theme: {
			type:    String,
			default: null,
			validator(theme) {
				return themes.includes(theme);
			},
		},
		closeable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
		renderOnlyWhenActive: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			modalActive:    false,
			initialised:    false,
			resolvePromise: null,
			rejectPromise:  null,
			passedData:     null,
		};
	},
	mounted() {
		window.eventBus.$on(`modal:${this.modalName}`, this.handleOpen);
		window.eventBus.$on(`modalClose:${this.modalName}`, this.handleClose);
		this.$refs.modal.addEventListener('click', this.handleModalClick);
		// Emitting an event after the modal opening animation has finished
		this.$refs.modalContentWrapper.addEventListener('transitionend', this.handleTransitionEnd);
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.handleKeyDown);
		this.$refs.modal.removeEventListener('click', this.handleModalClick);
		this.$refs.modalContentWrapper.removeEventListener('transitionend', this.handleTransitionEnd);
		window.openModals = window.openModals.filter(modalName => modalName !== this.modalName);
		window.eventBus.$off(`modal:${this.modalName}`);
		window.eventBus.$off(`modalClose:${this.modalName}`);
	},
	methods: {
		showModal() {
			// Modal is set to display none in order to prevent forms inside modal to trigger
			// autocompletion. For the opening animation to work properly, we first need to display
			// the modal.
			this.$refs.modalContentWrapper.style.display = 'flex';
			requestAnimationFrame(() => {
				this.modalActive = true;
				if (!this.initialised) {
					this.initialised = true;
					window.addEventListener('keydown', this.handleKeyDown);
				}
				window.openModals.push(this.modalName);
				document.body.classList.add('modal-is-open');
				document.documentElement.classList.add('modal-is-open');
			});
			return new Promise((resolve, reject) => {
				this.resolvePromise = resolve;
				this.rejectPromise = reject;
			});
		},
		closeModal(closingConfirmed = false) {
			if (this.confirmModal && !closingConfirmed) {
				setTimeout(() => {
					// need to wait for the keydown event to be finished before opening the confimation
					// modal, otherwise we end up in a loop
					window.eventBus.$emit(`modal:${this.confirmModal}`);
				}, 0);
			} else {
				this.modalActive = false;
				this.$emit('closing-modal');

				window.openModals = window.openModals.filter(name => name !== this.modalName);
				if (window.openModals.length === 0) {
					document.body.classList.remove('modal-is-open');
					document.documentElement.classList.remove('modal-is-open');
				}
			}
		},
		handleOpen(passedData) {
			this.showModal();
			this.$emit('opened', passedData);
			this.data = passedData;
			this.passedData = passedData;
		},
		handleClose(payLoad) {
			const isConfirmed = payLoad?.closingConfirmed || false;
			this.closeModal(isConfirmed);
		},
		handleKeyDown(e) {
			if (this.closeable && e.key === 'Escape') {
				if (
					window.openModals.length > 0 &&
					(window.openModals[window.openModals.length - 1] === this.modalName)
				) {
					e.stopPropagation();
					this.closeModal();
				}
			}
			// Trigger the enter button click event when data-key="enter" is set
			if (e.key === 'Enter') {
				const enterButton = this.$refs.modalFooter?.querySelector('button[data-key="enter"]');
				if (enterButton) {
					enterButton.click();
				}
			}
		},
		handleTransitionEnd(e) {
			if (
				e.propertyName === 'transform' &&
				this.$refs.modalContentWrapper === e.target
			) {
				if (!this.modalActive) {
					// prevent autocompletion on forms inside modal
					this.$refs.modalContentWrapper.style.display = 'none';
				} else {
					this.$emit('opening-animation-finished');
				}
			}
		},
		handleModalClick(e) {
			if (e.target === this.$refs.modal && this.closeable) {
				this.closeModal();
			}
		},
	},

};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

$modal-header-height: 80px;
$modal-header-height-dialog: 64px;
$box-shadow-modal: 0 9px 12px rgb(0 0 0 / 14%), 0 3px 16px rgb(0 0 0 / 12%), 0 5px 6px rgb(0 0 0 / 20%);

.v2-modal {
	@include z-index(shared-modal);
	display: flex;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	flex-direction: column;
	color: var(--color-black);
	pointer-events: none;
	transition: opacity $trans-time-fast;
	will-change: opacity;

	&.-dialog {
		&::after {
			z-index: -1;
			background-color: var(--color-black);
			opacity: 0;
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transition: opacity $trans-time-fast;
			will-change: opacity;
		}
	}

	&.is-visible {
		pointer-events: initial;

		&.-dialog {
			&::after { // stylelint-disable-line max-nesting-depth
				opacity: 0.8;
			}
		}
	}

	&.-t-dark {
		color: var(--color-white);
	}

	@include mobile-media-query {
		justify-content: flex-end;
	}
}

.v2-modal__content-w {
	position: relative;
	display: none;
	flex-direction: column;
	height: 100vh;
	background-color: var(--color-white);
	opacity: 0;
	overflow: hidden;
	transform: scale(0.8);
	transition: opacity $trans-time-fast, transform $trans-time-fast;
	will-change: transform;

	&.is-visible {
		transform: scale(1);
		opacity: 1;
	}

	&.-dialog {
		width: 460px;
		max-width: 100%;
		box-shadow: $box-shadow-modal;
		height: auto;
		max-height: calc(100vh - #{$sp8});
		margin-left: auto;
		margin-right: auto;
		border-radius: $border-radius--md;
		overflow: hidden;
	}

	&.-dialog-md {
		width: 700px;
	}

	&.-dialog-lg {
		width: 100%;
		max-width: 1200px;
	}

	.v2-modal.-t-dark & {
		background-color: var(--color-grey-850);
		border-radius: $border-radius--md;
		overflow: hidden;
	}

	@include mobile-media-query {
		opacity: 1;
		transform: translateY(100%);
		transition: transform $trans-time-fast;

		.v2-modal.-t-dark &,
		&.-dialog {
			border-radius: $border-radius--xl $border-radius--xl 0 0;
		}

		&.is-visible {
			transform: translateY(0);
		}
	}
}

.v2-modal__close {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	width: $modal-header-height;
	height: $modal-header-height;
	z-index: 3; // stylelint-disable-line
	cursor: pointer;

	&.-dialog {
		width: $modal-header-height-dialog;
		height: $modal-header-height-dialog;
		left: auto;
		right: 0;
	}

	&.-frameless {
		display: none;
	}
}

.v2-modal__header {
	width: 100%;
	height: $modal-header-height;
	background-color: var(--color-white);
	border-bottom: 1px solid var(--color-grey-100);
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: $modal-side-padding;
	padding-right: $modal-side-padding;
	flex-shrink: 0;

	&.-dialog {
		height: $modal-header-height-dialog;
	}

	.v2-modal.-t-dark & {
		background-color: var(--color-grey-850);
		border-bottom-color: var(--color-grey-750);
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type
		border-color: var(--color-grey-900);
	}
}

.v2-modal__title {
	font-size: 24px; // stylelint-disable-line
	font-weight: 300; // stylelint-disable-line
	text-align: center;
	margin: 0; // sdx reset

	&.-dialog {
		@include font(primary, medium, normal, fs-120);
		text-align: left;
	}
}

.v2-modal__content {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	padding-top: $sp5;
	padding-left: $modal-side-padding;
	padding-right: $modal-side-padding;
	padding-bottom: $sp5;
	flex-grow: 1;
	z-index: 1;

	&.-frameless {
		overflow: hidden;
		padding: 0;
	}

	.v2-modal.-t-dark & {
		background-color: var(--color-grey-850);
	}

	& > .l-container { // stylelint-disable-line
		padding-left: 0;
		padding-right: 0;
		width: 100%;
	}
}

.v2-modal__footer {
	width: 100%;
	padding: 20px 0;
	background-color: var(--color-white);
	flex-shrink: 0;
	z-index: 1;

	&.-dialog {
		padding: $sp5 $sp5 $sp6;
	}

	&:not(.-dialog) {
		border-top: 1px solid var(--color-grey-100);
	}

	.v2-modal.-t-dark & {
		background-color: var(--color-grey-850);
	}
}
</style>
