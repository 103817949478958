<template>
	<div
		v-if="hasSubscribtion"
		class="sl-subscribtion-badge"
	>
		{{ $I18n.trans('swiss_league_infos.subscription_info.active_short') }}
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name:     'SubscribtionBadge',
	computed: {
		...mapGetters({
			profile: 'user/profile',
		}),
		hasSubscribtion() {
			return this.profile?.accessibleResources?.subscriptions?.find(s => s.context === 'league');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.sl-subscribtion-badge {
	@include font(primary, book, normal, fs-80);
	background-color: var(--color-grey-800);
	border-radius: $border-radius--xs;
	padding: $sp1 $sp3;
}
</style>
