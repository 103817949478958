import Vue from 'vue';

let container = document.body;

export default {
	register() {
		Vue.directive('click-outside', {
			bind: function(el, binding, vnode) {
				el.clickOutsideEvent = function(event) {
					// here I check that click was outside the el and his childrens
					if (!(el === event.target || el.contains(event.target))) {
						// and if it did, call method provided in attribute value
						vnode.context[binding.expression](event);
					}
				};
				if (vnode.context.container === 'body') {
					container = document.body;
					container.addEventListener('click', el.clickOutsideEvent);
				}
			},
			inserted: function(el, binding, vnode) {
				if (vnode.context.container && vnode.context.container !== 'body') {
					container = document.querySelector(vnode.context.container);
				}
				if (container) {
					container.addEventListener('click', el.clickOutsideEvent);
				}
			},
			unbind: function(el) {
				if (container) {
					container.removeEventListener('click', el.clickOutsideEvent);
				}
			},
		});
	},
};
