export const EventOrder = {
	TITLE:           'title',
	VISIBILITY:      'visibility',
	SPORT:           'sport',
	CATEGORY_CHILD:  'category_child',
	CATEGORY_PARENT: 'category_parent',
	LEAGUE:          'league',
	LOCATION:        'location',
	DURATION:        'duration',
	START:           'start',
	STATUS:          'status',
	VIEWS:           'views',
	VIEWERS:         'viewers',
	RECOMMENDED:     'recommended',
	AVG_VIEWTIME:    'avgViewtime',
	ALBUM_ORDER:     'album_order',
};
