import store from '@legacy/js/store';
import { INIT_STORE as INIT_EVENT_STORE } from '@legacy/js/store/events/action-types';
import { INIT_ALBUMS } from '@legacy/js/store/albums';

export const loadToVuexStore = (action, payload, callback) => {
	store.dispatch(action, payload).then(callback);
};

if (window.StoreLoader) {
	if (window.StoreLoader.events) {
		Object.keys(window.StoreLoader.events).forEach((store) => {
			loadToVuexStore(INIT_EVENT_STORE, { storeId: store, data: window.StoreLoader.events[store] }, () => {
				delete window.StoreLoader.events[store];
			});
		});
	}
	if (window.StoreLoader.albums) {
		loadToVuexStore(INIT_ALBUMS, { data: window.StoreLoader.albums }, () => {
			delete window.StoreLoader.albums;
		});
	}
}
