import { adButlerClient } from '@shared/js/api/adbutler';
import { AdSource } from '@shared/enums/AdSource';
import { union } from 'lodash-es';

const adCache = new Map(); // Stores resolved ad data per ad ID
const adPromises = new Map(); // Stores ongoing fetch promises per ad ID

export default {
	methods: {
		getPageLabels() {
			const metaTag = document.querySelector('meta[name="labels"]');
			if (metaTag) {
				try {
					const content = JSON.parse(metaTag.getAttribute('content'));

					if (Array.isArray(content)) {
						return content;
					}
				} catch (error) {
					console.error('Failed to parse JSON content:', error);
				}
			}
			return [];
		},
		async fetchAdbutlerAd(callback, additionalProperties = {}) {
			const adId = this.ad.id; // Identify the ad uniquely

			// temporarily disable caching
			// -> auto ad refresh didn't work and all banners show the same ad
			// Return cached ad if it exists
			// if (adCache.has(adId)) {
			// 	callback(adCache.get(adId));
			// 	return;
			// }

			// // If a request is already in progress, return the same promise
			// if (adPromises.has(adId)) {
			// 	try {
			// 		const adData = await adPromises.get(adId);
			// 		callback(adData);
			// 	} catch (error) {
			// 		callback(null);
			// 	}
			// 	return;
			// }

			// Create a new fetch promise and store it
			const fetchPromise = (async() => {
				try {
					const response = await adButlerClient.getAd(
						this.ad.adbutlerZoneId,
						this.adButlerLocale,
						this.isAdbutlerMobile && this.ad.adbutlerFormatMobile
							? this.ad.adbutlerFormatMobile
							: this.ad.adbutlerFormat,
						this.ad.adbutlerSendLabels
							? union((additionalProperties.labels || []), this.getPageLabels())
							: [],
					);

					if (!response.ok) {
						throw new Error(`Request failed with status ${response.status}`);
					}

					const adButlerData = await response.json();
					adCache.set(adId, adButlerData); // Cache the response
					adPromises.delete(adId); // Remove from pending promises once resolved
					return adButlerData;
				} catch (error) {
					console.error('Unable to fetch ads: ', error);
					throw error;
				}
			})();

			// Store the fetch promise
			adPromises.set(adId, fetchPromise);

			// Wait for the promise to resolve and execute the callback
			try {
				const adData = await fetchPromise;
				callback(adData);
			} catch (error) {
				callback(null);
			}
		},
	},
	computed: {
		isStaticAd() {
			return this.ad.source === AdSource.UPLOAD;
		},
		isAdButlerAd() {
			return this.ad.source === AdSource.ADSERVER && this.ad.adserverType === 'adbutler';
		},
		adButlerLocale() {
			return window.locale === 'de' ? 'de-CH' : (window.locale + '-' + window.locale.toUpperCase());
		},
		isAdbutlerMobile() {
			return this.isMobile || (window.innerWidth < 1023);	// Use global isMobile from device mixin, or fallback to viewport size detection
		},
	},
};
