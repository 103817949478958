<template>
	<div
		class="in-page-navigation"
		:class="{
			'-t-dark': darkTheme,
			'-stacked-at-md': !horizontal,
		}"
	>
		<nav class="in-page-navigation__nav">
			<template v-for="item in navItems">
				<router-link
					v-if="isRouterAvailable && useVueRouter"
					:key="item.label"
					class="in-page-navigation__item"
					:to="item.url"
					exact-active-class="is-active"
					@click.native="$emit('click', item)"
				>
					<Icon
						v-if="item.icon"
						:icon-name="item.icon"
						class="in-page-navigation__icon"
					/>
					<span class="in-page-navigation__item-text">{{ item.label }}</span>
					<Icon
						v-if="item.warning"
						class="in-page-navigation__warning"
						icon-name="c-warning"
					/>
				</router-link>
				<component
					:is="useAnchorTag ? 'a' : 'span'"
					v-else
					:key="item.label"
					class="in-page-navigation__item"
					:href="useAnchorTag ? item.url : null"
					:class="{ 'is-active': item.active }"
					@click="handleClick(item)"
				>
					<Icon
						v-if="item.icon"
						:icon-name="item.icon"
						class="in-page-navigation__icon"
					/>
					<span class="in-page-navigation__item-text">{{ item.label }}</span>
					<Icon
						v-if="item.warning"
						class="in-page-navigation__warning"
						icon-name="c-warning"
					/>
				</component>
			</template>
		</nav>
		<div
			v-if="withLine"
			class="in-page-navigation__line"
		/>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';

export default {
	name:       'InPageNavigation',
	components: { Icon },
	props:      {
		navItems: {
			type:     Array,
			required: true,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		useVueRouter: {
			type:    Boolean,
			default: false,
		},
		useAnchorTag: {
			type:    Boolean,
			default: true,
		},
		horizontal: {
			type:    Boolean,
			default: false,
		},
		withLine: {
			type:    Boolean,
			default: true,
		},
	},
	computed: {
		isRouterAvailable() {
			return !!this.$router;
		},
	},
	methods: {
		handleClick(navItem) {
			this.$emit('click', navItem);
			if (!this.useAnchorTag) {
				this.$emit('nav-change', navItem.url);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.in-page-navigation {
	// Manager default colors
	--color-horizontal-text-active: var(--color-green-petrol);
	--color-horizontal-text-inactive: var(--color-grey-300);
	--color-horizontal-line: var(--color-grey-100);
	--color-stacked-background-focus: var(--color-grey-050);
	--color-stacked-background-active: var(--color-grey-100);
	--color-stacked-text-inactive: var(--color-black);
	--color-stacked-text-active: var(--color-black);
	position: relative;

	// Arena light colors
	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		--color-horizontal-text-active: var(--color-hype-yellow);
		--color-horizontal-line: var(--color-grey-900);
		--color-stacked-background-focus: var(--color-grey-950);
		--color-stacked-background-active: var(--color-grey-900);
	}

	// Manager & Arena dark colors
	&.-t-dark {
		--color-horizontal-text-active: var(--color-soft-white);
		--color-horizontal-text-inactive: var(--color-grey-400);
		--color-horizontal-line: var(--color-grey-900);
		--color-stacked-background-focus: var(--color-grey-900);
		--color-stacked-background-active: var(--color-grey-850);
		--color-stacked-text-inactive: var(--color-white);
		--color-stacked-text-active: var(--color-white);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			--color-horizontal-text-active: var(--color-black);
		}
	}
}

.in-page-navigation__nav {
	display: flex;
	gap: $sp6;
	margin-bottom: $sp5;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;

	@include size(md-up) {
		overflow-x: initial;
		margin-bottom: $sp4;

		.in-page-navigation.-stacked-at-md & {
			display: block;
			gap: 0;
		}
	}

	@include size(xs-only) {
		// offsetting grid padding so nav-line is visible outside of grid
		padding-left: 16px;
		padding-right: 16px;
		margin-left: -16px;
		margin-right: -16px;

		body.is-manager & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			padding-left: 8px;
			padding-right: 8px;
			margin-left: -8px;
			margin-right: -8px;
		}
	}
}

// Hide scrollbar
.in-page-navigation__nav::-webkit-scrollbar {
	display: none;
}

@mixin hover-and-active-style() {
	color: var(--color-horizontal-text-active);

	&::after {
		opacity: 1;
		height: 2px;
	}

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			color: var(--color-stacked-text-active);
		}
	}
};

.in-page-navigation__item {
	@include font-size(fs-120);
	line-height: 1;
	position: relative;
	display: flex;
	align-items: center;
	color: var(--color-horizontal-text-inactive);
	transition: none;
	padding-top: $sp5;
	padding-bottom: $sp5;
	margin: 0;
	border-bottom: none !important; // stylelint-disable-line declaration-no-important
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		bottom: 0;
		left: -5px;
		width: calc(100% + 10px);
		height: 1px;
		background-color: currentColor; // stylelint-disable-line declaration-property-value-allowed-list
		opacity: 0;
	}

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			@include font-size(fs-100);
			line-height: 1;
			padding: $sp4;
			color: var(--color-stacked-text-inactive);
			border: none;

			&::after {
				display: none;
			}
		}
	}

	@media (hover: hover) {
		&:hover,
		&:focus {
			@include hover-and-active-style;

			.in-page-navigation.-stacked-at-md & {
				@include size(md-up) {
					background-color: var(--color-stacked-background-focus);
				}
			}

		}
	}

	&.is-active {
		@include hover-and-active-style;

		.in-page-navigation.-stacked-at-md & {
			@include size(md-up) {
				background-color: var(--color-stacked-background-active);
			}
		}
	}
}

.in-page-navigation__icon {
	display: none;

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			display: block;
		}
	}
}

.in-page-navigation__item-text {
	white-space: nowrap;

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			margin-left: $sp4;
		}
	}
}

.in-page-navigation__warning {
	color: var(--color-red);
	padding-left: $sp2;

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			position: absolute;
			right: $sp4;
			padding-left: 0;
		}
	}
}

.in-page-navigation__line {
	position: absolute;
	left: 50%;
	bottom: 0;
	margin-left: -50vw;
	background-color: var(--color-horizontal-line);
	z-index: 1;
	height: 1px;
	width: 100vw;

	body.is-manager & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		@include size(sm-up) {
			left: calc(50% - 40px); // offset by $nav-side-width
		}
	}

	.in-page-navigation.-stacked-at-md & {
		@include size(md-up) {
			display: none;
		}
	}
}
</style>
