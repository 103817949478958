<template>
	<label
		class="v2-input-label"
		:class="{
			'is-required': required,
			'has-info': info,
			'-t-dark': darkTheme,
		}"
	>
		<!-- eslint-disable vue/no-v-html -->
		<span v-html="text" /><span v-if="showOptional"> ({{ $I18n.trans('commons.optional') }})</span>
		<!-- eslint-enable vue/no-v-html -->
		<Tooltip
			v-if="info"
			:text="info"
			position="top-center"
		/>
	</label>
</template>

<script>
import Tooltip from '@shared/components/Tooltip';

export default {
	name:       'V2InputLabel',
	components: { Tooltip },
	props:      {
		text: {
			type:     String,
			required: true,
		},
		info: {
			type:    String,
			default: null,
		},
		required: {
			type:    Boolean,
			default: false,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		hideOptional: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		showOptional() {
			return !this.required && !this.disabled && !this.hideOptional;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-input-label {
	--form-color-label-info: var(--color-shadow-grey);
	--form-color-info-text: inherit;
	@include form-input-label-font();

	display: block;
	position: relative;
	margin-bottom: $form-label-margin;
	color: $form-color-label;

	&.-t-dark {
		--form-color-label-info: var(--color-soft-white);
		--form-color-info-text: var(--color-black);
		color: var(--color-soft-white);
	}
}
</style>
