<template>
	<!-- eslint-disable vue/no-v-html -->
	<div class="terms">
		<div class="l-row l-align-center@md">
			<div class="l-col l-col--12 l-col--8@md s-html">
				<InPageNavigation
					:nav-items="navItems"
					:use-anchor-tag="false"
					:dark-theme="darkTheme"
					horizontal
					@nav-change="handleInPageNavChange"
				/>
				<div class="terms__content">
					<div
						v-if="isTermsView"
						class="s-html"
					>
						<template v-if="hasCustomTerms">
							<h1>{{ $I18n.trans(`legal.custom_terms.${$arenaConfig.terms}.title`) }}</h1>
							<div
								v-for="(section, i) in $I18n.trans(`legal.custom_terms.${$arenaConfig.terms}.sections`)"
								:key="i"
							>
								<h2>{{ section.title }}</h2>
								<p
									v-for="(content, ii) in section.content"
									:key="ii"
									v-html="content"
								/>
							</div>
						</template>
						<template v-else>
							<h1>{{ $I18n.trans('legal.terms_subtitle') }}</h1>

							<h2>{{ $I18n.trans('legal.terms.title.1') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.1.1')" />

							<h2>{{ $I18n.trans('legal.terms.title.2') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.2.1')" />
							<p v-html="$I18n.trans('legal.terms.content.2.2')" />

							<h2>{{ $I18n.trans('legal.terms.title.3') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.3.1')" />
							<p v-html="$I18n.trans('legal.terms.content.3.2')" />

							<h2>{{ $I18n.trans('legal.terms.title.4') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.4.1')" />
							<p v-html="$I18n.trans('legal.terms.content.4.2')" />

							<h2>{{ $I18n.trans('legal.terms.title.5') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.5.1')" />
							<p v-html="$I18n.trans('legal.terms.content.5.2')" />
							<p v-html="$I18n.trans('legal.terms.content.5.3')" />

							<h2>{{ $I18n.trans('legal.terms.title.6') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.6.1')" />

							<h2>{{ $I18n.trans('legal.terms.title.7') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.7.1')" />

							<h2>{{ $I18n.trans('legal.terms.title.8.8') }}</h2>
							<h4>{{ $I18n.trans('legal.terms.title.8.a') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.a')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.b') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.b')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.c') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.c')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.d') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.d.description_1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.d.public')" />
							<p v-html="$I18n.trans('legal.terms.content.8.d.hidden')" />
							<p v-html="$I18n.trans('legal.terms.content.8.d.private')" />
							<p v-html="$I18n.trans('legal.terms.content.8.d.description_2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.e') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.e.description')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.2')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.3')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.4')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.5')" />
							<p v-html="$I18n.trans('legal.terms.content.8.e.6')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.f') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.f')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.g') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.g.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.g.2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.h') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.h.description_1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.h.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.h.2')" />
							<p v-html="$I18n.trans('legal.terms.content.8.h.3')" />
							<p v-html="$I18n.trans('legal.terms.content.8.h.description_2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.i') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.i.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.i.2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.j') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.j.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.j.2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.k') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.k')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.l') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.l.1')" />
							<p v-html="$I18n.trans('legal.terms.content.8.l.2')" />

							<h4>{{ $I18n.trans('legal.terms.title.8.m') }}</h4>
							<p v-html="$I18n.trans('legal.terms.content.8.m')" />

							<h2>{{ $I18n.trans('legal.terms.title.9') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.9.1')" />
							<p v-html="$I18n.trans('legal.terms.content.9.2')" />

							<h2>{{ $I18n.trans('legal.terms.title.10') }}</h2>
							<p v-html="$I18n.trans('legal.terms.content.10.1')" />
							<p v-html="$I18n.trans('legal.terms.content.10.2')" />
							<p v-html="$I18n.trans('legal.terms.content.10.3')" />
						</template>
					</div>

					<div
						v-if="isPrivacyView"
						class="s-html"
					>
						<h1>{{ $I18n.trans('legal.privacy_subtitle') }}</h1>
						<p v-html="$I18n.trans('legal.privacy_description.1')" />
						<p
							v-html="$I18n.trans('legal.privacy_description.2')"
						/>

						<h2>{{ $I18n.trans('legal.privacy_contents_title') }}</h2>
						<ol>
							<li><a href="#1">{{ $I18n.trans('legal.privacy_contents.1') }}</a></li>
							<li><a href="#2">{{ $I18n.trans('legal.privacy_contents.2') }}</a></li>
							<li><a href="#3">{{ $I18n.trans('legal.privacy_contents.3') }}</a></li>
							<li><a href="#4">{{ $I18n.trans('legal.privacy_contents.4') }}</a></li>
							<li><a href="#5">{{ $I18n.trans('legal.privacy_contents.5') }}</a></li>
							<li><a href="#6">{{ $I18n.trans('legal.privacy_contents.6') }}</a></li>
							<li><a href="#7">{{ $I18n.trans('legal.privacy_contents.7') }}</a></li>
							<li><a href="#8">{{ $I18n.trans('legal.privacy_contents.8') }}</a></li>
							<li><a href="#9">{{ $I18n.trans('legal.privacy_contents.9') }}</a></li>
						</ol>
						<ul>
							<li><a href="#sub-table-1">{{ $I18n.trans('legal.privacy_contents.sub-table.1') }}</a></li>
							<li><a href="#sub-table-2">{{ $I18n.trans('legal.privacy_contents.sub-table.2') }}</a></li>
						</ul>

						<div class="s-html__two-col-table">
							<div
								id="1"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									1. {{ $I18n.trans('legal.privacy_contents.1') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.1') }}
									</p>
								</div>
							</div>
							<div
								id="2"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									2. {{ $I18n.trans('legal.privacy_contents.2') }}
								</div>
								<div class="l-col l-col--8@sm">
									<h5 v-html="$I18n.trans('legal.privacy.sub-titles.2.1')" />
									<p>
										{{ $I18n.trans('legal.privacy.content.2.1') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.2') }}
									</p>

									<h5 v-html="$I18n.trans('legal.privacy.sub-titles.2.2')" />
									<p>
										{{ $I18n.trans('legal.privacy.content.2.3') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.4') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.5') }}
									</p>

									<h5 v-html="$I18n.trans('legal.privacy.sub-titles.2.3')" />
									<p>
										{{ $I18n.trans('legal.privacy.content.2.6') }}
									</p>

									<h5 v-html="$I18n.trans('legal.privacy.sub-titles.2.4')" />
									<p>
										{{ $I18n.trans('legal.privacy.content.2.7') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.8') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.9') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.2.10') }}
									</p>
								</div>
							</div>
							<div
								id="3"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									3. {{ $I18n.trans('legal.privacy_contents.3') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p v-html="$I18n.trans('legal.privacy.content.3.1')" />
									<p v-html="$I18n.trans('legal.privacy.content.3.2')" />
									<p v-html="$I18n.trans('legal.privacy.content.3.3')" />
									<p v-html="$I18n.trans('legal.privacy.content.3.4')" />
									<p v-html="$I18n.trans('legal.privacy.content.3.5')" />
									<p v-html="$I18n.trans('legal.privacy.content.3.6')" />
								</div>
							</div>
							<div
								id="4"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									4. {{ $I18n.trans('legal.privacy_contents.4') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.4.1') }}
									</p>
									<p v-html="$I18n.trans('legal.privacy.content.4.2')" />
									<p>
										{{ $I18n.trans('legal.privacy.content.4.3') }}
									</p>
								</div>
							</div>
							<div
								id="5"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									5. {{ $I18n.trans('legal.privacy_contents.5') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.5.1') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.5.2') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.5.3') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.5.4') }}
									</p>
									<p v-html="$I18n.trans('legal.privacy.content.5.5')" />
								</div>
							</div>
							<div
								id="6"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									6. {{ $I18n.trans('legal.privacy_contents.6') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.6') }}
									</p>
								</div>
							</div>
							<div
								id="7"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									7. {{ $I18n.trans('legal.privacy_contents.7') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.7') }}
									</p>
								</div>
							</div>
							<div
								id="8"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									8. {{ $I18n.trans('legal.privacy_contents.8') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.8.1') }}
									</p>
									<p>
										{{ $I18n.trans('legal.privacy.content.8.2') }}
									</p>
								</div>
							</div>
							<div
								id="9"
								class="l-row"
							>
								<div class="l-col l-col--4@sm">
									9. {{ $I18n.trans('legal.privacy_contents.9') }}
								</div>
								<div class="l-col l-col--8@sm">
									<p>
										{{ $I18n.trans('legal.privacy.content.9') }}
									</p>
								</div>
							</div>
						</div>

						<h2 id="sub-table-1">
							{{ $I18n.trans('legal.privacy_contents.sub-table.1') }}
						</h2>
						<div class="s-html__table-wrapper">
							<table>
								<thead>
									<tr>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.solution') }}</th>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.provider') }}</th>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.address') }}</th>
										<th />
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Google Analytics</td>
										<td>Google Inc.</td>
										<td>1600 Amphitheatre Parkway<br>Mountain View, CA 94043<br>USA</td>
										<td>
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.data-protection', {link: 'http://www.google.com/intl/de/analytics/privacyoverview.html'})" />
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.opt-out', {link: 'https://tools.google.com/dlpage/gaoptout'})" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<h2 id="sub-table-2">
							{{ $I18n.trans('legal.privacy_contents.sub-table.2') }}
						</h2>
						<div class="s-html__table-wrapper">
							<table>
								<thead>
									<tr>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.solution') }}</th>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.provider') }}</th>
										<th>{{ $I18n.trans('legal.privacy.table.descriptions.address') }}</th>
										<th />
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Facebook</td>
										<td>Facebook Inc.</td>
										<td>1601 S. California Ave<br>Palo Alto, CA 94304<br>USA</td>
										<td v-html="$I18n.trans('legal.privacy.table.descriptions.data-protection', {link: 'http://www.facebook.com/policy.php'})" />
									</tr>
									<tr>
										<td>Google+</td>
										<td>Google Inc.</td>
										<td>1600 Amphitheatre Parkway<br>Mountain View, CA 94043<br>USA</td>
										<td v-html="$I18n.trans('legal.privacy.table.descriptions.data-protection', {link: 'http://www.google.com/intl/de/+/policy/+1button.html'})" />
									</tr>
									<tr>
										<td>Twitter</td>
										<td>Twitter Inc.</td>
										<td>795 Folsom St., Suite 600<br>San Francisco, CA 94107<br>USA</td>
										<td v-html="$I18n.trans('legal.privacy.table.descriptions.data-protection', {link: 'https://twitter.com/privacy?lang=de'})" />
									</tr>
									<tr>
										<td>Instagram</td>
										<td>Instagram LLC</td>
										<td>1601 Willow Road<br>Menlo Park, CA 94025<br>US</td>
										<td v-html="$I18n.trans('legal.privacy.table.descriptions.data-protection', {link: 'http://blog.instagram.com/post/36222022872/introducing-instagram-badges'})" />
									</tr>
									<tr>
										<td>YouTube</td>
										<td>Google Ireland Limited</td>
										<td>Gordon House Barrow Street<br>Dublin 4<br>Ireland</td>
										<td>
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.youtube_terms', {link: 'https://www.youtube.com/t/terms'})" />
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.youtube_privacy', {link: 'https://support.google.com/youtube/answer/7671399?p=privacy_guidelines&hl=en-GB&visit_id=637043895473321918-2907540004&rd=1'})" />
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.google_privacy', {link: 'https://policies.google.com/privacy'})" />
											<div v-html="$I18n.trans('legal.privacy.table.descriptions.revoke_access', {link: 'https://myaccount.google.com/permissions?pli=1'})" />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import InPageNavigation from '@shared/components/InPageNavigation';

export default {
	name:       'Terms',
	components: { InPageNavigation },
	props:      {
		darkTheme: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			navItems: [
				{
					label:  this.$I18n.trans('legal.terms_title'),
					url:    'terms',
					active: true,
				},
				{
					label:  this.$I18n.trans('legal.privacy_title'),
					url:    'privacy',
					active: false,
				},
			],
		};
	},
	computed: {
		isTermsView() {
			return this.navItems.find(i => i.url === 'terms').active;
		},
		isPrivacyView() {
			return this.navItems.find(i => i.url === 'privacy').active;
		},
		hasCustomTerms() {
			if (this.$arenaConfig.terms === 'asport') {
				return false;
			}
			const customTermsTranslationKey = this.$arenaConfig.terms;
			return this.$I18n.trans(`legal.custom_terms.${customTermsTranslationKey}`);
		},
	},
	mounted() {
		window.addEventListener('hashchange', () => {
			const hash = window.location.hash;
			if (!hash.includes('--')) {
				return;
			}
			const view = hash.split('--')[0].split('#')[1];
			const target = hash.split('--')[1];
			this.handleInPageNavChange(view);

			this.$nextTick(() => {
				window.location.hash = target;
			});
		});
	},
	methods: {
		handleInPageNavChange(url) {
			this.navItems.forEach(i => {
				i.active = false;
				if (url === i.url) {
					i.active = true;
				}
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.terms {
	margin-top: $sp7;
	margin-bottom: $sp7;
}

.terms__content {
	margin-top: $sp7;
}
</style>
