<template>
	<div
		class="mobile-filter"
		:class="{'is-open': isOpen, 'select-is-open': activeSelect !== null}"
	>
		<div class="mobile-filter__header">
			<transition
				name="fade"
				mode="out-in"
			>
				<div
					v-if="activeSelect === null"
					key="nav-overview"
					class="mobile-filter__header-nav"
				>
					<Icon
						class="mobile-filter__header-nav-icon"
						icon-name="e-remove"
						size="lg"
						@click="close"
					/>
					<SectionTitle
						class="mobile-filter__header-title"
						:title="$I18n.trans('commons.filter')"
						size="sm"
						:vertical-spacing="false"
					/>
				</div>
				<div
					v-if="activeSelect !== null"
					key="nav-detail"
					class="mobile-filter__header-nav"
				>
					<Icon
						icon-name="arrow-left"
						size="lg"
						@click="closeSelect"
					/>
					<SectionTitle
						class="mobile-filter__header-title"
						:title="activeSelect.label"
						size="sm"
						:vertical-spacing="false"
					/>
				</div>
			</transition>
			<div
				v-if="isFiltered"
				class="mobile-filter__header-reset"
				@click="resetAllFilters"
			>
				{{ $I18n.trans('commons.filters_reset') }}
			</div>
		</div>
		<div
			class="mobile-filter__overview-screen"
			:class="{'is-open': isOpen, 'select-is-open': activeSelect !== null}"
		>
			<div class="mobile-filter__filters">
				<div
					v-for="select in selects"
					:key="select.id"
					class="mobile-filter__select-select"
					@click="() => openSelect(select)"
				>
					{{ select.label }}
					<span
						v-if="select.value !== select.defaultValue"
						class="mobile-filter__selected-option"
					>
						{{ select.options.find(option => option.value === select.value).label }}
					</span>
					<Icon
						v-else
						icon-name="right-arrow"
					/>
				</div>
				<ToggleButtonGroup
					v-for="toggle in toggles"
					:key="toggle.id"
					:name="`${toggle.id}_mobile`"
					:unique-id="`${toggle.id}_mobile`"
					:can-emit-change="false"
					:buttons="toggle.options"
					:selected-id="toggle.value"
					:full-width="true"
					class="mobile-filter__toggle"
					theme="dark"
					@change="(value) => handleToggleChange(toggle, value)"
				/>
			</div>
			<div class="mobile-filter__submit-w">
				<Button
					:text="$I18n.trans('commons.type_show', { type: $I18n.trans_choice('commons.result', 2) })"
					:disabled="false"
					type="primary"
					theme="dark"
					@click="close()"
				/>
			</div>
		</div>

		<div
			v-for="select in selects"
			:key="select.id"
			class="mobile-filter__select-screen"
			:class="{'is-open': activeSelect && activeSelect.id === select.id}"
		>
			<div
				v-for="option in select.options"
				:key="option.value"
				class="mobile-filter__select-option"
				:class="{'is-selected': select.value === option.value || (select.value === null && option.value === select.defaultValue) }"
				@click="handleSelectChange(select, option.value)"
			>
				{{ option.label }}
			</div>
		</div>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon';
import Button from '@shared/components/Button';
import SectionTitle from '@js/components/SectionTitle';
import ToggleButtonGroup from '@shared/components/ToggleButtonGroup';

export default {
	name:       'MobileFilter',
	components: { Icon, Button, SectionTitle, ToggleButtonGroup },
	props:      {
		filters: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	data() {
		return {
			isOpen:       false,
			activeSelect: null,
		};
	},
	computed: {
		selects() {
			return this.filters.filter(filter => filter.type === 'select');
		},
		toggles() {
			return this.filters.filter(filter => filter.type === 'toggle');
		},
		isFiltered() {
			return this.filters.some(filter => filter.value !== filter.defaultValue);
		},
	},
	methods: {
		handleToggleChange(toggle, value) {
			this.$emit('changeToggle', toggle, value);
		},
		handleSelectChange(select, value) {
			this.$emit('changeSelect', select, value);
			this.closeSelect();
		},
		resetAllFilters() {
			this.$emit('reset');
		},
		open() {
			this.isOpen = true;
			document.body.style.overflow = 'hidden';
		},
		close() {
			this.isOpen = false;
			document.body.style.overflow = '';
		},
		openSelect(select) {
			this.activeSelect = select;
		},
		closeSelect() {
			this.activeSelect = null;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.mobile-filter {
	@include z-index(arena-mobile-filter);
	display: flex;
	flex-direction: column;
	margin-bottom: $form-margin-bottom;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	transform: translateY(100%);
	transition: transform $trans-time-fast;
	background-color: var(--color-black);

	&.is-open {
		transform: translateY(0);
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}
}

.mobile-filter__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $sp7-5;
	padding: $sp4;
}

.mobile-filter__header-nav {
	display: flex;
	align-items: center;
}

.mobile-filter__header-title {
	margin-left: $sp4;
}

.mobile-filter__header-reset {
	color: var(--color-hype-yellow);
}

.mobile-filter__header-nav-icon {
	cursor: pointer;
}

.mobile-filter__overview-screen {
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	top: $sp7-5;
	left: 0;
	width: 100%;
	height: calc(100vh - #{$sp7-5});
	transform: translateX(0);
	transition: transform $trans-time-fast;

	&.select-is-open {
		transform: translateX(-20%);
	}
}

// .mobile-filter__filters {}

.mobile-filter__submit-w {
	display: flex;
	flex-direction: column;
	padding: $sp5;
	position: sticky;
	bottom: 0;
}

.mobile-filter__select-select {
	font-size: 16px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: $sp4;
	min-height: 52px;

	&:not(:last-child) {
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--color-grey-800);
		}
	}
}

.mobile-filter__selected-option {
	@include ellipsis;
	margin-left: $sp3;
	text-align: right;
	color: var(--color-grey-300);
}

.mobile-filter__toggle {
	margin-top: $sp5;
	margin-left: $sp4;
	margin-right: $sp4;
}

.mobile-filter__select-screen {
	position: fixed;
	left: 0;
	top: $sp7-5;
	width: 100%;
	height: calc(100vh - #{$sp7-5});
	transform: translateX(120%);
	transition: transform $trans-time-fast;
	background-color: var(--color-black);
	overflow-y: scroll;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 10vh;

	&.is-open {
		transform: translateX(0%);
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}
}

.mobile-filter__select-option {
	font-size: 16px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	position: relative;
	padding: $sp4;

	&:not(:last-child) {
		&::after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 1px;
			background-color: var(--color-grey-800);
		}
	}

	&.is-selected {
		background-color: var(--color-grey-850);
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity 125ms;
}

.fade-enter,
.fade-leave-to {
	opacity: 0;
}

</style>
