<template>
	<div class="album">
		<Header
			:title="title"
			:filters="filters"
			@changeSearch="handleSearch"
		/>
		<div
			v-if="filtersData.search.value"
			class="album__search-text"
		>
			{{ $I18n.trans('arena.match_center.results_for') }} "{{ filtersData.search.value }}"
		</div>
		<div
			v-if="noSearchResults"
			class="album__no-results"
		>
			{{ $I18n.trans('arena.match_center.no_results') }}
		</div>
		<EventsSection
			v-show="eventsSectionVisible"
			:title="didSearch ? $I18n.trans_choice('commons.event', 2): ''"
			class="album__events"
			:pagination="events.pagination"
			:handle-pagination-change="handlePaginationChange"
			:events="events.items"
			:is-loading="events.isLoading"
			:show-results-counter="filtersData.search.value !== '' && typeof events.pagination.total === 'number'"
		>
			<div v-if="!didSearch" />
			<Select
				v-if="events.items.length"
				class="album__select-order"
				:options="sortOrder.options"
				:value="sortOrder.selected"
				:searchable="false"
				:clearable="false"
				:dark-theme="!$arenaConfig.isLightTheme"
				open-direction="left"
				@change="handleSortOrderChange"
			/>
		</EventsSection>
	</div>
</template>
<script>
import Header from '@js/components/VideoCenter/Header';
import EventsSection from '@js/components/MatchCenter/EventsSection';
import Select from '@shared/components/Select';
import { AlbumEventOrderType as Order } from '@shared/enums/AlbumEventOrderType';
import { EventOrder } from '@shared/enums/EventOrder';
import { EventFilter } from '@shared/enums/EventFilter';
import { debounce } from '@shared/utils/debounce';
import laroute from '@laroute';

export default {
	name:       'Album',
	components: { EventsSection, Header, Select },
	props:      {
		albumId: {
			type:     Number,
			required: true,
		},
		title: {
			type:    String,
			default: null,
		},
		type: {
			type:    String,
			default: null,
		},
		includeLiveAndUpcomingEvents: {
			type:    Boolean,
			default: false,
		},
		eventOrder: {
			type:    String,
			default: null,
		},
	},
	data() {
		return {
			filtersData: {
				search: {
					value: '',
				},
			},
			sortOrder: {
				options: [
					{
						label: this.$I18n.trans(`album.event_order_type.${Order.VIEWS}`),
						value: Order.VIEWS,
					},
					{
						label: this.$I18n.trans(`album.event_order_type.${Order.NEWEST}`),
						value: Order.NEWEST,
					},
					{
						label: this.$I18n.trans(`album.event_order_type.${Order.OLDEST}`),
						value: Order.OLDEST,
					},
				],
				selected: this.eventOrder,
			},
			events: {
				fetchController: new AbortController(),
				isLoading:       false,
				pagination:      this.getInitialPagination(),
				items:           [],
			},
			debounceSearch: null,
		};
	},
	computed: {
		filters() {
			return [
				{
					type:         'search',
					id:           'search',
					defaultValue: '',
					emitEvent:    'changeSearch',
					...this.filtersData.search,
				},
			];
		},
		eventsSectionVisible() {
			return !this.noSearchResults || this.events.isLoading;
		},
		noSearchResults() {
			const noResultsOnVideos = this.events.items.length === 0 && !this.events.isLoading;
			return this.didSearch && noResultsOnVideos;
		},
		didSearch() {
			return !!this.filtersData.search.value;
		},
	},
	created() {
		this.debounceSearch = debounce(this.getEvents, 300, false);
	},
	mounted() {
		if (this.eventOrder === Order.CUSTOM) {
			this.sortOrder.options.unshift({
				label: this.$I18n.trans(`album.event_order_type.${Order.CUSTOM}`),
				value: Order.CUSTOM,
			});
		}
		this.getEvents();
	},
	methods: {
		handleSearch(value) {
			this.filtersData.search.value = value;
			this.events.pagination = this.getInitialPagination(12);
			this.debounceSearch();
		},
		handlePaginationChange(page) {
			this.events.pagination.currentPage = page;
			this.getEvents();
		},
		handleSortOrderChange(value) {
			this.sortOrder.selected = value;
			this.events.pagination.currentPage = 1;
			this.getEvents();
		},
		async getEvents() {
			try {
				if (this.events.isLoading) {
					this.events.fetchController.abort();
					this.events.fetchController = new AbortController();
				}
				this.events.isLoading = true;

				const searchParams = this.getSearchParams();
				const { signal } = this.events.fetchController;
				const data = await this.fetchData(searchParams, signal);
				this.events.items = data.data;
				this.events.pagination = data.meta;
				this.events.isLoading = false;
			} catch (error) {
				if (error.name === 'AbortError') {
					// nothing to do here
				} else {
					console.error(error);
				}
			}
		},
		async fetchData(searchParams, signal) {
			const headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest' });
			const response = await fetch(`${laroute.route('api.events.cached')}?${searchParams}`, { signal, headers });
			return response.json();
		},
		getSearchParams() {
			const searchParams = new URLSearchParams();

			let sorting = {};
			const filters = {
				[EventFilter.ARENA_ALBUM]: this.albumId,
			};

			if (this.filtersData.search.value) {
				filters[EventFilter.SEARCH] = this.filtersData.search.value;
			}

			switch (this.sortOrder.selected) {
			case Order.OLDEST:
			case Order.NEWEST:
				sorting = { [EventOrder.START]: this.sortOrder.selected === Order.NEWEST ? 'desc' : 'asc' };
				break;
			case Order.CUSTOM:
				sorting = { [EventOrder.ALBUM_ORDER]: this.albumId };
				break;
			case Order.VIEWS:
				sorting = { [EventOrder.VIEWS]: 'desc' };
				break;
			}

			// filter parameters
			searchParams.append('filters', JSON.stringify(filters));
			searchParams.append('sortOrder', JSON.stringify(sorting));
			searchParams.append('page', JSON.stringify(this.events.pagination.currentPage));
			searchParams.append('perPage', JSON.stringify(this.events.pagination.perPage));

			return searchParams;
		},
		getInitialPagination(perPage = 12) {
			return {
				perPage:     perPage,
				currentPage: 1,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.album {
	padding-top: $sp6;
}

.album__select-order {
	margin-bottom: $sp5;
	width: 200px;

	@include size(md-up) {
		width: 240px;
	}
}

.album__search-text {
	@include font(primary, light, normal, fs-180);
	margin-bottom: $sp7;
}

.album__no-results {
	@include font(primary, light, normal, fs-150);
	color: var(--color-grey-300);
}
</style>
