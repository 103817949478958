export const SeasonStageRound = {
	GROUP_STAGE:             'group_stage',
	PRE_PLAYOFFS_A:          'pre_playoffs_a',
	PRE_PLAYOFFS_B:          'pre_playoffs_b',
	PRE_PLAYOFFS_A_B:        'pre_playoffs_a_b',
	QUARTERFINALS:           'quarterfinals',
	SEMIFINALS:              'semifinals',
	FINALS:                  'finals',
	RANKING_ROUND_3RD_PLACE: 'ranking_round_3rd_place',
	RANKING_ROUND_5TH_PLACE: 'ranking_round_5th_place',
	RANKING_ROUND_7TH_PLACE: 'ranking_round_7th_place',
	RANKING_ROUND_5_TO_6:    'ranking_round_5_to_6',
	RANKING_ROUND_5_TO_7:    'ranking_round_5_to_7',
	RANKING_ROUND_5_TO_8:    'ranking_round_5_to_8',
	RANKING_ROUND_5_6_7_8:   'ranking_round_5_6_7_8',
	PLAYOUTS:                'playouts',
	PLAYOFFS:                'playoffs',
	RELEGATION:              'relegation',
	PROMOTION:               'promotion',
	CHAMPIONS_ROUND:         'champions_round',
	LEAGUE_ROUND:            'league_round',
};
