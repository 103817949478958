<template>
	<div>
		<div
			class="loading-spinner"
			:class="[color ? `-color-${color}` : '']"
		/>
	</div>
</template>

<script>
const colors = [
	'dark',
	'light',
];

export default {
	name:  'LoadingSpinner',
	props: {
		color: {
			type:      String,
			default:   null,
			validator: color => colors.includes(color),
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.loading-spinner {
	--color: var(--color-white);

	position: relative;
	width: 40px;
	height: 40px;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
		--color: var(--color-black);
	}

	&.-color-dark {
		--color: var(--color-green-petrol);
	}

	&.-color-light {
		--color: var(--color-white);
	}

	&::before,
	&::after {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: var(--color);
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		animation: spinner 2s infinite ease-in-out;
	}

	&::after {
		animation-delay: -1s;
	}
}

@keyframes spinner {
	0%,
	100% {
		transform: scale(0);
	}

	50% {
		transform: scale(1);
	}
}
</style>
