import { LEAVE_CHANNEL, START_CHANNEL_LISTENING } from './mutation-types';

export default {
	[START_CHANNEL_LISTENING](state, { channelName, eventName }) {
		if (!state.activeChannels.find(channel => channel.name === channelName && channel.event === eventName)) {
			state.activeChannels.push({ name: channelName, event: eventName });
		}
	},
	[LEAVE_CHANNEL](state, { channelName }) {
		state.activeChannels = state.activeChannels.filter((channel) =>
			channel.name !== channelName
		);
	},
};
