<template>
	<div
		class="v2-toggle-button-group"
		:class="[fullWidth ? '-full-width' : '', theme ? `-t-${theme}` : '']"
	>
		<div
			v-for="button in buttons"
			:key="button.name + button.id"
			class="v2-toggle-button-group__wrapper"
			:class="{'is-disabled': button.disabled}"
		>
			<input
				:id="buttonId(button.id)"
				class="v2-toggle-button-group__input"
				type="radio"
				:name="name"
				:value="button.id"
				:checked="selectedButtonId == button.id"
				:disabled="disabled || button.disabled"
				:readonly="dataReadonly"
				:required="required"
			>
			<label
				:for="buttonId(button.id)"
				class="v2-toggle-button-group__label"
				:class="{'-ellipsis': ellipsis}"
				@click="onButtonClick($event, button.id, button.disabled)"
			>{{ translationKey ? $I18n.trans(translationKey + button.name) : button.name
			}}</label>
		</div>
	</div>
</template>

<script>
const themes = [
	'dark',
];

export default {
	name:  'ToggleButtonGroup',
	props: {
		name: {
			type:     String,
			required: true,
		},
		uniqueId: {
			type:     String,
			required: true,
		},
		buttons: {
			type:    Array,
			default: () => [],
		},
		selectedId: {
			type:    [String, Number, Boolean],
			default: null,
		},
		bubble:         { type: Boolean, default: false },
		fullWidth:      { type: Boolean, default: false },
		canEmitChange:  { type: Boolean, default: true },
		emitAutoSelect: { type: Boolean, default: false },
		disabled:       { type: Boolean, default: false },
		readonly:       { type: Boolean, default: false },
		translationKey: {
			type:    String,
			default: null,
		},
		theme: {
			type:    String,
			default: null,
			validator(theme) {
				return themes.includes(theme);
			},
		},
		required: {
			type:    Boolean,
			default: false,
		},
		ellipsis: {
			type:    Boolean,
			default: true,
		},
	},
	data() {
		return {
			selectedButtonId: this.selectedId,
			dataReadonly:     this.readonly,
		};
	},
	computed: {
		buttonCount() {
			return this.buttons.length;
		},
	},
	watch: {
		buttons() {
			this.dataReadonly = this.buttonCount === 1;
			if (this.buttonCount === 1) {
				this.selectedButtonId = this.buttons[0].id;
				if (this.canEmitChange && this.emitAutoSelect) {
					this.emitChange(this.selectedButtonId, true);
				}
			}
		},
		selectedButtonId() {
			if (this.selectedId && !this.selectedButtonId) {
				this.selectedButtonId = this.selectedId;
			}
		},
		selectedId(id) {
			this.selectedButtonId = id;
			if (this.canEmitChange) {
				this.emitChange(id, true);
			}
		},
	},
	mounted() {
		this.dataReadonly = this.buttons && Object.keys(this.buttons).length === 1;
	},
	methods: {
		onButtonClick(event, id, disabled) {
			if (!this.dataReadonly && !this.disabled && !disabled) {
				if (!this.bubble) {
					this.selectedButtonId = id;
				}
				this.emitChange(id);
			} else {
				event.preventDefault();
			}
		},
		buttonId(index) {
			return this.name + '_' + '_' + this.uniqueId + index;
		},
		unselect() {
			if (!this.disabled || !this.dataReadonly) {
				this.selectedButtonId = null;
			}
		},
		emitChange(id, autoChanged) {
			if (id === null) {
				return;
			}
			// autoChanged is false if change came from direct item selection
			this.$emit('change', {
				id:   id,
				// eslint-disable-next-line no-self-compare
				name: this.buttons.find(button => button.id === id).name,
			}, autoChanged);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-toggle-button-group {
	--border-color: var(--color-grey-200);
	--text-color: var(--color-grey-500);
	--color-checked: var(--color-green-petrol);
	--color-checked-inactive: var(--color-green-petrol--inactive);
	--border-color-hover: var(--color-green-petrol);
	--bg-color-hover: transparent;

	display: flex;
	max-width: 100%;
	padding-right: 1px;

	&.-t-dark {
		--border-color: #{$form-color-border--dark};
		--text-color: var(--color-grey-300);
		--color-checked: var(--color-hype-yellow);
		--color-checked-inactive: var(--color-hype-yellow--inactive);
		--border-color-hover: #{$form-color-border--dark};
		--bg-color-hover: var(--color-grey-800);
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type, max-nesting-depth
		--border-color: var(--color-grey-800);
		--text-color: var(--color-grey-500);
		--color-checked: var(--color-grey-100);
		--color-checked-inactive: var(--color-grey-200);
		--border-color-hover: var(--color-grey-100);
		--bg-color-hover: transparent;
	}
}

.v2-toggle-button-group__wrapper {
	line-height: 0;
	margin-right: -1px;
	overflow: hidden;

	.v2-toggle-button-group:not(.-full-width) & {
		min-width: 85px;
	}

	.v2-toggle-button-group.-full-width & {
		flex-grow: 1;
	}

	@include size(sm-up) {
		flex-basis: 140px;
	}
}

.v2-toggle-button-group__input {
	@include visually-hidden;
}

.v2-toggle-button-group__label {
	display: inline-block;
	position: relative;
	width: 100%;
	margin-bottom: 0;
	border: 1px solid var(--border-color);
	padding: 15px 12px;
	text-align: center;
	font-size: $button-font-size--sm; // stylelint-disable-line
	line-height: 100%;
	color: var(--text-color);
	cursor: pointer;
	transition: border-color $trans-time-fast;

	&.-ellipsis {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.v2-toggle-button-group__wrapper:first-child & {
		border-top-left-radius: $border-radius--sm;
		border-bottom-left-radius: $border-radius--sm;
	}

	.v2-toggle-button-group__wrapper:last-child & {
		border-top-right-radius: $border-radius--sm;
		border-bottom-right-radius: $border-radius--sm;
	}

	.v2-toggle-button-group__input:not(:disabled):not(:checked) + & {
		&:focus,
		&:hover {
			border-color: var(--border-color-hover);
			background-color: var(--bg-color-hover);
			z-index: 1;
		}
	}

	.v2-toggle-button-group__input:checked + & {
		border-color: var(--color-checked);
		color: var(--color-checked);
		z-index: 2;
	}

	// Disabled state
	.v2-toggle-button-group__input:disabled + & {
		cursor: not-allowed;
	}

	.v2-toggle-button-group__input:checked:disabled + & {
		border-color: var(--color-checked-inactive);
		color: var(--color-checked-inactive);
	}

	@include size(md-up) {
		font-size: $button-font-size; // stylelint-disable-line
	}
}
</style>
