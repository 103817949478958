<template>
	<div
		class="input-copy"
		:class="{'-t-dark': darkTheme, '-text': type === 'text'}"
	>
		<div
			class="input-copy__click-w"
		>
			<template v-if="type === 'input-field'">
				<div class="input-copy__input-w">
					<InputText
						:label="label"
						disabled
						:dark-theme="darkTheme"
						:value="value"
						icon="copy"
						class="-copy"
						@input-click="onClick"
					>
						<template #after>
							<slot name="after" />
						</template>
					</InputText>
					<transition name="fade">
						<span
							v-show="showConfirmMsg"
							class="input-copy__confirm-msg"
						>{{ confirmMsg ? confirmMsg : $I18n.trans('commons.copied_message') }}</span>
					</transition>
				</div>
				<input
					:id="id"
					ref="input"
					disabled
					class="input-copy__hidden-input"
					:value="value"
				>
			</template>
			<template v-else-if="type === 'text'">
				<InputLabel
					:text="label"
					:info="labelTooltip"
					:disabled="true"
				/>
				<div
					class="input-copy__text-w"
					@click="onClick"
				>
					<div
						class="input-copy__text"
						:class="{'-nowrap': noWrap}"
					>
						{{ value }}
					</div>
					<Icon
						icon-name="copy"
						size="lg"
					/>
					<transition name="fade">
						<div
							v-show="showConfirmMsg"
							class="input-copy__confirm-msg-text"
						>
							{{ confirmMsg ? confirmMsg : $I18n.trans('commons.copied_message') }}
						</div>
					</transition>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
import InputText from '@shared/components/Form/InputText';
import InputLabel from '@shared/components/Form/InputLabel';
import Icon from '@shared/components/Icon';

export default {
	name:       'InputCopy',
	components: { InputText, Icon, InputLabel },
	props:      {
		id: {
			type:    String,
			default: null,
		},
		label: {
			type:    String,
			default: null,
		},
		labelTooltip: {
			type:    String,
			default: null,
		},
		value: {
			type:     [String, Number],
			required: true,
		},
		type: {
			type:      String,
			default:   'input-field',
			validator: (type) => ['input-field', 'text'].includes(type),
		},
		confirmMsg: {
			type:    String,
			default: null,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		noWrap: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			showConfirmMsg: false,
		};
	},
	methods: {
		onClick() {
			if (this.type === 'input-field') {
				const el = this.$refs.input;
				el.disabled = false;
				el.focus();
				el.select();
				document.execCommand('copy');
				window.getSelection().removeAllRanges();
				el.blur();
				el.disabled = true;
			} else if (this.type === 'text') {
				navigator.clipboard.writeText(this.value);
			}
			this.showMsg();
		},
		showMsg() {
			this.showConfirmMsg = true;
			setTimeout(() => {
				this.showConfirmMsg = false;
			}, 2500);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

// .input-copy {}

.input-copy {
	--form-color-input-bg: var(--color-soft-white);
	--form-color-copy-success: var(--color-success);
	--form-color-border-active: transparent;

	&.-t-dark {
		--form-color-input-bg: var(--color-grey-900);
		--form-color-copy-success: var(--color-success);
		--form-color-border-active: var(--color-hype-yellow);
	}
}

.input-copy__click-w {
	position: relative;
}

.input-copy__input-w {
	position: relative;
}

.input-copy__text-w {
	display: flex;
	align-items: center;
	gap: $sp5;
	width: 100%;
	cursor: copy;

	.input-copy.-text & {
		gap: $sp3;
	}
}

.input-copy__hidden-input {
	@include visually-hidden;
}

.input-copy__text {
	margin-bottom: 0;

	&.-nowrap {
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
	}
}

.input-copy__confirm-msg-text {
	margin-bottom: 0;
	color: var(--form-color-copy-success);
}

::v-deep .v2-input-text__input {
	cursor: pointer !important; // stylelint-disable-line declaration-no-important
}

.input-copy__confirm-msg {
	position: absolute;
	left: 1px;
	bottom: 1px;
	width: calc(100% - 2px);
	height: calc(#{$form-input-height} - 2px);
	background-color: var(--form-color-input-bg);
	display: flex;
	align-items: center;
	padding-left: 16px;
	color: var(--form-color-copy-success);
	pointer-events: none;
	transition: opacity $trans-time-fast;
	border: 1px solid var(--form-color-border-active);
	z-index: 2;
}
</style>
