export const getArenaConfig = () => {
	return {
		domainKey:                       window.arenaConfig?.domainKey || null,
		isSmartTv:                       window.$Helpers?.isSmartTV(),
		isAsport:                        window.isAsport,
		isSwissLeague:                   window.isSwissLeague || false,
		isStvFsg:                        window.isStvFsg || false,
		isSwissVolley:                   window.isSwissVolley || false,
		isFanAt:                         window.isFanAt || false,
		upcomingEventIndex:              window.arenaConfig?.upcomingEventIndex ?? 1,
		showLeaguesAsSport:              window.arenaConfig?.showLeaguesAsSport || false,
		showTeamsAsSport:                window.arenaConfig?.showTeamsAsSport || false,
		disableLeagueMatchCenter:        window.arenaConfig?.disableLeagueMatchCenter || false,
		disableVideoCenter:              window.arenaConfig?.disableVideoCenter || false,
		enableSportsFilterInVideoCenter: window.arenaConfig?.enableSportsFilterInVideoCenter || false,
		enableDateFilterInVideoCenter:   window.arenaConfig?.enableDateFilterInVideoCenter || false,
		showEventCardTags:               window.arenaConfig?.showEventCardTags || false,
		headerImageLink:                 window.arenaConfig?.headerImageLink || null,
		enableLiveheader:                window.arenaConfig?.enableLiveheader || false,
		videoDownloadEnabled:            window.arenaConfig?.videoDownloadEnabled || false,
		imprint:                         window.arenaConfig?.imprint ?? 'asport',
		terms:                           window.arenaConfig?.terms ?? 'asport',
		about:                           window.arenaConfig?.about ?? null,
		headerImageUrl:                  window.arenaConfig?.headerImageUrl || null,
		footerImageUrl:                  window.arenaConfig?.footerImageUrl || null,
		socialMedia:                     window.arenaConfig?.socialMedia || [],
		cookieConsent:                   window.arenaConfig?.cookieConsent || false,
		cookieConsentRevisionNr:         window.arenaConfig?.cookieConsentRevisionNr || null,
		googleAnalyticsId:               window.arenaConfig?.googleAnalyticsId || null,
		menuItems:                       window.arenaConfig?.menuItems || null,
		additionalMenuItems:             window.arenaConfig?.additionalMenuItems || null,
		navigationSports:                window.arenaConfig?.navigationSports || null,
		enableNoSpoilerMode:             window.arenaConfig?.enableNoSpoilerMode || false,
		enableLogin:                     window.arenaParams?.enableLogin || false,
		type:                            window.arenaParams?.arenaContextableType || null,
		id:                              window.arenaParams?.arenaContextableId ?? null,
		isCustomDomain:                  window.arenaParams?.isCustomDomain || false,
		slug:                            window.arenaParams?.contextSlug || null,
		locales:                         window.arenaParams?.locales || [],
		languages:                       window.arenaConfig?.languages || [],
		defaultLanguage:                 window.arenaParams?.defaultLanguage,
		currentLanguage:                 window.arenaParams?.currentLanguage,
		currentLanguageKey:              window.locale || 'en',
		showPoweredBy:                   window.arenaParams?.showPoweredBy || false,
		showFooterOnEvent:               window.arenaParams?.showFooterOnEvent || false,
		headerSponsorsColored:           window.arenaParams?.headerSponsorsColored || false,
		arenaInfos:                      window.arenaParams?.arenaInfos || null,
		csrfToken:                       window.arenaParams?.csrfToken || '',
		contactEmail:                    window.arenaParams?.contactEmail || '',
		loadArenaProfile:                window.arenaParams?.loadArenaProfile || false,
		navItems:                        window.arenaParams?.navItems || null,
		isLightTheme:                    document.body.classList.contains('-t-light'),
		theme:                           window.arenaParams?.theme || 'asport',
		subTheme:                        window.arenaParams?.subTheme || null,
		geoblocking:                     window.arenaParams?.geoblocking || {},
		profileFields:                   window.arenaConfig?.profileFields || {},
	};
};
