export const throttle = (cb, delay) => {
	let onHold = false;
	let waitingArgs = null;

	return function() {
		if (!onHold) {
			cb.apply(this, arguments);
			onHold = true;
			setTimeout(() => {
				if (waitingArgs) {
					cb.apply(this, waitingArgs);
					waitingArgs = null;
				}
				onHold = false;
			}, delay);
		} else {
			waitingArgs = arguments;
		}
	};
};
