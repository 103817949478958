<template>
	<div class="header-sponsors">
		<component
			:is="sponsor.translatedLink ? 'a' : 'div'"
			v-for="(sponsor, i) in internalSponsors"
			:key="i"
			:href="sponsor.translatedLink ? sponsor.translatedLink : null"
			target="_blank"
			class="header-sponsors__sponsor"
		>
			<img
				class="header-sponsors__img"
				:class="[!$arenaConfig.headerSponsorsColored ? '-grayscale' : '']"
				:src="sponsor.imageUrl"
			>
		</component>
	</div>
</template>

<script>
export default {
	name:  'HeaderSponsors',
	props: {
		sponsors: {
			type:    Array,
			default: () => [],
		},
	},
	computed: {
		internalSponsors() {
			return this.sponsors.map((sponsor) => {
				if (typeof sponsor === 'string') {
					return {
						imageUrl: sponsor,
					};
				}

				return sponsor;
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.header-sponsors {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--color-grey-100);
	padding: $sp3 $sp2;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		background-color: var(--color-grey-900);
		@supports (gap: 1rem) {
			gap: $sp3-5;
		}
	}

	@include size(sm-up) {
		@supports (gap: 1rem) {
			gap: $sp4;
		}
	}

	@include size(md-up) {
		padding: $sp4 $sp3;

		@supports (gap: 1rem) {
			gap: $sp6;
		}
	}

	@include size(lg-up) {
		padding: $sp5 $sp3;

		@supports (gap: 1rem) {
			gap: $sp7-5;
		}
	}
}

.header-sponsors__sponsor {
	width: calc(100vw / 8);
	display: flex;
	justify-content: center;
	align-items: center;

	&:not(:last-child) {
		margin-right: $sp3-5;

		@include size(sm-up) {
			margin-right: $sp4;
		}

		@include size(md-up) {
			margin-right: $sp6;
		}

		@include size(lg-up) {
			margin-right: $sp7-5;
		}

		@supports (gap: 1rem) {
			margin-right: 0 !important; // stylelint-disable-line declaration-no-important
		}
	}

	@include size(md-up) {
		width: calc(100vw / 9);
	}

	@include size(lg-up) {
		width: calc((100vw - (100vw - #{$grid-container-width})) / 9);
	}
}

.header-sponsors__img {
	width: 100%;
	max-height: calc(100vw / 15);
	object-fit: contain;

	&.-grayscale {
		filter: grayscale(1);
		mix-blend-mode: darken;
	}

	@include size(lg-up) {
		max-height: 80px;
	}
}
</style>
