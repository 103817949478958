export const CategoryChild = {
	MATCH_LEAGUE:                                 'league',
	MATCH_CUP:                                    'cup',
	MATCH_FRIENDLY:                               'friendly',
	MATCH_INTERNATIONAL:                          'international',
	TRAINING_FREEPRACTICE:                        'freepractice',
	TRAINING_HALFCOURTS:                          'halfcourts',
	TRAINING_TACTICS:                             'tactics',
	TRAINING_MATCH:                               'match',
	OTHER_COMPETITION:                            'competition',
	OTHER_INTERVIEW:                              'interview',
	OTHER_WELCOME:                                'welcome',
	OTHER_OPENING:                                'opening',
	OTHER_PRESENTATION:                           'presentation',
	OTHER_MAGAZINE:                               'magazine',
	OTHER_STUDIO:                                 'studio',
	OTHER_EDUCATION:                              'education',
	OTHER_CONFERENCE:                             'conference',
	OTHER_MEETING:                                'meeting',
	ASSET_INTRO_OUTRO:                            'intro_outro',
	ASSET_VIDEO_AD:                               'video_ad',
	SKI_ALPINE_DOWNHILL:                          'downhill',
	SKI_ALPINE_SUPER_G:                           'super_g',
	SKI_ALPINE_SLALOM:                            'slalom',
	SKI_ALPINE_SLALOM_LARGE:                      'slalom_large',
	SKI_ALPINE_COMBINED:                          'combined',
	SKI_ALPINE_PARALLEL:                          'parallel',
	SKI_ALPINE_TEAM_EVENT:                        'team_event',
	SKI_CROSS_MULTI_4:                            'multi_4',
	SKI_CROSS_COUNTRY_SINGLE_10:                  'single_10',
	SKI_CROSS_COUNTRY_SINGLE_15:                  'single_15',
	SKI_CROSS_COUNTRY_MULTI_15:                   'multi_15',
	SKI_CROSS_COUNTRY_MULTI_30:                   'multi_30',
	SKI_CROSS_COUNTRY_MASS_START_10:              'mass_start_10',
	SKI_CROSS_COUNTRY_MASS_START_15:              'mass_start_15',
	SKI_CROSS_COUNTRY_MASS_START_30:              'mass_start_30',
	SKI_CROSS_COUNTRY_MASS_START_50:              'mass_start_50',
	SKI_CROSS_COUNTRY_CHASING_30:                 'chasing_30',
	SKI_CROSS_COUNTRY_CHASING_50:                 'chasing_50',
	SKI_CROSS_COUNTRY_SEASON_4X5:                 'season_4X5',
	SKI_CROSS_COUNTRY_SEASON_4X10:                'season_4X10',
	SKI_CROSS_COUNTRY_TOUR_DE_SKI:                'tour_de_ski',
	SKI_CROSS_COUNTRY_MINI_TOUR:                  'mini_tour',
	SKI_CROSS_COUNTRY_SPRINT:                     'sprint',
	SKI_CROSS_COUNTRY_SPRINT_TEAM:                'sprint_team',
	SKI_NORDIC_COMBINED_SINGLE:                   'single',
	SKI_NORDIC_COMBINED_TEAM:                     'team',
	SKI_NORDIC_COMBINED_SPRINT_TEAM:              'sprint_team',
	SKI_JUMPING_NORMAL_HILL:                      'normal_hill',
	SKI_JUMPING_LARGE_HILL:                       'large_hill',
	SKI_JUMPING_TEAM_JUMPING:                     'team_jumping',
	SKI_JUMPING_FLYING:                           'flying',
	SKI_BIATHLON_SINGLE:                          'single',
	SKI_BIATHLON_SPRINT:                          'sprint',
	SKI_BIATHLON_PURSUIT:                         'pursuit',
	SKI_BIATHLON_MASS_START:                      'mass_start',
	SKI_BIATHLON_SEASON:                          'season',
	SKI_BIATHLON_SEASON_MIXED:                    'season_mixed',
	SKI_BIATHLON_SEASON_MIXED_SINGLE:             'season_mixed_single',
	SNOWBOARD_FREESTYLE:                          'freestyle',
	SNOWBOARD_ALPINE:                             'alpine',
	SNOWBOARD_CROSS:                              'cross',
	SKI_FREESTYLE_SLOPE_STYLE:                    'slope_style',
	SKI_FREESTYLE_BIG_AIR:                        'big_air',
	SKI_FREESTYLE_HALFPIPE:                       'halfpipe',
	SKI_MOGULS_SINGLE:                            'single',
	SKI_MOGULS_DUAL:                              'dual',
	SKI_TELEMARK_CLASSIC:                         'classic',
	SKI_TELEMARK_SPRINT_CLASSIC:                  'sprint_classic',
	SKI_TELEMARK_SPRINT_PARALLEL:                 'sprint_parallel',
	SKI_MOUNTAINEERING_SPRINT_WOMEN:              'sprint_women',
	SKI_MOUNTAINEERING_SPRINT_MEN:                'sprint_men',
	SKI_MOUNTAINEERING_SINGLE_WOMEN:              'single_women',
	SKI_MOUNTAINEERING_SINGLE_MEN:                'single_men',
	SKI_MOUNTAINEERING_MIXED:                     'mixed',
	FIGURE_SKATING_SINGLES:                       'singles',
	FIGURE_SKATING_PAIR:                          'pair',
	FIGURE_SKATING_ICE_DANCE:                     'ice_dance',
	NATURAL_TRACK_LUGE_SINGLE:                    'single',
	NATURAL_TRACK_LUGE_DOUBLE:                    'double',
	NATURAL_TRACK_LUGE_TEAM:                      'team',
	ARTIFICIAL_TRACK_LUGE_SINGLE:                 'single',
	ARTIFICIAL_TRACK_LUGE_DOUBLE:                 'double',
	ARTIFICIAL_TRACK_LUGE_TEAM:                   'team',
	ROAD_CYCLING_SINGLE:                          'single',
	ROAD_CYCLING_MASS_START:                      'mass_start',
	ROAD_CYCLING_TEAM_RESULT:                     'team_result',
	ROAD_CYCLING_TEAM_TIME:                       'team_time',
	TRACK_CYCLING_SINGLE_CHASE:                   'single_chase',
	TRACK_CYCLING_TEAM_CHASE:                     'team_chase',
	TRACK_CYCLING_SCRATCH:                        'scratch',
	TRACK_CYCLING_ELIMINATION:                    'elimination',
	TRACK_CYCLING_POINTS:                         'points',
	TRACK_CYCLING_SPEED:                          'speed',
	TRACK_CYCLING_OMNIUM:                         'omnium',
	TRACK_CYCLING_MADISON:                        'madison',
	TRACK_CYCLING_SPRINT:                         'sprint',
	TRACK_CYCLING_TEAM_SPRINT:                    'team_sprint',
	TRACK_CYCLING_KEIRIN:                         'keirin',
	TRACK_CYCLING_TIME:                           'time',
	MOUNTAIN_BIKE_CROSS_COUNTRY:                  'cross_country',
	MOUNTAIN_BIKE_SHORT_TRACK:                    'short_track',
	MOUNTAIN_BIKE_MARATHON:                       'marathon',
	MOUNTAIN_BIKE_CROSS_COUNTRY_ELIMINATOR:       'cross_country_eliminator',
	MOUNTAIN_BIKE_DOWNHILL:                       'downhill',
	MOUNTAIN_BIKE_FOUR_CROSS:                     'four_cross',
	MOUNTAIN_BIKE_ELECTRIC:                       'electric',
	MOUNTAIN_BIKE_PUMP:                           'pump',
	MOUNTAIN_BIKE_ENDURO:                         'enduro',
	MOUNTAIN_BIKE_DIRT_JUMP:                      'dirt_jump',
	MOUNTAIN_BIKE_SLOPE_STYLE:                    'slope_style',
	MOUNTAIN_BIKE_BIG_MOUNTAIN:                   'big_mountain',
	CYCLO_CROSS_RACE:                             'race',
	CHEERSPORT_CHEERLEADING:                      'cheerleading',
	CHEERSPORT_PERFORMANCE_CHEER:                 'performance_cheer',
	DARTS_SINGLE:                                 'single',
	DARTS_DOUBLE:                                 'double',
	FENCING_FOIL:                                 'foil',
	FENCING_EPEE:                                 'epee',
	FENCING_SABRE:                                'sabre',
	FENCING_TEAM:                                 'team',
	KARATE_KATA:                                  'kata',
	KARATE_KUMITE:                                'kumite',
	KARATE_KIHON:                                 'kihon',
	KICKBOXING_SEMI_CONTACT:                      'semi_contact',
	KICKBOXING_LIGHT_CONTACT:                     'light_contact',
	KICKBOXING_FULL_CONTACT:                      'full_contact',
	KICKBOXING_POINT_FIGHTING:                    'point_fighting',
	KICKBOXING_KICK_LIGHT:                        'kick_light',
	KICKBOXING_MUAY_THAI:                         'muay_thai',
	ATHLETICS_SPRINT_100M:                        'sprint_100m',
	ATHLETICS_SPRINT_200M:                        'sprint_200m',
	ATHLETICS_SPRINT_400M:                        'sprint_400m',
	ATHLETICS_MIDDLE_800M:                        'middle_800m',
	ATHLETICS_MIDDLE_1500M:                       'middle_1500m',
	ATHLETICS_MIDDLE_3000M:                       'middle_3000m',
	ATHLETICS_LONG_5000M:                         'long_5000m',
	ATHLETICS_LONG_10000M:                        'long_10000m',
	ATHLETICS_3000M_STEEPLECHASE:                 '3000m_steeplechase',
	ATHLETICS_RELAY_4X100M:                       'relay_4x100m',
	ATHLETICS_RELAY_4X400M:                       'relay_4x400m',
	ATHLETICS_HURDLES_100M:                       'hurdles_100m',
	ATHLETICS_HURDLES_110M:                       'hurdles_110m',
	ATHLETICS_HURDLES_400M:                       'hurdles_400m',
	ATHLETICS_HALF_MARATHON:                      'half_marathon',
	ATHLETICS_MARATHON:                           'marathon',
	ATHLETICS_RACE_WALK_20KM:                     'race_walk_20km',
	ATHLETICS_RACE_WALK_50KM:                     'race_walk_50km',
	ATHLETICS_HIGH_JUMP:                          'high_jump',
	ATHLETICS_POLE_VAULT:                         'pole_vault',
	ATHLETICS_LONG_JUMP:                          'long_jump',
	ATHLETICS_TRIPLE_JUMP:                        'triple_jump',
	ATHLETICS_SHOT_PUT:                           'shot_put',
	ATHLETICS_DISCUS_THROW:                       'discus_throw',
	ATHLETICS_HAMMER_THROW:                       'hammer_throw',
	ATHLETICS_JAVELIN_THROW:                      'javelin_throw',
	ATHLETICS_HEPTATHLON:                         'heptathlon',
	ATHLETICS_DECATHLON:                          'decathlon',
	MODERN_PENTATHLON_SHOOTING:                   'shooting',
	MODERN_PENTATHLON_FENCING:                    'fencing',
	MODERN_PENTATHLON_SWIMMING:                   'swimming',
	MODERN_PENTATHLON_RIDING:                     'riding',
	MODERN_PENTATHLON_RUNNING:                    'running',
	ORIENTEERING_SPRINT:                          'sprint',
	ORIENTEERING_SPRINT_MIXED:                    'sprint_mixed',
	ORIENTEERING_MIDDLE_DISTANCE:                 'middle_distance',
	ORIENTEERING_LONG_DISTANCE:                   'long_distance',
	ORIENTEERING_RELAY:                           'relay',
	RACKETLON_TABLE_TENNIS:                       'table_tennis',
	RACKETLON_TABLE_TENNIS_DOUBLE:                'table_tennis_double',
	RACKETLON_BADMINTON:                          'badminton',
	RACKETLON_BADMINTON_DOUBLE:                   'badminton_double',
	RACKETLON_SQUASH:                             'squash',
	RACKETLON_SQUASH_DOUBLE:                      'squash_double',
	RACKETLON_TENNIS:                             'tennis',
	RACKETLON_TENNIS_DOUBLE:                      'tennis_double',
	ROPE_SKIPPING_SPEED:                          'speed',
	ROPE_SKIPPING_FREESTYLE:                      'freestyle',
	ROPE_SKIPPING_DOUBLE_DUTCH:                   'double_dutch',
	ROPE_SKIPPING_SHOW:                           'show',
	SPORTS_BOWLING_CLASSIC:                       'classic',
	SPORTS_BOWLING_BOHLE:                         'bohle',
	SPORTS_BOWLING_SCISSORS:                      'scissors',
	SPORTS_BOWLING_BOWLING:                       'bowling',
	ARTISTIC_SWIMMING_SOLO:                       'solo',
	ARTISTIC_SWIMMING_DUO:                        'duo',
	ARTISTIC_SWIMMING_GROUP:                      'group',
	ARTISTIC_SWIMMING_COMBINATION:                'combination',
	DANCING_BOOGIE_WOOGIE:                        'boogie_woogie',
	DANCING_ROCK_N_ROLL:                          'rock_n_roll',
	DANCING_BREAKING:                             'breaking',
	DANCING_HIP_HOP:                              'hip_hop',
	TRIATHLON_SPRINT:                             'sprint',
	TRIATHLON_SHORT_DISTANCE:                     'short_distance',
	TRIATHLON_LONG_DISTANCE:                      'long_distance',
	TRIATHLON_IRONMAN:                            'ironman',
	TRIATHLON_CROSS:                              'cross',
	TRIATHLON_TEAM:                               'team',
	DIVING_SPRINGBOARD_1M:                        'springboard_1m',
	DIVING_SPRINGBOARD_3M:                        'springboard_3m',
	DIVING_SPRINGBOARD_SYNCHRONISED_3M:           'springboard_synchronised_3m',
	DIVING_PLATFORM_5M:                           'platform_5m',
	DIVING_PLATFORM_7_5M:                         'platform_7_5m',
	DIVING_PLATFORM_10M:                          'platform_10m',
	DIVING_PLATFORM_SYNCHRONISED_10M:             'platform_synchronised_10m',
	TENNIS_WOMEN_SINGLES:                         'women_singles',
	TENNIS_WOMEN_DOUBLES:                         'women_doubles',
	TENNIS_MEN_SINGLES:                           'men_singles',
	TENNIS_MEN_DOUBLES:                           'men_doubles',
	TENNIS_MIXED:                                 'mixed',
	BEACH_TENNIS_SINGLE:                          'single',
	BEACH_TENNIS_DOUBLE:                          'double',
	BEACH_TENNIS_MIXED:                           'mixed',
	WHEELCHAIR_BOCCIA_SINGLE:                     'single',
	WHEELCHAIR_BOCCIA_DOUBLE:                     'double',
	WHEELCHAIR_BOCCIA_TEAM:                       'team',
	ICE_STOCK_SPORT_TEAM_GAME:                    'team_game',
	ICE_STOCK_SPORT_DISTANCE:                     'distance',
	ICE_STOCK_SPORT_TARGET:                       'target',
	INLINE_SKATING_ARTISTIC:                      'artistic',
	INLINE_SKATING_SPEED_SKATING:                 'speed_skating',
	INLINE_SKATING_ALPINE:                        'alpine',
	WATER_SKIING_SLALOM:                          'slalom',
	WATER_SKIING_JUMP:                            'jump',
	WATER_SKIING_TRICK:                           'trick',
	WATER_SKIING_COMBINATION:                     'combination',
	WAKEBOARDING_SKIM:                            'skim',
	WAKEBOARDING_SURF:                            'surf',
	MINIGOLF_CONCRETE:                            'concrete',
	MINIGOLF_MINIATURE_GOLF:                      'miniature_golf',
	MINIGOLF_FELT:                                'felt',
	MINIGOLF_STAR_GOLF:                           'star_golf',
	MINIGOLF_ADVENTURE_GOLF:                      'adventure_golf',
	MINIGOLF_CRAZY_GOLF:                          'crazy_golf',
	SQUASH_MATCH:                                 'match',
	CANOE_SPRINT_KAYAK_SINGLE:                    'kayak_single',
	CANOE_SPRINT_KAYAK_DOUBLE:                    'kayak_double',
	CANOE_SPRINT_KAYAK_QUAD:                      'kayak_quad',
	CANOE_SPRINT_CANADIAN_SINGLE:                 'canadian_single',
	CANOE_SPRINT_CANADIAN_DOUBLE:                 'canadian_double',
	CANOE_SLALOM_KAYAK:                           'kayak',
	CANOE_SLALOM_CANADIAN:                        'canadian',
	CANOE_WILD_WATER_KAYAK_SINGLE:                'kayak_single',
	CANOE_WILD_WATER_CANADIAN_SINGLE:             'canadian_single',
	CANOE_WILD_WATER_CANADIAN_DOUBLE:             'canadian_double',
	GYMNASTICS_ARTISTIC_FLOOR:                    'floor',
	GYMNASTICS_ARTISTIC_POMMEL_HORSE:             'pommel_horse',
	GYMNASTICS_ARTISTIC_STILL_RINGS:              'still_rings',
	GYMNASTICS_ARTISTIC_VAULT:                    'vault',
	GYMNASTICS_ARTISTIC_HORIZONTAL_BAR:           'horizontal_bar',
	GYMNASTICS_ARTISTIC_RECK:                     'reck',
	GYMNASTICS_ARTISTIC_UNEVEN_BARS:              'uneven_bars',
	GYMNASTICS_ARTISTIC_BALANCE_BEAM:             'balance_beam',
	GYMNASTICS_ARTISTIC_ALL_AROUND:               'all_around',
	GYMNASTICS_ARTISTIC_TEAM:                     'team',
	GYMNASTICS_ACROBATIC_PAIR_WOMEN:              'pair_women',
	GYMNASTICS_ACROBATIC_PAIR_MEN:                'pair_men',
	GYMNASTICS_ACROBATIC_PAIR_MIXED:              'pair_mixed',
	GYMNASTICS_ACROBATIC_GROUP_WOMEN:             'group_women',
	GYMNASTICS_ACROBATIC_GROUP_MEN:               'group_men',
	GYMNASTICS_TRAMPOLINE_TRAMPOLINE:             'trampoline',
	GYMNASTICS_TRAMPOLINE_TUMBLING:               'tumbling',
	GYMNASTICS_TRAMPOLINE_DOUBLE_MINI_TRAMPOLINE: 'double_mini_trampoline',
	GYMNASTICS_TRAMPOLINE_SINGLE:                 'single',
	GYMNASTICS_TRAMPOLINE_SYNCHRONISED:           'synchronised',
	JU_JITSU_DUO:                                 'duo',
	JU_JITSU_FIGHTING:                            'fighting',
	JU_JITSU_NE_WAZA:                             'ne_waza',
	SPORT_CLIMBING_LEAD:                          'lead',
	SPORT_CLIMBING_BOULDER:                       'boulder',
	SPORT_CLIMBING_SPEED:                         'speed',
	BILLARD_CAROM:                                'carom',
	BILLARD_POOL:                                 'pool',
	BILLARD_SNOOKER:                              'snooker',
	ARCHERY_RECURVE:                              'recurve',
	ARCHERY_COMPBOUND:                            'compbound',
	ARCHERY_BAREBOW:                              'barebow',
	POWERLIFTING_LIGHTWEIGHT_MEN:                 'lightweight_men',
	POWERLIFTING_LIGHTWEIGHT_WOMEN:               'lightweight_women',
	POWERLIFTING_MIDDLEWEIGHT_MEN:                'middleweight_men',
	POWERLIFTING_MIDDLEWEIGHT_WOMEN:              'middleweight_women',
	POWERLIFTING_HEAVYWEIGHT_MEN:                 'heavyweight_men',
	POWERLIFTING_HEAVYWEIGHT_WOMEN:               'heavyweight_women',
	MUAYTHAI_MUAY:                                'muay',
	SPEED_SKATING_SHORTTRACK:                     'shorttrack',
	SPEED_SKATING_500M:                           '500m',
	SPEED_SKATING_1000M:                          '1000m',
	SPEED_SKATING_1500M:                          '1500m',
	SPEED_SKATING_3000M:                          '3000m',
	SPEED_SKATING_5000M:                          '5000m',
	SPEED_SKATING_10000M:                         '10000m',
	SPEED_SKATING_SMALL_FOUR_COMPETITION:         'small_four_competition',
	SPEED_SKATING_LARGE_FOUR_COMPETITION:         'large_four_competition',
	SPEED_SKATING_TEAM_PURSUITS:                  'team_pursuits',
	SPEED_SKATING_MASS_START:                     'mass_start',
	SPEED_SKATING_LONG_DISTANCE:                  'long_distance',
	CURLING_MEN:                                  'men',
	CURLING_WOMEN:                                'women',
	CURLING_MIXED_DOUBLE:                         'mixed_double',
	GRASS_SKIING_SLALOM_LARGE:                    'slalom_large',
	GRASS_SKIING_SLALOM:                          'slalom',
	GRASS_SKIING_SUPER_G:                         'super_g',
	GRASS_SKIING_SUPER_COMBINATION:               'super_combination',
	GRASS_SKIING_PARALLEL_COMPETITION:            'parallel_competition',
	TAEKWONDO_LIGHT_CONTACT:                      'light_contact',
	TAEKWONDO_SEMI_CONTACT:                       'semi_contact',
	TAEKWONDO_CONTACT:                            'contact',
	TABLE_FOOTBALL_SINGLE:                        'single',
	TABLE_FOOTBALL_DOUBLE:                        'double',
	CHESS_TOURNAMENT:                             'tournament',
	CHESS_SPEED:                                  'speed',
	CHESS_BLITZ:                                  'blitz',
	SWIMMING_50M_FREESTYLE:                       '50m_freestyle',
	SWIMMING_100M_BREASTSTROKE:                   '100m_breaststroke',
	SWIMMING_100M_FREESTYLE:                      '100m_freestyle',
	SWIMMING_100M_BACKSTROKE:                     '100m_backstroke',
	SWIMMING_100M_BUTTERFLY:                      '100m_butterfly',
	SWIMMING_200M_BREASTSTROKE:                   '200m_breaststroke',
	SWIMMING_200M_INDIVIDUAL_MEDLEY:              '200m_individual_medley',
	SWIMMING_200M_FREESTYLE:                      '200m_freestyle',
	SWIMMING_200M_BACKSTROKE:                     '200m_backstroke',
	SWIMMING_200M_BUTTERFLY:                      '200m_butterfly',
	SWIMMING_400M_INDIVIDUAL_MEDLEY:              '400m_individual_medley',
	SWIMMING_400M_FREESTYLE:                      '400m_freestyle',
	SWIMMING_800M_FREESTYLE:                      '800m_freestyle',
	SWIMMING_1500M_FREESTYLE:                     '1500m_freestyle',
	SWIMMING_4X100M_FREESTYLE_RELAY:              '4x100m_freestyle_relay',
	SWIMMING_4X100M_MEDLEY_RELAY:                 '4x100m_medley_relay',
	SWIMMING_4X200M_FREESTYLE_RELAY:              '4x200m_freestyle_relay',
	SWIMMING_MIXED_4X100M_MEDLEY_RELAY:           'mixed_4x100m_medley_relay',
	BADMINTON_SINGLE:                             'single',
	BADMINTON_DOUBLE:                             'double',
	BADMINTON_MIXED_DOUBLE:                       'mixed_double',
	JUDO_WOMEN_48:                                'women_48',
	JUDO_WOMEN_52:                                'women_52',
	JUDO_WOMEN_57:                                'women_57',
	JUDO_WOMEN_63:                                'women_63',
	JUDO_WOMEN_70:                                'women_70',
	JUDO_WOMEN_78:                                'women_78',
	JUDO_WOMEN_78_PLUS:                           'women_78_plus',
	JUDO_MEN_60:                                  'men_60',
	JUDO_MEN_66:                                  'men_66',
	JUDO_MEN_73:                                  'men_73',
	JUDO_MEN_81:                                  'men_81',
	JUDO_MEN_90:                                  'men_90',
	JUDO_MEN_100:                                 'men_100',
	JUDO_MEN_100_PLUS:                            'men_100_plus',
	JUDO_MIXED_TEAM:                              'mixed_team',
	PADEL_OPEN:                                   'open',
	PADEL_MEN_DOUBLE:                             'men_double',
	PADEL_WOMEN_DOUBLE:                           'women_double',
	SHOOTING_SPORTS_AIR_RIFLE_10:                 'air_rifle_10',
	SHOOTING_SPORTS_AIR_GUN_10:                   'air_gun_10',
	SHOOTING_SPORTS_SPORT_GUN_25:                 'sport_gun_25',
	SHOOTING_SPORTS_THREE_KK_50:                  'three_kk_50',
	SHOOTING_SPORTS_SKEET:                        'skeet',
	SHOOTING_SPORTS_TRAP:                         'trap',
	SHOOTING_SPORTS_FAST_GUN_25:                  'fast_gun_25',
	SHOOTING_SPORTS_THREE_RIFLE_50:               'three_rifle_50',
	SHOOTING_SPORTS_MIXED_TEAM:                   'mixed_team',
	TABLE_TENNIS_SINGLE:                          'single',
	TABLE_TENNIS_DOUBLE:                          'double',
	TABLE_TENNIS_MIXED_DOUBLE:                    'mixed_double',
	TEQBALL_SINGLE:                               'single',
	TEQBALL_DOUBLE:                               'double',
	TEQBALL_MIXED_DOUBLE:                         'mixed_double',
	BOXING_WOMEN_FEATHERWEIGHT:                   'women_featherweight',
	BOXING_WOMEN_FLYWEIGHT:                       'women_flyweight',
	BOXING_WOMEN_LIGHTWEIGHT:                     'women_lightweight',
	BOXING_WOMEN_WELTERWEIGHT:                    'women_welterweight',
	BOXING_WOMEN_MIDDLEWEIGHT:                    'women_middleweight',
	BOXING_MEN_FEATHERWEIGHT:                     'men_featherweight',
	BOXING_MEN_FLYWEIGHT:                         'men_flyweight',
	BOXING_MEN_LIGHTWEIGHT:                       'men_lightweight',
	BOXING_MEN_WELTERWEIGHT:                      'men_welterweight',
	BOXING_MEN_MIDDLEWEIGHT:                      'men_middleweight',
	BOXING_MEN_LIGHT_HEAVYWEIGHT:                 'men_light_heavyweight',
	BOXING_MEN_HEAVYWEIGHT:                       'men_heavyweight',
	BOXING_MEN_SUPER_HEAVYWEIGHT:                 'men_super_heavyweight',
	SKATEBOARDING_PARK:                           'park',
	SKATEBOARDING_STREET:                         'street',
	PADBOL_MEN_DOUBLE:                            'men_double',
	PADBOL_WOMEN_DOUBLE:                          'women_double',
	AEROBIC_SINGLE:                               'single',
	AEROBIC_PAIR:                                 'pair',
	AEROBIC_GROUP_3:                              'group_3',
	AEROBIC_GROUP_4:                              'group_4',
	AEROBIC_GROUP_5:                              'group_5',
	GYMNASTICS_RHYTHMIC_SINGLE:                   'single',
	GYMNASTICS_RHYTHMIC_GROUP:                    'group',
	GYMNASTICS_NATIONAL_HIGH_LONG_JUMP:           'high_long_jump',
	GYMNASTICS_NATIONAL_WRESTLING:                'wrestling',
	GYMNASTICS_NATIONAL_SUISSE_WRESTLING:         'suisse_wrestling',
	GYMNASTICS_NATIONAL_STONE_LIFTING:            'stone_lifting',
	GYMNASTICS_NATIONAL_STONE_THROWING:           'stone_throwing',
	PARKOUR_SPEED:                                'speed',
	PARKOUR_FREESTYLE:                            'freestyle',
	GYMNASTICS_BASE_SINGLE:                       'single',
	GYMNASTICS_BASE_PAIRS:                        'pairs',
	GYMNASTICS_BASE_TEAM:                         'team',
	GYMNASTICS_WHEEL_LINE:                        'line',
	GYMNASTICS_WHEEL_SPIRAL:                      'spiral',
	GYMNASTICS_WHEEL_JUMP:                        'jump',
	GYMNASTICS_WHEEL_PAIRS:                       'pairs',
	GYMNASTICS_WHEEL_CLUB:                        'club',
	GYMNASTICS_APPARATUS_FLOOR:                   'floor',
	GYMNASTICS_APPARATUS_HORIZONTAL:              'horizontal',
	GYMNASTICS_APPARATUS_RINGS:                   'rings',
	GYMNASTICS_APPARATUS_VAULT:                   'vault',
	GYMNASTICS_APPARATUS_BARS_PARALLEL:           'bars_parallel',
	GYMNASTICS_APPARATUS_BARS_UNEVEN:             'bars_uneven',
	GYMNASTICS_APPARATUS_COMBINATION:             'combination',
	BOBSLEIGH_MONOBOB:                            'monobob',
	BOBSLEIGH_FOUR_MEN:                           'four_men',
	BOBSLEIGH_TWO_MEN:                            'two_men',
	BOBSLEIGH_TWO_WOMEN:                          'two_women',
	SKELETON_MEN:                                 'men',
	SKELETON_WOMEN:                               'women',
	TURNEN_SPECIALIST_TESTS:                      'specialist_tests',
	TURNEN_FIT_FUN:                               'fit_fun',
	TURNEN_FIT_HEALTHY:                           'fit_healthy',
	TURNEN_ADULTS:                                'adults',
	TURNEN_YOUTH:                                 'youth',
	PARA_ATHLETICS_SPRINT_100M:                   'sprint_100m',
	PARA_ATHLETICS_SPRINT_200M:                   'sprint_200m',
	PARA_ATHLETICS_SPRINT_400M:                   'sprint_400m',
	PARA_ATHLETICS_MIDDLE_800M:                   'middle_800m',
	PARA_ATHLETICS_MIDDLE_1000M:                  'middle_1000m',
	PARA_ATHLETICS_MIDDLE_1500M:                  'middle_1500m',
	PARA_ATHLETICS_MIDDLE_3000M:                  'middle_3000m',
	PARA_ATHLETICS_LONG_5000M:                    'long_5000m',
	PARA_ATHLETICS_LONG_10000M:                   'long_10000m',
	PARA_ATHLETICS_RELAY_4X100M:                  'relay_4x100m',
	PARA_ATHLETICS_RELAY_4X400M:                  'relay_4x400m',
	PARA_ATHLETICS_HALF_MARATHON:                 'half_marathon',
	PARA_ATHLETICS_MARATHON:                      'marathon',
	PARA_ATHLETICS_HIGH_JUMP:                     'high_jump',
	PARA_ATHLETICS_LONG_JUMP:                     'long_jump',
	PARA_ATHLETICS_TRIPLE_JUMP:                   'triple_jump',
	PARA_ATHLETICS_SHOT_PUT:                      'shot_put',
	PARA_ATHLETICS_CLUB_THROW:                    'club_throw',
	PARA_ATHLETICS_DISCUS_THROW:                  'discus_throw',
	PARA_ATHLETICS_JAVELIN_THROW:                 'javelin_throw',
	PARA_ATHLETICS_HEPTATHLON:                    'heptathlon',
	PARA_ATHLETICS_DECATHLON:                     'decathlon',
	WRESTLING_FREESTYLE:                          'freestyle',
	WRESTLING_GRECO_ROMAN:                        'greco_roman',
	WEIGHTLIFTING_W_45:                           'W_45',
	WEIGHTLIFTING_W_49:                           'W_49',
	WEIGHTLIFTING_W_55:                           'W_55',
	WEIGHTLIFTING_W_59:                           'W_59',
	WEIGHTLIFTING_W_64:                           'W_64',
	WEIGHTLIFTING_M_55:                           'M_55',
	WEIGHTLIFTING_M_61:                           'M_61',
	WEIGHTLIFTING_M_67:                           'M_67',
	WEIGHTLIFTING_M_73:                           'M_73',
	DISCGOLF_STROKE_PLAY:                         'stroke_play',
	DISCGOLF_DOUBLE:                              'double',
	DISCGOLF_TEAM:                                'team',
	SAILING_FINN_DINGHY:                          'finn_dinghy',
	SAILING_470ER:                                '470er',
	SAILING_LASER:                                'laser',
	SAILING_49ER:                                 '49er',
	SAILING_STAR:                                 'star',
	SAILING_LASER_RADIAL:                         'laser_radial',
	SAILING_49ER_FX:                              '49er_fx',
	SAILING_EUROPE:                               'europe',
	SAILING_YNGLING:                              'yngling',
	SAILING_ELLIOT_6_M:                           'elliot_6_m',
	SAILING_NACRA_17:                             'nacra_17',
	GOLF_18_HOLE:                                 '18_hole',
	GOLF_9_HOLE:                                  '9_hole',
	ROWING_SINGLE:                                'single',
	ROWING_PAIR:                                  'pair',
	ROWING_DOUBLE:                                'double',
	ROWING_FOUR:                                  'four',
	ROWING_QUAD:                                  'quad',
	ROWING_EIGHT:                                 'eight',
	EQUESTRIAN_DRESSAGE:                          'dressage',
	EQUESTRIAN_SHOW_JUMPING:                      'show_jumping',
	EQUESTRIAN_DRIVING:                           'driving',
	EQUESTRIAN_VAULTING:                          'vaulting',
	EQUESTRIAN_ENDURANCE_RIDING:                  'endurance_riding',
	PARA_ARCHERY_W1:                              'w1',
	PARA_ARCHERY_OPEN:                            'open',
	PARA_BADMINTON_SINGLE:                        'single',
	PARA_BADMINTON_DOUBLE:                        'double',
	PARA_BADMINTON_MIXED_DOUBLE:                  'mixed_double',
	PARA_CANOE_SINGLE_KAYAK:                      'single_kayak',
	PARA_CANOE_DOUBLE_KAYAK:                      'double_kayak',
	PARA_CANOE_QUAD_KAYAK:                        'quad_kayak',
	PARA_CANOE_SINGLE_CANOE:                      'single_canoe',
	PARA_CANOE_DOUBLE_CANOE:                      'double_canoe',
	PARA_CYCLING_TRACK_TIME_TRIAL:                'time_trial',
	PARA_CYCLING_TRACK_INDIVIDUAL_PURSUIT:        'individual_pursuit',
	PARA_CYCLING_TRACK_TEAM_PURSUIT:              'team_pursuit',
	PARA_CYCLING_ROAD_INDIVIDUAL_TIME_TRIAL:      'individual_time_trial',
	PARA_CYCLING_ROAD_MASS_START:                 'mass_start',
	PARA_CYCLING_ROAD_TEAM_RANKING:               'team_ranking',
	PARA_CYCLING_ROAD_TEAM_TIME_TRIAL:            'team_time_trial',
	PARA_EQUESTRIAN_PARA_DRESSAGE:                'para_dressage',
	PARA_JUDO_B1:                                 'b1',
	PARA_JUDO_B2_B3:                              'b2-b3',
	PARA_JUDO_MIXED_TEAM:                         'mixed_team',
	PARA_POWERLIFTING_WOMEN:                      'women',
	PARA_POWERLIFTING_MEN:                        'men',
	PARA_ROWING_PR1_SINGLE:                       'pr1_single',
	PARA_ROWING_PR2_MIXED_DOUBLE:                 'pr2_mixed_double',
	PARA_ROWING_PR3_MIXED_FOUR:                   'pr3_mixed_four',
	PARA_ROWING_PR3_MIXED_DOUBLE_FOUR:            'pr3_mixed_double_four',
	PARA_SWIMMING_50M_FREESTYLE:                  '50m_freestyle',
	PARA_SWIMMING_100M_BREASTSTROKE:              '100m_breaststroke',
	PARA_SWIMMING_100M_FREESTYLE:                 '100m_freestyle',
	PARA_SWIMMING_100M_BACKSTROKE:                '100m_backstroke',
	PARA_SWIMMING_100M_BUTTERFLY:                 '100m_butterfly',
	PARA_SWIMMING_200M_BREASTSTROKE:              '200m_breaststroke',
	PARA_SWIMMING_200M_INDIVIDUAL_MEDLEY:         '200m_individual_medley',
	PARA_SWIMMING_200M_FREESTYLE:                 '200m_freestyle',
	PARA_SWIMMING_200M_BACKSTROKE:                '200m_backstroke',
	PARA_SWIMMING_200M_BUTTERFLY:                 '200m_butterfly',
	PARA_SWIMMING_400M_INDIVIDUAL_MEDLEY:         '400m_individual_medley',
	PARA_SWIMMING_400M_FREESTYLE:                 '400m_freestyle',
	PARA_SWIMMING_800M_FREESTYLE:                 '800m_freestyle',
	PARA_SWIMMING_1500M_FREESTYLE:                '1500m_freestyle',
	PARA_SWIMMING_4X100M_FREESTYLE_RELAY:         '4x100m_freestyle_relay',
	PARA_SWIMMING_4X100M_MEDLEY_RELAY:            '4x100m_medley_relay',
	PARA_SWIMMING_4X200M_FREESTYLE_RELAY:         '4x200m_freestyle_relay',
	PARA_SWIMMING_MIXED_4X100M_MEDLEY_RELAY:      'mixed_4x100m_medley_relay',
	PARA_TABLE_TENNIS_TT1:                        'tt1',
	PARA_TABLE_TENNIS_TT2:                        'tt2',
	PARA_TABLE_TENNIS_TT3:                        'tt3',
	PARA_TABLE_TENNIS_TT4:                        'tt4',
	PARA_TABLE_TENNIS_TT5:                        'tt5',
	PARA_TABLE_TENNIS_TT6:                        'tt6',
	PARA_TABLE_TENNIS_TT7:                        'tt7',
	PARA_TABLE_TENNIS_TT8:                        'tt8',
	PARA_TABLE_TENNIS_TT9:                        'tt9',
	PARA_TABLE_TENNIS_TT10:                       'tt10',
	PARA_TABLE_TENNIS_TT11:                       'tt11',
	PARA_TAEKWONDO_K43:                           'k43',
	PARA_TAEKWONDO_K44:                           'k44',
	PARA_TRIATHLON_SPRINT:                        'sprint',
	PARA_TRIATHLON_SHORT_DISTANCE:                'short_distance',
	PARA_TRIATHLON_MIDDLE_DISTANCE:               'middle_distance',
	PARA_TRIATHLON_IRONMAN:                       'ironman',
	PARA_TRIATHLON_CROSS:                         'cross',
	PARA_TRIATHLON_TEAM:                          'team',
	SHOOTING_PARA_SPORT_10M_AIR_RIFLE:            '10m_air_rifle',
	SHOOTING_PARA_SPORT_10M_AIR_PISTOL:           '10m_air_pistol',
	SHOOTING_PARA_SPORT_25M_SPORT_PISTOL:         '25m_sport_pistol',
	SHOOTING_PARA_SPORT_50M_RIFLE:                '50m_rifle',
	WHEELCHAIR_FENCING_FOIL:                      'foil',
	WHEELCHAIR_FENCING_EPEE:                      'epee',
	WHEELCHAIR_FENCING_SABRE:                     'sabre',
	WHEELCHAIR_TENNIS_OPEN:                       'open',
	WHEELCHAIR_TENNIS_QUAD:                       'quad',
	WHEELCHAIR_DANCE_BOOGIE_WOOGIE:               'boogie_woogie',
	WHEELCHAIR_DANCE_BREAKING:                    'breaking',
	WHEELCHAIR_DANCE_HIPHOP:                      'hiphop',
	WHEELCHAIR_DANCE_ROCK_N_ROLL:                 'rock_n_roll',
	PARA_BIATHLON_SINGLE_RACE:                    'single_race',
	PARA_BIATHLON_MASS_START:                     'mass_start',
	PARA_BIATHLON_MIXED_RELAY:                    'mixed_relay',
	PARA_BIATHLON_SINGLE_MIXED_RELAY:             'single_mixed_relay',
	PARA_BIATHLON_SPRINT:                         'sprint',
	PARA_BIATHLON_RELAY:                          'relay',
	PARA_BIATHLON_PURSUIT:                        'pursuit',
	PARA_CROSS_COUNTRY_INDIVIDUAL_START_10KM:     'individual_start_10km',
	PARA_CROSS_COUNTRY_INDIVIDUAL_START_15KM:     'individual_start_15km',
	PARA_CROSS_COUNTRY_MASS_START_15KM:           'mass_start_15km',
	PARA_CROSS_COUNTRY_MASS_START_30KM:           'mass_start_30km',
	PARA_CROSS_COUNTRY_MASS_START_50KM:           'mass_start_50km',
	PARA_CROSS_COUNTRY_MINI_TOUR:                 'mini_tour',
	PARA_CROSS_COUNTRY_SKIATHLON_15KM:            'skiathlon_15km',
	PARA_CROSS_COUNTRY_SKIATHLON_30KM:            'skiathlon_30km',
	PARA_CROSS_COUNTRY_SPRINT_QUALIFICATION:      'sprint_qualification',
	PARA_CROSS_COUNTRY_SPRINT_FINAL:              'sprint_final',
	PARA_CROSS_COUNTRY_RELAY_4X5KM:               'relay_4x5km',
	PARA_CROSS_COUNTRY_RELAY_4X10KM:              'relay_4x10km',
	PARA_CROSS_COUNTRY_TEAM_SPRINT:               'team_sprint',
	PARA_CROSS_COUNTRY_PURSUIT_30KM:              'pursuit_30km',
	PARA_CROSS_COUNTRY_PURSUIT_50KM:              'pursuit_50km',
	PARA_CROSS_COUNTRY_TOUR_DE_SKI:               'tour_de_ski',
	PARA_SNOWBOARDING_ALPINE:                     'alpine',
	PARA_SNOWBOARDING_FREESTYLE:                  'freestyle',
	PARA_SNOWBOARDING_SNOWBOARDCROSS:             'snowboardcross',
	PARA_ALPINE_SKIING_DOWNHILL:                  'downhill',
	PARA_ALPINE_SKIING_COMBINED:                  'combined',
	PARA_ALPINE_SKIING_PARALLEL_EVENT:            'parallel_event',
	PARA_ALPINE_SKIING_GIANT_SLALOM:              'giant_slalom',
	PARA_ALPINE_SKIING_SLALOM:                    'slalom',
	PARA_ALPINE_SKIING_SUPER_G:                   'super_g',
	PARA_ALPINE_SKIING_TEAM_EVENT:                'team_event',
	NINJA_QUALIFICATION:                          'qualification',
	NINJA_FINAL:                                  'final',
	MEDIEVAL_COMBAT_TOURNAMENT:                   'tournament',
	MEDIEVAL_COMBAT_PROFESSIONAL_FIGHT:           'professional_fight',
	MEDIEVAL_COMBAT_5V5:                          '5v5',
	MEDIEVAL_COMBAT_21V21:                        '21v21',
	RALLYING_RALLY_RAID:                          'rally_raid',
	RALLYING_RALLY_CROSS:                         'rally_cross',
	RALLYING_RALLY_SPRINT:                        'rally_sprint',
	RALLYING_RALLY_FORMULA:                       'rally_formula',
	KART_RACING_SPRINT:                           'sprint',
	KART_RACING_ENDURANCE:                        'endurance',
	KART_RACING_KART_SLALOM:                      'kart_slalom',
	HANG_GLIDING_CLASSIC:                         'classic',
	HANG_GLIDING_TOWER_LESS:                      'tower_less',
	HANG_GLIDING_RIGID_WING:                      'rigid_wing',
};
