<template>
	<ul
		class="accordion"
		:class="[`-${modifier}`]"
	>
		<li
			v-for="(item, index) in items"
			:key="index"
			class="accordion__item"
		>
			<div
				class="accordion__trigger"
				@click="toggle(index)"
			>
				<slot
					name="header"
					:item="item"
				/>
				<Icon
					v-if="showArrow"
					class="accordion__trigger-icon"
					:class="{'is-open': openIndexes.includes(index)}"
					icon-name="down-arrow"
					:style="[iconPositionRight !== null ? { right: `${iconPositionRight}px` } : {}]"
				/>
			</div>

			<transition
				name="accordion"
				@enter="start"
				@after-enter="end"
				@before-leave="start"
				@after-leave="end"
			>
				<div
					v-show="openIndexes.includes(index)"
					class="accordion__content"
				>
					<slot
						name="body"
						:item="item"
					/>
				</div>
			</transition>
		</li>
	</ul>
</template>

<script>
import Icon from '@shared/components/Icon';

export default {
	name:       'Accordion',
	components: { Icon },
	props:      {
		items: {
			type:    Array,
			default: () => [],
		},
		showArrow: {
			type:    Boolean,
			default: true,
		},
		keepOpen: {
			type:    Boolean,
			default: false,
		},
		arrowPosition: {
			type:      String,
			default:   'right',
			validator: position => ['center', 'right'].includes(position),
		},
		modifier: {
			type:      String,
			default:   'default',
			validator: modifier => ['default', 'no-outlines', 'minimal-styles'].includes(modifier),
		},
		initialOpenIndex: {
			type:    Number,
			default: null,
		},
		iconPositionRight: {
			type:    String,
			default: null,
		},
	},
	data() {
		return {
			openIndexes: [],
		};
	},
	mounted() {
		if (this.initialOpenIndex !== null) {
			this.openIndexes.push(this.initialOpenIndex);
		}
	},
	methods: {
		toggle(index) {
			if (this.openIndexes.includes(index)) {
				this.openIndexes = this.openIndexes.filter(i => i !== index);
			} else if (!this.keepOpen) {
				this.openIndexes = [];
				this.openIndexes.push(index);
			} else {
				this.openIndexes.push(index);
			}
			this.$emit('toggle');
		},
		open(index) {
			if (!this.openIndexes.includes(index)) {
				this.openIndexes.push(index);
				this.$emit('toggle');
			}
		},
		openAll() {
			this.openIndexes = this.items.map((_, index) => index);
			this.$emit('toggle');
		},
		start(el) {
			el.style.height = el.scrollHeight + 'px';
		},
		end(el) {
			el.style.height = '';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.accordion {
	list-style: none;
	margin: 0;
	padding: 0;
}

.accordion__item {
	position: relative;

	.accordion:not(.-minimal-styles) & {
		border: 1px solid var(--color-grey-100);
		margin-bottom: -1px;
	}

	.accordion.-no-outlines & {
		border: none;
		border-bottom: 1px solid var(--color-grey-300);
	}
}

.accordion__item::v-deep .accordion__item { // stylelint-disable-line selector-pseudo-element-no-unknown
	border: none;
}

.accordion__trigger {
	position: relative;
	user-select: none;
	cursor: pointer;
	padding-right: $sp6;
}

.accordion__trigger-icon {
	position: absolute;
	top: calc(50% - 10px);
	right: $sp4;
	transform: rotate(0deg);
	transition: transform $trans-time-fast;
	transform-origin: center;

	&.is-open {
		transform: rotate(180deg);
	}
}

.accordion-enter-active,
.accordion-leave-active {
	will-change: height, opacity;
	transition: height 0.3s ease, opacity 0.3s ease;
	overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
	height: 0 !important; // stylelint-disable-line declaration-no-important
	opacity: 0;
}
</style>
