<template>
	<Layout>
		<JumbotronCarousel
			v-if="slides.length > 0"
			:slides="slides"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</JumbotronCarousel>
		<Jumbotron
			v-else
			:bg-image-url="bgImageUrl"
			:main-image-url="mainImageUrl"
			:title="title"
			:special-announcement="false"
		>
			<template #after>
				<HeaderSponsors
					v-if="headerSponsors"
					:sponsors="headerSponsors"
				/>
			</template>
		</Jumbotron>
		<div class="l-container">
			<div
				v-for="lane in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<SectionChildNav
					v-if="teams.length && lane.lane_type === ArenaLaneType.NAVIGATION && lane.visible"
					:type="$arenaConfig.showTeamsAsSport ? 'sportsCardSmall' : 'teamCardSmall'"
					:items="teams"
				/>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
					:labels="lane.labels"
				/>
				<AdLane
					v-if="lane.lane_type === ArenaLaneType.AD && lane.visible"
					:ad="lane.ad"
				/>
			</div>
			<EmptyState
				v-if="noEvents"
				:title="$I18n.trans_choice('commons.event', 2)"
				:text="$I18n.trans('arena.placeholder.replays')"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Jumbotron from '@js/components/Jumbotron';
import JumbotronCarousel from '@js/components/JumbotronCarousel';
import SectionChildNav from '@js/components/SectionChildNav';
import EventLane from '@js/components/EventLane';
import AdLane from '@js/components/AdLane';
import EmptyState from '@js/components/EmptyState';
import HeaderSponsors from '@js/components/HeaderSponsors';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { laneMixer } from '@js/utils/laneMixer';
import { AdType } from '@shared/enums/AdType';

export default {
	name:       'ViewClub',
	components: { Layout, Jumbotron, JumbotronCarousel, SectionChildNav, EventLane, AdLane, EmptyState, HeaderSponsors },
	props:      {
		bgImageUrl: {
			type:    String,
			default: null,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		slides: {
			type:    Array,
			default: () => [],
		},
		teams: {
			type:    Array,
			default: () => [],
		},
		albums: {
			type:    Array,
			default: () => [],
		},
		lanes: {
			type:    Array,
			default: () => [],
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
	},
	data() {
		return {
			ArenaLaneType,
		};
	},
	computed: {
		mixedLanes() {
			return laneMixer(
				this.albums,
				this.lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
			);
		},
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
		headerSponsors() {
			const headerSponsors = this.ads.find(ad => ad.type === AdType.SPONSORS_HEADER);
			return headerSponsors ? headerSponsors.logos : null;
		},
	},
};
</script>

<style>

</style>
