import { REMOVE_NOTIFICATION, SET_NOTIFICATIONS, SET_SEEN } from './mutation-types';
import laroute from '@laroute';
import moment from 'moment';

moment.locale(window.locale);

export default {
	initNotifications({ commit }, { userNotifications }) {
		commit(SET_NOTIFICATIONS, { notifications: mapUserNotifications(userNotifications) });
	},

	markNotificationAsSeen({ commit }, { notification }) {
		// Set client side seen status, so its faster for the user
		notification.seenAtTimestamp = moment().unix();
		commit(SET_SEEN, { notification });

		axios.put(laroute.route('api.user.notification.seen', { notification: notification.id }));
	},

	resolveNotification({ commit }, { notification }) {
		// axios.delete(laroute.route('api.notification.delete', { notification: notification.id })); // Disabled until NotificationObserver is fixed

		axios.delete(laroute.route('api.notification.delete', { notification: notification.id }))
			.then(response => {
				if (response.status === 200) {
					commit(REMOVE_NOTIFICATION, { notification });
				}
			}).catch(error => {
				console.error(error);
			});
	},

	notificationChanged({ commit, state }, { response }) {
		const exists = state.read.concat(state.unread).filter((item) => item.id === response.notification.notification.id).length > 0;
		const notification = mapUserNotifications([response.notification])[0];

		if (notification.deletedAtTimestamp > 0 && exists) {
			commit(REMOVE_NOTIFICATION, { notification });
		} else if (exists) {
			// commit(UPDATE_NOTIFICATION, {notification}); todo: update with event log api
		} else {
			// commit(ADD_NOTIFICATION, {notification}); todo: update with event log api
		}
	},
};

const mapUserNotifications = (userNotifications) => {
	return userNotifications.map((userNotification) => {
		const { seenAtTimestamp, sticky, notification } = userNotification;
		const mapped = {
			seenAtTimestamp,
			sticky,
		};
		for (const [key, value] of Object.entries(notification)) {
			mapped[key] = value;
		}
		return mapped;
	});
};
