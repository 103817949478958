<template>
	<Layout>
		<div class="l-container">
			<div class="l-row l-align-center">
				<div class="arena-message">
					<Icon
						icon-name="fireworks"
						size="xxxl"
						class="arena-message__icon"
					/>
					<SectionTitle :title="title" />
					<p>{{ message }}</p>
					<p v-if="showRedirectNotice">
						{{ $I18n.trans('arena.login.redirect.notice') }}
					</p>
					<Button
						v-if="continueButtonLink"
						:text="$I18n.trans('arena.login.welcome.button')"
						theme="dark"
						class="arena-message__button"
						:link="continueButtonLink"
					/>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Button from '@shared/components/Button';
import Icon from '@shared/components/Icon';
import SectionTitle from '@js/components/SectionTitle';

export default {
	name:       'ViewLoginSuccess',
	components: { Layout, Button, Icon, SectionTitle },
	props:      {
		title: {
			type:     String,
			required: true,
		},
		message: {
			type:     String,
			required: true,
		},
		redirectUrl: {
			type:    String,
			default: null,
		},
		newlyRegistered: {
			type:    Boolean,
			default: false,
		},
		showRedirectNotice: {
			type:     Boolean,
			required: true,
		},
	},
	data: function() {
		return {
			continueButtonLink: null,
		};
	},
	mounted() {
		let redirectUrl;
		if (this.redirectUrl) {
			redirectUrl = this.redirectUrl;
		} else {
			redirectUrl = localStorage.getItem('loginRedirectUrl');
			localStorage.removeItem('loginRedirectUrl');
		}

		redirectUrl = redirectUrl || '/';

		if (!this.newlyRegistered) {
			// Auto redirect on login / don't redirect automatically on first account activation
			setTimeout(function() {
				window.location.replace(redirectUrl);
			}, 500);
		}

		this.continueButtonLink = redirectUrl;
	},
};
</script>

<style>

</style>
