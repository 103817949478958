<template>
	<div class="device-support s-html">
		<h1>
			{{ $I18n.trans('arena.device_support.title') }}
		</h1>

		<p class="device-support__intro">
			{{ $I18n.trans('arena.device_support.info') }}
		</p>
		<p class="device-support__intro">
			{{ $I18n.trans('arena.device_support.note') }}
		</p>

		<h2>{{ $I18n.trans('arena.device_support.devices.desktop') }}</h2>
		<div class="device-support__group">
			<div class="device-support__section">
				<h3>
					{{ $I18n.trans('arena.device_support.os.macos') }}
				</h3>
				{{ $I18n.trans('arena.device_support.browsers.safari') }}: {{ $I18n.trans('arena.device_support.versions.last_n_versions', {n: isManager ? 2 : 4}) }}<br>
				{{ $I18n.trans('arena.device_support.browsers.chrome') }}, {{ $I18n.trans('arena.device_support.browsers.firefox') }}: {{ $I18n.trans('arena.device_support.versions.last_n_years', {n: isManager ? 2 : 4}) }}
			</div>
			<div class="device-support__section">
				<h3>
					{{ $I18n.trans('arena.device_support.os.windows') }}
				</h3>
				{{ $I18n.trans('arena.device_support.browsers.edge') }}, {{ $I18n.trans('arena.device_support.browsers.chrome') }}, {{ $I18n.trans('arena.device_support.browsers.firefox') }}: {{ $I18n.trans('arena.device_support.versions.last_n_years', {n: isManager ? 2 : 4}) }}
			</div>
		</div>
		<h2>{{ $I18n.trans('arena.device_support.devices.smartphone') }}</h2>
		<div class="device-support__group">
			<div class="device-support__section">
				<h3>
					{{ $I18n.trans('arena.device_support.os.android') }}
				</h3>
				<template v-if="isManager">
					{{ $I18n.trans('arena.device_support.browsers.chrome') }}: {{ $I18n.trans('arena.device_support.versions.latest_version') }}
				</template>
				<template v-else>
					{{ $I18n.trans('arena.device_support.browsers.chrome') }}, {{ $I18n.trans('arena.device_support.browsers.samsung_internet') }}: {{ $I18n.trans('arena.device_support.versions.last_n_years', {n: isManager ? 2 : 4}) }}
				</template>
			</div>
			<div class="device-support__section">
				<h3>
					{{ $I18n.trans('arena.device_support.os.ios') }}
				</h3>
				<template v-if="isManager">
					{{ $I18n.trans('arena.device_support.browsers.safari') }}: {{ $I18n.trans('arena.device_support.versions.latest_version') }}
				</template>
				<template v-else>
					{{ $I18n.trans('arena.device_support.browsers.safari') }}: {{ $I18n.trans('arena.device_support.versions.last_n_versions', {n: 3}) }}<br>
				</template>
			</div>
		</div>
		<h2>{{ $I18n.trans('arena.device_support.devices.tablet') }}</h2>
		<div class="device-support__group">
			<div class="device-support__section">
				<h3>
					{{ $I18n.trans('arena.device_support.os.ipados') }}
				</h3>
				<template v-if="isManager">
					{{ $I18n.trans('arena.device_support.browsers.safari') }}: {{ $I18n.trans('arena.device_support.versions.latest_version') }}
				</template>
				<template v-else>
					{{ $I18n.trans('arena.device_support.browsers.safari') }}: {{ $I18n.trans('arena.device_support.versions.last_n_versions', {n: 2}) }}
				</template>
			</div>
			<div
				v-if="!isManager"
				class="device-support__section"
			>
				<h3>
					{{ $I18n.trans('arena.device_support.os.android') }}
				</h3>
				{{ $I18n.trans('arena.device_support.browsers.chrome') }}, {{ $I18n.trans('arena.device_support.browsers.samsung_internet') }}: {{ $I18n.trans('arena.device_support.versions.last_n_years', {n: 4}) }}
			</div>
		</div>
		<template v-if="!isManager">
			<h2>{{ $I18n.trans('arena.device_support.devices.tv') }}</h2>
			<div class="device-support__group">
				<div
					v-if="$arenaConfig && $arenaConfig.isSwissLeague"
					class="device-support__section"
				>
					<h3>
						{{ $I18n.trans('arena.device_support.os.bluetv') }}
					</h3>
					{{ $I18n.trans('arena.device_support.versions.all') }}
				</div>
				<div class="device-support__section">
					<h3>
						{{ $I18n.trans('arena.device_support.os.tvos') }}
					</h3>
					{{ $I18n.trans('arena.device_support.versions.airplay') }}
				</div>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	name:  'DeviceSupport',
	props: {
		isManager: {
			type:    Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.device-support {
	margin-top: $sp7;
	margin-bottom: $sp8;
}

.device-support__intro {
	margin-bottom: $sp5;
}

.device-support__group {
	margin-bottom: $sp5;

	@include size(md-up) {
		display: flex;
		gap: $sp6;
		padding-bottom: $sp5;
	}
}

.device-support__section {
	margin-bottom: $sp5;

	@include size(md-up) {
		width: 50%;
	}
}
</style>
