<template>
	<div class="jumbotron-player">
		<div class="l-container">
			<div class="jumbotron-player__inner-w">
				<div class="jumbotron-player__img-w">
					<img
						class="jumbotron-player__img"
						:src="playerImageUrl"
					>
				</div>
				<div class="jumbotron-player__main-w">
					<img
						v-if="clubLogoUrl"
						class="jumbotron-player__team-logo"
						:src="clubLogoUrl"
					>
					<div class="jumbotron-player__text-w">
						<h1 class="jumbotron-player__title">
							{{ title }}
						</h1>
						<p class="jumbotron-player__player-infos">
							<a
								v-if="teamLink"
								class="jumbotron-player__player-link"
								:href="teamLink"
							>{{ teamName }}</a>
							<span v-else>{{ teamName }}</span>
							<a
								v-if="clubLink"
								class="jumbotron-player__player-link"
								:href="clubLink"
							>{{ clubName }}</a>
							<span v-else>{{ clubName }}</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:  'JumbotronPlayer',
	props: {
		playerImageUrl: {
			type:     String,
			required: true,
		},
		clubLogoUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:     String,
			required: true,
		},
		teamName: {
			type:    String,
			default: null,
		},
		teamLink: {
			type:    String,
			default: null,
		},
		clubName: {
			type:    String,
			default: null,
		},
		clubLink: {
			type:    String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.jumbotron-player {
	margin-bottom: $sp7;
	background-color: var(--color-grey-900);

	@include size(sm-up) {
		margin-bottom: $sp8;
	}

	@include size(md-up) {
		margin-bottom: $sp9;
	}
}

.jumbotron-player__inner-w {
	display: flex;
	flex-direction: column;
	padding-top: $sp6;
	padding-bottom: $sp6;

	@include size(sm-up) {
		flex-direction: row;
		padding: 0;
	}
}

.jumbotron-player__img-w {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: $sp4;

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}

	@include size(sm-up) {
		margin-bottom: 0;
		width: 33%;
	}
}

.jumbotron-player__img {
	width: 100%;
}

.jumbotron-player__main-w {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	justify-content: space-between;

	@include size(sm-up) {
		padding: 48px 48px 72px;
	}
}

.jumbotron-player__team-logo {
	width: 100%;
	max-width: 280px;
	max-height: 100px;
	height: auto;
	margin-bottom: $sp4;
	object-fit: contain;
	object-position: left;
}

.jumbotron-player__text-w {
	width: 100%;
}

.jumbotron-player__title {
	@include font(primary, light, normal, fs-200);
	margin-bottom: $sp3;

	@include size(md-up) {
		margin-bottom: $sp4;
	}
}

.jumbotron-player__player-infos {
	@include font(primary, book, normal, fs-150);

	/* stylelint-disable selector-max-type */
	a {
		color: var(--color-hype-yellow);
	}

	a:not(:last-child),
	span:not(:last-child) {
		&::after {
			content: ' • ';
			display: inline-block;
			margin-left: 1ch;
			margin-right: 1ch;
			width: 0.5ch;
			color: var(--color-white);
		}
	}
	/* stylelint-enable selector-max-type */
}

.jumbotron-player__player-link {
	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

</style>
