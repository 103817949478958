<template>
	<div class="video-list">
		<Header
			:title="title"
			:filters="Object.entries(filters).map(entry => entry[1]).filter(filter => filter !== null)"
			@changeSport="handleSportChange"
			@changeDate="handleDateChange"
			@changeSearch="handleSearch"
			@changeEventState="handleEventStateChange"
		/>
		<EventsSection
			:pagination="videos.pagination"
			:handle-pagination-change="handlePaginationChange"
			:events="videos.items"
			:is-loading="videos.isLoading"
			:show-results-counter="filters.search.value !== '' && typeof videos.pagination.total === 'number'"
		>
			<div
				v-if="showResultsCount"
				class="video-list__results-count"
			>
				{{ videos.pagination.total }} {{ $I18n.trans_choice('commons.video', videos.pagination.total) }}
			</div>
			<Select
				v-if="enableSortOrder"
				class="video-list__select-order"
				:options="sortOrder.options"
				:value="sortOrder.selected"
				:searchable="false"
				:clearable="false"
				:dark-theme="!$arenaConfig.isLightTheme"
				open-direction="left"
				@change="handleSortOrderChange"
			/>
		</EventsSection>
	</div>
</template>

<script>
import EventsSection from '@js/components/MatchCenter/EventsSection';
import Header from '@js/components/VideoCenter/Header';
import Select from '@shared/components/Select';
import { EventFilter } from '@shared/enums/EventFilter';
import { EventOrder } from '@shared/enums/EventOrder';
import { debounce } from '@shared/utils/debounce';
import laroute from '@laroute';

export default {
	name:       'VideoList',
	components: { EventsSection, Select, Header },
	props:      {
		title: {
			type:    String,
			default: null,
		},
		enableSearch: {
			type:    Boolean,
			default: true,
		},
		enableFilterDate: {
			type:    Boolean,
			default: true,
		},
		enableFilterSport: {
			type:    Boolean,
			default: true,
		},
		enableSortOrder: {
			type:    Boolean,
			default: true,
		},
		enableEventStateToggle: {
			type:    Boolean,
			default: true,
		},
		contextFilter: {
			type:     Object,
			required: true,
		},
		showResultsCount: {
			type:    Boolean,
			default: false,
		},
		sports: {
			type:    Array,
			default: () => [],
		},
		contentPeriod: {
			type:    Array,
			default: () => [],
		},
	},
	data() {
		return {
			filters: {
				sport: this.enableFilterSport
					? {
						type:         'select',
						id:           'sport',
						label:        this.$I18n.trans_choice('commons.sport', 1),
						defaultValue: 'all',
						emitEvent:    'changeSport',
						value:        'all',
						options:      [
							{
								label: this.$I18n.trans('commons.all_types', { type: this.$I18n.trans_choice('commons.sport', 2) }),
								value: 'all',
							},
							...this.sports,
						],
					}
					: null,
				date: this.enableFilterDate
					? {
						type:         'select',
						id:           'date',
						label:        this.$I18n.trans('commons.date'),
						defaultValue: 'all',
						emitEvent:    'changeDate',
						value:        'all',
						options:      [
							{
								label: this.$I18n.trans('commons.all_types', { type: this.$I18n.trans_choice('commons.year', 2) }),
								value: 'all',
							},
							...this.contentPeriod.map(year => ({
								label: year.toString(),
								value: year.toString(),
							})).reverse(),
						],
					}
					: null,
				search: this.enableSearch
					? {
						type:         'search',
						id:           'search',
						defaultValue: '',
						emitEvent:    'changeSearch',
						value:        '',
					}
					: null,
				eventState: this.enableEventStateToggle
					? {
						type:    'toggle',
						id:      'eventType',
						options: [
							{
								name: this.$I18n.trans('commons.all'),
								id:   'all',
							},
							{
								name: this.$I18n.trans('commons.live_upcoming_short'),
								id:   'not_ended',
							},
							{
								name: this.$I18n.trans_choice('commons.replay', 2),
								id:   'ended',
							},
						],
						value:        'all',
						defaultValue: 'all',
						emitEvent:    'changeEventState',
					}
					: {
						value: 'all',
					},
			},
			sortOrder: {
				options: [
					{
						label: this.$I18n.trans('commons.date'),
						value: 'date',
					},
					{
						label: this.$I18n.trans('commons.views'),
						value: 'views',
					},
				],
				selected: 'date',
			},
			videos: {
				fetchController: new AbortController(),
				isLoading:       false,
				pagination:      this.getInitialPagination(24),
				items:           [],
			},
			debounceSearch: null,
		};
	},
	beforeMount() {
		this.applyURLParameters();
	},
	created() {
		this.debounceSearch = debounce(this.getVideos, 300, false);
	},
	mounted() {
		this.getVideos();
	},
	methods: {
		handlePaginationChange(page) {
			this.videos.pagination.currentPage = page;
			this.getVideos();
		},
		handleSortOrderChange(value) {
			this.sortOrder.selected = value;
			this.videos.pagination.currentPage = 1;
			this.getVideos();
		},
		handleSportChange(sportId) {
			this.filters.sport.value = sportId;
			this.getVideos();
		},
		handleDateChange(date) {
			this.filters.date.value = date;
			this.getVideos();
		},
		handleSearch(value) {
			this.filters.search.value = value;
			this.videos.pagination = this.getInitialPagination(12);
			this.debounceSearch();
		},
		handleEventStateChange(value) {
			this.filters.eventState.value = value;
			this.getVideos();
		},
		async fetchData(searchParams, signal) {
			const headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest' });
			const response = await fetch(`${laroute.route('api.events.cached')}?${searchParams}`, { signal, headers });
			return await response.json();
		},
		async getVideos() {
			try {
				if (this.videos.isLoading) {
					this.videos.fetchController.abort();
					this.videos.fetchController = new AbortController();
				}
				this.videos.isLoading = true;

				const searchParams = this.getSearchParams();
				const { signal } = this.videos.fetchController;
				const data = await this.fetchData(searchParams, signal);
				this.videos.items = data.data;
				this.videos.pagination = data.meta;
				this.videos.isLoading = false;
			} catch (error) {
				if (error.name === 'AbortError') {
					// nothing to do here
				} else {
					console.error(error);
				}
			}
		},
		getSearchParams() {
			const searchParams = new URLSearchParams();

			// filter(s) parameter
			const filters = {
				[EventFilter.ARENA_AVAILABLE]: null,
			};

			// Apply event state filter
			switch (this.filters.eventState.value) {
			case 'all':
				break;
			case 'not_ended':
				filters[EventFilter.EVENT] = null;
				filters[EventFilter.NOT_ENDED] = null;
				break;
			case 'ended':
				filters[EventFilter.VIDEO] = null;
				break;
			default:
				break;
			}

			// Apply context filter
			filters[this.contextFilter.key] = this.contextFilter.value;

			// Date filter
			if (this.enableFilterDate && this.filters.date.value !== 'all') {
				const startOfYear = new Date(this.filters.date.value);
				startOfYear.setMonth(0);
				startOfYear.setDate(1);
				startOfYear.setHours(0, 0, 0, 0);
				const endOfYear = new Date(this.filters.date.value);
				endOfYear.setMonth(11);
				endOfYear.setDate(31);
				endOfYear.setHours(23, 59, 59, 59);
				const startFilterString = moment(new Date(startOfYear), 'DD.MM.YYYY').toISOString(true);
				const endFilterString = moment(new Date(endOfYear), 'DD.MM.YYYY').toISOString(true);

				filters[EventFilter.BETWEEN] = [startFilterString, endFilterString];
			}

			// Sport filter
			if (this.enableFilterSport && this.filters.sport.value !== 'all') {
				filters[EventFilter.SPORT] = this.filters.sport.value;
			}

			// Search
			if (this.enableSearch && this.filters.search.value) {
				filters[EventFilter.SEARCH] = this.filters.search.value;
			}

			searchParams.append('filters', JSON.stringify(filters));

			if (this.enableSortOrder && this.sortOrder.selected === 'views') {
				searchParams.append('sortOrder', JSON.stringify({
					[EventOrder.VIEWS]: 'desc',
				}));
			} else if (this.filters.eventState.value === 'not_ended') {
				searchParams.append('sortOrder', JSON.stringify({
					[EventOrder.START]: 'asc',
				}));
			} else {
				searchParams.append('sortOrder', JSON.stringify({
					[EventOrder.START]: 'desc',
				}));
			}

			// pagination parameters
			searchParams.append('page', JSON.stringify(this.videos.pagination.currentPage));
			searchParams.append('perPage', JSON.stringify(this.videos.pagination.perPage));

			return searchParams;
		},
		getInitialPagination(perPage) {
			return {
				perPage:     perPage,
				currentPage: 1,
			};
		},
		applyURLParameters() {
			const urlParams = new URLSearchParams(window.location.search);
			const eventState = urlParams.get('eventState');
			if (eventState && this.filters.eventState.options.find(o => o.id === eventState)) {
				this.filters.eventState.value = eventState;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.video-list {
	margin-top: $sp6;
	margin-bottom: $sp7;

	@include size(md-up) {
		margin-top: $sp8;
		margin-bottom: $sp9;
	}
}

.video-list__results-count {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-300);
	margin-bottom: 0;
}

.video-list__select-order {
	margin-bottom: $sp5;
	margin-left: auto;
	width: 200px;

	@include size(md-up) {
		width: 240px;
	}
}
</style>
