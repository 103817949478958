import laroute from '@laroute';
import { isMatch, getMatchScores, getLocation, getCategoryChild, getCategoryParent, getFormattedTitle, isTeamSport, isPPV, getEventStatus, EVENT_STATUS } from '@shared/utils/eventUtils';
import { CategoryChild } from '@shared/enums/CategoryChild';
import { CategoryParent } from '@shared/enums/CategoryParent';
import { ExternalPlatform } from '@shared/enums/ExternalPlatform';

/**
 *
 * @param {*} event
 * @returns {boolean}
 */
export const isLeagueMatch = (event) => {
	return event.categoryParent === CategoryParent.MATCH && event.categoryChild === CategoryChild.MATCH_LEAGUE;
};

/**
 *
 * @param {*} event
 * @returns {boolean}
 */
export const isLeagueHighLight = (event) => {
	return event.categoryParent === CategoryParent.HIGHLIGHT && event.categoryChild === CategoryChild.MATCH_LEAGUE;
};

/**
 *
 * @param {*} event
 * @return {?string} team logo - returns logo of first participant
 */
export const getTeamLogo = (event) => {
	return event.participants?.[0]?.participatable?.imageUrl ?? null;
};

const getLeagueLogo = (event) => {
	if (!isLeagueMatch(event) && !isLeagueHighLight(event)) {
		return null;
	}

	const leagueLogo = event.league?.imageUrl || null;
	return leagueLogo?.includes('background_auth') ? null : leagueLogo;
};

const getLeagueName = (event) => {
	return event.league?.name ?? null;
};

/**
 *
 * @param event
 * @returns {array}
 */
export const getTags = (event) => {
	const tags = [];
	tags.push({
		type:  'category',
		label: $I18n.trans(`sports.${event.sport.name}.name`),
	});

	if (event.categoryParent === CategoryParent.HIGHLIGHT) {
		tags.push({
			type:  'highlight',
			label: $I18n.trans(`sports.${event.sport.name}.categories.parent.${event.categoryParent}`),
		});
	}
	return tags;
};

export const EVENT_TYPE = {
	MATCH_LEAGUE:           'match_league',
	MATCH_LEAGUE_HIGHLIGHT: 'match_league_highlight',
	MATCH_OTHER:            'match_other', // also includes training match
	TEAM_TOURNAMENT:        'team_tournament',
	TEAM_TRAINING:          'team_training',
	SINGLE:                 'single',
	SINGLE_TRAINING:        'single_training',
};

export const getEventType = (event) => {
	if (isLeagueMatch(event)) {
		return EVENT_TYPE.MATCH_LEAGUE;
	}
	if (isLeagueHighLight(event)) {
		return EVENT_TYPE.MATCH_LEAGUE_HIGHLIGHT;
	}
	if (isMatch(event)) {
		return EVENT_TYPE.MATCH_OTHER;
	}
	if (
		event.categoryParent === CategoryParent.COMPETITION &&
		(
			event.categoryChild === CategoryChild.COMPETITION_ROUND_ROBIN ||
			event.categoryChild === CategoryChild.COMPETITION_SINGLE_ELIMINATION ||
			event.categoryChild === CategoryChild.COMPETITION_DOUBLE_ELIMINATION
		)
	) {
		return EVENT_TYPE.TEAM_TOURNAMENT;
	}
	if (isTeamSport(event) && event.categoryParent === CategoryParent.TRAINING) {
		return EVENT_TYPE.TEAM_TRAINING;
	}
	if (event.categoryParent === CategoryParent.TRAINING) {
		return EVENT_TYPE.SINGLE_TRAINING;
	}

	return EVENT_TYPE.SINGLE;
};

const getExternalEventPlatformIcon = (platform) => {
	switch (platform) {
	case ExternalPlatform.SRF:
		return '/images/external-platforms/srf.png';
	case ExternalPlatform.RTS:
		return '/images/external-platforms/rts.png';
	case ExternalPlatform.RSI:
		return '/images/external-platforms/rsi.png';
	case ExternalPlatform.YOUTUBE:
		return '/images/external-platforms/youtube.png';
	case ExternalPlatform.FACEBOOK:
		return '/images/external-platforms/facebook.png';
	case ExternalPlatform.TIKTOK:
		return '/images/external-platforms/tiktok.png';
	case ExternalPlatform.TWITCH:
		return '/images/external-platforms/twitch.png';
	case ExternalPlatform.VIMEO:
		return '/images/external-platforms/vimeo.png';
	case ExternalPlatform.EUROVISIONSPORT:
		return '/images/external-platforms/eurovision.png';
	case ExternalPlatform.FISU_TV:
		return '/images/external-platforms/fisu-tv.png';
	case ExternalPlatform.GYM_TV:
		return '/images/external-platforms/gym-tv.png';
	case ExternalPlatform.FIG_TV:
		return '/images/external-platforms/figtv.png';
	case ExternalPlatform.LA_TELE:
		return '/images/external-platforms/la-tele.png';
	default:
		return '/images/external-platforms/generic.png';
	}
};

// We want to redirect to the TV view if:
// - we're on BlueTV box and
// - event is not upcoming and
// - event is not PPV or event is PPV AND event is unlocked
const redirectToTvView = (event) => {
	return $Helpers.isBlueTv() &&
	getEventStatus(event.type, event.startTimestamp, event.endTimestamp) !== EVENT_STATUS.UPCOMING &&
	(!isPPV(event) || (isPPV(event) && event.isUnlocked));
};

/**
 *
 * @param {*} event
 * @param {boolean} large
 * @returns {object} All necessary props for the EventCard component
 */
export const EventCardProps = (event, _options = {}) => {
	const options = {
		large:                  false,
		flexibleSize:           false,
		isEmbed:                false,
		showEventTags:          true,
		showInfos:              true,
		hidePaymentRestriction: false,
		openInNewTab:           false,
		..._options,
	};

	const isExternalEvent = !!event.externalEventLink;

	return {
		eventType:   getEventType(event),
		type:        event.type,
		unixStart:   event.startTimestamp,
		unixKickoff: event.startTimestamp + (event.kickoffOffsetMinutes * 60),
		unixEnd:     event.endTimestamp,
		bgImgUrl:    event.imageUrl || null,
		leagueLogo:  getLeagueLogo(event),
		leagueName:  getLeagueName(event),
		tags:        getTags(event),
		sportIcon:   event.sport?.imageUrl || '/images/sport-icons/calendar.svg#icon',
		link:        isExternalEvent
			? event.externalEventLink
			: laroute.route(redirectToTvView(event) ? 'arena.event_tv' : 'arena.event', {
				event: event.id,
				slug:  $Helpers.slugify(event.nameTranslations?.[window.$arenaConfig.defaultLanguage] || event.name),
			}),
		couchModeAvailable:        (isExternalEvent || options.isEmbed) ? false : event.couchmodeAvailable,
		couchModeClipsCount:       event.couchmodeTagsCount,
		audioLanguages:            event.audioLanguages || [],
		teamLogo:                  getTeamLogo(event),
		location:                  getLocation(event),
		eventName:                 getFormattedTitle(event, { noLineBreaksForTeamNames: true }),
		categoryParent:            getCategoryParent(event),
		categoryChild:             getCategoryChild(event),
		isPaymentRestricted:       options.hidePaymentRestriction ? false : isPPV(event),
		openInNewTab:              options.openInNewTab || isExternalEvent,
		isUnlocked:                event.isUnlocked || false,
		rawEvent:                  event,
		placeholderText:           event.placeholder ? JSON.parse(event.placeholderText) : null,
		isExternalEvent,
		externalEventPlatformIcon: getExternalEventPlatformIcon(event.externalEventPlatformName),
		...getMatchScores(event),
		// Placement specific props
		large:                     options.large,
		flexibleSize:              options.flexibleSize,
		isEmbed:                   options.isEmbed,
		showEventTags:             options.showEventTags,
		showInfos:                 options.showInfos,
	};
};
