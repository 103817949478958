<template>
	<div
		class="c-sponsors -swiss-volley"
		:class="{
			'is-hidden-on-event': !$arenaConfig.showFooterOnEvent,
		}"
	>
		<div class="l-container">
			<div
				v-for="(level, levelKey) in $I18n.trans('arena.swiss_volley_sponsors')"
				:key="levelKey"
				class="c-sponsors__section"
				:class="`-level-${level.level}`"
			>
				<h2 class="c-sponsors__title">
					{{ level.title }}
				</h2>
				<div class="c-sponsors__logo-w">
					<div
						v-for="(sponsor, sponsorKey) in level.sponsors"
						:key="sponsorKey"
					>
						<a
							:href="sponsor.link"
							target="_blank"
							rel="noopener"
							class="c-sponsors__logo-link"
						>
							<img
								:src="sponsor.image"
								class="c-sponsors__logo"
								:alt="sponsor.title"
							>
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SponsorsStv',
};
</script>
