<template>
	<div class="stories-lane l-content-section">
		<SectionTitle
			title="News"
		/>
		<Carousel :cells="processSocialPosts(socialPosts)">
			<template #cell="{cell}">
				<a
					class="stories-lane__el l-carousel-wrapper-event"
					:class="[`-instagram`, {'has-image': cell.image_url}]"
					:href="cell.href"
					target="_blank"
					rel="noopener noreferrer"
				>
					<div class="stories-lane__el-inner-w">
						<div class="stories-lane__header">
							<div class="stories-lane__meta">
								<img
									class="stories-lane__author-image"
									src="/images/insta-logo.png"
								>
								<div class="stories-lane__author" />
								<div class="stories-lane__date">
									{{ cell.date }}
								</div>
							</div>

							<!-- eslint-disable vue/no-v-html -->
							<div
								v-if="cell.description"
								class="stories-lane__content s-html -xs"
								v-html="cell.description"
							/>
						</div>
						<div
							v-if="cell.image_url"
							class="stories-lane__image-w"
						>
							<img
								class="stories-lane__image"
								:src="$Helpers.getScaledImageUrl(cell.image_url, 360)"
							>
						</div>
						<div class="stories-lane__type">
							<span class="stories-lane__type-name">social</span>
							<svg class="stories-lane__type-icon">
								<use href="#sprite-logo-instagram-filled" />
							</svg>
						</div>
					</div>
				</a>
			</template>
		</Carousel>
	</div>
</template>

<script>
import Carousel from '@js/components/Carousel';
import SectionTitle from '@js/components/SectionTitle';
import { getDateTime } from '@shared/utils/timeUtils';

export default {
	name:       'SocialLane',
	components: { Carousel, SectionTitle },
	props:      {
		socialPosts: {
			type:    Array,
			default: () => [],
		},
	},
	methods: {
		processSocialPosts(posts) {
			return posts.map((post) => ({
				...post,
				date: getDateTime(new Date(post.created_at).getTime() / 1000, { withTime: false }),
			}));
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.stories-lane__el {
	width: 250px + $sp4;
	height: 449px + $sp4;
	display: block;

	// &.-story {}
	// &.-tweet {}
}

// .stories-lane__loading {}

.stories-lane__el-inner-w {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--color-grey-900);
	border-radius: $border-radius--sm;
	height: 100%;
	border-radius: $border-radius--sm $border-radius--sm 0 0;
	transition: transform $trans-time-fast;
	will-change: transform;
	overflow: hidden;

	@media (hover:hover) {
		&:hover {
			transform: scale(1.05);
		}
	}
}

.stories-lane__header {
	padding: $sp4 $sp4 $sp5;
	flex-shrink: 0;
	overflow: hidden;
}

.stories-lane__meta {
	display: flex;
	align-items: center;
	gap: $sp3;
	justify-content: space-between;
	margin-bottom: $sp3;
	color: var(--color-grey-200);
}

.stories-lane__date {
	@include font(primary, light, normal, fs-60);
	margin-left: auto;
	white-space: nowrap;
}

.stories-lane__title {
	@include font(primary, bold, normal, fs-100);
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
}

.stories-lane__content {
	@include font(primary, light, normal, fs-80);
	color: var(--color-grey-050);
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: 16;
	-webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix

	.stories-lane__el.has-image & {
		-webkit-line-clamp: 4;
	}
}

.stories-lane__author-image {
	width: $sp5;
	height: $sp5;
	border-radius: 50%;

	.stories-lane__el.-instagram & {
		margin-left: -4px;
	}
}

.stories-lane__image-w {
	flex-grow: 1;
	overflow: hidden;
	align-items: center;
	display: flex;
}

.stories-lane__image {
	object-fit: cover;
	width: 100%;
	height: 100%;
	// scaled down images look over sharpened on non high resolution displays, so we blur them a bit
	@media (max-resolution: 1dppx) {
		filter: blur(0.5px);
	}
}

.stories-lane__type {
	position: relative;
	height: 60px;
	color: var(--color-grey-750);
	flex-shrink: 0;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: -20%;
		width: 70%;
		height: 100%;
		background-color: var(--color-grey-850);
		transform: skewX(45deg);
	}
}

.stories-lane__type-name {
	position: absolute;
	bottom: -10px;
	left: 0;
	font-size: 65px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	font-weight: 500; // stylelint-disable-line property-disallowed-list
	line-height: 1;
	letter-spacing: -0.02em; // stylelint-disable-line property-disallowed-list
}

.stories-lane__type-icon {
	position: absolute;
	right: -10px;
	bottom: -10px;
	width: 55px;
	height: 55px;

	.stories-lane__el.-tweet & {
		right: -4px;
		bottom: -8px;
	}
}
</style>
