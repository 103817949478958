<template>
	<div class="l-container l-container--full-width l-container--full-width@mobile">
		<EventsSection
			:events="album.events"
			:is-loading="false"
			:handle-pagination-change="() => {}"
			:is-album-embed="true"
		/>
	</div>
</template>

<script>
import EventsSection from '@js/components/MatchCenter/EventsSection';

export default {
	name:       'ViewAlbumEmbed',
	components: { EventsSection },
	props:      {
		album: {
			type:     Object,
			required: true,
		},
	},
};
</script>

<style>

</style>
