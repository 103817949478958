<template>
	<div class="l-app">
		<CookieConsent v-if="cookieConsentEnabled" />

		<HeaderFanAt v-if="$arenaConfig.isFanAt" />
		<Header />

		<ArenaInfo
			v-if="($arenaConfig.arenaInfos instanceof Array)"
			:current-language-key="$arenaConfig.currentLanguageKey"
			:infos="$arenaConfig.arenaInfos"
		/>

		<div class="l-app__main js-app-content">
			<main class="l-app__content">
				<slot />
			</main>
		</div>

		<SponsorsStv v-if="$arenaConfig.isStvFsg" />
		<SponsorsSwissVolley v-if="$arenaConfig.isSwissVolley" />
		<div
			v-if="!$arenaConfig.isSmartTv"
			class="l-app__footer"
		>
			<Footer :show-cookies-menu="cookieConsentEnabled" />
		</div>

		<IcsDownloadModal />
		<FeedbackSurvey v-if="!$arenaConfig.isSmartTv && $arenaConfig.isAsport && !$arenaConfig.isSwissLeague" />
		<LoginModal />
		<QrLoginModal />
		<ModalCompleteProfile v-if="!$arenaConfig.isSmartTv" />
		<ContactForm
			:contact-email="$arenaConfig.contactEmail"
			:is-swiss-league="$arenaConfig.isSwissLeague"
			:dark-theme="!$arenaConfig.isLightTheme"
		/>
		<GeoblockingModal />
	</div>
</template>

<script>
import Footer from '@js/components/Footer';
import Header from '@js/components/Header';
import CookieConsent from '@js/components/CookieConsent';
import FeedbackSurvey from '@js/components/FeedbackSurvey';
import ContactForm from '@shared/components/ContactForm';
import IcsDownloadModal from '@js/components/IcsDownloadModal';
import LoginModal from '@js/components/Login/LoginModal';
import QrLoginModal from '@js/components/Login/QrLoginModal';
import ArenaInfo from '@shared/components/ArenaInfo';
import SponsorsStv from '@js/components/SponsorsStv';
import SponsorsSwissVolley from '@js/components/SponsorsSwissVolley';
import ModalCompleteProfile from '@js/components/ModalCompleteProfile';
import GeoblockingModal from '@js/components/GeoblockingModal';
import HeaderFanAt from '@js/components/HeaderFanAt';

export default {
	name:       'Layout',
	components: {
		Footer,
		Header,
		CookieConsent,
		ArenaInfo,
		FeedbackSurvey,
		ContactForm,
		LoginModal,
		QrLoginModal,
		IcsDownloadModal,
		SponsorsStv,
		SponsorsSwissVolley,
		ModalCompleteProfile,
		GeoblockingModal,
		HeaderFanAt,
	},
	computed: {
		cookieConsentEnabled() {
			return this.$arenaConfig.cookieConsent || !!this.$arenaConfig.googleAnalyticsId;
		},
	},
};
</script>
