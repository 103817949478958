export const ExternalPlatform = {
	SRF:             'SRF',
	RTS:             'RTS',
	RSI:             'RSI',
	YOUTUBE:         'Youtube',
	FACEBOOK:        'Facebook',
	TIKTOK:          'TikTok',
	TWITCH:          'Twitch',
	VIMEO:           'Vimeo',
	EUROVISIONSPORT: 'eurovisionsport.com',
	GYM_TV:          'gymtv',
	FISU_TV:         'fisu.tv',
	FIG_TV:          'FIG TV',
	LA_TELE:         'La Télé',
};
