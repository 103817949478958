<template>
	<div
		class="time-display"
		:class="[ `-${size}`, {'-dark': darkTheme } ]"
	>
		<span class="time-display__elapsed">
			{{ elapsedText }}
		</span>
		<template v-if="showDuration">
			<span class="time-display__separator">/</span>
			<span class="time-display__duration">
				{{ durationText }}
			</span>
		</template>
	</div>
</template>

<script>
export default {
	name:  'TimeDisplay',
	props: {
		time: {
			type:    Object,
			default: () => null,
		},
		showDuration: {
			type:    Boolean,
			default: true,
		},
		size: {
			type:      String,
			default:   'lg',
			validator: (value) => ['sm', 'md', 'lg'].includes(value),
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		elapsedText() {
			return this.time?.elapsed ?? '00:00';
		},
		durationText() {
			return this.time?.duration ?? '00:00';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.time-display {
	display: flex;
	color: var(--color-soft-white);
	line-height: normal;
	font-size: 12px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list

	&.-dark {
		color: var(--color-black);
	}

	&.-md {
		font-size: 16px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	}

	&.-lg {
		font-size: 18px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
	}
}

.time-display__elapsed {
	min-width: 4.4ch;
}

.time-display__separator {
	padding: 0 5px;

	.time-display.-sm & {
		display: none;
	}
}

.time-display__duration {
	min-width: 4.4ch;

	.time-display.-sm & {
		display: none;
	}
}
</style>
