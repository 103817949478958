import { CHANGE_ITEM_STORE, SET_STORE, SET_STORE_INTERVAL, SET_STORE_ITEMS, UPDATE_STORE_ITEM } from './mutation-types';
import { INIT_STORE, UPDATE_STORE_ITEMS } from './action-types';
import store from '../index';
import { getEventStatus, EVENT_STATUS } from '@shared/utils/eventUtils';
import { WS_EVENT } from '@shared/js/websocket/constants';

const storesWithUpcomingEvents = ['liveAndUpcoming', 'upcoming'];
const storesWithLiveEvents = ['liveAndUpcoming', 'live'];
const storesWithEndedEvents = ['ended'];
// Note: We don't listen for updates on any album store because they usually only include past events

export default {
	[INIT_STORE]({ commit, state, dispatch }, { storeId, data }) {
		commit(SET_STORE, { storeId, data });
		const storeIsUpcomingOrLive = [...storesWithUpcomingEvents, ...storesWithLiveEvents].includes(storeId);

		// startStoreInterval for upcoming and live events
		if (storeIsUpcomingOrLive) {
			commit(SET_STORE_INTERVAL, { storeId, intervalId: startStoreInterval(state, storeId) });
		}

		if (storeIsUpcomingOrLive && state[storeId].items && state[storeId].items.length > 0) {
			state[storeId].items.forEach((event) => {
				const unixNow = Math.round(new Date().getTime() / 1000);
				const eventLiveOrWithin1Hour = event.startTimestamp - unixNow - 3600 <= 0;

				// We only have updates on live events and upcoming within 1 hour
				if (!eventLiveOrWithin1Hour) {
					return;
				}

				// Return if event is already subscribed
				if (store.state.socket.activeChannels.find(channel => channel.name === `event.${event.id}`)) {
					return;
				}
				// Start listening for Event Updates
				dispatch('startListening', {
					channel:  `event.${event.id}`,
					event:    WS_EVENT.EVENT_UPDATE,
					callback: function(response) {
						commit(UPDATE_STORE_ITEM, { storeIds: ['upcoming', 'liveAndUpcoming', 'live'], item: response.event });
					},
				});
			});
		}
	},
	[UPDATE_STORE_ITEMS]({ commit, state }, { storeId, items }) {
		commit(SET_STORE_ITEMS, { storeId, items });
	},
};

const startScoreListening = (event) => {
	if (store.state.socket.activeChannels.find(channel => channel.name === `event.${event.id}.scores`)) {
		return;
	}

	store.dispatch('startListening', {
		channel:  `event.${event.id}.scores`,
		event:    WS_EVENT.SCORE_UPDATED,
		callback: function(response) {
			store.commit(UPDATE_STORE_ITEM, { storeIds: storesWithLiveEvents, item: { ...event, finalScore: response.finalScore, scores: response.scores } });
		},
	});
};

const startStoreInterval = (state, storeId) => {
	return setInterval(() => {
		if (state[storeId].items && state[storeId].items.length > 0) {
			state[storeId].items.forEach((item) => {
				const status = getEventStatus(item.type, item.startTimestamp, item.endTimestamp, Math.round(new Date().getTime() / 1000));

				// Start Score listening
				if (status === EVENT_STATUS.LIVE) {
					startScoreListening(item);
				}

				// Place events in live stores
				if (status === EVENT_STATUS.LIVE && !storesWithLiveEvents.includes(storeId)) {
					store.commit(CHANGE_ITEM_STORE, { oldStoreId: storeId, newStoreIds: storesWithLiveEvents, item });
				}

				// Place events in replay/ended stores
				if (status === EVENT_STATUS.REPLAY && !storesWithEndedEvents.includes(storeId)) {
					store.commit(CHANGE_ITEM_STORE, { oldStoreId: storeId, newStoreIds: storesWithEndedEvents, item });
					if (store.state.socket.activeChannels.find(channel => channel.name === `event.${item.id}.scores`)) {
						store.dispatch('leaveChannel', { channel: `event.${item.id}.scores` });
					}
				}
				store.dispatch(UPDATE_STORE_ITEMS, { storeId, items: state[storeId].items });
			});
		}
	}, 1000);
};
