<template>
	<div class="jumbotron-team">
		<div class="jumbotron-team__outter-w">
			<div class="l-container">
				<div class="jumbotron-team__inner-w">
					<div class="jumbotron-team__main-w">
						<img
							class="jumbotron-team__team-logo"
							:src="logoUrl"
						>
						<h1 class="jumbotron-team__title">
							{{ title }}
						</h1>
						<p class="jumbotron-team__team-infos">
							<a
								v-if="leagueLink"
								class="jumbotron-team__team-link"
								:href="leagueLink"
							>{{ leagueName }}</a>
							<span v-else>{{ leagueName }}</span>
							<span v-if="category">{{ category }}</span>
						</p>
						<div
							v-if="clubLink"
							class="jumbotron-team__club-link"
						>
							<Button
								:text="$I18n.trans('arena.to_club')"
								modifier="tertiary"
								:theme="$arenaConfig.isLightTheme ? null : 'dark'"
								:link="clubLink"
								:is-external-link="isExternalLink"
								:icon="isExternalLink ? 'launch' : null"
								:reverse-order="true"
								icon-size="sm"
							/>
						</div>
					</div>
					<div class="jumbotron-team__img-w">
						<img
							class="jumbotron-team__img"
							:src="teamImgUrl"
						>
					</div>
				</div>
			</div>
		</div>
		<slot name="after" />
	</div>
</template>

<script>
import Button from '@shared/components/Button';

export default {
	name:       'JumbotronTeam',
	components: { Button },
	props:      {
		logoUrl: {
			type:     String,
			required: true,
		},
		title: {
			type:     String,
			required: true,
		},
		teamImgUrl: {
			type:    String,
			default: null,
		},
		leagueLink: {
			type:    String,
			default: null,
		},
		leagueName: {
			type:    String,
			default: null,
		},
		category: {
			type:    String,
			default: null,
		},
		clubLink: {
			type:    String,
			default: null,
		},
	},
	computed: {
		isExternalLink() {
			return this.clubLink && (new URL(this.clubLink)).hostname !== location.hostname;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.jumbotron-team {
	margin-bottom: $sp7;
	background-color: var(--color-grey-900);

	@include size(sm-up) {
		margin-bottom: $sp8;
	}

	@include size(md-up) {
		margin-bottom: $sp9;
	}
}

.jumbotron-team__outter-w {
	padding-bottom: $sp5;

	@include size(sm-up) {
		padding-top: $sp5;
	}
}

.jumbotron-team__inner-w {
	display: flex;
	flex-direction: column;

	@include size(sm-up) {
		flex-direction: row;
	}
}

.jumbotron-team__img-w {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: $sp4;

	@include size(sm-up) {
		margin-bottom: 0;
		width: 57%;
	}
}

.jumbotron-team__img {
	width: 100%;
	object-fit: contain;
	max-height: 480px;
}

.jumbotron-team__main-w {
	order: 2;
	flex-grow: 1;

	@include size(sm-up) {
		order: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-top: 7%;
		padding-right: 48px;
	}
}

.jumbotron-team__club-link {
	text-decoration: none;
}

.jumbotron-team__team-logo {
	width: 100%;
	max-width: 230px;
	max-height: 100px;
	height: auto;
	margin-bottom: $sp4;
	object-fit: contain;
	object-position: left;
}

.jumbotron-team__title {
	@include font(primary, bold, normal, fs-200);
	margin-bottom: $sp2;

	@include size(md-up) {
		margin-bottom: $sp3;
	}
}

.jumbotron-team__team-infos {
	@include font(primary, book, normal, fs-120);

	/* stylelint-disable selector-max-type */
	a {
		color: var(--color-hype-yellow);
	}

	a:not(:last-child),
	span:not(:last-child) {
		&::after {
			content: ' • ';
			display: inline-block;
			margin-left: 1ch;
			margin-right: 1ch;
			width: 0.5ch;
			color: var(--color-white);

			body.-t-light & { // stylelint-disable-line selector-no-qualifying-type, max-nesting-depth
				color: var(--color-black);
			}
		}
	}
	/* stylelint-enable selector-max-type */
}

.jumbotron-team__team-link {
	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.jumbotron-team__club-link {
	margin-top: $sp5;
}

</style>
