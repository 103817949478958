<template>
	<div class="l-content-section js-keynav-section">
		<SectionTitle :title="title" />
		<Carousel
			:cells="filteredItems"
			class="margin-bottom-3"
		>
			<template #cell="{cell, isDragging}">
				<LeagueCard
					v-if="type === 'league'"
					:item="cell"
					:clickable="!isDragging"
				/>
				<ClubCard
					v-if="type === 'club'"
					:item="cell"
					:image-size="180"
					:clickable="!isDragging"
				/>
				<TeamCard
					v-if="type === 'team'"
					:item="cell"
					:image-size="180"
					:clickable="!isDragging"
				/>
				<TeamCardSmall
					v-if="type === 'teamCardSmall'"
					:item="cell"
					:clickable="!isDragging"
				/>
				<PlayerCard
					v-if="type === 'athletes'"
					:item="cell"
					:clickable="!isDragging"
					:with-link="withLink"
				/>
				<SportsCardSmall
					v-if="['sportsCardAlbum', 'sportsCardSmall'].includes(type)"
					:item="cell"
					:is-album="type === 'sportsCardAlbum'"
					:clickable="!isDragging"
				/>
			</template>
		</Carousel>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import Carousel from '@js/components/Carousel';
import ClubCard from '@js/components/ClubCard';
import TeamCard from '@js/components/TeamCard';
import LeagueCard from '@js/components/LeagueCard';
import SportsCardSmall from '@js/components/SportsCardSmall';
import TeamCardSmall from '@js/components/TeamCardSmall';
import PlayerCard from '@js/components/PlayerCard';

const cardTypes = ['team', 'league', 'club', 'teamCardSmall', 'sportsCardSmall', 'sportsCardAlbum', 'athletes'];

export default {
	name:       'SectionChildNav',
	components: { SectionTitle, Carousel, ClubCard, TeamCard, LeagueCard, SportsCardSmall, TeamCardSmall, PlayerCard },
	props:      {
		// type of the cards to be displayed
		type: {
			type:      String,
			required:  true,
			validator: (type) => cardTypes.includes(type),
		},
		items: {
			type:    Array,
			default: () => [],
		},
		laneConfig: {
			type:    Object,
			default: null,
		},
		isSingleSport: {
			type:    Boolean,
			default: false,
		},
		withLink: {
			type:    Boolean,
			default: true,
		},
	},
	computed: {
		title() {
			if (this.type === 'league') {
				return this.$arenaConfig.showLeaguesAsSport ? $I18n.trans_choice('commons.sport', 2) : $I18n.trans_choice('commons.league', 2);
			}
			if (this.type === 'athletes') {
				if (this.isSingleSport) {
					return $I18n.trans('commons.athletes');
				}
				return $I18n.trans('commons.squad');
			}
			if (this.type === 'club') {
				return $I18n.trans_choice('commons.club', 2);
			}
			if (this.type === 'team') {
				return $I18n.trans_choice('commons.team', 2);
			}
			if (['sportsCardSmall', 'sportsCardAlbum'].includes(this.type)) {
				return $I18n.trans_choice('commons.sport', 2);
			}

			return $I18n.trans_choice('commons.team', 2);
		},
		filteredItems() {
			if (!this.laneConfig?.navigationSports || this.type !== 'sportsCardAlbum') {
				return this.items;
			}
			// return all this.items which are visible in this.laneConfig?.navigationSports and order them by this.laneConfig?.navigationSports
			return this.items.filter((item) => {
				// return true if the array this.laneConfig?.navigationSports includes the item with the same id and visible is true
				return this.laneConfig?.navigationSports.some((sport) => {
					return sport.id === item.id && sport.visible;
				});
			}).sort((a, b) => {
				// return the index of the item in this.laneConfig?.navigationSports
				const aIndex = this.laneConfig?.navigationSports.findIndex((sport) => {
					return sport.id === a.id;
				});
				const bIndex = this.laneConfig?.navigationSports.findIndex((sport) => {
					return sport.id === b.id;
				});
				// return the difference of the indexes
				return aIndex - bIndex;
			});
		},
	},
};
</script>

<style>
</style>
