<template>
	<div
		class="arena-login__content"
		:class="{ '-in-progress': inProgress }"
	>
		<template v-if="isMode(LOGIN_MODES.SUCCESS)">
			<div class="arena-login__message">
				<V2Icon
					icon-name="fireworks"
					size="xxxl"
					class="arena-login__info-icon"
				/>
				<h2 class="arena-login__title">
					{{ $I18n.trans('arena.login.authenticated.title') }}
				</h2>
				<!-- eslint-disable vue/no-v-html -->
				<div
					class="arena-login__message--info"
					v-html="$I18n.trans('arena.login.authenticated.subtitle' )"
				/>
				<!--eslint-enable-->
				<Button
					class="arena-login__continue"
					:text="$Helpers.ucfirst($I18n.trans('arena.login.authenticated.button'))"
					theme="dark"
					@click="continueAuthenticated"
				/>
			</div>
		</template>
		<template v-if="isCheckEmail">
			<div class="arena-login__message">
				<V2Icon
					icon-name="newsletter"
					size="xxxl"
					class="arena-login__info-icon"
				/>
				<h2 class="arena-login__title">
					{{ $I18n.trans('arena.login.check_email.title') }}
				</h2>
				<!-- eslint-disable vue/no-v-html -->

				<div
					v-if="isMode(LOGIN_MODES.PASSWORD_RESET_SENT)"
					class="arena-login__message--info"
					v-html="$I18n.trans('arena.login.check_email.reset_subtitle', { email: email } )"
				/>
				<div
					v-else-if="isMode(LOGIN_MODES.ACTIVATION_EMAIL_SENT)"
					class="arena-login__message--info"
					v-html="$I18n.trans('arena.login.check_email.activation_sent_subtitle', { email: email } )"
				/>
				<div
					v-else
					class="arena-login__message--info"
					v-html="$I18n.trans('arena.login.check_email.subtitle', { email: email } )"
				/>
				<!--eslint-enable-->
				<div class="arena-login__message--help">
					{{ $I18n.trans('arena.login.check_email.help') }}
				</div>
				<Button
					class="arena-login__back"
					:theme="$arenaConfig.isLightTheme ? null : 'dark'"
					:text="$Helpers.ucfirst($I18n.trans('arena.login.login.back'))"
					modifier="borderless"
					@click="resetForm"
				/>
			</div>
		</template>
		<template v-else>
			<EmailAndSocialLogin
				v-if="isMode(LOGIN_MODES.EMAIL_LOGIN)"
				:redirect-url="redirectUrl"
				:email="email"
				@submitted="onSubmit"
			/>
			<RequestPasswordReset
				v-if="isMode(LOGIN_MODES.RESET_PASSWORD)"
				:email="email"
				@submitted="onSubmit"
			/>
			<PasswordLogin
				v-if="isMode(LOGIN_MODES.LOGIN_WITH_PASSWORD)"
				:redirect-url="redirectUrl"
				:has-account="hasAccount"
				:has-no-password="hasNoPassword"
				:email="email"
				@submitted="onSubmit"
				@login-success="continueAuthenticated"
			/>
			<!-- eslint-disable vue/no-v-html -->
			<div
				class="arena-login__disclaimer"
				v-html="$I18n.trans('arena.login.login.disclaimer', { userAgreement: laroute.route('arena.terms') })"
			/>
			<!--eslint-enable-->
		</template>
	</div>
</template>

<script>
import laroute from '@laroute';

import Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';
import RequestPasswordReset from './partials/RequestPasswordReset';
import PasswordLogin from './partials/PasswordLogin';
import EmailAndSocialLogin from './partials/EmailAndSocialLogin';

import { mapMutations } from 'vuex';

import { authApi } from '../../api/client';

export const LOGIN_MODES = {
	EMAIL_LOGIN:           'emailLogin',
	PASSWORD_LOGIN:        'passwordLogin',
	RESEND_ACTIVATION:     'resendActivation',
	RESET_PASSWORD:        'resetPassword',
	CHECK_EMAIL:           'checkEmail',
	SUCCESS:               'success',
	ACTIVATION_EMAIL_SENT: 'activationEmailSent',
	PASSWORD_RESET_SENT:   'passwordResetSent',
};

export default {
	name:       'LoginComponent',
	components: { Button, V2Icon, RequestPasswordReset, PasswordLogin, EmailAndSocialLogin },
	props:      {
		loginRedirectUrl: {
			type:    String,
			default: null,
		},
	},
	data() {
		return {
			inProgress:        false,
			hasAccount:        false,
			hasNoPassword:     false,
			email:             null,
			tokenVerification: null,
			redirectUrl:       null,
			mode:              LOGIN_MODES.EMAIL_LOGIN,
			tabIsActive:       true,
			LOGIN_MODES,
			laroute,
		};
	},
	computed: {
		isCheckEmail() {
			return [LOGIN_MODES.CHECK_EMAIL, LOGIN_MODES.ACTIVATION_EMAIL_SENT, LOGIN_MODES.PASSWORD_RESET_SENT].includes(this.mode);
		},
	},
	mounted() {
		if (document.querySelector('input[name="login-redirect-url"]') !== null) {
			this.redirectUrl = document.querySelector('input[name="login-redirect-url"]').value;
		} else {
			this.redirectUrl = this.loginRedirectUrl || window.location.href;
		}
		if (document.querySelector('input[name="login-email"]') !== null) {
			this.email = document.querySelector('input[name="login-email"]').value;
		}
		window.addEventListener('visibilitychange', this.setTabVisibility);
	},
	beforeDestroy() {
		window.removeEventListener('visibilitychange', this.setTabVisibility);
	},
	methods: {
		...mapMutations({
			setLoginRedirectUrl: 'user/setLoginRedirectUrl',
		}),
		isMode(mode) {
			return this.mode === mode;
		},
		onSubmit(options) {
			if (options.mode) {
				this.mode = options.mode;
			}
			this.$emit('submitted');
		},
		formValid() {
			return this.$refs.email && this.$refs.email.isValid;
		},
		async continueWithPassword() {
			this.inProgress = true;
			const url = laroute.route('arena.api.arena_login.check_account_status', { email: encodeURIComponent(this.email) });
			const response = await authApi.post(url);
			const responseContent = await response.json();
			this.mode = LOGIN_MODES.LOGIN_WITH_PASSWORD;
			this.hasAccount = responseContent.exists;
			this.hasNoPassword = responseContent.no_password;
			this.inProgress = false;
		},
		resetForm() {
			this.email = null;
			this.inProgress = false;
			this.mode = LOGIN_MODES.EMAIL_LOGIN;
			if (this.tokenVerification) {
				clearInterval(this.tokenVerification);
			}
			this.$emit('reset');
		},
		continueAuthenticated() {
			if (this.redirectUrl && window.location.href !== this.redirectUrl) {
				window.location.href = this.redirectUrl;
				window.location.reload(); // force reload
			} else {
				window.location.reload();
			}
		},
		async monitorTokenVerification(verificationToken, validUntil) {
			const verificationInterval = 13000;
			if (this.tokenVerification) {
				clearInterval(this.tokenVerification);
			}

			this.tokenVerification = setInterval(async() => {
				if (!this.tabIsActive) {
					return;
				}
				const response = await authApi.get(laroute.route('arena.api.arena_login.verify_token', { verificationToken }));
				const responseContent = await response.json();

				if (responseContent && responseContent.status === 'verified') {
					this.mode = LOGIN_MODES.SUCCESS;
				}

				if ((responseContent && responseContent.status === 'invalid_or_expired') || moment(validUntil) < moment.now()) {
					console.log('Login token expired.');
					this.resetForm();
				}
			}, verificationInterval);
		},
		setTabVisibility() {
			this.tabIsActive = !document.hidden;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

::v-deep {
	.arena-login__continue {
		width: 100%;
		margin-top: 10px;
	}

	.arena-login__error {
		color: $color-red;
		margin-bottom: 20px;
	}

	.arena-login__back {
		margin-left: auto;
		margin-right: auto;
		margin-top: 30px;
		display: flex;
	}

	.arena-login__hint {
		margin-bottom: 35px;
		position: relative;
		color: var(--color-grey-300);
		padding-left: 30px;

		&.-error {
			color: var(--color-red);
		}

		&.-no-icon {
			padding-left: 0;
		}

		&.-info {
			margin-top: 30px;
			text-align: center;
		}
	}

	.arena-login__hint-icon {
		position: absolute;
		top: 0;
		left: 0;
	}
}

.arena-login__message {
	@include font(primary, book, normal, fs-120);
	text-align: center;
	max-width: 540px;
	margin-left: auto;
	margin-right: auto;
}

.arena-login__message--help {
	@include font(primary, book, normal, fs-100);
	color: var(--color-grey-300);
	margin-bottom: 24px;
}

.arena-login__title {
	@include font(primary, light, normal, fs-150);
	margin-bottom: 16px;
}

.arena-login__info-icon {
	margin-top: 40px;
	margin-bottom: 40px;
}

.arena-login__message--info {
	@include font(primary, book, normal, fs-120);
	margin-bottom: 48px;
}

.arena-login__disclaimer {
	text-align: center;
	color: var(--color-grey-500);
	margin-top: 50px;

	&::v-deep a {                    // stylelint-disable-line
		@include font(primary, medium, normal, fs-100);
	}

	&::v-deep a:hover {             // stylelint-disable-line
		color: var(--color-grey-300);
	}
}

</style>
