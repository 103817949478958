<template>
	<div
		class="v2-select"
		:class="{
			'-t-dark': darkTheme,
			'is-disabled': disabled,
		}"
	>
		<InputLabel
			v-if="label"
			:required="required"
			:text="label"
			:dark-theme="darkTheme"
			:hide-optional="disabled || !clearable || hideOptional"
			:info="info"
		/>
		<!-- eslint-disable vue/no-parsing-error -->
		<input
			v-if="name"
			v-model="selectedValue"
			type="text"
			:name="name"
			:required="required"
			:disabled="disabled"
			class="v2-select__hidden-input"
			tabindex="-1"
			@input="handleInput"
		>
		<!-- eslint-enable vue/no-parsing-error -->

		<div class="v2-select__input-w">
			<VSelect
				ref="select"
				:value="selectedOption"
				:class="[
					{'has-selection': value !== null},
					{'is-clearable': clearable},
					{'-with-subtitles': withSubtitleOptions},
					`-opens-${openDirection}`,
				]"
				:options="internalOptions"
				:clearable="false"
				:selectable="selectable"
				:searchable="searchable"
				:placeholder="placeholder"
				:disabled="disabled"
				@input="handleInput"
				@search="handleSearch"
				@option:selecting="handleSelecting"
				@option:selected="handleSelected"
				@search:focus="handleSearchFocus"
				@search:blur="handleSearchBlur"
				@open="handleOpen"
				@close="handleClose"
			>
				<template #open-indicator="{ attributes }">
					<span v-bind="attributes">
						<Button
							class="v2-select__action-btn"
							icon="down-arrow"
							modifier="borderless"
						/>
					</span>
				</template>

				<template #selected-option="option">
					<div
						class="v2-select__selected-option"
						:class="{'-with-image': option.image}"
					>
						<img
							v-if="option.image"
							:src="option.image"
							class="v2-select__selected-option-image"
						>
						<div class="v2-select__selected-option-text-w">
							<h3 class="v2-select__selected-option-title">
								{{ option.label }}
							</h3>
							<h4
								v-if="option.subtitle"
								class="v2-select__selected-option-subtitle"
							>
								{{ option.subtitle }}
							</h4>
						</div>
					</div>
				</template>

				<template #option="option">
					<div
						class="v2-select__option"
						:class="{'-with-image': option.image, '-with-subtitle': option.subtitle && showSubtitleInOptions}"
					>
						<h3 class="v2-select__option-title">
							{{ option.label }}
						</h3>
						<h4
							v-if="option.subtitle && showSubtitleInOptions"
							class="v2-select__option-subtitle"
						>
							{{ option.subtitle }}
						</h4>
						<img
							v-if="option.image"
							:src="option.image"
							class="v2-select__option-image"
						>
					</div>
				</template>

				<div
					slot="no-options"
					class="v2-select__no-results"
				>
					{{ $I18n.trans('commons.no_results') }}
				</div>
			</VSelect>
			<Button
				v-if="value !== null && clearable"
				class="v2-select__action-btn -clear"
				icon="e-remove"
				modifier="borderless"
				@click="handleReset"
			/>
		</div>
		<div
			v-if="textAfter"
			class="v2-select__text-after"
			:class="[`-${textAfterAlignment}`]"
		>
			{{ textAfter }}
		</div>
		<template v-if="$slots.after">
			<div
				class="v2-select__text-after"
				:class="[`-${textAfterAlignment}`]"
			>
				<slot name="after" />
			</div>
		</template>
		<V2InputError
			v-if="error && wasFocused && value === null"
			class="v2-select__error"
			:text="error"
		/>
	</div>
</template>

<script>
import VSelect from 'vue-select';
import Button from '@shared/components/Button';
import InputLabel from '@shared/components/Form/InputLabel';
import V2InputError from '@shared/components/Form/InputError';
import { api } from '../../manager/js/util/api';

export default {
	name:       'Select',
	components: { VSelect, Button, InputLabel, V2InputError },
	model:      {
		prop:  'value',
		event: 'change',
	},
	props: {
		options: {
			type: Array,
			default() {
				return [];
			},
		},
		value: {
			type:    [String, Number, null],
			default: null,
		},
		searchable: {
			type:    Boolean,
			default: true,
		},
		selectable: {
			type:    Function,
			default: () => true,
		},
		clearable: {
			type:    Boolean,
			default: true,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		name: {
			type:    String,
			default: null,
		},
		placeholder: {
			type:    String,
			default: '',
		},
		showSubtitleInOptions: {
			type:    Boolean,
			default: false,
		},
		/**
		 * Input Label
		 * To set the label for the v-select options, use option-label
		 */
		label: {
			type:    String,
			default: null,
		},
		info: {
			type:    String,
			default: null,
		},
		apiUrl: {
			type:    String,
			default: null,
		},
		apiFilter: {
			type:    Object,
			default: null,
		},
		apiParameters: {
			type:    Object,
			default: null,
		},
		initialQuery: {
			type:    String,
			default: null,
		},
		resultMapping: {
			type:    Object,
			default: null,
		},
		translationKey: {
			type:    String,
			default: null,
		},
		required: {
			type:    Boolean,
			default: false,
		},
		error: {
			type:    String,
			default: null,
		},
		disableEmit: {
			type:    Boolean,
			default: false,
		},
		openDirection: {
			type:    String,
			default: 'right',
		},
		textAfter: {
			type:    String,
			default: null,
		},
		textAfterAlignment: {
			type:    String,
			default: 'right',
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		hideOptional: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			internalOptions: this.mapOptions(this.options) || [],
			query:           this.initialQuery || '',
			lastQuery:       '',
			wasFocused:      false,
			selectedValue:   this.value,
			dataFilter:      this.apiFilter,
		};
	},
	computed: {
		selectedOption: {
			get() {
				return this.internalOptions.find(option => (option.value === this.selectedValue || option === this.selectedValue)) || null;
			},
			set(newOption) {
				return newOption;
			},
		},
		withSubtitleOptions() {
			return this.options.every(option => {
				return Object.prototype.hasOwnProperty.call(option, 'label') && Object.prototype.hasOwnProperty.call(option, 'subtitle');
			});
		},
		withImage() {
			return this.options.every(option => {
				return Object.prototype.hasOwnProperty.call(option, 'image');
			});
		},
	},
	watch: {
		options() {
			// some selects get the options updated based on other selects
			// for those we must update the internal options.
			// We ignore it for Selects with API requests as this is handled in runAutoCompletion
			if (this.apiUrl) {
				return;
			}
			this.internalOptions = this.mapOptions(this.options) || [];
		},
		value() {
			this.selectedValue = this.value;
			this.selectedOption = this.internalOptions.find(option => (option.value === this.selectedValue || option === this.selectedValue)) || null;
		},
		apiFilter() {
			this.dataFilter = this.apiFilter;
		},
	},
	mounted() {
		if (this.selectedValue && !this.option) {
			this.runAutoCompletion();
		}
	},
	methods: {
		handleInput(data) {
			const value = Object.prototype.hasOwnProperty.call(data, 'value') ? data.value : data;
			if (this.disableEmit) {
				this.selectedValue = value;
				this.selectedOption = this.internalOptions.find(option => (option.value === this.selectedValue || option === this.selectedValue)) || null;
			} else {
				this.$emit('change', value);
			}
		},
		handleSearch(value) {
			if (value) {
				this.query = value;
				this.$nextTick(() => {
					this.runAutoCompletion();
				});
			}
			this.$emit('search', value);
		},
		handleSelecting(data) {
			this.$emit('option:selecting', data.value);
		},
		handleSelected(value) {
			if (this.resultMapping) {
				this.query = value[this.resultMapping.label];
			}
		},
		handleSearchFocus() {
			this.runAutoCompletion();
			this.wasFocused = false;
		},
		handleSearchBlur() {
			this.query = '';
			this.wasFocused = true;
		},
		handleOpen() {
			this.$emit('open');
		},
		handleClose() {
			this.$emit('close');
		},
		handleReset() {
			this.query = '';
			this.$emit('change', null);
			this.$refs.select.$refs.search.focus();
		},
		async runAutoCompletion() {
			if (!this.apiUrl) {
				return;
			}
			if (this.query === this.lastQuery && this.query && this.query.length > 0) {
				return;
			}

			this.lastQuery = this.query;

			const params = this.apiParameters || {
				query:     this.query ? this.query.toLowerCase() : null,
				filter:    this.dataFilter,
				relations: this.relations,
				page:      this.page ? this.page : null,
			};

			try {
				const response = await api.get(this.apiUrl, params);
				const results = response.data.meta ? response.data.data : response.data;
				const mappedOptions = this.mapOptions(results);

				// Add options from props which are not in the results  (results are paginated)
				// so preselected option can be displayed
				if (this.value !== null) {
					this.options.forEach(option => {
						if (!mappedOptions.find((mappedOption) => mappedOption.value === option.value)) {
							mappedOptions.push(option);
						}
					});
				}

				this.internalOptions = mappedOptions;
			} catch (error) {
				console.error(error);
			}
		},
		mapOptions(options) {
			if (!this.resultMapping) {
				return options;
			}
			return options.map(option => {
				const mapped = {};
				Object.keys(this.resultMapping).forEach(key => {
					// check if this.resultMapping[key] is an Object with key and handler
					if (typeof this.resultMapping[key] === 'object') {
						const { key: mappingKey, handler } = this.resultMapping[key];
						if (handler) {
							mapped[key] = handler(option[mappingKey]);
						} else {
							mapped[key] = option[mappingKey];
						}
					} else if (option[this.resultMapping[key]]) {
						const value = option[this.resultMapping[key]];
						if (key === 'label' && this.translationKey) {
							mapped[key] = this.$I18n.trans(this.translationKey.replace(':value', value));
						} else {
							mapped[key] = value;
						}
					}
				});

				return mapped;
			});
		},
	},
};
</script>

<style lang="scss">
@import '@shared/sass/shared-variables';
@import 'vue-select/dist/vue-select.css';

/* stylelint-disable */

.v2-select {
	--vs-colors--lightest: var(--color-grey-200);
	--vs-colors--light: var(--color-grey-400);
	--vs-colors--dark: var(--color-grey-400);
	--vs-colors--darkest: var(--color-grey-500);

	// Search Input
	--vs-search-input-color: #{$form-color-input-text};
	--vs-search-input-bg: #{$form-color-bg};
	--vs-search-input-placeholder-color: inherit;

	// Font
	--vs-font-size: 1rem;
	--vs-line-height: 1.4;

	//  Disabled State
	--vs-state-disabled-bg: transparent;
	--vs-disabled-bg: #{$form-color-bg-disabled};
	--vs-state-disabled-color: #{$form-color-input-text-disabled};
	--vs-state-disabled-controls-color: #{$form-color-input-text-disabled};
	--vs-state-disabled-cursor: not-allowed;

	//  Borders
	--vs-border-width: 1px;
	--vs-border-style: solid;
	--vs-border-radius: #{$border-radius--sm};
	--vs-border-color: #{$form-color-border};

	// Actions: house the component controls
	--vs-actions-padding: 4px 6px 0 3px;

	//  Component Controls: Clear, Open Indicator
	--vs-controls-color: var(--vs-colors--light);
	--vs-controls-size: 1;
	--vs-controls--deselect-text-shadow: 0 1px 0 var(--color-soft-white);

	//  Selected
	--vs-selected-bg: var(--color-grey-050);
	--vs-selected-color: #{$form-color-input-text};
	--vs-selected-border-color: var(--vs-border-color);
	--vs-selected-border-style: var(--vs-border-style);
	--vs-selected-border-width: var(--vs-border-width);

	//  Dropdown
	--vs-dropdown-bg: var(--color-white);
	--vs-dropdown-color: #{$form-color-input-text};
	--vs-dropdown-z-index: 1000;
	--vs-dropdown-min-width: 160px;
	--vs-dropdown-max-height: 350px;
	--vs-dropdown-box-shadow: 0 3px 6px 0 var(--vs-colors--darkest);

	// Options
	--vs-dropdown-option-color: var(--vs-dropdown-color);
	--vs-dropdown-option-padding: 3px 20px;

	//  Active State
	--vs-dropdown-option--active-bg: #{$form-color-selection-hover};
	--vs-dropdown-option--active-color: #{$form-color-input-text};

	margin-bottom: $form-margin-bottom;

	&.-t-dark {
		--vs-colors--lightest: var(--color-grey-200);
		--vs-colors--light: var(--color-grey-400);
		--vs-colors--dark: var(--color-grey-400);
		--vs-colors--darkest: var(--color-grey-500);

		// Search Input
		--vs-search-input-color: #{$form-color-input-text--dark};
		--vs-search-input-bg: #{$form-color-bg--dark};

		//  Disabled State
		--vs-disabled-bg: #{$form-color-bg-disabled--dark};
		--vs-state-disabled-color: #{$form-color-input-text-disabled--dark};
		--vs-state-disabled-controls-color: #{$form-color-input-text-disabled--dark};

		//  Borders
		--vs-border-color: #{$form-color-border--dark};

		//  Component Controls: Clear, Open Indicator
		--vs-controls-color: var(--vs-colors--light);
		--vs-controls--deselect-text-shadow: 0 1px 0 var(--color-soft-white);

		//  Selected
		--vs-selected-bg: var(--color-grey-050);
		--vs-selected-color: #{$form-color-input-text--dark};
		--vs-selected-border-color: var(--vs-border-color);

		//  Dropdown
		--vs-dropdown-bg: #{$form-color-bg-focus--dark};
		--vs-dropdown-color: #{$form-color-input-text--dark};
		--vs-dropdown-box-shadow: none;

		// Options
		--vs-dropdown-option-color: var(--vs-dropdown-color);

		//  Active State
		--vs-dropdown-option--active-bg: #{$form-color-selection-hover--dark};
		--vs-dropdown-option--active-color: #{$form-color-input-text--dark};
	}

	body.-t-light & {
		//  Active State
		--vs-dropdown-option--active-bg: var(--color-grey-950);
		--vs-dropdown-option--active-color: #{$form-color-input-text};

		//  Borders
		--vs-border-color: var(--color-grey-800);
		--vs-selected-border-color: var(--color-grey-700);
	}

	// SDX reset
	h1,
	h2,
	h3,
	h4,
	h5 {
		letter-spacing: initial;
	}
}

.v2-select__input-w {
	position: relative;

	.v2-select.is-disabled & {
		cursor: not-allowed;
	}
}

// INPUT
.v2-select__hidden-input {
	position: absolute;
	opacity: 0;
	pointer-events: none;
}

.vs__dropdown-toggle {
	// height: $form-input-height;
	width: 100%;
	min-height: $form-input-height;
	padding-bottom: 0;

	.v-select:not(.vs--disabled):hover & {
		border-color: $form-color-border-hover;

		.v2-select.-t-dark & {
			border-color: $form-color-border-hover--dark;
		}

		body.-t-light & {
			border-color: var(--color-grey-700);
		}
	}

	.v-select.vs--open & {
		border-color: $form-color-border-focus;

		.v2-select.-t-dark & {
			border-color: $form-color-border-hover--dark;
			border-bottom-color: $form-color-border-focus--dark;
			background-color: $form-color-bg-focus--dark;
		}
	}

	.v-select.has-selection.is-clearable.vs--searchable & {
		pointer-events: none;
	}

	.vs--searchable:not(.vs--open) & {
		cursor: pointer;
	}
}

.vs__selected-options {
	width: 100%;
	min-width: 0; // fix for overflow ellipsis
	align-items: center;
	padding: 0;
}

.vs__selected {
	display: block;
	margin: 0;
	padding-left: $form-input-padding;
	padding-right: 0;
	border: none;
	overflow: hidden;
	text-overflow: ellipsis;

	.v-select.vs--open:not(.vs--unsearchable) & {
		display: none;
	}

	.vs--open & {
		width: 100%;
	}

	.vs--disabled & {
		color: var(--vs-state-disabled-color);
	}
}

// SEARCH
.vs__search {
	@include form-input-placeholder-color();
	background-color: transparent !important;
	color: var(--vs-search-input-color);

	.v-select.has-selection:not(.vs--open) & {
		position: absolute;
	}
}

.vs__search:focus {
	position: relative;
}

.vs__search,
.vs__search:focus {
	@include form-input-text-font();

	line-height: 0;
	margin: 0;
	padding: 0 $form-input-padding;
	margin-top: $form-input-padding;
	margin-bottom: $form-input-padding;
	border: none;
}

// SELECTED OPTION
.v2-select__selected-option {
	display: flex;
	align-items: center;
}

.v2-select__selected-option-image {
	max-width: 48px;
	max-height: 48px;
	margin-right: $sp4;
	margin-top: $form-input-padding;
	margin-bottom: $form-input-padding;
}

.v2-select__selected-option-text-w {
	margin-top: $form-input-padding;
	margin-bottom: $form-input-padding;
	min-width: 0; // fix for overflow ellipsis

	.v2-select__selected-option.-with-image & {
		height: 48px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

.v2-select__selected-option-title {
	@include form-input-text-font();
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin: 0;
	line-height: 120%;
}

.v2-select__selected-option-subtitle {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-300);
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: $sp2;
}

// DISABLED
.vs--disabled {
	.vs__dropdown-toggle {
		cursor: not-allowed !important;
	}

	.vs__actions .v2-select__action-btn {
		cursor: not-allowed;
		color: var(--color-grey-300) !important;
	}

	&.vs--searchable .vs__dropdown-toggle {
		max-height: $form-input-height;
	}
}

// OPTION
.v2-select__option {
	display: flex;
	justify-content: space-between;
	align-items: center;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	min-width: 0; // fix for overflow ellipsis

	&.-with-subtitle {
		flex-direction: column;
		align-items: flex-start;
	}
}

.v2-select__option-title {
	@include form-input-text-font();
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	margin: 0;

	.v2-select__option.-with-image & {
		max-width: calc(100% - 50px);
	}
}

.v2-select__option-subtitle {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-300);
	margin: 0;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-top: $sp2;
}

.v2-select__option-image {
	max-width: 36px;
	max-height: 36px;
}

// DROPDOWN
.vs__dropdown-menu {
	border: none;
	margin-top: 1px;

	.v-select.-opens-left & {
		right: 0;
		left: auto;
	}
}

.vs__dropdown-option {
	padding: $form-input-padding;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: block;
	min-width: 0; // fix for overflow ellipsis

	&--selected {
		background-color: $form-color-selection-active;

		body.-t-light & {
			background-color: var(--color-grey-900);
		}

		.v2-select.-t-dark & {
			background-color: $form-color-selection-active--dark;
		}
	}
}

// ACTIONS
.vs__actions {
	padding: 0;
	width: 46px;
	flex-shrink: 0;
}

.vs__open-indicator {
	display: flex;
	margin: -1px 0;

	.v-select.has-selection.is-clearable & {
		display: none;
	}
}

.v2-select__action-btn {
	color: $form-color-icon !important;
	max-height: 46px;

	&.-clear {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;
		color: $form-color-icon;
	}

	.v2-select.-t-dark & {
		color: $form-color-icon--dark !important;
	}

	.v2-select.is-disabled & {
		pointer-events: none;
	}
}

.v2-select__no-results {
	padding-top: $sp3;
	padding-bottom: $sp3;
}

.v2-select__text-after {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-500);
	text-align: right;
	padding-top: $sp3;

	.v2-select.-t-dark & {
		color: var(--color-grey-300);
	}

	&.-left {
		text-align: left;
	}
}

/* stylelint-enable */
</style>
