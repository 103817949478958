import laroute from '@laroute';
import axios from 'axios';
import { getFormatedPatchedArgs } from '@shared/utils/getFormatedPatchedArgs';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';

const CancelToken = axios.CancelToken;

const cancelTokens = {
	getStatistics:          null,
	getStatisticsForEvents: null,
	getAdAssetsStatistics:  null,
};

const submit = async(_options = {}) => {
	const options = {
		method: 'GET',
		..._options,
	};

	if (!_options.url) {
		console.error('Options parameter URL is required');
		return;
	}

	return new Promise((resolve, reject) => {
		const axiosInstance = window.isStorybook ? window.axios : axios;
		if (!axiosInstance) return;
		axiosInstance(options).then(response => {
			resolve(response);
		}).catch((error) => {
			const errorResponse = error.response?.data?.errors;

			if (axios.isCancel(error)) {
				return reject(new Error('Request canceled'));
			}
			if (errorResponse) {
				for (const [_key, apiErrors] of Object.entries(errorResponse)) {
					apiErrors.forEach(apiError => {
						toast.error(apiError);
					});
				}
			} else if (!_options.suppressErrors) {
				toast.error($I18n.trans('commons.something_went_wrong'));
			}
			// todo log error to logging service
			reject(error);
		});
	});
};

/**
 * Maps data from Frontend to Backend
 * @param {*} data
 */
export const getApiDataForSlide = (data, isFormData = false) => {
	const apiData = {};

	if (Object.prototype.hasOwnProperty.call(data, 'headline')) {
		apiData.title = isFormData ? JSON.stringify(data.headline) : data.headline || '';
	}
	if (Object.prototype.hasOwnProperty.call(data, 'subtitle')) {
		apiData.subtitle = isFormData ? JSON.stringify(data.subtitle) : data.subtitle || '';
	}
	if (Object.prototype.hasOwnProperty.call(data, 'published')) {
		apiData.published = data.published;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'link')) {
		apiData.link = isFormData ? JSON.stringify(data.link) : data.link || '';
	}
	if (Object.prototype.hasOwnProperty.call(data, 'image')) {
		apiData.image = data.image;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'imageMobile')) {
		apiData.imageMobile = data.imageMobile;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'logo')) {
		apiData.logo = data.logo;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'slideType')) {
		apiData.slide_type = data.slideType;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'eventId')) {
		apiData.event_id = data.eventId;
	}
	if (Object.prototype.hasOwnProperty.call(data, 'showLiveVideo')) {
		apiData.show_live_video = data.showLiveVideo;
	}

	return apiData;
};

export const api = {
	getEvent: async(eventId, params) => {
		return submit(
			{
				method: 'GET',
				url:    laroute.route('api.event', { event: eventId }),
				params,
			}
		);
	},
	getEvents: async(params) => {
		// if (axiosCancelFunction) {
		// 	axiosCancelFunction();
		// }

		return submit(
			{
				method: 'GET',
				url:    laroute.route('api.events'),
				params,
				// cancelToken: new CancelToken(function executor(cancel) {
				// 	axiosCancelFunction = cancel;
				// }),
			}
		);
	},
	verifyUpload: async(uploadId) => {
		return submit(
			{
				method:         'GET',
				url:            laroute.route('api.upload.verify', { uploadId: uploadId }),
				suppressErrors: true,
			}
		);
	},
	getEventVideoStatus: async(eventId) => {
		return submit(
			{
				method: 'GET',
				url:    laroute.route('api.event.video_status', { event: eventId }),
			}
		);
	},
	deleteEvent: async(eventId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.event.delete', { event: eventId }),
		});
	},
	deleteEvents: async(eventIds) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.events.delete'),
			data:   {
				eventIds,
			},
		});
	},
	updateEvent: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    `/api/v1/event/${id}`,
			data:   getFormatedPatchedArgs(data),
		});
	},
	updateEvents: async(eventIds, data) => {
		const {
			patchData,
			arenaVisibility,
			labelsAdd,
			labelsRemove,
			adTypePlayerLogo,
			adTypePlayerBanner,
			adTypePlayerAd,
			adTypeEventBanner,
		} = data;

		const payloadData = {
			eventIds,
		};

		const fields = {
			patchData: patchData ? getFormatedPatchedArgs(patchData) : null,
			arenaVisibility,
			labelsAdd,
			labelsRemove,
			adTypePlayerLogo,
			adTypePlayerBanner,
			adTypePlayerAd,
			adTypeEventBanner,
		};

		Object.keys(fields).forEach(key => {
			if (fields[key] !== null) {
				payloadData[key] = fields[key];
			}
		});

		return submit({
			method: 'PATCH',
			url:    laroute.route('api.events.update'),
			data:   payloadData,
		});
	},
	resumeLiveEvent: async(eventId, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.resume_live', { event: eventId }),
			data,
		});
	},
	endEvent: async(eventId) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.end_live', { event: eventId }),
		});
	},
	extendEvent: async(eventId, data) => {
		const bodyFormData = new FormData();
		bodyFormData.append('minutes', data.minutes);

		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.extend_live', { event: eventId }),
			data:   bodyFormData,
		});
	},
	getPlayoutUrl: async(eventId, pw) => {
		const options = {
			method: 'GET',
			url:    laroute.route('api.event.playout', { event: eventId }),
		};

		if (pw) {
			options.params = {
				password: btoa(pw),
			};
		}
		return submit(options);
	},
	getIngestDetails: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.channel_ingest.status', { event: eventId }),
		});
	},
	getIngestDetailsForChannelId: async(channelId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.channel_ingest.status', { id: channelId }),
		});
	},
	getIngestLogs: async(eventId, params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.channel_ingest.logs', { event: eventId }),
			params,
		});
	},
	getIngestLogsForChannelId: async(channelId, params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.channel_ingest.logs', { id: channelId }),
			params,
		});
	},
	stopTranscodersForEvent: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.channel_ingest.stop', { event: eventId, syncTranscoderSettings: true }),
		});
	},
	toggleEventForwarding: async(eventId, forwardingIds, action) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.distribution.toggle', { event: eventId }),
			data:   {
				action,
				distributionIds: forwardingIds,
			},
		});
	},
	get3QStatusForEventsMonitoring(eventIds, params) {
		return submit({
			method: 'POST',
			url:    laroute.route('api.channel_ingest.status.events'),
			data:   {
				eventIds,
				...params,
			},
			suppressErrors: true,
		});
	},
	getAdAssets: async(filters = null, relations = null) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.ads', { filters, relations }),
		});
	},
	createAd: async(data) => {
		return submit({
			method: 'POST',
			url:    '/api/v1/ad',
			data,
		});
	},
	updateAd: async(id, data) => {
		return submit({
			method: 'POST', // using POST because laravel doesn't handle Arrays in FormData for PATCH requests ideally
			url:    `/api/v1/ad/${id}`,
			data,
		});
	},
	deleteAd: async(adId) => {
		return submit({
			method: 'DELETE',
			url:    `/api/v1/ad/${adId}`,
		});
	},
	/**
	 *
	 * @param {Number} id
	 * @param {Object} data - published, highlighted, eventId
	 * @returns {Promise}
	 */
	updateAdPlacement: async(adId, data) => {
		const urlParams = {
			ad: adId,
		};
		if (Object.prototype.hasOwnProperty.call(data, 'eventId')) {
			urlParams.event = data.eventId;
		}
		return submit({
			method: 'PUT',
			url:    laroute.route('api.ads.placements.update', urlParams),
			data,
		});
	},
	deleteAdPlacement: async(adId, eventId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.ads.placements.delete', { ad: adId, event: eventId }),
		});
	},
	getPlacedEventAds: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.ads', { event: eventId, relations: ['adEvent.image'] }),
		});
	},
	getAvailableEventAds: async(eventId = null) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.ads', { event: eventId, relations: ['adEvent.image'] }),
		});
	},
	switchUserContext: async(userContextId) => {
		return submit({
			method: 'POST',
			url:    '/api/context',
			data:   {
				userContextId,
			},
		});
	},
	setEventOffset: async(eventId, offset) => {
		return submit({
			method: 'PATCH',
			params: { createLogEntry: false },
			url:    `/api/v1/event/${eventId}`,
			data:   [{
				op:    'replace',
				path:  '/tagOffsetSeconds',
				value: offset,
			}],
		});
	},
	setEventReviewed: async(eventId, value) => {
		return submit({
			method: 'PATCH',
			url:    `/api/v1/event/${eventId}`,
			data:   [{
				op:   'replace',
				path: '/reviewed',
				value,
			}],
		});
	},
	updateEventSocialAccountActive: async(eventId, accountType = '', accountId = 0, active = false) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.social_account.update', {
				event:             eventId,
				socialAccountType: accountType,
				socialAccountId:   accountId,
			}),
			data: { active },
		});
	},

	// Event Assets
	getEventAssets: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.assets', { event: eventId }),
		});
	},
	addEventAsset: async(eventId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.event.asset.create', { event: eventId }),
			data,
		});
	},
	updateEventAsset: async(eventId, assetId, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.asset.update', { event: eventId, asset: assetId }),
			data:   data,
		});
	},
	deleteEventAsset: async(eventId, assetId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.event.asset.delete', { event: eventId, asset: assetId }),
		});
	},
	postEventAssetFile: async(eventId, assetId, file) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.event.asset.file', { event: eventId, asset: assetId }),
			data:   file,
		});
	},

	// Tag Templates
	getEventTagTemplates: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.tag_templates', { event: eventId }),
		});
	},
	addEventTagTemplate: async(eventId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.event.tag_template.create', { event: eventId }),
			data,
		});
	},
	updateEventTagTemplate: async(eventId, tagTemplateId, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.event.tag_template.update', { event: eventId, tagTemplate: tagTemplateId }),
			data:   data,
		});
	},
	deleteEventTagTemplate: async(eventId, tagTemplateId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.event.tag_template.delete', { event: eventId, tagTemplate: tagTemplateId }),
		});
	},
	postEventTagTemplateFile: async(eventId, file, tagTemplateId = null) => {
		let url = laroute.route('api.event.tag_template.file.preview', { event: eventId });
		if (tagTemplateId) {
			url = laroute.route('api.event.tag_template.file', { event: eventId, tagTemplate: tagTemplateId });
		}
		return submit({
			method: 'POST',
			url:    url,
			data:   file,
		});
	},

	// Labels
	getLabels: async(includeSubcontexts = false, options) => {
		const params = {
			includeSubcontexts,
			perPage: options?.perPage || 5000,
		};
		if (options?.filter) {
			params.filter = options.filter;
		}
		if (options?.query) {
			params.query = options.query;
		}
		return submit({
			method: 'GET',
			url:    laroute.route('api.labels', params),
		});
	},
	createLabel: async(name) => {
		return submit({
			method: 'POST',
			url:    '/api/v1/label',
			data:   {
				name,
			},
		});
	},
	updateLabel: async(id, name) => {
		return submit({
			method: 'PUT',
			url:    `/api/v1/label/${id}`,
			data:   {
				name,
			},
		});
	},
	deleteLabel: async(id) => {
		return submit({
			method: 'DELETE',
			url:    `/api/v1/label/${id}`,
		});
	},
	updateLabels: async(eventId, labelIds) => {
		return submit({
			method: 'PUT',
			url:    `/api/v1/event/${eventId}/labels`,
			data:   {
				labelIds,
			},
		});
	},

	// Albums
	getAlbums: async(relations = null, filter = null, options) => {
		const params = { page: 1, perPage: 100 };
		if (relations) {
			params.relations = relations;
		}
		if (filter) {
			params.filter = filter;
		}
		if (options?.perPage) {
			params.perPage = options.perPage;
		}
		if (options?.query) {
			params.query = options.query;
		}

		return submit({
			method: 'GET',
			url:    laroute.route('api.albums', params),
		});
	},
	createAlbum: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.album.create'),
			data,
		});
	},
	getAlbum: async(albumId, params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.album', { album: albumId }),
			params,
		});
	},
	updateAlbum: async(albumId, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.album.update', { album: albumId }),
			data:   getFormatedPatchedArgs(data),
		});
	},
	deleteAlbum: async(albumId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.album.delete', { album: albumId }),
		});
	},
	updateAlbumEventOrder: async(albumId, eventOrder) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.album.event.order', { album: albumId }),
			data:   {
				orderEventIds: eventOrder,
			},
		});
	},
	// Arena Slides
	getSlides: async(subsiteId) => {
		let url = laroute.route('api.arena_slides');
		if (subsiteId) {
			url += `?subsiteId=${subsiteId}`;
		}
		return submit({
			method: 'GET',
			url:    url,
		});
	},
	getSlide: async(id) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_slide', { slide: id }),
		});
	},
	createSlide: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.arena_slide.create'),
			data,
		});
	},
	updateSlide: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.arena_slide.update', { slide: id }),
			data:   getFormatedPatchedArgs(getApiDataForSlide(data, false)),
		});
	},
	updateSlideImages: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.arena_slide.update_images', { slide: id }),
			data,
		});
	},
	deleteSlide: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_slide.delete', { slide: id }),
		});
	},
	sortSlides: async(arenaSlidesOrderIds) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_slide.order'),
			data:   {
				arenaSlidesOrderIds,
			},
		});
	},

	// Arena Navigation Items
	getNavigationItems: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_navigation'),
		});
	},
	getLinkableItems: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_navigation.links'),
		});
	},
	createNavigationItem(data) {
		return submit({
			method: 'POST',
			url:    laroute.route('api.arena_navigation.create'),
			data,
		});
	},
	deleteNavigationItem: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_navigation.delete', { arenaNavigationItem: id }),
		});
	},
	updateNavigationItem: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.arena_navigation.update', { arenaNavigationItem: id }),
			data:   data,
		});
	},
	updateNavigationItemOrder: async(orderIds) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_navigation.order'),
			data:   {
				orderIds,
			},
		});
	},

	// Arena Infos
	getArenaInfos: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_infos'),
		});
	},
	createArenaInfo: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.arena_infos.create'),
			data,
		});
	},
	updateArenaInfo: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.arena_info.update', { info: id }),
			data:   getFormatedPatchedArgs(data),
		});
	},
	deleteArenaInfo: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_info.delete', { info: id }),
		});
	},

	// Arena Lanes
	addLane: async(targetId, type, hostingSubsiteId = null, extraData) => {
		const data = {
			hostingSubsiteId,
		};
		if (type === ArenaLaneType.ALBUM) {
			data.album_id = targetId;
		}
		if (type === ArenaLaneType.AD) {
			data.ad_id = targetId;
		}
		if (type === ArenaLaneType.SUBSITE) {
			data.subsite_id = targetId;
		}
		if (type === ArenaLaneType.ALBUM || type === ArenaLaneType.SUBSITE) {
			data.display_ad_ids = extraData.displayAdIds;
			data.visible = extraData.visible;
			data.highlighted = extraData.highlighted;
		}
		return submit({
			method: 'POST',
			url:    laroute.route('api.arena_lanes.create'),
			data,
		});
	},
	getLanes: async(hostingSubsiteId = null) => {
		let url = laroute.route('api.arena_lanes');
		if (hostingSubsiteId) {
			url += `?hostingSubsiteId=${hostingSubsiteId}`;
		}
		return submit({
			method: 'GET',
			url:    url,
		});
	},
	updateLane: async(id, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.arena_lanes.update', { lane: id }),
			data:   getFormatedPatchedArgs(data),
		});
	},
	sortLanes: async(laneOrderIds) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_lanes.order'),
			data:   {
				laneOrderIds,
			},
		});
	},
	removeLane: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_lanes.delete', { lane: id }),
		});
	},

	// SocialPosts
	getSocialPosts: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.social_posts'),
		});
	},
	updateSocialPost: async(id, published) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.social_posts.update', { socialPost: id }),
			data:   {
				published,
			},
		});
	},

	// Arena Subsites
	addSubsite: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.arena_subsites.create'),
			data,
		});
	},
	getSubsites: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_subsites'),
		});
	},
	updateSubsite: async(id, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_subsites.update', { subsite: id }),
			data:   data,
		});
	},
	deleteSubsite: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_subsites.delete', { subsite: id }),
		});
	},
	getSubsite: async(id) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.arena_subsites.details', { subsite: id, relations: ['contextable', 'contextable.arenacontext', 'filterRules.labels'] }),
		});
	},
	updateSubsiteSlides: async(subsiteId, slideIds) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_subsites.slides.edit', { subsite: subsiteId }),
			data:   { slideIds },
		});
	},
	getAssignedAdsForSubsite: async(subsiteId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.ads', { subsiteId }),
		});
	},
	updateSubsiteAd: async(subsiteId, adId, highlighted = false) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_subsites.adds.update', { subsite: subsiteId }),
			data:   {
				adId,
				highlighted,
			},
		});
	},
	removeSubsiteAd: async(subsiteId, adId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.arena_subsites.adds.delete', { subsite: subsiteId }),
			data:   { adId },
		});
	},

	// FanZone
	getPaymentOffers: async(relations = [], countedRelations = []) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.payment.offers', { relations, countedRelations }),
		});
	},
	getPaymentOffer: async(paymentOfferId, relations = []) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.payment.offer', { paymentOffer: paymentOfferId, relations }),
		});
	},
	getPaymentOfferVouchers: async(paymentOfferId, params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.payment.offers.vouchers', {
				paymentOffer: paymentOfferId,
				...params,
			}),
		});
	},
	generatePaymentOfferVouchers: async(paymentOfferId, amount) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.payment.offers.vouchers.generate', { paymentOffer: paymentOfferId }),
			data:   { amount },
		});
	},
	addPaymentOffer: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.payment.offers.create'),
			data,
		});
	},
	updatePaymentOffer: async(id, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.payment.offers.update', { paymentOffer: id }),
			data:   data,
		});
	},
	deletePaymentOffer: async(id) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.payment.offers.delete', { paymentOffer: id }),
		});
	},
	deletePaymentOfferVoucher: async(paymentOfferId, paymentVoucherId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.payment.offers.vouchers.delete', {
				paymentOffer:   paymentOfferId,
				paymentVoucher: paymentVoucherId,
			}),
		});
	},
	sortPaymentOffers: async(paymentOfferOrderIds) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.payment.offers.order'),
			data:   {
				paymentOfferOrderIds,
			},
		});
	},

	// ArenaConfigs
	updateArenaConfig: async(data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.arena_config.update'),
			data:   data,
		});
	},

	// IP Whitelist
	getIpWhitelistEntries: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.ip_whitelist.get'),
		});
	},

	addIpWhitelistEntry: async(data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.ip_whitelist.add_entry'),
			data,
		});
	},

	removeIpWhitelistEntry: async(data) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.ip_whitelist.add_entry'),
			data,
		});
	},

	// Event Ownership
	getEventOwnership: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.ownership.get', { event: eventId }),
		});
	},
	addOwnerships: async(eventId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.ownership.add', { event: eventId }),
			data,
		});
	},
	transferOwnership: async(eventId, ownershipId, newOwnership) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.ownership.transfer', { event: eventId, ownership: ownershipId }),
			data:   newOwnership,
		});
	},
	updateOwnership: async(eventId, ownershipId, data) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.ownership.update', { event: eventId, ownership: ownershipId }),
			data,
		});
	},
	deleteOwnership: async(eventId, ownershipId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.ownership.update', { event: eventId, ownership: ownershipId }),
		});
	},
	getRelatedContexts: async(data) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.user.context.related', data),
		});
	},

	// Statistics
	getStatistics: async(params) => {
		if (cancelTokens.getStatistics) {
			cancelTokens.getStatistics();
		}

		return submit({
			method:      'GET',
			url:         laroute.route('api.statistics'),
			params,
			cancelToken: new CancelToken(function executor(cancel) {
				cancelTokens.getStatistics = cancel;
			}),
		});
	},
	getStatisticsForEvents: async(params) => {
		if (cancelTokens.getStatisticsForEvents) {
			cancelTokens.getStatisticsForEvents();
		}

		return submit({
			method:      'GET',
			url:         laroute.route('api.statistics.events'),
			params,
			cancelToken: new CancelToken(function executor(cancel) {
				cancelTokens.getStatisticsForEvents = cancel;
			}),
		});
	},
	getEventStatistics: async(eventId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.statistics.event', { event: eventId }),
		});
	},
	getAdsStatisticsForDateRange: async(params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.statistics.ads.range'),
			params,
		});
	},
	getAdAssetsStatistics: async(params) => {
		if (cancelTokens.getAdAssetsStatistics) {
			cancelTokens.getAdAssetsStatistics();
		}

		return submit({
			method:      'GET',
			url:         laroute.route('api.statistics.ads.assets'),
			params,
			cancelToken: new CancelToken(function executor(cancel) {
				cancelTokens.getAdAssetsStatistics = cancel;
			}),
		});
	},

	// Highlight-Rules
	getHighlightRules: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.highlight_rules'),
		});
	},
	activateRule: async(ruleId) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.highlight_rules.activate', { rule: ruleId }),
		});
	},
	deactivateRule: async(ruleId) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.highlight_rules.deactivate', { rule: ruleId }),
		});
	},
	deleteRule: async(ruleId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.highlight_rules.delete', { rule: ruleId }),
		});
	},
	createRule: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.highlight_rules.create'),
			data,
		});
	},
	updateRule: async(ruleId, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.highlight_rules.update', { rule: ruleId }),
			data:   data,
		});
	},
	previewMultipleRule: async(rule) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.highlight_rules.multiple.preview'),
			data:   rule,
		});
	},
	exportMultipleRule: async(rule) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.highlight_rules.multiple.export'),
			data:   rule,
		});
	},
	duplicateRule: async(ruleId) => {
		return submit({
			method: 'PUT',
			url:    laroute.route('api.highlight_rules.duplicate', { rule: ruleId }),
		});
	},

	getCategoryChilds(sportId, categoryParentId) {
		return submit({
			method: 'GET',
			url:    laroute.route('api.sports.category_childs', { sport: sportId, category: categoryParentId }),
		});
	},

	getTags(sportId) {
		return submit({
			method: 'GET',
			url:    laroute.route('api.tags') + `?filters={"sportId":${sportId}}`,
		});
	},

	getEventTags(eventId) {
		return submit({
			method: 'GET',
			url:    laroute.route('api.event.tags', { event: eventId }),
		});
	},

	createVideoCrop: async(eventId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.event.video_crop.create', { event: eventId }),
			data,
		});
	},

	createVideoCropNotification: async(eventId, cropId, email) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.event.video_crop.notify', { event: eventId, cropId: cropId }),
			data:   { email },
		});
	},

	getEditorAssetVideos() {
		return submit({
			method: 'GET',
			url:    laroute.route('api.events.editor_assets'),
			data:   {
				useContextFilter: true,
			},
		});
	},

	getClubs(filters, options) {
		const params = {
			perPage: options?.perPage || 20,
			filter:  JSON.stringify(filters),
		};

		if (options?.query) {
			params.query = options.query;
		}

		return submit({
			method: 'GET',
			url:    laroute.route('api.clubs', params),
		});
	},

	getTeams(filters, options) {
		const params = {
			perPage: options?.perPage || 20,
			filter:  JSON.stringify(filters),
		};

		if (options?.query) {
			params.query = options.query;
		}

		return submit({
			method: 'GET',
			url:    laroute.route('api.teams', params),
		});
	},

	getPlayers(filters, limit = 20) {
		const params = {
			perPage: limit,
			filter:  JSON.stringify(filters),
		};

		return submit({
			method: 'GET',
			url:    laroute.route('api.players', params),
		});
	},

	getSports(filter = null, query = null, relations = [], limit = 20) {
		const params = {
			perPage:   limit,
			filter:    filter,
			relations: relations,
		};

		if (query) {
			params.query = query;
		}

		return submit({
			method: 'GET',
			url:    laroute.route('api.sports', params),
		});
	},

	getVenues(filters = null, query = null) {
		return submit({
			method: 'GET',
			url:    laroute.route('api.venues', { filters, query }),
		});
	},

	// General
	get: async(url, params) => {
		const options = {
			method: 'GET',
			url:    url,
			params,
		};

		return submit(options);
	},
	post: async(url, params) => {
		const options = {
			method: 'POST',
			url:    url,
			params,
		};

		return submit(options);
	},
	put: async(url, params) => {
		const options = {
			method: 'PUT',
			url:    url,
			params,
		};

		return submit(options);
	},

	// categories
	getSportCategoryParents: async(sportId, vodOnly) => {
		return submit({
			method: 'GET',
			url:    `${laroute.route('api.sports.category_parents', { sport: sportId })}${vodOnly ? '?vodOnly=true' : ''}`,
		});
	},
	getSportCategoryChilds: async(sportId, parentCategoryId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.sports.category_childs', { sport: sportId, category: parentCategoryId }),
		});
	},

	getCountries: async(data) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.countries', data),
		});
	},

	// admin
	importUserContextData: async(userContextId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.user_context.import', { userContext: userContextId }),
			data,
		});
	},

	// Moments
	getVideoCrops: async(params) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.events.video_crops'),
			params,
		});
	},

	// Analysis
	createAnalysis: async(data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.analyses.create'),
			data,
		});
	},
	getAllAnalyses: async() => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.analyses.overview'),
		});
	},
	getAnalysis: async(analysisId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.analyses.details', { analysis: analysisId }),
		});
	},
	updateAnalysis: async(analysisId, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.analyses.update', { analysis: analysisId }),
			data:   data,
		});
	},
	deleteAnalysis: async(analysisId) => {
		return submit({
			method: 'DELETE',
			url:    laroute.route('api.analyses.delete', { analysis: analysisId }),
		});
	},
	addVideosToAnalysis: async(analysisId, data) => {
		return submit({
			method: 'POST',
			url:    laroute.route('api.analyses.videos.add', { analysis: analysisId }),
			data,
		});
	},
	getVideosOfAnalysis: async(analysisId) => {
		return submit({
			method: 'GET',
			url:    laroute.route('api.analyses.videos.get', { analysis: analysisId }),
		});
	},
	updateAnalysisVideo: async(analysisId, videoId, data) => {
		return submit({
			method: 'PATCH',
			url:    laroute.route('api.analyses.videos.update', { analysis: analysisId, analysisVideo: videoId }),
			data,
		});
	},
};
