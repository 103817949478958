import Vue from 'vue';
import Router from 'vue-router';
import Profile from '@js/Pages/FanAccount/Profile';
import Security from '@js/Pages/FanAccount/Security';
import Purchases from '@js/Pages/FanAccount/Purchases';
import Settings from '@js/Pages/FanAccount/Settings';
import { getArenaConfig } from '@shared/utils/getArenaConfig';

// Router Plugin is used in VideoTools and Arena Vue Instance
// Regisering it twice causes an error, so we only register it on specific pages
if (window.location.pathname.startsWith('/account')) {
	Vue.use(Router);
}

const noSpoilerModeEnabled = getArenaConfig().enableNoSpoilerMode;

const routes = [
	{
		path:     '/account',
		redirect: '/account/profile',
	},
	{
		path:      '/account/profile',
		name:      'profile',
		component: Profile,
		props:     true,
	},
	{
		path:      '/account/security',
		name:      'security',
		component: Security,
		props:     true,
	},
	{
		path:      '/account/purchases',
		name:      'purchases',
		component: Purchases,
		props:     true,
	},
];

// Settings currently only contains no spoiler mode. If we'll add more, the settings route can be added again.
if (noSpoilerModeEnabled) {
	routes.push({
		path:      '/account/settings',
		name:      'settings',
		component: Settings,
		props:     true,
	});
}

export const router = new Router({
	mode: 'history',
	routes,
});
