<template>
	<Modal
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		class="payment__modal"
		modal-name="PaymentModal"
		:closeable="closeable"
		@opened="onOpen"
	>
		<template #title>
			{{ $I18n.trans('payments.modal.title_' + mode ) }}
		</template>
		<template #content>
			<div class="l-container payment-modal__content">
				<div
					v-if="steps.length > 1"
					class="l-row l-align-center"
				>
					<StepsIndicator
						class="payment-modal__steps"
						arena-theme
						:steps="steps"
						:active-step="activeStep"
					/>
				</div>
				<template v-if="isLogin">
					<div
						v-if="!hideIntro"
						class="l-row l-align-center"
					>
						<div class="l-col l-col--12">
							<div class="payment-modal__head">
								<h2 class="payment-modal__title">
									{{ $I18n.trans('payments.modal.step_1.headline_' + mode) }}
								</h2>
								<span class="payment-modal__subtitle">{{ $I18n.trans('payments.modal.step_1.subheadline') }}</span>
							</div>
						</div>
					</div>
					<LoginComponent
						:login-redirect-url="loginRedirectUrl"
						@submitted="loginSubmitted"
					/>
				</template>
				<template v-if="isCheckout">
					<div class="l-row l-align-center">
						<div class="l-col l-col--12">
							<div
								v-if="!voucherValid && availableOffers && availableOffers.length > 0"
								class="payment-modal__head"
							>
								<h2 class="payment-modal__title">
									{{ $I18n.trans('payments.modal.step_2.headline') }}
								</h2>
								<span class="payment-modal__subtitle">{{ $I18n.trans('payments.modal.step_2.subheadline') }}</span>
							</div>
							<div
								v-if="voucherValid"
								class="payment-modal__head"
							>
								<h2 class="payment-modal__title">
									{{ $I18n.trans('payments.modal.voucher.headline') }}
								</h2>
								<span class="payment-modal__subtitle">{{ $I18n.trans('payments.modal.voucher.subheadline') }}</span>
							</div>
							<div v-if="availableOffers && availableOffers.length > 0">
								<div
									v-for="paymentOffer in availableOffers"
									:key="paymentOffer.id"
									class="payment-modal__offer-selections"
									:class="{
										'is-selected': paymentOffer.id === offer.id,
										'has-multiple': availableOffers.length > 1
									}"
									@click="setOffer(paymentOffer)"
								>
									<div
										v-if="paymentOffer.subscription"
										class="payment-modal__offer"
									>
										<div class="payment-modal__offer-title">
											{{ $Helpers.getTranslatedContent(paymentOffer.name) }}
										</div>
										<div class="payment-modal__offer-desc">
											{{ $Helpers.getTranslatedContent(paymentOffer.description) }}
										</div>
									</div>
									<div
										v-else-if="isLeagueMatch(event)"
										class="payment-modal__offer"
									>
										<div class="payment-modal__offer-title">
											{{ $Helpers.getTranslatedContent(paymentOffer.name) }}:
											<EventInfos
												class="payment-modal__offer-match"
												:title="event.name"
												:sub-title="getDateTime(event.startTimestamp)"
												:large="true"
												:large-title="true"
												:show-scores="true"
												v-bind="getMatchScores(event)"
												:home-score="null"
												:guest-score="null"
											/>
										</div>
									</div>
									<div
										v-else
										class="payment-modal__offer"
									>
										<div class="payment-modal__offer-title">
											{{ $Helpers.getTranslatedContent(paymentOffer.name) }}: {{ event.name }}
										</div>
										<div class="payment-modal__offer-desc">
											{{ getDateTime(event.startTimestamp) }}
										</div>
									</div>
									<div class="payment-modal__offer-price">
										<span class="payment-modal__offer-price-desc">
											{{ $I18n.trans('payments.modal.step_2.price') }}
										</span>
										{{ formattedPrice(paymentOffer) }}
									</div>
								</div>
								<div
									v-if="!voucherValid"
									class="voucher"
								>
									<span class="voucher__title">
										{{ $I18n.trans('payments.modal.voucher.title') }}
									</span>
									<InputRadio
										name="useVoucher"
										:options="radioOptions"
										:dark-theme="!$arenaConfig.isLightTheme"
										:stacked="true"
										:selected="{ value: 'false' }"
										@change="setVoucher"
									/>
									<InputText
										v-if="useVoucher"
										class="voucher__input"
										name="voucher"
										type="text"
										:placeholder="$I18n.trans('payments.modal.voucher.placehodler')"
										:label="$I18n.trans('payments.modal.voucher.enter_voucher')"
										:dark-theme="!$arenaConfig.isLightTheme"
										:value="voucherCode"
										:custom-error-msg="$I18n.trans('payments.modal.voucher.invalid')"
										:show-error="voucherChecked && !voucherValid"
										@input="(data) => voucherCode = data"
									/>
									<Button
										v-if="useVoucher"
										class="voucher__apply"
										:text="$I18n.trans('commons.check')"
										modifier="secondary"
										:disabled="isBusy || !voucherCode"
										:theme="$arenaConfig.isLightTheme ? null : 'dark'"
										@click="checkVoucher"
									/>
								</div>
								<Button
									v-if="offer"
									class="payment-modal__continue"
									:text="submitText"
									:disabled="isBusy || (useVoucher && !voucherValid)"
									:theme="$arenaConfig.isLightTheme ? null : 'dark'"
									@click="submitForm"
								/>
								<InputError
									v-if="error"
									class="payment-modal__error"
									:text="$I18n.trans('payments.modal.step_2.error')"
								/>
							</div>
							<div
								v-if="availableOffers && availableOffers.length === 0"
								class="payment-modal__offer -center"
							>
								{{ $I18n.trans('payments.paywall.no_offers_' + mode) }}
							</div>
						</div>
					</div>
				</template>
				<template v-if="isConfirm">
					<template v-if="isPaymentPending">
						<div class="payment-modal__head">
							<h2 class="payment-modal__title">
								{{ $I18n.trans('payments.modal.step_3.please_wait.title') }}
							</h2>
							<span class="payment-modal__subtitle">{{ $I18n.trans('payments.modal.step_3.please_wait.subtitle') }}</span>
							<LoadingSpinner
								class="paywall-modal__loading"
							/>
							<!-- eslint-disable vue/no-v-html -->
							<span
								class="payment-modal__subtitle"
								v-html="$I18n.trans('payments.modal.step_3.please_wait.reference', { orderCode: urlParams.get('orderCode') } )"
							/>
							<!--eslint-enable-->
						</div>
					</template>
					<template v-if="isPaymentConfirmed">
						<div class="payment-modal__head">
							<icon
								icon-name="fireworks"
								size="xxxl"
								class="payment-modal__icon"
							/>
							<h2 class="payment-modal__title">
								{{ $I18n.trans('payments.modal.step_3.confirmed.title') }}
							</h2>

							<div
								v-if="continueOnOtherDevice"
							>
								<!-- eslint-disable vue/no-v-html -->
								<span
									class="payment-modal__subtitle"
									v-html="$I18n.trans('payments.modal.step_3.confirmed.continue_on_other_device', { event: event.name })"
								/>
								<!--eslint-enable-->
								<a href="/">
									<Button
										href
										class="payment-modal__continue"
										:text="$I18n.trans('payments.modal.step_3.confirmed.to_frontpage')"
										:theme="$arenaConfig.isLightTheme ? null : 'dark'"
									/>
								</a>
							</div>
							<div
								v-else
							>
								<!-- eslint-disable vue/no-v-html -->
								<span
									v-if="event"
									class="payment-modal__subtitle"
									v-html="$I18n.trans('payments.modal.step_3.confirmed.subtitle', { event: event.name })"
								/>
								<!--eslint-enable-->
								<Button
									v-if="event"
									class="payment-modal__continue"
									:text="$I18n.trans('payments.modal.step_3.confirmed.continue')"
									:theme="$arenaConfig.isLightTheme ? null : 'dark'"
									@click="reload()"
								/>
								<Button
									v-else
									class="payment-modal__continue"
									:text="$I18n.trans('commons.continue')"
									:theme="$arenaConfig.isLightTheme ? null : 'dark'"
									@click="toProfile()"
								/>
							</div>
						</div>
					</template>
					<template v-if="isPaymentFailed">
						<h2 class="payment-modal__title -center">
							{{ $I18n.trans('payments.modal.error.title') }}
						</h2>

						<icon
							icon-name="sad"
							size="xxxl"
							class="payment-modal__icon"
						/>
						<span
							class="payment-modal__subtitle -center"
							v-html="$I18n.trans('payments.modal.error.subtitle', { code: urlParams.get('orderCode') })"
						/>
						<Button
							class="payment-modal__continue"
							:text="$I18n.trans('payments.modal.error.back')"
							:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							@click="reload()"
						/>
					</template>
					<template v-if="isPaymentCanceled">
						<h2 class="payment-modal__title -center">
							{{ $I18n.trans('payments.modal.canceled.title') }}
						</h2>

						<icon
							icon-name="sad"
							size="xxxl"
							class="payment-modal__icon"
						/>
						<span
							class="payment-modal__subtitle -center"
							v-html="$I18n.trans('payments.modal.canceled.subtitle')"
						/>
						<Button
							class="payment-modal__continue"
							:text="$I18n.trans('payments.modal.canceled.back')"
							:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							@click="reload()"
						/>
					</template>
				</template>
			</div>
		</template>
	</Modal>
</template>

<script>
import laroute from '@laroute';

import { mapGetters, mapState } from 'vuex';

import Modal from '@shared/components/Modal';
import LoginComponent from '@js/components/Login/LoginComponent';
import EventInfos from '@shared/components/EventCard/EventInfos';
import { getMatchScores } from '@shared/utils/eventUtils';
import { isLeagueMatch } from '@shared/components/EventCard/EventCardProps';
import StepsIndicator from '@shared/components/StepsIndicator';
import InputText from '@shared/components/Form/InputText';
import Button from '@shared/components/Button';
import InputError from '@shared/components/Form/InputError';
import Icon from '@shared/components/Icon';
import InputRadio from '@shared/components/Form/InputRadio';
import LoadingSpinner from '@shared/components/V2LoadingSpinner';
import LoggingService from '@shared/js/LoggingService';

import { formatCurrency } from '@shared/utils/formatNumber';
import { getDateTime } from '@shared/utils/timeUtils';

import storage from '@shared/js/storage';
import { authApi } from '@js/api/client';

export const PAYMENT_STATE = {
	FAILED:   'paymentFailed',
	SUCCESS:  'paymentSuccess',
	CANCELED: 'paymentCanceled',
	PENDING:  'paymentPending',
};

export const ORDER_STATE = {
	PENDING:   'pending',
	CONFIRMED: 'confirmed',
	CREATED:   'created',
};

export const MODAL_STEPS = {
	login:    0,
	checkout: 1,
	confirm:  2,
};

export const MODAL_ACTION = {
	LOGIN:    'login',
	CHECKOUT: 'checkout',
	CONFIRM:  'confirm',
};

export default {
	name:       'PaymentModal',
	components: { Modal, LoginComponent, StepsIndicator, InputText, Button, InputError, InputRadio, Icon, LoadingSpinner, EventInfos },
	props:      {
		event: {
			type:     Object,
			required: false,
			default:  null,
		},
	},
	data() {
		return {
			steps: [
				this.$I18n.trans('payments.modal.step_1.step'),
				this.$I18n.trans('payments.modal.step_2.step'),
				this.$I18n.trans('payments.modal.step_3.step'),
			],
			radioOptions: [
				{
					label: $I18n.trans('payments.modal.voucher.no_voucher'),
					value: false,
				},
				{
					label: $I18n.trans('payments.modal.voucher.use_voucher'),
					value: true,
				},
			],
			activeStep:            null,
			paymentOffers:         null,
			voucherCode:           null,
			offer:                 null,
			voucher:               null,
			error:                 null,
			paymentVerification:   null,
			qrCode:                null,
			paymentStatus:         null,
			useVoucher:            false,
			isBusy:                false,
			hideIntro:             false,
			voucherChecked:        false,
			voucherValid:          false,
			continueOnOtherDevice: false,
			urlParams:             new URLSearchParams(window.location.search),
			getMatchScores,
			isLeagueMatch,
		};
	},
	computed: {
		...mapState({
			profileIsInitialized: state => state.user.isInitialized,
			isLoggedIn:           state => state.isLoggedIn,
		}),
		...mapGetters({
			isLoggedIn:           'user/isLoggedIn',
			profile:              'user/profile',
			subscriptions:        'user/subscriptions',
			fanAccountGeoblocked: 'user/fanAccountGeoblocked',
		}),
		submitText: function() {
			return this.offer ? (this.voucherValid ? $I18n.trans('payments.modal.step_2.continue_with_voucher') : $I18n.trans('payments.modal.step_2.continue', { amount: this.formattedPrice(this.offer) })) : null;
		},
		loginRedirectUrl: function() {
			return $Helpers.getUrlWithoutParameters() + '#checkout';
		},
		isLogin() {
			return this.activeStep === MODAL_STEPS[MODAL_ACTION.LOGIN];
		},
		isCheckout() {
			return this.activeStep === MODAL_STEPS[MODAL_ACTION.CHECKOUT];
		},
		isConfirm() {
			return this.activeStep === MODAL_STEPS[MODAL_ACTION.CONFIRM];
		},
		isPaymentPending() {
			return this.isConfirm && this.paymentStatus === PAYMENT_STATE.PENDING;
		},
		isPaymentConfirmed() {
			return this.paymentStatus === PAYMENT_STATE.SUCCESS;
		},
		isPaymentFailed() {
			return this.paymentStatus === PAYMENT_STATE.FAILED;
		},
		isPaymentCanceled() {
			return this.paymentStatus === PAYMENT_STATE.CANCELED;
		},
		closeable() {
			return this.mode === 'event' && !(this.isPaymentPending || (this.isConfirm && this.continueOnOtherDevice));
		},
		mode() {
			return this.event ? 'event' : 'subscription';
		},
		profilePage() {
			return laroute.route('arena.fan_account') + '/purchases';
		},
		availableOffers() {
			return this.paymentOffers?.filter((offer) => {
				return !(this.subscriptions || []).includes(offer.id);
			});
		},
	},
	watch: {
		profileIsInitialized() {
			if (this.isLoggedIn && this.isLogin) {
				this.activeStep = MODAL_STEPS[MODAL_ACTION.CHECKOUT];
			}
		},
		isLoggedIn() {
			if (this.isLoggedIn && this.isLogin) {
				this.activeStep = MODAL_STEPS[MODAL_ACTION.CHECKOUT];
			}
		},
		fanAccountGeoblocked() {
			if (this.fanAccountGeoblocked) {
				window.eventBus.$emit('modal:GeoblockingModal');
			}
		},
	},
	mounted() {
		this.getPaymentOffers();
	},
	methods: {
		formatCurrency,
		getDateTime,
		setVoucher(value) {
			this.useVoucher = value;
		},
		onOpen(options) {
			if (options && options.action) {
				this.activeStep = MODAL_STEPS[options.action];

				if (options.action === MODAL_ACTION.CONFIRM) {
					if (this.urlParams.get('status') === PAYMENT_STATE.SUCCESS) {
						this.paymentStatus = PAYMENT_STATE.PENDING;
						// Wait for final confirmation of payment transaction by webhook
						this.monitorOrderStatus();
					} else {
						this.paymentStatus = this.urlParams.get('status') || PAYMENT_STATE.FAILED;
					}
				}
			}
			if (options && options.qrCode) {
				this.qrCode = options.qrCode;
			}
		},
		reload() {
			window.location.href = $Helpers.getUrlWithoutParameters();
		},
		toProfile() {
			window.location.href = this.profilePage;
		},
		async monitorOrderStatus() {
			this.pending = true;
			const verificationInterval = 6000;
			if (this.paymentVerification) {
				clearInterval(this.tokenVerification);
			}

			this.paymentVerification = setInterval(async() => {
				const response = await authApi.get(
					laroute.route('arena.api.payment.order_code', {
						orderCode: this.urlParams.get('orderCode'),
					})
				);
				const responseContent = await response.json();

				this.handlePaymentResponse(responseContent);
			}, verificationInterval);
		},
		async getPaymentOffers() {
			const params = this.event ? { eventId: this.event.id } : { subscription: 1 };
			const response = await fetch(laroute.route('api.payment.offers', params));
			const data = await response.json();

			this.paymentOffers = Array.isArray(data) ? data : [];
			this.offer = this.paymentOffers.length > 0 ? this.paymentOffers[0] : null;
		},
		async checkVoucher() {
			this.voucherChecked = false;
			this.voucherValid = false;
			let voucherValid = false;

			try {
				this.isBusy = true;
				const response = await fetch(laroute.route('api.payment.voucher', { code: this.voucherCode, offerId: this.offer.id }));
				const responseContent = await response.json();
				voucherValid = responseContent && responseContent.id;
				this.voucher = responseContent;
			} catch (error) {
				console.error(error);
			} finally {
				this.isBusy = false;
			}

			this.voucherChecked = true;
			this.voucherValid = voucherValid;
		},
		loginSubmitted() {
			this.hideIntro = true;
		},
		setOffer(offer) {
			this.offer = offer;
		},
		handlePaymentResponse(response) {
			if (response.status && response.status === ORDER_STATE.CREATED) {
				if (response.redirectUrl) {
					// Redirect to payment gateway
					window.location.href = response.redirectUrl;
				}

				// Order is pending, wait for confirmation
				return;
			}

			if (response.status && response.status === ORDER_STATE.CONFIRMED) {
				this.activeStep = MODAL_STEPS[MODAL_ACTION.CONFIRM];
				this.paymentStatus = PAYMENT_STATE.SUCCESS;
				this.continueOnOtherDevice = response.continueOnOtherDevice;

				clearInterval(this.paymentVerification);
			} else {
				console.warn('Error: payment failed for orderCode=' + (this.orderCode || '?') + ', status: ' + response.status);
				this.paymentStatus = PAYMENT_STATE.FAILED;
				this.activeStep = MODAL_STEPS[MODAL_ACTION.CONFIRM];
				clearInterval(this.paymentVerification);
			}
		},
		async submitForm() {
			this.error = null;

			const paymentOrder = {
				offerId:     this.offer.id,
				eventId:     this.event?.id,
				redirectUrl: $Helpers.getUrlWithoutParameters(), // URL without query parameters and hash
				viewerId:    storage.viewerId,
				qrCode:      this.qrCode,
				channel:     $Helpers.isBlueTv() ? 'bluetv' : 'arena',
				domainKey:   window.domainKey,
				locale:      $Helpers.getLocale(),
			};

			try {
				this.isBusy = true;

				if (this.voucher) {
					paymentOrder.voucherId = this.voucher.id;
				};

				const response = await authApi.post(laroute.route('arena.api.payment.order.create'), paymentOrder);
				const responseContent = await response.json();

				if (responseContent && responseContent.id) {
					this.handlePaymentResponse(responseContent);
				} else {
					this.error = true;
					LoggingService.logFatal('Error processing payment', {
						context: {
							paymentOrder,
						},
						scope: 'payment',
					});
				}
			} catch (error) {
				LoggingService.logFatal(error, {
					context: {
						paymentOrder,
					},
					scope: 'payment',
				});
				this.error = true;
			} finally {
				this.isBusy = false;
			}
		},
		formattedPrice(offer) {
			return formatCurrency(offer.currencyCode, offer.amount);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.payment-modal__content {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
	width: auto;

	// Reserver space for sticky button
	@include mobile-media-query {
		padding-bottom: 50px;
	}
}

.payment-modal__head {
	text-align: center;
	margin-bottom: 48px;
}

.payment-modal__title {
	@include font(primary, light, normal, fs-150);
	margin-top: 45px;
	margin-bottom: 8px;

	&.-center {
		text-align: center;
	}

	@include mobile-media-query {
		margin-top: 0;
	}
}

.payment-modal__subtitle {
	color: var(--color-grey-300);
	display: inline;

	&.-center {
		text-align: center;
	}
}

.payment-modal__steps {
	margin: 50px 0;

	@include mobile-media-query {
		display: none;
	}
}

.payment-modal__offer {
	background-color: var(--color-grey-900);
	padding: 16px 24px;

	&.-center {
		text-align: center;
	}
}

.payment-modal__offer-selections {
	&.has-multiple {
		opacity: 0.4;
		cursor: pointer;
		transition: opacity $trans-time-fast;

		&:not(.is-selected):hover,
		&:not(.is-selected):focus {
			opacity: 0.7;
		}
	}

	&.is-selected {
		opacity: 1;
	}
}

.payment-modal__offer-price {
	@include font(primary, medium, normal, fs-120);
	background-color: var(--color-grey-800);
	padding: 16px 24px;
	text-align: right;
	color: var(--color-grey-050);
}

.payment-modal__offer-price-desc {
	@include font(primary, medium, normal, fs-100);
	color: var(--color-grey-050);
	color: var(--color-grey-300);
}

.payment-modal__offer-title {
	@include font(primary, medium, normal, fs-120);
	color: var(--color-grey-050);
	margin-bottom: 2px;
}

.payment-modal__offer-match {
	margin-top: $sp4;
}

.payment-modal__offer-desc {
	@include font(primary, medium, normal, fs-120);
	color: var(--color-grey-300);
}

.payment-modal__offer-price {
	margin-bottom: 48px;
}

.voucher__apply {
	display: flex;
	margin-left: auto;
}

.voucher__check {
	margin-top: 16px;
}

.voucher__input {
	margin-top: 12px;

	&::v-deep .v2-input-label.-t-dark {
		color: var(--color-grey-300);
	}
}

.voucher__title {
	@include font(primary, medium, normal, fs-100);
	margin-bottom: 15px;
	display: flex;
}

.payment-modal__continue {
	margin-top: 48px;
	display: flex;
	width: 100%;
}

.payment-modal__error {
	margin-top: 15px;
}

.payment-modal__icon {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	margin-bottom: 30px;
}

.paywall-modal__loading {
	margin-top: 50px;
	margin-bottom: 50px;
}
</style>
