<template>
	<ContextMenu
		class="v2-tooltip"
		:offset="'1px'"
		:align="position"
		:open-on-hover="!clickToOpen"
		:class="{'has-toggle-slot': hasToggleSlot}"
	>
		<template #toggle>
			<span v-if="hasToggleSlot">
				<slot name="toggle" />
			</span>
			<V2Icon
				v-else
				icon-name="c-info"
				:size="iconSize"
			/>
		</template>
		<template #context-menu>
			<div
				class="v2-tooltip__text"
				:style="{ minWidth: `${minWidth}px`}"
			>
				<slot
					v-if="hasContentSlot"
					name="content"
				/>
				<span v-else>{{ text }}</span>
			</div>
		</template>
	</ContextMenu>
</template>

<script>
import V2Icon from '@shared/components/Icon';
import ContextMenu from '@shared/components/ContextMenu';

const positions = ['top-right', 'top-center', 'bottom-center', 'bottom-right'];
const iconSizes = [
	'xxs',
	'xs',
	'sm',
	'md',
	'lg',
	'xl',
	'xxl',
	'xxxl',
];

export default {
	name:       'V2Tooltip',
	components: { V2Icon, ContextMenu },
	props:      {
		text: {
			type:    String,
			default: '',
		},
		position: {
			type:      String,
			default:   'top-center',
			validator: position => positions.includes(position),
		},
		iconSize: {
			type:      String,
			default:   'sm',
			validator: iconSize => iconSizes.includes(iconSize),
		},
		clickToOpen: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		hasToggleSlot() {
			return !!this.$slots.toggle;
		},
		hasContentSlot() {
			return !!this.$slots.content;
		},
		minWidth() {
			switch (true) {
			case (this.text.length > 100):
				return 320;
			case (this.text.length > 50):
				return 240;
			case (this.text.length > 20):
				return 160;
			default:
				return 80;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-tooltip {
	&:not(.has-toggle-slot) {
		vertical-align: middle;
		margin-top: -0.15em;
		margin-left: 0.25em;
	}
}

.v2-tooltip__text {
	@include font-size(fs-80);
	z-index: 2;
	padding: $sp3;
	background-color: var(--color-soft-white);
	color: var(--color-shadow-grey);
	word-break: initial;
}

::v-deep {
	.v2-context-menu__body {
		border-radius: $border-radius--sm;
		overflow: hidden;
	}

	.v2-context-menu__toggle {
		&.-arrow { // stylelint-disable-line max-nesting-depth
			&::before { // stylelint-disable-line max-nesting-depth
				border-color: transparent transparent var(--color-soft-white);

				.v2-context-menu.-top & { // stylelint-disable-line max-nesting-depth
					border-color: var(--color-soft-white) transparent transparent;
				}
			}

			&::after { // stylelint-disable-line max-nesting-depth
				border-color: transparent transparent var(--color-soft-white);

				.v2-context-menu.-top & { // stylelint-disable-line max-nesting-depth
					border-color: var(--color-soft-white) transparent transparent;
				}
			}
		}
	}
}

</style>
