<template>
	<Layout>
		<Jumbotron
			:bg-image-url="bgImageUrl"
			:title="title"
			:subtitle="subtitle"
		/>
		<div class="l-container">
			<div
				v-for="lane in mixedLanes"
				:key="lane.id"
				class="l-content-section-w"
			>
				<SectionChildNav
					v-if="teams.length && lane.lane_type === ArenaLaneType.NAVIGATION && lane.visible"
					type="team"
					:items="teams"
				/>
				<EventLane
					v-if="lane.visible && lane.storeId"
					:store-id="lane.storeId"
					:album-id="lane.albumId"
					:album-icon="lane.imgUrl"
					:title="lane.title"
					:subtitle="lane.subtitle"
					:more-link="lane.moreLink"
					:lane-type="lane.lane_type"
					:highlighted="lane.highlighted"
					:ad="lane.ad"
				/>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Jumbotron from '@js/components/Jumbotron';
import SectionChildNav from '@js/components/SectionChildNav';
import EventLane from '@js/components/EventLane';
import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { laneMixer } from '@js/utils/laneMixer';

export default {
	name:       'ViewBlueTv',
	components: { Layout, Jumbotron, SectionChildNav, EventLane },
	props:      {
		bgImageUrl: {
			type:    String,
			default: null,
		},
		title: {
			type:    String,
			default: null,
		},
		subtitle: {
			type:    String,
			default: null,
		},
		teams: {
			type:    Array,
			default: () => [],
		},
		albums: {
			type:    Array,
			default: () => [],
		},
		lanes: {
			type:    Array,
			default: () => [],
		},
		ads: {
			type:    Array,
			default: () => [],
		},
		highlightedAd: {
			type:    Object,
			default: null,
		},
	},
	data() {
		return {
			ArenaLaneType,
		};
	},
	computed: {
		mixedLanes() {
			return laneMixer(
				this.albums,
				this.lanes,
				this.$store,
				{
					highlighted: this.highlightedAd,
					normal:      this.ads,
				},
			);
		},
		noEvents() {
			return this.mixedLanes.filter(lane => !!lane.storeId).every(lane => {
				return this.$store.state.events[lane.storeId].items.length === 0;
			});
		},
	},
};
</script>

<style>

</style>
