<template>
	<div class="match-center">
		<MobileFilter
			v-if="seasonFilter.value"
			ref="mobileFilter"
			:filters="[seasonFilter]"
			@changeSelect="handleMobileSeasonChange"
			@reset="handleMobileSeasonChange(null, null)"
		/>
		<div class="match-center__header">
			<SectionTitle
				:title="$I18n.trans('arena.match_center.title')"
				type="main"
			/>
			<div
				v-if="seasonFilter.value"
				class="match-center__mobile-filter-toggle"
				@click="openMobileFilter"
			>
				{{ selectedSeasonLabel }}
				<Icon
					icon-name="right-arrow"
					size="sm"
				/>
			</div>
		</div>
		<div class="match-center__nav">
			<div class="match-center__nav-h-w">
				<InPageNavigation
					:nav-items="navItems"
					:use-anchor-tag="false"
					:dark-theme="!$arenaConfig.isLightTheme"
					horizontal
					@nav-change="handleInPageNavChange"
				/>
			</div>
			<div
				v-if="seasonFilter.value"
				class="match-center__season-filter"
			>
				<Select
					:options="seasonFilter.options"
					:value="seasonFilter.value"
					:searchable="false"
					:clearable="false"
					:dark-theme="!$arenaConfig.isLightTheme"
					@change="handleSeasonChange"
				/>
			</div>
		</div>
		<MatchDays
			v-show="isMatchDayView"
			ref="matchDays"
			:teams="teams"
			:events="events"
			:league-id="leagueId"
			:is-loading="isLoadingEvents"
		/>
		<div v-show="isTableView">
			<LeagueTable
				:table="leagueTable"
				:is-loading="isLoadingTable"
			/>
		</div>
		<div v-show="isTopScorerView">
			<TopScorers
				:top-scorer-tables="topScorerTables"
				:is-loading="isLoadingTopScorerTables"
			/>
		</div>
	</div>
</template>

<script>
import SectionTitle from '@js/components/SectionTitle';
import InPageNavigation from '@shared/components/InPageNavigation';
import MobileFilter from '@js/components/MobileFilter';
import MatchDays from './MatchDays';
import Select from '@shared/components/Select';
import LeagueTable from '@js/components/LeagueTable';
import TopScorers from '@js/components/TopScorers';
import Icon from '@shared/components/Icon';
import laroute from '@laroute';
import { EventFilter } from '@shared/enums/EventFilter';

const defaultNavItems = [
	{
		label:  $I18n.trans('arena.match_center.matchplan_and_results'),
		url:    'matchday',
		active: true,
	},
];

export default {
	name:       'MatchCenter',
	components: { TopScorers, MatchDays, Icon, InPageNavigation, MobileFilter, Select, SectionTitle, LeagueTable },
	props:      {
		teams: {
			type:     Array,
			required: true,
		},
		leagueId: {
			type:     Number,
			required: true,
		},
		seasons: {
			type:    Array,
			default: () => [],
		},
	},
	data() {
		return {
			navItems:                 [...defaultNavItems],
			events:                   null,
			leagueTable:              null,
			topScorerTables:          null,
			isLoadingTable:           false,
			isLoadingTopScorerTables: false,
			isLoadingEvents:          false,
			seasonFilter:             {
				type:         'select',
				id:           'season',
				label:        this.$I18n.trans('arena.match_center.season'),
				defaultValue: null,
				emitEvent:    'changeSeason',
				value:        this.seasons[this.seasons.length - 1]?.id || null,
				options:      this.seasons.map(season => {
					return {
						label: season.translatedName,
						value: season?.id,
					};
				}),
			},
		};
	},
	computed: {
		selectedSeasonLabel() {
			return this.seasonFilter.options.find(o => o.value === this.seasonFilter.value)?.label || null;
		},
		isMatchDayView() {
			return this.navItems.find(i => i.url === 'matchday').active;
		},
		isTopScorerView() {
			return this.navItems.find(i => i.url === 'top-scorer')?.active;
		},
		isTableView() {
			return this.navItems.find(i => i?.url === 'table')?.active;
		},
	},
	watch: {
		leagueTable(value) {
			if (value !== null) {
				this.navItems.push({
					label:  this.$I18n.trans('arena.match_center.table'),
					url:    'table',
					active: false,
				});
			}
		},
		topScorerTables(value) {
			if (value !== null) {
				this.navItems.push({
					label:  this.$I18n.trans('arena.match_center.top_scorer'),
					url:    'top-scorer',
					active: false,
				});
			}
		},
	},
	beforeMount() {
		this.getURLParams();
	},
	mounted() {
		this.loadData();
	},
	methods: {
		getURLParams() {
			const urlParams = new URLSearchParams(window.location.search);
			const seasonId = parseInt(urlParams.get('season'));
			if (seasonId && this.seasonFilter.options.find(s => s.value === seasonId)) {
				this.seasonFilter.value = seasonId;
			}
		},
		applyURLParams() {
			const url = new URL(window.location);
			url.searchParams.set('season', this.seasonFilter.value);
			window.history.pushState(null, '', url.toString());
		},
		async loadData() {
			await this.loadEvents();
			this.loadLeagueTable();
			this.loadLeagueTopScorerTables();
		},
		handleInPageNavChange(url) {
			this.navItems.forEach(i => {
				i.active = false;
				if (url === i.url) {
					i.active = true;
				}
			});
			// reset view on matchdays
			this.$refs.matchDays.setViewToMatchdays();
		},
		handleSeasonChange(value) {
			this.navItems = this.navItems.filter(i => i.url === 'matchday');
			this.navItems[0].active = true;
			this.seasonFilter.value = value;
			this.$refs.matchDays.resetFilter();
			this.events = null;
			this.leagueTable = null;
			this.topScorerTables = null;
			this.loadData();
			this.applyURLParams();
		},
		handleMobileSeasonChange(_select, value) {
			this.handleSeasonChange(value || this.seasons[this.seasons.length - 1]?.id);
		},
		openMobileFilter() {
			this.$refs.mobileFilter.open();
		},
		async loadLeagueTable() {
			try {
				this.isLoadingTable = true;
				const response = await fetch(laroute.route('api.league.table_season', { league: this.leagueId, season: this.seasonFilter.value }));
				const data = await response.json();
				this.leagueTable = data.entries ? data : null;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoadingTable = false;
			}
		},
		async loadLeagueTopScorerTables() {
			try {
				this.isLoadingTopScorerTables = true;
				const response = await fetch(laroute.route('api.league.top_scorers_season', { league: this.leagueId, season: this.seasonFilter.value }));
				const data = await response.json();
				this.topScorerTables = data?.length > 0 && data[0].entries?.data?.length > 0 ? data : null;
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoadingTopScorerTables = false;
			}
		},
		async loadEvents() {
			try {
				this.isLoadingEvents = true;

				// search Params
				const searchParams = new URLSearchParams();

				const filters = {
					[EventFilter.LEAGUE]:          this.leagueId,
					[EventFilter.EVENT]:           null,
					[EventFilter.ARENA_AVAILABLE]: null,
					[EventFilter.SEASON]:          this.seasonFilter.value,
					[EventFilter.SEASON_STAGE]:    true, // only include events with seasonStage
				};

				searchParams.append('useContextFilter', false);
				searchParams.append('filters', JSON.stringify(filters));
				searchParams.append('sortOrder', JSON.stringify({
					views: 'asc',
				}));

				searchParams.append('relations', 'participants,participants.participatable,scores,scores.period,sport,seasonStage');

				const headers = new Headers({ 'X-Requested-With': 'XMLHttpRequest' });
				const response = await fetch(`${laroute.route('api.events.cached')}?${searchParams}`, { headers });
				this.events = await response.json();
			} catch (error) {
				console.error(error);
			} finally {
				this.isLoadingEvents = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.match-center {
	padding-top: $sp6;
}

.match-center__header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	margin-bottom: #{-$sp4};
}

.match-center__season-filter {
	display: none;

	@include size(md-up) {
		position: absolute;
		top: 0;
		right: 0;
		display: block;
		width: 250px;

		> div { // stylelint-disable-line selector-max-type
			margin-bottom: 0;
		}
	}
}

.match-center__mobile-filter-toggle {
	display: flex;
	align-items: center;
	gap: $sp3;

	@include size(md-up) {
		display: none;
	}
}

.match-center__nav {
	position: relative;
}

.match-center__nav-h-w {
	padding-top: $sp4;
}

</style>
