<template>
	<Layout>
		<div class="l-container">
			<div class="l-row l-align-center">
				<div class="l-col l-col--12 l-col--8@lg">
					<section class="s-html l-content-section -reduced-spacing u-mt-7">
						<h1 class="title">
							Cookies
						</h1>
						<div>
							<p>
								Diese Webseite verwendet Cookies. Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
							</p>
							<p>
								Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten.<br>
								<br>
								Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis.<br>
								<br>
								Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.<br>
								<br>
								Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen.<br>
								<br>
								<a href="javascript:void(window.eventBus.$emit('openCookieConsent'))">Einwilligung ändern</a><br>
							</p>
						</div>
					</section>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';

export default {
	name:       'ViewCookies',
	components: { Layout },
};
</script>

<style>

</style>
