export const EventFilter = {
	EVENT:                         'event',
	ID:                            'id',
	RELATED_TO_EVENT:              'relatedToEvent',
	SEARCH:                        'search',
	NAME_SEARCH:                   'nameSearch',
	EXTERNAL_REFERENCE:            'externalReference',
	INCLUDE_DELETED:               'include_deleted',
	UPCOMING:                      'upcoming',
	LIVE:                          'live',
	ENDED:                         'ended',
	ENDED_AND_LIVE:                'ended_and_live',
	NOT_ENDED:                     'not_ended',
	LIVE_AND_UPCOMING:             'live_and_upcoming',
	CONTENT_TYPE:                  'contentType',
	VISIBILITY:                    'visibility',
	VISIBILITIES:                  'visibilities',
	NOT_PRIVATE:                   'not_private',
	SPORT:                         'sport',
	NOT_EXTERNAL_EVENT:            'not_external_event',
	CATEGORY_PARENT:               'categoryParent',
	CATEGORY_CHILD:                'categoryChild',
	SEASON:                        'season',
	SEASON_STAGE:                  'seasonStage',
	BETWEEN:                       'between',
	NOT_IN_ALBUM:                  'notInAlbum',
	INCLUDE:                       'include',
	EXCLUDE:                       'exclude',
	LABEL:                         'label',
	LABEL_MATCH_ALL:               'label_match_all',
	LABEL_FILTER_INCLUSIVE:        'label_filter_inclusive',
	LABEL_FILTER_EXCLUSIVE:        'label_filter_exclusive',
	VIDEO:                         'video',
	UPLOADED:                      'uploaded',
	FILE_STATUS:                   'fileStatus',
	VOD_STATUS:                    'vodStatus',
	MATCHDAY:                      'matchday',
	RECOMMENDED:                   'recommended',
	RECENT:                        'recent',
	ARENA_AVAILABLE:               'arena_available',
	ARENA_ALBUM:                   'arena_album',
	ALBUM:                         'album',
	ALBUMS:                        'albums',
	CLIENT:                        'client',
	FEDERATION:                    'federation',
	LEAGUE:                        'league',
	VENUE:                         'venue',
	CLUB:                          'club',
	TEAM:                          'team',
	PLAYER:                        'player',
	ASSET_VIDEO_AD:                'asset_video_ad',
	SOURCE_EVENT:                  'sourceEvent',
	HAS_EXPORTED_CLIPS_OR_MOMENTS: 'hasExportedClipsOrMoments',
	TQ_VOD_FILE_ID:                'tqVodFileId',
	TQ_LIVE_PROJECT_ID:            'tqLiveProjectId',
	FAILED:                        'failed',
	ANALYTICS_DELETED:             'analytics_deleted',
};
