<template>
	<div class="l-content-section event-section">
		<div
			class="event-section__title-w"
		>
			<SectionTitleMatchCenter
				v-if="title"
				:title="title"
				:is-loading="isLoading"
				:show-results-counter="showResultsCounter"
				:result-count="pagination.total"
			/>
			<slot />
		</div>
		<div
			class="event-section__grid-w"
			:class="{'is-empty': !isLoading && events.length === 0}"
		>
			<EventsGrid
				class="event-section__grid"
				:class="{'is-loading': isLoading}"
				:events="events"
				:is-loading="isLoading"
				:empty-message="$I18n.trans('arena.no_results')"
				:is-album-embed="isAlbumEmbed"
			/>
			<LoadingSpinner
				v-if="isLoading"
				class="event-section__loading-spinner"
			/>
		</div>
		<Pagination
			v-if="pagination && pagination.total && (pagination.total > pagination.perPage)"
			:disabled="isLoading"
			:total="pagination.total"
			:per-page="pagination.perPage"
			:current-page="pagination.currentPage"
			:last-page="pagination.lastPage"
			@change="handlePaginationChange"
		/>
	</div>
</template>

<script>
import EventsGrid from './EventsGrid';
import Pagination from '@shared/components/Pagination';
import SectionTitleMatchCenter from './SectionTitleMatchCenter';
import LoadingSpinner from '@shared/components/LoadingSpinner';

export default {
	name:       'EventsSection',
	components: { LoadingSpinner, EventsGrid, Pagination, SectionTitleMatchCenter },
	props:      {
		title: {
			type:    String,
			default: null,
		},
		pagination: {
			type:    Object,
			default: null,
		},
		handlePaginationChange: {
			type:     Function,
			required: true,
		},
		events: {
			type:    Array,
			default: () => [],
		},
		isLoading: {
			type:    Boolean,
			default: true,
		},
		showResultsCounter: {
			type:    Boolean,
			default: true,
		},
		isAlbumEmbed: {
			type:    Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

// .event-section {}

.event-section__title-w {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.event-section__grid-w {
	position: relative;
	min-height: 215px;

	&.is-empty {
		min-height: 100px;
	}
}

.event-section__grid {
	&.is-loading {
		opacity: 0.7;
	}
}

.event-section__loading-spinner {
	position: absolute;
	display: flex;
	align-items: center;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
}
</style>
