<template>
	<div class="private-event l-container">
		<div class="private-event__w l-row l-align-center l-align-middle">
			<div class="l-col l-col--12 l-col--6@md">
				<!-- eslint-disable vue/no-v-html -->
				<h5
					class="private-event__title"
					:class="{'has-pw': pwProtected}"
					v-html="$I18n.trans(pwProtected ? 'arena.private_event_password' : 'arena.private_event_message', { link: laroute.route('manager.event.edit.publish', { event: eventId}) })"
				/>
				<form
					v-if="pwProtected"
					@submit.prevent="handleSubmit"
				>
					<InputText
						v-model="pw"
						:label="$I18n.trans('commons.password')"
						type="password"
						class="private-event__pw-input"
						autocomplete="off"
						:dark-theme="!$arenaConfig.isLightTheme"
						required
						:custom-error-msg="$I18n.trans('validation.custom.password_invalid')"
						:show-error="invalidPassword"
					/>
					<Button
						type="submit"
						:text="$I18n.trans('commons.send')"
						theme="dark"
					/>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import InputText from '@shared/components/Form/InputText';
import Button from '@shared/components/Button';
import laroute from '@laroute';

export default {
	name:       'PrivateEvent',
	components: { InputText, Button },
	props:      {
		eventId: {
			type:     Number,
			required: true,
		},
		pwProtected: {
			type:     Boolean,
			required: false,
		},
	},
	data() {
		return {
			pw:              '',
			laroute,
			invalidPassword: false,
		};
	},
	mounted() {
		const urlParams = new URLSearchParams(window.location.search);
		const invalidPw = urlParams.get('password');

		if (invalidPw) {
			this.invalidPassword = true;
			urlParams.delete('password');
			window.history.replaceState(null, '', '?' + urlParams + location.hash);
		}
	},
	methods: {
		handleSubmit() {
			const pw = btoa(this.pw);
			window.location.href = `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}password=${pw}`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.private-event {
	height: 100%;
}

.private-event__w {
	height: 100%;
}

.private-event__title {
	@include font(primary, medium, normal, fs-150);
	line-height: 140%;
	margin-bottom: $sp6;
	text-align: center;

	&.has-pw {
		text-align: left;
	}
}

::v-deep .private-event__link {
	text-decoration: underline;
}

.private-event__pw-input {
	margin-bottom: $sp6;
}
</style>
