<template>
	<div>
		<InputText
			ref="email"
			name="email"
			type="text"
			pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
			required
			:label="$I18n.trans('arena.login.login.email')"
			:placeholder="$I18n.trans('arena.login.login.email')"
			:dark-theme="!$arenaConfig.isLightTheme"
			:value="email"
			@input="(data) => $parent.email = data"
		/>
		<Button
			class="arena-login__continue"
			:text="$Helpers.ucfirst($I18n.trans('arena.login.login.request_reset'))"
			:busy="inProgress"
			:theme="$arenaConfig.isLightTheme ? null : 'dark'"
			@click="requestReset"
		/>
		<Button
			class="arena-login__back"
			:text="$Helpers.ucfirst($I18n.trans('arena.login.login.back'))"
			:theme="$arenaConfig.isLightTheme ? null : 'dark'"
			modifier="borderless"
			@click="$parent.resetForm"
		/>
	</div>
</template>

<script>

import { authApi } from '../../../api/client';
import Button from '@shared/components/Button';
import InputText from '@shared/components/Form/InputText';
import { LOGIN_MODES } from '../LoginComponent';
import laroute from '@laroute';

export default {
	name:       'RequestPasswordReset',
	components: { Button, InputText },
	props:      {
		email: {
			type:    String,
			default: '',
		},
	},
	data() {
		return {
			inProgress: false,
			laroute,
		};
	},
	mounted() {
	},
	methods: {
		async requestReset() {
			this.inProgress = true;
			await authApi.post(laroute.route('arena.api.arena_login.request_reset'), {
				email: this.email,
			});
			this.$emit('submitted', {
				mode:           LOGIN_MODES.PASSWORD_RESET_SENT,
				resetRequested: true,
			});
			this.inProgress = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

</style>
