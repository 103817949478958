<template>
	<div class="matchdays">
		<LoadingSpinner
			v-if="isLoading"
			class="matchdays__loading-spinner"
		/>
		<MobileFilter
			ref="mobileFilter"
			:filters="mobileFilters"
			@changeSelect="handleMobileFilterChange"
			@reset="handleMobileFilterChange('reset', 'all')"
		/>
		<template v-if="!isLoading">
			<div class="matchdays__filter">
				<div class="matchdays__view-select-w">
					<div
						v-for="option in views.options"
						:key="option.value"
						class="matchdays__view-select"
						:class="{'is-active': option.value === views.currentSelection}"
						@click="handleViewChange(option)"
					>
						{{ option.label }}
					</div>
				</div>
				<div class="matchdays__filter-select">
					<Select
						v-if="isMatchDayView"
						:options="matchDayFilterOptions"
						:value="matchDayFilter.value"
						:searchable="false"
						:clearable="false"
						:dark-theme="!$arenaConfig.isLightTheme"
						@change="handleMatchDayChange"
					/>
					<Select
						:options="teamFilter.options"
						:value="teamFilter.value"
						:searchable="false"
						:clearable="false"
						:dark-theme="!$arenaConfig.isLightTheme"
						@change="handleTeamChange"
					/>
				</div>
				<div
					class="matchdays__mobile-filter-toggle"
					:class="{'is-filtered': mobileFilterIsFiltered }"
					@click="openMobileFilter"
				>
					<Icon
						icon-name="filter"
						size="lg"
					/>
				</div>
			</div>
			<div class="matchdays__list-w">
				<div
					v-if="!isMatchDayView"
					class="matchdays__month-selector-w"
				>
					<div class="matchdays__month-selector-inner-w">
						<div
							v-for="(listEntry, i) in eventsListFiltered"
							:key="listEntry.groupKey"
							ref="monthSelect"
							:data-month="listEntry.groupKey.replace('month_', '')"
							class="matchdays__month-select"
							:class="{'is-active': i === 0}"
							@click="handleMonthSelect(listEntry.groupKey)"
						>
							<span class="u-hide@md">{{ listEntry.titleMain.short }}</span>
							<span class="u-show@md">{{ listEntry.titleMain.long }}</span>
						</div>
					</div>
				</div>
				<div class="matchdays__list">
					<div
						v-for="listEntry in eventsListFiltered"
						:key="listEntry.groupKey"
						class="matchdays__list-inner-w"
					>
						<div
							v-if="!isMatchDayView"
							:id="listEntry.groupKey"
							ref="scrollAnchor"
							:data-month="listEntry.groupKey.replace('month_', '')"
							class="matchdays__list-scroll-anchor"
						/>
						<h3 class="matchdays__list-title">
							{{ listEntry.titleMain.long || listEntry.titleMain }}
						</h3>
						<template v-if="listEntry.isDuel && isMatchDayView">
							<div
								v-for="(duel, i) in listEntry.duelMatchesGrouped"
								:id="duel.scrollAnchor"
								:key="i"
								class="matchdays__list-duel-w"
							>
								<div
									class="matchdays__list-duel-standing"
									:class="{'-sets-view': isSetsView}"
								>
									<div class="matchdays__list-duel-team -home">
										<span class="matchdays__list-duel-team-name">{{ duel.homeTeamName }}</span> <span class="u-hide@sm">{{ noSpoilerMode ? '-' : duel.overallScores.homeTeamScore }}</span>
									</div>
									<span class="matchdays__list-duel-standing-score u-show@sm">
										{{ noSpoilerMode ? '-' : duel.overallScores.homeTeamScore }} : {{ noSpoilerMode ? '-' : duel.overallScores.guestTeamScore }}
									</span>
									<div class="matchdays__list-duel-team -guest">
										<span class="matchdays__list-duel-team-name">{{ duel.guestTeamName }}</span> <span class="u-hide@sm">{{ noSpoilerMode ? '-' : duel.overallScores.guestTeamScore }}</span>
									</div>
								</div>
								<div
									v-for="match in duel.events"
									:key="match.id"
									class="matchdays__list-date-w -duel"
								>
									<h4 class="matchdays__list-date">
										{{ getDateTime(match.startTimestamp, { withTime: false, withWeekdayPrefix: true, weekdayFormatLong: true, monthFormatLong: true }) }}<br>
									</h4>
									<MatchListItem
										v-bind="MatchListItemProps(match, { hideSeasonStageRoundInfo: isMatchDayView })"
									/>
								</div>
							</div>
						</template>
						<template v-else>
							<div
								v-for="matchDate in listEntry.matchDates"
								:key="matchDate.date"
								class="matchdays__list-date-w"
							>
								<h4 class="matchdays__list-date">
									{{ matchDate.date }}<br>
								</h4>
								<MatchListItem
									v-for="match in matchDate.matches"
									:key="match.id"
									v-bind="MatchListItemProps(match, { hideSeasonStageRoundInfo: isMatchDayView })"
								/>
							</div>
						</template>
					</div>
					<EmptyState
						v-if="eventsListFiltered.length === 0"
						:text="$I18n.trans('commons.no_types_found', { type: $I18n.trans_choice('commons.games', 2) })"
					/>
				</div>
			</div>
			<div
				v-if="isMatchDayView && matchDayFilter.value !== 'all'"
				class="matchdays__navigate-matchday"
			>
				<Button
					:text="$I18n.trans('pagination.previous')"
					icon="left-arrow"
					modifier="secondary"
					theme="dark"
					:disabled="matchDayFilter.value === matchDayFilterOptions[1].value"
					@click="navigateMatchDay(-1)"
				/>
				<Button
					:text="$I18n.trans('pagination.next')"
					icon="right-arrow"
					modifier="secondary"
					theme="dark"
					:reverse-order="true"
					:disabled="matchDayFilter.value === matchDayFilterOptions[matchDayFilterOptions.length - 1].value"
					@click="navigateMatchDay(+1)"
				/>
			</div>
		</template>
	</div>
</template>

<script>
import MatchListItem from '@js/components/MatchCenter/MatchListItem';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import Button from '@shared/components/Button';
import MobileFilter from '@js/components/MobileFilter';
import EmptyState from '@js/components/EmptyState';
import Icon from '@shared/components/Icon';
import { MatchListItemProps } from './MatchListItemProps';
import { getDateTime } from '@shared/utils/timeUtils';
import Select from '@shared/components/Select';
import { SeasonStageType } from '@shared/enums/SeasonStageType';
import { ParticipantType } from '@shared/enums/ParticipantType';
import { SportName } from '@shared/enums/SportName';
import { mapState } from 'vuex';

const chronologicalViewOption = {
	label: $I18n.trans('arena.match_center.chronological'),
	value: 'chronological',
};
const defaultViews = {
	currentSelection: 'chronological',
	options:          [chronologicalViewOption],
};

export default {
	name:       'MatchDays',
	components: { LoadingSpinner, MatchListItem, MobileFilter, Icon, Select, Button, EmptyState },
	props:      {
		teams: {
			type:     Array,
			required: true,
		},
		isLoading: {
			type:    Boolean,
			default: false,
		},
		events: {
			type:    Array,
			default: null,
		},
	},
	data() {
		return {
			MatchListItemProps,
			internalEvents: this.events,
			teamFilter:     {
				type:         'select',
				id:           'team',
				label:        this.$I18n.trans_choice('commons.team', 1),
				defaultValue: 'all',
				value:        'all',
				emitEvent:    'changeTeam',
				options:      [
					{
						label: this.$I18n.trans('commons.all_types', { type: this.$I18n.trans_choice('commons.team', 2) }),
						value: 'all',
					},
					...this.teams.map(team => ({ label: team.club.name, value: team.id })).sort((a, b) => a.label < b.label ? -1 : 1),
				],
			},
			matchDayFilter: {
				type:         'select',
				id:           'matchDay',
				label:        this.$I18n.trans_choice('commons.matchday', 1),
				defaultValue: 'all',
				value:        'all',
				emitEvent:    'changeMatchDay',
			},
			views:                defaultViews,
			scrollObserverActive: false,
			getDateTime,
		};
	},
	computed: {
		...mapState({
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
		eventsList() {
			if (!this.internalEvents || this.internalEvents.length <= 0) {
				return [];
			}

			let matchWeekCounter = 1;

			const groups = [...this.internalEvents]
				.sort((a, b) => a.startTimestamp - b.startTimestamp)
				.reduce((acc, current) => {
					const matchDateString = getDateTime(current.startTimestamp, { withTime: false, withWeekdayPrefix: true, weekdayFormatLong: true, monthFormatLong: true });

					const groupType = (() => {
						// chronological view
						if (!this.isMatchDayView) {
							return 'monthly';
						}
						// matchday views
						// regular season matchday without custom matchday number
						if (!current.seasonStage || (current.seasonStage.type === SeasonStageType.REGULAR_SEASON && !current.matchdayNumber)) {
							return 'weekly';
						}
						// regular season matchday with custom matchday number
						if (current.seasonStage.type === SeasonStageType.REGULAR_SEASON && current.matchdayNumber) {
							return SeasonStageType.REGULAR_SEASON;
						}
						// non regular season matchday
						return SeasonStageType.POST_SEASON;
					})();

					const isDuel = current.seasonStage?.duel || false;

					const groupKey = (() => {
						switch (groupType) {
						case 'monthly':
							return `month_${moment.unix(current.startTimestamp).month()}`;
						case 'weekly':
							return `week_${moment.unix(current.startTimestamp).week()}`;
						case SeasonStageType.REGULAR_SEASON:
							return `regular_season_${current.matchdayNumber}`;
						default:
							return `post_season_${current.seasonStage.round}`;
						}
					})();

					const orderIndex = groupType === SeasonStageType.REGULAR_SEASON ? current.matchdayNumber : null;

					const groupTitle = (() => {
						switch (groupType) {
						case 'monthly':
							return {
								long:  this.$Helpers.ucfirst(moment.unix(current.startTimestamp).format('MMMM')),
								short: moment.unix(current.startTimestamp).format('MMM').replace('.', '').toUpperCase(),
							};
						case 'weekly':
							return `${this.$I18n.trans_choice('commons.matchday', 1)} ${matchWeekCounter}`;
						case SeasonStageType.REGULAR_SEASON:
							return `${this.$I18n.trans_choice('commons.matchday', 1)}  ${current.matchdayNumber}`;
						default:
							return this.$Helpers.getTranslatedContent(current.seasonStage.name);
						}
					})();

					const groupKeyExistsIndex = acc.findIndex(a => (a.groupKey === groupKey));

					if (groupKeyExistsIndex === -1) {
						acc.push({
							groupKey,
							groupType,
							titleMain:  groupTitle,
							orderIndex,
							matchDates: [{
								date:    matchDateString,
								matches: [current],
							}],
							// all matches for duel grouping
							isDuel,
							duelMatches: isDuel ? [current] : [],
						});
						matchWeekCounter += 1;
					} else {
						const dateOnMatchDayGroupExistsIndex = acc[groupKeyExistsIndex].matchDates.findIndex(matchDate => matchDate.date === matchDateString);
						if (dateOnMatchDayGroupExistsIndex === -1) {
							acc[groupKeyExistsIndex].matchDates.push({
								date:    matchDateString,
								matches: [current],
							});
						} else {
							acc[groupKeyExistsIndex].matchDates[dateOnMatchDayGroupExistsIndex].matches.push(current);
						}
						if (isDuel) {
							acc[groupKeyExistsIndex].duelMatches.push(current);
						}
					}

					return acc;
				}, []);

			return groups.map(group => {
				return {
					...group,
					duelMatchesGrouped: !group.isDuel ? [] : this.groupDuels(group.duelMatches),
				};
			});
		},
		eventsListFiltered() {
			let eventsListFiltered = [...this.eventsList];

			if (this.teamFilter.value !== 'all') {
				eventsListFiltered = eventsListFiltered.map(group => {
					return {
						...group,
						matchDates: group.matchDates.map(date => {
							return {
								...date,
								matches: date.matches.filter(match => match.participants.some(p => p.participatable.id === this.teamFilter.value)),
							};
						}).filter(date => date.matches.length > 0),
						duelMatchesGrouped: group.duelMatchesGrouped.filter(subGroup => subGroup.teamIds.includes(this.teamFilter.value)),
					};
				}).filter(group => group.matchDates.length > 0);
			}
			if (this.isMatchDayView) {
				eventsListFiltered = eventsListFiltered
					.filter(group => (this.matchDayFilter.value === 'all' || group.groupKey === this.matchDayFilter.value) && (group.groupType === this.views.currentSelection || (this.views.currentSelection === SeasonStageType.REGULAR_SEASON && group.groupType === 'weekly')))
					.sort((a, b) => a.orderIndex !== null ? a.orderIndex - b.orderIndex : a);
			}
			return eventsListFiltered;
		},
		isMatchDayView() {
			return this.views.currentSelection !== 'chronological';
		},
		mobileFilters() {
			const filters = [this.teamFilter];

			if (this.isMatchDayView) {
				filters.push({
					...this.matchDayFilter,
					options: this.matchDayFilterOptions,
				});
			}

			return filters;
		},
		mobileFilterIsFiltered() {
			return this.teamFilter.value !== 'all' || this.matchDayFilter.value !== 'all';
		},
		matchDayFilterOptions() {
			const orderedOptions = [...this.eventsList].sort((a, b) => a.orderIndex !== null ? a.orderIndex - b.orderIndex : a);
			const options = [
				{
					label: this.$I18n.trans('commons.all_types', { type: this.$I18n.trans_choice('commons.game', 2) }),
					value: 'all',
				},
				...orderedOptions.map(listEntry => ({ label: listEntry.titleMain, value: listEntry.groupKey })),
			];
			if (this.isMatchDayView) {
				return options.filter(o => {
					return o.value.includes(this.views.currentSelection) || o.value === 'all';
				});
			}
			return options;
		},
		// get season stage types from events for the view select
		seasonStageTypes() {
			const seasonStageTypes = [];
			if (this.events) {
				[...this.events].sort((a, b) => a.startTimestamp - b.startTimestamp).forEach(event => {
					if (event.seasonStage) {
						const seasonStageType = event.seasonStage.type;
						if (!seasonStageTypes.find(t => t === seasonStageType)) {
							seasonStageTypes.push(seasonStageType);
						}
					}
				});
			}
			return seasonStageTypes;
		},
		isSetsView() {
			return this.events[0]?.sport?.name === SportName.VOLLEYBALL || false;
		},
	},
	watch: {
		events(newValues) {
			this.internalEvents = newValues;
		},
		seasonStageTypes(newValues) {
			this.views.options = [chronologicalViewOption];
			if (newValues.length) {
				// If all events have a SeasonStageType.REGULAR_SEASON and no event has a matchdaynumber, do not show matchday view (keep chronological view)
				// e.g. for SFL
				const onlyRegularSeason = this.events.every(event => event.seasonStage?.type === SeasonStageType.REGULAR_SEASON);
				if (onlyRegularSeason && this.events.filter(event => event.seasonStage?.type === SeasonStageType.REGULAR_SEASON).some(event => event.matchdayNumber === null)) {
					return;
				}
				[...newValues].reverse().forEach(seasonStageType => {
					this.views.options.unshift({
						label: $I18n.trans(`season_stages.stages.${seasonStageType}`),
						value: seasonStageType,
					});
				});
				this.views.currentSelection = newValues[0];
			}
			this.filterCurrentMatchDay();
			this.goToNextDuelMatchDay();
		},
	},
	methods: {
		filterCurrentMatchDay() {
			const reference = moment();
			const today = reference.clone().startOf('day');
			const yesterday = reference.clone().subtract(1, 'days').startOf('day');

			let groupKey = null;
			let groupType = null;
			let groupMatchDate = null;
			let multipleMatchDays = false;
			[...this.eventsList].reverse().forEach(group => {
				// check if there are otehr groups with events yesterday or today
				if (groupKey !== null) {
					const otherGroupHasEventYesterdayOrToday = group.matchDates.some(matchDate => {
						return matchDate.matches.some(match => {
							const matchDate = moment.unix(match.startTimestamp);
							return matchDate.isSame(today, 'd') || matchDate.isSame(yesterday, 'd');
						});
					});
					if (otherGroupHasEventYesterdayOrToday) {
						multipleMatchDays = true;
					}
				}

				group.matchDates.forEach(matchDate => {
					matchDate.matches.forEach(match => {
						if (groupKey !== null) {
							return;
						}

						const matchDate = moment.unix(match.startTimestamp);

						// Show todays matchday or yesterdays matchday if there are no matches today
						if (matchDate.isSame(today, 'd') || matchDate.isSame(yesterday, 'd')) {
							groupKey = group.groupKey;
							groupType = group.groupType;
						}
					});
				});
			});
			// If not yet filtered, filter by next upcoming Matchday
			if (!groupKey) {
				this.eventsList.forEach(group => {
					// check if there are other groups with events on the same date as the group with groupKey
					if (groupKey !== null) {
						const otherGroupHasMatchOnSameDateAsGroupWithGroupKey = group.matchDates.some(matchDate => {
							return matchDate.matches.some(match => {
								const matchDate = moment.unix(match.startTimestamp);
								return matchDate.isSame(groupMatchDate, 'd');
							});
						});
						if (otherGroupHasMatchOnSameDateAsGroupWithGroupKey) {
							multipleMatchDays = true;
						}
					}

					group.matchDates.forEach(matchDate => {
						matchDate.matches.forEach(match => {
							if (groupKey !== null) {
								return;
							}

							const matchDate = moment.unix(match.startTimestamp);

							if (matchDate.isAfter(today, 'd')) {
								groupKey = group.groupKey;
								groupType = group.groupType;
								groupMatchDate = matchDate;
							}
						});
					});
				});
			}

			if (groupKey && groupKey !== SeasonStageType.REGULAR_SEASON) {
				this.matchDayFilter.value = multipleMatchDays ? 'all' : groupKey;
				this.views.currentSelection = groupType;
			}
		},
		resetFilter() {
			this.teamFilter.value = 'all';
			this.matchDayFilter.value = 'all';
		},
		// reset view on navigation change (schedule / table)
		setViewToMatchdays() {
			this.resetFilter();
			this.views.currentSelection = this.views.options[0].value;
		},
		handleViewChange(option) {
			this.views.currentSelection = option.value;
			this.matchDayFilter.value = 'all';

			if (!this.isMatchDayView) {
				this.$nextTick(() => {
					this.initScrollObserver();
					this.goToCurrentMonth();
				});
			}
		},
		goToCurrentMonth() {
			const currentMonth = moment().month();
			const currentYear = moment().year();

			let currentMonthKey = null;
			this.eventsListFiltered.forEach(eventsList => {
				if (currentMonthKey) {
					return;
				}

				const isCurrentYear = currentYear === moment.unix(eventsList.matchDates[0].matches[0].startTimestamp).year();
				const isCurrentMonth = currentMonth === parseInt(eventsList.groupKey.split('_')[1]);
				if (isCurrentYear && isCurrentMonth) {
					currentMonthKey = eventsList.groupKey;
				}
			});

			if (currentMonthKey) {
				this.handleMonthSelect(currentMonthKey);
			}
		},
		goToNextDuelMatchDay() {
			if (!this.isMatchDayView) return;

			const today = moment();
			let scrollAnchorNextMatch = null;

			for (const listEntry of this.eventsListFiltered) {
				const foundDuel = listEntry.duelMatchesGrouped.find(duel => duel.events.some(event => {
					const matchDate = moment.unix(event.startTimestamp);
					return matchDate.isAfter(today, 'day');
				}));

				if (foundDuel) {
					scrollAnchorNextMatch = foundDuel.scrollAnchor;
					break;
				}
			}

			// Scroll to the anchor if found
			if (scrollAnchorNextMatch) {
				this.$nextTick(() => {
					const element = document.querySelector(`#${scrollAnchorNextMatch}`);
					if (element) {
						element.scrollIntoView({ block: 'start', behavior: 'smooth' });
					}
				});
			}
		},
		handleMonthSelect(id) {
			document.querySelector(`#${id}`).scrollIntoView({ block: 'start', behavior: 'smooth' });
		},
		handleMobileFilterChange(select, value) {
			if (select === 'reset') {
				this.handleTeamChange('all');
				this.handleMatchDayChange('all');
				return;
			}

			if (select?.id === 'team') {
				this.handleTeamChange(value);
			}
			if (select?.id === 'matchDay') {
				this.handleMatchDayChange(value);
			}
		},
		handleTeamChange(value) {
			this.teamFilter.value = value;
		},
		handleMatchDayChange(value) {
			this.matchDayFilter.value = value;
		},
		initScrollObserver() {
			if (this.scrollObserverActive) {
				return;
			}

			let ticking = false;
			window.addEventListener('scroll', () => {
				if (!ticking) {
					window.requestAnimationFrame(() => {
						this.setScrollMonth();
						ticking = false;
					});
					ticking = true;
				}
			});

			this.scrollObserverActive = true;
		},
		setScrollMonth() {
			const scrollAnchor = this.$refs.scrollAnchor;
			if (!scrollAnchor) {
				return;
			}
			const anchorItems = this.$refs.scrollAnchor.map(anchor => {
				return {
					el:  anchor,
					pos: anchor.getBoundingClientRect().top - 10,
				};
			});
			const currentMonth = anchorItems.filter(item => item.pos <= 0).reverse()[0]?.el.dataset.month || anchorItems[0].el.dataset.month;
			this.$refs.monthSelect.forEach(select => {
				select.classList.remove('is-active');
				if (select.dataset.month === currentMonth) {
					select.classList.add('is-active');
				}
			});
		},
		openMobileFilter() {
			this.$refs.mobileFilter.open();
		},
		navigateMatchDay(navigateBy) {
			const currentIndex = this.matchDayFilterOptions.findIndex(o => o.value === this.matchDayFilter.value);
			const newIndex = currentIndex + navigateBy;
			const newValue = this.matchDayFilterOptions[newIndex]?.value;
			if (typeof newValue === 'undefined' || newValue === 'all') {
				return;
			}
			this.handleMatchDayChange(newValue);
		},
		groupDuels(events) {
			const groups = [];
			events.forEach(event => {
				const groupIndex = groups.findIndex(group => {
					return group.events.some(groupEvent => {
						const groupEventParticipantIds = new Set(groupEvent.participants.map(participant => participant.participatable.id));
						const eventParticipantIds = new Set(event.participants.map(participant => participant.participatable.id));

						return [...groupEventParticipantIds].every(id => eventParticipantIds.has(id));
					});
				});
				if (groupIndex === -1) {
					groups.push({
						events: [event],
					});
				} else {
					groups[groupIndex].events.push(event);
				}
			});

			// check how many matches each team has won in each group
			return groups.map(group => {
				const home = group.events[0].participants?.find(participant => participant.type === ParticipantType.HOME)?.participatable;
				const guest = group.events[0].participants?.find(participant => participant.type === ParticipantType.GUEST)?.participatable;
				return {
					...group,
					homeTeamName:  home.club.name,
					guestTeamName: guest.club.name,
					teamIds:       [home.id, guest.id],
					scrollAnchor:  `duel_${home.id}_${guest.id}`,
					overallScores: group.events.reduce((acc, current) => {
						const isEventHomeTeamOverallHomeTeam = current.participants?.find(participant => participant.type === ParticipantType.HOME)?.participatable?.id === home.id;
						const homeTeamScore = current.finalScore?.teamHomeScore;
						const guestTeamScore = current.finalScore?.teamGuestScore;

						// If final score is not set or match is live, do not count the match
						if (!current.finalScore || current.isLive) {
							return acc;
						}

						const eventHomeTeamWon = homeTeamScore > guestTeamScore;
						const duelHomeTeamWon = (eventHomeTeamWon && isEventHomeTeamOverallHomeTeam) || (!eventHomeTeamWon && !isEventHomeTeamOverallHomeTeam);

						return {
							homeTeamScore:  duelHomeTeamWon ? acc.homeTeamScore + 1 : acc.homeTeamScore,
							guestTeamScore: !duelHomeTeamWon ? acc.guestTeamScore + 1 : acc.guestTeamScore,
						};
					}, { homeTeamScore: 0, guestTeamScore: 0 }),
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.matchdays__loading-spinner {
	position: absolute;
	top: 20vh;
	left: 50%;
	transform: translate(-50%);
}

.matchdays__filter {
	margin-bottom: $sp5;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--color-grey-900);

	@include size(md-up) {
		margin-bottom: $sp8;
	}
}

.matchdays__view-select-w {
	display: flex;
	align-self: center;
	gap: $sp4;
	margin-bottom: $sp4;
}

.matchdays__view-select {
	padding: $sp3 $sp4;
	border-radius: $border-radius--sm;
	color: var(--color-grey-400);
	transition: background-color $trans-time-fast;
	cursor: pointer;

	&.is-active {
		background-color: var(--color-grey-850);
		color: var(--color-soft-white);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			background-color: var(--color-hype-yellow);
		}
	}

	&:hover,
	&:focus {
		background-color: var(--color-grey-900);
	}
}

.matchdays__filter-select {
	display: none;

	@include size(md-up) {
		display: flex;
		gap: $sp5;
		margin-bottom: 0;

		> div { // stylelint-disable-line selector-max-type
			width: 250px;
		}
	}
}

.matchdays__mobile-filter-toggle {
	cursor: pointer;
	margin-bottom: $sp4;

	&.is-filtered {
		color: var(--color-hype-yellow);
	}

	@include size(md-up) {
		display: none;
	}
}

.matchdays__list-w {
	display: flex;
}

.matchdays__month-selector-w {
	min-width: 65px;

	@include size(md-up) {
		min-width: 140px;
	}
}

.matchdays__month-selector-inner-w {
	position: sticky;
	top: $sp5;
}

.matchdays__month-select {
	color: var(--color-grey-400);
	padding: $sp2 0;
	margin-bottom: $sp3;
	cursor: pointer;

	&:focus,
	&:hover,
	&.is-active {
		color: var(--color-soft-white);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			color: var(--color-hype-yellow);
		}
	}
}

.matchdays__list {
	flex-grow: 1;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-black);
	}
}

// .matchdays__list-inner-w {}

.matchdays__list-scroll-anchor {
	visibility: hidden;
	width: 0;
	height: 0;
	position: relative;
	top: -100px;
}

.matchdays__list-title {
	@include font(primary, book, normal, fs-150);
	position: relative;
	margin-bottom: $sp3;

	@include size(md-up) {
		@include font(primary, light, normal, fs-170);
		margin-bottom: $sp4;
	}
}

.matchdays__list-date-w {
	margin-bottom: $sp6;

	&.-duel {
		margin-bottom: $sp4;
	}
}

.matchdays__list-date {
	margin-bottom: $sp2;

	.matchdays__list-date-w.-duel & {
		margin-bottom: 0;
	}

	@include size(md-up) {
		margin-bottom: $sp4;
	}

	@include size(sm-down) {
		font-weight: 500; // stylelint-disable-line property-disallowed-list
	}
}

// Duel title styles
.matchdays__list-duel-w {
	padding-bottom: $sp7;
}

.matchdays__list-duel-standing {
	@include font(primary, medium, normal, fs-100);
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: flex-start;
	padding-bottom: $sp3-5;
	padding-right: $sp3;
	padding-top: $sp3-5;
	margin-bottom: $sp4;
	margin-top: $sp4;
	border-top: 1px solid var(--color-grey-900);
	border-bottom: 1px solid var(--color-grey-900);
	gap: $sp3;

	&.-sets-view {
		@include size(sm-up) {
			margin-left: 0;
			justify-content: flex-start;
			padding-left: 0;
		}
	}

	@include size(sm-up) {
		@include font(primary, medium, normal, fs-150);
		flex-direction: row;
		align-items: center;
		gap: $sp6;
		padding-left: 12px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		margin-top: 0;
		border-top: none;
		padding-right: 0;
	}
}

.matchdays__list-duel-team {
	$result-width: 60px;
	$result-width-md: 110px;

	display: flex;
	align-items: center;
	gap: $sp3;
	width: 100%;
	justify-content: space-between;

	.matchdays__list-duel-standing.-sets-view & {
		@include size(sm-up) {
			width: auto;
		}
		@include size(md-up) {
			width: auto;
		}
	}

	// &.-guest {}

	@include size(sm-up) {
		width: calc(100% / 2 - #{$result-width});

		&.-home {
			justify-content: flex-end;
		}
	}

	@include size(md-up) {
		width: calc(100% / 2 - #{$result-width-md});
	}
}

.matchdays__list-duel-team-name {
	@include size(sm-up) {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: calc(50vw - 50px);
	}
}

.matchdays__list-duel-standing-score {
	padding: $sp3 $sp4;
	background-color: var(--color-grey-900);
	border-radius: $border-radius--sm;
	white-space: nowrap;
}

.matchdays__navigate-matchday {
	display: flex;
	justify-content: center;
	gap: $sp6;
	margin-bottom: $sp7;
}
</style>
