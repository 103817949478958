<template>
	<SectionTitle :title="title">
		<template #inner>
			<span
				v-if="showResultsCounter"
				class="results-counter"
			>|
				<template v-if="isLoading">
					{{ $I18n.trans('commons.loading') }}
				</template>
				<template v-else>
					{{ resultCount }} {{ $I18n.trans_choice('commons.results', resultCount) }}
				</template>
			</span>
		</template>
	</SectionTitle>
</template>

<script>
import SectionTitle from '../SectionTitle';

export default {
	name:       'SectionTitleMatchCenter',
	components: { SectionTitle },
	props:      {
		title: {
			type:     String,
			required: true,
		},
		isLoading: {
			type:    Boolean,
			default: true,
		},
		showResultsCounter: {
			type:    Boolean,
			default: true,
		},
		resultCount: {
			type:    Number,
			default: 0,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.results-counter {
	color: var(--color-grey-300);
}
</style>
