<template>
	<div
		class="play-toggle"
		:class="{'-dark': darkTheme}"
	>
		<button
			v-if="isPaused"
			class="play-toggle__button -play"
			@click.stop="handlePlay"
		>
			<Icon
				icon-name="button-play-filled"
				:size="size"
			/>
		</button>
		<button
			v-if="pauseEnabled && !isPaused"
			class="play-toggle__button -pause"
			@click.stop="handlePause"
		>
			<Icon
				icon-name="button-pause-filled"
				:size="size"
			/>
		</button>
		<button
			v-if="stopEnabled && !isPaused"
			class="stop"
			@click.stop="handleStop"
		>
			<Icon
				icon-name="button-stop-filled"
				:size="size"
			/>
		</button>
	</div>
</template>

<script>
import Icon from '@shared/components/Icon.vue';

export default {
	name:       'PlayToggle',
	components: {
		Icon,
	},
	props: {
		isPlaying: {
			type:    Boolean,
			default: false,
		},
		pauseEnabled: {
			type:    Boolean,
			default: true,
		},
		stopEnabled: {
			type:    Boolean,
			default: false,
		},
		size: {
			type:      String,
			default:   'lg',
			validator: (value) => ['sm', 'md', 'lg'].includes(value),
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		isPaused() {
			return !this.isPlaying;
		},
	},
	methods: {
		handlePlay() {
			this.$emit('play');
		},
		handlePause() {
			this.$emit('pause');
		},
		handleStop() {
			this.$emit('stop');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.play-toggle {
	color: var(--color-white);
	line-height: 0;

	&.-dark {
		color: var(--color-black);
	}
}

.play-toggle__button {
	color: var(--color-white);
	cursor: pointer;

	.play-toggle.-dark & {
		color: var(--color-black);
	}
}
</style>
