import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, SET_NOTIFICATIONS, SET_SEEN, UPDATE_NOTIFICATION } from './mutation-types';

export default {
	[SET_NOTIFICATIONS](state, { notifications }) {
		if (notifications) {
			state.read = notifications.filter((notification) => (notification.seenAtTimestamp !== 0 && notification.dismissible || notification.notificationType.name === 'info'));
			state.unread = notifications.filter((notification) => (notification.seenAtTimestamp === 0 && notification.notificationType.name !== 'info') || !notification.dismissible);
		} else {
			state.read = [];
			state.unread = [];
		}
	},
	[SET_SEEN](state, { notification }) {
		state.unread = state.unread.map((oldNotification) => {
			return oldNotification.id === notification.id ? notification : oldNotification;
		});
	},
	[ADD_NOTIFICATION](state, { notification }) {
		state.unread.unshift(notification);
	},
	[UPDATE_NOTIFICATION](state, { notification }) {
		// Edge case, tbd...
	},
	[REMOVE_NOTIFICATION](state, { notification }) {
		state.read = state.read.filter((item) => item.id !== notification.id);
		state.unread = state.unread.filter((item) => item.id !== notification.id);
	},
};
