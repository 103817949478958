<template>
	<Player
		v-if="previewHlsUrl && hlsStreamAvailable"
		ref="player"
		class="live-player__player"
		:class="{ '-playing': isPlaying }"
		:hls-url="previewHlsUrl"
		:show-controls="false"
		:auto-play="true"
		:pointer-events="true"
		:show-errors="false"
		@quality-levels-updated="onQualityLevelsUpdated"
		@play="onPlay"
		@error="handlePlaybackError(eventId, $event)"
	>
		<div
			slot="over"
			class="live-player__over-w"
			:class="{ '-stopped': isStopped }"
		>
			<ProductPlacementInfo
				v-if="productPlacementEnabled"
				class="live-player__product-placement-info"
				:is-playing="isPlaying"
				:is-live="true"
				:only-show-at-beginning="true"
			/>
			<div
				v-if="isStopped"
				class="live-player__stopped-indicator"
			>
				<Icon
					class="live-player__stopped-indicator-play"
					icon-name="button-play-filled"
					size="xxxl"
				/>
			</div>
			<div class="live-player__muted-indicator">
				<AutoplayMutedIndicator
					v-if="showAutoplayMutedIndicator"
					:show-sound-off-when-unmuted="true"
					:disable-dismiss="true"
					:local-is-mobile="isMobile"
					@click="handleAutoplayedMutedClick"
				/>
			</div>
		</div>
	</Player>
	<div
		v-else-if="!hlsStreamAvailable"
		class="live-player__poster"
		:style="{ backgroundImage: `url(${poster})` }"
	/>
</template>

<script>
import Player from '@shared/components/Player';
import AutoplayMutedIndicator from '@shared/components/AutoplayMutedIndicator.vue';
import ProductPlacementInfo from '@shared/components/ProductPlacementInfo.vue';
import Icon from '@shared/components/Icon';
import laroute from '@laroute';
import * as Sentry from '@sentry/vue';

import { createHeartbeat } from '@shared/js/heartbeat';
import storage from '@shared/js/storage';

export default {
	name:       'LivePreviewPlayer',
	components: { Player, AutoplayMutedIndicator, ProductPlacementInfo, Icon },
	props:      {
		eventId: {
			type:     Number,
			required: true,
		},
		productPlacementEnabled: {
			type:    Boolean,
			default: false,
		},
		autostopMutedAfterSeconds: {
			type:    Number,
			default: 0,
		},
		autostopAfterSeconds: {
			type:    Number,
			default: 0,
		},
		poster: {
			type:    String,
			default: '',
		},
	},
	data() {
		return {
			previewHlsUrl:              '',
			showAutoplayMutedIndicator: false,
			isPlaying:                  false,
			wasUnmuted:                 false,
			hasPlayedOnce:              false,
			heartbeat:                  null,
			isStopped:                  false,
			autoplayTimeout:            null,
			autoplayMutedTimeout:       null,
			playbackStart:              null,
			playerQualityLevel:         null,
			hlsStreamAvailable:         true,
			isMobile:                   window.innerWidth < 1024 && window.matchMedia('(pointer: coarse)').matches,
		};
	},
	beforeMount() {
		this.setPreviewHlsUrl();
	},
	mounted() {
		this.heartbeat = createHeartbeat()
			.setViewerId(storage.viewerId)
			.setEventId(this.eventId)
			.setPayload({ autoplay: true }, true);
	},
	methods: {
		async setPreviewHlsUrl() {
			try {
				const options = {
					method: 'GET',
					url:    laroute.route('api.event.playout', { event: this.eventId }),
				};

				// fetch the response with window.fetch
				const response = await fetch(options.url, options);
				const data = await response.json();
				const hlsUrl = data?.hlsUrl;

				if (hlsUrl) {
					this.previewHlsUrl = hlsUrl;
					this.fetchEventData();
				} else {
					this.hlsStreamAvailable = false;
				}
			} catch (error) {
				this.hlsStreamAvailable = false;
			}
		},
		async fetchEventData() {
			const options = {
				method: 'GET',
				url:    laroute.route('api.event', { event: this.eventId }),
			};

			const response = await fetch(options.url, options);
			const data = await response.json();

			if (data.exclusive) {
				this.heartbeat.setPayload({ autoplay: true, exclusive: data.exclusive }, true);
			}
		},
		onQualityLevelsUpdated(levels) {
			const index720p = levels.findIndex((level) => level.height === 720);
			if (index720p !== -1 && index720p !== this.playerQualityLevel) {
				this.log('Setting player quality to 720p.');
				this.$refs.player.setQuality(index720p);
				this.playerQualityLevel = index720p;
			}
		},
		onPlay() {
			if (this.isStopped) {
				this.log('Player tried to play, but was stopped earlier.');
				this.$refs.player.stop();
				return;
			}

			this.playbackStart = Date.now();
			this.showAutoplayMutedIndicator = true;
			this.isPlaying = true;
			this.logPlaybackStart(this.eventId);
			this.heartbeat.start();

			if (this.autostopMutedAfterSeconds > 0 && !this.autoplayMutedTimeout) {
				this.autoplayMutedTimeout = setTimeout(() => {
					if (this.wasUnmuted) {
						this.log('Player was unmuted, not pausing playback');
						return;
					}

					this.log(`Pausing playback after ${Math.abs(Date.now() - this.playbackStart) / 1000} seconds because player is muted`);
					this.pause();
				}, this.autostopMutedAfterSeconds * 1000);
			}

			if (this.autostopAfterSeconds > 0 && !this.autoplayTimeout) {
				this.autoplayTimeout = setTimeout(() => {
					this.log(`Pausing playback after ${Math.abs(Date.now() - this.playbackStart) / 1000} seconds, with player unmuted`);
					this.pause();
				}, this.autostopAfterSeconds * 1000);
			}

			this.log('Playback started');
		},
		handleAutoplayedMutedClick() {
			this.$refs.player.toggleMute();
			this.wasUnmuted = true;
			this.log('Player unmuted');
		},
		logPlaybackStart(eventId) {
			if (this.hasPlayedOnce) {
				return;
			}
			this.hasPlayedOnce = true;

			Sentry.captureMessage('Live Slide: Video Playback Started', {
				level: 'info',
				tags:  {
					eventId,
					action: 'playback_start',
					group:  'live-slide',
				},
				extra: {
					timestamp: new Date().toISOString(), //
				},
			});
		},
		handlePlaybackError(eventId, error) {
			if (error.details === 'manifestLoadError') {
				this.$emit('stream-not-available', eventId);
				this.hlsStreamAvailable = false;
			}
			Sentry.captureMessage('Live Slide: Video Playback Error', {
				level: 'error',
				tags:  {
					eventId,
					group: 'live-slide',
				},
				extra: {
					errorDetails: error,
					timestamp:    new Date().toISOString(),
				},
			});

			this.heartbeat.pause();
		},
		pause() {
			if (!this.isStopped) {
				this.$refs.player.end();
				this.heartbeat.pause();
				this.showAutoplayMutedIndicator = false;
				this.isStopped = true;

				if (this.autoplayTimeout) {
					clearTimeout(this.autoplayTimeout);
				}
				this.log('Playback stopped.');
			} else {
				this.log('Pause requested but playback is already stopped.');
			}
		},
		log(msg, level = 'info') {
			msg = `[LIVE][${new Date().toLocaleString()}] ${msg} (eventId=${this.eventId})`;
			if (typeof console[level] === 'function') {
				console[level](msg);
			} else {
				console.log('Unknown log level:', level);
				console.log(msg);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.live-player__over-w {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: background-color 0.1s ease-in;
	background-color: transparent;

	&.-stopped:hover {
		background-color: rgba(0, 0, 0, 0.1);
	}
}

.live-player__stopped-indicator {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	align-items: center;
	flex-direction: column;
}

.live-player__stopped-indicator-text {
	@include font-size(fs-150);
	margin-bottom: $sp4;
}

.live-player__player {
	opacity: 0.2;
	transition: opacity 0.7s ease-in;

	&.-playing {
		opacity: 1;
	}
}

.live-player__poster {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
	aspect-ratio: 16 / 9;

	@include size(xs-only) {
		aspect-ratio: 4 / 3;
	}
}

.live-player__product-placement-info {
	position: absolute;
	top: 24px;
	right: 24px;
}

.live-player__muted-indicator {
	position: absolute;
	bottom: 24px;
	left: 24px;

	@include size(sm-down) {
		position: absolute;
		top: 0;
		left: -10px;
		width: 100%;
		height: 80px;
	}
}
</style>
