<template>
	<div class="v2-input-error">
		<V2Icon
			class="v2-input-error__icon"
			size="xs"
			icon-name="c-warning"
		/>
		{{ text }}
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';

export default {
	name:       'InputError',
	components: { V2Icon },
	props:      {
		text: {
			type:     String,
			required: true,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-input-error {
	@include form-error-message-font();

	display: flex;
	align-items: center;
	color: $form-color-error;
	margin-top: 6px;
}

.v2-input-error__icon {
	margin-right: 8px;
}
</style>
