<template>
	<a
		:href="link"
		class="match"
		:class="{'-show-sets': showSets}"
	>
		<div class="match__time">
			<div
				v-if="status === EVENT_STATUS.LIVE || seasonStageRoundInfo"
				class="match__infos-top"
			>
				<div
					v-if="status === EVENT_STATUS.LIVE"
					class="match__live-badge"
				>
					<BadgeLive
						modifier="small"
						:always-show-text="true"
					/>
				</div>
				<div
					v-if="seasonStageRoundInfo"
					class="match__competition-stage"
					:class="{'is-advanced': seasonStageRoundInfo.advanced}"
				>{{ seasonStageRoundInfo.label }}</div>
			</div>
			{{ time }}
		</div>
		<div
			v-if="showSets"
			class="match__result-w-sets"
			:class="[`-repeat-${numberOfPeriods}`]"
		>
			<div class="match__result-logo l-result-item">
				<img
					:src="$Helpers.getScaledImageUrl(homeImgUrl, 50)"
					:alt="homeName"
				>
			</div>
			<div class="match__result-name l-result-item u-show-as-flex@sm">
				{{ homeNameFull }}
			</div>
			<div class="match__result-name l-result-item u-hide@sm">
				{{ homeName }}
			</div>
			<div class="match__result-score l-result-item">
				{{ noSpoilerMode ? '-' : homeScoreWithFallback }}
			</div>
			<!-- The loser of the set is low colored, except the last set (the ongoing one) -->
			<div
				v-for="(period, i) in scores"
				:key="`setshome-${period.id}`"
				class="match__result-set l-result-item"
				:class="{
					'-loser': ((period.teamHomeScoreInPeriod < period.teamGuestScoreInPeriod) && (i < numberOfPeriods-1 || status !== EVENT_STATUS.LIVE)),
				}"
			>
				{{ noSpoilerMode ? '-' : (period.teamHomeScoreInPeriod || '0') }}
			</div>
			<div class="match__result-logo l-result-item">
				<img
					:src="$Helpers.getScaledImageUrl(guestImgUrl, 50)"
					:alt="guestName"
				>
			</div>
			<div class="match__result-name l-result-item u-show-as-flex@sm">
				{{ guestNameFull }}
			</div>
			<div class="match__result-name l-result-item u-hide@sm">
				{{ guestName }}
			</div>
			<div class="match__result-score l-result-item">
				{{ noSpoilerMode ? '-' : guestScoreWithFallback }}
			</div>

			<div
				v-for="(period, i) in scores"
				:key="`setsguest-${period.id}`"
				class="match__result-set l-result-item"
				:class="{ '-loser': ((period.teamHomeScoreInPeriod > period.teamGuestScoreInPeriod) && (i < numberOfPeriods-1 || status !== EVENT_STATUS.LIVE)) }"
			>
				{{ noSpoilerMode ? '-' : (period.teamGuestScoreInPeriod || '0') }}
			</div>
		</div>

		<div
			v-else
			class="match__result-w"
		>
			<div class="match__no-sets-team -home">
				<div class="match__no-sets-team-name u-show@sm">
					{{ homeNameFull }}
				</div>
				<div class="match__no-sets-team-name u-hide@sm">
					{{ homeName }}
				</div>
				<div class="match__no-sets-team-logo">
					<img
						v-if="homeImgUrl"
						:src="$Helpers.getScaledImageUrl(homeImgUrl, 50)"
						:alt="homeNameFull"
					>
				</div>
			</div>
			<div class="match__no-sets-score">
				{{ noSpoilerMode ? '-' : homeScoreWithFallback }}<span class="match__no-sets-score-divider-space" />:<span class="match__no-sets-score-divider-space" /><span
					class="match__no-sets-score-extra"
					:data-extra="noSpoilerMode ? '' : scoreExtraAbbr"
				>{{ noSpoilerMode ? '-' : guestScoreWithFallback }}</span>
			</div>
			<div class="match__no-sets-team -guest">
				<div class="match__no-sets-team-logo">
					<img
						v-if="guestImgUrl"
						:src="$Helpers.getScaledImageUrl(guestImgUrl, 50)"
						:alt="guestNameFull"
					>
				</div>
				<div class="match__no-sets-team-name u-show@sm">
					{{ guestNameFull }}
				</div>
				<div class="match__no-sets-team-name u-hide@sm">
					{{ guestName }}
				</div>
			</div>
		</div>

		<div class="match__actions">
			<div class="match__event-link-icon">
				<div
					v-if="showSets"
					class="match__event-link-icon-text"
				>{{ $I18n.trans('arena.watch_game') }}</div>
				<Icon icon-name="button-play-filled" />
			</div>
			<div
				v-if="couchModeAvailable"
				class="match__couchmode-button"
				@click.prevent="openCouchMode"
			>
				<Icon
					icon-name="sofa"
					size="xl"
				/>
			</div>
		</div>
	</a>
</template>

<script>
import { getDateTime, getRelativeTime } from '@shared/utils/timeUtils';
import { getEventStatus, EVENT_STATUS } from '@shared/utils/eventUtils';
import BadgeLive from '@shared/components/BadgeLive';
import Icon from '@shared/components/Icon';
import { mapState } from 'vuex';

export default {
	name:       'MatchListItem',
	components: { BadgeLive, Icon },
	props:      {
		homeName: {
			type:    String,
			default: null,
		},
		homeNameFull: {
			type:    String,
			default: null,
		},
		homeImgUrl: {
			type:    String,
			default: null,
		},
		homeScore: {
			type:    String,
			default: null,
		},
		guestName: {
			type:    String,
			default: null,
		},
		guestNameFull: {
			type:    String,
			default: null,
		},
		guestImgUrl: {
			type:    String,
			default: null,
		},
		guestScore: {
			type:    String,
			default: null,
		},
		numberOfPeriods: {
			type:    Number,
			default: 0,
		},
		scores: {
			type:    Array,
			default: () => [],
		},
		scoreExtraAbbr: {
			type:    String,
			default: null,
		},
		showSets: {
			type:    Boolean,
			default: false,
		},
		link: {
			type:     String,
			required: true,
		},
		unixStart: {
			type:     Number,
			required: true,
		},
		unixKickoff: {
			type:     Number,
			required: true,
		},
		unixEnd: {
			type:     Number,
			required: true,
		},
		eventType: {
			type:     String,
			required: true,
		},
		couchModeAvailable: {
			type:    Boolean,
			default: false,
		},
		seasonStageRoundInfo: {
			type:    Object,
			default: null,
		},
	},
	data() {
		return {
			liveInSeconds: null,
			unixNow:       Math.round(new Date().getTime() / 1000),
			EVENT_STATUS,
		};
	},
	computed: {
		...mapState({
			preferences: state => state.user.preferences,
		}),
		noSpoilerMode() {
			return this.preferences?.noSpoilerMode || false;
		},
		status() {
			return getEventStatus(this.eventType, this.unixStart, this.unixEnd, this.unixNow);
		},
		time() {
			switch (this.status) {
			case EVENT_STATUS.UPCOMING:
				return this.$I18n.trans('arena.match_center.live_from', { time: getDateTime(this.unixStart, { withDate: false }) });
			case EVENT_STATUS.LIVE:
				return getRelativeTime(this.unixEnd, { useRemaining: true, unixNow: this.unixNow });
			default:
				return getDateTime(this.unixKickoff, { withDate: false });
			}
		},
		homeScoreWithFallback() {
			return this.homeScore || (this.status === EVENT_STATUS.LIVE ? '0' : '');
		},
		guestScoreWithFallback() {
			return this.guestScore || (this.status === EVENT_STATUS.LIVE ? '0' : '');
		},
	},
	beforeMount() {
		this.liveInSeconds = this.getLiveInSeconds();
		setInterval(() => {
			this.unixNow = Math.round(new Date().getTime() / 1000);
			this.liveInSeconds = this.getLiveInSeconds();
		}, 1000);
	},
	methods: {
		getLiveInSeconds() {
			const unixNow = new Date() / 1000;
			return this.unixStart - unixNow;
		},
		openCouchMode() {
			window.location.href = `${this.link}?autostartCouchmode`;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

$result-width: 60px;
$result-width-md: 110px;

.match {
	display: block;
	padding: $sp5 $sp4;
	border-bottom: 1px solid var(--color-grey-900);

	&.-show-sets {
		padding: $sp5 $sp4 $sp5 0;
	}

	&:focus,
	&:hover {
		background-color: var(--color-grey-900);
	}

	@include size(md-up) {
		display: flex;
		align-items: center;
		padding: 0 $sp5 0 $sp4;
		transition: background-color $trans-time-fast;

		&.-show-sets {
			height: 95px;
			padding: 0 $sp4;
		}

		&.match:not(.-show-sets) {
			justify-content: center;
			height: 80px;
		}

	}
}

.match__time {
	@include font-size(fs-60);
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: $sp4;
	position: relative;
	color: var(--color-grey-200);

	.match:not(.-show-sets) & {
		align-items: center;
	}

	@include size(md-up) {
		align-items: flex-start !important; // stylelint-disable-line declaration-no-important
		margin-bottom: 0;
		width: 140px;
		height: 100%;
		flex-shrink: 0;

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;
			height: 40%;
			background-color: var(--color-grey-700);
		}
	}
}

.match__infos-top {
	display: flex;
	align-items: baseline;
	gap: $sp3;
	margin-bottom: $sp3;
}

.match__competition-stage {
	color: var(--color-red);

	&.is-advanced {
		color: var(--color-success);
	}
}

.match__result-w {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: $sp5;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-black);
	}

	@include size(md-up) {
		flex-grow: 1;
	}
}

.match__result-w-sets {
	--width-logo: 32px;
	--width-name: 54px;
	--width-main-result: 36px;
	--width-set-result: 28px;
	display: grid;
	gap: $sp3 0;

	&.-repeat-0 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result);
	}

	&.-repeat-1 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result) var(--width-set-result);
	}

	&.-repeat-2 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result) repeat(2, var(--width-set-result));
	}

	&.-repeat-3 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result) repeat(3, var(--width-set-result));
	}

	&.-repeat-4 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result) repeat(4, var(--width-set-result));
	}

	&.-repeat-5 {
		grid-template-columns: var(--width-logo) var(--width-name) var(--width-main-result) repeat(5, var(--width-set-result));
	}

	@include size(sm-up) {
		--width-logo: 32px;
		--width-name: 230px;
		--width-main-result: 45px;
		--width-set-result: 35px;
	}

	@include size(md-up) {
		--width-logo: 42px;
		--width-name: 270px;
		--width-main-result: 45px;
		--width-set-result: 35px;
		margin: 0 $sp6;
	}
}

.match__no-sets-team {
	@include font-size(fs-80);
	display: flex;
	align-items: center;
	gap: $sp3;
	width: calc(100% / 2 - #{$result-width});

	&.-home {
		justify-content: flex-end;
	}
	// &.-guest {}

	@include size(md-up) {
		width: calc(100% / 2 - #{$result-width-md});
	}
}

// .match__no-sets-team-name {}

.match__no-sets-team-logo {
	width: 24px;
	flex-shrink: 0;

	@include size(md-up) {
		width: 40px;
	}
}

.match__no-sets-score {
	@include font(primary, medium, normal, fs-150);
	width: $result-width;
	text-align: center;

	@include size(md-up) {
		width: $result-width-md;
	}
}

.match__no-sets-score-divider-space {
	position: relative;
	width: 0.1em;
	display: inline-block;
}

.match__no-sets-score-extra {
	&::after {
		@include font(primary, book, normal, fs-60);
		line-height: inherit;
		content: attr(data-extra);
		position: absolute;
		color: var(--color-grey-300);
		margin-left: $sp2;
	}
}

.l-result-item {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 2ch;
	min-height: 2.5ex;
}

.match__result-name {
	@include font-size(fs-80);
	justify-content: flex-start;
	margin-right: $sp3;
	flex-grow: 0;
}

.match__result-logo {
	width: $sp5;

	@include size(md-up) {
		width: $sp6;
	}
}

.match__result-score {
	@include font(primary, bold, normal, fs-150);

	@include size(sm-up) {
		@include font(primary, medium, normal, fs-150);
	}
}

.match__result-set {
	@include font(primary, book, normal, fs-100);

	&.-loser {
		color: var(--color-grey-300);
	}

	//first set
	.match__result-score + & {
		margin-left: $sp2;

		@include size(sm-up) {
			margin-left: $sp3;
		}
	}

	@include size(md-up) {
		@include font-size(fs-80);
	}

}

@keyframes blinking {
	0% { opacity: 1; }
	80% { opacity: 0; }
	100% { opacity: 0; }
}

.match__actions {
	display: none;

	@include size(md-up) {
		display: flex;
		justify-content: flex-end;
		flex-shrink: 0;

		.match.-show-sets & {
			margin-left: auto;
		}

		.match:not(.-show-sets) & {
			width: 120px;
		}
	}
}

.match__event-link-icon {
	display: none;
	opacity: 0;
	transition: opacity $trans-time-fast;
	color: var(--color-hype-yellow);

	.match:focus &,
	.match:hover & {
		opacity: 1;
	}

	@include is-touch {
		opacity: 1;
	}

	@include size(md-up) {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.match__event-link-icon-text {
	margin-right: $sp3;
}

.match__couchmode-button {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-left: $sp5;
	padding-left: $sp5;

	&::after {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 1px;
		height: 100%;
		background-color: var(--color-grey-700);
		opacity: 0;
		transition: opacity $trans-time-fast;
	}

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-grey-700);
	}

	.match:focus &,
	.match:hover & {
		&::after {
			opacity: 1;
		}
	}

	&:focus,
	&:hover {
		color: var(--color-hype-yellow);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			color: var(--color-hype-yellow);
		}
	}

	@include is-touch {
		&::after {
			opacity: 1;
		}
	}
}

</style>
