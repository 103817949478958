<template>
	<div
		class="v2-badge-live"
		:class="[
			modifier ? `-${modifier}` : '',
			{'-not-live': notLive},
			{'-clickable': clickable},
		]"
		@click="handleClick"
	>
		<span
			v-if="!hideText || alwaysShowText"
			class="v2-badge-live__text"
			:class="{'-show-on-sm': alwaysShowText}"
		>
			{{ $I18n.trans('event.status.live') }}
		</span>
	</div>
</template>

<script>
const modifiers = ['large', 'medium', 'small', 'adaptive'];

export default {
	name:  'BadgeLive',
	props: {
		modifier: {
			type:      String,
			default:   null,
			validator: (modifier) => modifiers.includes(modifier),
		},
		hideText: {
			type:    Boolean,
			default: false,
		},
		alwaysShowText: {
			type:    Boolean,
			default: false,
		},
		notLive: {
			type:    Boolean,
			default: false,
		},
		clickable: {
			type:    Boolean,
			default: false,
		},
	},
	methods: {
		handleClick() {
			if (this.clickable) {
				this.$emit('click');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-badge-live {
	@include font(primary, bold, normal, fs-60);

	display: inline-flex;
	position: relative;
	background-color: var(--color-red);
	color: var(--color-white);
	align-items: center;
	line-height: 1;
	height: $sp4;
	padding-left: $sp2;
	padding-right: $sp2;

	&::before {
		content: '';
		background-color: var(--color-white);
		width: $sp3;
		height: $sp3;
		border-radius: 50%;
	}

	&.-small {
		height: 16px;
		padding-left: $sp2;
		padding-right: $sp2;
		border-radius: $border-radius--xs;
	}

	&.-medium {
		@include font(primary, medium, normal, fs-100);
		height: $sp5;
		padding-left: 7px;
		padding-right: 7px;
		border-radius: 1px;

		&::before {
			width: 8px;
			height: 8px;
		}
	}

	&.-large {
		@include font(primary, medium, normal, fs-120);
		height: $sp6;
		padding-left: 12px;
		padding-right: 12px;
		border-radius: 1px;

		&::before {
			width: 10px;
			height: 10px;
		}
	}

	&.-adaptive {
		@include size(md-up) {
			@include font(primary, medium, normal, fs-120);
			height: $sp6;
			padding-left: 12px;
			padding-right: 12px;
			border-radius: 1px;

			&::before {
				width: 10px;
				height: 10px;
			}
		}
	}

	&.-not-live {
		background-color: var(--color-grey-800) !important; // stylelint-disable-line declaration-no-important

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			background-color: var(--color-grey-200);
		}
	}

	&.-clickable {
		cursor: pointer;
	}

	body.-t-sportpass & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-hype-yellow);
	}
}

.v2-badge-live__text {
	margin-left: $sp2;
	margin-top: 0.1em;

	.v2-badge-live.-adaptive & { // stylelint-disable-line selector-max-type
		&:not(.-show-on-sm) {
			@include size(sm-down) {
				display: none;
			}
		}
	}

	.v2-badge-live.-large & { // stylelint-disable-line selector-max-type
		margin-top: -0.1em;
	}

}
</style>
