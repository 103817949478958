<template>
	<component
		:is="isLink ? 'a' : 'button'"
		:href="link"
		:title="title"
		class="v2-button"
		:class="[
			`-${modifier}`,
			theme ? `-t-${theme}` : '',
			busy ? 'is-busy' : '',
			size ? `-${size}` : '',
			text ? `has-text` : '',
			reverseOrder ? `-reverse-order` : '',
			simplifiedOnMobile ? `-simplified-on-mobile` : '',
			simplifiedOnTablet ? `-simplified-on-tablet` : '',
			noHorizontalPadding ? `-no-h-padding` : '',
		]"
		:disabled="disabled || busy"
		:target="isExternalLink ? '_blank' : false"
		type="button"
		@click="$emit('click', $event)"
	>
		<V2Icon
			v-if="icon && !busy"
			class="v2-button__icon"
			:icon-name="icon"
			:size="iconSize ? iconSize : size === 'small' ? 'xs' : 'md'"
		/>
		<span
			v-if="busy"
			class="v2-button__processing"
		/>
		<span
			v-if="text"
			class="v2-button__text"
		>
			{{ text }}
		</span>
	</component>
</template>

<script>
import V2Icon from '@shared/components/Icon';

const modifiers = [
	'primary',
	'secondary',
	'tertiary',
	'danger',
	'danger-negative',
	'borderless',
	'borderless-danger',
	'custom',
];

const themes = [
	'dark',
];

const sizes = [
	'medium',
	'small',
];

export default {
	name:       'V2Button',
	components: { V2Icon },
	props:      {
		text: {
			type:     String,
			required: false,
			default:  null,
		},
		/**
		 * primary (default), secondary, tertiary, danger, danger-negative, borderless
		 */
		modifier: {
			type:     String,
			required: false,
			default:  'primary',
			validator(modifier) {
				return modifiers.includes(modifier);
			},
		},
		icon: {
			type:    String,
			default: null,
		},
		iconSize: {
			type:    String,
			default: null,
		},
		/**
		 * null (default), dark
		 */
		theme: {
			type:    String,
			default: null,
			validator(theme) {
				return themes.includes(theme);
			},
		},
		/**
		 * null (default), small
		 */
		size: {
			type:    String,
			default: 'medium',
			validator(size) {
				return sizes.includes(size);
			},
		},
		busy: {
			type:    Boolean,
			default: false,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		link: {
			type:    String,
			default: null,
		},
		isExternalLink: {
			type:    Boolean,
			default: false,
		},
		reverseOrder: {
			type:    Boolean,
			default: false,
		},
		title: {
			type:    String,
			default: null,
		},
		simplifiedOnMobile: {
			type:    Boolean,
			default: false,
		},
		simplifiedOnTablet: {
			type:    Boolean,
			default: false,
		},
		noHorizontalPadding: {
			type:    Boolean,
			default: false,
		},
	},
	computed: {
		isLink() {
			// Show disabled links as button for proper disabled styling
			return this.link !== null && !this.disabled;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

/* stylelint-disable selector-max-type, selector-no-qualifying-type */

.v2-button {
	display: inline-flex;
	align-items: center;
	vertical-align: top;
	height: 48px;
	padding: 0 12px;
	gap: $sp3;
	border-radius: $border-radius--lg;
	border-width: 1px;
	border-style: solid;
	font-size: $button-font-size; // stylelint-disable-line
	line-height: 100%;
	text-align: center;
	justify-content: center;
	transition: border-color $trans-time-fast, background-color $trans-time-fast, color $trans-time-fast;
	cursor: pointer;

	&.-small {
		height: 32px;
		padding: 0 8px;
		font-size: $button-font-size--sm; // stylelint-disable-line

		@include size(sm-up) {
			height: 36px;
		}
	}

	&.-primary {
		background-color: var(--color-green-petrol);
		border-color: var(--color-green-petrol);
		color: var(--color-soft-white);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-green-petrol--inactive);
			border-color: var(--color-green-petrol--inactive);
		}

		body.-t-light &, // stylelint-disable-line
		&.-t-dark {
			background-color: var(--color-hype-yellow);
			border-color: var(--color-hype-yellow);
			color: var(--color-text-on-hype-yellow);

			&:not([disabled]):focus,
			&:not([disabled]):hover {
				background-color: var(--color-hype-yellow--active);
				border-color: var(--color-hype-yellow--active);
			}
		}
	}

	&.-secondary {
		background-color: transparent;
		border-color: var(--color-green-petrol);
		color: var(--color-green-petrol);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-green-petrol--light);

			body.-t-manager-sportpass & { // stylelint-disable-line
				color: var(--color-soft-white);
			}
		}

		body.-t-light &, // stylelint-disable-line
		&.-t-dark {
			border-color: var(--color-hype-yellow);
			color: var(--color-hype-yellow);

			&:not([disabled]):focus,
			&:not([disabled]):hover {
				background-color: var(--color-hype-yellow);
				border-color: var(--color-hype-yellow);
				color: var(--color-text-on-hype-yellow);
			}
		}
	}

	&.-tertiary {
		background-color: transparent;
		border-color: var(--color-black);
		color: var(--color-black);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-grey-800);
			color: var(--color-soft-white);
		}
	}

	body.-t-light &, // stylelint-disable-line
	&.-tertiary.-t-dark {
		background-color: transparent;
		border-color: var(--color-soft-white);
		color: var(--color-soft-white);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-grey-800);
		}
	}

	&.-danger {
		background-color: transparent;
		border-color: var(--color-red);
		color: var(--color-red);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-red);
			color: var(--color-soft-white);
		}
	}

	&.-danger-negative {
		background-color: transparent;
		border-color: var(--color-soft-white);
		color: var(--color-soft-white);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			background-color: var(--color-soft-white);
			color: var(--color-red);
		}
	}

	&.-borderless-danger,
	&.-borderless {
		background-color: transparent !important; // stylelint-disable-line declaration-no-important
		border-color: transparent !important; // stylelint-disable-line declaration-no-important
		color: var(--color-green-petrol);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			color: var(--color-green-petrol--inactive);
		}

		&.-t-dark {
			color: var(--color-soft-white);

			&:not([disabled]):focus,
			&:not([disabled]):hover {
				color: var(--color-soft-white);
			}
		}

		body.-t-light & { // stylelint-disable-line
			color: var(--color-grey-300);

			&:not([disabled]):focus,
			&:not([disabled]):hover {
				color: var(--color-grey-100);
			}
		}
	}

	&.-borderless-danger {
		color: var(--color-red);

		&:not([disabled]):focus,
		&:not([disabled]):hover {
			color: var(--color-red--active);
		}
	}

	&.-reverse-order {
		flex-direction: row-reverse;
	}

	&.-simplified-on-mobile {
		@include size(xs-only) {
			min-width: auto !important; // stylelint-disable-line declaration-no-important
			border-radius: 50%;
		}
	}

	&.-simplified-on-tablet {
		@include size(sm-down) {
			min-width: auto !important; // stylelint-disable-line declaration-no-important
			border-radius: 50%;
		}
	}

	&.-no-h-padding {
		padding: 0;
	}

	&:not(.-borderless):not(.-borderless-danger).has-text {
		min-width: 135px;

		&.-small {
			min-width: 74px;
		}
	}

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	body.is-smarttv & { // stylelint-disable-line
		&:not([disabled]):focus { // stylelint-disable-line
			border: 3px solid var(--color-red) !important; // stylelint-disable-line declaration-no-important
			border-radius: 3px;
		}
	}
}

.v2-button__processing {
	display: inline-block;
	width: 17px;
	height: 17px;
	border: 2px solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	animation: spinner 1s ease-in-out infinite;
}

.v2-button__text {
	.v2-button.-simplified-on-mobile & {
		@include size(xs-only) {
			display: none;
		}
	}

	.v2-button.-simplified-on-tablet & {
		@include size(sm-down) {
			display: none;
		}
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
