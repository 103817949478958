<template>
	<div class="l-content-section">
		<div
			ref="jumbotron"
			class="jumbotron"
			:class="{'is-smart-tv': $Helpers.isSmartTV()}"
			:style="{backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)), url(${ bgImageUrl }`}"
		>
			<div class="l-container">
				<div class="l-row">
					<div :class="`l-col l-col--12 ${$Helpers.isSmartTV() ? '' : 'l-col--11@lg l-col--12@xl l-offset--1@lg l-offset--0@xl'}`">
						<div class="jumbotron__content">
							<img
								v-if="mainImageUrl"
								class="jumbotron__logo"
								:alt="title"
								:src="mainImageUrl"
							>
							<h1 class="jumbotron__title">
								{{ title }}
							</h1>
							<h2
								v-if="subtitle"
								class="jumbotron__subtitle"
								:class="{'-highlight': specialAnnouncement}"
							>
								{{ subtitle }}
							</h2>
						</div>
					</div>
				</div>
			</div>
		</div>
		<slot name="after" />
	</div>
</template>

<script>

export default {
	name:  'Jumbotron',
	props: {
		title: {
			type:     String,
			required: true,
		},
		subtitle: {
			type:    String,
			default: null,
		},
		specialAnnouncement: {
			type:    Boolean,
			default: false,
		},
		mainImageUrl: {
			type:    String,
			default: null,
		},
		bgImageUrl: {
			type:     String,
			required: true,
		},
	},
	data() {
		return {
			skeleton:           null,
			skeletonFullImages: null,
		};
	},
	mounted() {
		this.initSkeletonUnloading();
	},
	methods: {
		initSkeletonUnloading() {
			this.skeleton = document.querySelector('.js-jumbotron-skeleton');
			this.skeletonFullImages = document.querySelectorAll('.js-jumbotron-image-full');

			if (this.skeleton && this.skeletonFullImages) {
				this.unloadSkeleton();
			} else {
				this.$refs.jumbotron.classList.add('is-loaded');
			}
		},
		unloadSkeleton() {
			let loadedImagesCount = 0;

			const hideSkeleton = () => {
				loadedImagesCount++;

				if (loadedImagesCount === this.skeletonFullImages.length) {
					this.skeleton.classList.add('is-hidden');
					this.$refs.jumbotron.classList.add('is-loaded');
				}
			};

			Array.from(this.skeletonFullImages).forEach(img => {
				img.addEventListener('load', () => {
					hideSkeleton();
				});
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.jumbotron {
	position: relative;
	display: none;
	flex-direction: column;
	justify-content: center;
	padding-top: 4%;
	background-size: cover;
	background-position: center;
	border-bottom: 1px solid var(--color-grey-800);
	height: 420px;

	&.is-loaded {
		display: flex;
	}

	&.is-smart-tv {
		justify-content: flex-end;
		padding-bottom: 4%;
	}

	// on that size on Smart TV we have a zoom of 1.25 which would make it too big
	body.is-smarttv & { // stylelint-disable-line selector-no-qualifying-type, selector-max-type
		@media screen and (width: 1920px) {
			@supports (zoom: 1.25) {
				height: 600px;
			}
		}
	}

	@include size(md-up) {
		height: 520px;
	}

	@include size(xl-up) {
		height: 728px;
	}
}

.jumbotron__content {
	margin-bottom: $sp4;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-white);
	}
}

.jumbotron__logo {
	width: auto;
	max-height: 80px;
	max-width: 200px;
	margin-bottom: $sp6;

	@include size(md-up) {
		max-height: 90px;
		max-width: 240px;
	}

	@include size(xl-only) {
		max-height: 100px;
		max-width: 280px;
	}
}

.jumbotron__title {
	@include font(primary, bold, normal, fs-250);

	.jumbotron.is-smart-tv & {
		@include font(primary, bold, normal, fs-200);
	}
}

.jumbotron__subtitle {
	@include font-size(fs-150);

	margin-top: $sp3;

	&.-highlight {
		color: var(--color-hype-yellow);
		margin-top: 10px;
		font-size: 18px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
		font-weight: 300; // stylelint-disable-line property-disallowed-list
		font-style: normal; // stylelint-disable-line property-disallowed-list
		letter-spacing: -0.025em; // stylelint-disable-line property-disallowed-list

		@include size(md-up) {
			margin-left: 0.3em;
			font-size: 24px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
			margin-top: 15px;
		}

		@include size(xl-up) {
			font-size: 32px; // stylelint-disable-line sh-waqar/declaration-use-variable, property-disallowed-list
			margin-top: 20px;
		}
	}

	.jumbotron.is-smart-tv & {
		@include font(primary, light, normal, fs-150);
		max-width: 80%;
	}
}
</style>
