import '@shared/js/polyfills';
import { router } from '@js/utils/router';
import Vue from 'vue';
import Vuex from 'vuex';
import './bootstrap';
import directives from './directives';
import store from '@legacy/js/store';
import KeyNavigation from './utils/KeyNavigation';
import { initSentry } from '@shared/js/sentry';
import { initAffil } from '@shared/js/affil';
import './store/StoreLoader';
import './mixins/common';

// Globally registered components
import SectionTitle from './components/SectionTitle';
import V2Button from '@shared/components/Button';
import V2Icon from '@shared/components/Icon';

// Views
import ViewClient from './Views/ViewClient';
import ViewFederation from './Views/ViewFederation';
import ViewLeague from './Views/ViewLeague';
import ViewClub from './Views/ViewClub';
import ViewTeam from './Views/ViewTeam';
import ViewPlayer from './Views/ViewPlayer';
import ViewVenue from './Views/ViewVenue';
import ViewEvent from './Views/ViewEvent';
import ViewSports from './Views/ViewSports';
import ViewAlbum from './Views/ViewAlbum';
import ViewAlbumEmbed from './Views/ViewAlbumEmbed';
import ViewAlbumEmbedCarousel from './Views/ViewAlbumEmbedCarousel';
import ViewSubsite from './Views/ViewSubsite';
import ViewImprint from './Views/ViewImprint';
import ViewTerms from './Views/ViewTerms';
import ViewDeviceSupport from './Views/ViewDeviceSupport';
import ViewTermsSportpass from './Views/ViewTermsSportpass';
import ViewCookies from './Views/ViewCookies';
import ViewLoginError from './Views/ViewLoginError';
import ViewMatchCenter from './Views/ViewMatchCenter';
import ViewVideoCenter from './Views/ViewVideoCenter';
import ViewVideoCenterLeague from './Views/ViewVideoCenterLeague';
import ViewSwissLeagueInfos from './Views/ViewSwissLeagueInfos';
import ViewSflInfos from './Views/ViewSflInfos';
import ViewLogin from './Views/Login/ViewLogin';
import ViewResetPassword from './Views/Login/ViewResetPassword';
import ViewLoginSuccess from './Views/Login/ViewLoginSuccess';
import ViewBlueTv from './Views/ViewBlueTv';
import ViewGeneric from './Views/ViewGeneric';
import ViewFanAccount from './Views/ViewFanAccount';
import ViewEmailUpdate from './Views/ViewEmailUpdate';
import ViewMultiFormat from '@shared/Views/ViewMultiFormat';
import ViewSubscriptions from './Views/ViewSubscriptions';

Vue.use(Vuex);

initSentry('Arena');

initAffil();

window.openModals = [];

// register directives
for (const directive of directives) { // eslint-disable-line
	directive.register();
}

// Globally registered components
Vue.component('SectionTitle', SectionTitle);
Vue.component('V2Button', V2Button);
Vue.component('V2Icon', V2Icon);

// Views
Vue.component('ViewClient', ViewClient);
Vue.component('ViewFederation', ViewFederation);
Vue.component('ViewLeague', ViewLeague);
Vue.component('ViewClub', ViewClub);
Vue.component('ViewTeam', ViewTeam);
Vue.component('ViewPlayer', ViewPlayer);
Vue.component('ViewVenue', ViewVenue);
Vue.component('ViewEvent', ViewEvent);
Vue.component('ViewSports', ViewSports);
Vue.component('ViewAlbum', ViewAlbum);
Vue.component('ViewAlbumEmbed', ViewAlbumEmbed);
Vue.component('ViewAlbumEmbedCarousel', ViewAlbumEmbedCarousel);
Vue.component('ViewSubsite', ViewSubsite);
Vue.component('ViewImprint', ViewImprint);
Vue.component('ViewTerms', ViewTerms);
Vue.component('ViewDeviceSupport', ViewDeviceSupport);
Vue.component('ViewTermsSportpass', ViewTermsSportpass);
Vue.component('ViewCookies', ViewCookies);
Vue.component('ViewLoginError', ViewLoginError);
Vue.component('ViewMatchCenter', ViewMatchCenter);
Vue.component('ViewVideoCenter', ViewVideoCenter);
Vue.component('ViewVideoCenterLeague', ViewVideoCenterLeague);
Vue.component('ViewSwissLeagueInfos', ViewSwissLeagueInfos);
Vue.component('ViewSflInfos', ViewSflInfos);
Vue.component('ViewLogin', ViewLogin);
Vue.component('ViewResetPassword', ViewResetPassword);
Vue.component('ViewLoginSuccess', ViewLoginSuccess);
Vue.component('ViewBlueTv', ViewBlueTv);
Vue.component('ViewGeneric', ViewGeneric);
Vue.component('ViewFanAccount', ViewFanAccount);
Vue.component('ViewEmailUpdate', ViewEmailUpdate);
Vue.component('ViewMultiFormat', ViewMultiFormat);
Vue.component('ViewSubscriptions', ViewSubscriptions);

if (document.querySelector('#arena')) {
	const instance = new Vue({ // eslint-disable-line
		el: '#arena',
		router,
		store,
		mounted() {
			const tvLoadingSpinner = document.querySelector('.js-loading-spinner-tv');
			if (tvLoadingSpinner) {
				tvLoadingSpinner.style.display = 'none';
			}
			if (this.$arenaConfig.loadArenaProfile) {
				store.dispatch('user/loadProfile');
			}

			if (window.$arenaConfig.geoblocking?.geoblockFanaccount) {
				store.dispatch('user/checkArenaGeoblocking');
			}
		},
	});

	if ($Helpers.isSmartTV()) {
		document.body.classList.add('is-smarttv');
		KeyNavigation.init(instance);
	}
}
