// The value is in milliseconds (60s)
export const WS_TIMEOUT = 60000;
// The value is in milliseconds (10s)
export const WS_FALLBACK_INTERVAL = 5000;
export const WS_FALLBACK_NO_OF_RETRIES = 5;
export const WS_REGISTER_DELAY = 500;

export const WS_EVENT = {
	EVENT_UPDATE:     'EventUpdated',
	STREAM_UPDATE:    'StreamUpdated',
	EVENT_TAG_CREATE: 'EventTagCreated',
	EVENT_TAG_UPDATE: 'EventTagUpdated',
	EVENT_TAG_DELETE: 'EventTagDeleted',
	SCORE_UPDATED:    'ScoreUpdated',
};
