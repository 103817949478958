<template>
	<Layout>
		<div class="l-container fan-account">
			<div class="fan-account__header">
				<div class="fan-account__profile-icon">
					<Icon
						icon-name="account"
						size="md"
						size-at-md="xl"
					/>
				</div>
				<div
					v-if="profile"
					class="fan-account__profile-details"
				>
					<div
						v-if="profile.name"
						class="fan-account__profile-name"
					>
						{{ profile.name }}
					</div>
					<div class="fan-account__profile-email">
						{{ profile.email }}
					</div>
				</div>
			</div>
			<div class="l-row">
				<div class="l-col l-col--3@md">
					<InPageNavigation
						:nav-items="navItems"
						:dark-theme="!$arenaConfig.isLightTheme"
						:use-vue-router="true"
					/>
				</div>
				<div class="l-col l-col--9@md">
					<div class="fan-account__content-w">
						<LoadingSpinner
							v-if="isLoading"
							class="fan-account__loading-spinner"
						/>
						<router-view v-else />
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Icon from '@shared/components/Icon';
import InPageNavigation from '@shared/components/InPageNavigation';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import { mapState } from 'vuex';

export default {
	name:       'ViewFanAccount',
	components: { Layout, Icon, InPageNavigation, LoadingSpinner },
	computed:   {
		...mapState({
			profile: state => state.user?.profile,
		}),
		isLoading() {
			return !this.profile;
		},
		navItems() {
			const items = [
				{
					label:  this.$I18n.trans('commons.profile'),
					url:    '/account/profile',
					icon:   'pen',
					active: false,
				},
				{
					label:  this.$I18n.trans('commons.security'),
					url:    '/account/security',
					icon:   'lock2',
					active: false,
				},
				{
					label:  this.$I18n.trans('arena.fan_account.purchases.nav_label'),
					url:    '/account/purchases',
					icon:   'present',
					active: false,
				},
			];

			// Settings currently only contains no spoiler mode. If we'll add more, settings needs to be a default nav item.
			// noSpoilerMode setting is then handled again in the Settings.vue component.
			if (this.$arenaConfig.enableNoSpoilerMode) {
				items.push({
					label:  this.$I18n.trans('commons.settings'),
					url:    '/account/settings',
					icon:   'settings-gear',
					active: false,
				});
			}

			return items;
		},
	},
	created() {
		this.$store.subscribe((mutation) => {
			if (mutation.type === 'user/flushProfile') {
				window.location.href = window.location.origin;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.fan-account {
	margin-top: $sp6;
	margin-bottom: $sp6;
}

.fan-account__header {
	display: flex;
	gap: $sp4;
	align-items: center;
	margin-bottom: $sp7;

	@include size(sm-up) {
		gap: $sp6;
	}
}

.fan-account__profile-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: var(--color-grey-750);
	border: 1px solid var(--color-grey-600);
	color: var(--color-grey-050);
	flex-shrink: 0;

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}

	@include size(sm-up) {
		width: 72px;
		height: 72px;
	}
}

.fan-account__profile-details {
	overflow: hidden;
	flex-grow: 1;
}

.fan-account__profile-name {
	@include font(primary, light, normal, fs-150);
	margin-bottom: $sp2;
}

.fan-account__profile-email {
	@include ellipsis;
	color: var(--color-grey-500);
}

.fan-account__content-w {
	position: relative;
}

.fan-account__loading-spinner {
	position: absolute;
	right: 50%;
	top: 10vh;
	transform: translateX(-50%);
}
</style>
