import { CategoryParent } from '@shared/enums/CategoryParent';
import { CategoryChild } from '@shared/enums/CategoryChild';
import { ParticipantType } from '@shared/enums/ParticipantType';
import { ParticipantsAmount } from '@shared/enums/ParticipantsAmount';
import { EventVisibility } from '@shared/enums/EventVisibility';
import { EventVODStatus } from '@shared/enums/EventVODStatus';
import { EventAccessRestriction } from '@shared/enums/EventAccessRestriction';
import { SportName } from '@shared/enums/SportName';
import { EventType } from '@shared/enums/EventType';

export const isMatch = (event) => {
	if (event.categoryParent === CategoryParent.MATCH) {
		return true;
	}
	if (event.categoryParent === CategoryParent.TRAINING && event.categoryChild === CategoryChild.MATCH_FRIENDLY) {
		return true;
	}
	return false;
};

/**
 * Use isMatch to check if it should have MatchScores
 * @param {*} event
 * @returns {object} MatchScores
 */
export const getMatchScores = (event) => {
	const teamHome = event.participants?.find(participant => participant.type === ParticipantType.HOME)?.participatable;
	const teamGuest = event.participants?.find(participant => participant.type === ParticipantType.GUEST)?.participatable;
	const finalScore = event.finalScore ?? null;

	// Note: Hornussen has some strange score system that's why we don't display it
	// Note: As a first step, we want to show sets only for volleyball events. event.scores is an array with the sets. That's why we return them only for volleyball events.
	return {
		homeName:       teamHome?.club?.code || null,
		homeNameFull:   teamHome?.club?.name || null,
		homeImgUrl:     teamHome?.imageUrl || null,
		homeScore:      event.sport?.name === SportName.HORNUSSEN ? null : (finalScore?.teamHomeScore?.toString() || null),
		guestName:      teamGuest?.club?.code || null,
		guestNameFull:  teamGuest?.club?.name || null,
		guestImgUrl:    teamGuest?.imageUrl || null,
		guestScore:     event.sport?.name === SportName.HORNUSSEN ? null : (finalScore?.teamGuestScore?.toString() || null),
		scores:         event.sport?.name === SportName.VOLLEYBALL ? event.scores?.filter(s => s.period) : [],
		hasOvertime:    event.finalScore?.overtime,
		hasShootout:    event.finalScore?.shootout,
		scoreExtraAbbr: (finalScore?.shootout ? $I18n.trans('commons.shootout_abbr') : (finalScore?.overtime ? $I18n.trans('commons.overtime_abbr') : null)),
	};
};

export const getOwnershipProps = (event) => {
	const ownership = (event.ownerships ?? []).find(ownership => ownership.isOwnContext);
	if (ownership) {
		return {
			id:              ownership.id,
			label:           $I18n.trans(`event.ownership.rights.${ownership.type}`),
			arenaVisibility: ownership.arenaVisibility,
		};
	}
	return null;
};

/**
 * @param {*} event
 * @returns {boolean}
 */
export const isSingleSport = (event) => {
	return event?.sport?.participantsAmount === ParticipantsAmount.MANY;
};

/**
 *
 * @param {*} event
 * @returns {boolean}
 */
export const isTeamSport = (event) => {
	return event?.sport?.participantsAmount !== ParticipantsAmount.MANY || false;
};

/**
 * @param {*} event
 * @returns {?string}
 */
export const getCategoryParent = (event) => {
	if (!(event.categoryParent && event.sport?.name)) {
		return null;
	}
	return $I18n.trans(`sports.${event.sport.name}.categories.parent.${event.categoryParent}`);
};

/**
 * @param {*} event
 * @returns {?string}
 */
export const getCategoryChild = (event) => {
	if (!(event.categoryChild && event.sport?.name)) {
		return null;
	}
	return $I18n.trans(`sports.${event.sport.name}.categories.child.${event.categoryChild}`);
};

/**
 * @param {*} event
 * @returns {?string}
 */
export const getLeagueName = (event) => {
	return event.league?.name || event.participants?.[0]?.participatable?.league?.name || null;
};

/**
 * @param {*} event
 * @returns {?string}
 */
export const getSportName = (event) => {
	if (!event.sport) {
		return null;
	}
	return $I18n.trans(`sports.${event.sport.name}.name`);
};

/**
 * @param {*} event
 * @returns {?string}
 */
export const getLeagueImageWithoutFallback = (event) => {
	const leagueImgUrl = event.league?.imageUrl;
	if (!leagueImgUrl || leagueImgUrl.includes('background_auth')) {
		return null;
	}
	return event.league.imageUrl;
};

/**
 * Hidden is not considered private, since it can still be accessed from the public
 * @param {*} event
 * @returns {boolean}
 */
export const isPrivate = (event) => {
	return event.visibility === EventVisibility.PRIVATE;
};

/**
 * @param {*} event
 * @returns {boolean}
 */
export const isHidden = (event) => {
	return event.visibility === EventVisibility.HIDDEN;
};

export const isPublic = (event) => {
	return event.visibility === EventVisibility.PUBLIC;
};

/**
 * @param {*} event
 * @returns {boolean}
 */
export const isEditorAsset = (event) => {
	return event.sport?.name === SportName.ASSET;
};

/**
 * @param {*} event
 * @returns {boolean}
 */
export const isExtendable = (event) => {
	return !event.venue || event.venue?.systemType?.canExtend || false;
};

/**
 * @param {*} event
 * @returns {boolean}
 */
export const isEndable = (event) => {
	return !event.venue || event.venue?.systemType?.canEndLive || false;
};

/**
 * @param {*} event
 * @returns {?String}
 */
export const getLocation = (event, _options = {}) => {
	const options = {
		withCountry:      false,
		countryOnNewLine: false,
		..._options,
	};
	let formattedName = null;
	const location = event.location || event.venue?.location || null;
	const country = event.location?.country || event.venue?.location?.country || null;

	if (location) {
		if (!location.name) {
			formattedName = location.city;
		} else {
			let parts = location.name.split(',').map(string => string.trim());

			// Remove country
			if (parts.includes(location.country)) {
				parts.splice(parts.indexOf(location.country), 1);
			}

			const street = location.street?.replace(/\s[0-9]*[a-z]{0,10}$/, '') ?? null;

			// Remove street
			if (parts.length > 1) {
				if (parts.includes(street)) {
					parts.splice(parts.indexOf(street), 1);
				} else if (parts.includes(location.street)) {
					parts.splice(parts.indexOf(location.street), 1);
				}
			} else if (parts.length === 1) {
				if (parts[0] === location.street || parts[0] === street) {
					parts = [location.city]; // Name is only street -> use only city
				} else if (location.city && parts[0] !== location.city) {
					parts.push(location.city); // Name is not only city -> append it
				}
			}
			formattedName = parts.join(', ');
		}

		if (country && options.withCountry) {
			formattedName += `, ${options.countryOnNewLine ? '<br>' : ''}${country}`;
		}
	}

	return formattedName;
};

/**
 * @param {*} event
 * @returns {Boolean}
 */
export const vodIsAvailable = (event) => {
	const status = event.streams?.[0]?.vodStatus;
	return status ? status === EventVODStatus.AVAILABLE : false;
};

/**
 * @param {*} event
 * @returns {Boolean}
 */
export const dvrIsAvailable = (event) => {
	// assuming that when streamStartedAt is set, a signal was sent at some point, so there is a dvr
	const status = event.streams?.[0]?.vodStatus;
	return !!event.streams?.[0]?.startedAtTimestamp && [EventVODStatus.INAPPLICABLE, EventVODStatus.RECORDING_STOPPED].includes(status);
};

/**
 * Used for table column titles
 * @param {Array} events
 * @returns {String}
 */
export const getSportTypes = (events) => {
	if (events.every(event => isSingleSport(event))) {
		return 'single';
	} else if (events.every(event => !isSingleSport(event))) {
		return 'team';
	} else {
		return 'mixed';
	}
};

/**
 * Used for event table row info (Leage/Discipline)
 * @param {Object} event
 * @param {String} sportTypes
 * @returns {String}
 */
export const getLeagueOrDisciplineInfo = (event, sportTypes) => {
	if (sportTypes === 'team') {
		return getLeagueName(event) || '-';
	} else if (sportTypes === 'single') {
		return getCategoryChild(event) || '-';
	} else {
		if (isSingleSport(event)) {
			return getCategoryChild(event) || '-';
		} else {
			return getLeagueName(event) || '-';
		}
	}
};

/**
 * Used for event table row title
 * @param {Object} event
 * @returns {String}
 */
export const getFormattedTitle = (event, _options = {}) => {
	const options = {
		noLineBreaksForTeamNames: false,
		..._options,
	};

	if (isMatch(event) && event.participants?.length === 2 && event.name?.includes(' - ')) {
		if (options.noLineBreaksForTeamNames) {
			const names = event.name.split(' - ');
			return `${names[0]} - <span style="white-space: nowrap;">${names[1]}</span>`;
		}
		return event.name.replace(' - ', ' - <br>');
	}
	return event.name || $I18n.trans('commons.unnamed');
};

export const EVENT_STATUS = {
	UPCOMING: 'upcoming',
	LIVE:     'live',
	REPLAY:   'replay',
};

/**
 * @param {string} eventType
 * @param {number} unixStart
 * @param {number} unixEnd
 * @param {?number} unixNow
 * @returns {string}
 */
export const getEventStatus = (eventType, unixStart, unixEnd, _unixNow = null) => {
	const unixNow = _unixNow || Math.round(new Date().getTime() / 1000);

	if (eventType !== EventType.EVENT || unixEnd < unixNow) {
		return EVENT_STATUS.REPLAY;
	}
	if (unixStart <= unixNow && unixNow <= unixEnd) {
		return EVENT_STATUS.LIVE;
	}
	return EVENT_STATUS.UPCOMING;
};

export const isPPV = (event) => {
	return Array.isArray(event.accessRestriction) && event.accessRestriction.includes(EventAccessRestriction.PAYMENT);
};
