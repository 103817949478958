import { LEAVE_CHANNEL, START_CHANNEL_LISTENING } from './mutation-types';
import ws from '@shared/js/websocket/websocket-client';

export default {
	startListening({ commit }, { channel, event, callback }) {
		ws.subscribe({ channel, event, callback });
		commit(START_CHANNEL_LISTENING, { channelName: channel, eventName: event });
	},
	leaveChannel({ commit }, { channel }) {
		ws.unsubscribe(channel);
		commit(LEAVE_CHANNEL, { channelName: channel });
	},
};
