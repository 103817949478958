<template>
	<div class="l-container l-container--full-width l-container--full-width@mobile">
		<Carousel :cells="album.events">
			<template #cell="{cell}">
				<EventCard
					v-bind="EventCardProps(cell, { showEventTags: true, large: false, hidePaymentRestriction: false, openInNewTab: true })"
				/>
			</template>
		</Carousel>
	</div>
</template>

<script>
import Carousel from '@js/components/Carousel';
import { EventCardProps } from '@shared/components/EventCard/EventCardProps';
import EventCard from '@shared/components/EventCard/EventCard';

export default {
	name:       'ViewAlbumEmbedCarousel',
	components: { Carousel, EventCard },
	props:      {
		album: {
			type:     Object,
			required: true,
		},
	},
	data() {
		return {
			EventCardProps,
		};
	},
};
</script>

<style>

</style>
