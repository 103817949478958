<template>
	<Layout>
		<div class="l-container">
			<div class="l-row l-align-center">
				<div class="arena-message">
					<Icon
						:icon-name="error ? 'sad' : 'fireworks'"
						size="xxxl"
						class="arena-message__icon"
					/>
					<!-- eslint-disable vue/no-v-html -->
					<template v-if="error">
						<SectionTitle :title="$I18n.trans('commons.error')" />
						<div v-html="$I18n.trans('notifications.verify_email.error_message')" />
					</template>
					<template v-else>
						<SectionTitle :title="error ? $I18n.trans('commons.error') : $I18n.trans('commons.type_updated', {type: $I18n.trans('commons.email')})" />
						<div v-html="$I18n.trans('notifications.verify_email.success_message', {email: email})" />
					</template>
					<!-- eslint-enable -->
					<Button
						:text="$I18n.trans('commons.continue')"
						theme="dark"
						class="arena-message__button"
						link="/"
					/>
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Button from '@shared/components/Button';
import Icon from '@shared/components/Icon';
import SectionTitle from '@js/components/SectionTitle';

export default {
	name:       'ViewEmailUpdate',
	components: { Layout, Button, Icon, SectionTitle },
	props:      {
		error: {
			type:    String,
			default: null,
		},
		email: {
			type:    String,
			default: null,
		},
	},
};
</script>

<style>

</style>
