<template>
	<div
		class="c-sponsors-stv"
		:class="{
			'is-hidden-on-event': !$arenaConfig.showFooterOnEvent,
		}"
	>
		<div class="l-container">
			<div class="c-sponsors-stv__section -level-1">
				<h2 class="c-sponsors-stv__title">
					{{ $I18n.trans('arena.stv_fsg_sponsors.platin_partner') }}
				</h2>
				<div class="c-sponsors-stv__logo-row">
					<div class="c-sponsors-stv__logo-box-outter">
						<div class="c-sponsors-stv__logo-box">
							<div class="c-sponsors-stv__logo-w">
								<a
									:href="$I18n.trans('arena.stv_fsg_sponsors.link_coop')"
									target="_blank"
									rel="noopener"
									class="c-sponsors-stv__logo-link"
								>
									<img
										src="/images/stv-fsg/sponsors/coop.svg"
										class="c-sponsors-stv__logo"
										alt="Coop"
									>
								</a>
							</div>
						</div>
					</div>
					<div class="c-sponsors-stv__logo-box-outter">
						<div class="c-sponsors-stv__logo-box">
							<div class="c-sponsors-stv__logo-w">
								<a
									:href="$I18n.trans('arena.stv_fsg_sponsors.link_concordia')"
									target="_blank"
									rel="noopener"
									class="c-sponsors-stv__logo-link"
								>
									<img
										src="/images/stv-fsg/sponsors/concordia.svg"
										class="c-sponsors-stv__logo"
										alt="Concordia"
									>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="c-sponsors-stv__section -level-2">
				<h2 class="c-sponsors-stv__title">
					{{ $I18n.trans('arena.stv_fsg_sponsors.gold_partner') }}
				</h2>
				<div class="c-sponsors-stv__logo-row">
					<div class="c-sponsors-stv__logo-box-outter">
						<div class="c-sponsors-stv__logo-box">
							<div class="c-sponsors-stv__logo-w">
								<a
									:href="$I18n.trans('arena.stv_fsg_sponsors.link_ochsner')"
									target="_blank"
									rel="noopener"
									class="c-sponsors-stv__logo-link"
								>
									<img
										src="/images/stv-fsg/sponsors/ochsner.svg"
										class="c-sponsors-stv__logo"
										alt="Ochsner Sport"
									>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'SponsorsStv',
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.c-sponsors-stv {
	background-color: var(--color-grey-100);
	padding-top: $sp7;
	padding-bottom: $sp3;
	margin-top: $sp5;

	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		background-color: var(--color-grey-950);
	}

	&.is-hidden-on-event {
		body.is-player-mode & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			display: none;
		}
	}
}

.c-sponsors-stv__section {
	margin-bottom: $sp6;

	// &.-level-1 {}
	// &.-level-2 {}
}

.c-sponsors-stv__title {
	@include font-size(fs-80);
	padding-left: $sp3;
	margin-bottom: $sp4;

	@include size(md-up) {
		padding-left: $sp3-5;
	}
}

.c-sponsors-stv__logo-row {
	display: flex;
	flex-wrap: wrap;
	gap: $sp5;
}

.c-sponsors-stv__logo-box-outter {
	position: relative;

	&::after {
		content: '';
		position: absolute;
		bottom: -8%;
		left: 4%;
		width: 100%;
		height: 100%;
		z-index: 1;
		opacity: 0.4;
		mix-blend-mode: multiply;
		background-blend-mode: multiply;
		background: linear-gradient(40deg, #E3E3E3 0%, rgba(227, 227, 227, 0) 100%);
		transform-origin: left bottom;
		transform: rotate(4deg) skew(-14deg, 0deg);
		transition: left 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), bottom 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95), opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	}

	&:hover {
		&::after {
			bottom: -4%;
			left: 0;
			opacity: 1;
		}
	}
}

.c-sponsors-stv__logo-box {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 2;
	padding: $sp2-5 $sp4-5;
	background-color: var(--color-white);
	transform-origin: center;
	transform: skew(-14deg, 0deg);
}

.c-sponsors-stv__logo-w {
	transform-origin: center;
	transform: skew(14deg, 0deg);
}

// .c-sponsors-stv__logo-link {}

.c-sponsors-stv__logo {
	.c-sponsors-stv__section.-level-1 & {
		width: 120px;

		@include size(md-up) {
			width: 140px;
		}
	}

	.c-sponsors-stv__section.-level-2 & {
		width: 75px;

		@include size(md-up) {
			width: 120px;
		}
	}
}
</style>
