<template>
	<div
		class="info"
		:class="{'is-active': active}"
	>
		<span>{{ $I18n.trans('view_mode.product_placement_info') }}</span>
	</div>
</template>

<script>
const DISPLAY_DURATION = 5000;

export default {
	name:  'ProductPlacementInfo',
	props: {
		isPlaying: {
			type:    Boolean,
			default: false,
		},
		isLive: {
			type:    Boolean,
			default: false,
		},
		duration: {
			type:    Number,
			default: 0,
		},
		elapsed: {
			type:    Number,
			default: 0,
		},
		onlyShowAtBeginning: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			active:            false,
			playedAtBeginning: false,
			playedAtEnd:       false,
		};
	},
	watch: {
		// showInfo at the beginning
		isPlaying(isPlaying) {
			if (isPlaying) {
				this.handleInitialPlaying();
			}
		},
		elapsed() {
			if (this.onlyShowAtBeginning) {
				return;
			}
			// When live, event ends before entire stream was shown due to buffer.
			// To compoensate that, we show the info a bit earlier.
			const offset = (this.isLive ? 22 : 5) + (DISPLAY_DURATION / 1000);
			if (this.duration - this.elapsed < offset && this.isPlaying && !this.playedAtEnd) {
				this.showInfo();
				this.playedAtEnd = true;
			}
		},
	},
	mounted() {
		this.handleInitialPlaying();
	},
	methods: {
		handleInitialPlaying() {
			if (this.isPlaying && !this.playedAtBeginning) {
				this.showInfo();
				this.playedAtBeginning = true;
			}
		},
		showInfo() {
			this.active = true;
			setTimeout(() => {
				this.active = false;
			}, DISPLAY_DURATION);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.info {
	display: flex;
	align-items: center;
	border-radius: 20px; // stylelint-disable-line sh-waqar/declaration-use-variable
	padding: 12px;
	background-color: var(--color-soft-white);
	color: var(--color-shadow-grey);
	font-weight: 500; // stylelint-disable-line declaration-property-value-allowed-list, property-disallowed-list
	font-size: 16px; // stylelint-disable-line declaration-property-value-allowed-list, sh-waqar/declaration-use-variable, property-disallowed-list
	max-width: 100%;
	white-space: nowrap;
	overflow: hidden;
	opacity: 0;
	transition: opacity 0.5s cubic-bezier(0, 0, 0, 1), max-width 0.5s cubic-bezier(0, 0, 0, 1);
	pointer-events: none;

	&.is-active {
		opacity: 1;
	}
}
</style>
