<template>
	<Layout>
		<div class="l-container">
			<VideoCenter
				:context-filter="contextFilter"
				:enable-filter-sport="$arenaConfig.enableSportsFilterInVideoCenter"
				:enable-filter-date="$arenaConfig.enableDateFilterInVideoCenter"
				:sports="sports"
				:content-period="contentPeriod"
			/>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import VideoCenter from '@js/components/VideoCenter.vue';

export default {
	name:       'ViewVideoCenter',
	components: { Layout, VideoCenter },
	props:      {
		contextFilter: {
			type:     Object,
			required: true,
		},
		sports: {
			type:    Array,
			default: () => [],
		},
		contentPeriod: {
			type:    Array,
			default: () => [],
		},
	},
};
</script>

<style>

</style>
