<template>
	<div
		class="nav-mobile"
		:class="{'is-open': isOpen, 'child-is-open': openItemId !== null}"
	>
		<div class="nav-mobile__header">
			<div
				v-if="mobileNavOpenItemIds.length"
				class="nav-mobile__back"
				@click="handleNavMobileBackClick"
			>
				<V2Icon
					icon-name="arrow-left"
					size="lg"
				/>
				<span>{{ $Helpers.ucfirst($I18n.trans('commons.back')) }}</span>
			</div>
			<div
				class="nav-mobile__toggle"
				@click="closeNavMobile"
			>
				<V2Icon
					icon-name="e-remove"
					size="lg"
				/>
			</div>
		</div>
		<div class="nav-mobile__content">
			<a
				v-for="item in contextNavItems.filter(i => !i.hidden)"
				:key="item.link"
				:href="item.link"
				:target="mixinIsExternalLink(item.link) ? '_blank' : '_self'"
				:rel="mixinIsExternalLink(item.link) ? 'noopener' : ''"
				class="nav-mobile__item"
				:class="{'is-active': item.isActive}"
			>
				<span>{{ item.text }}</span>
				<V2Icon
					v-if="mixinIsExternalLink(item.link)"
					icon-name="launch"
					size="xxs"
				/>
			</a>
			<a
				v-for="item in topNavItems"
				:key="item.link"
				:href="item.link"
				:target="mixinIsExternalLink(item.link) ? '_blank' : '_self'"
				:rel="mixinIsExternalLink(item.link) ? 'noopener' : ''"
				class="nav-mobile__item"
				:class="{'is-active': item.isActive}"
			>
				<span>{{ item.title }}</span>
				<V2Icon
					v-if="mixinIsExternalLink(item.link)"
					icon-name="launch"
					size="xxs"
				/>
			</a>
			<NavMobileChild
				ref="navMobileChild"
				:sub-nav-items="subNavItems"
				@open-child="(id) => handleNavMobileOpenChild(id)"
			/>
		</div>
	</div>
</template>

<script>
import V2Icon from '@shared/components/Icon';
import NavMobileChild from '@js/components/NavMobileChild.vue';

export default {
	name:       'NavMobile',
	components: { V2Icon, NavMobileChild },
	props:      {
		isOpen: {
			type:    Boolean,
			default: false,
		},
		contextNavItems: {
			type:    Array,
			default: () => [],
		},
		topNavItems: {
			type:    Array,
			default: () => [],
		},
		subNavItems: {
			type:    Array,
			default: () => [],
		},
	},
	data() {
		return {
			openItemId:           null,
			mobileNavOpenItemIds: [],
		};
	},
	watch: {
		isOpen(val) {
			this.handleOpen(val);
		},
	},
	mounted() {
		window.eventBus.$on('mobileNav:close-child', (data) => {
			if (data.id === this.openItemId) {
				this.openItemId = null;
			}
		});
	},
	methods: {
		handleOpen(isOpen) {
			if (isOpen) {
				document.body.style.overflow = 'hidden';
			} else {
				document.body.style.overflow = '';
			}
		},
		closeNavMobile(item) {
			this.$emit('close-nav-mobile', item);
		},
		handleNavMobileOpenChild(id) {
			if (!this.mobileNavOpenItemIds.includes(id)) {
				this.mobileNavOpenItemIds.push(id);
			}
		},
		handleNavMobileBackClick() {
			const id = this.mobileNavOpenItemIds.pop();
			window.eventBus.$emit('mobileNav:close-child', { id });
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.nav-mobile {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	transform: translateX(100%);
	transition: transform $trans-time-fast;
	color: var(--color-white);
	background-color: var(--color-black);

	&.is-open {
		transform: translateX(0%);
	}

	&.child-is-open {
		transform: translateX(-20%);
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		color: var(--color-black);
	}
}

.nav-mobile__header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: $sp7-5;
	background-color: var(--color-black);
	padding: $sp4;
	z-index: 2;

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}
}

.nav-mobile__content {
	position: fixed;
	left: 0;
	top: $sp7-5;
	width: 100%;
	height: calc(100vh - #{$sp7-5});
	background-color: var(--color-black);
	overflow-y: scroll;
	overflow-x: hidden;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 10vh;

	// &.is-open {}
	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		background-color: var(--color-white);
	}
}

// .nav-mobile__toggle {}

.nav-mobile__item {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 0 $sp4;
	padding: $sp4 0;
	font-size: 16px; // stylelint-disable-line property-disallowed-list, sh-waqar/declaration-use-variable
	border-bottom: 1px solid var(--color-grey-900);
	cursor: pointer;
}

.nav-mobile__child {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--color-black);
	transform: translateX(100%);
	transition: transform $trans-time-fast;

	&.is-open {
		transform: translateX(20%);
		z-index: 1;
	}
}

.nav-mobile__back {
	display: flex;
	flex-grow: 1;
	align-items: center;
	padding: $sp2 0;
	gap: 0 $sp4;
	font-size: 16px; // stylelint-disable-line property-disallowed-list, sh-waqar/declaration-use-variable
}
</style>
