import * as Sentry from '@sentry/vue';

class LoggingService {
	constructor() {
		this.isLocalEnvironment = import.meta.env.DEV;
	}

	/**
	 *
	 * @param {string} level: "fatal", "error", "warning", "log", "info", and "debug"
	 * @param {string|Error} errorOrMessage
	 * @param {Object} data: data.context for additional context data like user info, etc. / Use data.scope for information about where the error occurred
	 */
	logToSentry(level, errorOrMessage, data = {}) {
		let error;
		if (errorOrMessage instanceof Error) {
			error = errorOrMessage;
		} else {
			error = new Error(errorOrMessage);
		}

		if (this.isLocalEnvironment) {
			switch (level) {
			case 'fatal':
				console.error('Error:', error, data);
				break;
			case 'error':
				console.error('Error:', error, data);
				break;
			case 'warning':
				console.warn('Warning:', error, data);
				break;
			case 'info':
				console.info('Info:', error, data);
				break;
			default:
				console.log('Log:', error, data);
			}
		} else {
			Sentry.withScope((scope) => {
				scope.setLevel(level);
				if (data.context) {
					scope.setContext('Asport Custom Context', data.context);
				}
				scope.setTag('asport_scope', data.scope || 'unknown');
				Sentry.captureException(error);
			});
		}
	}

	logFatal(errorOrMessage, data = {}) {
		this.logToSentry('fatal', errorOrMessage, data);
	}

	logError(errorOrMessage, data = {}) {
		this.logToSentry('error', errorOrMessage, data);
	}

	logWarning(message, data = {}) {
		this.logToSentry('warning', message, data);
	}

	logInfo(message, data = {}) {
		this.logToSentry('info', message, data);
	}
}

export default LoggingService;
