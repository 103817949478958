<template>
	<Modal
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		class="arena-login-modal"
		modal-name="LoginModal"
		:closeable="!isLoginPage"
		@opened="onOpen"
	>
		<template #title>
			{{ $I18n.trans('arena.login.login.title') }}
		</template>
		<template #content>
			<div
				v-if="!isLoggedIn"
				class="arena-login-modal__content"
			>
				<div
					v-if="!submitted"
					class="arena-login-modal__head"
				>
					<h2 class="arena-login-modal__title">
						{{ $I18n.trans('arena.login.login.headline') }}
					</h2>
					<span class="arena-login-modal__subtitle">{{ $I18n.trans('arena.login.login.subheadline') }}</span>
				</div>

				<LoginComponent
					@submitted="onSubmit"
					@reset="onReset"
				/>
			</div>
			<div
				v-else
				class="arena-login-modal__content"
			>
				<div
					class="arena-login-modal__head"
				>
					<h2 class="arena-login-modal__title is-logged-in">
						{{ $I18n.trans('arena.login.login.already_authenticated') }}
					</h2>
					<div>
						<V2Icon
							icon-name="fireworks"
							size="xxxl"
							class="arena-login__info-icon"
						/>
					</div>
					<!-- eslint-disable vue/no-v-html -->
					<span
						class="arena-login-modal__subtitle"
						v-html="$I18n.trans('arena.login.login.already_authenticated_msg', { 'email': profile.email })"
					/>
					<div class="arena-login-modal__action-buttons">
						<Button
							class="arena-login-modal__action-button"
							:text="$Helpers.ucfirst($I18n.trans('commons.logout'))"
							:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							modifier="secondary"
							@click="signOut"
						/>
						<Button
							class="arena-login-modal__action-button"
							:text="$Helpers.ucfirst($I18n.trans('commons.continue'))"
							:theme="$arenaConfig.isLightTheme ? null : 'dark'"
							@click="toFrontpage"
						/>
					</div>
				</div>
			</div>
		</template>
	</Modal>
</template>

<script>
import LoginComponent from '@js/components/Login/LoginComponent';
import Modal from '@shared/components/Modal';
import Button from '@shared/components/Button';
import { mapGetters, mapActions } from 'vuex';

export default {
	name:       'LoginModal',
	components: { Modal, LoginComponent, Button },
	data() {
		return {
			isLoginPage: window.location.pathname.includes('login'),
			submitted:   false,
			isOpen:      false,
		};
	},
	computed: {
		...mapGetters({
			isLoggedIn:           'user/isLoggedIn',
			profile:              'user/profile',
			fanAccountGeoblocked: 'user/fanAccountGeoblocked',
		}),
	},
	watch: {
		fanAccountGeoblocked() {
			if (this.isOpen && this.fanAccountGeoblocked) {
				window.eventBus.$emit('modal:GeoblockingModal');
			}
		},
	},
	mounted() {
		if (document.querySelector('input[name="trigger-login-modal"]') !== null) {
			window.eventBus.$emit('modal:LoginModal');
		}
	},
	methods: {
		...mapActions({
			logout: 'user/logout',
		}),
		onOpen() {
			if (this.fanAccountGeoblocked) {
				window.eventBus.$emit('modal:GeoblockingModal');
			}

			this.isOpen = true;
		},
		async signOut() {
			this.logout();
		},
		toFrontpage() {
			window.location.href = '/';
		},
		onSubmit: function() {
			this.submitted = true;
		},
		onReset: function() {
			this.submitted = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.arena-login-modal__content {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}

.arena-login-modal__head {
	text-align: center;
	margin-bottom: $sp6;
}

.arena-login-modal__title {
	@include font(primary, light, normal, fs-150);
	margin-top: $sp6;
	margin-bottom: $sp3;

	&.is-logged-in {
		margin-bottom: $sp6;
	}
}

.arena-login-modal__subtitle {
	color: var(--color-grey-300);
}

.arena-login__info-icon {
	margin-bottom: 30px;
}

.arena-login-modal__action-buttons {
	margin-top: 30px;
}

.arena-login-modal__action-button {
	margin: 0 5px;
}
</style>
