<template>
	<div class="fan-at-header">
		<div class="fan-at-header__inner-w">
			<a
				href="https://fan.at/"
				class="fan-at-header__logo-w"
			>
				<img
					class="fan-at-header__logo"
					src="/images/fan-at/logo.svg"
					alt="Fan.at"
				>
			</a>
			<div class="fan-at-header__links">
				<a
					class="fan-at-header__link"
					href="https://fan.at/videos/uebersicht"
				>
					<img
						src="/images/fan-at/icons/soccer.svg"
						class="fan-at-header__link-logo"
						alt="Fussball"
					>
					Fussball
				</a>
				<span class="fan-at-header__link -active">
					<img
						src="/images/fan-at/icons/handball.svg"
						class="fan-at-header__link-logo"
						alt="Handball"
					>
					Handball
				</span>
			</div>

			<ContextMenu
				class="fan-at-header__context-menu"
				:arrow="false"
				align="bottom-left"
			>
				<template #toggle>
					<span class="fan-at-header__context-menu-toggler">
						<img
							src="/images/fan-at/icons/handball.svg"
							class="fan-at-header__link-logo -mobile"
							alt="Handball"
						>
						<span>Handball</span>
						<svg>
							<use href="#sprite-down-arrow" />
						</svg>
					</span>
				</template>
				<template #context-menu>
					<div class="fan-at-header__links-mobile">
						<a
							class="fan-at-header__link-mobile"
							href="https://fan.at/videos/uebersicht"
						>
							<img
								src="/images/fan-at/icons/soccer.svg"
								class="fan-at-header__link-mobile-logo"
								alt="Fussball"
							>
							Fussball
						</a>
						<span class="fan-at-header__link-mobile -active">
							<img
								src="/images/fan-at/icons/handball.svg"
								class="fan-at-header__link-mobile-logo"
								alt="Handball"
							>
							Handball
						</span>
					</div>
				</template>
			</ContextMenu>

			<div class="fan-at-header__league-logos">
				<img
					src="/images/fan-at/hla-challenge.png"
					class="fan-at-header__league-logo"
					alt="HLA Challenge"
				>
				<img
					src="/images/fan-at/hla-meisterliga.png"
					class="fan-at-header__league-logo"
					alt="HLA Meisterliga"
				>
			</div>
			<div class="fan-at-header__actions">
				<a
					href="https://reporter.fan.at/"
					class="fan-at-header__action"
				>
					<img
						src="/images/fan-at/icons/mic.svg"
						class="fan-at-header__action-logo"
						alt="Reporter"
					>
					Reporter
				</a>
				<a
					href="https://fan.at/"
					class="fan-at-header__action"
				>
					<img
						src="/images/fan-at/icons/person.svg"
						class="fan-at-header__action-logo"
						alt="Login"
					>
					Login
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import ContextMenu from '@shared/components/ContextMenu';

export default {
	name:       'HeaderFanAt',
	components: { ContextMenu },
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

/* stylelint-disable */

.fan-at-header {
	@include z-index(arena-header);

	position: relative;
	background-color: var(--color-hype-yellow);
	color: var(--color-white);
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	border-bottom: 1px solid rgba(255,255,255,.2);
	color: #19132F;
	width: 100%;

	@include mobile-media-query {
		body.is-player-mode & {
			display: none;
		}
	}
}

.fan-at-header__inner-w {
	height: 60px;
	display: flex;
	align-items: center;
	position: relative;
	max-width: 960px!important;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;

	@media only screen and (min-width: 992px) {
		margin-left: 50px;
		height: 72px;
	}

	@media only screen and (min-width: 1650px) {
		margin-left: auto;
	}
}

.fan-at-header__logo-w {
    position: absolute;
    left: 50%;
    transform: translate(-50%);

	@media only screen and (min-width: 768px) {
		position: relative;
		transform: none;
		left: auto;
	}
}

.fan-at-header__logo {
	height: 24px;

	@media only screen and (min-width: 768px) {
		height: 32px;
	}

	@media only screen and (min-width: 992px) {
		height: 44px;
	}
}

// NAV DESKTOP
.fan-at-header__links {
	display: none;

	@media only screen and (min-width: 768px) {
		display: flex;
		gap: 10px;
		margin-left: 30px;
		color: var(--color-white);
	}
}

.fan-at-header__link {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px 10px;
	gap: 5px;
	border-radius: 30px;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12.5px;
	letter-spacing: -0.01em;

	&.-active {
		background-color: #19132F;
	}
}

.fan-at-header__link-logo {
	width: 20px;
}

// NAV MOBILE
.fan-at-header__context-menu {

	@media only screen and (min-width: 768px) {
		display: none !important;
	}

}

::v-deep {
	.v2-context-menu__body {
		background-color: transparent !important;
	}
	.v2-context-menu__body-inner {
		background-color: transparent !important;
	}
}

.fan-at-header__context-menu-toggler {
	color: var(--color-white);
	text-transform: uppercase;
	font-weight: 800;
	font-weight: 750;
	font-size: 14.2px;
	display: flex;
	align-items: center;
	gap: 5px;

	svg {
		width: 12px;
		height: 12px;
		stroke-width: 3px;
	}
}

.fan-at-header__links-mobile {
    padding-top: 8px;
    padding-bottom: 8px;
	background-color: #302b44;
	color: var(--color-white);
	min-width: 150px;
	border-radius: 4px;
}

.fan-at-header__link-mobile {
    color: var(--color-white);
    background: transparent;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    white-space: nowrap;
    display: block;
    line-height: 48px;
    height: 48px;
    padding: 0 16px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    text-decoration: none;
	text-transform: uppercase;
    max-width: 100%;
    position: relative;
}

.fan-at-header__link-mobile-logo {
	margin-right: 16px;
}

// LEAGUE LOGOS
.fan-at-header__league-logos {
	display: none;

	@media only screen and (min-width: 768px) {
		display: flex;
		gap: 10px;
		align-items: center;
		margin-left: auto;
	}
}

.fan-at-header__league-logo {
	width: 46px;
}

.fan-at-header__actions {
	display: flex;
	align-items: center;
	margin-left: auto;
	height: 100%;
}
.fan-at-header__action {
	color: var(--color-white);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border: none;
	text-decoration: none;
	height: 100%;
	margin-left: 8px;
	background-color: transparent;
	padding: 0;
	font-weight: 700;
	font-size: 10.2px;
	letter-spacing: -0.01em;
	line-height: 1.2;

	@media only screen and (min-width: 992px) {
		min-width: 54px;
		font-size: 11.2px;
	}
}

.fan-at-header__action-logo {
	width: 20px;

	@media only screen and (min-width: 992px) {
		width: 24px;
	}
}

</style>
