import { ArenaLaneType } from '@shared/enums/ArenaLaneType';
import { AdType } from '@shared/enums/AdType';
import laroute from '@laroute';

export const laneMixer = (albums, arenaLanes, store, ads = null, _options = {}) => {
	const options = {
		moreLinks: null,
		..._options,
	};

	const mixedLanes = arenaLanes.map(arenaLane => {
		switch (arenaLane.lane_type) {
		case (ArenaLaneType.NAVIGATION): {
			return arenaLane;
		}
		case (ArenaLaneType.STORIES): {
			return arenaLane;
		}
		case (ArenaLaneType.SUBSITE): {
			return {
				storeId:  `subsite_${arenaLane.subsite_id}`,
				title:    arenaLane.name,
				subtitle: '', // empty string so more link gets displayed
				moreLink: laroute.route('arena.subsite', { subsite: arenaLane.subsite?.path }),
				...arenaLane,
			};
		}
		case (ArenaLaneType.NAVIGATION_SPORTS): {
			return arenaLane;
		}
		case (ArenaLaneType.RECOMMENDATIONS): {
			return {
				storeId:  'recommended',
				albumId:  null,
				title:    $I18n.trans_choice('commons.recommendation', 2),
				subtitle: null,
				moreLink: null,
				...arenaLane,
			};
		}
		case (ArenaLaneType.LIVE_AND_UPCOMING): {
			return {
				storeId:  'liveAndUpcoming',
				albumId:  null,
				title:    $I18n.trans('commons.live_and_upcoming'),
				moreLink: options.moreLinks?.upcoming,
				...arenaLane,
			};
		}
		case (ArenaLaneType.REPLAYS): {
			return {
				storeId:  'ended',
				albumId:  null,
				title:    $I18n.trans_choice('commons.replay', 2),
				moreLink: options.moreLinks?.replays,
				...arenaLane,
			};
		}
		case (ArenaLaneType.ALBUM): {
			const album = albums.find(album => album.id === arenaLane.album_id);
			return {
				storeId:  `album_${arenaLane.album_id}`,
				title:    arenaLane.name,
				subtitle: album.subtitle_translations || album.subtitle,
				albumId:  arenaLane.album_id,
				...album,
				...arenaLane,
			};
		}
		case (ArenaLaneType.SPORT): {
			const album = albums.find(album => album.id === arenaLane.album_id);
			return {
				storeId:  `album_${arenaLane.album_id}`,
				title:    album.name_translations || album.name,
				subtitle: album.subtitle_translations || album.subtitle,
				albumId:  arenaLane.album_id,
				moreLink: album.link || null,
				...album,
				...arenaLane,
			};
		}
		case (ArenaLaneType.VIDEOS): {
			return {
				storeId:  'videos',
				albumId:  null,
				title:    $I18n.trans_choice('commons.video', 2),
				subtitle: null,
				moreLink: null,
				...arenaLane,
			};
		}
		default: {
			return arenaLane;
		}
		}
	});

	// Ad injection
	let adNormalIncludedIndex = 0;
	let adHighlightedIncluded = false;
	let adPosition = 2; // 2 = Event | AD | Event  /  3 = Event | Event | AD  /  ...
	const displayAdsNormal = ads?.normal?.filter(ad => ad.type === AdType.DISPLAY_AD) ?? [];
	const normalAdsShuffled = displayAdsNormal
		.map(value => ({ value, sort: Math.random() }))
		.sort((a, b) => a.sort - b.sort)
		.map(({ value }) => value);

	return mixedLanes.map(lane => {
		// add ads to event lanes
		if (lane.storeId && store.state.events[lane.storeId]) {
			const laneEventsCount = store.state.events[lane.storeId].items.length;

			if (lane.display_ad) {
				lane.ad = {
					...lane.display_ad,
					position: 2,
				};
			} else if (lane.highlighted && ads.highlighted && !adHighlightedIncluded) {
				lane.ad = {
					...ads.highlighted,
					position: 2,
				};
				adHighlightedIncluded = true;
			} else if (normalAdsShuffled.length > adNormalIncludedIndex && laneEventsCount > 0) {
				lane.ad = {
					...displayAdsNormal[adNormalIncludedIndex],
					position: adPosition > (laneEventsCount + 1) ? laneEventsCount + 1 : adPosition,
				};
				adNormalIncludedIndex += 1;
				adPosition = adPosition === 4 ? 2 : adPosition + 1;
			}
		}
		return lane;
	});
};
