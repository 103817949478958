<template>
	<div
		class="steps-indicator"
		:class="{'-t-arena': arenaTheme}"
	>
		<div
			v-for="(step, key) in steps"
			:key="key"
			class="steps-indicator__item"
			:class="{'is-active': activeStep === key}"
		>
			<div class="steps-indicator__item-inner-w">
				<div class="steps-indicator__item-counter">
					{{ key + 1 }}
				</div>
				<div class="steps-indicator__item-title">
					{{ step }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:  'StepsIndicator',
	props: {
		activeStep: {
			type:    Number,
			default: 0,
		},
		steps: {
			type:     Array,
			required: true,
		},
		arenaTheme: {
			type:    Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

$circle-size: 32px;

.steps-indicator {
	display: flex;
	color: var(--color-shadow-grey);

	&.-t-arena {
		color: var(--color-soft-white);

		body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			color: var(--color-black);
		}
	}
}

.steps-indicator__item {
	@include font(primary, light, normal, fs-80);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: $sp4;
	padding-right: $sp4;

	// Invisible Placeholder so we can position the after pseudo element absolute
	&:not(:first-child) {
		&::before {
			content: '';
			width: 60px;
		}
	}

	&:not(:last-child) {
		&::after {
			position: absolute;
			left: 100%;
			top: calc($circle-size / 2);
			content: '';
			width: 60px;
			height: 1px;
			background-color: var(--color-grey-600);
			display: block;
		}
	}

	&:first-child {
		padding-left: 0;
	}

	&:last-child {
		padding-right: 0;
	}

	@include size(sm-up) {
		&:not(:first-child) {
			&::before {
				width: 104px;
			}
		}

		&:not(:last-child) {
			&::after {
				width: 104px;
			}
		}
	}
}

.steps-indicator__item-inner-w {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.steps-indicator__item-counter {
	@include font(primary, medium, normal, fs-80);
	width: $circle-size;
	height: $circle-size;
	background-color: var(--color-grey-100);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: $sp3;

	.steps-indicator.-t-arena & {
		background-color: var(--color-grey-800);
	}

	.steps-indicator__item.is-active & {
		background-color: var(--color-green-petrol);
		color: var(--color-soft-white);

		.steps-indicator.-t-arena & {
			background-color: var(--color-hype-yellow);
			color: var(--color-text-on-hype-yellow);
		}
	}

}

// .steps-indicator__item-title {}

</style>
