<template>
	<div class="security">
		<LoadingSpinner
			v-if="isLoading"
			class="security__loading-spinner"
		/>
		<template v-else>
			<!-- PASSWORD UPDATE -->
			<div class="security__section">
				<SectionTitle
					:title="$I18n.trans('commons.type_edit', {type: $I18n.trans('commons.password')})"
					type="md"
				/>
				<Password
					:pw-is-set="pwIsSet"
					@password-set="pwIsSet = true"
				/>
			</div>

			<!-- E-MAIL UPDATE -->
			<div class="security__section">
				<SectionTitle
					:title="$I18n.trans('commons.type_edit', {type: $I18n.trans('commons.email')})"
					type="md"
				/>
				<Email :pw-is-set="pwIsSet" />
			</div>
		</template>
	</div>
</template>

<script>
import Password from './partials/Password';
import Email from './partials/Email';
import SectionTitle from '@js/components/SectionTitle';
import LoadingSpinner from '@shared/components/LoadingSpinner';
import laroute from '@laroute';
import { authApi } from '@js/api/client';
import { mapState } from 'vuex';

export default {
	name:       'Security',
	components: { Password, Email, SectionTitle, LoadingSpinner },
	data() {
		return {
			isLoading: true,
			pwIsSet:   false,
		};
	},
	computed: {
		...mapState({
			profile: state => state.user.profile,
		}),
	},
	created() {
		this.checkIfPwIsSet();
	},
	methods: {
		async checkIfPwIsSet() {
			try {
				this.isLoading = true;
				const url = laroute.route('arena.api.arena_login.check_account_status', {
					email: encodeURIComponent(this.profile.email),
				});
				const response = await authApi.post(url);
				const responseContent = await response.json();
				this.pwIsSet = responseContent.no_password === false;
			} catch (error) {
				this.$toast.error(this.$I18n.trans('errors.500.title'));
				console.error(error);
			} finally {
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.security {
	position: relative;
}

.security__section {
	margin-bottom: $sp6;
}

.security__loading-spinner {
	position: absolute;
	right: 50%;
	top: 10vh;
	transform: translateX(-50%);
}
</style>
