export default class I18n {
	/**
	 * Initialize a new translation instance.
	 *
	 * @param  {string}  key
	 * @return {void}
	 */
	constructor(key = 'translations') {
		this.key = key;
	}

	/**
	 * Get and replace the string of the given key.
	 *
	 * @param  {string}  key
	 * @param  {object}  replace
	 * @return {string}
	 */
	trans(key, replace = {}) {
		// if key contains whitespace, we don't want to translate it
		if (/\s/.test(key)) {
			return key;
		}
		return this._replace(this._extract(key), replace);
	}

	/**
	 * Get and pluralize the strings of the given key.
	 *
	 * @param  {string}  key
	 * @param  {number}  count
	 * @param  {object}  replace
	 * @return {string}
	 */
	trans_choice(key, count = 1, replace = {}) {
		const translations = this._extract(key, '|').split('|'); let translation;

		translations.some(t => translation = this._match(t, count));

		translation = translation || ((count > 1 || count === 0) ? translations[1] : translations[0]);

		translation = translation.replace(/\[.*?\]|\{.*?\}/, '');

		return this._replace(translation, replace);
	}

	/**
	 * Match the translation limit with the count.
	 *
	 * @param  {string}  translation
	 * @param  {number}  count
	 * @return {string|null}
	 */
	_match(translation, count) {
		const match = translation.match(/^[\{\[]([^\[\]\{\}]*)[\}\]](.*)/);

		if (!match) {
			return;
		}

		if (match[1].includes(',')) {
			const [from, to] = match[1].split(',', 2);

			if (to === '*' && count >= from) {
				return match[2];
			} else if (from === '*' && count <= to) {
				return match[2];
			} else if (count >= from && count <= to) {
				return match[2];
			}
		}

		return match[1] == count ? match[2] : null;
	}

	/**
	 * Replace the placeholders.
	 *
	 * @param  {string}  translation
	 * @param  {object}  replace
	 * @return {string}
	 */
	_replace(translation, replace) {
		if (typeof translation === 'object') {
			return translation;
		}

		for (const placeholder in replace) {
			const regexReplace = new RegExp(`:${placeholder}`, 'gi');
			translation = translation.toString().replace(regexReplace, replace[placeholder]);
		}

		return translation.toString().trim();
	}

	/**
	 * Extract values from objects by dot notation.
	 *
	 * @param  {string}  key
	 * @param  {mixed}  value
	 * @return {mixed}
	 */
	_extract(key, value = null) {
		const path = key.toString().split('::');
		const keys = path.pop().toString().split('.');

		if (path.length > 0) {
			path[0] += '::';
		}

		return path.concat(keys).reduce((t, i) => t[i] || (value || key), window[this.key]);
	}

	has(key) {
		const path = key.toString().split('::');
		const keys = path.pop().toString().split('.');

		if (path.length > 0) {
			path[0] += '::';
		}

		let transObj = window[this.key];
		let found = true;

		for (const pathPart of path.concat(keys)) {
			transObj = transObj[pathPart];
			if (!transObj) {
				found = false;
				break;
			}
		}

		return found;
	}
}

window.$I18n = new I18n();
