import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export default {
	state: {
		read:   [],
		unread: [],
	},
	actions,
	getters,
	mutations,
};
