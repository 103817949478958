import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { CaptureConsole, HttpClient } from '@sentry/integrations';
import UAParser from 'ua-parser-js';

// try here: https://uaparser.dev/
const ignoredBrowsers = [
	{
		name:                 'Safari',
		supportedFromVersion: 14,
	},
	{
		name:                 'Mobile Safari',
		supportedFromVersion: 14,
	},
	{
		name:                 'MIUI Browser',
		supportedFromVersion: null,
	},
];

const ignoredMessages = [
	'AbortError',
	'Can\'t find variable: gmo', // https://github.com/The-Best-Codes/bestcodes-react-site/issues/65
];

export const initSentry = (app) => {
	const ERROR_LIMIT = 100;
	const ERROR_KEY = 'sentryErrorLog';

	function getErrorLog() {
		const errorLog = localStorage.getItem(ERROR_KEY);
		if (errorLog) {
			return JSON.parse(errorLog);
		}
		return [];
	}

	function updateErrorLog() {
		const errorLog = getErrorLog();
		const now = Date.now();
		// Filter out errors older than 24 hours
		const updatedLog = errorLog.filter(errorTime => now - errorTime < 24 * 60 * 60 * 1000);
		localStorage.setItem(ERROR_KEY, JSON.stringify(updatedLog));
		return updatedLog;
	}

	function shouldReportError() {
		const errorLog = updateErrorLog();
		if (errorLog.length < ERROR_LIMIT) {
			errorLog.push(Date.now());
			localStorage.setItem(ERROR_KEY, JSON.stringify(errorLog));
			return true;
		}
		return false;
	}

	/**
	 * Error tracking
	 */
	const urlParams = new URLSearchParams(window.location.search);
	const isDebugMode = urlParams.has('sentry-debug');
	const isProduction = import.meta.env.PROD &&
		!window.location.hostname.match(/\.(dev|test|local)$/) &&
		!window.location.hostname.match(/^192\.168\.\d+\.\d+$/) &&
		!window.location.host.includes('localhost') &&
		!window.location.host.includes('127.0.0.1') &&
		!window.location.host.includes('dev-');
	if ((isDebugMode || isProduction) && !window.sentryInitialized) {
		Sentry.init({
			Vue,
			dsn:                      'https://d36f0b51110d490da9a5b138ad341b70@o991528.ingest.sentry.io/5948539',
			tracesSampleRate:         0.2,
			replaysSessionSampleRate: 0.2,
			replaysOnErrorSampleRate: 1.0,
			integrations:             [
				new CaptureConsole({ levels: ['error'] }),
				Sentry.replayIntegration(),
				new HttpClient(),
			],
			logErrors:    true,
			ignoreErrors: [
				/Googlebot/, // Ignore errors when the user agent contains "Googlebot"
				'Non-Error exception captured', // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/18
				'Non-Error promise rejection captured',
			],
			beforeSend(event) {
				const ua = event.request?.headers?.['User-Agent'];
				const parser = new UAParser(ua);
				const browserInfo = parser.getBrowser(); // { name: "Chrome", version: "90.0.4430.85", major: "90" }
				const versionParts = browserInfo.version.split('.').map(Number);
				const majorVersion = versionParts[0];

				// Ignore legacy browsers
				if (majorVersion && ignoredBrowsers.find((ignored) => ignored.name === browserInfo.name && (ignored.supportedFromVersion === null || ignored.supportedFromVersion > majorVersion))) {
					return null;
				}

				// ignore certain errors
				if (ignoredMessages.find((ignored) => event.message?.includes(ignored))) {
					return null;
				}

				// Check if the error should be reported based on the limit
				if (!shouldReportError()) {
					console.log('[SENTRY] not reporting error, limit exceeded');
					return null;
				}

				return event;
			},
		});

		Sentry.setTag('App', app);

		window.Sentry = Sentry;
		window.sentryInitialized = true;
	}
};
