<template>
	<Layout>
		<div class="l-container">
			<div class="l-row l-align-center">
				<div class="l-col l-col--12 l-col--8@lg">
					<Imprint />
				</div>
			</div>
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Imprint from '@shared/components/Imprint';

export default {
	name:       'ViewImprint',
	components: { Layout, Imprint },
};
</script>

<style>

</style>
