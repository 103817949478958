<template>
	<div
		class="v2-input-text"
		:class="{
			'-t-dark': darkTheme,
			[`-${size}`]: size,
			[`-align-${textAlign}`]: textAlign,
			'-hide-inner-spin-button': hideInnerSpinButton,
		}"
	>
		<V2InputLabel
			v-if="label"
			:text="label"
			:info="info"
			:required="required"
			:disabled="disabled"
			:hide-optional="type === 'search' || hideOptional"
			:dark-theme="darkTheme"
		/>
		<div
			class="v2-input-text__w"
			@click="handleClick"
		>
			<input
				v-if="type !== 'textarea'"
				ref="input"
				:value="internalValue"
				class="v2-input-text__input"
				:class="{
					'was-focused': wasFocused,
					'-with-icon': type === 'search' || icon,
				}"
				:type="type"
				:name="name"
				:placeholder="disabled ? '' : placeholder"
				:disabled="disabled"
				:required="required"
				:minlength="minlength"
				:maxlength="maxlength"
				:pattern="pattern"
				:min="type === 'number' ? (Object.prototype.hasOwnProperty.call($attrs, 'min') ? $attrs.min : '0') : undefined"
				v-bind="$attrs"
				@blur="$emit('blur')"
				@input="updateSelf($event)"
				@keydown.esc="type === 'search' ? updateSelf('') : null"
				@focusout="handleFocusOut"
				@focus="wasFocused = true; $emit('focus')"
			>
			<textarea
				v-if="type === 'textarea'"
				ref="input"
				:value="internalValue"
				class="v2-input-text__input -textarea"
				:class="{
					'was-focused': wasFocused,
					'grow-on-focus': growTextAreaOnFocus,
				}"
				:name="name"
				:placeholder="disabled ? '' : placeholder"
				:disabled="disabled"
				:required="required"
				:minlength="minlength"
				:maxlength="maxlength"
				:pattern="pattern"
				v-bind="$attrs"
				@blur="$emit('blur')"
				@input="updateSelf($event)"
				@focusout="handleFocusOut"
				@focus="wasFocused = true"
			/>
			<V2InputError
				v-if="errorMsg"
				class="v2-input-text__error"
				:class="{'-always-visible': showError}"
				:text="errorMsg"
			/>
			<V2Icon
				v-if="icon"
				class="v2-input-text__icon"
				:class="{ '-clickable': iconClickable }"
				:icon-name="icon"
				size="lg"
				@click="$emit('icon-click')"
			/>
			<template v-if="type === 'search'">
				<V2Icon
					v-if="internalValue.length === 0"
					class="v2-input-text__icon"
					icon-name="search"
					size="lg"
				/>
				<V2Icon
					v-else
					class="v2-input-text__icon -reset"
					icon-name="e-remove"
					size="lg"
					@click="updateSelf('')"
				/>
			</template>
		</div>
		<div
			v-if="charCounter"
			class="v2-input-text__char-counter"
		>
			{{ internalValue.length }}<span v-if="maxlength">/{{ maxlength }}</span>
		</div>
		<div
			v-if="textAfter"
			class="v2-input-text__text-after"
			:class="[`-${textAfterAlignment}`]"
		>
			{{ textAfter }}
		</div>
		<template v-if="$slots.after">
			<div class="v2-input-text__text-after">
				<slot name="after" />
			</div>
		</template>
	</div>
</template>

<script>
import V2InputLabel from '@shared/components/Form/InputLabel';
import V2InputError from '@shared/components/Form/InputError';
import V2Icon from '@shared/components/Icon';

export default {
	name:       'V2InputText',
	components: { V2InputLabel, V2InputError, V2Icon },
	model:      {
		prop:  'value',
		event: 'input',
	},
	props: {
		value: {
			type:    [String, Number],
			default: '',
		},
		name: {
			type:    String,
			default: '',
		},
		info: {
			type:    String,
			default: null,
		},
		type: {
			type:    String,
			default: 'text',
		},
		label: {
			type:    String,
			default: null,
		},
		placeholder: {
			type:    String,
			default: null,
		},
		icon: {
			type:    String,
			default: null,
		},
		iconClickable: {
			type:    Boolean,
			default: false,
		},
		disabled: {
			type:    Boolean,
			default: false,
		},
		required: {
			type:    Boolean,
			default: false,
		},
		minlength: {
			type:    [String, Number],
			default: null,
		},
		maxlength: {
			type:    [String, Number],
			default: null,
		},
		charCounter: {
			type:    Boolean,
			default: false,
		},
		pattern: {
			type:    String,
			default: null,
		},
		customErrorMsg: {
			type:    String,
			default: null,
		},
		// Always display error, not only after focus
		showError: {
			type:    Boolean,
			default: false,
		},
		darkTheme: {
			type:    Boolean,
			default: false,
		},
		growTextAreaOnFocus: {
			type:    Boolean,
			default: true,
		},
		textAfter: {
			type:    String,
			default: null,
		},
		textAfterAlignment: {
			type:    String,
			default: 'right',
		},
		size: {
			type:       String,
			default:    'md',
			validators: [value => ['md', 'lg'].includes(value)],
		},
		textAlign: {
			type:       String,
			default:    'left',
			validators: [value => ['left', 'center'].includes(value)],
		},
		hideInnerSpinButton: {
			type:    Boolean,
			default: false,
		},
		hideOptional: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			wasFocused:       false,
			internalErrorMsg: null,
			isValid:          false,
			internalValue:    this.value,
		};
	},
	computed: {
		errorMsg() {
			return this.customErrorMsg || this.internalErrorMsg;
		},
	},
	watch: {
		value(value) {
			if (value === this.internalValue) {
				return;
			}
			this.updateSelf(this.value);
			this.wasFocused = true;
			this.handleFocusOut();
		},
		isValid(value) {
			this.$emit('validity-change', value);
		},
	},
	mounted() {
		this.checkValidity();
	},
	methods: {
		focus() {
			if (!this.$refs.input) {
				return;
			}
			this.$refs.input.focus();
		},
		updateSelf(data) {
			const value = data?.target ? data.target.value : (data ?? '');
			if (this.maxlength && value.length > this.maxlength && data?.target) {
				data.target.value = value.substring(0, this.maxlength);
				return;
			}
			this.internalValue = value ?? '';
			this.$nextTick(() => {
				this.checkCharCountValidity();
				this.checkValidity();
				this.$emit('input', this.internalValue);
			});
		},
		handleFocusOut() {
			if (!this.$refs.input) {
				return;
			}
			this.updateGenricErrorMsg();
		},
		handleClick() {
			this.$emit('input-click');
		},
		checkValidity() {
			if (!this.$refs.input) {
				return;
			}
			this.isValid = this.$refs.input.validity.valid;
		},
		// When programmatically setting a value, the min/max length validity doesn't work
		checkCharCountValidity() {
			if (!this.$refs.input) {
				return;
			}
			this.$refs.input.setCustomValidity('');
			if (this.maxlength && this.internalValue.length > this.maxlength) {
				this.$refs.input.setCustomValidity('tooLong');
			}
			if (this.minlength && this.internalValue.length < this.minlength) {
				this.$refs.input.setCustomValidity('tooShort');
			}
		},
		updateGenricErrorMsg() {
			if (this.isValid) {
				this.internalErrorMsg = null;
			} else {
				if (this.type === 'url' && this.$refs.input.validity.typeMismatch) {
					this.internalErrorMsg = $I18n.trans('validation.url', { attribute: (this.label || this.name) });
				} else if (this.type === 'email' && this.$refs.input.validity.typeMismatch) {
					this.internalErrorMsg = $I18n.trans('validation.email', { attribute: (this.label || this.name) });
				} else {
					if (this.$refs.input.validity.tooShort) {
						this.internalErrorMsg = $I18n.trans('validation.min.string', { attribute: (this.label || this.name), min: this.minlength });
					} else if (this.$refs.input.validity.tooLong) {
						this.internalErrorMsg = $I18n.trans('validation.max.string', { attribute: (this.label || this.name), max: this.maxlength });
					} else if (this.type === 'number' && (Object.prototype.hasOwnProperty.call(this.$attrs, 'min') && Object.prototype.hasOwnProperty.call(this.$attrs, 'max')) && (this.$refs.input.validity.rangeOverflow || this.$refs.input.validity.rangeUnderflow)) {
						this.internalErrorMsg = $I18n.trans('validation.between.numeric', { attribute: (this.label || this.name), min: this.$attrs.min, max: this.$attrs.max });
					} else {
						this.internalErrorMsg = $I18n.trans('validation.required', { attribute: (this.label || this.name) });
					}
				}
			}
		},
		resetFocus() {
			this.wasFocused = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.v2-input-text {
	--form-color-input-text: #{$form-color-input-text};
	--form-color-input-text-disabled: #{$form-color-input-text-disabled};
	--form-color-placeholder: #{$form-color-input-placeholder};
	--form-color-border: #{$form-color-border};
	--form-color-border-hover: #{$form-color-border-hover};
	--form-color-border-focus: #{$form-color-border-focus};
	--form-color-bg: #{$form-color-bg};
	--form-color-error: #{$form-color-error};
	--form-color-bg-disabled: #{$form-color-bg-disabled};

	position: relative;
	margin-bottom: $form-margin-bottom;

	&.-t-dark {
		--form-color-input-text: #{$form-color-input-text--dark};
		--form-color-input-text-disabled: #{$form-color-input-text-disabled--dark};
		--form-color-placeholder: #{$form-color-input-placeholder--dark};
		--form-color-border: #{$form-color-border--dark};
		--form-color-border-hover: #{$form-color-border-hover--dark};
		--form-color-border-focus: #{$form-color-border-focus--dark};
		--form-color-bg: #{$form-color-bg--dark};
		--form-color-error: #{$form-color-error--dark};
		--form-color-bg-disabled: #{$form-color-bg-disabled--dark};
	}

	body.-t-light & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		--form-color-border: var(--color-grey-800);
		--form-color-border-hover: var(--color-grey-700);
		--form-color-border-focus: var(--color-grey-700);
		--form-color-bg-disabled: var(--color-grey-900);
	}
}

.v2-input-text__w {
	position: relative;
	z-index: 0;

	.v2-input-text.-copy & {
		cursor: copy;
	}
}

.v2-input-text__input {
	@include form-input-text-font();
	display: block;
	color: var(--form-color-input-text);
	border: 1px solid var(--form-color-border);
	border-radius: $form-border-radius;
	outline: none;
	padding-right: $form-input-padding;
	padding-left: $form-input-padding;
	width: 100%;
	height: $form-input-height;
	background-color: var(--form-color-bg);
	user-select: text;
	box-shadow: none;
	-webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix

	&.-with-icon {
		padding-right: ($form-input-padding * 2) + 24px; // 24px is icon-size lg
	}

	&.-textarea {
		padding-top: $form-input-padding + 1px;
		padding-bottom: $form-input-padding;
		min-height: 100px;
		transition: min-height $trans-time-fast;
		resize: vertical;
	}

	.v2-input-text.-lg & {
		height: $form-input-height-lg;
	}

	.v2-input-text.-align-center & {
		text-align: center;
	}

	.v2-input-text.-hide-inner-spin-button & {
		&::-webkit-inner-spin-button {
			-webkit-appearance: none; // stylelint-disable-line property-no-vendor-prefix
		}
	}

	&.was-focused:invalid:not(:focus) {
		color: var(--form-color-error);
		border-color: var(--form-color-error);
	}

	&:disabled {
		.v2-input-text:not(.-copy) & {
			background-color: var(--form-color-bg-disabled);
			color: var(--form-color-input-text-disabled);
			cursor: not-allowed;
		}

		.v2-input-text.-copy & {
			pointer-events: none;
		}
	}

	&::placeholder {
		color: var(--form-color-placeholder);
	}

	&:not(:disabled):hover {
		border-color: var(--form-color-border-hover);
	}

	&:focus {
		border-color: var(--form-color-border-focus) !important; // stylelint-disable-line declaration-no-important

		&.-textarea.grow-on-focus {
			min-height: 150px;
		}
	}
}

.v2-input-text__error {
	display: none;

	&.-always-visible {
		display: flex;
	}

	.v2-input-text__input.was-focused:invalid:not(:focus) + & {
		display: flex;
	}
}

.v2-input-text__icon {
	position: absolute;
	top: $form-input-padding;
	right: $form-input-padding;
	z-index: 1;
	vertical-align: middle;
	color: var(--form-color-input-text);

	&.-clickable,
	&.-reset {
		cursor: pointer;
	}
}

.v2-input-text__char-counter,
.v2-input-text__text-after {
	@include font(primary, book, normal, fs-80);
	color: var(--color-grey-500);
	text-align: right;
	padding-top: $sp3;
	position: absolute;
	width: 100%;
	z-index: 1;

	&.-left {
		text-align: left;
	}
}

// Search input Reset
// stylelint-disable
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
	-webkit-appearance: none;
}
// stylelint-enable

</style>
