<template>
	<div>
		<div
			v-if="$arenaConfig.profileFields.additionalFieldsEnabled"
			class="profile-additional-fields"
		>
			<Select
				ref="languageInput"
				:label="$I18n.trans_choice('commons.language', 1)"
				:options="languageOptions"
				:value="localProfile.locale"
				:searchable="false"
				:clearable="false"
				:required="true"
				:dark-theme="!$arenaConfig.isLightTheme"
				open-direction="left"
				@change="val => localProfile.locale = val"
			/>
			<Select
				v-if="countryOptions"
				ref="countryInput"
				:label="$I18n.trans('commons.country')"
				:options="countryOptions"
				:value="localProfile.country"
				:placeholder="$I18n.trans('commons.select')"
				:searchable="true"
				:clearable="false"
				:required="$arenaConfig.additionalFieldsRequired"
				:dark-theme="!$arenaConfig.isLightTheme"
				open-direction="left"
				@change="val => localProfile.country = val"
			/>
			<InputToggle
				v-if="profileLoaded"
				:text="$I18n.trans('commons.newsletter_consent')"
				:value="localProfile.newsletter"
				size="sm"
				:dark-theme="!$arenaConfig.isLightTheme"
				@input="(val) => localProfile.newsletter = val"
			/>
		</div>
		<InputText
			ref="firstNameInput"
			v-model="localProfile.firstName"
			:label="$I18n.trans('commons.first_name')"
			:dark-theme="!$arenaConfig.isLightTheme"
			:disabled="isProcessing"
			autocomplete="given-name"
		/>
		<InputText
			ref="lastNameInput"
			v-model="localProfile.lastName"
			:label="$I18n.trans('commons.last_name')"
			:dark-theme="!$arenaConfig.isLightTheme"
			:disabled="isProcessing"
			autocomplete="family-name"
		/>
		<InputBirthdate
			ref="birthdateInput"
			v-model="localProfile.birthday"
			:disabled="isProcessing"
			:dark-theme="!$arenaConfig.isLightTheme"
		/>
	</div>
</template>

<script>
import * as countries from 'i18n-iso-countries';

import countryDataDe from 'i18n-iso-countries/langs/de.json';
import countryDataEn from 'i18n-iso-countries/langs/en.json';
import countryDataIt from 'i18n-iso-countries/langs/it.json';
import countryDataFr from 'i18n-iso-countries/langs/fr.json';

import InputText from '@shared/components/Form/InputText';
import InputBirthdate from '@shared/components/Form/InputBirthdate';
import Select from '@shared/components/Select';
import InputToggle from '@shared/components/Form/InputToggle';
import { mapState } from 'vuex';
import { actions } from '@legacy/js/store/user';

export default {
	name:       'ProfileForm',
	components: { InputText, InputBirthdate, Select, InputToggle },
	data() {
		return {
			isProcessing: false,
			localProfile: {
				firstName:  null,
				lastName:   null,
				birthday:   null,
				country:    null,
				locale:     null,
				newsletter: false,
			},
			isMounted:      false,
			countryOptions: null,
			profileLoaded:  false,
		};
	},
	computed: {
		...mapState({
			profile: state => state.user.profile,
		}),
		formHasChanged() {
			if (!this.profile) return false;
			// check every property of localProfile against profile
			const checkKey = ['firstName', 'lastName', 'birthday', 'country', 'locale', 'newsletter'];
			return checkKey.some(key => this.localProfile[key] !== this.profile[key]);
		},
		formIsValid() {
			if (!this.isMounted) return false;
			const basicFieldsValid = this.$refs.firstNameInput?.isValid && this.$refs.lastNameInput?.isValid && this.$refs.birthdateInput?.isValid;

			if (this.$arenaConfig.profileFields && this.$arenaConfig.profileFields.additionalFieldsEnabled && this.$arenaConfig.profileFields.additionalFieldsRequired) {
				return basicFieldsValid && this.localProfile.locale != null && this.localProfile.country != null;
			} else {
				return basicFieldsValid;
			}
		},
		canSubmit() {
			return this.formHasChanged && this.formIsValid;
		},
		languageOptions() {
			return this.$arenaConfig.locales.map((lang) => {
				return {
					label: lang.name,
					value: lang.code,
				};
			});
		},
		currentLanguage() {
			return this.$arenaConfig.locales?.find((locale) => locale.name === this.$arenaConfig.currentLanguageKey)?.code;
		},
		newsletterOptions() {
			return [
				{ name: this.$I18n.trans('commons.yes'), id: true },
				{ name: this.$I18n.trans('commons.no'), id: false },
			];
		},
	},
	watch: {
		isProcessing: {
			handler(value) {
				this.$emit('isProcessing', value);
			},
		},
		canSubmit: {
			handler(value) {
				this.$emit('canSubmit', value);
			},
		},
		profile: {
			immediate: true,
			handler(profile) {
				if (profile && !profile.profileUpdated && this.$arenaConfig.profileFields.newsletterOptOut) {
					profile.newsletter = true;
				}

				this.localProfile = {
					...this.localProfile,
					...profile,
				};

				if (profile) {
					this.profileLoaded = true;
				}
			},
		},
	},
	mounted() {
		this.countryOptions = this.getCountryOptions();
		this.isMounted = true;
	},
	methods: {
		async handleProfileUpdate() {
			try {
				this.isProcessing = true;
				await this.$store.dispatch(`user/${actions.UPDATE_PROFILE}`, this.localProfile);
				this.$toast.success($I18n.trans('notifications.profile_updated'));

				if (this.localProfile.locale !== this.currentLanguage) {
					setTimeout(() => {
						window.location.href = window.location.href.split('?')[0] + '?locale=' + this.localProfile.locale;
					}, 200);
				}
			} catch (e) {
				console.error(e);
				this.$toast.error(this.$I18n.trans('errors.500.title'));
			} finally {
				this.isProcessing = false;
			}
		},
		submit() {
			this.handleProfileUpdate();
		},
		getCountryOptions() {
			const countryTranslation = ['de', 'fr', 'it', 'en'].includes(this.currentLanguage) ? this.currentLanguage : 'en';

			switch (this.currentLanguage) {
			case 'de':
				countries.registerLocale(countryDataDe);
				break;
			case 'fr':
				countries.registerLocale(countryDataFr);
				break;
			case 'it':
				countries.registerLocale(countryDataIt);
				break;
			default:
				countries.registerLocale(countryDataEn);
				break;
			};

			const countryList = countries.getNames(countryTranslation);
			return Object.keys(countryList).map(code => {
				return {
					label: countryList[code],
					value: code.toLowerCase(),
				};
			});
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.profile-additional-fields {
	margin-bottom: $sp6;
}

</style>
