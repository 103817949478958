<template>
	<div
		v-if="hasTextContent"
		ref="contextMenu"
		class="v2-context-menu"
		:class="{
			'is-open' : isOpen,
			[`-${computedAlign.split('-')[0]}`]: true,
			[`-${computedAlign.split('-')[1]}`]: true,
			'open-on-hover': openOnHover
		}"
		@mouseleave="handleMouseLeave"
	>
		<div
			class="v2-context-menu__toggle"
			:class="{
				'-arrow': arrow,
			}"
			@click.prevent="toggleContextMenu"
		>
			<slot name="toggle" />
		</div>
		<div
			ref="contextMenuBody"
			class="v2-context-menu__body"
			:class="{
				'-arrow': arrow,
				[`-${computedAlign}`]: true
			}"
		>
			<div
				class="v2-context-menu__body-inner"
				:style="{'max-height': maxHeight}"
			>
				<slot name="context-menu" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name:  'ContextMenu',
	props: {
		offset: {
			type:    String,
			default: '6px',
		},
		keepOpen: {
			type:    Boolean,
			default: true,
		},
		openOnHover: {
			type:    Boolean,
			default: false,
		},
		arrow: {
			type:    Boolean,
			default: true,
		},
		align: {
			type:      String,
			default:   'bottom-right',
			validator: align => ['bottom-right', 'bottom-left', 'bottom-center', 'top-right', 'top-left', 'top-center'].includes(align),
		},
		maxHeight: {
			type:    String,
			default: '60vh',
		},
	},
	data() {
		return {
			isOpen:         false,
			hasTextContent: true,
			computedAlign:  this.align,
			isMounted:      false,
		};
	},
	watch: {
		isOpen(value) {
			this.$emit('change', value);
			document[(value ? 'add' : 'remove') + 'EventListener']('click', this.onClickOutside);
		},
	},
	mounted() {
		this.isMounted = true;
		this.loadMenu();
		setTimeout(() => {
			// waiting for the content to be loaded
			// not perfect but works for now
			if (this.isMounted) {
				this.autoAlign();
			}
		}, 500);

		// listen for a close event on the event bus
		this.eventBus?.$on('context-menu:close', this.close);
	},
	beforeUnmount() {
		this.isMounted = false;
		document.removeEventListener('click', this.onClickOutside);
	},
	methods: {
		loadMenu() {
			this.hasTextContent = this.$refs.contextMenuBody?.textContent?.trim()?.length !== 0 ?? false;
		},
		toggleContextMenu() {
			if (this.openOnHover) {
				return;
			}
			this.isOpen = !this.isOpen;
		},
		onClickOutside(event) {
			if (!this.$el.contains(event.target)) {
				this.isOpen = false;
			}
		},
		handleMouseLeave() {
			if (this.keepOpen || this.openOnHover) {
				return;
			}
			this.isOpen = false;
		},
		close() {
			this.isOpen = false;
		},
		autoAlign() {
			if (!this.$refs.contextMenuBody) {
				return;
			}
			const { top, left, bottom, right } = this.$refs.contextMenuBody.getBoundingClientRect();
			// hide context menu so it doesn't increase the height of the viewport
			this.$refs.contextMenuBody.style.display = 'none';
			const documentHeight = document.documentElement.scrollHeight;
			const documentWidth = document.documentElement.scrollWidth;
			const isOutsideTop = top < 0;
			const isOutsideLeft = left < 0;
			const isOutsideBottom = bottom > documentHeight;
			const isOutsideRight = right > documentWidth;

			if (isOutsideTop) {
				this.computedAlign = this.computedAlign.replace('top', 'bottom');
			}
			if (isOutsideBottom) {
				this.computedAlign = this.computedAlign.replace('bottom', 'top');
			}
			if (isOutsideLeft) {
				this.computedAlign = this.computedAlign.replace('right', 'left');
				if (this.computedAlign.includes('center')) {
					this.computedAlign = this.computedAlign.replace('center', 'left');
				}
			}
			if (isOutsideRight) {
				this.computedAlign = this.computedAlign.replace('left', 'right');
				if (this.computedAlign.includes('center')) {
					this.computedAlign = this.computedAlign.replace('center', 'right');
				}
			}
			this.$refs.contextMenuBody.style.display = 'block';
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

$arrow-vertical-offset: 10px;
$arrow-size: 10px;
$hover-protect-area-offset: 35px;

.v2-context-menu {
	position: relative;
	display: inline-flex;
	align-items: center;
	z-index: 1;

	&.open-on-hover:hover,
	&.is-open {
		@include z-index(manager-context-menu);
	}
}

.v2-context-menu__toggle {
	text-align: center;
	display: flex;
	align-items: center;
	cursor: pointer;

	&.-arrow {
		&::before {
			@include z-index(manager-context-menu);
			opacity: 0;
			transition: opacity $trans-time-fast;
			content: '';
			position: absolute;
			border: $arrow-size solid;
			border-color: transparent transparent var(--color-white);
			left: calc(50% - #{$arrow-size});
			bottom: -#{$arrow-vertical-offset};

			.v2-context-menu.-top & { // stylelint-disable-line
				border-color: var(--color-white) transparent transparent;
				top: -#{$arrow-vertical-offset};
				bottom: auto;
			}
		}

		// Shadow for arrow (is below arrow itself)
		&::after {
			opacity: 0;
			transition: opacity $trans-time-fast;
			content: '';
			position: absolute;
			border: 10px solid;
			border-color: transparent transparent var(--color-white);
			left: calc(50% - #{$arrow-size});
			bottom: -#{$arrow-vertical-offset};
			filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));

			.v2-context-menu.-top & { // stylelint-disable-line max-nesting-depth
				border-color: var(--color-white) transparent transparent;
				top: -#{$arrow-vertical-offset};
				bottom: auto;
			}
		}

		.v2-context-menu.open-on-hover:hover &,
		.v2-context-menu.is-open & {
			&::before { // stylelint-disable-line max-nesting-depth
				opacity: 1;
			}

			&::after { // stylelint-disable-line max-nesting-depth
				opacity: 1;
			}
		}
	}
}

.v2-context-menu__body {
	$arrow-size: 10px;
	$arrow-offset: 20px;

	position: absolute;
	filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.2));
	text-align: center;
	background-color: var(--color-white);
	z-index: 1;
	opacity: 0;
	pointer-events: none;
	transition: opacity $trans-time-fast;

	// area to keep hover on toggle active
	&::before {
		content: '';
		position: absolute;
		top: -#{$hover-protect-area-offset};
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		.v2-context-menu.-top & {
			top: auto;
			bottom: -#{$hover-protect-area-offset};
		}
	}

	.v2-context-menu.-bottom & {
		top: 100%;
	}

	.v2-context-menu.-top & {
		bottom: 100%;

		&::before {
			top: auto;
			bottom: -35px;
		}
	}

	.v2-context-menu.-left & {
		left: 0;
	}

	.v2-context-menu.-right & {
		right: 0;
	}

	.v2-context-menu.-center & {
		left: 50%;
		transform: translateX(-50%);
	}

	&.-arrow {
		.v2-context-menu.-bottom & {
			top: calc(100% + #{$arrow-vertical-offset});
		}

		.v2-context-menu.-top & {
			bottom: calc(100% + #{$arrow-vertical-offset});
		}

		.v2-context-menu.-left & {
			left: -5px;
		}

		.v2-context-menu.-right & {
			right: -5px;
		}
	}

	.v2-context-menu.is-open & {
		opacity: 1;
		pointer-events: initial;
	}

	// pointer-events deactivated for now in order to avoid jiggering.
	// open-on-hover is used on tooltip component where we don't need to keep it open
	.v2-context-menu.open-on-hover:hover & {
		opacity: 1;
	}
}

.v2-context-menu__body-inner {
	background-color: var(--color-white);
	overflow: auto;
}
</style>
