<template>
	<Layout>
		<NavBack
			v-if="$Helpers.getBackUrl()"
			:link="$Helpers.getBackUrl()"
			:title="$I18n.trans('commons.back')"
		/>
		<PrivateEvent
			v-if="eventIsProtected && !passwordUnlocked"
			:event-id="eventId"
			:pw-protected="passwordEnabled"
		/>
		<template v-else>
			<template v-if="paymentRestricted">
				<PaywallModal
					:event="event"
				/>
				<PaymentModal
					:event="event"
					:checkout="checkout"
				/>
			</template>
			<div
				v-if="$arenaConfig.isSmartTv"
				class="l-smart-tv-wrapper"
			>
				<div id="embed-view-mode">
					<!-- Player goes here -->
				</div>
			</div>
			<div
				v-else
				class="l-container l-container--full-width@mobile u-flex-grow-1"
			>
				<div id="full-page-mode">
					<!-- Player goes here -->
				</div>
			</div>
		</template>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import PrivateEvent from '@js/components/PrivateEvent';
import PaymentModal from '@js/components/Payments/PaymentModal';
import PaywallModal from '@js/components/Payments/PaywallModal';
import NavBack from '@js/components/NavBack';

export default {
	name:       'ViewEvent',
	components: { Layout, PrivateEvent, PaymentModal, PaywallModal, NavBack },
	props:      {
		eventIsProtected: {
			type:     Boolean,
			required: true,
		},
		passwordUnlocked: {
			type:     Boolean,
			required: true,
		},
		eventId: {
			type:     Number,
			required: true,
		},
		passwordEnabled: {
			type:     Boolean,
			required: true,
		},
		paymentRestricted: {
			type:     Boolean,
			required: true,
		},
		event: {
			type:     Object,
			required: true,
		},
		checkout: {
			type:     Boolean,
			required: true,
		},
	},
};
</script>

<style>

</style>
