<template>
	<Layout>
		<div class="l-container">
			<Terms dark-theme />
		</div>
	</Layout>
</template>

<script>
import Layout from '@js/Views/Layout';
import Terms from '@shared/components/Terms';

export default {
	name:       'ViewTerms',
	components: { Layout, Terms },
};
</script>

<style>

</style>
