<template>
	<Modal
		:modal-name="modalName"
		type="dialog"
		:theme="$arenaConfig.isLightTheme ? null : 'dark'"
		:closeable="!isRequired"
		@closing-modal="storeDismissed"
	>
		<template #title>
			{{ $I18n.trans('commons.profile') }}
		</template>
		<template #content>
			<div v-if="!wasUpdated">
				<p>{{ isRequired ? $I18n.trans('auth.messages.complete_profile_mandatory') : $I18n.trans('auth.messages.complete_profile') }}</p>
				<br>
				<ProfileForm
					ref="profileForm"
					@isProcessing="isProcessing = $event"
					@canSubmit="canSubmit = $event"
				/>
			</div>
			<div
				v-else
				class="complete-profile__success-message"
			>
				<V2Icon
					icon-name="fireworks"
					size="xxxl"
					class="complete-profile__success-message-icon"
				/>
				<p>{{ $I18n.trans('notifications.profile_updated') }}</p>
			</div>
		</template>
		<template #footer>
			<div
				v-if="!wasSubmitted || !wasUpdated"
				class="l-button-group"
			>
				<V2Button
					v-if="!isRequired"
					:text="$Helpers.ucfirst($I18n.trans('commons.cancel'))"
					modifier="secondary"
					theme="dark"
					@click="closeModal"
				/>
				<V2Button
					:text="$Helpers.ucfirst($I18n.trans('commons.save'))"
					theme="dark"
					:busy="isProcessing"
					:disabled="!canSubmit"
					@click="submitForm"
				/>
			</div>
		</template>
	</Modal>
</template>

<script>
import Modal from '@shared/components/Modal';
import V2Button from '@shared/components/Button';
import ProfileForm from '@js/components/ProfileForm';
import { mapState } from 'vuex';

const LOCAL_STORAGE_DISMISSED = 'profileFormDismissed';

export default {
	name:       'ModalCompleteProfile',
	components: { Modal, V2Button, ProfileForm },
	data() {
		return {
			modalName:    'completeProfile',
			canSubmit:    false,
			isProcessing: false,
			wasSubmitted: false,
			wasUpdated:   false,
		};
	},
	computed: {
		...mapState({
			profile: state => state.user?.profile,
		}),
		isLoading() {
			return !this.profile;
		},
		isRequired() {
			return this.$arenaConfig.profileFields?.additionalFieldsRequired;
		},
	},
	mounted() {
		this.$store.subscribe((mutation) => {
			if (mutation.type === 'user/setProfile' && this.shouldShowModal()) {
				this.openModal();
			}
			if (this.wasSubmitted && mutation.type === 'user/setProfile') {
				this.wasUpdated = true;
				this.isProcessing = false;
				this.closeModalWithDelay();
			}
		});
	},
	methods: {
		openModal() {
			window.eventBus.$emit(`modal:${this.modalName}`);
		},
		closeModal() {
			window.eventBus.$emit(`modalClose:${this.modalName}`);
		},
		closeModalWithDelay() {
			setTimeout(() => {
				this.closeModal();
			}, 2000);
		},
		submitForm() {
			this.wasSubmitted = true;
			this.$refs.profileForm.submit();
		},
		shouldShowModal() {
			let showModal = false;
			if (this.$arenaConfig.profileFields?.additionalFieldsRequired && !$Helpers.isSmartTV()) {
				showModal = !this.profile.profileUpdated;
			} else {
				const isHomePage = window.location.pathname === '/';
				const isNotSmartTv = !$Helpers.isSmartTV();
				const profileIsIncomplete = this.profile && (!this.profile.firstName || !this.profile.lastName);
				const hasRecentlyDismissedForm = this.hasRecentlyDismissedForm();
				showModal = isHomePage && profileIsIncomplete && isNotSmartTv && !hasRecentlyDismissedForm;
			}

			return showModal;
		},
		hasRecentlyDismissedForm() {
			const dismissed = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DISMISSED));
			const now = new Date().getTime();
			return dismissed ? now < (dismissed + (1000 * 60 * 60 * 24 * 30)) : false;
		},
		storeDismissed() {
			if (!this.wasUpdated) {
				localStorage.setItem(LOCAL_STORAGE_DISMISSED, JSON.stringify(new Date().getTime()));
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.complete-profile__success-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: $sp3;
	margin-top: $sp3;
}

.complete-profile__success-message-icon {
	margin-bottom: $sp3;
}
</style>
