<template>
	<div
		ref="multiFormatPlayer"
		class="multi-format-player"
		:class="[`is-${uiStatus}`, { 'is-mobile-portrait': internalIsMobilePortrait }]"
	>
		<div
			class="multi-format-player__video-container"
			:style="{width: `${containerWidth}px`, height: `${containerHeight}px`}"
		>
			<slot name="video-overlay" />
			<video
				ref="videoPlayer"
				class="multi-format-player__video-player"
				playsinline
				preload="metadata"
				@timeupdate="handleTimeUpdate"
			/>
		</div>
		<div
			v-show="uiStatus !== 'preview'"
			class="multi-format-player__crop-container"
		>
			<canvas
				ref="cropCanvas"
				class="multi-format-player__crop-canvas"
				:width="containerWidth"
				:height="containerHeight"
			/>
		</div>
		<PlayerControls
			ref="playerControls"
			class="multi-format-player__controls"
			:class="{ 'is-hidden': !showControls }"
			:duration="playerControlsDuration"
			:position="playerControlsPosition"
			:is-playing="isPlaying"
			:show-scrubber="true"
			:is-muted="isMuted"
			:volume-level="volumeLevel"
			:show-fullscreen="false"
			:show-share-button="false"
			:timeline-full-width="true"
			:show-volume-selector="!internalIsMobilePortrait"
			@play="togglePlay(true)"
			@pause="togglePlay(false)"
			@set-mute="setMute($event)"
			@set-volume="setVolume($event)"
			@seek="setVideoTime($event)"
		/>
	</div>
</template>

<script>
import Hls from 'hls.js';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import PlayerControls from '@shared/components/PlayerControls/PlayerControls.vue';
import { debounce } from '@shared/utils/debounce';

const CROPPER_SETTINGS = {
	background:       false,
	modal:            false,
	center:           false,
	guides:           false,
	cropBoxResizable: false,
	aspectRatio:      9 / 16,
	viewMode:         1,
	dragMode:         'move',
	responsive:       true,
};

const urlParams = new URLSearchParams(window.location.search);
const requestedPosition = urlParams.get('position');

export default {
	name:       'MultiFormatPlayer',
	components: {
		PlayerControls,
	},
	props: {
		hlsUrl: {
			type:    String,
			default: null,
		},
		uiStatus: {
			type:     String,
			required: true,
		},
		showControls: {
			type:    Boolean,
			default: true,
		},
		tagTime: {
			type:    Object,
			default: null,
		},
		internalIsMobilePortrait: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			duration:       0,
			position:       requestedPosition ? parseFloat(requestedPosition) : 0,
			isPlaying:      false,
			isMuted:        false,
			volumeLevel:    50,
			containerWidth: 1200,
			cropper:        null,
		};
	},
	computed: {
		containerHeight() {
			return Math.round(this.containerWidth * (9 / 16)) + 1;
		},
		playerControlsDuration() {
			return this.tagTime ? this.tagTime.end - this.tagTime.start : this.duration;
		},
		playerControlsPosition() {
			return this.tagTime ? this.position - this.tagTime.start : this.position;
		},
	},
	watch: {
		playerControlsDuration() {
			this.$emit('duration-update', this.playerControlsDuration);
		},
	},
	mounted() {
		this.updateContainerSize();
		if (this.hlsUrl && Hls.isSupported()) {
			const hls = new Hls();
			hls.loadSource(this.hlsUrl);
			hls.attachMedia(this.$refs.videoPlayer);
		} else if (this.$refs.videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
			this.$refs.videoPlayer.src = this.hlsUrl; // safari fix
		}

		this.$refs.videoPlayer.addEventListener('loadedmetadata', this.handleLoadedMetadata);

		this.$refs.videoPlayer.removeAttribute('controls');

		this.cropper = new Cropper(this.$refs.cropCanvas, {
			...CROPPER_SETTINGS,
			minCropBoxHeight: this.containerHeight,
			cropmove:         this.handleCropMove,
		});

		this.$refs.cropCanvas.addEventListener('ready', this.handleCropperReady);
		this.$refs.cropCanvas.addEventListener('cropstart', this.handleCropStart);

		window.addEventListener('resize', debounce(this.reinitializeCropper, 100));
	},
	beforeDestroy() {
		// Clean up event listeners to prevent memory leaks
		this.$refs.videoPlayer.removeEventListener('loadedmetadata', this.handleLoadedMetadata);
		this.$refs.videoPlayer.removeEventListener('timeupdate', this.handleTimeUpdate);
		this.$refs.cropCanvas.removeEventListener('ready', this.handleCropperReady);
		this.$refs.cropCanvas.removeEventListener('cropstart', this.handleCropStart);
		window.removeEventListener('resize', debounce(this.reinitializeCropper, 100));

		// Destroy cropper instance
		if (this.cropper) {
			this.cropper.destroy();
		}
	},
	methods: {
		reinitializeCropper() {
			if (this.cropper) {
				this.cropper.destroy();
			}

			window.requestAnimationFrame(() => {
				this.updateContainerSize();
				window.requestAnimationFrame(() => {
					this.handleCropMove();
					this.cropper = new Cropper(this.$refs.cropCanvas, {
						...CROPPER_SETTINGS,
						minCropBoxHeight: this.containerHeight,
						cropmove:         this.handleCropMove,
					});
				});
			});
		},
		updateContainerSize() {
			window.requestAnimationFrame(() => {
				this.$refs.multiFormatPlayer.style.display = 'none';
				const windowWidth = window.innerWidth;
				const windowHeight = window.innerHeight;
				this.$refs.multiFormatPlayer.style.display = 'block';
				const heightPlayerControls = this.$refs.playerControls?.$el?.clientHeight || 0;
				const mobileWidth = ((windowHeight - (heightPlayerControls + 15)) / 9 * 16);
				const desktopWidth = windowWidth - 60;
				const isMobileLandscape = window.matchMedia('(pointer: coarse) and (orientation: landscape)').matches;
				const isMobilePortrait = window.matchMedia('(pointer: coarse) and (orientation: portrait)').matches;
				if (isMobilePortrait) {
					this.containerWidth = windowWidth;
				} else {
					this.containerWidth = windowWidth > 1200
						? 1200
						: isMobileLandscape ? mobileWidth : desktopWidth;
				}
			});
		},
		handleCropMove() {
			this.$emit('coordinates-update', this.cropper.getCropBoxData());
		},
		handleTimeUpdate(event) {
			this.position = event.target?.currentTime;

			// stopping 0.1s before the end to prevent some glitches
			if ((this.tagTime && this.position + 0.1 >= this.tagTime.end) || (this.position + 0.1 >= this.duration)) {
				this.togglePlay(false);
				this.$emit('video-ended');
			}
		},
		togglePlay(value) {
			value ? this.$refs.videoPlayer.play() : this.$refs.videoPlayer.pause();
			this.isPlaying = value;
		},
		setVideoTime(time) {
			if (this.tagTime) {
				time += this.tagTime.start;
			}
			this.position = time;
			this.$refs.videoPlayer.currentTime = time;
			if (!this.isPlaying) {
				this.togglePlay(true);
				this.togglePlay(false);
			}
		},
		seek(time) {
			if (this.playerControlsPosition + time < 0) {
				this.setVideoTime(0);
			} else if (this.playerControlsPosition + time > this.playerControlsDuration) {
				this.setVideoTime(this.playerControlsDuration);
			} else {
				this.setVideoTime(this.playerControlsPosition + time);
			}
		},
		getPlayerData() {
			this.duration = this.$refs.videoPlayer.duration;
			this.isPlaying = !this.$refs.videoPlayer.paused;
			this.isMuted = this.$refs.videoPlayer.muted;
			this.volumeLevel = this.$refs.videoPlayer.volume * 100;
		},
		setMute(value) {
			if (value && this.volumeLevel === 0) {
				this.setVolume(50);
			}
			this.$refs.videoPlayer.muted = value;
			this.isMuted = value;
		},
		setVolume(value) {
			this.$refs.videoPlayer.volume = value / 100;
			this.volumeLevel = value;
			if (value > 0 && this.isMuted) {
				this.setMute(false);
			} else if (value === 0) {
				this.setMute(true);
			}
		},
		handleLoadedMetadata() {
			this.getPlayerData();
			if (requestedPosition) {
				this.$refs.videoPlayer.currentTime = parseFloat(requestedPosition);
			} else {
				this.$refs.videoPlayer.currentTime = this.tagTime ? this.tagTime.start : 0;
			}
		},
		handleCropperReady() {
			this.$emit('coordinates-update', this.cropper.getCropBoxData());
		},
		handleCropStart() {
			this.$emit('cropstart');
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@shared/sass/shared-variables';

.multi-format-player {
	position: relative;
}

.multi-format-player__video-container {
	overflow-x: clip;
	overflow-y: visible;
	position: relative;

}

.multi-format-player__video-player {
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	position: absolute;
}

.multi-format-player__controls {
	&.is-hidden {
		visibility: hidden;
		pointer-events: none;
		opacity: 0;
	}

	.multi-format-player.is-preview & {
		height: 0;
	}
}

.multi-format-player__crop-container {
	position: absolute;
	top: 0;
	left: 0;
	overflow-x: clip;
	overflow-y: visible;
}

.multi-format-player__crop-canvas {
	display: block;
	max-width: 100%;
}

::v-deep {
	.cropper-container {
		overflow: hidden;
	}

	.cropper-face {
		background-color: transparent;
	}

	.cropper-wrap-box {
		pointer-events: none;
	}

	.cropper-canvas,
	.cropper-drag-box {
		pointer-events: none;
		cursor: default;
	}

	.cropper-view-box {
		outline: none;

		.multi-format-player.is-initial &,
		.multi-format-player.is-finished-recording &,
		.multi-format-player.is-generating-video-crop &,
		.multi-format-player.is-video-crop-available &,
		.multi-format-player.is-video-crop-failure & {
			border: 3px dashed var(--color-white);

			@include mobile-portrait {
				border-width: 2px;
			}
		}

		.multi-format-player.is-ready &,
		.multi-format-player.is-preview & {
			border: 3px solid var(--color-hype-yellow);

			@include mobile-portrait {
				border-width: 2px;
			}
		}

		.multi-format-player.is-recording & {
			border: 3px dashed var(--color-red);

			@include mobile-portrait {
				border-width: 2px;
			}
		}
	}

	.cropper-crop-box {
		box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75); // stylelint-disable-line
		will-change: transform;
	}
}

</style>
