<template>
	<div class="top-scorers">
		<LoadingSpinner
			v-if="isLoading"
			class="top-scorers__loading-spinner"
		/>
		<template v-if="!isLoading && topScorerTables">
			<div class="top-scorers__filter">
				<div class="top-scorers__view-select-w">
					<div
						v-for="option in views.options"
						:key="option.value"
						class="top-scorers__view-select"
						:class="{'is-active': option.value === views.currentSelection}"
						@click="handleViewChange(option)"
					>
						{{ option.label }}
					</div>
				</div>
			</div><template v-if="!activeTableIsEmpty">
				<div class="top-scorers__w">
					<table class="top-scorers__table">
						<tr class="top-scorers__header">
							<th class="top-scorers__col -player">
								<span class="top-scorers__rank">R</span> {{ $I18n.trans('context.type.player') }}
							</th>
							<th class="top-scorers__col -team">
								{{ $I18n.trans_choice('commons.team', 1) }}
							</th>
							<th
								v-for="col in tableColumns"
								:key="col.abbr"
								class="top-scorers__col"
							>
								<div>
									<Tooltip
										:text="col.name"
										position="top-center"
									>
										<template #toggle>
											{{ col.abbr }}
										</template>
									</Tooltip>
								</div>
							</th>
						</tr>
						<tr
							v-for="(entry, i) in tableEntries"
							:key="i"
							class="top-scorers__body"
						>
							<td class="top-scorers__col -player">
								<div class="top-scorers__col-w">
									<span class="top-scorers__rank">{{ i + 1 }}</span>
									<img
										v-if="entry.playerImage"
										class="top-scorers__logo -player"
										:src="entry.playerImage"
										:alt="entry.playerName.long"
									>
									<div
										v-else
										class="top-scorers__logo -placeholder"
									>
										<Icon
											icon-name="account"
											size="sm"
										/>
									</div>
									<div class="top-scorers__txt">
										<span>{{ entry.playerName.long }}</span>
										<span class="line--muted">{{ entry.teamName.short }}</span>
									</div>
								</div>
							</td>
							<td class="top-scorers__col -team">
								<a
									:href="entry.teamLink"
									class="top-scorers__link"
								/>
								<div class="top-scorers__col-w">
									<img
										class="top-scorers__logo"
										:src="entry.teamImage"
										:alt="entry.teamName.long"
									>
									<span class="u-hide@md">{{ entry.teamName.short }}</span>
									<span class="u-show-as-inline-flex@md">{{ entry.teamName.long }}</span>
								</div>
							</td>
							<td
								v-for="(d, j) in entry.data"
								:key="j"
								class="top-scorers__col"
							>
								{{ d.value }}
							</td>
						</tr>
					</table>
				</div>
				<div class="top-scorers__footnote">
					<div
						v-for="col in tableColumns"
						:key="col.abbr"
						class="top-scorers__footnote-row"
					>
						<div class="top-scorers__footnote-key">
							{{ col.abbr }}
						</div>
						<div class="top-scorers__footnote-value">
							{{ col.name }}
						</div>
					</div>
				</div>
			</template>
		</template>
		<EmptyState
			v-if="activeTableIsEmpty && !isLoading"
			class="u-mt-7"
			:text="$I18n.trans('commons.no_types_existing', { type: $I18n.trans('arena.match_center.top_scorer')})"
		/>
	</div>
</template>
<script>
import LoadingSpinner from '@shared/components/LoadingSpinner';
import EmptyState from '@js/components/EmptyState';
import Tooltip from '@shared/components/Tooltip.vue';
import laroute from '@laroute';
import Icon from '@shared/components/Icon.vue';

export default {
	name:       'TopScorers',
	components: { Icon, Tooltip, EmptyState, LoadingSpinner },
	props:      {
		topScorerTables: {
			type:    Array,
			default: null,
		},
		isLoading: {
			type:    Boolean,
			default: false,
		},
	},
	data() {
		return {
			views:                null,
			activeTopScorerTable: null,
		};
	},
	computed: {
		activeTableIsEmpty() {
			return this.activeTopScorerTable?.entries?.data?.length <= 0 ?? true;
		},
		tableProperties() {
			return this.activeTopScorerTable?.entries?.meta?.formats.find(format => format.format === 'table').properties;
		},
		tableColumns() {
			return this.tableProperties?.map(p => {
				return {
					abbr: this.$I18n.trans(`sports.league.${p}`).abbr,
					name: this.$I18n.trans(`sports.league.${p}`).name,
				};
			});
		},
		tableEntries() {
			return this.activeTopScorerTable?.entries?.data?.map(entry => {
				return {
					playerName: {
						long:  entry.player.name,
						short: entry.player.code?.split('')?.join('.'),
					},
					teamName: {
						long:  entry.team.club.name,
						short: entry.team.club.code,
					},
					playerImage: this.portraitImage(entry.player),
					teamImage:   this.getScaledImage(entry.team.imageUrl),
					data:        this.tableProperties.map(col => ({
						key:   col,
						value: entry.data[col],
					})),
					teamLink: laroute.route('arena.team', {
						team: entry.team.id,
						slug: $Helpers.slugify(entry.team.clubTeamName),
					}),
				};
			});
		},
	},
	watch: {
		topScorerTables(value) {
			if (value !== null) {
				this.views = {
					currentSelection: this.topScorerTables[0].id,
					options:          this.topScorerTables.map(t => ({ label: t.name, value: t.id })),
				};
				this.activeTopScorerTable = this.topScorerTables.find(t => t.id === this.views.currentSelection);
			}
		},
	},
	methods: {
		handleViewChange(option) {
			this.views.currentSelection = option.value;
			this.activeTopScorerTable = this.topScorerTables.find(t => t.id === option.value);
		},
		portraitImage(entry) {
			if (!entry.imageUrl || entry.imageUrl.includes('placeholder')) {
				return null;
			}
			return this.getScaledImage(entry.imageUrl);
		},
		getScaledImage(imageUrl) {
			return this.$Helpers ? this.$Helpers.getScaledImageUrl(imageUrl, 50) : imageUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.top-scorers {
	width: 100%;
	position: relative;
	margin-bottom: $sp7;
}

.top-scorers__loading-spinner {
	position: absolute;
	top: 20vh;
	left: 50%;
	transform: translate(-50%);
}

// View selection
.top-scorers__filter {
	margin-bottom: $sp5;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid var(--color-grey-900);
}

.top-scorers__view-select-w {
	display: flex;
	align-self: center;
	gap: $sp4;
	margin-bottom: $sp4;
}

.top-scorers__view-select {
	padding: $sp3 $sp4;
	border-radius: $border-radius--sm;
	color: var(--color-grey-400);
	transition: background-color $trans-time-fast;
	cursor: pointer;

	&.is-active {
		background-color: var(--color-grey-850);
		color: var(--color-soft-white);

		body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
			background-color: var(--color-hype-yellow);
		}
	}

	&:hover,
	&:focus {
		background-color: var(--color-grey-900);
	}
}

// Table
.top-scorers__w {
	width: 100%;
	margin-bottom: $sp6;

	@include size(xs-only) {
		overflow-x: auto;
	}
}

.top-scorers__table {
	@include font-size(fs-80);
	width: 100%;
}

.top-scorers__header {
	color: var(--color-grey-300);

	.top-scorers__col.-player::after {
		content: none;
	}
}

.top-scorers__body {
	body.-t-light & { // stylelint-disable-line selector-no-qualifying-type
		color: var(--color-black);
	}
}

.top-scorers__col {
	vertical-align: middle;
	white-space: nowrap;
	padding: $sp4 $sp3 $sp4 $sp3;
	border-bottom: 1px solid var(--color-grey-900);
	position: relative;

	&.-player {
		text-align: left;

		&::after {
			content: '';
			position: absolute;
			top: $sp2;
			bottom: $sp2;
			right: 0;
			display: block;
			background-color: var(--color-grey-900);
			width: 1px;
		}

		@include size(sm-down) {
			min-width: 120px;
		}

		@include size(xs-only) {
			width: 99%;
		}
	}

	&.-team {
		width: 99%;
		text-align: left;
		@include size(xs-only) {
			display: none;
		}
	}

	.top-scorers__body:hover & {
		background-color: var(--color-grey-950);
	}

	@include size(md-up) {
		padding: $sp4 $sp4 $sp4 $sp4;
	}

	@include size(xs-only) {
		.top-scorers__header &,
		.top-scorers__body & {
			&:first-child { // Player name column
				min-width: 120px;
				position: sticky;
				left: 0;
				z-index: 2;
				background-color: var(--color-body-background);
			}
		}

		.top-scorers__body & {
			&:first-child { // Shadow on player name column
				&::after {
					content: '';
					position: absolute;
					top: 0;
					right: 0;
					display: block;
					box-shadow: 5px 0 14px rgba(248, 248, 247, 0.18); // stylelint-disable-line declaration-property-value-allowed-list
					clip-path: inset(0 -15px 0 1px);
					width: 15px;
					height: 100%;
					background-color: unset; // stylelint-disable-line sh-waqar/declaration-use-variable, declaration-property-value-allowed-list
				}
			}
		}
	}
}

.top-scorers__col-w {
	display: flex;
	align-items: center;
	width: max-content;
}

.top-scorers__txt {
	display: flex;
	flex-direction: column;

	.line--muted {
		display: none;
		color: var(--color-grey-300);

		@include size(xs-only) {
			display: inline;
		}
	}
}

.top-scorers__link {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.top-scorers__rank {
	display: inline-block;
	width: $sp5;
}

.top-scorers__logo {
	width: $sp5;
	height: $sp5;
	margin-right: $sp3;
	object-fit: cover;
	border-radius: 50%;

	&.-placeholder {
		width: $sp5;
		height: $sp5;
		border: 1px solid var(--color-grey-800);
		background-color: var(--color-body-background);
		border-radius: 50%;
		justify-content: center;
		align-items: center;
		stroke-width: 0.5px;
		color: var(--color-grey-500);
		display: inline-flex;
	}
}

.top-scorers__footnote {
	@include font-size(fs-80);
	color: var(--color-grey-300);
}

.top-scorers__footnote-row {
	display: flex;
}

.top-scorers__footnote-key {
	width: 80px;
}

</style>
