<template>
	<div
		class="v2-icon"
		:class="[`-size-${size}`, background ? `-bg-${background}` : '', sizeAtMd ? `-size-${sizeAtMd}@md` : '']"
		@click="$emit('click', $event)"
	>
		<svg>
			<use :href="`#sprite-${iconNameComputed}`" />
		</svg>
	</div>
</template>

<script>
export const iconNames = Array.from(document.querySelectorAll('.js-icon-sprite symbol')).map(icon => {
	return icon.getAttribute('id').replace('sprite-', '');
});

const sizes = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'xxxl', 'xxxxl'];
const colors = ['primary', 'secondary', 'grey-100', 'grey-600'];

export default {
	name:  'V2Icon',
	props: {
		iconName: {
			type:     String,
			required: true,
		},
		iconFallback: {
			type:     String,
			required: false,
			default:  null,
		},
		size: {
			type:    String,
			default: 'md',
			validator(size) {
				return sizes.includes(size);
			},
		},
		sizeAtMd: {
			type:    String,
			default: null,
			validator(size) {
				return sizes.includes(size);
			},
		},
		background: {
			type:     String,
			required: false,
			default:  null,
			validator(color) {
				return color === null ? true : colors.includes(color);
			},
		},
	},
	data() {
		return {
			useFallback: false,
		};
	},
	computed: {
		iconNameComputed() {
			return this.useFallback ? this.iconFallback : this.iconName;
		},
	},
	watch: {
		$props: {
			immediate: true,
			handler() {
				this.validateProps();
			},
		},
	},
	methods: {
		validateProps() {
			this.useFallback = false;
			if (window.isStorybook) {
				return true;
			}
			if (!iconNames.includes(this.iconName)) {
				if (this.iconFallback && iconNames.includes(this.iconFallback)) {
					this.useFallback = true;
				} else {
					throw new Error(`Icon does not exist, icon-name: ${this.iconName}, icon-fallback: ${this.iconFallback}`);
				}
			}
		},
	},
};
// Styles are in shared/sass/components/icon.scss so we can use icons without the component to avoid flash of unstyled content
</script>
