<template>
	<a
		:href="laroute.route('arena.club', {club: item.id, slug: $Helpers.slugify(item.name)})"
		class="l-carousel-wrapper-club card-club"
	>
		<div class="card-club__inner">
			<div class="card-club__logo-w">
				<img
					class="card-club__logo"
					:src="imageUrl"
				>
			</div>
			<h4
				class="card-club__title"
				v-text="item.name"
			/>
		</div>
	</a>
</template>

<script>
import laroute from '@laroute';

export default {
	name:  'ClubCard',
	props: {
		item: {
			type:     Object,
			required: true,
		},
		clickable: {
			type:     Boolean,
			required: false,
			default:  true,
		},
		imageSize: {
			type:     Number,
			required: false,
			default:  null,
		},
	},
	data() {
		return {
			laroute,
		};
	},
	computed: {
		imageUrl: function() {
			return this.$Helpers && this.imageSize && this.item.imageUrl && !this.item.imageUrl.includes('logo_placeholder')
				? this.$Helpers.getScaledImageUrl(this.item.imageUrl, this.imageSize)
				: this.item.imageUrl;
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.card-club {
	display: inline-block;
}

.card-club__inner {
	text-align: center;
	background-color: $color-grey-900;
	border-radius: $border-radius;
	width: 100%;
	height: 100%;
	padding: 0 $sp3 $sp4;
	border: 1px solid $color-grey-800;
	transition: transform $trans-time-fast, background-color $trans-time-fast;
	will-change: transform, background-color;
	cursor: pointer;

	.is-keynav-section-active .is-selected & {
		transform: scale(1.09);
		background-color: $color-grey-850;
		border: 3px solid $color-hype-yellow;
		border-radius: 3px;
	}

	&:focus,
	&:hover {
		transform: scale(1.09);
	}
}

.card-club__title {
	@include font-size(fs-80);

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box; // stylelint-disable-line value-no-vendor-prefix
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical; // stylelint-disable-line property-no-vendor-prefix
}

.card-club__logo-w {
	height: 92px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card-club__logo {
	width: 56px;
}
</style>
