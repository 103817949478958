<template>
	<nav
		v-if="computedLink"
		class="nav-back u-smarttv-hidden"
	>
		<a
			class="nav-back__link"
			:href="computedLink"
		>
			<Icon
				icon-name="arrow-left"
				class="nav-back__icon"
			/><span class="nav-back__text">{{ $Helpers.ucfirst(title) }}</span>
		</a>
	</nav>
</template>

<script>
import Icon from '@shared/components/Icon';

export default {
	name:       'NavBack',
	components: { Icon },
	props:      {
		link: {
			type:     String,
			required: true,
		},
		title: {
			type:     String,
			required: true,
		},
	},
	computed: {
		computedLink() {
			if (this.$arenaConfig.isFanAt) {
				return document.referrer || 'https://fan.at/handball/videos/uebersicht';
			}
			// If a custom link was passed, use it, otherwise use the default back url
			return this.link || $Helpers.getBackUrl();
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@sass/variables';

.nav-back {
	@include font(primary, book, normal, fs-80);
	display: flex;
	padding-left: $grid-outter-padding--xs;
	border-bottom: 1px solid var(--color-grey-900);

	body.is-player-mode & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
		@include mobile-media-query {
			display: none;
		}

		@media screen and (max-height: 800px) {
			display: none;
		}
	}

	@include size(sm-up) {
		padding-left: $grid-outter-padding;

		body.-t-swiss-league & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			padding-left: 115px; // swiss-league logo covers back button, therefore we move it a bit inwards
		}
	}

	@include size(md-up) {
		body.-t-swiss-league & { // stylelint-disable-line selector-max-type, selector-no-qualifying-type
			padding-left: 130px; // swiss-league logo covers back button, therefore we move it a bit inwards
		}
	}

	@include size(xl-only) {
		padding-left: $grid-outter-padding;
	}
}

.nav-back__link {
	display: inline-flex;
	padding-top: $sp4;
	padding-bottom: $sp4;
	color: var(--color-grey-300) !important; // stylelint-disable-line declaration-no-important

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

// .nav-back__text {}

.nav-back__icon {
	margin-right: $sp2;
	transform: translateY(-1px);
	color: var(--color-grey-300);
}
</style>
